//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
//#endregion

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  title = 'SUNRISE PROJECT';
  currentUrl!: string;
  //#endregion

  constructor(
    private _activeRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-09
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    // Atualiza a variável currentUrl com o valor da rota atual.
    this._router.events.subscribe((xValue) => {
      if (this._activeRoute.firstChild) {
        this._activeRoute.firstChild.url.subscribe((url) => {
          this.currentUrl = url[0].path;
        });
      }
    });
  }
  //#endregion
}
