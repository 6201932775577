//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-countdown-utc',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './countdown-utc.component.html'
})
export class CountdownUtcComponent implements OnInit, OnDestroy {
  //#region "|--- INPUTS ---|"
  @Input() targetDate!: string; // Data e hora de destino no formato ISO (yyyy-MM-ddTHH:mm:ss)
  //#endregion

  //#region "|--- PROPERTIES---|"
  private _subscription!: Subscription;

  days!: string;
  hours!: string;
  minutes!: string;
  seconds!: string;

  isRunning!: boolean;
  isWarning!: boolean;
  isDanger!: boolean;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
    this._startCountdown();
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  //#region "|--- PRIVATE METHODS ---|"
  private _initVariables() {
    this.isRunning = false;
    this.isWarning = false;
    this.isDanger = false;
  }

  /**
   * @author GASPAR
   * @date 2024-10-03
   * @version 1.0.0
   * 
   * @description Inicia a contagem regressiva para a data alvo.
   * 
   * @returns 
   */
  private _startCountdown(): void {
    if (!this.targetDate) {
      this._setZeroValues();
      return;
    }

    const target = Date.parse(this.targetDate); // Parse the target date in UTC
    if (isNaN(target)) {
      this._setZeroValues();
      return;
    }

    this._subscription = interval(1000).subscribe(() => {
      const now = new Date().getTime(); // Current time in milliseconds
      const distance = target - now;

      if (distance < 0) {
        this._setZeroValues();
        this.isRunning = false;
        this._subscription.unsubscribe();
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.days = this._formatNumber(days);
        this.hours = this._formatNumber(hours);
        this.minutes = this._formatNumber(minutes);
        this.seconds = this._formatNumber(seconds);

        this.isWarning = distance <= 60 * 60 * 1000 && distance > 45 * 60 * 1000;
        this.isDanger = distance <= 45 * 60 * 1000;
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-03
   * @version 1.0.0
   * 
   * @description Define os valores de dias, horas, minutos e segundos como zero.
   */
  private _setZeroValues(): void {
    this.days = this.hours = this.minutes = this.seconds = '00';
  }

  /**
   * @author GASPAR
   * @date 2024-10-03
   * @version 1.0.0
   * 
   * @description Formata um número para duas casas decimais.
   * 
   * @param num 
   * @returns 
   */
  private _formatNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  //#endregion
}
