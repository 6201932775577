//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initWeightBalanceDataForm(xFormatType: string) {
    const objFormBuilder: FormBuilder = new FormBuilder();

    /**
     * --- xFormatType ---
     * "template": construído no módulo de templates, que pode ser usado por várias aeronaves diferentes; 
     * "sheet": construído no módulo de aeronaves, que é específico para uma aeronave em particular e não pode ser usado por outras aeronaves.
     */
    return objFormBuilder.group({
        weight_balance_data_id: objFormBuilder.control(null),
        format_type: [xFormatType], // "template", "sheet" 
        belong_to_aircraft: [[]],
        path_perfil_image: [[]], // Array de String
        header_data: objFormBuilder.group({
            name: [null],
            description_config: [null],
            aircraft_type: [null],
            aircraft_model: [null],
            aircraft_icao_code: [null],
            aircraft_manual_id: [null],
            aircraft_manual_date: [null],
            aircraft_manual_version: [null],
            calibration_date_utc: [null],
            calibration_next_date_utc: [null],
            calibration_observation: [null]
        }),
        unit_data: objFormBuilder.group({
            weight: ["lb"],
            arm: ["in"],
            fuel: ["kg"],
        }),
        cg_data: [[]],
        weight_data: objFormBuilder.group({
            bem: objFormBuilder.group({
                weight: [null],
                longitudinal_arm: [null],
                lateral_arm: [null]
            }),            
            max_takeoff_weight: [null],
            max_landing_weight: [null],
            max_ramp_weight: [null],
            max_zero_fuel_weight: [null]
        }),
        components_input_data: [[]],
        components_constant_data: [[]],
        components_fuel_data: [[]],
        components_external_data: [[]],
        chart_longitudinal_data: objFormBuilder.array([]),
        chart_lateral_data: objFormBuilder.array([])        
    });
}

