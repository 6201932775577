//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initPaxDataForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        pax_data_id: [null],
        name: objFormBuilder.group({
            first_name: [''],
            last_name: [''],
            full_name: ['']
        }),
        birthday: [''],
        gender: [''],
        physical: objFormBuilder.group({
            height: [null],
            height_unit: ["kg"],
            weight: [null],
            weight_unit: ["m"],
            blood_type: [null]            
        }),
        nationality: [''],
        documents: objFormBuilder.array([]),
        addresses: objFormBuilder.array([]),
        contacts: objFormBuilder.array([]),
        emergency_contacts: objFormBuilder.array([]),
        aircraft: objFormBuilder.array([]),
        data_from: [null],
        pax_remarks: [null]        
    });
}

