<section class="cls-component-tab-data">
    <p-toolbar class="cls-component-toolbar">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container
                *ngIf="this.formComponent.controls.crew_data_id.value !== null"
            >
                <p-button
                    label="Novo"
                    styleClass="cls-component-button"
                    [rounded]="true"
                    icon="fa-solid fa-plus"
                    severity="secondary"
                    class="mr-2"
                    (click)="callbackClickButton(this.buttonActions.NEW)"
                />
                <p-button
                    label="Salvar Modificações"
                    styleClass="cls-component-button"
                    [rounded]="true"
                    icon="fa-solid fa-save"
                    severity="secondary"
                    class="mr-2"
                    (click)="callbackClickButton(this.buttonActions.UPDATE)"
                />
            </ng-container>
            <ng-container
                *ngIf="this.formComponent.controls.crew_data_id.value === null"
            >
                <p-button
                    label="Salvar Novo"
                    styleClass="cls-component-button"
                    [rounded]="true"
                    icon="fa-solid fa-save"
                    severity="secondary"
                    class="mr-2"
                    (click)="callbackClickButton(this.buttonActions.SAVE)"
                />
            </ng-container>
            <p-button
                label="Sair"
                styleClass="cls-component-button"
                [rounded]="true"
                icon="fa-solid fa-right-from-bracket"
                severity="danger"
                (click)="callbackClickButton(this.buttonActions.EXIT)"
            />
        </div>
    </p-toolbar>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Cadastro Tripulação </span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-input-text 
                                        label="Nome Completo #BOLD#"
                                        [control]="this.formComponent.controls.name.controls.full_name">
                                    </lib-ui-primeng-input-text>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down-flag label="País de Registro #BOLD#"
                                        [control]="this.formComponent.controls.nationality"
                                        [arrayOptions]="this.optionsNationalityCountries">
                                    </lib-ui-primeng-drop-down-flag>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-input-date
                                        label="Aniversário #BOLD#"
                                        [control]="this.formComponent.controls.birthday">
                                    </lib-ui-primeng-input-date>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down 
                                        label="Gênero"
                                        [control]="this.formComponent.controls.gender"
                                        [arrayOptions]="this.optionsGender">                                        
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Tipo Documento #BOLD#"
                                        [control]="this.formComponent.controls.documents.controls[0].controls.document_type"
                                        [arrayOptions]="this.optionsDocumentsType">
                                    </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-input-text label="Número #BOLD#"
                                        [control]="this.formComponent.controls.documents.controls[0].controls.document_type">
                                    </lib-ui-primeng-input-text>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Tipo Doc. Extra #BOLD#"
                                    [control]="this.formComponent.controls.documents.controls[1].controls.document_type"
                                    [arrayOptions]="this.optionsDocumentsType">
                                </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-input-text label="Número Doc. Extra #BOLD#"
                                        [control]="this.formComponent.controls.documents.controls[1].controls.document_type">
                                    </lib-ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-panel> 
        </div>
    </div>
</section>
