//#region "|--- INTERFACE ---|"
import { IDropDownOptionsCountry } from "../interfaces/IDropDownOptionsCountry";
//#endregion

/**
 * @author GASPAR
 * @date 2024-10-12
 * @version 1.0.0
 * 
 * @description Opções Todos os Países com Bandeiras e DDI.
 */
export const staticOptionsAllCountries: IDropDownOptionsCountry[] = [
    { label: 'ARGENTINA', value: 'AR', flag: 'AR', ddi: '+54' },
    { label: 'AUSTRÁLIA', value: 'AU', flag: 'AU', ddi: '+61' },
    { label: 'BRASIL', value: 'BR', flag: 'BR', ddi: '+55' },
    { label: 'BOLÍVIA', value: 'BO', flag: 'BO', ddi: '+591' },
    { label: 'CANADA', value: 'CA', flag: 'CA', ddi: '+1' },
    { label: 'CHILE', value: 'CL', flag: 'CL', ddi: '+56' },
    { label: 'COLÔMBIA', value: 'CO', flag: 'CO', ddi: '+57' },
    { label: 'EQUADOR', value: 'EC', flag: 'EC', ddi: '+593' },
    { label: 'ESTADOS UNIDOS', value: 'US', flag: 'US', ddi: '+1' },
    { label: 'GEÓRGIA DO SUL', value: 'GE', flag: 'GE', ddi: '+500' },
    { label: 'GUIANA', value: 'GY', flag: 'GY', ddi: '+592' },
    { label: 'GUIANA FRANCESA', value: 'GF', flag: 'GF', ddi: '+594' },
    { label: 'MÉXICO', value: 'MX', flag: 'MX', ddi: '+52' },
    { label: 'PARAGUAI', value: 'PY', flag: 'PY', ddi: '+595' },
    { label: 'PERU', value: 'PE', flag: 'PE', ddi: '+51' },
    { label: 'SURINAME', value: 'SR', flag: 'SR', ddi: '+597' },
    { label: 'URUGUAI', value: 'UY', flag: 'UY', ddi: '+598' },
    { label: 'VENEZUELA', value: 'VE', flag: 'VE', ddi: '+58' },
    { label: 'NEW ZEALAND', value: 'NZ', flag: 'NZ', ddi: '+64' }
]