//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initPaxDataForm } from '../../form-init/pax-data-form';
import { initDocumentsPersonalForm } from '../../form-init/documents-personal-form';

// ***** COMPONENT ***** //
import { TabPaxRegisterComponent } from './_components/pax_data/tab-pax-register/tab-pax-register.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
import { ModulePaxService } from '../../../../_services/module-pax/module-pax.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IWaitingDialog } from "../../../../../_library/interfaces/IWaitingDialog";
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-pax-data-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TabPaxRegisterComponent,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './pax-data-form.component.html'
})
export class PaxDataFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public activeTabIndex!: number;
  public currentSettings!: any;
  public typeDialogWaiting!: string;
  public extraDataDialogWaiting!: any[];

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classLocalMethods!: any;
  public classModuleMethods!: any;

  // ***** SHOW DIALOG ***** //  
  public bolShowDialogWaiting!: boolean;

  // ***** FORMS ***** //
  public formPaxData: any

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** ARRAY ***** // 
  public listAircraftData!: any[];

  // ***** OPTIONS ***** //
  public optionsAircraftData!: IDropDownOptionsIcon[];
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _location: Location,
    private _messageService: MessageService,
    private _moduleAircraftService: ModuleAircraftService,
    private _modulePaxService: ModulePaxService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();
    this._initPaxDataForm();
    this._checkEditMode();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables() {
    this.actionType = ACTION_TYPE;
    this.activeTabIndex = 0;
    this.currentSettings = settingConfig;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;
    this.extraDataDialogWaiting = [];

    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;

    this.listAircraftData = [];

    this.optionsAircraftData = [];

    this.bolShowDialogWaiting = false;
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-12-26
  * @version 1.0.0
  * 
  * @description 
  *   - Método que verifica se é uma EDIÇÃO de dados de um registro ou um NOVO registro.
  *   - Define os Título da página.
  */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      let isEdit = false;

      if (xParams["id"]) {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_PaxDataAlter'),
          subtitle: this.classInternationalization.getTranslation('txt_PaxDataAlter'),
          icon: 'fa-solid fa-edit'
        }

        isEdit = true;
      } else {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_PaxDataNew'),
          subtitle: this.classInternationalization.getTranslation('txt_PaxDataNew'),
          icon: 'fa-solid fa-plus'
        }
      }

      if (isEdit) {
        Promise.all([
          await this._loadExtraDataDb(false),
          await this._getPaxDataById(xParams["id"])
        ]);
      } else {
        await this._loadExtraDataDb(true);
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-26
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega o dados necessários para preenchimento do cadastro.
   */
  private async _loadExtraDataDb(xBolCloseWaitingDialog: boolean): Promise<void> {
    this.bolShowDialogWaiting = true;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.LOADING_DATA;

    Promise.resolve([
      await this._getAllAircraftDataDb()
    ]);

    setTimeout(() => {
      if (xBolCloseWaitingDialog === true) {
        this.bolShowDialogWaiting = false;
      }
    }, this.currentSettings.WAITING_DIALOG_LIFE);
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Gera as opções de todas as aeronaves para por DROPDOWN.
   */
  private _generateOptionsAllAircraft(): void {
    this.optionsAircraftData = [];

    this.optionsAircraftData = this.listAircraftData ? this.listAircraftData.map((xObjAircraft: any) => {
      return {
        label: xObjAircraft.register_data.aircraft_mark,
        value: xObjAircraft.aircraft_data_id,
        icon: `${this.classModuleMethods.getAircraftTypeLabel(xObjAircraft.register_data.aircraft_type, true)}`
      }
    }) : [];
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário.
   */
  private _initPaxDataForm(): void {
    this.formPaxData = initPaxDataForm();

    // É preciso criar pelo menos 2 instâncias do documento para que o formulário funcione corretamente.
    this.formPaxData.controls.documents_personal.controls.push(initDocumentsPersonalForm());
    this.formPaxData.controls.documents_personal.controls.push(initDocumentsPersonalForm());
  }

  /**
   * @author GASPAR
   * @date 2024-22-27
   * @version 1.0.0
   * 
   * @description 
   *   - Método que limpa o formulário de dados da aeronave.
   */
  private _toastMessage(xMessage: any): void {
    this._messageService.add({
      severity: xMessage.severity,
      summary: xMessage.summary,
      detail: xMessage.detail,
      key: this.currentSettings.TOAST_KEY,
      life: this.currentSettings.TOAST_LIFE
    });
  }

  /**
   * @status:
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description 
   *   - Método que cria um novo registro de PAX.
   */
  private _newRegisterData() {
    this._clearForm();

    this._router.navigate([`/app/${this._locale}/pax/data/form`]);
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método que limpa o formulário de dados da aeronave.
   */
  private _clearForm(): void {
    this.formPaxData = null;
    this._initPaxDataForm();

    this._location.replaceState(`/app/${this._locale}/pax/form`);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *     - Método que trata a ação de fechar o dialog de espera.
   */
  public handlerActionCloseWaitingDialog(xBolCloseDialog: boolean): void {
    this.bolShowDialogWaiting = xBolCloseDialog;
  }

  /**
   * @status
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   *
   * @description 
   *   - Método que trata as ações da aba de dados de registro da aeronave.
   *
   * @param xAction
   */
  public async handlerActionToolbarTabPaxData(xAction: string): Promise<void> {
    if (xAction === ACTION_TYPE.NEW) {
      this._newRegisterData();
    } else if (xAction === ACTION_TYPE.SAVE) {
      await this._saveFormPaxData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      if (this.formPaxData.controls.pax_data_id.value !== null) {
        await this._updatePaxData();
      }
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log("SAIR DA CREW DATA");
    } else {
      console.log("NENHUMA AÇÃO DEFINIDA");
    }
  }

  /**
   * @status
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * @param xShowDialog
   *
   * @description 
   *   - Método que trata a exibição do dialog de espera.
   *
   * @param xShowDialog
   */
  public handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowDialogWaiting = xShowDialog.show;
    this.typeDialogWaiting = xShowDialog.type;
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status OK
   * @author GASPAR
   * @date 2025-01-12
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados do "REGISTRO DO PAX".
   * 
   * @abstract 
   *   - Importante ressaltar que quando você está salvando um novo "REGISTRO DO PAX".
   */
  private async _saveFormPaxData(): Promise<void> {
    if (this.formPaxData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formPaxData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._toastMessage({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_PaxDataSave'),
        detail: `${this.classInternationalization.getTranslation('msg_FormFieldsNotValid')} \n- ${messageErrosFormRequest}`
      });
    } else {
      this.bolShowDialogWaiting = true;
      this.typeDialogWaiting = DIALOG_WAITING_TYPE.PAX_DATA_SAVE;

      await this._modulePaxService.saveFormPaxData(this.formPaxData).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
            this.formPaxData.controls.pax_data_id.setValue(xApiResponse.data[0].data[0].id);

            // Atualizar a URL do Browser sem fazer o redirect. Isto é feito para refletir o Estado atual, uqe vem a ser o de editar os dados da aeronave.
            this._location.replaceState(`/app/${this._locale}/pax/form/edit/${xApiResponse.data[0].data[0].id}`);

            setTimeout(() => {
              this.bolShowDialogWaiting = false;
              this._toastMessage({
                severity: 'success',
                summary: this.classInternationalization.getTranslation('ttl_PaxDataSave'),
                detail: `${xApiResponse.data[0].message}`
              });
            }, this.currentSettings.WAITING_DIALOG_LIFE);
          }
        },
        error: (xError: any) => {
          this.bolShowDialogWaiting = false;

          this._toastMessage({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_PaxDataSave'),
            detail: `${xError.detail}`
          });
        }
      });
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-04
   * @version 1.0.0
   * 
   * @description 
   *  - Método que recupera os dados do PAX Data pelo ID.
   * 
   * @param xPaxDataId 
   */
  private async _getPaxDataById(xPaxDataId: string): Promise<void> {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.PAX_DATA;
    this.bolShowDialogWaiting = true;

    await this._modulePaxService.getPaxDataById(xPaxDataId).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          const tempData = xApiResponse[0];

          // Atualiza o formulário com os dados do pax.
          this.formPaxData.patchValue(tempData, { emitEvent: false });

          // Simula um delay para fechar o diálogo de espera.
          setTimeout(() => {
            this.bolShowDialogWaiting = false;
          }, this.currentSettings.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowDialogWaiting = false;

          this._toastMessage({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_PaxDataRecover'),
            detail: this.classInternationalization.getTranslation('msg_PaxDataRecoverError')
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        this.bolShowDialogWaiting = false;

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_PaxDataRecover'),
          detail: `${xError.detail}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-13
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atualiza os dados do "REGISTRO DO PAX".
   */
  private async _updatePaxData(): Promise<void> {
    if (this.formPaxData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formPaxData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._toastMessage({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_PaxDataUpdate'),
        detail: `${this.classInternationalization.getTranslation('msg_FormFieldsNotValid')} \n- ${messageErrosFormRequest}`
      });
    } else {
      this.bolShowDialogWaiting = true;
      this.typeDialogWaiting = DIALOG_WAITING_TYPE.PAX_DATA_UPDATE;

      await this._modulePaxService.updateFormPaxData(this.formPaxData.controls.pax_data_id.value, this.formPaxData).subscribe({
        next: (xResponse) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            setTimeout(() => {
              this.bolShowDialogWaiting = false;

              this._toastMessage({
                severity: 'success',
                summary: this.classInternationalization.getTranslation('ttl_PaxDataUpdate'),
                detail: `${xResponse.data[0].message}`
              });
            }, this.currentSettings.WAITING_DIALOG_LIFE);
          }
        },
        error: (xError) => {
          let tempSeverity = 'error';

          this.bolShowDialogWaiting = false;

          if (xError.http_error == 404) {
            tempSeverity = 'info'
          }

          this._toastMessage({
            severity: tempSeverity,
            summary: this.classInternationalization.getTranslation('ttl_PaxDataUpdate'),
            detail: `${xError.detail}`
          });
        }
      });
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *    - Carrega a lista de Aeronaves cadastradas.
   */
  private async _getAllAircraftDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleAircraftService.getAllAircraftDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listAircraftData = []; // Para forçar a atualização da lista.
            this.listAircraftData = xApiResponse;

            this._generateOptionsAllAircraft(); // Gera as opções de todas as aeronaves para por DROPDOWN.
          } else {
            this.listAircraftData = [];
            this.optionsAircraftData = [];

            this._toastMessage({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
              detail: this.classInternationalization.getTranslation('msg_AircraftDataListError')
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._toastMessage({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
            detail: `${xError.detail}`
          });

          this.listAircraftData = [];
          this.optionsAircraftData = [];

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }
  //#endregion
}