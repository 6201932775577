//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const staticOptionsFlightType: IDropDownOptionsBasic[] = [
    { label: 'Aeromédico', value: 'aeromedical' },
    { label: 'Fretamento', value: 'chartering' },
    { label: 'Linha Inspeção', value: 'line_inspection' },
    { label: 'Particular', value: 'private' },
    { label: 'Panorâmico', value: 'panoramic' },
    { label: 'Translado', value: 'transfer' },
    { label: 'Outros', value: 'others' }
]