//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../_services/_app-locale/app-locale.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleOwnerOperatorService {
  //#region "|--- PROPERTIES---|" 
  urlOwnerOperatorModuleService: string;
  //#endregion

  constructor(
    private _appLocaleService: AppLocaleService,
    private _http: HttpClient
  ) {
    if (settingConfig.API_OWNER_OPERATOR_PORT == "443") {
      this.urlOwnerOperatorModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlOwnerOperatorModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_OWNER_OPERATOR_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- OWNER/OPERATOR DATA ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR TODOS os dados relacionados ao módulo: OWNER/OPERATOR DATA.
   * 
   * @returns
   */
  getAllOwnerOperatorDataList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocal = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlOwnerOperatorModuleService}/${settingConfig.API_OWNER_OPERATOR_URL_SEGMENT}/${tempLocal}/owner/operator/data/list`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }















  //#region "|--- MÉTODOS DO FORMULÁRIO DE DADOS DE REGISTRO DA AERONAVE ---|"
  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método responsável por SALVAR os dados relacionados ao módulo: OWNER OPERATOR DATA.
   * 
   * @param xOwnerOperatorDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele
   * @returns 
   */
  saveOwnerOperatorData(xOwnerOperatorData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>(`${this.urlOwnerOperatorModuleService}/${settingConfig.API_OWNER_OPERATOR_URL_SEGMENT}/owner/operator/data`, xOwnerOperatorData, { headers })
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método responsável por ATUALIZAR os dados relacionados ao módulo: OWNER OPERATOR  DATA.
   * 
   * @param xOwnerOperatorData - tem que passar os dados, pois o tratamento do erro só é possível com ele
   * @param xOwnerOperatorDataId
   * @returns
   */
  updateOwnerOperatorData(xOwnerOperatorDataId: string, xOwnerOperatorData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.put<any>(`${this.urlOwnerOperatorModuleService}/${settingConfig.API_OWNER_OPERATOR_URL_SEGMENT}/owner/operator/data/${xOwnerOperatorDataId}`, xOwnerOperatorData, { headers })
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }



  /**
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   * @description Método responsável por RECUPERAR BY ID os dados relacionados ao módulo: OWNER OPERATOR DATA.
   * 
   * @param xOperatorOwnerDataId
   * @returns 
   */
  getOwnerOperatorDataById(xOwnerOperatorDataId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlOwnerOperatorModuleService}/${settingConfig.API_OWNER_OPERATOR_URL_SEGMENT}/owner/operator/data/${xOwnerOperatorDataId}`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-11-14
   * @version 1.0.0
   * 
   * @description 
   *   - Verifica se um Nome Completo existe cadastrado no Sistema.
   * 
   * @param (string) Nome Completo que será verificado se existe no Sistema. 
   * @returns (Observable<any>) Retorna um Observable com a resposta da requisição.
   */
  public checkIfHasFullNameValue(xFieldValue: string): Observable<any> {
    return this._http.get<any>(`${this.urlOwnerOperatorModuleService}/${settingConfig.API_OWNER_OPERATOR_URL_SEGMENT}/owner/operator/data/full/name/${xFieldValue}`)
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-11-09
   * @version 1.0.0
   * 
   * @description Verifica se um determinado valor sá existe na Base de Dados.
   * 
   * @param xDocumentType 
   * @param xFieldValue 
   * @returns 
   */
  public checkIfHasDocumentValue(xDocumentType: string, xFieldValue: string): Observable<any> {
    return this._http.get<any>(`${this.urlOwnerOperatorModuleService}/${settingConfig.API_OWNER_OPERATOR_URL_SEGMENT}/owner/operator/data/document/${xDocumentType}/${xFieldValue}`)
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description Método responsável por APAGAR a Folha de Peso e Balanceamento da Aeronave.
   * 
   * @param xOwnerOperatorId 
   * @param objWeightBalanceSheet 
   * @returns 
   */
  deleteOwnerOperatorData(xIdOwnerOperatorData: string, xIdWeightBalanceSheet: any): Observable<any> {
    return this._http.delete<any>(`${this.urlOwnerOperatorModuleService}/${settingConfig.API_OWNER_OPERATOR_URL_SEGMENT}/owner/operator/data/${xIdOwnerOperatorData}/${xIdWeightBalanceSheet}`)
      .pipe(
        catchError((xError) => _handleError(xError))
      )
  }
  //#endregion
}
