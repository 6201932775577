//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { InputDdiDddPhoneComponent } from '../../../../../../_library/layouts/components/primeng/form/input-ddi-ddd-phone/input-ddi-ddd-phone.component';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsBasic } from '../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../../_library/interfaces/IDropDownOptionsIcon';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../../_library/definitions/ButtonAction';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAircraftCountries } from '../../../../../../_library/static_options/staticOptionsAircraftCountries';
import { staticOptionsEntityType } from '../../../../../../_library/static_options/staticOptionsEntityType';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-dialog-form-owner-operator',
  standalone: true,
  imports: [
    CommonModule,
    InputDdiDddPhoneComponent,
    PrimengComponentsModule
  ],
  templateUrl: './dialog-form-owner-operator.component.html'
})
export class DialogFormOwnerOperatorComponent implements OnInit, OnChanges {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() showDialog!: boolean;
  @Input() typeDialog!: string;
  //#endregion

  //#region "|--- OUTPUT ---|"
  @Output() actionDialogOwnerOperatorForm = new EventEmitter<string>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  optionsAircraftCountries!: IDropDownOptionsCountry[];
  optionsEntityType!: IDropDownOptionsIcon[];

  buttonActions!: any;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
  }

  // Método que é chamado sempre que houver alterações nos inputs do componente.
  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['formComponent']) {
      // ATUALIZAR OS PARÂMETROS DO CHART CONFORME AS MUDANÇAS REALIZADA NO FORMULÁRIO.
      if (this.formComponent) {
        this._initControlsResponsibleContact()
      }
    }
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.showDialog = this.showDialog ?? false;
    this.buttonActions = BUTTON_ACTION;

    this.optionsAircraftCountries = staticOptionsAircraftCountries;
    this.optionsEntityType = staticOptionsEntityType
  }

  private _initControlsResponsibleContact(): void {
    const objFormBuilder = new FormBuilder();

    // Como vai existir somente um único contato, pode criar um unico registro que atende a necessidade.
    this.formComponent.controls.company_responsible.controls.contacts.controls.push(
      {
        title: new FormControl<string>("Contato Responsável"),
        type: new FormControl<string>('mobile'),
        ddi: new FormControl<string>('+55'),
        ddd: new FormControl<string>(''),
        number: new FormControl<string>(''),
        normalized: new FormControl<string>(''),
        is_whatsapp: new FormControl<boolean>(true),
        is_telegram: new FormControl<boolean>(true),
        observation: new FormControl<string>(''),
        receive_news: new FormControl<boolean>(true)
      });

    // Acrescenta o E-mail do Responsável
    this.formComponent.controls.company_responsible.controls.contacts.controls.push(
      {
        title: new FormControl<string>("E-mail do Responsável"),
        type: new FormControl<string>('email'),
        contact: new FormControl<string>(''),
        observation: new FormControl<string>(''),
        receive_news: new FormControl<boolean>(true)
      });    
  }

  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Método que emite o evento de clique no botão.
   * 
   * @param event 
   */
  callbackClickButton(xEvent: any): void {
    this.actionDialogOwnerOperatorForm.emit(xEvent);
  }
  //#endregion
}
