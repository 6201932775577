//#region "|--- INTERFACE ---|"
import { IDropDownOptionsIcon } from '../interfaces/IDropDownOptionsIcon';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-24
 * @version 1.0.0
 * 
 * @description Opções estáticas para o tipo de entidade.
 */
export const staticOptionsEntityType: IDropDownOptionsIcon[] = [
    { label: 'EMPRESA', value: 'company', icon: 'fa-solid fa-industry' },
    { label: 'PESSOA', value: 'person', icon: 'fa-solid fa-person' },
]