//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { DIALOG_WAITING_TYPE } from '../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-app-platform--waiting-dialog',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './waiting-dialog.component.html'
})
export class WaitingDialogComponent implements OnInit {
  //#region "|--- INPUTS ---|"  
  @Input() typeWaitingDialog!: string;

  @Input() set showWaitingDialog(xValue: boolean) {

    if (xValue != undefined) {
      this.isToShowDialog = xValue ?? false;
    }
  };
  //#endregion

  //region "|--- PROPERTIES ---|"
  public isToShowDialog!: boolean;
  public typeLayout!: string
  public dialogWaitingType!: any;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables()
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.typeLayout = this.typeWaitingDialog ?? 'default';
    this.dialogWaitingType = DIALOG_WAITING_TYPE;
  }
  //#endregion
}
