//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// ***** COMPONENTS ***** //
import { HandlerErrorComponent } from '../_handle_error/_handler_error.component';

// ***** HANDLER ***** //
import { hasControlErrors } from '../_methods/_controls-methods';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-input-text',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    HandlerErrorComponent,
    ReactiveFormsModule
  ],
  templateUrl: './input-text.component.html'
})
export class InputTextComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;  
  @Input() idField!: string;
  @Input() groupRightText!: string;
  @Input() groupLeftText!: string;
  @Input() groupRightIcon!: string;
  @Input() groupLeftIcon!: string;
  @Input() label!: string;
  @Input() onBlurMethod!: any; // Método que será executado quando o campo perder o foco.
  @Input() typeInput!: string;
  @Input() formComponent!: any; // SERÁ USADO SOMENTE COM ONBLUER
  @Input() isLowerCase!: boolean;
  @Input() toolTip?: string;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: number;

  showLabel = true;
  showLabelBold = false;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;

    this.onBlurMethod = this.onBlurMethod === undefined ? () => { } : this.onBlurMethod;
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Método para colocar o conteúdo em letra maiúscula.
   * 
   * @abstract Por default sempre vai ser maiúscula.
   * 
   * @param event 
   */
  transformLetterCase(event: Event): void {
    const input = event.target as HTMLInputElement;

    if(this.isLowerCase){
      input.value = input.value.toLowerCase();
    } else {
      input.value = input.value.toUpperCase();
    }

    this.control.setValue(input.value, { emitEvent: false });
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   * 
   */
  showErrors(): void {
    return hasControlErrors(this.control, "input");
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo, com base no idField.
   * 
   * @returns 
   */
  getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }

  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   * @description Verifica se o campo possui um grupo à esquerda.
   * 
   * @returns 
   */
  hastLeftGroup(): boolean {
    return this.groupLeftText !== undefined || this.groupLeftIcon !== undefined;
  }

  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   *  @description Verifica se o campo possui um grupo à direita.
   * 
   * @returns 
   */
  hastRightGroup(): boolean {
    return this.groupRightText !== undefined || this.groupRightIcon !== undefined;
  }

   /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna o tipo do campo Input.
   * 
   * @returns 
   */
   getTypeInput(): string {
    return this.typeInput ? this.typeInput : "text";
  }
  //#endregion
}
