//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Injectable } from '@angular/core';

// ***** RXJS *****
import { Observable, of } from 'rxjs';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IUserConfigsSinglePageApplication } from '../../../_library/interfaces/IUserConfigs';
import { IUserConfigsHomePage } from '../../../_library/interfaces/IUserConfigs';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class UserConfigService {

  constructor() { }

  getUserConfigSinglePageApplication(): Observable<IUserConfigsSinglePageApplication> {
    const userConfig: IUserConfigsSinglePageApplication = {
      "top_header": "top-header-01",
      "top_horizontal_menu": "top-horizontal-menu-01",
      "layout_frame": "horizontal-basic-01",
      "color_schema": "light",
      "theme": "fly-solutions"
    }

    return of(userConfig);
  }

  getUserConfigHomepage(): Observable<IUserConfigsHomePage> {
    const userConfig: IUserConfigsHomePage = {
      "layout_frame": "home-page-01",
      "color_schema": "light",
      "theme": "fly-solutions"
    }

    return of(userConfig);
  }
}
