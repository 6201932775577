//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções  
 */
export const staticOptionsCgComponentsConstant: IDropDownOptionsBasic[] = [
    { label: 'CÍCLICO', value: 'cyclic' },
    { label: 'COLETIVO', value: 'collective' },
    { label: 'PEDAL', value: 'pedal' },
    { label: 'PORTA', value: 'door' },
    { label: 'OUTROS', value: 'others' }
]