<section class="cls-component-tab-data">
     <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
          [expandIcon]="'fa-solid fa-chevron-up'">
          <ng-template pTemplate="header">
               <div class="cls-component-panel-default-header">
                    <div class="cls-component-panel-default-header-title">
                         <h2>
                              <i class="fa-solid fa-bolt"></i>
                              <span> Lista de Fichas de Peso & Balanceamento</span>
                         </h2>
                    </div>
               </div>
          </ng-template>
          <ng-template pTemplate="content">
               <p-table [value]="this.arrayWeighBalanceSheetListByAircraft" [tableStyle]="{ 'min-width': '50rem' }"
                    [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Página {first} de {last}." [breakpoint]="'768px'"
                    responsiveLayout="stack" class="cls-component-table-panel-list"
                    styleClass="p-datatable-gridlines p-datatable-striped">
                    <ng-template pTemplate="caption">
                         <div class="cls-component-table-panel-list-caption">
                              <div class="cls-component-table-panel-list-caption-left">
                                   Total:
                                   {{this.arrayWeighBalanceSheetListByAircraft ? this.arrayWeighBalanceSheetListByAircraft.length : 0 }}
                              </div>
                              <div class="cls-component-table-panel-list-caption-right">
                                   <div>
                                        <p-button styleClass="cls-component-button" label="CLONAR"
                                             icon="fa-solid fa-clone" severity="secondary"
                                             (click)="callbackTableWeightBalanceClickButton(this.buttonActions.OPEN_DIALOG)" />
                                   </div>
                                   <div>
                                        <p-button styleClass="cls-component-button" label="NOVA" icon="fa-solid fa-plus"
                                             severity="secondary"
                                             (click)="callbackTableWeightBalanceClickButton(this.buttonActions.NEW)" />
                                   </div>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                         <tr>
                              <th style="width:15%;">Nome</th>
                              <th style="width:50%">Descrição</th>
                              <th style="width:10%">Código ICAO</th>
                              <th style="width:10%">Modelo Aeronave</th>
                              <th style="width:15%;"></th>
                         </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                         <tr>
                              <td>{{ itemArray.header_data.name }}</td>
                              <td>{{ itemArray.header_data.description_config }}</td>
                              <td>{{ itemArray.header_data.aircraft_icao_code }}</td>
                              <td>{{ itemArray.header_data.aircraft_model }}</td>
                              <td>
                                   <div class="cls-component-table-page-list-td-button">
                                        <p-button icon="fa-solid fa-clone" [rounded]="true" [outlined]="true"
                                             severity="help"
                                             (onClick)="callbackItemClickButton(this.buttonActions.CLONE, itemArray.weight_balance_data_id)" />
                                        <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true"
                                             severity="info"
                                             (onClick)="callbackItemClickButton(this.buttonActions.EDIT, itemArray.weight_balance_data_id)" />
                                        <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true"
                                             [outlined]="true"
                                             (onClick)="callbackItemClickButton(this.buttonActions.DELETE, itemArray.weight_balance_data_id)" />
                                   </div>
                              </td>
                         </tr>
                    </ng-template>
               </p-table>
          </ng-template>
     </p-panel>
     <p-dialog [modal]="true" [(visible)]="this.bolShowCloneDialog" [style]="{ width: '100rem', height: '50rem'}">
          <ng-template pTemplate="headless">
               <p-panel class="cls-component-panel-default">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span> Folhas de Peso e Balanceamento Para Clonar</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <p-table [value]="this.arrayWeighBalanceSheetListAll" [tableStyle]="{ 'min-width': '50rem' }"
                              [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 20]"
                              [showCurrentPageReport]="true" currentPageReportTemplate="Página {first} de {last}."
                              [breakpoint]="'768px'" responsiveLayout="stack" class="cls-component-table-panel-list">
                              <ng-template pTemplate="caption">
                                   <div class="cls-component-table-panel-list-caption">
                                        <div class="cls-component-table-panel-list-caption-left">
                                             Total:
                                             {{this.arrayWeighBalanceSheetListAll ? this.arrayWeighBalanceSheetListAll.length : 0 }}
                                        </div>
                                        <div class="cls-component-table-panel-list-caption-right">

                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="header">
                                   <tr>
                                        <th style="width:10%">Aeronaves</th>
                                        <th style="width:10%">Código ICAO</th>
                                        <th style="width:10%">Modelo Aeronave</th>
                                        <th style="width:15%;">Nome</th>
                                        <th style="width:40%">Descrição</th>
                                        <th style="width:15%;"></th>
                                   </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                   <tr>
                                        <td>{{ this.getAllAircraftMarks(itemArray.belong_to_aircraft) }}</td>
                                        <td>{{ itemArray.header_data.aircraft_icao_code }}</td>
                                        <td>{{ itemArray.header_data.aircraft_model }}</td>
                                        <td>{{ itemArray.header_data.name }}</td>
                                        <td>{{ itemArray.header_data.description_config }}</td>                                                                                
                                        <td>
                                             <div class="cls-component-table-page-list-td-button">
                                                  <p-button icon="fa-solid fa-clone" [rounded]="true" [outlined]="true"
                                                       severity="help"
                                                       (onClick)="callbackItemClickButton(this.buttonActions.CLONE, itemArray.weight_balance_data_id)" />                                                  
                                             </div>
                                        </td>
                                   </tr>
                              </ng-template>
                         </p-table>
                    </ng-template>
               </p-panel>
               <div style="position:absolute; bottom:0; width:100%">
                    <p-toolbar class="cls-component-toolbar">
                         <div class="p-toolbar-group-start"></div>
                         <div class="p-toolbar-group-center"></div>
                         <div class="p-toolbar-group-end">
                              <p-button label="Sair" styleClass="cls-component-button" [rounded]="true"
                                   icon="fa-solid fa-right-from-bracket" severity="danger"
                                   (click)="this.bolShowCloneDialog = false" />
                         </div>
                    </p-toolbar>
               </div>
          </ng-template>
     </p-dialog>
</section>