<section class="cls-component-tab-data">
    <p-toolbar class="cls-component-toolbar">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <p-button label="Salvar Modificações" styleClass="cls-component-button" [rounded]="true"
                icon="fa-solid fa-save" severity="primary" class="mr-2"
                (click)="this.callbackClickButton(this.buttonActions.UPDATE)" />
            <p-button label="Salvar Novo" styleClass="cls-component-button" [rounded]="true" icon="fa-solid fa-save"
                severity="primary" class="mr-2" (click)="this.callbackClickButton(this.buttonActions.SAVE)" />
        </div>
    </p-toolbar>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span> Ficha de Peso e Balanceamento</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Configuração #BOLD#"
                                        [control]="this.formWeightBalanceFilled.controls.fk_weight_balance_data_id"
                                        [arrayOptions]="this.optionsWeightBalanceSheet">
                                    </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-show-input-data label="Tipo de Aeronave #BOLD#"
                                        [dataInput]="this.classModuleMethods.getAircraftTypeLabel(this.formWeightBalanceFilled.controls.header_data.controls.aircraft_type.value)">
                                    </lib-ui-primeng-show-input-data>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-show-input-data label="Modelo Aeronave #BOLD#"
                                        [dataInput]="this.formWeightBalanceFilled.controls.header_data.controls.aircraft_model.value">
                                    </lib-ui-primeng-show-input-data>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-show-input-data label="Código ICAO #BOLD#"
                                        [dataInput]="this.formWeightBalanceFilled.controls.header_data.controls.aircraft_icao_code.value">
                                    </lib-ui-primeng-show-input-data>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span> Balanceamento</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-7 xl:col-9">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Tripulação</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngFor="let item of this.formWeightBalanceFilled.controls.components_input_data.controls; let i = index">
                                        <ng-container
                                            *ngIf="item.controls.type_component.value === this.cgComponentType.PILOT_SEAT">
                                            <div class="cls-app-platform--component-card-weight-balance-entries">
                                                <div
                                                    class="cls-app-platform--component-card-weight-balance-entries-header">
                                                    <div>
                                                        <p-inputSwitch
                                                            [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i]"
                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                    </div>
                                                    <div>
                                                        <h2>{{item.controls.description.value}}</h2>
                                                    </div>
                                                </div>
                                                <div class="cls-app-platform--component-card-weight-balance-entries-content"
                                                    [ngClass]="{'cls-util-display-none': this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i] === false}">
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-left">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-buttons">
                                                            <div class="grid">
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="00kg/ 000,00lb" severity="danger"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'zero', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="50kg/ 110,23lb" severity="info"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'lightest', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="80kg/ 176,37lb" severity="warning"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'average', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="85kg/ 187,39lb" severity="help"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'heaviest', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-slider">
                                                            <p-slider
                                                                [formControl]="this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight"
                                                                class="w-full" [min]="0"
                                                                [max]="this.defineMaxWeight(this.formWeightBalanceFilled.controls.components_input_data.controls[i], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)">
                                                            </p-slider>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-right">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-main">
                                                            {{this.classLocalMethods.convertNumberToPtBr(this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value)}}{{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-secondary">
                                                            ({{this.classLocalMethods.convertNumberToPtBr(this.convertToOppositeWeightUnit(this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)) }}
                                                            {{this.getOppositeWeightUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> PAX</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngFor="let item of this.formWeightBalanceFilled.controls.components_input_data.controls; let i = index">
                                        <ng-container
                                            *ngIf="item.controls.type_component.value === this.cgComponentType.PAX_SEAT">
                                            <div class="cls-app-platform--component-card-weight-balance-entries">
                                                <div
                                                    class="cls-app-platform--component-card-weight-balance-entries-header">
                                                    <div>
                                                        <p-inputSwitch
                                                            [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i]"
                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                    </div>
                                                    <div>
                                                        <h2>{{item.controls.description.value}}</h2>
                                                    </div>
                                                </div>
                                                <div class="cls-app-platform--component-card-weight-balance-entries-content"
                                                    [ngClass]="{'cls-util-display-none': this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i] === false}">
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-left">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-buttons">
                                                            <div class="grid">
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="00kg/ 000,00lb" severity="danger"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'zero', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-child"
                                                                        label="50kg/ 110,23lb" severity="info"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'lightest', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person-dress"
                                                                        label="80kg/ 176,37lb" severity="warning"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'average', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="85kg/ 187,39lb" severity="help"
                                                                        (click)="setDefaultPaxValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'heaviest', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-slider">
                                                            <p-slider
                                                                [formControl]="this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight"
                                                                class="w-full" [min]="0"
                                                                [max]="this.defineMaxWeight(this.formWeightBalanceFilled.controls.components_input_data.controls[i], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)">
                                                            </p-slider>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-right">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-main">
                                                            {{this.classLocalMethods.convertNumberToPtBr(this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value)}}{{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-secondary">
                                                            ({{this.classLocalMethods.convertNumberToPtBr(this.convertToOppositeWeightUnit(this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)) }}
                                                            {{this.getOppositeWeightUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Carga Interna</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngFor="let item of this.formWeightBalanceFilled.controls.components_input_data.controls; let i = index">
                                        <ng-container *ngIf="item.controls.type_component.value === this.cgComponentType.LUGGAGE_RACK 
                                        || item.controls.type_component.value === this.cgComponentType.INTERNAL_LOAD 
                                        || item.controls.type_component.value === this.cgComponentType.BALLAST
                                        || item.controls.type_component.value === this.cgComponentType.STRETCHER
                                        || item.controls.type_component.value === this.cgComponentType.OTHERS">
                                            <div class="cls-app-platform--component-card-weight-balance-entries">
                                                <div
                                                    class="cls-app-platform--component-card-weight-balance-entries-header">
                                                    <div>
                                                        <p-inputSwitch
                                                            [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i]"
                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                    </div>
                                                    <div>
                                                        <h2>{{item.controls.description.value}}</h2>
                                                    </div>
                                                </div>
                                                <div class="cls-app-platform--component-card-weight-balance-entries-content"
                                                    [ngClass]="{'cls-util-display-none': this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i] === false}">
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-left">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-buttons">
                                                            <div class="grid">
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-suitcase-rolling"
                                                                        label="{{setDefaultWeightLabel('0', this.formWeightBalanceFilled.controls.components_input_data.controls[i] , this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}}"
                                                                        severity="danger"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], '0', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-suitcase-rolling"
                                                                        label="{{setDefaultWeightLabel('33', this.formWeightBalanceFilled.controls.components_input_data.controls[i] , this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}}"
                                                                        severity="info"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], '33', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-suitcase-rolling"
                                                                        label="{{setDefaultWeightLabel('66', this.formWeightBalanceFilled.controls.components_input_data.controls[i] , this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}}"
                                                                        severity="warning"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], '66', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-suitcase-rolling"
                                                                        label="{{setDefaultWeightLabel('100', this.formWeightBalanceFilled.controls.components_input_data.controls[i] , this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}}"
                                                                        severity="help"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], '100', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-slider">
                                                            <p-slider
                                                                [formControl]="this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight"
                                                                class="w-full" [min]="0"
                                                                [max]="this.defineMaxWeight(this.formWeightBalanceFilled.controls.components_input_data.controls[i], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)">
                                                            </p-slider>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-right">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-main">
                                                            {{this.classLocalMethods.convertNumberToPtBr(this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value)}}{{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-secondary">
                                                            ({{this.classLocalMethods.convertNumberToPtBr(this.convertToOppositeWeightUnit(this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)) }}
                                                            {{this.getOppositeWeightUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Carga Externa</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngFor="let item of this.formWeightBalanceFilled.controls.components_external_data.controls; let i = index">
                                        <ng-container *ngIf="item.controls.type_component.value === this.cgComponentType.LUGGAGE_RACK 
                                    || item.controls.type_component.value === this.cgComponentType.BASKET_LOAD 
                                    || item.controls.type_component.value === this.cgComponentType.EXTERNAL_LOAD 
                                    || item.controls.type_component.value === this.cgComponentType.HOIST_LOAD
                                    || item.controls.type_component.value === this.cgComponentType.BALLAST                                    
                                    || item.controls.type_component.value === this.cgComponentType.OTHERS">
                                            <div class="cls-app-platform--component-card-weight-balance-entries">
                                                <div
                                                    class="cls-app-platform--component-card-weight-balance-entries-header">
                                                    <div>
                                                        <p-inputSwitch
                                                            [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][i]"
                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                    </div>
                                                    <div>
                                                        <h2>{{item.controls.description.value}}</h2>
                                                    </div>
                                                </div>
                                                <div class="cls-app-platform--component-card-weight-balance-entries-content"
                                                    [ngClass]="{'cls-util-display-none': this.formWeightBalanceFilled.controls.matrixOfElements.value[1][i] === false}">
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-left">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-buttons">
                                                            <div class="grid">
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-basket-shopping"
                                                                        label="00kg/ 000,00lb" severity="danger"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_external_data.controls[i], '0', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-basket-shopping"
                                                                        label="{{setDefaultWeightLabel('33', this.formWeightBalanceFilled.controls.components_external_data.controls[i] , this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}}"
                                                                        severity="info"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_external_data.controls[i], '33', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-basket-shopping"
                                                                        label="{{setDefaultWeightLabel('66', this.formWeightBalanceFilled.controls.components_external_data.controls[i] , this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}}"
                                                                        severity="warning"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_external_data.controls[i], '66', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-basket-shopping"
                                                                        label="{{setDefaultWeightLabel('100', this.formWeightBalanceFilled.controls.components_external_data.controls[i] , this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}}"
                                                                        severity="help"
                                                                        (click)="setDefaultWeightValue(this.formWeightBalanceFilled.controls.components_external_data.controls[i], '100', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-slider">
                                                            <p-slider
                                                                [formControl]="this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.weight"
                                                                class="w-full" [min]="0"
                                                                [max]="this.defineMaxWeight(this.formWeightBalanceFilled.controls.components_external_data.controls[i], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)">
                                                            </p-slider>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-right">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-main">
                                                            {{this.classLocalMethods.convertNumberToPtBr(this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.weight.value)}}{{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-secondary">
                                                            ({{this.classLocalMethods.convertNumberToPtBr(this.convertToOppositeWeightUnit(this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)) }}
                                                            {{this.getOppositeWeightUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Removíveis</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngFor="let item of this.formWeightBalanceFilled.controls.components_constant_data.controls; let i = index">
                                        <ng-container *ngIf="item.controls.type_component.value === this.cgComponentType.CYCLIC
                                        || item.controls.type_component.value === this.cgComponentType.COLLECTIVE
                                        || item.controls.type_component.value === this.cgComponentType.PEDAL
                                        || item.controls.type_component.value === this.cgComponentType.DOOR                                   
                                        || item.controls.type_component.value === this.cgComponentType.OTHERS">
                                            <div class="cls-app-platform--component-card-weight-balance-entries">
                                                <div
                                                    class="cls-app-platform--component-card-weight-balance-entries-header">
                                                    <div>
                                                        <p-inputSwitch
                                                            [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][i]"
                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                    </div>
                                                    <div>
                                                        <h2>{{item.controls.description.value}}</h2>
                                                    </div>
                                                </div>
                                                <div class="cls-app-platform--component-card-weight-balance-entries-content"
                                                    [ngClass]="{'cls-util-display-none': this.formWeightBalanceFilled.controls.matrixOfElements.value[2][i] === false}">
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-left">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-buttons">
                                                            <div class="grid">
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="00kg/ 000,00lb" severity="danger"
                                                                        (click)="setDefaultValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'zero', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person"
                                                                        label="85kg/ 187,39lb" severity="help"
                                                                        (click)="setDefaultValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'heaviest', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-person-dress"
                                                                        label="80kg/ 176,37lb" severity="warning"
                                                                        (click)="setDefaultValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'average', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-child"
                                                                        label="50kg/ 110,23lb" severity="info"
                                                                        (click)="setDefaultValue(this.formWeightBalanceFilled.controls.components_input_data.controls[i], 'lightest', this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-slider">
                                                            <p-slider
                                                                [formControl]="this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight"
                                                                class="w-full" [min]="0"
                                                                [max]="this.defineMaxWeight(this.formWeightBalanceFilled.controls.components_input_data.controls[i], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)">
                                                            </p-slider>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-right">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-main">
                                                            {{this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value}}{{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-secondary">
                                                            ({{this.convertToOppositeWeightUnit(this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value) }}
                                                            {{this.getOppositeWeightUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)}})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Combustível</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngFor="let item of this.formWeightBalanceFilled.controls.components_fuel_data.controls; let i = index">
                                        <ng-container *ngIf="item.controls.type_component.value === this.cgComponentType.FUEL                                   
                                            || item.controls.type_component.value === this.cgComponentType.OTHERS">
                                            <div class="cls-app-platform--component-card-weight-balance-entries">
                                                <div
                                                    class="cls-app-platform--component-card-weight-balance-entries-header">
                                                    <div>
                                                        <p-inputSwitch
                                                            [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][i]"
                                                            (onChange)="callbackOnChangeSwitch($event)" />
                                                    </div>
                                                    <div>
                                                        <h2>{{item.controls.description.value}}</h2>
                                                    </div>
                                                </div>
                                                <div class="cls-app-platform--component-card-weight-balance-entries-content"
                                                    [ngClass]="{'cls-util-display-none': this.formWeightBalanceFilled.controls.matrixOfElements.value[3][i] === false}">
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-left">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-buttons">
                                                            <div class="grid">
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-gas-pump"
                                                                        label="25% Tank" severity="danger"
                                                                        (click)="setDefaultFuelValue(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i], '25', this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.max_weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-gas-pump"
                                                                        label="50% Tank" severity="help"
                                                                        (click)="setDefaultFuelValue(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i], '50', this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.max_weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-gas-pump"
                                                                        label="75% Tank" severity="warning"
                                                                        (click)="setDefaultFuelValue(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i], '75', this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.max_weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value)" />
                                                                </div>
                                                                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                                                    <p-button icon="fa-solid fa-gas-pump"
                                                                        label="Full Tank" severity="info"
                                                                        (click)="setDefaultFuelValue(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i], '100', this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.max_weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-slider">
                                                            <p-slider
                                                                [formControl]="this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight"
                                                                class="w-full" [min]="0"
                                                                [max]="this.defineMaxFuel(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i], this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value)">
                                                            </p-slider>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="cls-app-platform--component-card-weight-balance-entries-content-right">
                                                        <div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-fuel-input">
                                                            <div
                                                                class="cls-app-platform--component-card-weight-balance-entries-content-fuel-input-knob">
                                                                <p-knob
                                                                    [(ngModel)]="this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight.value"
                                                                    [min]="0"
                                                                    [max]="this.defineMaxFuel(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i], this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value)"
                                                                    valueTemplate="{{this.classLocalMethods.convertNumberToPtBr(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight.value)}} {{this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value}}" />
                                                            </div>
                                                            <div
                                                                class="cls-app-platform--component-card-weight-balance-entries-content-fuel-input-label-secondary">
                                                                ({{this.classLocalMethods.convertNumberToPtBr(this.converToOthersFuelUnit(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value, this.getOthersFuelUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, 0))) }}
                                                                {{this.getOthersFuelUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, 0)}})
                                                            </div>
                                                            <div
                                                                class="cls-app-platform--component-card-weight-balance-entries-content-fuel-input-label-tertiary">
                                                                ({{this.classLocalMethods.convertNumberToPtBr(this.converToOthersFuelUnit(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value, this.getOthersFuelUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, 1))) }}
                                                                {{this.getOthersFuelUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, 1)}})
                                                            </div>
                                                            <div
                                                                class="cls-app-platform--component-card-weight-balance-entries-content-fuel-input-label-quaternary">
                                                                ({{this.classLocalMethods.convertNumberToPtBr(this.converToOthersFuelUnit(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value, this.getOthersFuelUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, 2))) }}
                                                                {{this.getOthersFuelUnit(this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, 2)}})
                                                            </div>
                                                        </div>

                                                        <!--<div
                                                            class="cls-app-platform--component-card-weight-balance-entries-content-weight-main">
                                                            {{this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value}}{{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                        </div>
                                                        -->
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </p-panel>

                        </div>
                        <div class="field col-12 md:col-12 lg:col-5 xl:col-3">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Configuração</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <p-image src="app_platform/bel206.png" alt="" width="100%" [preview]="true" />
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Dados Voo</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-12">
                                            <lib-ui-primeng-drop-down label="Tipo Combustível #BOLD#"
                                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.fuel_type"
                                                [arrayOptions]="this.optionsFuelType">
                                            </lib-ui-primeng-drop-down>
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                            <lib-ui-primeng-input-time label="Tempo de Voo #BOLD#"
                                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.flight_eet">
                                            </lib-ui-primeng-input-time>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                            <lib-ui-primeng-input-dropdown-text label="Consumo #BOLD#"
                                                [controlInput]="this.formWeightBalanceFilled.controls.flight_data.controls.flight_fuel_rate"
                                                [controlDropdown]="this.formWeightBalanceFilled.controls.flight_data.controls.flight_fuel_rate_unit"
                                                [arrayOptions]="this.optionsFuelConsumption">
                                            </lib-ui-primeng-input-dropdown-text>
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                            <lib-ui-primeng-input-time label="Tempo de Taxi"
                                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.taxi_eet">
                                            </lib-ui-primeng-input-time>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                            <lib-ui-primeng-input-dropdown-text label="Consumo"
                                                [controlInput]="this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate"
                                                [controlDropdown]="this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate_unit"
                                                [arrayOptions]="this.optionsFuelConsumption">
                                            </lib-ui-primeng-input-dropdown-text>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-panel>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Calculo Matemático</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <p-table
                                        [value]="this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value"
                                        [tableStyle]="{ 'min-width': '50rem' }">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Descrição</th>
                                                <th>Peso
                                                    ({{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}})
                                                </th>
                                                <th>Braço Lon.
                                                    ({{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                </th>
                                                <th>Momento Lon.
                                                    ({{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                    x
                                                    {{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                </th>
                                                <th>Braço Lat.
                                                    ({{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                </th>
                                                <th>Momento Lat.
                                                    ({{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                    x
                                                    {{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-ItemArray>
                                            <tr>
                                                <td>{{ ItemArray.label }}</td>
                                                <td>{{ ItemArray.weight }}</td>
                                                <td>{{ ItemArray.longitudinal_arm }}</td>
                                                <td>{{ ItemArray.longitudinal_moment }}</td>
                                                <td>{{ ItemArray.lateral_arm }}</td>
                                                <td>{{ ItemArray.lateral_moment }}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </ng-template>
                            </p-panel>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-6">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> CG Envelope LONGITUDINAL</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div
                                        *ngIf="this.formWeightBalanceFilled.controls.chart_longitudinal_data.controls[0]">
                                        <sunrise-app-platform--chart-cg-limits
                                            [objChartCGLimits]="this.formWeightBalanceFilled.controls.chart_longitudinal_data.controls[0].getRawValue()">
                                        </sunrise-app-platform--chart-cg-limits>
                                    </div>
                                </ng-template>
                            </p-panel>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-6">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> CG Envelope LATERAL</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div *ngIf="this.formWeightBalanceFilled.controls.chart_lateral_data.controls[0]">
                                        <sunrise-app-platform--chart-cg-limits
                                            [objChartCGLimits]="this.formWeightBalanceFilled.controls.chart_lateral_data.controls[0].getRawValue()">
                                        </sunrise-app-platform--chart-cg-limits>
                                    </div>
                                </ng-template>
                            </p-panel>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>
</section>