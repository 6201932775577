//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções  
 */
export const staticOptionsCgComponentsExternal: IDropDownOptionsBasic[] = [
    { label: 'BAGAGEIRO', value: 'luggage_rack' },
    { label: 'CARGA BASKET', value: 'basket_load' },
    { label: 'CARGA EXTERNA', value: 'external_load' },
    { label: 'CARGA HOIST', value: 'hoist_load' },
    { label: 'LASTRO', value: 'ballast' },
    { label: 'OUTROS', value: 'others' }
]