//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções  
 */
export const staticOptionsCgComponentsInput: IDropDownOptionsBasic[] = [
    { label: 'BANCO PILOTO', value: 'pilot_seat' },
    { label: 'BANCO PAX', value: 'pax_seat' },
    { label: 'BAGAGEIRO', value: 'luggage_rack' },
    { label: 'CARGA INTERNA', value: 'internal_load' },    
    { label: 'LASTRO', value: 'ballast' },
    { label: 'MACA', value: 'stretcher' },
    { label: 'OUTROS', value: 'others' }
]