const pageCode = "JRANPHFR";

//#region "|--- IMPORT MODULES ---|"
import { environment } from "src/environment/environment";
//#endregion

// SOBRE CONFIGURAÇÃO DE AMBIENTE.
//https://dev.to/nikosanif/using-shared-settings-between-multiple-environments-in-angular-1d0e

class SettingConfig {
    public IS_PRODUCTION_ENV: boolean | undefined;
    public ANGULAR_ENV: string | undefined;
    public WEB_SERVICE_NAME: string | undefined;
    public PROJECT_NAME: string | undefined;
    public FRONT_END_VERSION_BUILD: string | undefined;
    public BACK_END_VERSION_BUILD: string | undefined;
    public API_PROTOCOL: string | undefined;
    public API_HOST: string | undefined;

    public API_URL_PORT: string | undefined;
    public API_AIM_PORT: string | undefined;
    public API_CANTTECH_PORT: string | undefined;
    public API_AUTHENTICATION_PORT: string | undefined;
    public API_AIRCRAFT_PORT: string | undefined;
    public API_CREW_PORT: string | undefined;
    public API_FLIGHT_PORT: string | undefined;
    public API_PAX_PORT: string | undefined;
    public API_WEIGHT_BALANCE_PORT: string | undefined;


    public API_API_URL_SEGMENT: string | undefined;
    public API_AIM_URL_SEGMENT: string | undefined;
    public API_CANTTECH_URL_SEGMENT: string | undefined;
    public API_AUTHENTICATION_URL_SEGMENT: string | undefined;
    public API_AIRCRAFT_URL_SEGMENT: string | undefined;
    public API_CREW_URL_SEGMENT: string | undefined;
    public API_FLIGHT_URL_SEGMENT: string | undefined;
    public API_PAX_URL_SEGMENT: string | undefined;
    public API_WEIGHT_BALANCE_URL_SEGMENT: string | undefined;

    public API_PATH: string | undefined;
    public SHOW_LOG_EXCEPTION: boolean | undefined;
    public NO_IMAGE_AVAILABLE: string | undefined;
    public TOAST_LIFE: number | undefined;
    public TOAST_KEY: string | undefined;
    public WAITING_DIALOG_LIFE: number | undefined;

    constructor() {
        this.IS_PRODUCTION_ENV = environment.production;
        this.ANGULAR_ENV = environment.angular_env || "development";
        this.WEB_SERVICE_NAME = environment.web_service_name || "";
        this.PROJECT_NAME = environment.project_name || "";
        this.FRONT_END_VERSION_BUILD = environment.front_end_version_build || "0.0.0";
        this.BACK_END_VERSION_BUILD = environment.backs_end_version_build || "0.0.0";
        this.API_PROTOCOL = environment.api_protocol || "http";
        this.API_HOST = environment.api_host || "api-web.XXXXXXXXXX.app.br";

        this.API_URL_PORT = environment.api_url_port || "443";
        this.API_AIM_PORT = environment.api_aim_port || "443";
        this.API_CANTTECH_PORT = environment.api_canttech_port || "443";
        this.API_AUTHENTICATION_PORT = environment.api_authentication_port || "443";
        this.API_AIRCRAFT_PORT = environment.api_aircraft_port || "443";
        this.API_CREW_PORT = environment.api_crew_port || "443";
        this.API_FLIGHT_PORT = environment.api_flight_port || "443";
        this.API_PAX_PORT = environment.api_pax_port || "443";
        this.API_WEIGHT_BALANCE_PORT = environment.api_weight_balance_port || "443";

        this.API_API_URL_SEGMENT = environment.api_url_segment || "mod_api";
        this.API_AIM_URL_SEGMENT = environment.api_aim_url_segment || "mod_aim";
        this.API_CANTTECH_URL_SEGMENT = environment.api_canttech_url_segment || "mod_ctc";
        this.API_AUTHENTICATION_URL_SEGMENT = environment.api_authentication_url_segment || "mod_auth";
        this.API_AIRCRAFT_URL_SEGMENT = environment.api_aircraft_url_segment || "mod_acf";
        this.API_CREW_URL_SEGMENT = environment.api_crew_url_segment || "mod_crew";
        this.API_FLIGHT_URL_SEGMENT = environment.api_flight_url_segment || "mod_flt";
        this.API_PAX_URL_SEGMENT = environment.api_pax_url_segment || "mod_pax";
        this.API_WEIGHT_BALANCE_URL_SEGMENT = environment.api_weight_balance_url_segment || "mod_wbl";

        this.API_PATH = environment.api_path || "api/v1";
        this.SHOW_LOG_EXCEPTION = environment.show_log_exception || false;
        this.NO_IMAGE_AVAILABLE = environment.no_image_available || "no_image_available.jpg";
        this.TOAST_LIFE = environment.toast_life || 3000;
        this.TOAST_KEY = environment.toast_key || "toast-basic";
        this.WAITING_DIALOG_LIFE = environment.waiting_dialog_life || 3000;
    }

    public validateConfig(): void {
        // Busca uma Key ou Value em uym determinado objeto.
        for (const [key, value] of Object.entries(this)) {
            if (value === undefined) {
                throw new Error(`Configuration ${key} is undefined`);
            }
        }
    }
}

export const settingConfig: SettingConfig = new SettingConfig();
