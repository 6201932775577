//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleIamAppService } from '../../_services/module-iam-app/module.iam.app.service';
//#endregion

@Injectable()
export class AuthenticationAppInterceptor implements HttpInterceptor {
  constructor(
    private _moduleIamAppService: ModuleIamAppService
  ) {}

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Método responsável por interceptar a requisição e adicionar o token de autenticação.
   * 
   * @abstract Este método foi adicionado ao appConfig.js
   * 
   * @param req 
   * @param next 
   * @returns 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenApp = this._moduleIamAppService.getAppToken();

    ///console.log("Intercepted URL:", req.url);

    if (tokenApp) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${tokenApp}`
        }
      });
    }

    return next.handle(req);
  }
}