//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

export function initLoginForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        user: [
            null,
            [
                Validators.required
            ]
        ],
        password: [
            null,
            [
                Validators.required
            ]
        ]
    });
}