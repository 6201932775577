//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-10-12
 * @version 1.0.0
 * 
 * @description Opções tipos de endereços.
 */
export const staticOptionsAddressType: IDropDownOptionsBasic[] = [   
    { label: 'RESIDENCIAL', value: 'residential' },
    { label: 'TRABALHO', value: 'work' },
    { label: 'ESCRITÓRIO', value: 'office' },
    { label: 'OUTRO', value: 'other' }
]