//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';
import { SelectItemGroup } from 'primeng/api';

// ***** CLASSES ***** //
import { CoordinatesHandlerClass } from '../../../../../../../_classes/CoordinatesHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';

// ***** COMPONENT ***** //
import { AviationToolsHandlerClass } from '../../../../../../../_classes/AviationToolsHandlerClass';
import { AerodromeInputFieldsetComponent } from '../../../../../../../_components/layout/aerodrome-input-fieldset/aerodrome-input-fieldset.component';
import { GoogleMapsPreviewComponent } from '../../../../../../../_components/layout/google-maps-preview/google-maps-preview.component';
import { TimeZoneComponent } from '../../../../../../../_components/layout/time-zone/time-zone.component';
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsFlightPlanRules } from '../../../../../../../../_library/static_options/staticOptionsFlightPlanRules';
import { staticOptionsFlightType } from '../../../../../../../../_library/static_options/staticOptionsFlightType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-flight-request',
  standalone: true,
  imports: [
    AerodromeInputFieldsetComponent,
    CommonModule,
    GoogleMapsPreviewComponent,
    PrimengComponentsModule,
    TimeZoneComponent,
    TitleFormTopicComponent
  ],
  templateUrl: './tab-flight-request.component.html'
})
export class TabFlightRequestComponent implements OnInit, OnChanges {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() arrayAllAircraft!: IDropDownOptionsIcon[];
  @Input() arrayOptionsAircraft!: IDropDownOptionsIcon[];
  @Input() arrayAllOperatorByAircraft!: any;
  @Input() arrayOptionsOperator!: SelectItemGroup[];
  @Input() arrayOptionsCrew!: SelectItemGroup[];

  @Input() set utcDateTime(xValue: string) {
    if (xValue != undefined && xValue != null && xValue != '') {
      const tempArray = xValue.split('###');

      this.currentUtcDateTime = tempArray[0].trim();
      this.originUtcDateTime = tempArray[1].trim();
    }
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabFlightRequest = new EventEmitter<string>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public actionType!: any;

  // ***** CLASSES ***** //
  public classAviationToolsHandler !: any;
  public classInternationalization!: any;

  // ***** OPTIONS ***** //
  public optAllAircraftByUser!: IDropDownOptionsIcon[];
  public optPilotsByUser!: SelectItemGroup[];
  public optOperatorByAircraft!: SelectItemGroup[];
  public optSecondPilotInCommand!: SelectItemGroup[];

  // ***** DROP-DOWN OPTIONS ***** //
  public optFlightPlanRules!: IDropDownOptionsBasic[];
  public optFlightType!: IDropDownOptionsBasic[];

  // ***** OTHERS ***** //
  public currentUtcDateTime!: string;
  public originUtcDateTime!: string;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
  }

  // Método que é chamado sempre que houver alterações nos inputs do componente.
  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['arrayOptionsAircraft']) {
      // Handle the changes here
      this.optAllAircraftByUser = this.arrayOptionsAircraft;
    }

    if (xChanges['arrayOptionsOperator']) {
      // Handle the changes here
      this.optOperatorByAircraft = this.arrayOptionsOperator
      ///this.arrayOptionsOperator;
    }

    if (xChanges['arrayOptionsCrew']) {
      // Handle the changes here
      this.optPilotsByUser = this.arrayOptionsCrew;
    }
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {    
    this.actionType = ACTION_TYPE;

    this.classAviationToolsHandler = AviationToolsHandlerClass;
    this.classInternationalization = InternationalizationHandler;

    this.optFlightPlanRules = staticOptionsFlightPlanRules;
    this.optFlightType = staticOptionsFlightType;
  }
  //#endregion


  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-15
  * @version 1.0.0
  * 
  * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
  * 
  * @abstract Os botões estão no Toolbar do formulário do REGISTRO AERONÁUTICO.
  */
  onClickToolbarTabFlightRequest(xButtonAction: string): void {
    this.actionTabFlightRequest.emit(xButtonAction);
  }
  //#endregion


  //#region "|--- ACTION METHODS ---|"
  public onBlurCheckFieldValue(): any {
    return async (xEvent: any, xComponentContext: any) => {
      const tempIcao = xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.icao`).value;
      const tempFkAdBscLst = xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.fk_ad_bsc_data_id`).value;

      if (tempFkAdBscLst === null && tempIcao !== "ZZZZ" && tempIcao !== "" && tempIcao !== null) {
        if (CoordinatesHandlerClass.coordinateType(tempIcao.toUpperCase()) !== null) {
          const tempValue = CoordinatesHandlerClass.automaticConvertToDecimal(tempIcao);
          const tempCoord = [tempValue[0], tempValue[1]];

          xComponentContext.originalCoordinates = tempIcao;

          xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.coord_dc`).setValue(tempCoord, { emitEvent: false });
          xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.icao`).setValue("ZZZZ");
        }
      }
    }
  }

  public onFocusCheckFieldValue(): any {
    return async (xEvent: any, xComponentContext: any) => {
      const tempIcao = xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.icao`).value;
      const tempFkAdBscLst = xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.fk_ad_bsc_data_id`).value;
      const tempCoord = xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.coord_dc`).value;

      if (tempFkAdBscLst === null && tempIcao === "ZZZZ" && tempCoord !== null) {
        xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.icao`).setValue(xComponentContext.originalCoordinates, { emitEvent: false });
        if (xComponentContext.originalCoordinates !== "") {
          xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.icao`).setValue(xComponentContext.originalCoordinates, { emitEvent: false });
          xComponentContext.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${xComponentContext.index}.coord_dc`).setValue(null, { emitEvent: false });
        }
      }
    }
  }
  //#endregion
}
