<section class="cls-component-tab-data">
    <p-toolbar class="cls-component-toolbar">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container *ngIf="this.formComponent.controls.flight_data_id.value !== null">
                <p-button label="Novo" styleClass="cls-component-button" [rounded]="true" icon="fa-solid fa-plus"
                    severity="secondary" class="mr-2" (click)="callbackClickButton(this.buttonActions.NEW)" />
                <p-button label="Salvar Modificações" styleClass="cls-component-button" [rounded]="true"
                    icon="fa-solid fa-save" severity="secondary" class="mr-2"
                    (click)="callbackClickButton(this.buttonActions.UPDATE)" />
            </ng-container>
            <ng-container *ngIf="this.formComponent.controls.flight_data_id.value === null">
                <p-button label="Salvar Novo" styleClass="cls-component-button" [rounded]="true" icon="fa-solid fa-save"
                    severity="secondary" class="mr-2" (click)="callbackClickButton(this.buttonActions.SAVE)" />
            </ng-container>
            <p-button label="Sair" styleClass="cls-component-button" [rounded]="true"
                icon="fa-solid fa-right-from-bracket" severity="danger"
                (click)="callbackClickButton(this.buttonActions.EXIT)" />
        </div>
    </p-toolbar>
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Aeronave e Tripulação </span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down-icon label="Aeronave #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.aircraft_data.controls.fk_aircraft_data_id"
                                        [arrayOptions]="this.optionsAllAircraftFromUser">
                                    </lib-ui-primeng-drop-down-icon>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Operador #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.aircraft_data.controls.owner_operator_id"
                                        [arrayOptions]="this.optionsOperatorFromAircraft">
                                    </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Primeiro Comando #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.crew_data.controls[0].controls.crew_data_id"
                                        [arrayOptions]="this.optionsAllPilotsFromUser">
                                    </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Segundo Comando #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.crew_data.controls[1].controls.crew_data_id"
                                        [arrayOptions]="this.optionsSecondPilotInCommand">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Dados do Voo</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">                            
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-calendar label="Data EOBT (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.eobt_date_time.controls.date_zulu">
                                    </lib-ui-primeng-input-calendar>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-time label="Hora EOBT (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.eobt_date_time.controls.time_zulu">
                                    </lib-ui-primeng-input-time>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-time label="Total EET #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.eet_time">
                                    </lib-ui-primeng-input-time>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-drop-down label="Regra de Voo #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.flight_rule"
                                        [arrayOptions]="this.optionsFlightPlanRules">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-calendar label="Data Pouso (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.landing_date_time.controls.date_zulu">
                                    </lib-ui-primeng-input-calendar>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-time label="Hora Pouso (Z) #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.landing_date_time.controls.time_zulu">
                                    </lib-ui-primeng-input-time>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-text label="Nível de Voo #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.flight_level">
                                    </lib-ui-primeng-input-text>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                    <lib-ui-primeng-input-text label="Veloc. Cruzeiro #BOLD#"
                                        [control]="this.formComponent.controls.flight_request.controls.cruise_speed">
                                    </lib-ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                    <div class="cls-app-platform--component-field-utc">
                                        <div class="cls-app-platform--component-field-utc-label">
                                            <i class="fa-solid fa-clock"></i> DATA E HORA ATUAL:
                                        </div>
                                        <div class="cls-app-platform--component-field-utc-datetime">
                                            {{ this.currentUtcDateTime }}
                                            <ng-container *ngIf="this.originUtcDateTime === 'local'">
                                                <i class="fa-solid fa-computer"></i>
                                            </ng-container>
                                            <ng-container *ngIf="this.originUtcDateTime === 'web'">
                                                <i class="fa-solid fa-cloud"></i>
                                            </ng-container>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span> Aeródromos</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Partida #BOLD#"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[0]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Destino #BOLD#"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[1]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Alt 1 #BOLD#"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[2]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                                <div class="field col-12 md:col-6 lg:col-6 xl:col-3">
                                    <sunrise-app-platform--aerodrome-input-fieldset labelInput="AD Alt 2"
                                        [controlInput]="this.formComponent.controls.flight_request.controls.aerodrome_list.controls[3]">
                                    </sunrise-app-platform--aerodrome-input-fieldset>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>
    <!--<div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Prévia do Voo</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-5 xl:col-5">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                     <div class="cls-component-panel-form-fieldset-header">
                                          <div class="cls-component-panel-form-fieldset-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> MAPA</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                     
                                </ng-template>
                           </p-panel>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-7 xl:col-7">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                     <div class="cls-component-panel-form-fieldset-header">
                                          <div class="cls-component-panel-form-fieldset-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> DADOS</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                     
                                </ng-template>
                           </p-panel>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </div>
    </div>-->
</section>