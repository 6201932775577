//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion

export function initDocumentsForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        array_id: [LocalMethodsHandlerClass.generateRandomString(6)],
        document_type:  [null],
        ni: [null]
    });
}
