<p-dialog 
    [modal]="true" 
    [(visible)]="this.bolShowErrorDialog" 
    position="top" 
    [style]="{ width: '30rem' }">
    <ng-template pTemplate="headless">
        <div class="cls-cmp-esp-login-error">
            <i class="fa-solid fa-triangle-exclamation"></i> {{this.loginError}}
        </div>
        <p-toolbar class="cls-cmp-esp-login-error-toolbar">
            <div class="p-toolbar-group-start"></div>
            <div class="p-toolbar-group-center">
            </div>
            <div class="p-toolbar-group-end">
                <p-button label="OK" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-check" severity="primary" (click)="this.onClickCloseDialog()" />
            </div>
        </p-toolbar>
    </ng-template>
</p-dialog>
<div class="cls-pge-login-form">
    <div class="cls-cmp-esp-login-input">
        <lib-ui-primeng-input-text label="E-mail / Usuário #BOLD#" iconRight="fa-solid fa-user"
            [control]="this.formLogin.controls.user">
        </lib-ui-primeng-input-text>
    </div>
    <div class="cls-cmp-esp-login-input">
        <lib-ui-primeng-input-password label="Senha #BOLD#" [control]="this.formLogin.controls.password">
        </lib-ui-primeng-input-password>
        <div class="cls-cmp-esp-login-input cls-cmp-esp-login-password-forgot">
            <!--<a href="#" class="forgot-password">Esqueceu a Senha?</a>-->
        </div>
    </div>
    <div class="cls-cmp-esp-login-input cls-cmp-esp-login-button">
        <p-button (click)="onClickButton(this.actionType.LOGIN)">
            <i class="fa-solid fa-right-to-bracket"></i> <span
                style="display: inline-block; margin-left: 0.5rem;">Entrar no Sistema</span>
        </p-button>
    </div>
    <div class="cls-cmp-esp-login-input cls-cmp-esp-login-sign-up">
        <!--<p class="signup-link">Ainda não tem conta? <a href="#">Registre-se</a></p>-->
    </div>
</div>