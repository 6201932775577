//#region "|--- INTERFACE ---|"
import { IDropDownOptionsIcon } from '../interfaces/IDropDownOptionsIcon';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const staticOptionsAircraftRules:IDropDownOptionsIcon[] =  [
    { label: 'VFR DIURNO', value: 'vfr_day', icon: 'fa-solid fa-sun' },
    { label: 'VFR NOTURNO', value: 'vfr_night', icon: 'fa-solid fa-moon' },
    { label: 'IFR', value: 'ifr', icon: 'fa-solid fa-cloud' },
]