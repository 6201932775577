//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { TreeNode } from 'primeng/api';

// ***** CLASS ***** //
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAccountService } from '../../../../../../../_services/module-account/module.account.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsColor } from '../../../../../../../../_library/interfaces/IDropDownOptionsColor';
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IDropDownOptionsBasic } from 'src/app/_library/interfaces/IDropDownOptionsBasic';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAllCountries } from '../../../../../../../../_library/static_options/staticOptionsAllCountries';
import { staticOptionsAccountStatus } from '../../../../../../../../_library/static_options/staticOptionsAccountStatus';
import { staticOptionsProfileType } from '../../../../../../../../_library/static_options/staticOptionsProfileType';
import { staticOptionsDocumentsPersonBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsPersonBr';
import { staticOptionsDocumentsCompanyBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsCompanyBr';
import { staticOptionsDocumentsDefault } from '../../../../../../../../_library/static_options/staticOptionsDocumentsDefault';
import { staticOptionsPersonGender } from '../../../../../../../../_library/static_options/staticOptionsPersonGender';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-register-data',
  standalone: true,
  imports: [
    CanttechComponentsModule,
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './tab-register-data.component.html'
})
export class TabRegisterDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;

  @Input() set actionStatus(xValue: string) {
    if (xValue != undefined) {
      this.currentStatus = xValue;
    }
  }

  @Input() set dataRegisterStatus(xValue: string) {
    if (xValue != undefined) {
      this.currentStatus = xValue;
    }
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionToolbarTabRegisterData = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;

  // ***** CURRENT ***** //
  public currentStatus!: string; // Vai ser Inicializado no set do input dataRegisterStatus.

  // ***** CLASSES ***** //
  public classLocalMethods!: any;

  // ***** TREE ***** //
  public pathTree!: TreeNode[];

  // ***** OPTIONS ***** //
  public optionsAccountCountries!: IDropDownOptionsCountry[];
  public optionsAccountStatus!: IDropDownOptionsColor[];
  public optionsProfileType!: IDropDownOptionsIcon[];
  public optionsDocumentType!: IDropDownOptionsBasic[];
  public optionsPersonGender!: IDropDownOptionsBasic[];
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _moduleAccountApisService: ModuleAccountService,
  ) { }

  // @status: OK
  ngOnInit(): void {
    this._initVariables();

    this._settingPreviewPathTree();

    this._initComponentFormEvents();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-26
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;

    this.classLocalMethods = LocalMethodsHandlerClass;

    this.optionsAccountStatus = staticOptionsAccountStatus;
    this.optionsAccountCountries = staticOptionsAllCountries;
    this.optionsProfileType = staticOptionsProfileType;
    this.optionsDocumentType = staticOptionsDocumentsDefault;
    this.optionsPersonGender = staticOptionsPersonGender;

    this._settingDocumentType(this.formComponent.controls.profile_data.controls.country.value, this.formComponent.controls.profile_data.controls.profile_type.value);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Define o tipo de documento de acordo com o país e o tipo de conta.
   * 
   * @param xCountry 
   * @param xAccountType 
   */
  private _settingDocumentType(xCountry: string, xAccountType: string): void {
    if (xCountry === 'BR') {
      if (xAccountType === 'company') {
        this.optionsDocumentType = staticOptionsDocumentsCompanyBr;
      } else if (xAccountType === 'person') {
        this.optionsDocumentType = staticOptionsDocumentsPersonBr;
      }
    } else {
      if (xAccountType === 'company') {
        this.optionsDocumentType = staticOptionsDocumentsCompanyBr;
      } else if (xAccountType === 'person') {
        this.optionsDocumentType = staticOptionsDocumentsPersonBr;
      }
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Monta a árvore de diretórios do sistema.
   */
  private _settingPreviewPathTree() {
    const tempAccountId = this.formComponent.controls.account_data_id.value;
    let tempAccountFolder = "xxxxxxxxxx";

    if (tempAccountId !== null) {
      tempAccountFolder = tempAccountId.replace(/[^a-zA-Z0-9]/g, "").toLowerCase().trim().substring(this.currentSettings.ACCOUNT_FOLDER_NAME_SIZE);
    }

    const tempBasicStructure = [
      {
        label: 'account',
        icon: 'fa-regular fa-folder',
        expanded: true,
        children: [
          {
            label: `${tempAccountFolder}`,
            icon: 'fa-regular fa-folder',
            expanded: true,
            children: [
              {
                label: 'imgs',
                icon: 'fa-regular fa-file'
              }
            ]
          }
        ]
      },
      {
        label: 'aircraft',
        icon: 'fa-regular fa-folder',
        expanded: true,
        children: [
          {
            label: `${tempAccountFolder}`,
            icon: 'fa-regular fa-folder'
          }
        ]
      },
      {
        label: 'crew',
        icon: 'fa-regular fa-folder',
        expanded: true,
        children: [
          {
            label: `${tempAccountFolder}`,
            icon: 'fa-regular fa-folder'
          }
        ]
      },
      {
        label: 'flight_plan',
        icon: 'fa-regular fa-folder',
        expanded: true,
        children: [
          {
            label: `${tempAccountFolder}`,
            icon: 'fa-regular fa-folder'
          }
        ]
      },
      {
        label: 'pax',
        icon: 'fa-regular fa-folder',
        expanded: true,
        children: [
          {
            label: `${tempAccountFolder}`,
            icon: 'fa-regular fa-folder'
          }
        ]
      }
    ];

    this.pathTree = [
      {
        label: 'SUNRISE',
        icon: 'fa-solid fa-folder',
        expanded: true,
        children: [
          {
            label: 'local',
            icon: 'fa-solid fa-folder',
            expanded: false,
            children: [
              {
                label: 'modules',
                icon: 'fa-solid fa-folder',
                expanded: true,
                children: tempBasicStructure
              }
            ]
          },
          {
            label: 'dev',
            icon: 'fa-solid fa-folder',
            expanded: false,
            children: [
              {
                label: 'modules',
                icon: 'fa-solid fa-folder',
                expanded: true,
                children: tempBasicStructure
              }
            ]
          },
          {
            label: 'prod',
            icon: 'fa-solid fa-folder',
            expanded: false,
            children: [
              {
                label: 'modules',
                icon: 'fa-solid fa-folder',
                expanded: true,
                children: tempBasicStructure
              }
            ]
          }
        ]
      }
    ]
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa os eventos do formulário.
   */
  private _initComponentFormEvents(): void {
    this.formComponent.get('profile_data.profile_type').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        this._settingDocumentType(this.formComponent.controls.profile_data.controls.country.value, xValue);
      } else {
        this.optionsDocumentType = staticOptionsDocumentsDefault;
        this.formComponent.controls.profile_data.controls.document_type.setValue('not_informed');
      }
    });
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-12-27
  * @version 1.0.0
  * 
  * @description 
  *   - Método que gera um password padrão para o usuário.
  */
  public onClickGenerateDefaultPassword(): any {
    return async (xEvent: any, xComponentContext: any) => {
      const tempPassword = `NEW${this.classLocalMethods.generateRandomNumber(5)}`;

      this.formComponent.controls.user_owner_data.controls.password_text.setValue(tempPassword);
      this.formComponent.controls.user_owner_data.controls.confirm_password.setValue(tempPassword);
    }
  }

  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-12-27
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será chamado quando um botão for clicado.
  * 
  * @param xButtonAction
  */
  public onClickToolbarTabRegisterData(xButtonAction: string): void {
    this.actionToolbarTabRegisterData.emit(xButtonAction);
  }

  /**
   * @status? 
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Método que será associado ao onBlur do controle para verificar se o valor do Fiel já está cadastrado.
   * 
   * @returns 
   */
  public onBlurCheckFieldValue(): any {
    return async (xEvent: any, xComponentContext: any) => {
      const inputElement = xEvent.target as HTMLInputElement;
      const inputValue = inputElement.value;
      const inputName = inputElement.name.replaceAll("-", "_");

      xComponentContext.showIconWaiting = true;

      if (inputValue && inputValue != null) {
        if (inputName === 'account_data_id') {
          // VERIFICA SE O VALOR JÁ EXISTE NA BASE DE DADOS: ACCOUNT
          await this._moduleAccountApisService.checkIfHasFieldValue(inputName, inputValue).subscribe({
            next: (xApiResponse: any) => {
              if (xApiResponse) { // Só importa se houver resultado positivo. Erros e outros não deve ser sinalizado
                if (xApiResponse.data[0] && xApiResponse.data[0].qtd > 0) {
                  if (this.formComponent.controls[inputName].errors === null) { // Se já estiver inválido, não precisa marcar novamente. 
                    this.formComponent.controls[inputName].markAsDirty();
                    this.formComponent.controls[inputName].markAsTouched();
                    this.formComponent.controls[inputName].setErrors({ unique: true });
                  }
                }
              }
            },
            error: (xError: any) => { // Não importa tratar o erro, pois na hora de inserir vai ser feita outra verificação mais detalhada.                           
            }
          });
        } else {
          // VERIFICA SE O VALOR JÁ EXISTE NA BASE DE DADOS: USER
          /*await this._moduleUserApisService.checkIfIdentifierHasFieldValue(inputName, inputValue).subscribe({
            next: (xApiResponse: any) => {
              if (xApiResponse) { // Só importa se houver resultado positivo. Erros e outros não deve ser sinalizado
                if (xApiResponse.data[0] && xApiResponse.data[0].qtd > 0) {
                  if (this.formComponent.controls[inputName].errors === null) { // Se já estiver inválido, não precisa marcar novamente. 
                    this.formComponent.controls[inputName].markAsDirty();
                    this.formComponent.controls[inputName].markAsTouched();
                    this.formComponent.controls[inputName].setErrors({ unique: true });
                  }
                }
              }
            },
            error: (xError: any) => { // Não importa tratar o erro, pois na hora de inserir vai ser feita outra verificação mais detalhada.                           
            }
          });*/
        }
      }

      setTimeout(() => {
        xComponentContext.showIconWaiting = false;
      }, 1500)
    }
  }

  public onClickCreatePathTree(): any {
    return async (xEvent: any, xComponentContext: any) => {
      console.log("CRIAR ARVORE DE DIRETORIOS")
    }
  }


  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  //#endregion
}
