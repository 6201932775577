<section class="cls-component-tab-data">
    <p-toolbar class="cls-component-toolbar">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <p-button label="Salvar Dados" styleClass="cls-component-button" [rounded]="true" icon="fa-solid fa-save"
                severity="secondary" class="mr-2" (click)="callbackClickButton(this.buttonActions.SAVE)" />
        </div>
    </p-toolbar>
    <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
        [expandIcon]="'fa-solid fa-chevron-up'">
        <ng-template pTemplate="header">
            <div class="cls-component-panel-default-header">
                <div class="cls-component-panel-default-header-title">
                    <h2>
                        <i class="fa-solid fa-bolt"></i>
                        <span> Pesos da Aeronave</span>
                    </h2>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-form cls-component-form-frame">
                <div class="formgrid">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="MTO #BOLD#"
                                [groupRightText]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_takeoff_weight_unit.value"
                                [control]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_takeoff_weight"
                                toolTip="Maximum Takeoff Weight">
                            </lib-ui-primeng-input-number-float>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="MLW #BOLD#"
                                [groupRightText]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_landing_weight_unit.value"
                                [control]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_landing_weight"
                                toolTip="Maximum Landing Weight">
                            </lib-ui-primeng-input-number-float>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="MRW"
                                [groupRightText]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_ramp_weight_unit.value"
                                [control]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_ramp_weight"
                                toolTip="Maximum Ramp Weight">
                            </lib-ui-primeng-input-number-float>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="MZFW #BOLD#"
                                [groupRightText]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_zero_fuel_weight_unit.value"
                                [control]="this.formComponent.controls.specification_data.controls.weight_data.controls.max_zero_fuel_weight"
                                toolTip="Maximum Zero Fuel Mass">
                            </lib-ui-primeng-input-number-float>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-panel>
    <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
        [expandIcon]="'fa-solid fa-chevron-up'">
        <ng-template pTemplate="header">
            <div class="cls-component-panel-default-header">
                <div class="cls-component-panel-default-header-title">
                    <h2>
                        <i class="fa-solid fa-bolt"></i>
                        <span> Performance Básica Aeronave</span>
                    </h2>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-form cls-component-form-frame">
                <div class="formgrid">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-time label="Autonomia #BOLD#"
                                [control]="this.formComponent.controls.specification_data.controls.performance_data.controls.autonomy">
                            </lib-ui-primeng-input-time>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="Vel. Cruzeiro #BOLD#"
                                [groupRightText]="this.formComponent.controls.specification_data.controls.performance_data.controls.cruise_speed_unit.value"
                                [control]="this.formComponent.controls.specification_data.controls.performance_data.controls.cruise_speed">
                            </lib-ui-primeng-input-number-float>
                        </div>                        
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="Máx. Dist Decolagem #BOLD#"
                                [groupRightText]="this.formComponent.controls.specification_data.controls.performance_data.controls.max_takeoff_distance_unit.value"
                                [control]="this.formComponent.controls.specification_data.controls.performance_data.controls.max_takeoff_distance">
                            </lib-ui-primeng-input-number-float>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="Máx. Dist Pouso #BOLD#"
                                [groupRightText]="this.formComponent.controls.specification_data.controls.performance_data.controls.max_landing_distance_unit.value"
                                [control]="this.formComponent.controls.specification_data.controls.performance_data.controls.max_landing_distance">
                            </lib-ui-primeng-input-number-float>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-panel>
    <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
        [expandIcon]="'fa-solid fa-chevron-up'">
        <ng-template pTemplate="header">
            <div class="cls-component-panel-default-header">
                <div class="cls-component-panel-default-header-title">
                    <h2>
                        <i class="fa-solid fa-bolt"></i>
                        <span> Combustível Aeronave</span>
                    </h2>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-form cls-component-form-frame">
                <div class="formgrid">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down label="Tipo Combustível #BOLD#"
                                [control]="this.formComponent.controls.specification_data.controls.fuel_data.controls.fuel_type"
                                [arrayOptions]="this.optionsFuelType">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-dropdown-text label="Capacidade Máxima #BOLD#"
                                [controlInput]="this.formComponent.controls.specification_data.controls.fuel_data.controls.total_capacity"
                                [controlDropdown]="this.formComponent.controls.specification_data.controls.fuel_data.controls.total_capacity_unit"
                                [arrayOptions]="this.optionsCgFuelUnits">
                            </lib-ui-primeng-input-dropdown-text>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-dropdown-text label="Consumo em Voo #BOLD#"
                                [controlInput]="this.formComponent.controls.specification_data.controls.fuel_data.controls.flight_fuel_rate"
                                [controlDropdown]="this.formComponent.controls.specification_data.controls.fuel_data.controls.flight_fuel_rate_unit"
                                [arrayOptions]="this.optionsFuelRateUnits">
                            </lib-ui-primeng-input-dropdown-text>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-dropdown-text label="Consumo Combustível #BOLD#"
                                [controlInput]="this.formComponent.controls.specification_data.controls.fuel_data.controls.taxi_fuel_rate"
                                [controlDropdown]="this.formComponent.controls.specification_data.controls.fuel_data.controls.taxi_fuel_rate_unit"
                                [arrayOptions]="this.optionsFuelRateUnits">
                            </lib-ui-primeng-input-dropdown-text>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-panel>
</section>