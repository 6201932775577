// CHECKED: OK
//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initCrewDataForm }from '../../form-init/crew-data-form';
import { initDocumentsForm } from '../../form-init/documents-form';

// ***** MOCK ***** //

// ***** CLASS ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';

// ***** COMPONENT ***** //
import { TabCrewRegisterComponent } from './_components/crew-data/tab-crew-register/tab-crew-register.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleCrewService } from '../../../../_services/module-crew/module-crew.service';
import { ModuleAircraftService } from 'src/app/app-platform/_services/module-aircraft/module-aircraft.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IDropDownOptionsBasic } from '../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from 'src/app/_library/definitions/DialogWaitingType';
//#endregion
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-crew-data-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TabCrewRegisterComponent,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './crew-data-form.component.html'
})
export class CrewDataFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public buttonActions!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** FORMS ***** //
  public formCrewData: any

  // ***** DIALOG ***** //
  public waitingDialogType!: any;
  public bolShowWaitingDialog!: boolean;
  public strTypeWaitingDialog!: string;

  // ***** ARRAYS ***** //

  // ***** OPTIONS***** //
  public optionsAllAircraft!: IDropDownOptionsIcon[];

  // ***** OTHERS ***** //
  public activeTabIndex!: number;  
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleAircraftApisService: ModuleAircraftService,
    private _moduleCrewService: ModuleCrewService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initCrewDataForm();

    //#region "|--- MOCKS ---|"
    //#endregion

    //#region "|--- BANCO DE DADOS ---|"
    //#endregion

    this._initFormCrewDataEvents();
    this._checkEditMode();
  }

  //region "|--- EVENT HANDLERS ---|"
  /**
   * 
   */
  private _initFormCrewDataEvents(): void {
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables() {
    this.bolShowWaitingDialog = false;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.CREW_DATA;

    this.activeTabIndex = 0;

    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;    
  }

  /**
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de voo.
   */
  private _initCrewDataForm() {
    this.formCrewData = initCrewDataForm();

    // É preciso criar pelo menos 2 instâncias do documento para que o formulário funcione corretamente.
    this.formCrewData.controls.documents.controls.push(initDocumentsForm());
    this.formCrewData.controls.documents.controls.push(initDocumentsForm());    
  }

  /**
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * 
   * @description Verifica se o formulário está em modo de edição.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      if (xParams["id"]) {
        this.objPageTitle = {
          title: 'Editar Tripulante',
          subtitle: 'Alterar os Dados de um Tripulante.',
          icon: 'fa-solid fa-edit'
        }

        //this._getFlightDataById(xParams["id"]);
      } else {
        this.objPageTitle = {
          title: 'Novo Tripulante',
          subtitle: 'Inclua os Dados de um Novo Tripulante.',
          icon: 'fa-solid fa-plus'
        }
      }
    })
  }

  private async _newCrewData() {
    //this.private_clearForm();
    this._router.navigate(['/app/crew/form']);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Método que trata a mudança de abas.
   */
  onTabChange(xTabNumber: number): void {
    this.activeTabIndex = xTabNumber;
  }

  /**
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * 
   * @description Método que trata as ações da aba de dados de registro da aeronave.
   * 
   * @param xAction 
   */
  handlerActionTabCrewData(xAction: string): void {
    if (xAction === BUTTON_ACTION.NEW) {
      console.log('NOVO REGISTRO DE TRIPULANTE');
    } else if (xAction === BUTTON_ACTION.SAVE) {
      console.log('SALVAR DADOS DA TRIPULANTE');
    } else if (xAction === BUTTON_ACTION.UPDATE) {
      console.log('UPDATE DADOS DA TRIPULANTE');
    } else if (xAction === BUTTON_ACTION.EXIT) {
      console.log('SAIR DA TRIPULANTE');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowWaitingDialog = xShowDialog.show_dialog;
    this.strTypeWaitingDialog = xShowDialog.type_waiting;
  }
  //#endregion
}