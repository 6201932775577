/**
 * @author GASPAR
 * @date 2024-10-02
 * @version 1.0.0
 * 
 * @description Enumerador  de tipos de componentes de CG.
 */
export const CG_COMPONENT_TYPE = {
    CYCLIC: "cyclic",
    COLLECTIVE: "collective",
    PEDAL: "pedal",
    PILOT_SEAT: "pilot_seat",
    PAX_SEAT: "pax_seat",    
    LUGGAGE_RACK: "luggage_rack",
    BASKET_LOAD: "basket_load",
    INTERNAL_LOAD: "internal_load",
    EXTERNAL_LOAD: "external_load",
    HOIST_LOAD: "hoist_load",
    FUEL: "fuel",
    BALLAST: "ballast",
    STRETCHER: "stretcher",
    DOOR: "door",
    OTHERS: "otherS"
}