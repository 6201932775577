//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// ***** CONFIG PROJECT ***** //
import { configAerodromes } from '../../../../_library/config/configAerodromes';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

// ***** HANDLER ***** //
import { HandlerErrorComponent } from '../../../../_library/layouts/components/primeng/form/_handle_error/_handler_error.component';
import { hasControlErrors } from '../../../../_library/layouts/components/primeng/form/_handle_error/_handler_error.component';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-aerodrome-input-fieldset',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    IconFieldModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputIconModule,
    InputTextModule,
    PrimengComponentsModule,
    ReactiveFormsModule,
    HandlerErrorComponent
  ],
  templateUrl: './aerodrome-input-fieldset.component.html'
})
export class AerodromeInputFieldsetComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formAircraftBasic!: any;
  @Input() index!: string;
  @Input() idField!: string;
  @Input() labelInput!: string;
  @Input() onBlurMethod!: any; // Método que será executado quando o campo perder o foco.
  @Input() onFocusMethod!: any;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  public aerodromeControl!: FormControl;
  public innerWidth?: number;

  public originalCoordinates!: any;

  public labelToShow?: string;

  public showLabelBold?: boolean;
  public showIconWaiting?: boolean;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
    this.showIconWaiting = false;

    this.originalCoordinates = "";

    this.aerodromeControl = this.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${this.index}.icao`) as FormControl;

    this.onBlurMethod = this.onBlurMethod === undefined ? (xEvent: any, xComponentContext: any) => { } : this.onBlurMethod;
    this.onFocusMethod = this.onFocusMethod === undefined ? (xEvent: any, xComponentContext: any) => { } : this.onFocusMethod;
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.labelInput != undefined) {
      if (this.labelInput.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.labelInput = this.labelInput.replace("#BOLD#", "").trim();
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description - Retorna o ícone do Aeródromo.
   * 
   * @param value 
   */
  public getIcon(): string {
    const aerodromeGoal = this.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${this.index}.ad_goal`).value;
    const item = configAerodromes.find(x => x.value == aerodromeGoal);

    return item ? item.icon : '';
  }

  /**
   * @COPIED
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Método para colocar o conteúdo em letra maiúscula.
   * 
   * @abstract Por default sempre vai ser maiúscula.
   * 
   * @param event 
   */
  public transformLetterCase(event: Event): void {
    const input = event.target as HTMLInputElement;

    this.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${this.index}.icao`).setValue(input.value.toUpperCase(), { emitEvent: false });
  }

  /**
   * @COPIED
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   * 
   */
  public showErrors(): void {    
    return hasControlErrors(this.formAircraftBasic.get(`flt_req_data.fk_ad_bsc_lst.${this.index}.icao`), "input");
  }

  /**
   * @COPIED
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo, com base no idField.
   * 
   * @returns 
   */
  public getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  public getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }

  public getAerodromeName(): string {
    const tempName = `flt_req_data.fk_ad_bsc_lst.${this.index}.name`;

    if(this.formAircraftBasic.get(tempName).value !== null) {
      return this.formAircraftBasic.get(tempName).value;
    } else {
      return "";
    }
  }

  public getAerodromeCoordinates(): string {
    const tempCoord = `flt_req_data.fk_ad_bsc_lst.${this.index}.coord_dc`;

    if (this.formAircraftBasic.get(tempCoord).value !== null) {
      return `(${this.formAircraftBasic.get(tempCoord).value[0]}, ${this.formAircraftBasic.get(tempCoord).value[1]})`;
    } else {
      return "";
    }
  }
  //#endregion
}
