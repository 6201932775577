//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder, FormsModule, Validators } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';
import { configAerodromes } from '../../../../../../app/_library/config/configAerodromes';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { SelectItemGroup } from 'primeng/api';

// ***** FORM ***** //
import { initFormFlightData } from '../../form-init/form-flight-data';
import { initFormFkCrewData } from '../../form-init/form-fk-crew-data';
import { initFormFkAerodromeBasicDataForm } from '../../form-init/form-fk-aerodrome-basic-data-form';

// ***** COMPONENT ***** //
import { AircraftInfoCardComponent } from '../../../../_components/layout/aircraft-info-card/aircraft-info-card.component';
import { CountdownUtcComponent } from '../../../../_components/layout/countdown-utc/countdown-utc.component';
import { FlightInfoCardComponent } from '../../../../_components/layout/flight-info-card/flight-info-card.component';
import { TabFlightRequestComponent } from './_components/flight-request/tab-flight-request/tab-flight-request.component';
import { TabWeightBalanceComponent } from './_components/weight-balance/tab-weight-balance/tab-weight-balance.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
import { TabWeightBalanceFilledComponent } from './_components/weight-balance/tab-weight-balance-filled/tab-weight-balance-filled.component';

// ***** CLASSES ***** //
import { CoordinatesHandlerClass } from '../../../../_classes/CoordinatesHandlerClass'
import { InternationalizationHandler } from '../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
import { ModuleCrewService } from 'src/app/app-platform/_services/module-crew/module-crew.service';
import { ModuleFlightService } from '../../../../_services/module-flight/module-flight.service';
import { ModuleOwnerOperatorService } from '../../../../_services/module-owner-operator/module-owner-operator.service';
import { ModulePaxService } from '../../../../_services/module-pax/module-pax.service';
import { ModuleWeightBalanceService } from '../../../../_services/module-weight-balance/module-weight-balance.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
import { FLIGHT_CREW_ROLE } from '../../../../../_library/definitions/FlightCrewRole';
import { REGEX_EXPRESSIONS } from '../../../../../_library/definitions/RegexExpressions';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IDropDownOptionsBasic } from '../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT DOMAINS ---|"
import { domainBasicAerodromeTypes } from '../../../../../_library/domains/domainBasicAerodromeTypes';
//@endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-planning-data-form',
  standalone: true,
  imports: [
    AircraftInfoCardComponent,
    FlightInfoCardComponent,
    CommonModule,
    FormsModule,
    PrimengComponentsModule,
    TitlePageComponent,
    TabFlightRequestComponent,
    TabWeightBalanceComponent,
    ToastMessageComponent,
    CountdownUtcComponent,
    WaitingDialogComponent,
    TabWeightBalanceFilledComponent
  ],
  templateUrl: './planning-data-form.component.html'
})
export class PlanningDataFormComponent implements OnInit, OnDestroy {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public activeTabIndex!: number;
  public currentSettings!: any;
  public typeDialogWaiting!: string;
  public extraDataDialogWaiting!: any[];

  // ***** CURRENT ***** //
  public currentUtcDateTime!: string; // Propriedade para armazenar o horário UTC

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** SHOW DIALOG ***** //
  public bolShowDialogWaiting!: boolean;

  // ***** OBJECTS ***** //
  public objCurrentAircraft: any;
  public objPageTitle!: IPageTitle;

  // ***** FORMS ***** //
  public formFlightData!: any
  public formFlightPlanData!: any

  // ***** ARRAYS ***** //
  public arrAircraftData!: any[];
  public arrOwnerOperatorData!: any[];
  public arrCrewData!: any[];
  public arrPaxData!: any[];
  public arrWeightBalanceDataSheet!: any[] | null; // Tem que ser NULL para forçar a mudança de estado e ser capturado pelo componente de Peso e Balanceamento.

  // ***** OPTIONS***** //
  public optAircraftData!: IDropDownOptionsIcon[];
  public optOperatorsData!: SelectItemGroup[]; // Como pode ter a necessidade de Agrupar os Valores, é preferencial deixar como ARRAY.
  public optCrewData!: SelectItemGroup[];
  public optPaxData!: IDropDownOptionsBasic[];
  public optWeightBalanceDataSheet!: IDropDownOptionsBasic[];

  // ***** CLOCK ***** //  
  public originUtcTime!: string; // Propriedade para armazenar o horário UTC
  private _clockInterval!: any; // Propriedade para armazenar o intervalo do relógio
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleAircraftApisService: ModuleAircraftService,
    private _moduleCrewService: ModuleCrewService,
    private _moduleFlightService: ModuleFlightService,
    private _moduleOwnerOperatorService: ModuleOwnerOperatorService,
    private _modulePaxService: ModulePaxService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();

    this._initFlightDataForm();
    this._initFormFlightDataEvents();

    this._initClock();
    this._checkEditMode();
  }

  ngOnDestroy(): void {
    // Limpe o intervalo quando o componente for destruído
    if (this._clockInterval) {
      clearInterval(this._clockInterval);
    }
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-19
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private async _initVariables(): Promise<void> {
    this.activeTabIndex = 0;
    this.currentSettings = settingConfig;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;
    this.extraDataDialogWaiting = [];

    this.currentUtcDateTime = "";

    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;

    this.bolShowDialogWaiting = false;

    this.objCurrentAircraft = null;

    this.formFlightData = null;
    this.formFlightPlanData = null;

    this.arrAircraftData = [];
    this.arrOwnerOperatorData = [];
    this.arrCrewData = [];
    this.arrPaxData = [];
    this.arrWeightBalanceDataSheet = [];

    this.optAircraftData = [];
    this.optOperatorsData = [];
    this.optCrewData = [];
    this.optPaxData = [];
    this.optWeightBalanceDataSheet = [];
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que verifica se é uma EDIÇÃO de dados de um registro ou um NOVO registro.
   *   - Define os Labels da página.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      let isEdit = false;

      if (xParams["id"]) {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_FlightPlanningAlter'),
          subtitle: this.classInternationalization.getTranslation('txt_FlightPlanningAlter'),
          icon: 'fa-solid fa-edit'
        }

        isEdit = true;
      } else {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_FlightPlanningNew'),
          subtitle: this.classInternationalization.getTranslation('txt_FlightPlanningNew'),
          icon: 'fa-solid fa-plus'
        }
      }

      if (isEdit) {
        Promise.all([
          await this._loadExtraDataDb(false),
          await this._getFlightDataById(xParams["id"])
        ]);
      } else {
        await this._loadExtraDataDb(true);
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-26
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega o dados necessários para preenchimento do cadastro.
   */
  private async _loadExtraDataDb(xBolCloseWaitingDialog: boolean): Promise<void> {
    this.bolShowDialogWaiting = true;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.LOADING_DATA;

    Promise.resolve([
      await this._getAllAircraftDataDb(),
      await this._getAllOwnerOperatorDataDb(),
      await this._getAllCrewDataDb()
    ]);

    setTimeout(() => {
      if (xBolCloseWaitingDialog === true) {
        this.bolShowDialogWaiting = false;
      }
    }, this.currentSettings.WAITING_DIALOG_LIFE);
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário.
   */
  private _initFlightDataForm(): void {
    this.formFlightData = initFormFlightData();

    // Criando os registros mínimos do voo para tripulação: pilot e copilot
    this.formFlightData.get('flt_req_data.fk_crew_lst').controls.push(initFormFkCrewData(FLIGHT_CREW_ROLE.PILOT_IN_COMMAND));
    this.formFlightData.get('flt_req_data.fk_crew_lst').controls.push(initFormFkCrewData(FLIGHT_CREW_ROLE.CO_PILOT));

    // É preciso forçar para que o Piloto em Primeiro Comando seja obrigatório
    this.formFlightData.get('flt_req_data.fk_crew_lst.0.fk_crew_data_id').setValidators([Validators.required]);
    this.formFlightData.get('flt_req_data.fk_crew_lst.0.fk_crew_data_id').updateValueAndValidity();

    for (let i = 0; i < domainBasicAerodromeTypes.length; i++) {
      // Adiciona os campos de Aeródromos Básicos Mínimos para o Processo: departure, destination, alt_1 e alt_2.
      this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst`).controls.push(initFormFkAerodromeBasicDataForm(domainBasicAerodromeTypes[i]));

      if (i === 0 || i === 1 || i === 2) {
        // O Campo ICAO deve ser obrigatório
        this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.icao`).setValidators([
          Validators.required,
          Validators.pattern(REGEX_EXPRESSIONS.AERODROME_ICAO_CODE)
        ]);

        // Após definir os validadores, você deve chamar updateValueAndValidity() no controle do formulário para que o Angular reavalie o estado do controle.
        this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.icao`).updateValueAndValidity();
      }
    }

    // Inicializa os eventos ligados aos dados do aeródromo básico.
    this._initFormAerodromeBasicDataEvents();
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Gera as opções de todas as aeronaves para por DROPDOWN.
   */
  private _generateOptionsAllAircraft(): void {
    this.optAircraftData = [];

    this.optAircraftData = this.arrAircraftData ? this.arrAircraftData.map((xObjAircraft: any) => {
      return {
        label: xObjAircraft.register_data.aircraft_mark,
        value: xObjAircraft.aircraft_data_id,
        icon: `${this.classModuleMethods.getAircraftTypeLabel(xObjAircraft.register_data.aircraft_type, true)}`
      }
    }) : [];

    if (this.optAircraftData.length > 1) {
      // ORDENAR OPERADORES PELO LABEL
      this.optAircraftData.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);
    }
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-09-24
  * @version 1.0.0
  * 
  * @description 
  *   - Faz a normalização e transformação para tipo Date em UTC, da data e hora EOBT.
  */
  private _processEobtDateTime(): void {
    const tempEobtControl = this.formFlightData.get('flt_req_data.eobt_date_time');
    const tempEobtDate = tempEobtControl.get('date_utc').value;
    const tempEobtTime = tempEobtControl.get('time_utc').value;

    if (tempEobtDate && tempEobtTime && tempEobtDate !== null && tempEobtTime !== null) {
      // Divide a data e a hora em componentes individuais
      const [day, month, year] = tempEobtDate.split('/').map(Number);
      const [hours, minutes] = tempEobtTime.split(':').map(Number);

      // Cria a string no formato ISO 8601
      const isoDateString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00Z`;

      tempEobtControl.get('date_time_utc').setValue(isoDateString, { emitEvent: false });
      tempEobtControl.get('date_time_norm').setValue(`${tempEobtDate} ${tempEobtTime}`, { emitEvent: false });
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Faz o calculo do da e da hora de pousolevando em conta o EOBT e o EET.
   */
  private _calculateLandingDateTime(): void {
    const tempEobtDateTimeControl = this.formFlightData.get('flt_req_data.eobt_date_time');
    const tempLandingDateTimeControl = this.formFlightData.get('flt_req_data.lnd_date_time');
    const tempEetTotal = this.formFlightData.get('flt_req_data.eet_total').value;
    const tempEobtDateTime = tempEobtDateTimeControl.get('date_time_utc').value;

    if (tempEobtDateTime && tempEetTotal) {
      const tempEobtDateTimeUTC = new Date(tempEobtDateTime);
      // Transformar tempEetTotal que esta no formato hh:mm em minutos
      const tempEetTotalUTC = this.classLocalMethods.convertTimeToMinutes(tempEetTotal);

      // Acrescentar os minutos do EET ao EOBT
      const tempLandingDateTime = new Date(tempEobtDateTimeUTC.getTime() + tempEetTotalUTC * 60000);
      const tempDayUtc = `${tempLandingDateTime.getUTCDate()}`.padStart(2, "0");
      const tempMonthUtc = `${tempLandingDateTime.getUTCMonth() + 1}`.padStart(2, "0");
      const tempYearUtc = tempLandingDateTime.getUTCFullYear();
      const tempHoursUtc = `${tempLandingDateTime.getUTCHours()}`.padStart(2, "0");
      const tempMinutesUtc = `${tempLandingDateTime.getUTCMinutes()}`.padStart(2, "0");

      tempLandingDateTimeControl.get('date_utc').setValue(`${tempDayUtc}/${tempMonthUtc}/${tempYearUtc}`, { emitEvent: false });
      tempLandingDateTimeControl.get('time_utc').setValue(`${tempHoursUtc}:${tempMinutesUtc}`, { emitEvent: false });
      tempLandingDateTimeControl.get('date_time_utc').setValue(tempLandingDateTime.toISOString(), { emitEvent: false });
      tempLandingDateTimeControl.get('date_time_norm').setValue(`${tempDayUtc}/${tempMonthUtc}/${tempYearUtc} ${tempHoursUtc}:${tempMinutesUtc}`, { emitEvent: false });
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status: 
   * @author GASPAR
   * @date 2024-12-04
   * @version 1.0.0
   * 
   * @description 
   *   - Atribui os eventos aos controles do Formulário.
   */
  private _initFormFlightDataEvents(): void {
    const tempAircraftDataControl = this.formFlightData.get('flt_req_data.acft_data');
    const tempEobtDateTimeControl = this.formFlightData.get('flt_req_data.eobt_date_time');
    const tempLandingDateTimeControl = this.formFlightData.get('flt_req_data.lnd_date_time');

    this.formFlightData.get('flt_req_data.acft_data.fk_acft_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue !== undefined && xValue !== null) {
        // Recupera os dados da Aeronave no Objeto que já foi recuperado...        
        this.objCurrentAircraft = this.arrAircraftData.find((xObjAircraft: any) => xObjAircraft.aircraft_data_id === xValue);
        tempAircraftDataControl.get('mark').setValue(this.objCurrentAircraft.register_data.aircraft_mark);
        tempAircraftDataControl.get('acft_type').setValue(this.objCurrentAircraft.register_data.aircraft_type);

        //#region "|--- OPERATOR FOR THIS AIRCRAFT ---|"
        this.optOperatorsData = [];

        let tempOperatorAircraft = this.objCurrentAircraft?.fk_owner_operator_list.map((xObjOwnerOperator: any) => {
          if (xObjOwnerOperator.owner_operator_type === "operator" || xObjOwnerOperator.owner_operator_type === "both") {
            return {
              label: this.arrOwnerOperatorData.find((xListObjOwnerOperator: any) => xListObjOwnerOperator.owner_operator_data_id === xObjOwnerOperator.fk_owner_operator_data_id)?.full_name,
              value: xObjOwnerOperator.fk_owner_operator_data_id
            }
          } else {
            return null
          }
        });

        // Remover os valores nulos
        tempOperatorAircraft = tempOperatorAircraft.filter((xObjOwnerOperator: any) => xObjOwnerOperator !== null);

        // ORDENAR OPERADORES PELO LABEL
        tempOperatorAircraft.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);

        // OPERATOR FOR ALL AIRCRAFT 
        const tempAllOperator = [];

        for (let j = 0; j < this.arrOwnerOperatorData.length; j++) {
          if (this.arrOwnerOperatorData[j].aircraft_operator.length > 0) {
            tempAllOperator.push({
              label: this.arrOwnerOperatorData[j].full_name,
              value: this.arrOwnerOperatorData[j].owner_operator_data_id
            });
          }
        }

        // ORDENAR OPERADORES PELO LABEL
        tempAllOperator.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);

        let operatorForOthersAircraft = [...tempAllOperator, ...tempOperatorAircraft];

        //Remover ambos os operadores repetidos, sem deixar nenhuma copia. Tratamento para os demais Operadores.
        operatorForOthersAircraft = this.classLocalMethods.removeDuplicatesAndOriginal(operatorForOthersAircraft);

        // DROPDOWN OPTIONS: OPERADORES DA AERONAVE
        this.optOperatorsData.push({
          label: `- Aeronave ${this.objCurrentAircraft.register_data.aircraft_mark}`,
          value: 'this',
          items: tempOperatorAircraft
        })

        // DROPDOWN OPTIONS: DEMAIS OPERADORES
        if (operatorForOthersAircraft.length > 0) {
          this.optOperatorsData.push({
            label: '- Outros Operadores',
            value: 'others',
            items: operatorForOthersAircraft
          });
        }

        if (tempOperatorAircraft.length == 1) {
          tempAircraftDataControl.get('fk_owr_opr_data_id').setValue(tempOperatorAircraft[0].value);
        }
        //#endregion

        //#region "|--- CREW FOR THIS AIRCRAFT ---|"
        this.optCrewData = [];
        const tempAllCrew = [];

        let tempCrewAircraft = this.objCurrentAircraft?.fk_crew_list.map((xObjCrew: any) => {
          const tempCrewData = this.arrCrewData.find((xListObjCrew: any) => xListObjCrew.crew_data_id === xObjCrew.fk_crew_data_id);

          if (tempCrewData.documents_profissional.length > 0) {
            if (tempCrewData.crew_role === "pilot") {
              for (let k = 0; k < tempCrewData.documents_profissional.length; k++) {
                const tempProfissionalType = tempCrewData.documents_profissional[k].document_type;
                const tempProfissionalIn = tempCrewData.documents_profissional[k].document_in;

                return {
                  label: `${tempProfissionalType}: ${tempProfissionalIn}  - ${tempCrewData.name_data.full_name}`,
                  value: tempCrewData.crew_data_id
                };
              }
            } else {
              return null;
            }
          } else {
            return null;
          }

          return null;
        });

        // Remover os valores nulos
        tempCrewAircraft = tempCrewAircraft.filter((xObjTempCrew: any) => xObjTempCrew !== null);

        // ORDENAR TRIPULANTES PELO LABEL
        tempCrewAircraft.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);

        for (let j = 0; j < this.arrCrewData.length; j++) {
          if (this.arrCrewData[j].documents_profissional.length > 0) {
            if (this.arrCrewData[j].crew_role === "pilot") {
              for (let k = 0; k < this.arrCrewData[j].documents_profissional.length; k++) {
                const tempProfissionalType = this.arrCrewData[j].documents_profissional[k].document_type;
                const tempProfissionalIn = this.arrCrewData[j].documents_profissional[k].document_in;

                tempAllCrew.push({
                  label: `${tempProfissionalType}: ${tempProfissionalIn}  - ${this.arrCrewData[j].name_data.full_name}`,
                  value: this.arrCrewData[j].crew_data_id
                });
              }
            }
          }
        }

        // ORDENAR TRIPULANTES PELO LABEL
        tempAllCrew.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1);

        let crewForOthersAircraft = [...tempAllCrew, ...tempCrewAircraft];

        //Remover ambos os tripulantes repetidos, sem deixar nenhuma copia. Tratamento para os demais Operadores.
        crewForOthersAircraft = this.classLocalMethods.removeDuplicatesAndOriginal(crewForOthersAircraft);

        // DROPDOWN OPTIONS: TRIPULANTES DA AERONAVE
        this.optCrewData.push({
          label: `- Aeronave ${this.objCurrentAircraft.register_data.aircraft_mark}`,
          value: 'this',
          items: tempCrewAircraft
        })

        // DROPDOWN OPTIONS: DEMAIS TRIPULANTES
        if (crewForOthersAircraft.length > 0) {
          this.optCrewData.push({
            label: '- Outros Tripulantes',
            value: 'others',
            items: crewForOthersAircraft
          });
        }

        if (tempCrewAircraft.length == 1) {
          this.formFlightData.controls.flight_request.controls.fk_crew_data_list.controls[0].controls.fk_crew_data_id.setValue(tempCrewAircraft[0].value);
        }
        //#endregion        
      } else {
        this.arrWeightBalanceDataSheet = null;
        this.objCurrentAircraft = null;
        tempAircraftDataControl.get('mark').setValue(null);
        tempAircraftDataControl.get('fk_owr_opr_data_id').setValue(null);
        tempAircraftDataControl.get('opr_full_name').setValue(null);
        this.optOperatorsData = [];
      }
    });

    this.formFlightData.get('flt_req_data.acft_data.fk_owr_opr_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue !== undefined && xValue !== null) {
        const tempOperatorName = this.arrOwnerOperatorData.find((xListObjOwnerOperator: any) => xListObjOwnerOperator.owner_operator_data_id === xValue)?.full_name;

        tempAircraftDataControl.get('opr_full_name').setValue(tempOperatorName);
      } else {
        tempAircraftDataControl.get('opr_full_name').setValue(null);
      }
    });

    this.formFlightData.get('flt_req_data.eobt_date_time.date_utc').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) { // O Calendário repassa o valor como um tipo DATE.
        // Recupera somente os números da data.
        const tempDay = `${xValue.getDate()}`.padStart(2, "0");
        const tempMonth = `${xValue.getMonth() + 1}`.padStart(2, "0");
        const tempYear = xValue.getFullYear();

        if (tempDay && tempMonth && tempYear) {
          const tempData = `${tempDay}/${tempMonth}/${tempYear}`;

          tempEobtDateTimeControl.get('date_utc').setValue(tempData, { emitEvent: false });

          this._processEobtDateTime();
          this._calculateLandingDateTime();
        } else {
          tempEobtDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
          tempEobtDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });

          tempLandingDateTimeControl.get('date_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('time_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });
        }
      } else { // Se o valor não for valido, tem que apagar o valor dos campos relacionados - NÃO É PERMITIDO SUPOSIÇÃO.
        tempEobtDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
        tempEobtDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });

        tempLandingDateTimeControl.get('date_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('time_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });
      }
    });

    this.formFlightData.get('flt_req_data.eobt_date_time.time_utc').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        if (REGEX_EXPRESSIONS.TIME_HH_MM.test(xValue)) {
          this._processEobtDateTime();
          this._calculateLandingDateTime();
        } else {
          tempEobtDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
          tempEobtDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });

          tempLandingDateTimeControl.get('date_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('time_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });
        }
      } else { // Se o valor não for valido, tem que apagar o valor dos campos relacionados - NÃO É PERMITIDO SUPOSIÇÃO.
        tempEobtDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
        tempEobtDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });

        tempLandingDateTimeControl.get('date_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('time_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });
      }
    });

    this.formFlightData.get('flt_req_data.eet_total').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        if (REGEX_EXPRESSIONS.TIME_HH_MM.test(xValue)) {
          this._calculateLandingDateTime();
        } else {
          tempLandingDateTimeControl.get('date_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('time_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
          tempLandingDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });
        }
      } else { // Se o valor não for valido, tem que apagar o valor dos campos relacionados - NÃO É PERMITIDO SUPOSIÇÃO.       
        tempLandingDateTimeControl.get('date_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('time_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('date_time_utc').setValue(null, { emitEvent: false });
        tempLandingDateTimeControl.get('date_time_norm').setValue(null, { emitEvent: false });
      }
    });

    for (let i = 0; i < this.formFlightData.get('flt_req_data.fk_crew_lst').length; i++) {
      this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.fk_crew_data_id`).valueChanges.subscribe(async (xValue: any) => {
        if (xValue !== undefined && xValue !== null) {
          const tempCrewData = this.arrCrewData.find((xListObjCrew: any) => xListObjCrew.crew_data_id === xValue);

          if (tempCrewData !== undefined && tempCrewData !== null) {
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.crew_role`).setValue(tempCrewData.crew_role);
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.prof_doc_type`).setValue(tempCrewData.documents_profissional[0].document_type);
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.prof_doc_in`).setValue(tempCrewData.documents_profissional[0].document_in);
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.full_name`).setValue(tempCrewData.name_data.full_name);
          } else {
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.crew_role`).setValue(null);
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.prof_doc_type`).setValue(null);
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.prof_doc_in`).setValue(null);
            this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.full_name`).setValue(null);
          }
        } else {
          this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.crew_role`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.prof_doc_type`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.prof_doc_in`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_crew_lst.${i}.full_name`).setValue(null);
        }
      });
    }
  }

  /**
   * @status: 
   * @author GASPAR
   * @date 2024-12-04
   * @version 1.0.0
   * 
   * @description 
   *   - Atribui os eventos aos controles do Aerodromos.
   */
  private _initFormAerodromeBasicDataEvents(): void {
    // Preciso monitorar toda vez que muda o valor do ICAO do aerodrmo que esta no formulário flight_request.fk_aerodrome_basic_list[icao_code]
    for (let i = 0; i < this.formFlightData.get('flt_req_data.fk_ad_bsc_lst').length; i++) {
      this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.icao`).valueChanges.subscribe(async (xValue: any) => {
        let clearContent = false;

        if (xValue !== undefined && xValue !== null && xValue !== "") {
          xValue = xValue.trim().toUpperCase();

          // Verifica se é um Código ICAO para Aeródromos.         
          if (REGEX_EXPRESSIONS.AERODROME_ICAO_CODE.test(xValue)) {

            /**
             * SEQUENCIA DA PESQUISA DOS DADOS BÁSICOS DE AERÓDROMO:
             * 1) PESQUISA NO BANCO DE DADOS LOCAL;
             * 2) PESQUISA NA API DO AISWEB;
             * 3) PESQUISA NA API DO OPENSKY;
             * 4) PESQUISA NA API DO AVIATIONSTACK;
             * 5) PESQUISA NO METEOROBLUE
             */
            if (xValue === "ZZZZ") {
              if (this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).value !== null) {
                await this._aerodromeBasicDataInfoByIcao(xValue, i);
              }
            } else {
              await this._aerodromeBasicDataInfoByIcao(xValue, i);
            }

            const tempMarkerToDraw: any[] = [];
            const currentMarkerData = this.formFlightData.get(`map_data.marker_data`).value;

            if (currentMarkerData !== null && currentMarkerData.length > 0) {
              let hasInserted = false;

              for (let j = 0; j < currentMarkerData.length; j++) {
                // 1) Garantir que os aeródromos sejam inseridos na ORDEM correta
                if (currentMarkerData[j].type !== undefined && currentMarkerData[j].type.indexOf('aerodrome') >= 0) {
                  if (hasInserted === false) {
                    const currentItem = currentMarkerData[j].type.split('#');
                    const indexItem = i;

                    if (currentItem.length > 1) {
                      if (parseInt(currentItem[1]) > indexItem) {
                        tempMarkerToDraw.push({
                          name: xValue,
                          type: `aerodrome#${i}`,
                          icon: this.getIcon(i),
                          lat: this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).value[0],
                          lng: this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).value[1]
                        });

                        tempMarkerToDraw.push(currentMarkerData[j]);
                        this.formFlightData.get(`map_data.marker_data`).setValue(tempMarkerToDraw);

                        hasInserted = true;
                      } else {
                        tempMarkerToDraw.push(currentMarkerData[j]);
                        this.formFlightData.get(`map_data.marker_data`).setValue(tempMarkerToDraw);
                      }
                    }
                  } else { // Vai inserir os demais elementos da lista atual.
                    tempMarkerToDraw.push(currentMarkerData[j]);
                    this.formFlightData.get(`map_data.marker_data`).setValue(tempMarkerToDraw);
                  }
                }
              }

              // Caso o novo registro seja o ultimo item da lista.
              if (hasInserted === false) {
                tempMarkerToDraw.push({
                  name: xValue,
                  type: `aerodrome#${i}`,
                  icon: this.getIcon(i),
                  lat: this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).value[0],
                  lng: this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).value[1]
                });

                this.formFlightData.get(`map_data.marker_data`).setValue(tempMarkerToDraw);
              }
            } else { // Se não tiver nenhum registro de marcadores no mapa.
              tempMarkerToDraw.push({
                name: xValue,
                type: `aerodrome#${i}`,
                icon: this.getIcon(i),
                lat: this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).value[0],
                lng: this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).value[1]
              });
              this.formFlightData.get(`map_data.marker_data`).setValue(tempMarkerToDraw);
            }
          } else {
            clearContent = true;
          }
        } else {
          clearContent = true;
        }

        if (clearContent) {
          // Limpa os campos do Aeródromo Básico.        
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.fk_ad_bsc_data_id`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.fk_ad_cmp_data_id`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.iata`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.ad_type`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.name`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_dc`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.alt_ft`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.timezone`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.coord_geo.location.coordinates`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.locus.country`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.locus.state`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.locus.city`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.doc_db_control.crted_at_utc`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.doc_db_control.upted_at_utc`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.doc_db_control.dlted_at_utc`).setValue(null);
          this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${i}.font`).setValue([]);

          // Tem que tirar o marker do mapa.
          const tempMarkerToDraw: any[] = [];
          const currentMarkerData = this.formFlightData.get(`map_data.marker_data`).value;

          if (currentMarkerData !== null && currentMarkerData.length > 0) {
            for (let j = 0; j < currentMarkerData.length; j++) {
              if (currentMarkerData[j].type !== undefined && currentMarkerData[j].type.indexOf('aerodrome') >= 0) {
                const currentItem = currentMarkerData[j].type.split('#');
                const indexItem = i;

                if (currentItem.length > 1) {
                  if (parseInt(currentItem[1]) !== indexItem) {
                    tempMarkerToDraw.push(currentMarkerData[j]);
                  }
                }
              } else {
                tempMarkerToDraw.push(currentMarkerData[j]);
              }
            }

            this.formFlightData.get(`map_data.marker_data`).setValue(tempMarkerToDraw);
          }
        }
      });
    }
  }

  /**
     * @author GASPAR
     * @date 2024-09-23
     * @version 1.0.0
     * 
     * @description - Retorna o ícone do Aeródromo.
     * 
     * @param value 
     */
  public getIcon(xIndex: number): string {
    const aerodromeGoal = this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${xIndex}.ad_goal`).value;
    const item = configAerodromes.find(x => x.value == aerodromeGoal);

    return item ? item.icon : '';
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Salva a Requisição de Voo.
   */
  private async _saveFlightData() {
    if (this.formFlightData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formFlightData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Requisição de Voo',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      await this._moduleFlightService.saveFormFlightData(this.formFlightData).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
            const dataFlight = xApiResponse.data[0].data[0];
            const tempHistDataListControls = [];
            const objFormBuilder: FormBuilder = new FormBuilder();

            this.formFlightData.get('flt_data_id').setValue(dataFlight.flt_data_id, { emitEvent: false });
            this.formFlightData.get('fk_flt_plan_id').setValue(dataFlight.fk_flt_plan_id, { emitEvent: false });
            this.formFlightData.get('flt_code_data').setValue(dataFlight.flt_code_data, { emitEvent: false });
            this.formFlightData.get('ext_url_data.code').setValue(dataFlight.ext_url_data.code, { emitEvent: false });
            this.formFlightData.get('ext_url_data.url').setValue(dataFlight.ext_url_data.url, { emitEvent: false });
            this.formFlightData.get('ext_url_data.end_date_time_utc').setValue(dataFlight.ext_url_data.end_date_time_utc, { emitEvent: false });
            this.formFlightData.get('curr_sts').patchValue(dataFlight.curr_sts, { emitEvent: false });

            if (dataFlight.hist_data_list) {
              for (let i = 0; i < dataFlight.hist_data_list.length; i++) {
                tempHistDataListControls.push(objFormBuilder.group(
                  dataFlight.hist_data_list[i]
                ));
              }
            }

            this.formFlightData.get('hist_data_list').controls = tempHistDataListControls;

            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Requisição de Voo',
              detail: `${xApiResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });

            // WEIGHT BALANCE SHEET
            this._getWeightBalanceSheetListByAircraft(this.formFlightData.get('flt_req_data.acft_data.fk_acft_data_id').value);
          }
        },
        error: (xError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Requisição de Voo',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
    * @status: 
    * @author GASPAR
    * @date 2025-01-04
    * @version 1.0.0
    * 
    * @description 
    *  - Método que recupera os dados do FLIGHT DATA pelo ID.
    * 
    * @param xFlightDataId 
    */
  private async _getFlightDataById(xFlightDataId: string): Promise<void> {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.FLIGHT_DATA;
    this.bolShowDialogWaiting = true;

    await this._moduleFlightService.getFlightDataById(xFlightDataId).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          const tempData = xApiResponse[0];

          this.formFlightData.patchValue(tempData, { emitEvent: false });

          // Precisa configurar a Aeronave, Operador, por isto tem que tem que fazer manualmente e disparar o evento.
          this.formFlightData.get('flt_req_data.acft_data.fk_acft_data_id').setValue(tempData.flt_req_data.acft_data.fk_acft_data_id);
          this.formFlightData.get('flt_req_data.acft_data.fk_owr_opr_data_id').setValue(tempData.flt_req_data.acft_data.fk_owr_opr_data_id);


          // WEIGHT BALANCE SHEET
          this._getWeightBalanceSheetListByAircraft(tempData.flt_req_data.acft_data.fk_acft_data_id);

          // Simula um delay para fechar o diálogo de espera.
          setTimeout(() => {
            this.bolShowDialogWaiting = false;
          }, this.currentSettings.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowDialogWaiting = false;

          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_FlightDataRecover'),
            detail: this.classInternationalization.getTranslation('msg_FlightDataRecoverError'),
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        this.bolShowDialogWaiting = false;

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_FlightDataRecover'),
          detail: `${xError.detail}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-04
   * @version 1.0.0
   * 
   * @description 
   *   - Recupera todas as Aeronaves do Sistema que estão Cadastradas para o Usuário.
   */
  private async _getAllAircraftDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this.arrAircraftData = []; // Para forçar a atualização da lista.

      this._moduleAircraftApisService.getAllAircraftDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.arrAircraftData = xApiResponse;

            this._generateOptionsAllAircraft(); // Gera as opções de todas as aeronaves para por DROPDOWN.
          } else {
            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
              detail: this.classInternationalization.getTranslation('msg_AircraftDataListError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-12-04
  * @version 1.0.0
  * 
  * @description
  *   - Recupera todos os Owners/Operators do Sistema.
  */
  private async _getAllOwnerOperatorDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this.arrOwnerOperatorData = []; // Para forçar a atualização da lista.

      this._moduleOwnerOperatorService.getAllOwnerOperatorDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {

            this.arrOwnerOperatorData = xApiResponse;
          } else {
            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
              detail: this.classInternationalization.getTranslation('msg_OwnerOperatorDataListError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-04
   * @version 1.0.0
   * 
   * @description 
   *   - Recupera todos os Tripulantes do Sistema.
   */
  private async _getAllCrewDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this.arrCrewData = [];

      this._moduleCrewService.getAllCrewDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {

            this.arrCrewData = xApiResponse;
          } else {
            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_CrewDataList'),
              detail: this.classInternationalization.getTranslation('msg_CrewDataListError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_CrewDataList'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Obtém a lista de Fichas de Peso e Balanceamento por Aeronave.
   * 
   * @param xAircraftId 
   */
  private async _getWeightBalanceSheetListByAircraft(xAircraftId: string): Promise<void> {
    this.arrWeightBalanceDataSheet = []; // Sempre que chamar, força a atualização da lista.

    await this._moduleWeightBalanceService.getWeightBalanceDataSheetByAircraft(xAircraftId).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          // Esta Lista será passada para o componente de Fichas de Peso e Balanceamento.          
          this.arrWeightBalanceDataSheet = xApiResponse.data[0].data;

          setTimeout(() => {
            this._messageService.add({
              severity: 'success',
              summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
              detail: this.classInternationalization.getTranslation('msg_WeighBalanceDataSheetListSuccess'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }, settingConfig.WAITING_DIALOG_LIFE);
        } else {
          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
            detail: this.classInternationalization.getTranslation('msg_WeighBalanceDataSheetListError'),
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Obtém os Dados Básicos de um aeródromo com base no Código Icao.
   * 
   * @param xAircraftId 
   */
  private async _aerodromeBasicDataInfoByIcao(xIcaoCode: string, xIndexAerodromeGoal: number): Promise<void> {
    return new Promise((resolve) => {
      this._moduleFlightService.getAerodromeBasicDataInfoByIcao(xIcaoCode).subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {

            if (xApiResponse.length === 1) {
              this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${xIndexAerodromeGoal}`).patchValue(xApiResponse[0], { emitEvent: false });
              this.formFlightData.get(`flt_req_data.fk_ad_bsc_lst.${xIndexAerodromeGoal}.fk_ad_bsc_data_id`).setValue(xApiResponse[0].ad_bsc_data_id, { emitEvent: false });
            } else if (xApiResponse.length > 1) {
              // ABRE UM TELA PARA ESCOLHA DE TODOS OS RESULTADOS QEU VOLTARAM
            }

            // Esta Lista será passada para o componente de Fichas de Peso e Balanceamento.          
            setTimeout(() => {
              this._messageService.add({
                severity: 'success',
                summary: 'Recuperar Dados Básicos do Aeródromo',
                detail: 'Recuperação dos Dados Básicos do Aeródromo com Sucesso.',
                key: settingConfig.TOAST_KEY,
                life: settingConfig.TOAST_LIFE
              });
            }, settingConfig.WAITING_DIALOG_LIFE);
          } else {
            this._messageService.add({
              severity: 'error',
              summary: 'Recuperar Dados Básicos do Aeródromo',
              detail: 'Erro ao Recuperar os Dados Básicos do Aeródromo.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve();
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          console.log("!!!!!!!!!!!!!!", xError)

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: 'Recuperar Dados Básicos do Aeródromo',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          resolve();
        }
      });
    });
  }
  //#endregion

  //#region "|--- CLOCK ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Seleciona qual será a base de referencia para o horário UTC.
   *     - WEB: busca o horário inicial em algum site de relógio de precisão;
   *     - LOCAL: busca o horário inicial no relógio do computador local. 
   */
  private _initClock(): void {
    // Tente sincronizar com um servidor de horário de precisão
    this._syncWithPrecisionClockUtc().then((time) => {
      this._startClockUtc(time);
      this.originUtcTime = "web";
    }).catch(() => {
      // Se a sincronização falhar, use o horário do computador local
      this._startClockUtc(new Date());
      this.originUtcTime = "local";
    });
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Inicia o relógio com o horário UTC.
   * 
   * @param initialTime 
   */
  private _startClockUtc(initialTime: Date): void {
    let currentTime = initialTime;

    // Atualize o horário a cada segundo
    this._clockInterval = setInterval(() => {
      currentTime = new Date(currentTime.getTime() + 1000);
      this.currentUtcDateTime = `${this._formatDateTimeUtc(currentTime)}###${this.originUtcTime}`;
    }, 1000);
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Sincroniza o relógio com um servidor de horário de precisão.
   * 
   * @returns 
   */
  private async _syncWithPrecisionClockUtc(): Promise<Date> {
    // Tente obter o horário do World Time API
    const response = await fetch('http://worldtimeapi.org/api/timezone/Etc/UTC');

    if (!response.ok) {
      throw new Error('Failed to fetch time from World Time API');
    }

    const data = await response.json();
    const preciseTime = new Date(data.utc_datetime);

    return preciseTime;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description 
   *   - Formata a Data e Hora para o padrão UTC.
   * 
   * @returns 
   */
  private _formatDateTimeUtc(date: Date): string {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} UTC`;
  }
  //#endregion













  //!!!--------------------------------------------------------------------------------------------




  //region "|--- EVENT HANDLERS ---|"

  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *     - Método que trata a ação de fechar o dialog de espera.
   */
  public handlerActionCloseWaitingDialog(xBolCloseDialog: boolean): void {
    this.bolShowDialogWaiting = xBolCloseDialog;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"  

  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * 
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata o evento de mudança de aba. 
   */
  public onChangeTabView(xEvent: any): void {
    this.activeTabIndex = xEvent.index;
  }
  //#endregion




  //#region "|--- PRIVATE METHODS ---|"

  private async _newFlightData() {
    //this.private_clearForm();
    this._router.navigate(['/app/flight/form']);
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Obtém todas as aeronaves do usuário.
   */




  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
   * 
   * @param xAction 
   */
  handlerActionTabFlightRequest(xAction: string): void {
    if (xAction === ACTION_TYPE.NEW) {
      console.log('NOVA REQUISIÇÃO DE VOO');
    } else if (xAction === ACTION_TYPE.SAVE) {
      console.log('SALVAR REQUISIÇÃO DE VOO');
      this._saveFlightData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      console.log('UPDATE REQUISIÇÃO DE VOO');
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log('SAIR REQUISIÇÃO DE VOO');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowDialogWaiting = xShowDialog.show;
    this.typeDialogWaiting = xShowDialog.type;
  }
  //#endregion
}

/*
state: new FormControl<string>(''),
              country: new FormControl<string>(''),
              timezone: new FormControl<string>(''),
              is_international: new FormControl<boolean>(false),
              is_customs: new FormControl<boolean>(false),
              is_military: new FormControl<boolean>(false),
              is_heliport: new FormControl<boolean>(false),
              is_seaport: new FormControl<boolean>(false),
              is_spaceport: new FormControl<boolean>(false),
              is_airport: new FormControl<boolean>(false),
              is_aerodrome: new FormControl<boolean>(false),
              is_base: new FormControl<boolean>(false),
              is_terminal: new FormControl<boolean>(false),
              is_station: new FormControl<boolean>(false),
              is_hangar: new FormControl<boolean>(false),
              is_landing_strip: new FormControl<boolean>(false),
              is_runway: new FormControl<boolean>(false),
              is_taxiway: new FormControl<boolean>(false),
              is_apron: new FormControl<boolean>(false),
              is_terminal_gate: new FormControl<boolean>(false),
              is_terminal_building: new FormControl<boolean>(false),
              is_control_tower: new FormControl<boolean>(false),
              is_fire_station: new FormControl<boolean>(false),
              is_rescue_station: new FormControl<boolean>(false),
              is_medical_station: new FormControl<boolean>(false),
              is_fuel_station: new FormControl<boolean>(false),
              is_maintenance_station: new FormControl<boolean>(false),
              is_cargo_station: new FormControl<boolean>(false),
              is_passenger_station: new FormControl<boolean>(false),
              is_vip_station: new FormControl<boolean>(false),
              is_catering_station: new FormControl<boolean>(false),
              is_crew_station: new FormControl<boolean>(false),
              is_customs_station: new FormControl<boolean>(false),
              */