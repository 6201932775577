<div class="cls-app-platform--component">
<div class="countdown-component" >
    <div class="cls-app-platform--component-countdown">
        <div class="cls-app-platform--component-countdown-frame" [ngClass]="{ 'running': isRunning, 'stopped': !isRunning, 'warning': isWarning, 'danger': isDanger }">
            <div class="time-unit">
                <span class="label">Dias</span>
                <div class="digit">{{ days }}</div>                
            </div>
            <div class="separator">-</div>
            <div class="time-unit">
                <span class="label">Horas</span>
                <div class="digit">{{ hours }}</div>                
            </div>
            <div class="separator">:</div>
            <div class="time-unit">
                <span class="label">Minutos</span>
                <div class="digit">{{ minutes }}</div>                
            </div>
            <div class="separator">:</div>
            <div class="time-unit">
                <span class="label">Segundos</span>
                <div class="digit">{{ seconds }}</div>                
            </div>
        </div>
    </div>
</div>