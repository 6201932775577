//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleCanttechService {
  //#region "|--- PROPERTIES---|" 
  urlCanttechModuleService: string;
  //#endregion

  constructor(private _http: HttpClient) {
    if (settingConfig.API_CANTTECH_PORT == "443") {
      this.urlCanttechModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlCanttechModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_CANTTECH_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- MÉTODOS DO FORMULÁRIO DE DADOS CANTTECH DATA ---|"
  /**
   * @author GASPAR
   * @date 2024-10-04
   * @version 1.0.0
   * 
   * @description Método responsável por SALVAR os dados relacionados ao módulo: CANTTECH DATA.
   * 
   * @param xAccountDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele
   * @returns 
   */
  saveAccountData(xAccountDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>(`${this.urlCanttechModuleService}/${settingConfig.API_CANTTECH_URL_SEGMENT}/canttech/account/data`, xAccountDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xAccountDataForm))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-10-04
   * @version 1.0.0
   * 
   * @description Método responsável por ATUALIZAR os dados relacionados ao módulo: CANTTECH DATA.
   * 
   * @param xAccountDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele.
   * @param xAccountDataId
   * @returns
   */
  updateAccountData(xAccountDataId: string, xAccountDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.put<any>(`${this.urlCanttechModuleService}/${settingConfig.API_CANTTECH_URL_SEGMENT}/canntech/account/data/${xAccountDataId}`, xAccountDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xAccountDataForm))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-10-04
   * @version 1.0.0
   * 
   * @description Método responsável por RECUPERAR TODOS os dados relacionados ao módulo: CANTTECH DATA.
   * 
   * @returns 
   */
  getAllAccountDataList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlCanttechModuleService}/${settingConfig.API_CANTTECH_URL_SEGMENT}/cantech/account/data/list`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Método responsável por RECUPERAR BY ID os dados relacionados ao módulo: CANTTECH DATA.
   * 
   * @param xAccountDataId
   * @returns 
   */
  getAccountDataById(xAccountDataId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlCanttechModuleService}/${settingConfig.API_CANTTECH_URL_SEGMENT}/canttech/account/data/${xAccountDataId}`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }
  //#endregion
}
