<article class="cls-frame-hrt01">
    <div class="cls-frame-hrt01-grid">
        <header class="cls-frame-hrt01-header">
            <div class="cls-cmn-box-container">
                <!--&lt;header&gt;
                <button (click)="toggleTheme()">Toggle Theme</button>-->
            </div>
        </header>
        <nav class="cls-frame-hrt01-nav">
            <div class="cls-cmn-box-container">
                <div class="cls-util-flex-grow-1">
                    <p-menubar [model]="this.arrMenuItems" />
                </div>
            </div>
        </nav>
        <main class="cls-frame-hrt01-main">
            <div class="cls-cmn-box-container">
                <router-outlet></router-outlet>
            </div>
        </main>
        <footer class="cls-frame-hrt01-footer">
            <div class="cls-cmn-box-container">
                <!--&lt;footer&gt;-->
            </div>
        </footer>
    </div>
</article>