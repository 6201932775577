<div class="cls-app-platform--component">
    <div class="cls-app-platform--component-addresses">
        <p-table [value]="this.formComponent.controls" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true"
            [rows]="5" [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
            currentPageReportTemplate="Página {first} de {last}." [breakpoint]="'768px'" responsiveLayout="stack"
            class="cls-component-table-panel-list">
            <ng-template pTemplate="caption">
                <div class="cls-component-table-panel-list-caption">
                    <div class="cls-component-table-panel-list-caption-left">
                        Total:
                        {{this.formComponent ? this.formComponent.length : 0 }}
                    </div>
                    <div class="cls-component-table-panel-list-caption-right">
                        <div>
                            <p-button styleClass="cls-component-button" label="NOVO" icon="fa-solid fa-plus"
                                severity="secondary"
                                (click)="callbackTableAddressClickButton(this.buttonActions.NEW)" />
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%; text-align: center">Tipo</th>
                    <th style="width:10%">CEP</th>
                    <th style="width:15%;">Pais</th>
                    <th style="width:15%;">Cidade</th>
                    <th style="width:15%;">Estado</th>
                    <th style="width:35%;">Endereço</th>
                    <th style="width:10%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                <tr>
                    <td>{{this.getAddressTypeName(itemArray.controls.address_type.value)}}</td>
                    <td>{{itemArray.controls.zip_code.value}}</td>
                    <td>{{this.getCountryName(itemArray.controls.country.value)}}</td>
                    <td>{{itemArray.controls.city.value}}</td>
                    <td>{{getStateName(itemArray.controls.state.value)}}</td>
                    <td>{{itemArray.controls.address.value}}</td>
                    <td>
                        <div class="cls-component-table-page-list-td-button">
                            <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true" severity="info"
                                (onClick)="callbackItemClickButton(this.buttonActions.EDIT, itemArray.controls.address_id.value)" />
                            <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true" [outlined]="true"
                                (onClick)="callbackItemClickButton(this.buttonActions.DELETE, itemArray.controls.address_id.value)" />
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-dialog [modal]="true" [(visible)]="this.bolShowDialog" [style]="{ width: '70rem', height: '50rem'  }"
        class="cls-component-dialog-form">
        <ng-template pTemplate="headless">
            <p-panel class="cls-component-panel-default" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span> Endereço</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">

                                <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                    <lib-ui-primeng-drop-down-flag label="País #BOLD#"
                                        [control]="this.currentFormAddress.controls.country"
                                        [arrayOptions]="this.optionsNationalityCountries" appendDropdownTo="body">
                                    </lib-ui-primeng-drop-down-flag>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                    <lib-ui-primeng-input-text label="CEP #BOLD#"
                                        [control]="this.currentFormAddress.controls.zip_code">
                                    </lib-ui-primeng-input-text>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                    <lib-ui-primeng-drop-down label="Tipo Endereço #BOLD#"
                                        [control]="this.currentFormAddress.controls.address_type"
                                        [arrayOptions]="this.optionsAddressType" appendDropdownTo="body">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                    <lib-ui-primeng-input-text label="Endereço #BOLD#"
                                        [control]="this.currentFormAddress.controls.address">
                                    </lib-ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                    <lib-ui-primeng-input-text label="Número #BOLD#"
                                        [control]="this.currentFormAddress.controls.number">
                                    </lib-ui-primeng-input-text>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                    <lib-ui-primeng-input-text label="Complemento "
                                        [control]="this.currentFormAddress.controls.complement">
                                    </lib-ui-primeng-input-text>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                    <lib-ui-primeng-input-text label="Bairro #BOLD#"
                                        [control]="this.currentFormAddress.controls.district">
                                    </lib-ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                    <ng-container *ngIf="this.currentFormAddress.controls.country.value !== 'BR'">
                                        <lib-ui-primeng-input-text label="Estado #BOLD#"
                                            [control]="this.currentFormAddress.controls.state">
                                        </lib-ui-primeng-input-text>
                                    </ng-container>
                                    <ng-container *ngIf="this.currentFormAddress.controls.country.value === 'BR'">
                                        <lib-ui-primeng-drop-down label="Estado #BOLD#"
                                            [control]="this.currentFormAddress.controls.state"
                                            [arrayOptions]="this.optionsBrStates" appendDropdownTo="body">
                                        </lib-ui-primeng-drop-down>
                                    </ng-container>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-8">
                                    <lib-ui-primeng-input-text label="Cidade #BOLD#"
                                        [control]="this.currentFormAddress.controls.city">
                                    </lib-ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                    <ui-primeng-input-text-area label="Obervação"
                                        [control]="this.currentFormAddress.controls.address_remarks">
                                    </ui-primeng-input-text-area>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="footer">
                    <p-toolbar class="cls-component-toolbar">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-center"></div>
                        <div class="p-toolbar-group-end">
                            <p-button label="Sair" styleClass="cls-component-button" [rounded]="true"
                                icon="fa-solid fa-right-from-bracket" severity="danger"
                                (click)="callbackDialogClickButton(this.buttonActions.EXIT)" />
                            <ng-container *ngIf="this.currentFormAddress.controls.address_id.value !== null">
                                <p-button label="Salvar Modificações" styleClass="cls-component-button" [rounded]="true"
                                    icon="fa-solid fa-save" severity="secondary" class="mr-2"
                                    (click)="callbackDialogClickButton(this.buttonActions.UPDATE)" />
                            </ng-container>
                            <ng-container *ngIf="this.currentFormAddress.controls.address_id.value === null">
                                <p-button label="Salvar Novo" styleClass="cls-component-button" [rounded]="true"
                                    icon="fa-solid fa-save" severity="secondary" class="mr-2"
                                    (click)="callbackDialogClickButton(this.buttonActions.SAVE)" />
                            </ng-container>
                        </div>
                    </p-toolbar>
                </ng-template>
            </p-panel>
        </ng-template>
    </p-dialog>
</div>