<section class="cls-pge-app-form">
    <div class="cls-frame-dashboard-horizontal-main-form-grid-2-col-right">
        <section class="cls-frame-dashboard-horizontal-main-content">
            <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
            <div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                    <sunrise-info-box [objData]="this.objAircraftData"></sunrise-info-box>
                </div>
                <!--<div class="col-12 md:col-12 lg:col-12 xl:col-3">
                    <sunrise-info-box [objData]="this.objFlightPlanData"></sunrise-info-box>
                </div>
                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                    <sunrise-info-box [objData]="this.objCrewData"></sunrise-info-box>
                </div>
                <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                </div>-->
            </div>
            <div class="cls-cmp-ptv-form-gap"></div>
            <!--<div class="grid">
                <div class="col-12 md:col-12 lg:col-12 xl:col-4">
                    <p-card>
                        <p-dataView #dv [value]="this.arrayAircraftDataList">
                            <ng-template pTemplate="header">
                                <div class="flex flex-column md:flex-row md:justify-content-between">
                                   ORDENAÇÃO | FILTRO
                                </div>
                            </ng-template>
                            <ng-template pTemplate="list" let-aircraft>
                                <div class="col-12" *ngFor="let item of aircraft; let first = first" class="col-12">
                                    {{ item.register_data.aircraft_mark }}
                                </div>
                            </ng-template>
                        </p-dataView>
                    </p-card>
                </div>
                <div class="col-12 md:col-12 lg:col-12 xl:col-4">
                    <p-card>
                        PLANOS
                    </p-card>
                </div>
                <div class="col-12 md:col-12 lg:col-12 xl:col-4">
                    <p-card>
                        MAPA
                    </p-card>
                </div>
            </div>-->
        </section>
        <aside class="cls-frame-dashboard-horizontal-main-right">
            <div class="grid">
                <!--<div class="col-12 md:col-12 lg:col-12 xl:col-6">WIDGETS</div>-->
            </div>
        </aside>
    </div>
</section>