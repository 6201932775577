//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

// ***** COMPONENTS ***** //
import { HandlerErrorComponent } from '../_handle_error/_handler_error.component';

// ***** HANDLER ***** //
import { hasControlErrors } from '../_methods/_controls-methods';

//#region "|--- IMPORT PRIMENG ---|"
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-input-number-float',
  standalone: true,
  imports: [
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputNumberModule,
    HandlerErrorComponent,
    ReactiveFormsModule,
    TooltipModule
  ],
  templateUrl: './input-number-float.component.html'
})
export class InputNumberFloatComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() idField!: string;
  @Input() groupRightText!: string;
  @Input() groupLeftText!: string;
  @Input() groupRightIcon!: string;
  @Input() groupLeftIcon!: string;
  @Input() label!: string;
  @Input() minNumber!: number;
  @Input() maxNumber!: number;
  @Input() numberType!: string;
  @Input() toolTip?: string;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: number;

  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
    this._hasToShowToolTipIcon();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Verifica se o campo deve exibir o ícone de ToolTip.
   */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido.
   */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   */
  showErrors(): void {
    return hasControlErrors(this.control, "input");
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna o nome do campo, com base no idField.
   * 
   * @returns 
   */
  getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Função que verifica se foi passado um Valor mínimo, caso contrário manda um default igual a 0.
   * 
   * @returns
   */
  getMinNumber(): number {
    return this.minNumber ? this.minNumber : 0;
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Função que verifica se foi passado um Valor máximo, caso contrário manda um default igual a 100.
   * 
   * @returns
   */
  getMaxNumber(): number {
    return this.maxNumber ? this.maxNumber : 100;
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna o tipo do campo Number.
   * 
   * @returns 
   */
  getNumberType(): string {
    return this.numberType ? this.numberType : "decimal";
  }

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Verifica se o campo possui um grupo à esquerda.
   * 
   * @returns 
   */
  hastLeftGroup(): boolean {
    return this.groupLeftText !== undefined || this.groupLeftIcon !== undefined;
  }

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   *  @description Verifica se o campo possui um grupo à direita.
   * 
   * @returns 
   */
  hastRightGroup(): boolean {
    return this.groupRightText !== undefined || this.groupRightIcon !== undefined;
  }
  //#endregion
}
