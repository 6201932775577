//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** COMPONENT ***** //
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCgFuelUnits } from '../../../../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsCgArmUnits } from '../../../../../../../../_library/static_options/staticOptionsCgArmUnits';
import { staticOptionsCgWeightUnits } from '../../../../../../../../_library/static_options/staticOptionsCgWeightUnits';
import { staticOptionsCgSpeedUnits } from '../../../../../../../../_library/static_options/staticOptionsCgSpeedUnits';
import { staticOptionsFuelRateUnits } from '../../../../../../../../_library/static_options/staticOptionsFuelRateUnits';
import { staticOptionsFuelType } from '../../../../../../../../_library/static_options/staticOptionsFuelType';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../../../../_library/definitions/ButtonAction';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-specification-data',
  standalone: true,
  imports: [
    CommonModule,
    TitleFormTopicComponent,
    PrimengComponentsModule
  ],
  templateUrl: './tab-specification-data.component.html'
})
export class TabSpecificationDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabSpecificationData = new EventEmitter<string>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public buttonActions!: any;

  // ***** DROP-DOWN OPTIONS ***** //
  public optionsCgFuelUnits!: IDropDownOptionsBasic[];
  public optionsCgArmUnits!: IDropDownOptionsBasic[];
  public optionsCgWeightUnits!: IDropDownOptionsBasic[];
  public optionsCgSpeedUnits!: IDropDownOptionsBasic[];
  public optionsFuelRateUnits!: IDropDownOptionsBasic[];
  public optionsFuelType!: IDropDownOptionsBasic[];
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-21
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Peso e Balanceamento.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;

    this.optionsCgWeightUnits = staticOptionsCgWeightUnits;
    this.optionsCgArmUnits = staticOptionsCgArmUnits;
    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;
    this.optionsCgSpeedUnits = staticOptionsCgSpeedUnits;
    this.optionsFuelRateUnits = staticOptionsFuelRateUnits;
    this.optionsFuelType = staticOptionsFuelType;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-21
  * @version 1.0.0
  * 
  * @description Emite a ação do botão clicado para o componente pai.
  */
  callbackClickButton(xButtonAction: string): void {
    this.actionTabSpecificationData.emit(xButtonAction);
  }
  //#endregion
}
