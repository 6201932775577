//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { ApplicationConfig, provideZoneChangeDetection, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport, withNoXsrfProtection } from "@angular/common/http";
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

// ***** ROUTES ***** //
import { appRoutes } from './app.routes';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleIamAdmService } from './adm-platform/_services/module-iam-adm/module-iam.adm.service';
import { ModuleIamAppService } from './app-platform/_services/module-iam-app/module.iam.app.service';
//#endregion

//#region "|--- IMPORT INTERCEPTOR ---|"
import { AuthenticationAdmInterceptor } from './adm-platform/_interceptor/authentication-adm-interceptor/authentication.adm.interceptor';
import { AuthenticationAppInterceptor } from './app-platform/_interceptor/authentication-app-interceptor/authentication.app.interceptor';
//#endregion

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideHttpClient(withJsonpSupport(), withNoXsrfProtection(), withInterceptorsFromDi()),
    provideAnimations(),
    MessageService,
    ConfirmationService,    
    ModuleIamAdmService,
    { 
      provide: LOCALE_ID, 
      useValue: document.documentElement.lang
    }, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationAppInterceptor,
      multi: true
    },
    ModuleIamAppService    
  ]
};
