//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** CLASS ***** //
import { AviationToolsHandlerClass } from '../../../../../../../_classes/AviationToolsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { CheckCgEnvelopHandlerClass } from '../../../../../../../_classes/CheckCgEnvelopHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from "../../../../../../../../_library/layouts/components/primeng/primeng-components.module";

// ***** FORM ***** //
import { initWeightBalanceFilledForm } from '../../../../../form-init/weight-balance-filled-form';

// ***** SERVICES ***** //
import { ModuleWeightBalanceService } from '../../../../../../../_services/module-weight-balance/module-weight-balance.service';

// ***** COMPONENT ***** //
import { ChartCGLimitsComponent } from '../../../../../../../_components/chart/chart-cg-limits/chart-cg-limits.component';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsFuelType } from '../../../../../../../../_library/static_options/staticOptionsFuelType';
import { staticOptionsFuelRateUnits } from '../../../../../../../../_library/static_options/staticOptionsFuelRateUnits';
import { staticOptionsCgFuelUnits } from '../../../../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsCgArmUnits } from '../../../../../../../../_library/static_options/staticOptionsCgArmUnits';
import { staticOptionsCgWeightUnits } from '../../../../../../../../_library/static_options/staticOptionsCgWeightUnits';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { ICgComponents } from '../../../../../../../../_library/interfaces/ICgComponents';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { CG_COMPONENT_TYPE } from '../../../../../../../../_library/definitions/CgComponentType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
import { POSITION_CG_IN_ENVELOP } from '../../../../../../../../_library/definitions/PositionCgInEnvelop';
//#endregion
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-weight-balance-filled',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    ChartCGLimitsComponent,
    FormsModule,
    MenuModule,
    PrimengComponentsModule,
    ReactiveFormsModule
  ],
  templateUrl: './weight-balance-filled.component.html'
})
export class WeightBalanceFilledComponent implements OnInit, OnChanges {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() arrWeightBalanceSheet!: any
  @Input() objAircraft!: any
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public itemsMenuWeightPattern: MenuItem[] | undefined;
  public showAllUnits!: boolean;
  public cgComponentType!: any;
  public positionCgInEnvelop!: any;
  public objAviationTools!: any;
  public typeInputFuel!: any;

  public zfwPositionChartLong!: any;
  public zfwPositionChartLat!: any;
  public rwPositionChartLong!: any;
  public rwPositionChartLat!: any;
  public ldwPositionChartLong!: any;
  public ldwPositionChartLat!: any;
  public towPositionChartLong!: any;
  public towPositionChartLat!: any;

  // ***** ARRAY ***** //
  public arrLongPointsToDraw!: any[];
  public arrLatPointsToDraw!: any[];

  // ***** CLASSES ***** //
  public classAviationToolsMethods!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** DIALOG ***** //
  public setupShowWaitingDialog!: IWaitingDialog;
  public setupHiddenWaitingDialog!: IWaitingDialog;

  // ***** OPTIONS ***** //
  public optionsWeightBalanceSheet!: any;
  public optionsFuelType!: any;
  public optionsFuelRate!: any;
  public optionsCgWeightUnits!: any;
  public optionsCgArmUnits!: any;
  public optionsCgFuelUnits!: any;
  public optionsFuelRateUnits!: any;

  // ***** FORMS ***** // 
  public formWeightBalanceFilled: any
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initWeightBalanceFilledForm();
    this._initFormWeightBalanceSheetEvents();
  }

  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['arrWeightBalanceSheet']) {
      if (this.arrWeightBalanceSheet && this.arrWeightBalanceSheet !== null && this.arrWeightBalanceSheet.length > 0) {
        this.optionsWeightBalanceSheet = this.arrWeightBalanceSheet.map((xItem: any) => { return { label: xItem.header_data.name, value: xItem.weight_balance_data_id } });
      } else {
        this.optionsWeightBalanceSheet = [];
      }
    }
  }

  //region "|--- EVENT HANDLERS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-15
   * @version 1.0.0
   * 
   * @description Evento acionado quando o usuário seleciona uma Folha de Peso e Balanceamento.
   */
  private _initFormWeightBalanceSheetEvents() {
    this.formWeightBalanceFilled.get('fk_weight_balance_data_id').valueChanges.subscribe((xValue: ICgComponents[]) => {
      this._getWeightBalanceSheetById(xValue);
    });

    this.formWeightBalanceFilled.get('unit_data').get('fuel_rate').valueChanges.subscribe((xValue: string) => {
      this.formWeightBalanceFilled.get('flight_data').get('flight_fuel_rate_unit').setValue(xValue, { emitEvent: false });
      this.formWeightBalanceFilled.get('flight_data').get('taxi_fuel_rate_unit').setValue(xValue, { emitEvent: false });
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-15
   * @version 1.0.0
   * 
   * @description Inicializa os eventos dos controles do Array.
   * 
   * @param xControlName 
   */
  private _initArrayControlsEvents(xControlName: string) {
    this.formWeightBalanceFilled.get(xControlName).controls.forEach((xComponent: AbstractControl, xIndex: number) => {
      xComponent.get('weight')?.valueChanges.subscribe((xValue: any) => {
        const tempWeight = xValue;

        if (xControlName == "components_input_data") {
          const tempLongitudinalArm = this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.longitudinal_arm.value;
          const tempLateralArm = this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.lateral_arm.value;

          this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
          this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });
        } else if (xControlName == "components_external_data") {
          const tempLongitudinalArm = this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.longitudinal_arm.value;
          const tempLateralArm = this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.lateral_arm.value;

          this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
          this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });
        } else if (xControlName == "components_fuel_data") {
          const tempLongitudinalArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.longitudinal_arm.value;
          const tempLateralArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.lateral_arm.value;

          this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
          this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });
        }

        this._processCgCalculation();
      });
    })
  }
  //endregion

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.objAviationTools = new AviationToolsHandlerClass();

    this.typeInputFuel = "knob";

    this.arrLongPointsToDraw = [];
    this.arrLatPointsToDraw = [];

    this.cgComponentType = CG_COMPONENT_TYPE;
    this.positionCgInEnvelop = POSITION_CG_IN_ENVELOP;

    this.showAllUnits = false;

    this.optionsFuelType = staticOptionsFuelType;
    this.optionsFuelRateUnits = staticOptionsFuelRateUnits;
    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;
    this.optionsCgArmUnits = staticOptionsCgArmUnits;
    this.optionsCgWeightUnits = staticOptionsCgWeightUnits;

    this.setupShowWaitingDialog = {
      show_dialog: true,
      type_waiting: DIALOG_WAITING_TYPE.FLIGHT_WEIGHT_BALANCE_LIST
    };

    this.setupHiddenWaitingDialog = {
      show_dialog: false,
      type_waiting: DIALOG_WAITING_TYPE.FLIGHT_WEIGHT_BALANCE_LIST
    };

    this.classAviationToolsMethods = AviationToolsHandlerClass;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;

    this.itemsMenuWeightPattern = [
      {
        label: 'Options',
        items: [
          {
            label: 'Refresh',
            icon: 'pi pi-refresh'
          },
          {
            label: 'Export',
            icon: 'pi pi-upload'
          }
        ]
      }
    ];
  }

  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Peso e Balanceamento para ser Preenchido.
   */
  private _initWeightBalanceFilledForm(): void {
    this.formWeightBalanceFilled = initWeightBalanceFilledForm();
  }

  /**
  * @author GASPAR
  * @date 2024-09-30
  * @version 1.0.0
  * 
  * @description Recupera a Folha de Peso e Balanceamento pelo ID.
  * 
  * @param xIdRegister 
  */
  private async _getWeightBalanceSheetById(xIdRegister: any): Promise<void> {
    this.actionShowWaitingDialog.emit(this.setupShowWaitingDialog);

    await this._moduleWeightBalanceService.getWeightBalanceSheetById(xIdRegister).subscribe({
      next: (xApiResponse) => {
        if (xApiResponse.status_code == HTTP_STATUS.OK) {
          const objFormBuilder: FormBuilder = new FormBuilder();

          const tempData = xApiResponse.data[0].data[0];
          const arrayElementsCalculation = ["Total Fuel", "Ramp Mass (RW)", "Taxi Fuel", "Take Off Weight (TOW)", "Flight Fuel", "Landing Weight (LDW)"]

          // ----- >>> PREENCHER OS DADOS AVULSOS <<< ----- //          
          this.formWeightBalanceFilled.controls.path_perfil_image.setValue(tempData.path_perfil_image, { emitEvent: false });
          this.formWeightBalanceFilled.controls.filled_to.controls.fk_aircraft_data_id.setValue(this.formComponent.controls.flight_request.controls.aircraft_data.controls.fk_aircraft_data_id.value, { emitEvent: false });
          this.formWeightBalanceFilled.controls.filled_to.controls.aircraft_mark.setValue(this.formComponent.controls.flight_request.controls.aircraft_data.controls.aircraft_mark.value, { emitEvent: false });

          // ----- >>> PREENCHER OS DADOS DO CABEÇALHO <<< ----- //
          this.formWeightBalanceFilled.controls.header_data.patchValue(tempData.header_data, { emitEvent: false });

          // ----- >>> PREENCHER OS DAS UNIDADES <<< ----- //
          this.formWeightBalanceFilled.controls.unit_data.patchValue(tempData.unit_data, { emitEvent: false });
          this.formWeightBalanceFilled.controls.unit_data.controls.chart_x_unit.setValue(tempData.unit_data.arm, { emitEvent: false });
          this.formWeightBalanceFilled.controls.unit_data.controls.chart_y_unit.setValue(tempData.unit_data.weight, { emitEvent: false });

          // ----- >>> RECUPERA OS DADOS INPUT <<< ----- //
          if (tempData.components_input_data && tempData.components_input_data.length > 0) {
            tempData.components_input_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_input_data.controls.push(objFormBuilder.group(xData));
            });

            this._initArrayControlsEvents("components_input_data");
          }

          // ----- >>> RECUPERA OS DADOS CONSTANT <<< ----- //
          if (tempData.components_constant_data && tempData.components_constant_data.length > 0) {
            tempData.components_constant_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_constant_data.controls.push(objFormBuilder.group(xData));
            });

            this._initArrayControlsEvents("components_constant_data");
          }

          // ----- >>> RECUPERA OS DADOS FUEL <<< ----- //
          if (tempData.components_fuel_data && tempData.components_fuel_data.length > 0) {
            tempData.components_fuel_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_fuel_data.controls.push(objFormBuilder.group(xData));
            });

            this._initArrayControlsEvents("components_fuel_data");
          }

          // ----- >>> RECUPERA OS DADOS EXTERNAL <<< ----- //
          if (tempData.components_external_data && tempData.components_external_data.length > 0) {
            tempData.components_external_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_external_data.controls.push(objFormBuilder.group(xData));
            });

            this._initArrayControlsEvents("components_external_data");
          }

          // ----- >>> PREENCHER A TABELA MATEMÁTICA DE CALCULO DO CG <<< ----- //
          // Basic Empty Mass (BEM)
          this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value.push({
            calculate_id: null,
            order_show: 1,
            label: "Basic Empty Mass (BEM)",
            weight: tempData.weight_data.bem.weight,
            longitudinal_arm: tempData.weight_data.bem.longitudinal_arm,
            longitudinal_moment: (tempData.weight_data.bem.weight * tempData.weight_data.bem.longitudinal_arm),
            lateral_arm: tempData.weight_data.bem.lateral_arm,
            lateral_moment: (tempData.weight_data.bem.weight * tempData.weight_data.bem.lateral_arm),
            weight_unit: tempData.unit_data.weight_unit,
            arm_unit: tempData.unit_data.arm_unit
          });

          // Zero Few Weight (ZFW) -- Preencher com o valor do BEM, pois o formulário está em branco, no inicio do processo.
          this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value.push({
            calculate_id: null,
            order_show: 2,
            label: "Zero Few Weight (ZFW)",
            weight: tempData.weight_data.bem.weight,
            longitudinal_arm: tempData.weight_data.bem.longitudinal_arm,
            longitudinal_moment: (tempData.weight_data.bem.weight * tempData.weight_data.bem.longitudinal_arm),
            lateral_arm: tempData.weight_data.bem.lateral_arm,
            lateral_moment: (tempData.weight_data.bem.weight * tempData.weight_data.bem.lateral_arm),
            weight_unit: tempData.unit_data.weight_unit,
            arm_unit: tempData.unit_data.arm_unit
          });

          arrayElementsCalculation.forEach((xElement: string, xIndex: number) => {
            this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value.push({
              calculate_id: null,
              order_show: 3 + xIndex, // 3 é o valor inicial, pois já temos 2 elementos na tabela e o primeiro valor de Index é 0.
              label: xElement,
              weight: 0,
              longitudinal_arm: 0,
              longitudinal_moment: 0,
              lateral_arm: 0,
              lateral_moment: 0,
              weight_unit: tempData.unit_data.weight_unit,
              arm_unit: tempData.unit_data.arm_unit
            });
          });

          // ----- >>> ATRIBUI OS VALORES QUE FORMAM O GRÁFICO DO C.G. LONGITUDINAL << ----- //
          if (tempData.chart_longitudinal_data && tempData.chart_longitudinal_data.length > 0) {
            tempData.chart_longitudinal_data.forEach((xData: any, xIndex: number) => {
              this.formWeightBalanceFilled.controls.chart_longitudinal_data.push(objFormBuilder.group(xData));

              // Tem um problema o comando acima, ele transforma o array limits_data em um objeto, então tem que forçar o patchValue e sobrepor o objeto pelo array.            
              this.formWeightBalanceFilled.controls.chart_longitudinal_data.controls[xIndex].controls.limits_data.patchValue(xData.limits_data);
            });
          }

          // ----- >>> ATRIBUI OS VALORES QUE FORMAM O GRÁFICO DO C.G. LATERAL << ----- //
          if (tempData.chart_lateral_data && tempData.chart_lateral_data.length > 0) {
            tempData.chart_lateral_data.forEach((xData: any, xIndex: number) => {
              this.formWeightBalanceFilled.controls.chart_lateral_data.push(objFormBuilder.group(xData));

              // Tem um problema o comando acima, ele transforma o array limits_data em um objeto, então tem que forçar o patchValue e sobrepor o objeto pelo array.            
              this.formWeightBalanceFilled.controls.chart_lateral_data.controls[xIndex].controls.limits_data.patchValue(xData.limits_data);
            });
          }

          // ----- >>> CRIAR A MATRIX DE ELEMENTOS <<< ----- //
          this.formWeightBalanceFilled.controls.matrixOfElements.value = [];

          // Criar uma matriz com todos os elementos false, mas que tenha a mesma quantidade de elementos do formulário.     
          // 0 - Input Data
          // 1 - External Data
          // 2 - Constant Data
          // 3 - Fuel Data     
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(tempData.components_input_data.map(() => false));
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(tempData.components_external_data.map(() => false));
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(tempData.components_constant_data.map(() => false));
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(tempData.components_fuel_data.map(() => false));
        }

        // ----- >>> REALIZA O PROCESSAMENTO DO CG <<< ----- //
        this._processCgCalculation();

        this.actionShowWaitingDialog.emit(this.setupHiddenWaitingDialog);
      },
      error: (xError) => {
        this.actionShowWaitingDialog.emit(this.setupHiddenWaitingDialog);

        this._messageService.add({
          severity: 'error',
          summary: 'Recuperar a Folha de Peso e Balanceamento',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   *
   * @description Processa o Cálculo do C.G. 
   */
  private _processCgCalculation(): void {
    // TODAS AS UNIDADES QUE SERÃO UTILIZADAS NO CÁLCULO DO C.G. DEVE SER CONVERTIDAS PARA ESTAS UNIDADES ANTES DE FAZER QUALQUER CALCULO.
    const weightFinalUnit = this.formWeightBalanceFilled.controls.unit_data.controls.weight.value;
    const ArmFinalUnit = this.formWeightBalanceFilled.controls.unit_data.controls.arm.value;

    // ----->>> CALCULA O CONSUMO DO TEMPO DE VOO E DE TAXI <<<----- // 
    const tempFuelType = this.formWeightBalanceFilled.controls.flight_data.controls.fuel_type.value;

    // Consumo Taxi
    const tempTaxiFuelRate = this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate.value;
    const tempTaxiFuelRateUnit = this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate_unit.value;
    const tempTaxiTime = this.formWeightBalanceFilled.controls.flight_data.controls.taxi_eet.value;
    let tempTaxiFuelConsumption = this.objAviationTools.calculateFuelConsumption(tempFuelType, tempTaxiFuelRate, tempTaxiFuelRateUnit, tempTaxiTime, weightFinalUnit);
    tempTaxiFuelConsumption = parseFloat(tempTaxiFuelConsumption.toFixed(2));

    // Consumo Voo
    const tempFlightFuelRate = this.formWeightBalanceFilled.controls.flight_data.controls.flight_fuel_rate.value;
    const tempFlightFuelRateUnit = this.formWeightBalanceFilled.controls.unit_data.controls.fuel_rate.value;
    const tempFlightTime = this.formWeightBalanceFilled.controls.flight_data.controls.flight_eet.value;
    let tempFlightFuelConsumption = this.objAviationTools.calculateFuelConsumption(tempFuelType, tempFlightFuelRate, tempFlightFuelRateUnit, tempFlightTime, weightFinalUnit);
    tempFlightFuelConsumption = parseFloat(tempFlightFuelConsumption.toFixed(2));

    // ----->>> ZERA AS LINHAS DA TABELA PARA REFAZER TODA A OPERAÇÃO <<<----- //
    for (let i = 1; i < 8; i++) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[i].weight = 0;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[i].longitudinal_arm = 0;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[i].longitudinal_moment = 0;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[i].lateral_arm = 0;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[i].lateral_moment = 0;
    }

    // ----->>> INICIALIZAR OS CAMPOS IMPAR COM O PESO BÁSICO <<<----- //
    for (let j = 1; j < 8; j = j + 2) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[j].weight = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].weight;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[j].longitudinal_arm = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_arm;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[j].longitudinal_moment = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_moment;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[j].lateral_arm = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_arm;
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[j].lateral_moment = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_moment;
    }

    // ----->>> CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE <<<----- //
    // Linha 0 da matrixOfElements =>  components_input_data;
    // Linha 1 da matrixOfElements =>  components_external_data;
    // Linha 2 da matrixOfElements =>  components_constant_data;
    // Linha 3 da matrixOfElements =>  components_fuel_data;    
    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_input_data
    for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[0].length; i++) {
      if (this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i]) {
        // ----->>> CALCULAR ZFW <<<----- //
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight += this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment += this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.longitudinal_moment.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment += this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.lateral_moment.value;
      }
    }

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_external_data
    for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[1].length; i++) {
      if (this.formWeightBalanceFilled.controls.matrixOfElements.value[1][i]) {
        // ----->>> CALCULAR ZFW <<<----- //
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight += this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.weight.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment += this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.longitudinal_moment.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment += this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.lateral_moment.value;
      }
    }

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_fuel_data
    for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[3].length; i++) {
      if (this.formWeightBalanceFilled.controls.matrixOfElements.value[3][i]) {
        const tempFuelWeight = this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight.value;
        const tempCurrentFuelUnit = this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value;

        // PRECISA CONVERTER A UNIDADE DE MEDIDA DO COMBUSTÍVEL PARA O DE PESO.        
        const tempWeightOfficialUnit = this.objAviationTools.convertFuelUnit(tempFuelType, tempFuelWeight, tempCurrentFuelUnit, weightFinalUnit)

        // CALCULA O TOTAL FUEL WEIGHT  
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight += tempWeightOfficialUnit;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_moment += this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.longitudinal_moment.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_moment += this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.lateral_moment.value;
      }
    }

    // CALCULAR O BRAÇO LONGITUDINAL DO ZERO FUEL WEIGHT (ZFW)
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_arm = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight).toFixed(2));

    // CALCULAR O BRAÇO LATERAL DO ZERO FUEL WEIGHT (ZFW)
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_arm = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight).toFixed(2));

    // ----->>> CALCULAR TOTAL FUEL <<<----- //
    const tempTotalFuelLongArm = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight;
    const tempTotalFuelLatArm = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight;

    // Verifica se os braços são numeros e diferentes de infinito e 
    if (typeof tempTotalFuelLongArm === 'number' && isFinite(tempTotalFuelLongArm)) {
      // CALCULAR O BRAÇO LONGITUDINAL DO TOTAL FUEL
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_arm = parseFloat((tempTotalFuelLongArm).toFixed(2));
    }

    if (typeof tempTotalFuelLongArm === 'number' && isFinite(tempTotalFuelLatArm)) {
      // CALCULAR O BRAÇO LATERAL DO TOTAL FUEL
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_arm = parseFloat((tempTotalFuelLatArm).toFixed(2));
    }

    // ----->>> CALCULAR RAMP WEIGHT (RW) <<<----- //    
    const tempRwWeight = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight;
    const tempRwLongMom = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_moment;
    const tempRwLatMom = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_moment;
    const tempRwLongArm = tempRwLongMom / tempRwWeight;
    const tempRwLatArm = tempRwLatMom / tempRwWeight;

    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].weight = tempRwWeight;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].longitudinal_moment = tempRwLongMom;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].lateral_moment = tempRwLatMom;

    if (typeof tempRwLongArm === 'number' && isFinite(tempRwLongArm)) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].longitudinal_arm = parseFloat((tempRwLongArm).toFixed(2));
    }

    if (typeof tempRwLatArm === 'number' && isFinite(tempRwLatArm)) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].lateral_arm = parseFloat((tempRwLatArm).toFixed(2));
    }

    // ----->>> CALCULAR COMBUSTÍVEL TAXI FUEL <<<----- //
    // O PESO VAI SER NEGATIVO, NÃO ESQUECER, POIS ESTA SENDO CONSUMIDO
    // !!! AQUI VOU TER QUE FAZER UM ESTUDO QUANDO FOR MAIS DE 1 TANQUE DE COMBUSTÍVEL. QUANTOS POR CENTO DE CADA TANQUE É CONSUMIDO NO TAXI    
    const tempTaxiFuelLongArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[0].controls.longitudinal_arm.value;
    const tempTaxiFuelLatArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[0].controls.lateral_arm.value;;
    const tempTaxiWeight = (-1 * tempTaxiFuelConsumption);

    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].weight = tempTaxiWeight;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].longitudinal_arm = tempTaxiFuelLongArm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].lateral_arm = tempTaxiFuelLatArm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].longitudinal_moment = tempTaxiWeight * tempTaxiFuelLongArm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].lateral_moment = tempTaxiWeight * tempTaxiFuelLatArm;

    // ----->>> CALCULAR TAKE OFF WEIGHT (TOW) <<<----- //
    const tempTowWeight = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].weight + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].weight;
    const tempTowLongMom = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].longitudinal_moment + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].longitudinal_moment;
    const tempTowLatMom = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].lateral_moment + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[4].lateral_moment;
    const tempTowLongArm = tempTowLongMom / tempTowWeight;
    const tempTowLatArm = tempTowLatMom / tempTowWeight;

    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].weight = parseFloat(tempTowWeight.toFixed(2));
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].longitudinal_moment = parseFloat(tempTowLongMom.toFixed(2));
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].lateral_moment = parseFloat(tempTowLatMom.toFixed(2));

    if (typeof tempTowLongArm === 'number' && isFinite(tempTowLongArm)) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].longitudinal_arm = parseFloat((tempTowLongArm).toFixed(2));
    }

    if (typeof tempTowLatArm === 'number' && isFinite(tempTowLatArm)) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].lateral_arm = parseFloat((tempTowLatArm).toFixed(2));
    }

    // ----->>> CALCULAR COMBUSTÍVEL CONSUMIDO VOO <<<----- //
    // !!! AQUI VOU TER QUE FAZER UM ESTUDO QUANDO FOR MAIS DE 1 TANQUE DE COMBUSTÍVEL. QUANTOS POR CENTO DE CADA TANQUE É CONSUMIDO NO VOO   
    const tempFlightFuelLongArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[0].controls.longitudinal_arm.value;
    const tempFlightFuelLatArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[0].controls.lateral_arm.value;;
    const tempFlightWeight = (-1 * tempFlightFuelConsumption);

    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].weight = tempFlightWeight;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].longitudinal_arm = tempFlightFuelLongArm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].lateral_arm = tempFlightFuelLatArm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].longitudinal_moment = tempFlightWeight * tempFlightFuelLongArm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].lateral_moment = tempFlightWeight * tempFlightFuelLatArm;

    // ----->>> CALCULAR LANDING OFF WEIGHT (LDW) <<<----- //
    const tempLdwWeight = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].weight + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].weight;
    const tempLdwLongMom = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].longitudinal_moment + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].longitudinal_moment;
    const tempLdwLatMom = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].lateral_moment + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[6].lateral_moment;
    const tempLdwLongArm = tempLdwLongMom / tempLdwWeight;
    const tempLdwLatArm = tempLdwLatMom / tempLdwWeight;

    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].weight = parseFloat(tempLdwWeight.toFixed(2));
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].longitudinal_moment = parseFloat(tempLdwLongMom.toFixed(2));
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].lateral_moment = parseFloat(tempLdwLatMom.toFixed(2));

    if (typeof tempLdwLongArm === 'number' && isFinite(tempLdwLongArm)) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].longitudinal_arm = parseFloat((tempLdwLongArm).toFixed(2));
    }

    if (typeof tempLdwLatArm === 'number' && isFinite(tempLdwLatArm)) {
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].lateral_arm = parseFloat((tempLdwLatArm).toFixed(2));
    }

    // ----->>> INSERIR O PONTO ZFW <<<----- //     
    this.arrLongPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight,
        color: "#0000FF",
        label: "ZFW"
      }
    )

    this.arrLatPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight,
        color: "#0000FF",
        label: "ZFW"
      }
    )

    // ----->>> INSERIR O PONTO RW <<<----- //  
    /*this.arrLongPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].longitudinal_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].weight,
        color: "#800080",
        label: "RW"
      }
    )

    this.arrLatPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].lateral_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].weight,
        color: "#800080",
        label: "RW"
      }
    )*/

    // ----->>> INSERIR O PONTO TOW <<<----- // 
    this.arrLongPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].longitudinal_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].weight,
        color: "#ff8c00",
        label: "TOW"
      }
    )

    this.arrLatPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].lateral_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].weight,
        color: "#ff8c00",
        label: "TOW"
      }
    )

    // ----->>> INSERIR O PONTO LDW <<<----- // 
    this.arrLongPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].longitudinal_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].weight,
        color: "#800000",
        label: "LDW"
      }
    )

    this.arrLatPointsToDraw.push(
      {
        x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].lateral_arm,
        y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].weight,
        color: "#800000",
        label: "LDW"
      }
    )




    // ----- >>> CALCULAR SE OS PONTOS ESTÃO DENTRO DO ENVELOPE LONGITUDINAL E LATERAL <<< ----- //            
    let tempLongitudinalPoints = this.formWeightBalanceFilled.controls.chart_longitudinal_data.controls[0].controls.limits_data.value[0].coordinates;
    let tempLateralPoints = this.formWeightBalanceFilled.controls.chart_lateral_data.controls[0].controls.limits_data.value[0].coordinates;

    // Remove a Propriedade "component_id", pois só preciso das coordenadas X e Y
    tempLongitudinalPoints = tempLongitudinalPoints.map((xItem: any) => { return { x: xItem.x, y: xItem.y } });
    tempLateralPoints = tempLateralPoints.map((xItem: any) => { return { x: xItem.x, y: xItem.y } });

    const objCheckCgLongitudinal = new CheckCgEnvelopHandlerClass(tempLongitudinalPoints);
    const objCheckCgLateral = new CheckCgEnvelopHandlerClass(tempLateralPoints);

    // ***** CALCULAR O PONTO ZFW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    const tempZfwLongitudinal = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight
    }

    const tempZfwLateral = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight
    }

    this.zfwPositionChartLong = objCheckCgLongitudinal.checkPoint(tempZfwLongitudinal);
    this.zfwPositionChartLat = objCheckCgLateral.checkPoint(tempZfwLateral);

    // ***** CALCULAR O PONTO RW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    const tempRwLongitudinal = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].longitudinal_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].weight
    }

    const tempRwLateral = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].lateral_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].weight
    }

    this.rwPositionChartLong = objCheckCgLongitudinal.checkPoint(tempRwLongitudinal);
    this.rwPositionChartLat = objCheckCgLateral.checkPoint(tempRwLateral);

    // ***** CALCULAR O PONTO TOW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    const tempTowLongitudinal = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].longitudinal_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].weight
    }

    const tempTowLateral = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].lateral_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[5].weight
    }

    this.towPositionChartLong = objCheckCgLongitudinal.checkPoint(tempTowLongitudinal);
    this.towPositionChartLat = objCheckCgLateral.checkPoint(tempTowLateral);

    // ***** CALCULAR O PONTO LDW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    const tempLdwLongitudinal = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].longitudinal_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].weight
    }

    const tempLdwLateral = {
      x: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].lateral_arm,
      y: this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[7].weight
    }

    this.ldwPositionChartLong = objCheckCgLongitudinal.checkPoint(tempLdwLongitudinal);
    this.ldwPositionChartLat = objCheckCgLateral.checkPoint(tempLdwLateral);

  }
  //endregion

  //#region "|--- PUBLIC METHODS ---|"  
  public getClassCgInChart(xIndex: number, xChart: string): string {
    let tempPosition = "";

    if (xIndex === 1) { // ZFW
      if (xChart === "long") {
        tempPosition = this.zfwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.zfwPositionChartLat;
      }
    } else if (xIndex === 3) { // RW
      if (xChart === "long") {
        tempPosition = this.rwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.rwPositionChartLat;
      }
    } else if (xIndex === 5) { // TOW
      if (xChart === "long") {
        tempPosition = this.towPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.towPositionChartLat;
      }
    } else if (xIndex === 7) { // LDW
      if (xChart === "long") {
        tempPosition = this.ldwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.ldwPositionChartLat;
      }
    }

    if (tempPosition === this.positionCgInEnvelop.INSIDE) {
      return "cls-component-cg-inside";
    } else if (tempPosition === this.positionCgInEnvelop.INSIDE_NEAR_BOARD) {
      return "cls-component-cg-inside-near-board";
    } else if (tempPosition === this.positionCgInEnvelop.OUTSIDE) {
      return "cls-component-cg-outside";
    } else {
      return "";
    }
  }

  public normalizingFuelRateUnit(xValue: string): string {
    if (xValue && xValue !== null) {
      return xValue.replaceAll("_", "/");
    } else {
      return "";
    }
  }

  public isPilotSeat(xValue: string): boolean {
    return xValue === this.cgComponentType.PILOT_SEAT;
  }

  public isPaxSeat(xValue: string): boolean {
    return xValue === this.cgComponentType.PAX_SEAT;
  }

  public isInternalLoad(xValue: string): boolean {
    return xValue === this.cgComponentType.LUGGAGE_RACK
      || xValue === this.cgComponentType.INTERNAL_LOAD
      || xValue === this.cgComponentType.BALLAST
      || xValue === this.cgComponentType.STRETCHER
      || xValue === this.cgComponentType.OTHERS;
  }

  public isExternalLoad(xValue: string): boolean {
    return xValue === this.cgComponentType.LUGGAGE_RACK
      || xValue === this.cgComponentType.BASKET_LOAD
      || xValue === this.cgComponentType.EXTERNAL_LOAD
      || xValue === this.cgComponentType.HOIST_LOAD
      || xValue === this.cgComponentType.BALLAST
      || xValue === this.cgComponentType.OTHERS;
  }

  public isRemovable(xValue: string): boolean {
    return xValue === this.cgComponentType.CYCLIC
      || xValue === this.cgComponentType.COLLECTIVE
      || xValue === this.cgComponentType.PEDAL
      || xValue === this.cgComponentType.DOOR
      || xValue === this.cgComponentType.OTHERS;
  }

  public isFuel(xValue: string): boolean {
    return xValue === this.cgComponentType.FUEL
      || xValue === this.cgComponentType.OTHERS;
  }

  public convertItemTableFuelUnit(xFuelWeight: number, xWeightFinalUnit: string): number {
    const tempFuelType = this.formWeightBalanceFilled.controls.flight_data.controls.fuel_type.value;
    const tempCurrentFuelUnit = this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value;

    return this.objAviationTools.convertFuelUnit(tempFuelType, xFuelWeight, tempCurrentFuelUnit, xWeightFinalUnit)
  }

  public changeInputFuelInput() {
    return this.typeInputFuel === "input" ? this.typeInputFuel = "knob" : this.typeInputFuel = "input";
  }

  public handleKnobOnChange(xEvent: any, xComponent: any) {
    xComponent.setValue(xEvent);
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que manipular mudanças de estado do componente Switch.
   * 
   * @param xEvent 
   */
  public callbackOnChangeSwitch(xEvent: any): void {
    this._processCgCalculation();
  }

  defineMaxWeight(xComponent: any, xMainUnit: string): number {
    let tempMaxValue = 0;

    if (xComponent.controls.max_weight && xComponent.controls.max_weight.value != null && xComponent.controls.max_weight.value > 0) {
      tempMaxValue = xComponent.controls.max_weight.value;
    } else {
      if (xMainUnit == "kg") {
        tempMaxValue = 150
      } else if (xMainUnit == "lb") {
        tempMaxValue = 330
      }
    }

    return tempMaxValue;//xComponent.max_weight ? xComponent.max_weight : this.convertToOppositeWeightUnit(xComponent.max_weight, xMainUnit);
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que define o valores padrões MÁXIMO do Combustível.
   * 
   * @param xComponent 
   * @param xMainUnit 
   * @returns 
   */
  defineMaxFuel(xComponent: any, xMainUnit: string): number {
    let tempMaxValue = 0;

    if (xComponent.controls.max_weight && xComponent.controls.max_weight.value != null && xComponent.controls.max_weight.value > 0) {
      tempMaxValue = xComponent.controls.max_weight.value;
    } else {
      if (xMainUnit == "kg") {
        tempMaxValue = 5000
      } else if (xMainUnit == "lb") {
        tempMaxValue = 11023
      } else if (xMainUnit == "l") {
        tempMaxValue = 5000
      } else if (xMainUnit == "gal") {
        tempMaxValue = 11023
      }
    }

    return tempMaxValue;//xComponent.max_weight ? xComponent.max_weight : this.convertToOppositeWeightUnit(xComponent.max_weight, xMainUnit);
  }

  // Linha 0 da matrixOfElements =>  components_input_data;
  // Linha 1 da matrixOfElements =>  components_external_data;
  // Linha 2 da matrixOfElements =>  components_constant_data;
  // Linha 3 da matrixOfElements =>  components_fuel_data;  
  public createMenuItemCrewPaxData(xItem: any, xCurrentUnit: string): MenuItem[] {
    const tempMenuItems: MenuItem[] = [];

    tempMenuItems.push({
      label: '000,00 kg/000,00 Lb',
      icon: 'fa-solid fa-person',
      command: () => {
        xItem.controls.weight.setValue(0);
        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: '80,00 kg/176,37 Lb',
      icon: 'fa-solid fa-person',
      command: () => {
        if (xCurrentUnit == "kg") {
          xItem.controls.weight.setValue(80);
        } else {
          xItem.controls.weight.setValue(176.37);
        }

        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: '85,00 kg/187,39 Lb',
      icon: 'fa-solid fa-person',
      command: () => {
        if (xCurrentUnit == "kg") {
          xItem.controls.weight.setValue(85);
        } else {
          xItem.controls.weight.setValue(187.39);
        }

        this._processCgCalculation();
      }
    });

    return tempMenuItems;
  }

  public createMenuItemLoad(xItem: any, xCurrentUnit: string, xMaxWeight: any): MenuItem[] {
    const tempMenuItems: MenuItem[] = [];
    const tempLabel00 = "000,00 kg/000,00 Lb";

    let tempLabel25 = "";
    let tempLabel50 = "";
    let tempLabel75 = "";
    let tempLabel100 = "";
    let tempWeight25 = 0;
    let tempWeight50 = 0;
    let tempWeight75 = 0;
    let tempWeight100 = 0;

    if (xMaxWeight && xMaxWeight != null && xMaxWeight > 0) {
      tempWeight25 = xMaxWeight * 0.25;
      tempWeight50 = xMaxWeight * 0.50;
      tempWeight75 = xMaxWeight * 0.75;
      tempWeight100 = xMaxWeight;

      tempLabel25 = `${this.objAviationTools.convertWeightUnit(tempWeight25, xCurrentUnit, "kg")} kg/${this.objAviationTools.convertWeightUnit(tempWeight25, xCurrentUnit, "lb")} lb`;
      tempLabel50 = `${this.objAviationTools.convertWeightUnit(tempWeight50, xCurrentUnit, "kg")} kg/${this.objAviationTools.convertWeightUnit(tempWeight50, xCurrentUnit, "lb")} lb`;
      tempLabel75 = `${this.objAviationTools.convertWeightUnit(tempWeight75, xCurrentUnit, "kg")} kg/${this.objAviationTools.convertWeightUnit(tempWeight75, xCurrentUnit, "lb")} lb`;
      tempLabel100 = `${this.objAviationTools.convertWeightUnit(tempWeight100, xCurrentUnit, "kg")} kg/${this.objAviationTools.convertWeightUnit(tempWeight100, xCurrentUnit, "lb")} lb`;
    } else {

      tempWeight25 = xCurrentUnit == "kg" ? 25 : 55.11;
      tempWeight50 = xCurrentUnit == "kg" ? 50 : 110.23;
      tempWeight75 = xCurrentUnit == "kg" ? 75 : 165.34;
      tempWeight100 = xCurrentUnit == "kg" ? 100 : 220.46;

      tempLabel25 = "25,00 kg/55,11 lb";
      tempLabel50 = "50,00 kg/110,23 lb";
      tempLabel75 = "75,00 kg/165,34 lb";
      tempLabel100 = "100,00 kg/220,46 lb";
    }

    tempMenuItems.push({
      label: '000,00 kg/000,00 lb',
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(0);
        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel25,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight25);

        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel50,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight50);

        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel75,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight75);

        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel100,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight100);

        this._processCgCalculation();
      }
    });

    return tempMenuItems;
  }

  public createMenuItemFuel(xItem: any, xMaxWeight: any): MenuItem[] {
    const tempMenuItems: MenuItem[] = [];

    const tempLabel00 = "00% Tank";
    const tempLabel25 = "25% Tank";
    const tempLabel50 = "50% Tank";
    const tempLabel75 = "75% Tank";
    const tempLabel100 = "Full Tank";
    const tempWeight25 = xMaxWeight * 0.25;
    const tempWeight50 = xMaxWeight * 0.50;
    const tempWeight75 = xMaxWeight * 0.75;
    const tempWeight100 = xMaxWeight;

    tempMenuItems.push({
      label: tempLabel00,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(0);
        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel25,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight25);

        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel50,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight50);

        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel75,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight75);

        this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel100,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight100);

        this._processCgCalculation();
      }
    });

    return tempMenuItems;
  }

  public fuelPercent(xAmount: number, xTotal: number) {
    return parseFloat(((xAmount / xTotal) * 100).toFixed(2));
  }
  //endregion
}
