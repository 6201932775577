<div class="cls-ui-primeng-field cls-ui-primeng-field-input-text">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.label}}
        </label>   
        <p-inputGroup>         
            <ng-container *ngIf="this.hastLeftGroup()"> 
                <p-inputGroupAddon>
                    <span>{{this.groupLeftText}}</span>
                    <i class="{{this.groupLeftIcon}}"></i>
                </p-inputGroupAddon>
            </ng-container>            
            <input
                [formControl]="this.control"
                [ngClass]="{ 'is-danger': this.showErrors() }"
                (blur)="this.onBlurMethod()"
                (input)="this.transformLetterCase($event)"
                pTooltip="Enter your username"
                tooltipPosition="bottom" 
                type="text"
                name="{{this.getFieldName()}}" 
                id="{{this.idField}}" 
                [type]="this.getTypeInput()"
                pInputText />
                <ng-container *ngIf="this.hastRightGroup()"> 
                    <p-inputGroupAddon>
                        <span>{{this.groupRightText}}</span>
                        <i class="{{this.groupRightIcon}}"></i>  
                    </p-inputGroupAddon>
                </ng-container>            
        </p-inputGroup>
    </div>
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="input"></lib-ui-primeng-handler-error>
</div>

