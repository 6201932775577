//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService } from 'primeng/api';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** HANDLER ***** //
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';

// ***** FORM ***** //
import { initWeightBalanceDataForm } from '../../../../../../weight-balance/form-init/weight-balance-data-form';
import { initChartDataForm } from '../../../../../../weight-balance/form-init/chart-data-form';

// ***** MOCK ***** //
import { MockAircraftFormDataClass } from '../../../_mock/MockAircraftFormDataClass';

// ***** SERVICES ***** //
import { ModuleWeightBalanceService } from '../../../../../../../_services/module-weight-balance/module-weight-balance.service';

// ***** COMPONENT ***** //
import { ChartCGLimitsComponent } from '../../../../../../../_components/chart/chart-cg-limits/chart-cg-limits.component';
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../../../../_library/definitions/ButtonAction';
import { WEIGHT_BALANCE_FORMAT } from '../../../../../../../../_library/definitions/WeightBalanceFormat';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { ICgComponents } from '../../../../../../../../_library/interfaces/ICgComponents';
import { IPlotagemChart } from '../../../../../../../../_library/interfaces/IPlotagemChart';
import { IChartDrawLimitsCG } from '../../../../../../../../_library/interfaces/IChartDrawLimitsCG';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCgFuelUnits } from '../../../../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsCgArmUnits } from '../../../../../../../../_library/static_options/staticOptionsCgArmUnits';
import { staticOptionsCgWeightUnits } from '../../../../../../../../_library/static_options/staticOptionsCgWeightUnits';
import { staticOptionsAircraftTypes } from '../../../../../../../../_library/static_options/staticOptionsAircraftTypes';
import { staticOptionsChartScales } from '../../../../../../../../_library/static_options/staticOptionsChartScales';
import { staticOptionsCgComponentsConstant } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsConstant';
import { staticOptionsCgComponentsExternal } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsExternal';
import { staticOptionsCgComponentsFuel } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsFuel';
import { staticOptionsCgComponentsInput } from '../../../../../../../../_library/static_options/staticOptionsCgComponentsInput';
import { staticOptionsYesNo } from '../../../../../../../../_library/static_options/staticOptionsYesNo';
import { staticOptionsBasicColors } from '../../../../../../../../_library/static_options/staticOptionsBasicColors';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-weight-balance-sheet-form',
  standalone: true,
  imports: [
    ChartCGLimitsComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    PrimengComponentsModule,
    TitleFormTopicComponent
  ],
  templateUrl: './tab-weight-balance-sheet-form.component.html'
})
export class TabWeightBalanceSheetFormComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() set idWeightBalanceSheet(xValue: any) {
    if (xValue) {
      if (this.currentIdWeightBalanceSheet != xValue) {
        this.isToClone = false;

        if (xValue.indexOf("##TOCLONE##") > -1) {
          this.currentIdWeightBalanceSheet = xValue.replace("##TOCLONE##", "");
          this.isToClone = true;
        } else {
          this.currentIdWeightBalanceSheet = xValue;
        }
      }
    }
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabWeightBalanceSheetForm = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public buttonActions!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  currentIdWeightBalanceSheet: any = null;

  // ***** DROP-DOWN OPTIONS ***** //
  optionsCgFuelUnits!: IDropDownOptionsBasic[];
  optionsCgArmUnits!: IDropDownOptionsBasic[];
  optionsCgWeightUnits!: IDropDownOptionsBasic[];
  optionsAircraftTypes!: IDropDownOptionsIcon[];
  optionsChartScales!: IDropDownOptionsBasic[];
  optionsBasicColors!: IDropDownOptionsBasic[];
  optionsYesNo!: IDropDownOptionsBasic[];
  optionsCgComponents!: IDropDownOptionsBasic[];
  optionsCgComponentsConstant!: IDropDownOptionsBasic[];
  optionsCgComponentsExternal!: IDropDownOptionsBasic[];
  optionsCgComponentsFuel!: IDropDownOptionsBasic[];
  optionsCgComponentsInput!: IDropDownOptionsBasic[];

  // ***** AIRCRAFT COMPONENTS  ***** //
  cgComponentsInputs!: ICgComponents[];
  cgComponentsConstants!: ICgComponents[];
  cgComponentsFuel!: ICgComponents[];
  cgComponentsExternal!: ICgComponents[];
  cgPlotChartData!: IPlotagemChart[];

  chartDrawLimitsCGLongitudinal!: IChartDrawLimitsCG[];
  chartDrawLimitsCGLateral!: IChartDrawLimitsCG[];

  clonedCgComponents: { [s: string]: ICgComponents } = {};
  clonedCoordinates: { [s: string]: any } = {};

  isToClone!: boolean;

  // ***** FORMS ***** //
  formWeightBalanceSheet: any
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService
  ) { }

  ngOnInit(): void {
    this._initVariables();

    this._initWeightBalanceSheetForm();
    this._initFormWeightBalanceEvents();

    if (this.currentIdWeightBalanceSheet !== null) {
      this._getWeightBalanceSheetById(this.currentIdWeightBalanceSheet, this.isToClone);
    }

    if (this.isToClone == true) {
      this.currentIdWeightBalanceSheet = null;
    }

    if (this.currentIdWeightBalanceSheet == null) {
      //this.initMock();
    }
  }

  //#region "|--- MOCK METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private initMock(): void {
    this.cgComponentsInputs = MockAircraftFormDataClass.cgComponentsInput();
    this.cgComponentsConstants = MockAircraftFormDataClass.cgComponentsConstant();
    this.cgComponentsFuel = MockAircraftFormDataClass.cgComponentsFuel();
    this.cgComponentsExternal = MockAircraftFormDataClass.cgComponentsExternal();
    this.chartDrawLimitsCGLongitudinal = MockAircraftFormDataClass.chartDrawLimitsCG();
    this.chartDrawLimitsCGLateral = MockAircraftFormDataClass.chartDrawLimitsCG();

    // ADD LONGITUDINAL
    this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLongitudinal;
    // ADD LATERAL
    this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLateral;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;
    this.optionsCgArmUnits = staticOptionsCgArmUnits;
    this.optionsCgWeightUnits = staticOptionsCgWeightUnits;
    this.optionsAircraftTypes = staticOptionsAircraftTypes;
    this.optionsChartScales = staticOptionsChartScales;
    this.optionsBasicColors = staticOptionsBasicColors;
    this.optionsYesNo = staticOptionsYesNo;

    this.optionsCgComponentsConstant = staticOptionsCgComponentsConstant;
    this.optionsCgComponentsExternal = staticOptionsCgComponentsExternal;
    this.optionsCgComponentsFuel = staticOptionsCgComponentsFuel;
    this.optionsCgComponentsInput = staticOptionsCgComponentsInput;

    this.cgComponentsInputs = [];
    this.cgComponentsConstants = [];
    this.cgComponentsFuel = [];
    this.cgComponentsExternal = [];
    this.chartDrawLimitsCGLongitudinal = [];
    this.chartDrawLimitsCGLateral = [];
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de peso e balanço.
   */
  private _initWeightBalanceSheetForm(): void {
    this.formWeightBalanceSheet = initWeightBalanceDataForm(WEIGHT_BALANCE_FORMAT.SHEET);

    // Adiciona oos Dados do Chat de Limitações do CG.
    this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls.push(initChartDataForm(LocalMethodsHandlerClass.generateUuid(), 'longitudinal'));
    this.formWeightBalanceSheet.controls.chart_lateral_data.controls.push(initChartDataForm(LocalMethodsHandlerClass.generateUuid(), 'lateral'));

    // Configura as unidades do gráfico de Limitações de CG com as unidades.
    this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.x_unit.setValue(this.formWeightBalanceSheet.controls.unit_data.controls.arm.value);
    this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.y_unit.setValue(this.formWeightBalanceSheet.controls.unit_data.controls.weight.value);
    this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.x_unit.setValue(this.formWeightBalanceSheet.controls.unit_data.controls.arm.value);
    this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.y_unit.setValue(this.formWeightBalanceSheet.controls.unit_data.controls.weight.value);
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Inicializa os eventos do formulário de Peso e Balanceamento.
   */
  private _initFormWeightBalanceEvents(): void {
    this.formWeightBalanceSheet.get('unit_data.arm').valueChanges.subscribe((xValue: any) => {
      const tempLongCounter = this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls.length;
      const tempLateralCounter = this.formWeightBalanceSheet.controls.chart_lateral_data.controls.length;

      if (tempLongCounter > 0) {
        for (let i = 0; i < tempLongCounter; i++) {
          this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.x_unit.setValue(xValue);
        }
      }

      if (tempLateralCounter > 0) {
        for (let i = 0; i < tempLateralCounter; i++) {
          this.formWeightBalanceSheet.controls.chart_lateral_data.controls[i].controls.x_unit.setValue(xValue);
        }
      }
    });

    this.formWeightBalanceSheet.get('unit_data.weight').valueChanges.subscribe((xValue: any) => {
      const tempLongCounter = this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls.length;
      const tempLateralCounter = this.formWeightBalanceSheet.controls.chart_lateral_data.controls.length;

      if (tempLongCounter > 0) {
        for (let i = 0; i < tempLongCounter; i++) {
          this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.y_unit.setValue(xValue);
        }
      }

      if (tempLateralCounter > 0) {
        for (let i = 0; i < tempLateralCounter; i++) {
          this.formWeightBalanceSheet.controls.chart_lateral_data.controls[i].controls.y_unit.setValue(xValue);
        }
      }
    });

    for (let i = 0; i < this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls.length; i++) {
      this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].get('y_min').valueChanges.subscribe((xValue: any) => {
        const tempYMax = this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.y_max.value;
        if (xValue >= tempYMax) {
          const tempNewXMax = xValue * 1.5; // Aumenta 50% do valor.
          this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.y_max.setValue(tempNewXMax, { emitEvent: false });
        }
      });

      this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].get('y_max').valueChanges.subscribe((xValue: any) => {
        const tempYMin = this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.y_min.value;
        const stringValue = xValue.toString();
        const stringTempYMin = tempYMin.toString();

        if (stringValue.length >= stringTempYMin.length) {
          if (xValue <= tempYMin) {
            this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.y_min.setValue(0, { emitEvent: false });
          }
        }
      });

      this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].get('x_min').valueChanges.subscribe((xValue: any) => {
        const tempXMax = this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.x_max.value;
        if (xValue >= tempXMax) {
          const tempNewXMax = xValue * 1.5; // Aumenta 50% do valor.
          this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.x_max.setValue(tempNewXMax, { emitEvent: false });
        }
      });

      this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].get('x_max').valueChanges.subscribe((xValue: any) => {
        const tempXMin = this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.x_min.value;
        const stringValue = xValue.toString();
        const stringTempXMin = tempXMin.toString();
        if (stringValue.length >= stringTempXMin.length) {
          if (xValue <= tempXMin) {
            this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[i].controls.x_min.setValue(0, { emitEvent: false });
          }
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Limpa o formulário de Peso e Balanceamento.
   */
  private _clearFormWeightBalanceSheet(): void {
    this._initWeightBalanceSheetForm();
    this._initFormWeightBalanceEvents();
    this.currentIdWeightBalanceSheet = null;
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description Salva a Folha de Peso e Balanceamento.
   */
  private async _saveWeightBalanceSheet(): Promise<void> {
    if (this.formWeightBalanceSheet.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formWeightBalanceSheet, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Folha de Peso e Balanceamento',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      
      // ADICIONA O ID DO AIRCRAFT DATA ID no formulário do Weight Balance Sheet.
      let hasAircraftDataId = false;

      for(let i = 0; i < this.formWeightBalanceSheet.controls.belong_to_aircraft.value.length; i++) {  
        if (this.formWeightBalanceSheet.controls.belong_to_aircraft.value[i].aircraft_data_id == this.formComponent.controls.aircraft_data_id.value) {
          hasAircraftDataId = true;
          break;
        }         
      }

      if(!hasAircraftDataId) {
        this.formWeightBalanceSheet.controls.belong_to_aircraft.value.push(
          {
            "aircraft_data_id": this.formComponent.controls.aircraft_data_id.value,
            "mark": this.formComponent.controls.registration_data.controls.mark.value
          }
        );
      }

      // Este dados são fixos e tem origem no formulário do REGISTRATION DATA.
      this.formWeightBalanceSheet.controls.header_data.controls.aircraft_type.setValue(this.formComponent.controls.registration_data.controls.aircraft_type.value);
      this.formWeightBalanceSheet.controls.header_data.controls.aircraft_model.setValue(this.formComponent.controls.registration_data.controls.model.value);
      this.formWeightBalanceSheet.controls.header_data.controls.aircraft_icao_code.setValue(this.formComponent.controls.registration_data.controls.icao_code.value);

      // Tem que gravar os dados do Components antes de gravar a Folha de Peso e Balanceamento.
      this.formWeightBalanceSheet.controls.components_input_data.value = this.cgComponentsInputs;
      this.formWeightBalanceSheet.controls.components_constant_data.value = this.cgComponentsConstants;
      this.formWeightBalanceSheet.controls.components_fuel_data.value = this.cgComponentsFuel;
      this.formWeightBalanceSheet.controls.components_external_data.value = this.cgComponentsExternal;

      await this._moduleWeightBalanceService.saveWeightBalanceSheet(this.formWeightBalanceSheet).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
            // Adiciona o ID do Weight Balance Sheet ao formulário WEIGHT BALANCE SHEET
            this.formWeightBalanceSheet.controls.weight_balance_data_id.setValue(xApiResponse.data[0].data[0].id);

            // Adiciona o ID do Weight Balance Sheet ao formulário AIRCRAFT DATA.
            if (!this.formComponent.controls.fk_weight_balance_sheet_id.value.includes(xApiResponse.data[0].data[0].id)) {
              this.formComponent.controls.fk_weight_balance_sheet_id.value.push(xApiResponse.data[0].data[0].id);
            }

            // Emite a ação para atualizar os dados do AIRCRAFT DATA
            this.actionTabWeightBalanceSheetForm.emit({ "action": this.buttonActions.SAVE, "id": `${xApiResponse.data[0].data[0].id}` });

            // !!! Tem que colocar uma Tela de Espera.
            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Folha de Peso e Balanceamento',
              detail: `${xApiResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Folha de Peso e Balanceamento',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-29
   * @version 1.0.0
   * 
   * @description Busca a Folha de Peso e Balanceamento pelo ID.
   * 
   * @param xIdRegister 
   */
  private async _getWeightBalanceSheetById(xIdRegister: any, xIsToClone = false): Promise<void> {
    // !!! Tem que colocar uma Tela de Espera.
    await this._moduleWeightBalanceService.getWeightBalanceSheetById(xIdRegister).subscribe({
      next: (xResponse) => {
        if (xResponse.status_code == HTTP_STATUS.OK) {
          this.formWeightBalanceSheet.patchValue(xResponse.data[0].data[0]);

          this.cgComponentsInputs = xResponse.data[0].data[0].components_input_data;
          this.cgComponentsConstants = xResponse.data[0].data[0].components_constant_data;
          this.cgComponentsFuel = xResponse.data[0].data[0].components_fuel_data;
          this.cgComponentsExternal = xResponse.data[0].data[0].components_external_data;
          this.chartDrawLimitsCGLongitudinal = xResponse.data[0].data[0].chart_longitudinal_data[0].limits_data;
          this.chartDrawLimitsCGLateral = xResponse.data[0].data[0].chart_lateral_data[0].limits_data;

          if (xIsToClone == true) {
            this.formWeightBalanceSheet.controls.weight_balance_data_id.setValue(null);
            this.formWeightBalanceSheet.controls.header_data.controls.name.setValue(null);
            this.formWeightBalanceSheet.controls.header_data.controls.description_config.setValue(null);

            this.formWeightBalanceSheet.controls.header_data.controls.calibration_date_utc.setValue(null);
            this.formWeightBalanceSheet.controls.header_data.controls.calibration_next_date_utc.setValue(null);
            this.formWeightBalanceSheet.controls.header_data.controls.calibration_observation.setValue(null);

            // Tem que zerar as aeronaves pertecentes também
            this.formWeightBalanceSheet.controls.belong_to_aircraft.setValue([]);
          }
        }
      },
      error: (xError) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Recuperar a Folha de Peso e Balanceamento',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Atualiza a Folha de Peso e Balanceamento.
   */
  private async _updateWeightBalanceSheet(): Promise<void> {
    if (this.formWeightBalanceSheet.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formWeightBalanceSheet, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Atualizar Folha de Peso e Balanceamento',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      // Tem que gravar os dados do Components antes de gravar a Folha de Peso e Balanceamento.
      this.formWeightBalanceSheet.controls.components_input_data.value = this.cgComponentsInputs;
      this.formWeightBalanceSheet.controls.components_constant_data.value = this.cgComponentsConstants;
      this.formWeightBalanceSheet.controls.components_fuel_data.value = this.cgComponentsFuel;
      this.formWeightBalanceSheet.controls.components_external_data.value = this.cgComponentsExternal;

      // !!! Tem que colocar uma Tela de Espera.
      await this._moduleWeightBalanceService.updateWeightBalanceSheet(this.formWeightBalanceSheet.controls.weight_balance_data_id.value, this.formWeightBalanceSheet).subscribe({
        next: (xResponse) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            this._messageService.add({
              severity: 'success',
              summary: 'Atualizar Folha de Peso e Balanceamento',
              detail: `${xResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          let tempSeverity = 'error';

          if (xError.http_error == 404) {
            tempSeverity = 'info'
          }

          this._messageService.add({
            severity: tempSeverity,
            summary: 'Atualizar Folha de Peso e Balanceamento',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-24
  * @version 1.0.0
  * 
  * @description Emite a ação do botão clicado para o componente pai.
  */
  async callbackClickButton(xButtonAction: string): Promise<void> {
    if (xButtonAction == this.buttonActions.SAVE) {
      console.log("SALVA FOLHA DE PESO E BALANCEAMENTO");
      await this._saveWeightBalanceSheet();
    } else if (xButtonAction == this.buttonActions.UPDATE) {
      console.log("ATUALIZA FOLHA DE PESO E BALANCEAMENTO");
      this._updateWeightBalanceSheet();
    } else if (xButtonAction == this.buttonActions.LIST) {
      console.log("VOLTA A TAB QUE LISTA DE PESO E BALANCEAMENTO");
      this._clearFormWeightBalanceSheet();
      this.actionTabWeightBalanceSheetForm.emit(xButtonAction);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Adiciona um novo item na tabela de Componentes da Aeronave.
   * 
   * @param xTable 
   */
  onRowNew(xTable: string) {
    const tempId = "";
    const tempElement: ICgComponents = {
      component_id: LocalMethodsHandlerClass.generateUuid(),
      tags: [],
      type_component: "",
      description: "",
      label: "",
      weight: 0,
      max_weight: 0,
      min_weight: 0,
      ave_weight: 0,
      longitudinal_arm: 0,
      longitudinal_moment: 0,
      lateral_arm: 0,
      lateral_moment: 0,
      avatar_color: "#000000",
      confirm: false
    }

    if (xTable == "cgComponentsInput") {
      this.cgComponentsInputs.push(tempElement);
    } else if (xTable == "cgComponentsConstants") {
      this.cgComponentsConstants.push(tempElement);
    } else if (xTable == "cgComponentsFuel") {
      this.cgComponentsFuel.push(tempElement);
    } else if (xTable == "cgComponentsExternal") {
      this.cgComponentsExternal.push(tempElement);
    }

    //this.clonedCgComponents[tempId as string] = { ...tempElement };
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Remove um item da tabela de Componentes da Aeronave.
   * 
   * @param xItem 
   * @param xTable 
   */
  onRowDelete(xItem: any, xTable: string) {
    this._confirmationService.confirm({
      message: `Você deseja APAGAR o Componente do CG da Aeronave?`,
      header: 'Confirmação Apagar o Item da Tabela do Componentes do CG.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: () => {
        if (xTable == "cgComponentsInput") {
          this.cgComponentsInputs = this.cgComponentsInputs.filter((val) => val.component_id !== xItem.component_id);
        } else if (xTable == "cgComponentsConstants") {
          this.cgComponentsConstants = this.cgComponentsConstants.filter((val) => val.component_id !== xItem.component_id);
        } else if (xTable == "cgComponentsFuel") {
          this.cgComponentsFuel = this.cgComponentsFuel.filter((val) => val.component_id !== xItem.component_id);
        } else if (xTable == "cgComponentsExternal") {
          this.cgComponentsExternal = this.cgComponentsExternal.filter((val) => val.component_id !== xItem.component_id);
        } else if (xTable == "chartDrawLimitsCGLongitudinal") {
          this.chartDrawLimitsCGLongitudinal = this.chartDrawLimitsCGLongitudinal.filter((val) => val.draw_limit_data_id !== xItem.draw_limit_data_id);
          // ADD LONGITUDINAL
          this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLongitudinal;
        } else if (xTable == "chartDrawLimitsCGLateral") {
          this.chartDrawLimitsCGLateral = this.chartDrawLimitsCGLateral.filter((val) => val.draw_limit_data_id !== xItem.draw_limit_data_id);
          // ADD LATERAL
          this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLateral;
        }

        this._messageService.add({
          severity: 'success',
          summary: 'Component do CG da Aeronave',
          detail: 'Item removido com sucesso.',
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: 'Você deseja APAGAR o Componente do CG da Aeronave?',
          detail: 'O Processo de Apagar o Registro do Tripulante foi Cancelado pelo Usuário.',
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Inicia a edição de um item da tabela de Componentes da Aeronave.
   * 
   * @param xItem 
   */
  onRowEditInit(xItem: ICgComponents) {
    this.clonedCgComponents[xItem.component_id as string] = { ...xItem };
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Salva a edição de um item da tabela de Componentes da Aeronave.
   * 
   * @param xItem 
   */
  onRowEditSave(xItem: ICgComponents) {
    delete this.clonedCgComponents[xItem.component_id as string];

    this._messageService.add({
      severity: 'success',
      summary: 'Componente da Aeronave',
      detail: 'Item atualizado com sucesso',
      key: settingConfig.TOAST_KEY,
      life: settingConfig.TOAST_LIFE
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Cancela a edição de um item da tabela de Componentes da Aeronave.
   * 
   * @param xItem 
   * @param xIndex 
   * @param xTable 
   */
  onRowEditCancel(xItem: ICgComponents, xIndex: number, xTable: string) {
    if (xTable == "cgComponentsInput") {
      this.cgComponentsInputs[xIndex] = this.clonedCgComponents[xItem.component_id as string];
      delete this.clonedCgComponents[xItem.component_id as string];
    } else if (xTable == "cgComponentsConstants") {
      this.cgComponentsConstants[xIndex] = this.clonedCgComponents[xItem.component_id as string];
      delete this.clonedCgComponents[xItem.component_id as string];
    } else if (xTable == "cgComponentsFuel") {
      this.cgComponentsFuel[xIndex] = this.clonedCgComponents[xItem.component_id as string];
      delete this.clonedCgComponents[xItem.component_id as string];
    } else if (xTable == "cgComponentsExternal") {
      this.cgComponentsExternal[xIndex] = this.clonedCgComponents[xItem.component_id as string];
      delete this.clonedCgComponents[xItem.component_id as string];
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-07
   * @version 1.0.0
   * 
   * @description Adiciona um novo item na tabela de Coordenadas dos Limites do CG.
   * 
   * @param xTable 
   */
  onRowNewLimits(xTable: string): void {
    const tempId = "";
    const tempElement: IChartDrawLimitsCG = {
      draw_limit_data_id: LocalMethodsHandlerClass.generateUuid(),
      coordinates: [],
      fill_color: "#000000",
      label: ""
    }

    if (xTable == "cgDataDrawLimitsCGLongitudinal") {
      this.chartDrawLimitsCGLongitudinal.push(tempElement);
    } else if (xTable == "cgDataDrawLimitsCGLateral") {
      this.chartDrawLimitsCGLateral.push(tempElement);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-06
   * @version 1.0.0
   * 
   * 
   * @param xTypeChart 
   * @param xChartDrawLimitId 
   */
  onRowNewCoordinates(xTypeChart: string, xChartDrawLimitId: string | null = null) {
    const tempId = "";
    const tempElement = {
      component_id: LocalMethodsHandlerClass.generateRandomString(10),
      x: 0.0,
      y: 0.0
    }

    if (xTypeChart == "longitudinal") {
      this.chartDrawLimitsCGLongitudinal = this.chartDrawLimitsCGLongitudinal.map((val) => {
        if (val.draw_limit_data_id === xChartDrawLimitId) {
          // preciso apagar o array com o xIndex que foi passado
          val.coordinates.push(tempElement);
        }
        return val;
      });
      // ADD LONGITUDINAL
      this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLongitudinal;

    } else if (xTypeChart == "lateral") {
      this.chartDrawLimitsCGLateral = this.chartDrawLimitsCGLateral.map((val) => {
        if (val.draw_limit_data_id === xChartDrawLimitId) {
          // preciso apagar o array com o xIndex que foi passado
          val.coordinates.push(tempElement);
        }
        return val;
      });
      // ADD LATERAL
      this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLateral;
    }

    this.clonedCoordinates[tempId as string] = { ...tempElement };
  }

  /**
   * @author GASPAR
   * @date 2024-10-06
   * @version 1.0.0
   * 
   * @description Remove um item da Tabela de Coordenadas.
   * 
   * @param xIndex 
   * @param xTable 
   * @param xChartDrawLimitId 
   */
  onRowDeleteCoordinates(xTypeChart: string, xIndex: any, xTable: string, xChartDrawLimitId: string | null = null) {
    if (xTypeChart == "longitudinal") {
      this.chartDrawLimitsCGLongitudinal = this.chartDrawLimitsCGLongitudinal.map((val) => {
        if (val.draw_limit_data_id === xChartDrawLimitId) {
          // preciso apagar o array com o xIndex que foi passado
          val.coordinates = val.coordinates.filter((val, index) => index !== xIndex);
        }
        return val;
      });
      // ADD LONGITUDINAL
      this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLongitudinal;

    } else if (xTypeChart == "lateral") {
      this.chartDrawLimitsCGLateral = this.chartDrawLimitsCGLateral.map((val) => {
        if (val.draw_limit_data_id === xChartDrawLimitId) {
          // preciso apagar o array com o xIndex que foi passado
          val.coordinates = val.coordinates.filter((val, index) => index !== xIndex);
        }
        return val;
      });
      // ADD LATERAL
      this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLateral;
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-06
   * @version 1.0.0
   * 
   * @description Inicia a edição de um item da tabela de Componentes da Aeronave.
   * 
   * @param xItem 
   */
  onRowEditInitCoordinates(xItem: any) {
    // Tem como fazer esta estrutura com o Index,m pois eu não tenho o ID do item.
    this.clonedCoordinates[xItem.component_id as string] = { ...xItem };
  }

  /**
  * @author GASPAR
  * @date 2024-10-02
  * @version 1.0.0
  * 
  * @description Salva a edição de um item da tabela de Componentes da Aeronave.
  * 
  * @param xItem 
  */
  onRowEditSaveCoordinates(xItem: any) {
    delete this.clonedCoordinates[xItem.component_id as string];

    this._messageService.add({
      severity: 'success',
      summary: 'Coordenada dos Limites da Aeronave',
      detail: 'Item atualizado com sucesso',
      key: settingConfig.TOAST_KEY,
      life: settingConfig.TOAST_LIFE
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Cancela a edição de um item da tabela de Componentes da Aeronave.
   * 
   * @param xItem 
   * @param xIndex  
   * @param xTable 
   */
  onRowEditCancelCoordinates(xTypeChart: string, xItem: any, xIndex: number, xChartDrawLimitId: string | null = null) {
    if (xTypeChart == "longitudinal") {
      this.chartDrawLimitsCGLongitudinal = this.chartDrawLimitsCGLongitudinal.map((val) => {
        if (val.draw_limit_data_id === xChartDrawLimitId) {
          val.coordinates = this.clonedCoordinates[xItem.id as string];
        }
        return val;
      });
      // ADD LONGITUDINAL
      this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLongitudinal;
    } else if (xTypeChart == "lateral") {
      this.chartDrawLimitsCGLateral = this.chartDrawLimitsCGLateral.map((val) => {
        if (val.draw_limit_data_id === xChartDrawLimitId) {
          val.coordinates = this.clonedCoordinates[xItem.id as string];
        }
        return val;
      });
      // ADD LATERAL
      this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.limits_data.value = this.chartDrawLimitsCGLateral;
    }

    delete this.clonedCoordinates[xItem.id as string];
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Retorna o label do componente da aeronave.
   * 
   * @param xItem 
   * @returns 
   */
  showLabelCgComponents(xTypeOptions: string, xItem: string): string {
    if (xTypeOptions == "constant") {
      return this.optionsCgComponentsConstant.find(x => x.value == xItem)?.label as string;
    } else if (xTypeOptions == "external") {
      return this.optionsCgComponentsExternal.find(x => x.value == xItem)?.label as string;
    } else if (xTypeOptions == "fuel") {
      return this.optionsCgComponentsFuel.find(x => x.value == xItem)?.label as string;
    } else if (xTypeOptions == "input") {
      return this.optionsCgComponentsInput.find(x => x.value == xItem)?.label as string;
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Retorna o label do tipo de aeronave.
   * 
   * @param xItem 
   * @returns 
   */
  showLabelYesNo(xItem: boolean): string {
    return this.optionsYesNo.find(x => x.value == xItem)?.label as string;
  }

  /**
   * @author GASPAR
   * @date 2024-10-05
   * @version 1.0.0
   * 
   * @description Retorna o peso atual convertido para a unidade de peso atual.
   * 
   * @param xWeight 
   * @returns 
   */
  getCurrentWeightUnit(xWeight: string): string {
    let tempReturn = ""

    if (xWeight == 'MTOW') {
      const tempWeight = this.formComponent.controls.specification_data.controls.weight_data.controls.max_takeoff_weight.value
      const tempUnit = this.formComponent.controls.specification_data.controls.weight_data.controls.max_takeoff_weight_unit.value
      const tempCurrentUnit = this.formWeightBalanceSheet.controls.unit_data.controls.weight.value
      const convertedWeight = LocalMethodsHandlerClass.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso Máximo de Decolagem
      this.formWeightBalanceSheet.controls.weight_data.controls.max_takeoff_weight.setValue(convertedWeight);

      // Verifica se o número tem casas decimais
      tempReturn = `${convertedWeight.toFixed(2).replaceAll(",", "").replaceAll(".", ",")}`;
    } else if (xWeight == 'MLW') {
      const tempWeight = this.formComponent.controls.specification_data.controls.weight_data.controls.max_landing_weight.value
      const tempUnit = this.formComponent.controls.specification_data.controls.weight_data.controls.max_landing_weight_unit.value
      const tempCurrentUnit = this.formWeightBalanceSheet.controls.unit_data.controls.weight.value
      const convertedWeight = LocalMethodsHandlerClass.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso Máximo de Pouso
      this.formWeightBalanceSheet.controls.weight_data.controls.max_landing_weight.setValue(convertedWeight);

      // Verifica se o número tem casas decimais
      tempReturn = `${convertedWeight.toFixed(2).replaceAll(",", "").replaceAll(".", ",")}`;
    } else if (xWeight == 'MRW') {
      const tempWeight = this.formComponent.controls.specification_data.controls.weight_data.controls.max_ramp_weight.value
      const tempUnit = this.formComponent.controls.specification_data.controls.weight_data.controls.max_ramp_weight_unit.value
      const tempCurrentUnit = this.formWeightBalanceSheet.controls.unit_data.controls.weight.value
      const convertedWeight = LocalMethodsHandlerClass.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso de Taxi
      this.formWeightBalanceSheet.controls.weight_data.controls.max_ramp_weight.setValue(convertedWeight);

      // Verifica se o número tem casas decimais
      tempReturn = `${convertedWeight.toFixed(2).replaceAll(",", "").replaceAll(".", ",")}`;
    } else if (xWeight == 'MZFW') {
      const tempWeight = this.formComponent.controls.specification_data.controls.weight_data.controls.max_zero_fuel_weight.value
      const tempUnit = this.formComponent.controls.specification_data.controls.weight_data.controls.max_zero_fuel_weight_unit.value
      const tempCurrentUnit = this.formWeightBalanceSheet.controls.unit_data.controls.weight.value
      const convertedWeight = LocalMethodsHandlerClass.convertWeight(tempWeight, tempUnit, tempCurrentUnit);

      // Atribui o Valor calculado ao campo de Peso Máximo Zero Combustível
      this.formWeightBalanceSheet.controls.weight_data.controls.max_zero_fuel_weight.setValue(convertedWeight);

      // Verifica se o número tem casas decimais
      tempReturn = `${convertedWeight.toFixed(2).replaceAll(",", "").replaceAll(".", ",")}`;
    }

    return tempReturn;
  }
  //#endregion
}
