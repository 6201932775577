//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { LocalMethodsHandlerClass } from '../../../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { ICgComponents } from '../../../../../../_library/interfaces/ICgComponents';
import { IChartDrawLimitsCG } from '../../../../../../_library/interfaces/IChartDrawLimitsCG';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { CG_COMPONENT_TYPE } from '../../../../../../_library/definitions/CgComponentType';
//#endregion

export class MockAircraftFormDataClass {
    static cgComponentsInput(): ICgComponents[] {
        return [
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["input"],
                type_component: CG_COMPONENT_TYPE.PILOT_SEAT,
                description: "Banco A",
                label: "B-A",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 1.65,
                longitudinal_moment: 0,
                lateral_arm: 0.36,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["input"],
                type_component: CG_COMPONENT_TYPE.PILOT_SEAT,
                description: "Banco B",
                label: "B-B",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 1.65,
                longitudinal_moment: 0,
                lateral_arm: -0.28,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["input"],
                type_component: CG_COMPONENT_TYPE.PAX_SEAT,
                description: "Banco C",
                label: "B-C",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 3.28,
                longitudinal_moment: 0,
                lateral_arm: -0.39,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["input"],
                type_component: CG_COMPONENT_TYPE.PAX_SEAT,
                description: "Banco D",
                label: "B-D",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 3.28,
                longitudinal_moment: 0,
                lateral_arm: 0.39,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["input"],
                type_component: CG_COMPONENT_TYPE.PAX_SEAT,
                description: "Banco E",
                label: "B-E",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 3.28,
                longitudinal_moment: 0,
                lateral_arm: 0,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["input"],
                type_component: CG_COMPONENT_TYPE.PAX_SEAT,
                description: "Banco F",
                label: "B-F",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 2.31,
                longitudinal_moment: 0,
                lateral_arm: -0.33,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["input"],
                type_component: CG_COMPONENT_TYPE.PAX_SEAT,
                description: "Banco G",
                label: "B-G",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 2.31,
                longitudinal_moment: 0,
                lateral_arm: 0.33,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            }
        ];
    }

    static cgComponentsConstant(): ICgComponents[] {
        return [
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["constant"],
                type_component: CG_COMPONENT_TYPE.DOOR,
                description: "Porta Frente Direita",
                label: "P-FD",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 1.65,
                longitudinal_moment: 0,
                lateral_arm: 0.36,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["constant"],
                type_component: CG_COMPONENT_TYPE.DOOR,
                description: "Porta Frente Esquerda",
                label: "P-FE",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 1.65,
                longitudinal_moment: 0,
                lateral_arm: -0.36,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["constant"],
                type_component: CG_COMPONENT_TYPE.BALLAST,
                description: "Tailboom Ballast",
                label: "TB",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 1.9,
                longitudinal_moment: 0,
                lateral_arm: -0.80,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: true
            }
        ]
    }

    static cgComponentsFuel(): ICgComponents[] {
        return [
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["constant"],
                type_component: CG_COMPONENT_TYPE.FUEL,
                description: "Tanque Principal",
                label: "F-TP",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 3.48,
                longitudinal_moment: 0,
                lateral_arm: 0.36,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            }
        ]
    }

    static cgComponentsExternal(): ICgComponents[] {
        return [
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["constant"],
                type_component: CG_COMPONENT_TYPE.BASKET_LOAD,
                description: "Carga Basket",
                label: "F-TP",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 3.15,
                longitudinal_moment: 0,
                lateral_arm: 1.02,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            },
            {
                component_id: LocalMethodsHandlerClass.generateUuid(),
                tags: ["constant"],
                type_component: CG_COMPONENT_TYPE.EXTERNAL_LOAD,
                description: "Carga Externa",
                label: "F-TP",
                weight: 0,
                max_weight: 0,
                min_weight: 0,
                ave_weight: 0,
                longitudinal_arm: 3.07,
                longitudinal_moment: 0,
                lateral_arm: 0,
                lateral_moment: 0,
                avatar_color: "#000000",
                confirm: false
            }
        ];
    }

    static chartDrawLimitsCG(): IChartDrawLimitsCG[] {
        return [{
            draw_limit_data_id: LocalMethodsHandlerClass.generateUuid(),
            coordinates: [
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 10, y: 100 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 10, y: 900 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 90, y: 900 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 90, y: 100 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 10, y: 100 }
            ],
            fill_color: "#000000",
            label: "LIMITES DE PESO"
        },
        {
            draw_limit_data_id: LocalMethodsHandlerClass.generateUuid(),
            coordinates: [
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 10, y: 900 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 10, y: 950 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 90, y: 950 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 90, y: 900 },
                { id: LocalMethodsHandlerClass.generateRandomString(10), x: 10, y: 900 }
            ],
            fill_color: "#FFFF00",
            label: "COM PESO EXTERNO"
        }]
    }
}