<div class="cls-app-component">
    <div class="cls-cmp-esp-aerodrome-input-fieldset" [ngClass]="{'cls-ui-primeng-field-wrong': this.showErrors() }">
        <p-fieldset>
            <ng-template pTemplate="header">
                <div class="cls-cmp-esp-aerodrome-input-fieldset-header">
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-header-image">
                        <img src="app_platform/icons/{{this.getIcon()}}" alt="Image" />
                    </div>
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-header-label" >
                        <span [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-wrong': this.showErrors() }" >{{this.labelInput}}</span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="cls-cmp-esp-aerodrome-input-fieldset-sub-label">
                    <lib-ui-primeng-label label="ICAO/Coord."  toolTip="Aceita coordenadas geográficas nos formatos: <br> -> -26.79944, -48.61278 <br> -> 26 47 58S, 048 36 46W <br> -> 2647S04836W <br> -> 2647.57S, 04836.4608W"></lib-ui-primeng-label>
                </div>                
                <div class="cls-cmp-esp-aerodrome-input-fieldset-content">
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-content-input"> 
                        <div class="cls-ui-primeng-field cls-ui-primeng-field-input-text">
                            <div [ngClass]="{'cls-ui-primeng-field-wrong': this.showErrors() }">
                                <input 
                                    [formControl]="this.aerodromeControl" 
                                    [ngClass]="{ 'is-danger': this.showErrors() }"                                    
                                    (blur)="this.onBlurMethod($event, this)" 
                                    (focus)="this.onFocusMethod($event, this)"
                                    (input)="this.transformLetterCase($event)" 
                                    type="text"
                                    name="{{this.getFieldName()}}" 
                                    id="{{this.idField}}" 
                                    autocomplete="off" 
                                    pInputText />
                            </div>
                        </div>                    
                        <lib-ui-primeng-handler-error [control]="this.aerodromeControl" typeControl="input"></lib-ui-primeng-handler-error>
                        <div class="cls-cmp-esp-aerodrome-input-fieldset-content-input-coordinate">
                            Coordenada: {{this.getAerodromeCoordinates()}}
                        </div>
                        <div class="cls-cmp-esp-aerodrome-input-fieldset-content-input-name">
                            Nome: {{this.getAerodromeName()}}
                        </div>
                    </div>
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-content-button">
                        <p-button icon="fa-solid fa-magnifying-glass" severity="secondary" />
                    </div>
                    <div class="cls-cmp-esp-aerodrome-input-fieldset-content-button">
                        <p-button icon="fa-solid fa-map-location-dot" severity="secondary" />
                    </div>
                </div>                
            </ng-template>
        </p-fieldset>
    </div>
</div>