<div class="cls-app-platform--component">
    <div class="cls-app-platform--component-aircraft-data-summary">
        <p-panel class="cls-component-panel-aside">
            <ng-template pTemplate="header">
                <div class="cls-component-panel-aside-header">
                    <div class="cls-component-panel-aside-header-title">
                        <h3>
                            <span><i class="fa-solid fa-bolt"></i> Dados da Aeronave</span>
                        </h3>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                
            </ng-template>
        </p-panel>
    </div>
</div>