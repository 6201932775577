//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initFlightDataForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        flight_data_id: [null],
        fk_flight_plan_id: [null],
        official_clearance: objFormBuilder.group({
            ni: [null],
            content: [null],
            date_time: [null]
        }),
        folder_path: [null],
        fast_access_data: objFormBuilder.group({
            access_code: [null],
            url: [null],
            valid_util: [null],
        }),
        flight_data_code: objFormBuilder.group({
            normalized_text: [null],
            eobt_string_date: [null],
            sequential: [null]
        }),
        flight_request: objFormBuilder.group({
            aircraft_data: objFormBuilder.group({
                fk_aircraft_data_id: [null],
                aircraft_mark: [null],
                owner_operator_id: [null],
                operator_name: [null],
            }),
            aerodrome_list: objFormBuilder.array([]),
            crew_data: objFormBuilder.array([]),
            eobt_date_time: objFormBuilder.group({
                date_zulu: ["15/10/2024"],
                time_zulu: ["16:30"],
                date_time_zulu: ["15/10/2024 16:30"],
                iso_zulu: [null]
            }),
            eet_time: ["01:30"],
            landing_date_time: objFormBuilder.group({
                date_zulu: ["15/10/2024"],
                time_zulu: ["15:00"],
                date_time_zulu: ["15/10/2024 15:00"],
                iso_zulu: [null]
            }),
            flight_level: ["F150"],
            cruise_speed: ["N083"],
            flight_rule: ["I"],
        }),
        map_data: objFormBuilder.group({
            marker_data: [null],
            draw_data: [null],
        }),
        historical_data: [null],
        current_status: objFormBuilder.group({
            flight_service: [null],
            flight_plan: [null],
            flight: [null],
            official: [null]
        })
    });
}

