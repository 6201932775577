/**
 * @author GASPAR
 * @date 2024-09-18
 * @version 1.0.0
 * 
 * @description Função que verifica se um controle possui erros de validação.
 * 
 * @param xControl 
 * @param xTypeControl 
 * @returns 
 */
function hasControlErrors(xControl: any, xTypeControl: string): any {
    // Dirty    = indica se em algum momento há ou houve algum valor digitado no input;
    // Touched  = indica se o foco do controle já saiu dele; 
    // Erros    = Indica se há algum erro de validação do campo.

    if (xTypeControl === "input") {
        const { dirty, touched, errors } = xControl;

        return (dirty && touched && errors);
    } else if (xTypeControl === "dropdown"){
        const { dirty, errors } = xControl;

        return (dirty && errors);
    }
};

export { hasControlErrors };