//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, ElementRef, Input, AfterViewInit, SimpleChanges, OnChanges, HostListener, OnInit, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** CLASSES ***** //
import { AviationToolsHandlerClass } from 'src/app/app-platform/_classes/AviationToolsHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';

// ***** FORM**** //
import { initChartDataForm } from '../../../modules/weight-balance/form-init/chart-data-form';

// ***** NPM ***** //
import * as d3 from 'd3';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { WEIGHT_BALANCE_FORMAT } from '../../../../_library/definitions/WeightBalanceFormat';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IChartCGLimitations } from '../../../../_library/interfaces/IChartCGLimitations';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-app-platform--chart-cg-limits',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './chart-cg-limits.component.html'
})
export class ChartCGLimitsComponent implements AfterViewInit, OnInit, OnChanges {
  ///#region "|--- INPUTS ---|"  
  @Input() chartMode!: string;
  @Input() aircraftMark!: any;

  @Input() set arrPointsToDraw(xValue: any | undefined) {
    if (xValue != undefined) {
      
      this._arrPointsToDraw = xValue;
      this._drawChart();
    }
  }

  @Input() set objChartCGLimits(xValue: IChartCGLimitations | undefined) {
    if (xValue != undefined) {
      if (!this.deepEqual(this._objChartCGLimits, xValue)) {
        this._objChartCGLimits = xValue;
        this._drawChart();
      }
    }
  }
  //#endregion

  //#region "|--- PROPERTIES---|"  
  // ***** SCREEN ***** //
  _innerWidth!: number; // Largura da tela do usuário.

  // ***** CLASS ***** // 
  _aviationToolsHandlerClass!: any;

  // ***** OBJ CHART ***** //
  _objChartCGLimits!: IChartCGLimitations;
  _arrPointsToDraw!: [];

  // ***** CHARTER ***** //
  _hostD3Element!: any; // Elemento D3 do componente ChartCGLimits.
  _divFrameChart!: any; // Elemento div do componente ChartCGLimits.
  _axisX!: any;
  _axisY!: any;
  _axisOppositeX!: any;
  _axisOppositeY!: any;

  // ***** SVG ***** //
  _svgChart!: any;  // SVG do componente ChartCGLimits.
  _svgChartWidth!: number;  // Largura do SVG do componente ChartCGLimits.
  _svgChartHeight!: number; // Altura do SVG do componente ChartCGLimits.
  _svgChartMargin!: any;  // Margens do SVG do componente ChartCGLimits.

  // ***** MOCK ***** //
  plotDataLimit!: any[];
  plotDataLimitExtraLoad!: any[];
  plotBasicWeight!: any[];
  //#endregion

  constructor(
    private _element: ElementRef
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Método que é chamado após a renderização do componente.
   */
  ngAfterViewInit(): void {
    /**********************************************************/
    // ***** AÇÕES QUE DEVEM SER FEITAS SOMENTE UMA VEZ ***** //
    /**********************************************************/

    // Quando for recuperar elementos da interface, é preciso esperar ele renderizar para poder recurar. Por isto é preciso usar o AfterViewInit, pois ele executa após a renderização.   
    // Se executar estes comando dentro do OnInit, ele não vai encontrar os elementos, pois eles ainda não foram renderizados (criados).    
    // Neste exemplo, bodySelection será uma seleção D3 contendo o elemento div com o seletor "#id-cg-limits-chart". 
    this._hostD3Element = d3.select(this._element.nativeElement.querySelector("#id-cg-limits-chart"));

    // Utilizado como referência para pegar as dimensões do SVG do componente ChartCGLimits para manipulação. 
    // Tamanho definido no CSS, ele ajusta conforme o tamanho da tela.
    this._divFrameChart = this._element.nativeElement.querySelector('.cls-app-platform--component-chart-cg-limits');

    // Pega o tamanho da tela do usuário. 
    this._innerWidth = window.innerWidth;

    // Desenha o gráfico do componente ChartCGLimits.
    this._drawChart();
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Método que é chamado sempre que houver alterações nos inputs do componente.
   * 
   * @param xChanges 
   */
  ngOnChanges(xChanges: SimpleChanges): void {
    /*
    if (xChanges['aircraftMark']) {
      this._drawChart();
    }*/
  }


  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente ChartCGLimits.
   */
  private _initVariables(): void {
    this._aviationToolsHandlerClass = AviationToolsHandlerClass;

    if (this._objChartCGLimits == undefined) {
      this._initDefaultObjChartCGLimits();
    }
  }

  private deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  /**
   * @author GASPAR
   * @date 2024-10-06
   * @version 1.0.0
   * 
   * @description Inicializa o objeto padrão do componente ChartCGLimits.
   */
  private _initDefaultObjChartCGLimits(): void {
    this._objChartCGLimits = {
      chart_data_id: '',
      background_image: '',
      limits_data: [],
      chart_type: 'longitudinal',
      x_min: 0,
      x_max: 100,
      x_step_axis: 10,
      x_step_grid: 5,
      x_scale: 'linear',
      x_unit: 'in',
      x_show_opposite_unit: 'mm',
      y_min: 0,
      y_max: 1000,
      y_step_axis: 100,
      y_step_grid: 50,
      y_scale: 'linear',
      y_unit: 'lb',
      y_show_opposite_unit: 'kg',
      show_grid: false
    };
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Desenha o gráfico do componente ChartCGLimits.
   */
  private _drawChart(): void {
    // Verifica se o elemento D3 do componente ChartCGLimits foi criado. Pois este método pode ser chamado durante redimensionamento antes de formar o componente
    if (this._hostD3Element) {
      // 1) APAGA TODOS os SVG do componente ChartCGLimits.
      this._hostD3Element.selectAll('svg').remove();

      // 2) DEFININDO O TAMANHO INICIAL DO SVG DO COMPONENTE.
      // Estou definindo a altura do elemento divFrameChart, pois o SVG é responsivo e se eu não definir a altura do divFrameChart, o SVG não vai aparecer.
      // A Altura é definida com base no tamanho da largura do divFrameChart, pois o SVG é responsivo.
      const tempResponseHeight = this._divFrameChart.getBoundingClientRect().width / 1.5;

      //The Element.getBoundingClientRect() method returns a DOMRect object providing information about the size of an element and its position relative to the viewport.
      this._svgChartWidth = this._divFrameChart.getBoundingClientRect().width;
      this._svgChartHeight = tempResponseHeight;

      // Set the height of the divFrameChart element      
      this._divFrameChart.style.height = `${tempResponseHeight}px`;

      // 3) Define as margens do SVG do componente ChartCGLimits, conforme o tamanho da tela.
      this._settingSvgMarginsByScreen();

      // 4) Cria o SVG do componente ChartCGLimits.
      this._createSvg();

      // 5) Cria os eixos do SVG do componente ChartCGLimits.
      this._createAxis();

      // 6) Cria as linhas do SVG do componente ChartCGLimits.
      this._drawDataLimitLine();

      // 7) Desenhando os pontos no gráfico.
      this._drawPoints();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   * @description Define as margens do SVG do componente ChartCGLimits, garantindo a responsividade.
   */
  private _settingSvgMarginsByScreen(): void {
    const tempScreenWidth = this._innerWidth;

    // Para as Margem SUPERIOR, tem que levar em consideração a exibição da unidade oposta no eixo X.
    // Para as Margem DIREITA, tem que levar em consideração a exibição da unidade oposta no eixo Y.
    // CELULAR: 575px ou menos
    if (tempScreenWidth < 575) {
      this._svgChartMargin = {
        top: (this._objChartCGLimits.y_show_opposite_unit == null || this._objChartCGLimits.y_show_opposite_unit == "") ? 10 : 20,
        right: (this._objChartCGLimits.y_show_opposite_unit == null || this._objChartCGLimits.y_show_opposite_unit == "") ? 15 : 20,
        bottom: 20,
        left: 70
      };
    } else if (tempScreenWidth >= 576 && tempScreenWidth < 991) {
      this._svgChartMargin = {
        top: (this._objChartCGLimits.y_show_opposite_unit == null || this._objChartCGLimits.y_show_opposite_unit == "") ? 15 : 30,
        right: (this._objChartCGLimits.y_show_opposite_unit == null || this._objChartCGLimits.y_show_opposite_unit == "") ? 15 : 30,
        bottom: 40,
        left: 70
      };
    } else {
      this._svgChartMargin = {
        top: (this._objChartCGLimits.y_show_opposite_unit == null || this._objChartCGLimits.y_show_opposite_unit == "") ? 20 : 40,
        right: (this._objChartCGLimits.y_show_opposite_unit == null || this._objChartCGLimits.y_show_opposite_unit == "") ? 20 : 75,
        bottom: 40,
        left: 60
      };
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Cria o SVG do componente ChartCGLimits.
   */
  private _createSvg(): void {
    // Pega o SVG especifico do componente ChartCGLimits e armazena na variável _svgChart para manipulação
    this._svgChart = this._hostD3Element
      .append('svg')
      .attr('width', this._svgChartWidth)
      .attr('height', this._svgChartHeight);

    // Adiciona a imagem de fundo com coordenadas ajustadas
    /*this._svgChart.append('image')
      .attr('xlink:href', '/app_platform/teste.png') // Substitua pelo caminho da sua imagem
      .attr('x', this._svgChartMargin.left) // Coordenada X inicial
      .attr('y', this._svgChartMargin.top) // Coordenada Y inicial
      .attr('width', this._svgChartWidth - this._svgChartMargin.left - this._svgChartMargin.right)
      .attr('height', this._svgChartHeight - this._svgChartMargin.top - this._svgChartMargin.bottom)
      .attr('preserveAspectRatio', 'xMidYMid meet'); // Ajusta a imagem para caber dentro do gráfico*/
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Cria os eixos do SVG do componente ChartCGLimits.
   */
  private _createAxis(): void {
    //#region "|--- PRÉ TRATAMENTO ---|"
    const boolShow_grid = this._objChartCGLimits.show_grid;
    const xStepAxis = this._objChartCGLimits.x_step_axis;
    const xStepGrid = this._objChartCGLimits.x_step_grid;
    const xScale = this._objChartCGLimits.x_scale;
    const xUnit = this._objChartCGLimits.x_unit;
    const xShowOppositeUnit = this._objChartCGLimits.x_show_opposite_unit;

    let xMin = this._objChartCGLimits.x_min;
    let xMax = this._objChartCGLimits.x_max;

    // PREVENIR DISTORÇÕES NOS EIXOS
    if (xMin >= xMax) {
      xMax = xMin + 100;
    }

    if (xMax <= xMin) {
      xMin = (xMin + 100) < 0 ? xMin + 100 : 0;
    }

    const yStepAxis = this._objChartCGLimits.y_step_axis;
    const yStepGrid = this._objChartCGLimits.y_step_grid;
    const yScale = this._objChartCGLimits.y_scale;
    const yUnit = this._objChartCGLimits.y_unit;
    const yShowOppositeUnit = this._objChartCGLimits.y_show_opposite_unit;

    let yMin = this._objChartCGLimits.y_min;
    let yMax = this._objChartCGLimits.y_max;

    // PREVENIR DISTORÇÕES NOS EIXOS
    if (yMin >= yMax) {
      yMax = yMin + 1000;
    }

    if (yMax <= yMin) {
      yMin = (yMin + 1000) < 0 ? yMin + 1000 : 0;
    }

    // Calcula a quantidade de ticks no eixo X e Y. Indica em quantas partes o eixo será dividido. 
    // Cada Marquinha no eixo é um tick.   
    let xAxisTick = parseFloat(((xMax - xMin) / xStepAxis).toFixed(2));
    let yAxisTick = parseFloat(((yMax - yMin) / yStepAxis).toFixed(2));
    let xGridTick = parseFloat(((xMax - xMin) / xStepGrid).toFixed(2));
    let yGridTick = parseFloat(((yMax - yMin) / yStepGrid).toFixed(2));
    
    xAxisTick = (xAxisTick !== Infinity && xAxisTick > 0) ? xAxisTick : 1;
    yAxisTick = (yAxisTick !== Infinity && yAxisTick > 0) ? yAxisTick : 1;
    xGridTick = (xGridTick !== Infinity && xGridTick > 0) ? xGridTick : 1;
    yGridTick = (yGridTick !== Infinity && yGridTick > 0) ? yGridTick : 1;

    //#endregion

    //#region "|--- DEFININDO OS EIXOS X E Y ---|"
    // RANGE: [ponto de partida, ponto de chegada] - Define o intervalo de valores que o eixo vai representar.
    if (xScale == "linear") {
      // Declare the x (horizontal position) scale.
      this._axisX = d3.scaleLinear()
        .domain([xMin, xMax])
        .range([this._svgChartMargin.left, this._svgChartWidth - this._svgChartMargin.right]);
    }

    // Add the x-axis.
    this._svgChart.append("g")
      .attr("transform", `translate(0,${this._svgChartHeight - this._svgChartMargin.bottom})`)
      .call(
        d3.axisBottom(this._axisX)
          .ticks(xAxisTick)
          .tickValues(d3.range(xMin, xMax, xStepAxis)) // Ajuste os valores dos ticks para começar no valor desejado
          .tickFormat((d: any) => `${parseFloat((d).toFixed(3))} ${xUnit}`)
      );

    if (yScale == "linear") {
      // Declare the y (vertical position) scale.
      this._axisY = d3.scaleLinear()
        .domain([yMin, yMax])
        .range([this._svgChartHeight - this._svgChartMargin.bottom, this._svgChartMargin.top]);
    }

    // Add the y-axis.
    this._svgChart.append("g")
      .attr("transform", `translate(${this._svgChartMargin.left}, 0)`)
      .call(
        d3.axisLeft(this._axisY)
          .ticks(yAxisTick)
          .tickValues(d3.range(yMin, yMax, yStepAxis)) // Ajuste os valores dos ticks para começar no valor desejado
          .tickFormat((d: any) => `${parseFloat((d).toFixed(3))} ${yUnit}`)
      );
    //#endregion

    //#region "|--- DEFININDO OS OPOSITORES EIXOS X E Y ---|"
    if (xShowOppositeUnit != "" && xShowOppositeUnit != null) {
      if (xScale == "linear") {
        // Declare the x (horizontal position) scale.
        this._axisOppositeX = d3.scaleLinear()
          .domain([xMin, xMax])
          .range([this._svgChartMargin.left, this._svgChartWidth - this._svgChartMargin.right]);
      }

      // Add the x-axis.
      this._svgChart.append("g")
        .attr("transform", `translate(0,${this._svgChartMargin.top})`)
        .call(
          d3.axisTop(this._axisOppositeX)
            .ticks(xAxisTick)
            .tickFormat((xArm: any) => {
              const convertedWeight = this._aviationToolsHandlerClass.convertArm(xArm, xUnit, xShowOppositeUnit);

              return `${parseFloat(convertedWeight.toFixed(2))} ${xShowOppositeUnit}`;
            })
        );

      // Add X Axis Label
      this._svgChart.append('text')
        .attr('class', 'x-axis-label')
        .attr('text-anchor', 'middle')
        .attr('x', this._svgChartWidth / 2)
        .attr('y', (this._svgChartMargin.top / 4))
        .text(`${this._objChartCGLimits.chart_type == "longitudinal" ? "Longitudinal" : "Lateral"} CG (${xShowOppositeUnit})`);
    }

    if (yShowOppositeUnit != "" && yShowOppositeUnit != null) {
      if (yScale == "linear") {
        // Declare the y (vertical position) scale.
        this._axisY = d3.scaleLinear()
          .domain([yMin, yMax])
          .range([this._svgChartHeight - this._svgChartMargin.bottom, this._svgChartMargin.top]);
      }

      // Add the y-axis.
      this._svgChart.append("g")
        .attr("transform", `translate(${this._svgChartWidth - this._svgChartMargin.right}, 0)`)
        .call(
          d3.axisRight(this._axisY)
            .ticks(yAxisTick)
            .tickFormat((xWeight: any) => {
              const convertedWeight = this._aviationToolsHandlerClass.convertWeight(xWeight, yUnit, yShowOppositeUnit);

              return `${parseFloat(convertedWeight.toFixed(2))} ${yShowOppositeUnit}`;
            })
        );

      // Add Y Axis Label
      this._svgChart.append('text')
        .attr("class", "axis-label")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", this._svgChartWidth - 4)
        .attr("x", ((this._svgChartHeight / 2) * - 1) + (this._svgChartMargin.top))
        .text(`Gross Weight - (${yShowOppositeUnit})`);
    }
    //#endregion

    if (boolShow_grid == true) {
      // Adiciona as linhas de grade no eixo X
      this._svgChart.append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(0,${(this._svgChartHeight - this._svgChartMargin.bottom)})`)
        .call(d3.axisBottom(this._axisX)
          .ticks(xGridTick)
          .tickValues(d3.range(xMin, xMax + xStepGrid, xStepGrid)) // Ajuste os valores dos ticks para começar no valor desejado
          .tickSize(-(this._svgChartHeight - this._svgChartMargin.top - this._svgChartMargin.bottom))
          .tickFormat('' as any)
        )
        .selectAll('.tick line')
        .style('stroke', 'lightgray'); // Define a cor das linhas de grade

      // Adiciona as linhas de grade no eixo Y
      this._svgChart.append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(${this._svgChartMargin.left}, 0)`)
        .call(d3.axisLeft(this._axisY)
          .ticks(yGridTick)
          .tickValues(d3.range(yMin, yMax + yStepGrid, yStepGrid)) // Ajuste os valores dos ticks para começar no valor desejado
          .tickSize(-this._svgChartWidth + this._svgChartMargin.left + this._svgChartMargin.right)
          .tickFormat('' as any)
        )
        .selectAll('.tick line')
        .style('stroke', 'lightgray'); // Define a cor das linhas de grade
    }

    // Add X Axis Label
    this._svgChart.append('text')
      .attr('class', 'x-axis-label')
      .attr('text-anchor', 'middle')
      .attr('x', this._svgChartWidth / 2)
      .attr('y', this._svgChartHeight - (this._svgChartMargin.bottom / 15))
      .text(`${this._objChartCGLimits.chart_type == "longitudinal" ? "Longitudinal" : "Lateral"} CG (${xUnit})`);

    // Add Y Axis Label
    this._svgChart.append('text')
      .attr("class", "axis-label")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-90)")
      .attr("y", 10)
      .attr("x", ((this._svgChartHeight / 2) * -1) + (this._svgChartMargin.top))
      .text(`Gross Weight - (${yUnit})`);
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Desenha as linhas do SVG do componente ChartCGLimits.
   */
  private _drawDataLimitLine(): void {
    // Define the line
    const line = d3.line()
      .x((d: any) => this._axisX(d.x))
      .y((d: any) => this._axisY(d.y));

    for (let i = 0; i < this._objChartCGLimits.limits_data.length; i++) {
      // preciso que seja feita uma copia do conteúdo e não fo endereço de memória
      const tempCoordinates: any = JSON.parse(JSON.stringify(this._objChartCGLimits.limits_data[i]["draw_coordinates"]));

      //Preciso remover a propriedade id de cada elemento do objeto de arrays
      for (let j = 0; j < tempCoordinates.length; j++) {
        delete tempCoordinates[j].id;
      }

      // Add the line to the SVG
      this._svgChart.append('path')
        .datum(tempCoordinates)
        .attr('fill', LocalMethodsHandlerClass.hexToRgba(this._objChartCGLimits.limits_data[i]["fill_color"], 0.2))
        .attr('stroke', this._objChartCGLimits.limits_data[i]["fill_color"])
        .attr('stroke-width', 2)
        .attr('d', line);

      // ACRESCENTA AS LEGENDAS, QUANDO TIVER
      if (this._objChartCGLimits.limits_data[i]["label"] != null && this._objChartCGLimits.limits_data[i]["label"] != "") {
        const textGroup = this._svgChart.append('g');

        let tempX = "0";
        let tempY = "0";

        if (this._objChartCGLimits.limits_data[i]["label_position"] && this._objChartCGLimits.limits_data[i]["label_position"] != null && this._objChartCGLimits.limits_data[i]["label_position"] != "") {
          tempX = this._objChartCGLimits.limits_data[i]["label_position"].replaceAll("(", "").replaceAll(")", "").split(",")[0];
          tempY = this._objChartCGLimits.limits_data[i]["label_position"].replaceAll("(", "").replaceAll(")", "").split(",")[1];
        }

        // Adiciona o texto para calcular suas dimensões
        const textElement = textGroup.append('text')
          .attr('x', this._axisX(tempX)) // Converte a coordenada X do gráfico para a coordenada X do SVG
          .attr('y', this._axisY(tempY)) // Converte a coordenada Y do gráfico para a coordenada Y do SVG
          .attr('text-anchor', 'middle') // Alinhamento do texto          
          .style('font-size', `${this._objChartCGLimits.limits_data[i]["label_font_size"]}px`) // Tamanho da fonte
          .style('font-weight', 'bold')
          .style('letter-spacing', '0.2rem')
          .style('fill', this._objChartCGLimits.limits_data[i]["label_color"]) // Cor do texto
          .text(this._objChartCGLimits.limits_data[i]["label"].toUpperCase()); // Conteúdo do texto

        // Obtém as dimensões do texto
        const bbox = textElement.node().getBBox();

        // Adiciona o retângulo de fundo
        textGroup.insert('rect', 'text')
          .attr('x', bbox.x - 2) // Ajuste a posição X do retângulo
          .attr('y', bbox.y - 2) // Ajuste a posição Y do retângulo
          .attr('width', bbox.width + 6) // Ajuste a largura do retângulo
          .attr('height', bbox.height + 4) // Ajuste a altura do retângulo
          .style('fill', LocalMethodsHandlerClass.hexToRgba(this._objChartCGLimits.limits_data[i]["fill_color"], 0.2)) // Cor de fundo
          .style('stroke-width', 1); // Largura da borda
      }
    }
  }

  private _drawPoints() {
    if (this._arrPointsToDraw && this._arrPointsToDraw != null && this._arrPointsToDraw.length > 0) {
      this._arrPointsToDraw.forEach((xPoint: any) => {
        this._removePointByLabel(xPoint.label);

        // Adiciona um círculo preenchido
        /**/this._svgChart.append('circle')
          .attr('cx', this._axisX(xPoint.x))
          .attr('cy', this._axisY(xPoint.y))
          .attr('r', 5)
          .attr('fill', xPoint.color)
          .datum(xPoint); // Attach data to the element

        // Adiciona um círculo vazado com um ponto central preenchido
        /*this._svgChart.append('circle')
          .attr('cx', this._axisX(xPoint.x) + 20) // Ajuste a posição conforme necessário
          .attr('cy', this._axisY(xPoint.y))
          .attr('r', 6)
          .attr('fill', 'none')
          .attr('stroke', 'blue')
          .attr('stroke-width', 2)
          .datum(xPoint); // Attach data to the element

        this._svgChart.append('circle')
          .attr('cx', this._axisX(xPoint.x) + 20) // Ajuste a posição conforme necessário
          .attr('cy', this._axisY(xPoint.y))
          .attr('r', 2)
          .attr('fill', 'blue')
          .datum(xPoint); // Attach data to the element
*/
        // Adiciona uma legenda ao lado direito do ponto
        this._svgChart.append('text')
          .attr('x', this._axisX(xPoint.x) + 10) // Ajuste a posição conforme necessário
          .attr('y', this._axisY(xPoint.y))
          .attr('dy', '.35em')
          .attr('text-anchor', 'start')
          .attr('fill', xPoint.color)
          .text(`${xPoint.label}`)
          .datum(xPoint); // Attach data to the element
      });
    }
  }

  private _removePointByLabel(label: string) {
    this._svgChart.selectAll('circle')
      .filter((d: any) => d && d.label === label)
      .remove();

    this._svgChart.selectAll('text')
      .filter((d: any) => d && d.label === label)
      .remove();
  }
  //#endregion
}
