//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASSES ***** //
import { AviationToolsHandlerClass } from '../../../../_classes/AviationToolsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

// ***** COMPONENT ***** //
import { CountryFlagComponent } from '../../../../_components/layout/country-flag/country-flag.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleFlightService } from '../../../../_services/module-flight/module-flight.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-planning-data-list',
  standalone: true,
  imports: [
    CommonModule,
    CountryFlagComponent,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './planning-data-list.component.html'
})
export class PlanningDataListComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public typeDialogWaiting!: string;

  // ***** CLASSES ***** //
  public classModuleMethods!: any;
  public classAviationTools!: any;

  // ***** DIALOG ***** //
  public bolShowDialogWaiting!: boolean;

  // ***** LIST ***** //
  public listFlightData: any[] = [];

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _messageService: MessageService,
    private _moduleFlightApisService: ModuleFlightService,
    private _confirmationService: ConfirmationService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();

    this._loadDataDb();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   *   - Executado uma única vez, antes de qualquer outro método e, principalmente, de renderizar qualquer componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;

    this.classAviationTools = AviationToolsHandlerClass;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.bolShowDialogWaiting = false;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.FLIGHT_DATA_LIST;

    this.objPageTitle = {
      title: 'Listar Voos',
      subtitle: 'Voos Cadastrados no Sistema.',
      icon: 'fa-solid fa-plane-departure'
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega a lista de Dados dos Voos.
   */
  private async _loadDataDb(): Promise<void> {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.FLIGHT_DATA_LIST;
    this.bolShowDialogWaiting = true;

    this.listFlightData = [];

    Promise.resolve([
      await this._loadFlightDataList()
    ]);

    setTimeout(() => {
      this.bolShowDialogWaiting = false;
    }, this.currentSettings.WAITING_DIALOG_LIFE);
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Redireciona para a página de Fomulário: de Flight Data - NOVO.
   */
  private async _newFlightData(): Promise<void> {
    this._router.navigateByUrl(`/app/${this._locale}/planning/data/form`.toLowerCase());
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Redireciona para a página de Fomulário: Flight Data - EDITAR.
   * 
   */
  private async _editFlightData(xIdFlightData: string): Promise<void> {
    this._router.navigateByUrl(`/app/${this._locale}/planning/data/form/edit/${xIdFlightData}`);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"  
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *     - Método que trata a ação de fechar o dialog de espera.
   */
  public handlerActionCloseWaitingDialog(xBolCloseDialog: boolean): void {
    this.bolShowDialogWaiting = xBolCloseDialog;
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"  
  /**
    * @status: OK
    * @author GASPAR
    * @date 2024-09-28
    * @version 1.0.0
    * 
    * @description 
    *   - Faz o tratamento das ações dos botões da TABELA.
    * 
    * @param xButtonAction
    */
  public onClickTableList(xButtonAction: string): void {
    if (xButtonAction == ACTION_TYPE.NEW) {
      this._newFlightData()
    } else if (xButtonAction == ACTION_TYPE.RELOAD) {
      this._loadFlightDataList();
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Emite as ações dos botões de um REGISTRO listado na tabela.
   * 
   * @param xButtonAction 
   * @param xIdRegister 
   */
  public onClickItemTableList(xButtonAction: string, xIdRegister: string): void {
    if (xButtonAction == ACTION_TYPE.EDIT) {
      this._editFlightData(xIdRegister);
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-03-16
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega a lista de Dados dos Voos.
   */
  private async _loadFlightDataList(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleFlightApisService.getAllFlightDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listFlightData = [];
            this.listFlightData = xApiResponse;
          } else {
            this.listFlightData = [];

            this._messageService.add({
              severity: 'error',
              summary: 'Listar Dados de Voos',
              detail: 'Não foi possível carregar a lista de Dados de Voos.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });            
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this.bolShowDialogWaiting = false;

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: 'Listar Dados de Voos',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          this.listFlightData = [];

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }
  //#endregion
}
