//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../_services/_app-locale/app-locale.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleWeightBalanceService {
  //#region "|--- PROPERTIES---|" 
  urlWeightBalanceModuleService: string;
  //#endregion

  constructor(
    private _appLocaleService: AppLocaleService,
    private _http: HttpClient
  ) {
    if (settingConfig.API_AIRCRAFT_PORT == "443") {
      this.urlWeightBalanceModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlWeightBalanceModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_WEIGHT_BALANCE_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- WEIGHT BALANCE DATA SHEET ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por salvar os dados a Folha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceDataSheetForm 
   * 
   * @returns 
   */
  public saveFormWeightBalanceDataSheet(xWeightBalanceDataSheetForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.post<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/${tempLocale}/weight/balance/data/sheet`, xWeightBalanceDataSheetForm.getRawValue(), { headers })
      .pipe(
        catchError((error) => _handleError(error, xWeightBalanceDataSheetForm))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR TODOS os dados relacionados ao módulo: WEIGHT BALANCE DATA SHEET.
   * 
   * @returns 
   */
  public getAllWeightBalanceDataSheetList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/${tempLocale}/weight/balance/data/sheet/list`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por buscar a Folha de Peso e Balanceamento pelo ID da Aeronave.
   * 
   * @param idRegister 
   * @returns 
   */
  public getWeightBalanceSheetById(idRegister: string): Observable<any> {
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/${tempLocale}/weight/balance/data/sheet/${idRegister}`)
      .pipe(
        catchError((error) => _handleError(error, null))
      )
  }

  /**
   * @status:
   * author GASPAR
   * @date 2024-09-29
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por buscar a Folha de Peso e Balanceamento pelo ID da Aeronave.
   * 
   * @param xAircraftId 
   * @returns 
   */
  public getWeightBalanceDataSheetByAircraft(xAircraftId: string): Observable<any> {
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/${tempLocale}/weight/balance/data/sheet/aircraft/${xAircraftId}`)
      .pipe(
        catchError((error) => _handleError(error, null))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por atualizar os dados a Folha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceForm 
   * @param xWeightBalanceId 
   * @returns 
   */
  public updateFormWeightBalanceDataSheet(xWeightBalanceId: string, xWeightBalanceForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.put<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/${tempLocale}/weight/balance/data/sheet/${xWeightBalanceId}`, xWeightBalanceForm.getRawValue(), { headers })
      .pipe(
        catchError((error) => _handleError(error, xWeightBalanceForm))
      )
  }

   /**
   * @status:
   * @author GASPAR
   * @date 2025-02-03
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por deletar a Folha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceId 
   * @returns 
   */
   deleteWeightBalanceSheet(xWeightBalanceId: string): Observable<any> {
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.delete<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/${tempLocale}/weight/balance/data/sheet/${xWeightBalanceId}`)
      .pipe(
        catchError((error) => _handleError(error, null))
      )
  }
  //#endregion
}