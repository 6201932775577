//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';

// ***** CLASSES ***** //
import { hasControlErrors } from '../_methods/_controls-methods';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-handler-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './_handler_error.component.html'
})
export class HandlerErrorComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() typeControl!: string;
  @Input() hasError!: string;
  //#endregion

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void { }

  showErrors(): any {
    return hasControlErrors(this.control, this.typeControl);
  }
}
export { hasControlErrors };

