<div class="cls-ui-primeng-field cls-ui-primeng-field-input-color">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.label}}
        </label> 
        <div class="cls-ui-primeng-field-input-color-frame">
            <div class="cls-ui-primeng-field-input-color-frame-color">
                <p-colorPicker 
                    [formControl]="this.control"
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    appendTo="body"
                    />
            </div>
            <div class="cls-ui-primeng-field-input-color-frame-input">
                <input
                    [formControl]="this.inputTextControl"
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    type="text"
                    name="{{this.getFieldName()}}" 
                    id="{{this.idField}}" 
                    pInputText />
            </div>           
        </div>
    </div>
</div>
