
//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { LocalMethodsHandlerClass } from '../../_library/classes/LocalMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAircraftTypes } from '../../_library/static_options/staticOptionsAircraftTypes';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { IChartCGLimitations } from '../../_library/interfaces/IChartCGLimitations';
//#endregion

export class ModuleMethodsHandlerClass {
  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna o label do tipo de aeronave.
   * 
   * @param xAircraftType 
   * @returns 
   */
  static getAircraftTypeLabel(xAircraftType: string): string {
    if (xAircraftType === null || xAircraftType === undefined) {
      return '';
    } else {      
      const aircraftType = staticOptionsAircraftTypes.find(x => x.value === xAircraftType.toLowerCase());

      return aircraftType ? `<i class="${aircraftType.icon}"></i> ${aircraftType.label}` : '';
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna a matricula da Aeronave formatada conforme o país de registro
   * 
   * @param xMark 
   * @returns 
   */
  static setAircraftMark(xMark: string): string {
    // peque as 2 primeiras letras de xMark e veja se ele é igual a PR ou PP)
    if (xMark.substring(0, 2) === 'PR' || xMark.substring(0, 2) === 'PP' || xMark.substring(0, 2) === 'PT' || xMark.substring(0, 2) === 'P') {
      return `${xMark.substring(0, 2)}-${xMark.substring(2, 5)}`; // BRASIL
    } else {
      return xMark;
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Configura as unidades do gráfico de Limitações de CG com as unidades.
   * 
   * @param xUnits 
   * @param xObjOriginal 
   * @returns 
   */
  static settingUnitsChartCgLimitations(xUnits: any, xObjOriginal: IChartCGLimitations): IChartCGLimitations {

    xObjOriginal.x_unit = xUnits.weight;
    xObjOriginal.y_unit = xUnits.length;

    return xObjOriginal;
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Retorna o label do tipo de aeronave.
   * 
   * @returns 
   */
  static getFunctionFieldName(): any {
    return (xFiledId: string): any => {
      switch (xFiledId) {
        case 'avatar_image':
          return 'Imagem Aeronave';
        case 'avatar_color':
          return 'Cor Aeronave no Sistema';
        case 'registration_data.country':
          return 'País de Registro';
        case 'registration_data.aircraft_type':
          return 'Tipo Aeronave';
        case 'registration_data.mark':
          return 'Matrícula';
        case 'registration_data.icao_code':
          return 'ICAO Code';
        case 'registration_data.year_manufactured':
          return 'Ano Fabricação';
        case 'registration_data.manufacturer':
          return 'Fabricante';
        case 'registration_data.model':
          return 'Modelo';
        case 'registration_data.serial_number':
          return 'Número Serial';
        case 'registration_data.flight_rule':
          return 'Regra de Voo';
        case 'ni':
          return 'CNPJ/CPF';
        case 'name':
          return 'Nome';
        case 'address.country':
          return 'País';
        case 'address.zip_code':
          return 'CEP';
        case 'address.street_name':
          return 'Logradouro';
        case 'address.city':
          return 'Cidade';
        case 'address.state':
          return 'Estado';
        case 'contacts.mobile':
          return 'Celular/WhatsApp';
        case 'contacts.email':
          return 'E-mail';
        default:
          return xFiledId;
      }
    }
  }
}