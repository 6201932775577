
<div class="cls-ui-primeng-field mb-1">
    <ng-container *ngIf="showErrors()">
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['fieldServerError']">
            <small class="p-error">{{ control.errors?.['fieldServerError'].message }}</small>
        </div>
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['required']">
            <small class="p-error">Valor Obrigatório.</small>
        </div>
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['minlength']">
            <small class="p-error">Tamanho Mínimo: {{ control.errors?.['minlength'].requiredLength }}
                caracteres.</small>
        </div>
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['maxlength']">
            <small class="p-error">Tamanho Máximo: {{ control.errors?.['maxlength'].requiredLength }}
                caracteres.</small>
        </div>
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['domain']">
            <small class="p-error">O Valor informado não pertence ao domínio esperado.</small>
        </div>
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['unique']">
            <small class="p-error">O Valor já está cadastrado na base.</small>
        </div>
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['extension-image']">
            <small class="p-error">A imagem deve ter extensão: jpg, jpeg, npg.</small>
        </div>
        <div class="help cls-ui-primeng-field-wrong" *ngIf="control.errors?.['pattern']">
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '/^[a-zA-Z0-9]+$/'">
                <small class="p-error">Somente Letras e Números, sem hífen, underline ou espaço em branco!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '/^[0-9]+$/'">
                <small class="p-error">Somente Números!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[a-zA-Z0-9]+$'">
                <small class="p-error">Somente Letras e Números!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[a-zA-Z]+$'">
                <small class="p-error">Somente Letras!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[0-9]+$'">
                <small class="p-error">Somente Números Inteiros!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^\\d*\\.?\\d*$'">
                <small class="p-error">Somente Números. Usar ponto (.) para decimal.</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[KkNn]\\d{4}|[Mm]\\d{3}$'">
                <small class="p-error">O Conteúdo deve começar com a Letra K ou N seguido de 4 algorismos ou pela letra
                    M seguido de 3 Algarismos!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[AaFf]\\d{3}|VFR|vfr$'">
                <small class="p-error">O Conteúdo deve começar com a Letra A ou F seguido de 3 algorismos ou pela
                    palavra VFR!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[0-9]+ - [a-zA-Z0-9 ]*$'">
                <small class="p-error">Formato: CANAC - NOME_PILOTO</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^(3[01]|[12][0-9]|0[1-9])/(1[0-2]|0[1-9])/[0-9]{4}$'">
                <small class="p-error">Formato: dd/mm/aaaa</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'">
                <small class="p-error">Formato: hh:mm</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[iIvVyYzZ]$'">
                <small class="p-error">Formato: I, V, V ou Z</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '^[a-zA-Z0-9_-]+$'">
                <small class="p-error">Somente Letras, Números, Hífen e Underline. Não permite Espaço em Branco!</small>
            </ng-container>
            <ng-container *ngIf="control.errors?.['pattern'].requiredPattern === '/^[a-zA-Z][a-zA-Z0-9#@._-]*$/'">
                <small class="p-error">Obrigatoriamente iniciar com letra seguida de outras letras, números ou de um dos caracteres entre parenteses: (#&#64;.-_)</small>
            </ng-container>
        </div>
    </ng-container>
</div>