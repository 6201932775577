/**
 * @author GASPAR
 * @date 2024-09-24
 * @version 1.0.0
 * 
 * @description Enumerador de ações de botões.
 */
export const DIALOG_WAITING_TYPE = {
    DEFAULT: "default",
    ANAC_RAB: "anac_rab",    
    AIRCRAFT_DATA_LIST: "aircraft_data_list",
    AIRCRAFT_DATA: "aircraft_data",
    ACCOUNT_DATA_LIST: "account_data_list",
    ACCOUNT_DATA: "account_data",
    CREW_DATA_LIST: "crew_data_list",
    CREW_DATA: "crew_data",
    PAX_DATA_LIST: "pax_data_list",
    PAX_DATA: "pax_data",
    FLIGHT_DATA_LIST: "flight_data_list",
    FLIGHT_DATA: "flight_data",
    FLIGHT_WEIGHT_BALANCE_LIST: "flight_weight_balance_list"
}