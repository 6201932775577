//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../_services/_app-locale/app-locale.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleIamAppService {
  //#region "|--- PROPERTIES---|" 
  public urlIamService: string;
  //#endregion

  constructor(
    private _appLocaleService: AppLocaleService,
    private _http: HttpClient
  ) {
    if (settingConfig.API_IAM_APP_PORT == "443") {
      this.urlIamService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlIamService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_IAM_APP_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- LOGIN SERVICES---|" 
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por realizar o login do usuário no sistema.
   * 
   * @param xFormLogin 
   * @returns 
   */
  public loginFormApp(xFormLogin: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    this._clearUserCredentials();

    return this._http.post(`${this.urlIamService}/${settingConfig.API_IAM_APP_URL_SEGMENT}/${tempLocale}/iam/user/login`, xFormLogin.getRawValue(), { headers })
      .pipe(
        map((response: any) => {
          if (response.status_code === HTTP_STATUS.OK) {
            this.saveAppToken(response.data[0].data[0].token);

            return response;
          } else {
            throw new Error('Login failed');
          }
        }),
        catchError((xError) => _handleError(xError, xFormLogin))
      )
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-17
   * @version 1.0.0
   * 
   * @description
   *   - Método responsável por verificar e validar o token do usuário.
   * 
   * @param xToken 
   * @returns 
   */
  public verifyToken(): Observable<any> {
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get(`${this.urlIamService}/${settingConfig.API_IAM_APP_URL_SEGMENT}/${tempLocale}/iam/user/verify/token`)
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return true;
          } else {
            return false
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-17
   * @version 1.0.0
   * 
   * @description
   *   - Método responsável por obter o menu principal do usuário.
   * 
   * @returns 
   */
  public getAppMainMenu(): Observable<any> {
    if (localStorage.getItem('userAppMainMenu')) {
      return new Observable(observer => {
        observer.next(JSON.parse(localStorage.getItem('userAppMainMenu') || ''));
        observer.complete();
      });
    } else {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const tempLocale = this._appLocaleService.getLocale();
     
      return this._http.get<any>(`${this.urlIamService}/${settingConfig.API_IAM_APP_URL_SEGMENT}/${tempLocale}/iam/user/main/menu`, { headers })
        .pipe(
          map((xResponse: any) => {
            if (xResponse.status_code == HTTP_STATUS.OK) {
              localStorage.setItem('userAppMainMenu', JSON.stringify(xResponse.data));

              return xResponse.data;
            } else {
              return undefined; // Adicione um valor de retorno padrão
            }
          }),
          catchError((xError) => _handleError(xError, null))
        )
    }
  }
  
  public saveAppToken(token: string): void {
    localStorage.setItem('authAppToken', token);
  }

  public getAppToken(): string | null {
    return localStorage.getItem('authAppToken');
  }

  public logoutApp(): void {
    this._clearUserCredentials();
  }
  //#endregion










  //#region "|--- LOGIN SERVICES---|" 
  
  //#endregion

  //#region "|--- PRIVATE METHODS ---|" 
  private _clearUserCredentials(): void {
    localStorage.removeItem('authAppToken');
    localStorage.removeItem('userAppMainMenu');
  }
  //#endregion
}
