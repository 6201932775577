//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';

// ***** CLASS ***** //
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** COMPONENT ***** //
import { AircraftInfoCardComponent } from '../../../../../../../_components/layout/aircraft-info-card/aircraft-info-card.component';
import { UnitConversionDialogComponent } from '../../../../../../../../_library/layouts/components/canttech/unit_conversion_dialog/unit_conversion_dialog.component';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCgFuelUnits } from '../../../../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsCgArmUnits } from '../../../../../../../../_library/static_options/staticOptionsCgArmUnits';
import { staticOptionsCgWeightUnits } from '../../../../../../../../_library/static_options/staticOptionsCgWeightUnits';
import { staticOptionsCgSpeedUnits } from '../../../../../../../../_library/static_options/staticOptionsCgSpeedUnits';
import { staticOptionsFuelRateUnits } from '../../../../../../../../_library/static_options/staticOptionsFuelRateUnits';
import { staticOptionsFuelType } from '../../../../../../../../_library/static_options/staticOptionsFuelType';
import { staticOptionsAircraftTurbulenceCategory } from '../../../../../../../../_library/static_options/staticOptionsAircraftTurbulenceCategory';
import { staticOptionsAircraftPerformanceCategory } from '../../../../../../../../_library/static_options/staticOptionsAircraftPerformanceCategory';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../../../../_services/module-aircraft/module-aircraft.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-specification-data',
  standalone: true,
  imports: [
    AircraftInfoCardComponent,
    CommonModule,
    PrimengComponentsModule,
    UnitConversionDialogComponent
  ],
  templateUrl: './tab-specification-data.component.html'
})
export class TabSpecificationDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabSpecificationData = new EventEmitter<string>();
  @Output() actionSpecificationData = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public actionType!: any;
  public currentSettings!: any
  public advicesToUser!: Message[];

  // ***** CLASSES ***** //
  public classModuleMethods!: any;
  public classInternationalization!: any;

  // ***** SHOW DIALOG ***** //
  public bolShowDialogRadioNavigation!: boolean;
  public bolShowDialogSsr!: boolean;
  public bolShowDialogPbn!: boolean;
  public bolShowCloneDialog!: boolean;

  //#region "|--- UNIT CONVERSION ---|" 
  public bolShowDialogUnitConversion!: boolean;

  public unitConversionControl!: any;
  public unitConversionType!: any;
  public unitConversionOriginalValue!: any;
  public unitConversionUnitToConvert!: any;
  //#endregion

  // ***** DROP-DOWN OPTIONS ***** //
  public optionsCgFuelUnits!: IDropDownOptionsBasic[];
  public optionsCgArmUnits!: IDropDownOptionsBasic[];
  public optionsCgWeightUnits!: IDropDownOptionsBasic[];
  public optionsCgSpeedUnits!: IDropDownOptionsBasic[];
  public optionsFuelRateUnits!: IDropDownOptionsBasic[];
  public optionsFuelType!: IDropDownOptionsBasic[];
  public optionsAircraftTurbulenceCategory!: IDropDownOptionsBasic[];
  public optionsAircraftPerformanceCategory!: IDropDownOptionsBasic[];

  // ***** LIST ***** //
  public listAircraftSpecification: any[] = [];
  //#endregion

  constructor(
    private _moduleAircraftService: ModuleAircraftService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-21
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Peso e Balanceamento.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.bolShowDialogUnitConversion = false;

    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;

    this.bolShowDialogRadioNavigation = false;
    this.bolShowDialogSsr = false;
    this.bolShowDialogPbn = false;
    this.bolShowCloneDialog = false;

    this.optionsCgWeightUnits = staticOptionsCgWeightUnits;
    this.optionsCgArmUnits = staticOptionsCgArmUnits;
    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;
    this.optionsCgSpeedUnits = staticOptionsCgSpeedUnits;
    this.optionsFuelRateUnits = staticOptionsFuelRateUnits;
    this.optionsFuelType = staticOptionsFuelType;
    this.optionsAircraftTurbulenceCategory = staticOptionsAircraftTurbulenceCategory;
    this.optionsAircraftPerformanceCategory = staticOptionsAircraftPerformanceCategory;

    this.advicesToUser = [
      { severity: 'success', detail: this.classInternationalization.getTranslation('adv_SpecificationUse') },
      { severity: 'warn', detail: this.classInternationalization.getTranslation('adv_TakeCareUnits') }
    ];
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  public onClickItemTableListSpecification(xButtonAction: string, xIdRegister: string): any {
    if (xButtonAction == ACTION_TYPE.CLONE) {
      const tempAircraftData = this.listAircraftSpecification.find((xItem: any) => xItem.aircraft_data_id === xIdRegister);

      this.formComponent.controls.general_data.patchValue(tempAircraftData.general_data, { emitEvent: false });

      this._messageService.add({
        severity: 'success',
        summary: "Clonar Especificações da Aeronave",
        detail: "Especificação da aeronave clonada com sucesso.",
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });

      this.bolShowCloneDialog = false;
    }
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-12-30
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será associado ao onClick para Abrir a Tela de Conversão de Dados.
  * 
  * @returns 
  */
  public onClickConverterValue(xTypeConvert: string): any {
    return async (xEvent: any, xComponentContext: any) => {
      // Recuperar o elemento que disparou o evento.
      const inputElement = xEvent.target as HTMLInputElement;

      //#region "|--- UNIT CONVERSION ---|" 
      this.unitConversionControl = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionControl = xComponentContext.control;

      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = xTypeConvert;

      this.unitConversionOriginalValue = null;
      this.unitConversionOriginalValue = xComponentContext.control.value;

      this.unitConversionUnitToConvert = null;
      this.unitConversionUnitToConvert = inputElement.innerText || inputElement.textContent;
      //#endregion

      this.bolShowDialogUnitConversion = true;
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-01-01
   * @version 1.0.0
   * 
   * @description 
   *   - Emite a ação do botão da Toolbar da Tab de Dados de Especificação.
   */
  public async onClickToolbarTabSpecificationData(xButtonAction: string): Promise<void> {
    if (xButtonAction === this.actionType.SAVE) {
      this.actionTabSpecificationData.emit(xButtonAction);
    } else if (xButtonAction === this.actionType.OPEN_UNIT_CONVERSION) {
      //#region "|--- UNIT CONVERSION ---|" 
      this.unitConversionControl = null;
      this.unitConversionType = null;
      this.unitConversionOriginalValue = null;
      this.unitConversionUnitToConvert = null;
      //#endregion

      this.bolShowDialogUnitConversion = true;
    } else if (xButtonAction === this.actionType.OPEN_DIALOG) {
      await this._loadAircraftSpecification();

      this.bolShowCloneDialog = true;
    }
  }

  /**
  * 
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de Navegação Rádio.
   */
  public onClickMethodRadioNavigation(): any {
    return async (xEvent: any, xComponentContext: any) => {
      this.bolShowDialogRadioNavigation = true;
    }
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de SSR.
   */
  public onClickMethodSsr(): any {
    return async (xEvent: any, xComponentContext: any) => {
      this.bolShowDialogSsr = true;
    }
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de PBN.
   */
  public onClickMethodPbn(): any {
    return async (xEvent: any, xComponentContext: any) => {
      this.bolShowDialogPbn = true;
    }
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de PBN.
   */
  public onClickToolbarDialogRadioNavigation(xButtonAction: string): void {
    if (xButtonAction === ACTION_TYPE.EXIT) {
      this.bolShowDialogRadioNavigation = false;
    } else if (xButtonAction === ACTION_TYPE.SAVE) {
      const tempRadioNavigationData = [];

      this.bolShowDialogRadioNavigation = false;
      this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_text.setValue("");

      for (const key in this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls) {
        if (Object.prototype.hasOwnProperty.call(this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls, key)) {
          const element = this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls[key];

          if (element.value === true && key !== "item18_nav" && key !== "item18_com" && key !== "item18_dat") {
            tempRadioNavigationData.push(key.toUpperCase());

            if (key === "z") {
              const temp18Nav = this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.item18_nav.value;
              const temp18Com = this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.item18_com.value;
              const temp18Dat = this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_data.controls.item18_dat.value;

              if (temp18Nav && temp18Nav !== null && temp18Nav !== "") {
                tempRadioNavigationData.push(` NAV/${temp18Nav} `);
              }

              if (temp18Com && temp18Com !== null && temp18Com !== "") {
                tempRadioNavigationData.push(` COM/${temp18Com} `);
              }

              if (temp18Dat && temp18Dat !== null && temp18Dat !== "") {
                tempRadioNavigationData.push(` DAT/${temp18Dat} `);
              }
            }
          }
        }
      }

      this.formComponent.controls.general_data.controls.equipment_data.controls.radio_navigation_text.setValue(tempRadioNavigationData.join(""));

      this.actionSpecificationData.emit({ action: ACTION_TYPE.UPDATE });
    }
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de SSR.
   */
  public onClickToolbarDialogSsr(xButtonAction: string): void {
    if (xButtonAction === ACTION_TYPE.EXIT) {
      this.bolShowDialogSsr = false;
    } else if (xButtonAction === ACTION_TYPE.SAVE) {
      const tempSsrData = [];

      this.bolShowDialogSsr = false;
      this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_text.setValue("");

      for (const key in this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls) {
        if (Object.prototype.hasOwnProperty.call(this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls, key)) {
          const element = this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls[key];

          if (element.value === true && key !== "item18_sur") {
            tempSsrData.push(key.toUpperCase());
          } else {
            const temp18_sur = this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_data.controls.item18_sur.value;
            if (key === "item18_sur" && temp18_sur !== null && temp18_sur !== "") {
              tempSsrData.push(` SUR/${temp18_sur} `);
            }
          }
        }
      }

      this.formComponent.controls.general_data.controls.equipment_data.controls.ssr_text.setValue(tempSsrData.join(""));

      this.actionSpecificationData.emit({ action: ACTION_TYPE.UPDATE });
    }
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de Pbn.
   */
  public onClickToolbarDialogPbn(xButtonAction: string): void {
    if (xButtonAction === ACTION_TYPE.EXIT) {
      this.bolShowDialogPbn = false;
    } else if (xButtonAction === ACTION_TYPE.SAVE) {
      const tempPbnData = [];

      this.bolShowDialogPbn = false;
      this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_text.setValue("");

      for (const key in this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls) {
        if (Object.prototype.hasOwnProperty.call(this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls, key)) {
          const element = this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_data.controls[key];

          if (element.value !== null && element.value !== "") {
            tempPbnData.push(key.toUpperCase());
          }
        }
      }

      this.formComponent.controls.general_data.controls.equipment_data.controls.pbn_text.setValue(tempPbnData.join(""));

      this.actionSpecificationData.emit({ action: ACTION_TYPE.UPDATE });
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-20
   * @version 1.0.0
   * 
   * @description 
   *   - Método que recupera as especificações da aeronave.
   */
  private async _loadAircraftSpecification(): Promise<void> {
    this._moduleAircraftService.getAllAircraftSpecificationList().subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.listAircraftSpecification = [];
          this.listAircraftSpecification = xApiResponse;

          // Filtrar somente os registros como oo mesmo ICAO da aeronave
          this.listAircraftSpecification = this.listAircraftSpecification.filter((xItem: any) => xItem.register_data.icao_code === this.formComponent.controls.register_data.controls.icao_code.value);

        } else {
          this.listAircraftSpecification = [];

          this._messageService.add({
            severity: 'error',
            summary: "Recuperar Especificações da Aeronave",
            detail: "Houve um erro ao recuperar as especificações da aeronave.",
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: "Recuperar Especificações da Aeronave",
          detail: `${xError.detail}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });

        this.listAircraftSpecification = [];
      }
    });
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Dialogo de Transformação de Unidades.
   * 
   * @param xAction 
   */
  public async handlerActionUnitConversionDialog(xAction: any): Promise<void> {
    if (xAction === ACTION_TYPE.EXIT) {
      this.unitConversionControl = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionOriginalValue = null;
      this.unitConversionUnitToConvert = null;

      this.bolShowDialogUnitConversion = false;
    } else if (xAction === ACTION_TYPE.COPIED_TO_CLIPBOARD) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataCopiedClipboardSuccess'),
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else if (xAction === ACTION_TYPE.SAVE) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataSaveInField'),
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    }
  }
  //#endregion
}