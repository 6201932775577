//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description Opções estáticas para unidades de peso.
 */
export const staticOptionsCgWeightUnits: IDropDownOptionsBasic[] = [    
    { label: "lb - LIBRA/POUND", value: "lb" },
    { label: 'kg - QUILOGRAMAS', value: 'kg' }
]