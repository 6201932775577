//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// ***** PRIMENG ***** //
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../_library/definitions/ButtonAction';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-contact-component',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    PrimengComponentsModule,
  ],
  templateUrl: './contacts-component.component.html'
})
export class ContactsComponentComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  //#endregion

  //region "|--- PROPERTIES ---|"
  public formContacts!: any;
  public buttonActions!: any;

  public currentContact = null;

  public bolShowDialog!: any;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;
    this.bolShowDialog = false;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Adiciona um novo contato.
   * 
   * @param action 
   */
  callbackTableContactClickButton(xAction: string) {
    if (xAction == BUTTON_ACTION.NEW) {
      this.bolShowDialog = true;
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões do Painel.
   * 
   * @param xAction 
   */
  callbackDialogClickButton(xAction: string) {
    if (xAction == BUTTON_ACTION.SAVE) {

    } else if (xAction == BUTTON_ACTION.UPDATE) {

    } else if (xAction == BUTTON_ACTION.EXIT) {
      this.bolShowDialog = false;
    }
  }
  //#endregion
}
