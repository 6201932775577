//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../_services/_app-locale/app-locale.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleCrewService {
  //#region "|--- PROPERTIES---|" 
  urlCrewModuleService: string;
  //#endregion

  constructor(
    private _appLocaleService: AppLocaleService,
    private _http: HttpClient
  ) {
    if (settingConfig.API_CREW_PORT == "443") {
      this.urlCrewModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlCrewModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_CREW_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- CREW DATA ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por SALVAR os dados relacionados ao módulo: CREW DATA.
   * 
   * @param xCrewDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele
   * @returns 
   */
  public saveCrewData(xCrewDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.post<any>(`${this.urlCrewModuleService}/${settingConfig.API_CREW_URL_SEGMENT}/${tempLocale}/crew/data`, xCrewDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xCrewDataForm))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR TODOS os dados relacionados ao módulo: CREW DATA.
   * 
   * @returns 
   */
  public getAllCrewDataList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlCrewModuleService}/${settingConfig.API_CREW_URL_SEGMENT}/${tempLocale}/crew/data/list`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR BY ID os dados relacionados ao módulo: CREW DATA.
   * 
   * @param xCrewDataId
   * @returns 
   */
  public getCrewDataById(xCrewDataId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlCrewModuleService}/${settingConfig.API_CREW_URL_SEGMENT}/${tempLocale}/crew/data/${xCrewDataId}`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por ATUALIZAR os dados relacionados ao módulo: CREW DATA.
   * 
   * @param xCrewDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele.
   * @param xCrewDataId
   * @returns
   */
  public updateCrewData(xCrewDataId: string, xCrewDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.put<any>(`${this.urlCrewModuleService}/${settingConfig.API_CREW_URL_SEGMENT}/${tempLocale}/crew/data/${xCrewDataId}`, xCrewDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xCrewDataForm))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por Verificar se existe o Documento do Tipo e Numero.
   * 
   * @param xCrewDataId
   * @returns 
   */
  public checkIfHasDocumentValue(xDocumentType: string, xFieldValue: string): Observable<any> {
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlCrewModuleService}/${settingConfig.API_CREW_URL_SEGMENT}/${tempLocale}/crew/data/document/${xDocumentType}/${xFieldValue}`)
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }
  //#endregion
}