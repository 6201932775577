//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';
import { configAircraft } from '../../../../../_library/config/configAircraft';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initFlightDataForm } from '../../form-init/flight-data-form';
import { initFlightCrewForm } from '../../form-init/flight-crew-form';
import { initAerodromeBasicDataForm } from '../../form-init/aerodrome-basic-data-form';

// ***** MOCK ***** //

// ***** DOMÍNIOS ***** //
import { domainBasicAerodromeTypes } from '../../../../../_library/domains/domainBasicAerodromeTypes';

// ***** COMPONENT ***** //
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { TabFlightRequestComponent } from './_components/flight-request/tab-flight-request/tab-flight-request.component';
import { TabWeightBalancePaxComponent } from './_components/weight-balance-pax/tab-weight-balance-pax/tab-weight-balance-pax.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { CountdownUtcComponent } from '../../../../_components/layout/countdown-utc/countdown-utc.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
import { WeightBalanceFilledComponent } from './_components/weight-balance-pax/weight-balance-filled/weight-balance-filled.component';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleFlightService } from '../../../../_services/module-flight/module-flight.service';
import { ModulePaxService } from '../../../../_services/module-pax/module-pax.service';
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
import { ModuleWeightBalanceService } from '../../../../_services/module-weight-balance/module-weight-balance.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
import { FLIGHT_CREW_ROLE } from '../../../../../_library/definitions/FlightCrewRole';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IDropDownOptionsBasic } from '../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-flight-data-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PrimengComponentsModule,
    TitlePageComponent,
    TabFlightRequestComponent,
    TabWeightBalancePaxComponent,
    ToastMessageComponent,
    CountdownUtcComponent,
    WaitingDialogComponent,
    WeightBalanceFilledComponent
  ],
  templateUrl: './flight-data-form.component.html'
})
export class FlightDataFormComponent implements OnInit, OnDestroy {  
  //#region "|--- PROPERTIES---|"
  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** FORMS ***** //
  public formFlightData: any

  // ***** ARRAYS ***** //
  public allAircraftListDb!: any[];
  public allWeightBalanceSheetListDb!: any[] | null; // Tem que ser NULL para forçar a mudança de estado e ser capturado pelo componente de Peso e Balanceamento.
  public allPilotsDb!: any[];

  // ***** OBJETO ***** //
  public objCurrentAircraft: any;

  // ***** DIALOG ***** //
  public bolShowWaitingDialog!: boolean;
  public strTypeWaitingDialog!: string;

  // ***** OPTIONS***** //
  public optionsAllAircraft!: IDropDownOptionsIcon[];
  public optionsAllPilots!: IDropDownOptionsIcon[];
  public optionsOperatorsByAircraft!: IDropDownOptionsBasic[];

  // ***** OTHERS ***** //
  

  public originUtcTime!: string; // Propriedade para armazenar o horário UTC
  public currentUtcDateTime!: string; // Propriedade para armazenar o horário UTC
  private _clockInterval!: any; // Propriedade para armazenar o intervalo do relógio
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleAircraftApisService: ModuleAircraftService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService,
    private _moduleFlightService: ModuleFlightService,
    private _modulePaxService: ModulePaxService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initClock();
    this._initFlightDataForm();

    //#region "|--- BANCO DE DADOS ---|"
    this._getAllAircraftDb();
    this._getAllPilotsDb();
    //#endregion

    this._initFormFlightDataEvents();
    this._checkEditMode();
  }

  ngOnDestroy(): void {
    // Limpe o intervalo quando o componente for destruído
    if (this._clockInterval) {
      clearInterval(this._clockInterval);
    }
  }

  //region "|--- EVENT HANDLERS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Atribui os eventos aos controles do Formulário.
   */
  private _initFormFlightDataEvents(): void {
    this.formFlightData.get('flight_request.aircraft_data.fk_aircraft_data_id').valueChanges.subscribe((xValue: any) => {

      if (xValue != null) {
        // Recupera os dados da Aeronave no Objeto que já foi recuperado...
        this.objCurrentAircraft = this.allAircraftListDb.find((xObjAircraft: any) => xObjAircraft.aircraft_data_id === xValue);

        let tempOwnerOperator = [];

        // !!! AQUI TEM MUDAR DE MARK PARA AIRCRAFT_MARK
        this.formFlightData.controls.flight_request.controls.aircraft_data.controls.aircraft_mark.setValue(this.objCurrentAircraft.registration_data.mark);

        tempOwnerOperator = this.objCurrentAircraft?.owner_operator_data.map((xObjOwnerOperator: any) => {
          if (xObjOwnerOperator.type_owner_operator === "operator" || xObjOwnerOperator.type_owner_operator === "both") {
            return {
              label: xObjOwnerOperator.name,
              value: xObjOwnerOperator.owner_operator_id[0]
            }
          } else {
            return null
          }
        });

        // OPERATOR
        this.optionsOperatorsByAircraft = tempOwnerOperator.filter((xObjOwnerOperator: any) => xObjOwnerOperator !== null);

        if (this.optionsOperatorsByAircraft.length == 1) {
          //this.formFlightData.controls.flight_request.controls.aircraft_data.controls.operator.setValue(this.optionsOperatorsByAircraft[0].value);
        }

        // WEIGHT BALANCE SHEET
        this.allWeightBalanceSheetListDb = null;
        this._getWeightBalanceSheetListByAircraft(xValue);
      } else {
        this.optionsOperatorsByAircraft = [];
        this.allWeightBalanceSheetListDb = null;
      }
    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private async _initVariables(): Promise<void> {
    this.bolShowWaitingDialog = false;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.DEFAULT;
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de voo.
   */
  private _initFlightDataForm() {
    this.formFlightData = initFlightDataForm();

    this.formFlightData.controls.flight_request.controls.crew_data.controls.push(initFlightCrewForm(FLIGHT_CREW_ROLE.PILOT_IN_COMMAND));
    this.formFlightData.controls.flight_request.controls.crew_data.controls.push(initFlightCrewForm(FLIGHT_CREW_ROLE.CO_PILOT));

    for (let i = 0; i < domainBasicAerodromeTypes.length; i++) {
      this.formFlightData.controls.flight_request.controls.aerodrome_list.controls.push(
        initAerodromeBasicDataForm(domainBasicAerodromeTypes[i])
      );
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Verifica se o formulário está em modo de edição.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      if (xParams["id"]) {
        this.objPageTitle = {
          title: 'Editar Voo',
          subtitle: 'Alterar os Dados de um Voo.',
          icon: 'fa-solid fa-edit'
        }

        //this._getFlightDataById(xParams["id"]);
      } else {
        this.objPageTitle = {
          title: 'Novo do Voo',
          subtitle: 'Inclua os Dados de um Novo Voo.',
          icon: 'fa-solid fa-plus'
        }
      }
    })
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Salva a Requisição de Voo.
   */
  private async _saveFlightRequest() {
    if (this.formFlightData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formFlightData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Requisição de Voo',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      await this._moduleFlightService.saveFlightData(this.formFlightData).subscribe({
        next: (xApiResponse) => {
          console.log("0000000000000", xApiResponse)

          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {

            ////this.formAircraftData.controls.aircraft_data_id.setValue(xApiResponse.data[0].data[0].id);

            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Requisição de Voo',
              detail: `${xApiResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Requisição de Voo',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }

  private async _newFlightData() {
    //this.private_clearForm();
    this._router.navigate(['/app/flight/form']);
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Obtém todas as aeronaves do usuário.
   */
  private async _getAllAircraftDb(): Promise<void> {
    await this._moduleAircraftApisService.getAllAircraftDataList().subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.allAircraftListDb = xApiResponse;

          this._generateOptionsAllAircraft();

          /*setTimeout(() => {
            this._messageService.add({
              severity: 'success',
              summary: 'Lista de Aeronaves',
              detail: 'Lista de de Aeronaves carregada com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }, settingConfig.WAITING_DIALOG_LIFE);*/
        } else {
          this.bolShowWaitingDialog = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Lista de Aeronaves',
            detail: 'Não foi possível carregar a lista de Aeronaves.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowWaitingDialog = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Listar Dados das Aeronaves',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Obtém todos os pilotos do usuário.
   */
  private _getAllPilotsDb(): void {
    this.allPilotsDb = [];
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Gera as opções de todas as aeronaves para por DROPDOWN.
   * 
   */
  private _generateOptionsAllAircraft(): void {
    this.optionsAllAircraft = this.allAircraftListDb ? this.allAircraftListDb.map((xObjAircraft: any) => {
      return {
        label: xObjAircraft.registration_data.mark,
        value: xObjAircraft.aircraft_data_id,
        icon: configAircraft.find((xTempObjConfigAircraft: any) => xTempObjConfigAircraft.value === xObjAircraft.registration_data.aircraft_type)?.icon
      }
    }) : [];
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Obtém a lista de Fichas de Peso e Balanceamento por Aeronave.
   * 
   * @param xAircraftId 
   */
  private async _getWeightBalanceSheetListByAircraft(xAircraftId: string): Promise<void> {
    await this._moduleWeightBalanceService.getWeightBalanceSheetListByAircraft(xAircraftId).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {          
          // Esta Lista será passada para o componente de Fichas de Peso e Balanceamento.
          this.allWeightBalanceSheetListDb = null;
          this.allWeightBalanceSheetListDb = xApiResponse.data[0].data;         

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          /*setTimeout(() => {
            this._messageService.add({
              severity: 'success',
              summary: 'Lista de Aeronaves',
              detail: 'Lista de de Aeronaves carregada com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }, settingConfig.WAITING_DIALOG_LIFE);*/
        } else {
          this.bolShowWaitingDialog = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Lista Fichas de Peso e Balanceamento da Aeronaves',
            detail: 'Não foi possível carregar as Fichas de Peso e Balanceamento da Aeronave.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowWaitingDialog = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Lista Fichas de Peso e Balanceamento da Aeronaves',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  private _initClock(): void {
    // Tente sincronizar com um servidor de horário de precisão
    this._syncWithPrecisionClock().then((time) => {
      this._startClock(time);
      this.originUtcTime = "web";
    }).catch(() => {
      // Se a sincronização falhar, use o horário do computador local
      this._startClock(new Date());
      this.originUtcTime = "local";
    });
  }

  private async _syncWithPrecisionClock(): Promise<Date> {
    // Tente obter o horário do World Time API
    const response = await fetch('http://worldtimeapi.org/api/timezone/Etc/UTC');
    if (!response.ok) {
      throw new Error('Failed to fetch time from World Time API');
    }
    const data = await response.json();
    const preciseTime = new Date(data.utc_datetime);
    return preciseTime;
  }

  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description Inicia o relógio com o horário atual UTC.
   * 
   * @param initialTime 
   */
  private _startClock(initialTime: Date): void {
    let currentTime = initialTime;

    // Atualize o horário a cada segundo
    this._clockInterval = setInterval(() => {
      currentTime = new Date(currentTime.getTime() + 1000);
      this.currentUtcDateTime = `${this._formatDateTime(currentTime)}###${this.originUtcTime}`;
    }, 1000);
  }

  private _formatDateTime(date: Date): string {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} UTC`;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-02
   * @version 1.0.0
   * 
   * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
   * 
   * @param xAction 
   */
  handlerActionTabFlightRequest(xAction: string): void {
    if (xAction === BUTTON_ACTION.NEW) {
      console.log('NOVA REQUISIÇÃO DE VOO');
    } else if (xAction === BUTTON_ACTION.SAVE) {
      console.log('SALVAR REQUISIÇÃO DE VOO');
      this._saveFlightRequest();
    } else if (xAction === BUTTON_ACTION.UPDATE) {
      console.log('UPDATE REQUISIÇÃO DE VOO');
    } else if (xAction === BUTTON_ACTION.EXIT) {
      console.log('SAIR REQUISIÇÃO DE VOO');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowWaitingDialog = xShowDialog.show_dialog;
    this.strTypeWaitingDialog = xShowDialog.type_waiting;
  }
  //#endregion
}

/*
state: new FormControl<string>(''),
              country: new FormControl<string>(''),
              timezone: new FormControl<string>(''),
              is_international: new FormControl<boolean>(false),
              is_customs: new FormControl<boolean>(false),
              is_military: new FormControl<boolean>(false),
              is_heliport: new FormControl<boolean>(false),
              is_seaport: new FormControl<boolean>(false),
              is_spaceport: new FormControl<boolean>(false),
              is_airport: new FormControl<boolean>(false),
              is_aerodrome: new FormControl<boolean>(false),
              is_base: new FormControl<boolean>(false),
              is_terminal: new FormControl<boolean>(false),
              is_station: new FormControl<boolean>(false),
              is_hangar: new FormControl<boolean>(false),
              is_landing_strip: new FormControl<boolean>(false),
              is_runway: new FormControl<boolean>(false),
              is_taxiway: new FormControl<boolean>(false),
              is_apron: new FormControl<boolean>(false),
              is_terminal_gate: new FormControl<boolean>(false),
              is_terminal_building: new FormControl<boolean>(false),
              is_control_tower: new FormControl<boolean>(false),
              is_fire_station: new FormControl<boolean>(false),
              is_rescue_station: new FormControl<boolean>(false),
              is_medical_station: new FormControl<boolean>(false),
              is_fuel_station: new FormControl<boolean>(false),
              is_maintenance_station: new FormControl<boolean>(false),
              is_cargo_station: new FormControl<boolean>(false),
              is_passenger_station: new FormControl<boolean>(false),
              is_vip_station: new FormControl<boolean>(false),
              is_catering_station: new FormControl<boolean>(false),
              is_crew_station: new FormControl<boolean>(false),
              is_customs_station: new FormControl<boolean>(false),
              */