
//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormBuilder, AbstractControl, ReactiveFormsModule } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** CLASS ***** //
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from "../../../../../../../../_library/layouts/components/primeng/primeng-components.module";

// ***** FORM ***** //
import { initWeightBalanceFilledForm } from '../../../../../form-init/weight-balance-filled-form';

// ***** SERVICES ***** //
import { ModuleWeightBalanceService } from '../../../../../../../_services/module-weight-balance/module-weight-balance.service';

// ***** COMPONENT ***** //
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
import { ChartCGLimitsComponent } from '../../../../../../../_components/chart/chart-cg-limits/chart-cg-limits.component';

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsFuelType } from '../../../../../../../../_library/static_options/staticOptionsFuelType';
import { staticOptionsFuelRateUnits } from '../../../../../../../../_library/static_options/staticOptionsFuelRateUnits';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
//import { IPageTitle } from '../../../../../../../../_library/interfaces/IPageTitle';
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
//import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IChartCGLimitations } from '../../../../../../../../_library/interfaces/IChartCGLimitations';
import { ICgComponents } from '../../../../../../../../_library/interfaces/ICgComponents';
//import { IPlotagemChart } from '../../../../../../../../_library/interfaces/IPlotagemChart';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../../../../_library/definitions/ButtonAction';
import { CG_COMPONENT_TYPE } from '../../../../../../../../_library/definitions/CgComponentType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-weight-balance-pax',
  standalone: true,
  imports: [
    ChartCGLimitsComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengComponentsModule,
    TitleFormTopicComponent
  ],
  templateUrl: './tab-weight-balance-pax.component.html'
})
export class TabWeightBalancePaxComponent implements OnInit, OnChanges {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() arrWeightBalanceSheet!: any
  @Input() objAircraft!: any
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabWeightBalancePax = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  public buttonActions!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  cgComponentType!: any;

  // ***** ARRAY ***** //  
  arrayFixedTableCalculateCg!: any[];
  arrayVariableTableCalculateCg!: any[];

  // ***** DIALOG ***** //
  public setupShowWaitingDialog!: IWaitingDialog;
  public setupHiddenWaitingDialog!: IWaitingDialog;

  // ***** OPTIONS ***** //
  optionsWeightBalanceSheet!: IDropDownOptionsBasic[];
  optionsFuelType!: IDropDownOptionsBasic[];
  optionsFuelConsumption!: IDropDownOptionsBasic[];

  // ***** OBJ CHART ***** //
  objChartCGLongitudinal!: IChartCGLimitations;
  objChartCGLateral!: IChartCGLimitations;

  // ***** FORMS ***** //
  public formWeightBalanceFilled: any
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initWeightBalanceFellOutForm();
    this._initFormWeightBalanceSheetEvents();
  }

  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['arrWeightBalanceSheet']) {
      if (this.arrWeightBalanceSheet && this.arrWeightBalanceSheet !== null && this.arrWeightBalanceSheet.length > 0) {
        this.optionsWeightBalanceSheet = this.arrWeightBalanceSheet.map((xItem: any) => { return { label: xItem.header_data.name, value: xItem.weight_balance_data_id } });
      } else {
        this.optionsWeightBalanceSheet = [];
      }
    }
  }

  //region "|--- EVENT HANDLERS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Inicializa os eventos do formulário de Peso e Balanceamento.
   */
  private _initFormWeightBalanceSheetEvents() {
    this.formWeightBalanceFilled.get('fk_weight_balance_data_id').valueChanges.subscribe((xValue: ICgComponents[]) => {
      this._getWeightBalanceSheetById(xValue);
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa os eventos dos controles de dados de entrada.
   */
  private _initInputDataControlsEvents() {
    this.formWeightBalanceFilled.controls.components_input_data.controls.forEach((xComponent: AbstractControl, xIndex: number) => {
      xComponent.get('weight')?.valueChanges.subscribe((xValue: any) => {
        const tempWeight = xValue;
        const tempLongitudinalArm = this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.longitudinal_arm.value;
        const tempLateralArm = this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.lateral_arm.value;

        this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
        this.formWeightBalanceFilled.controls.components_input_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });

        this._processCgCalculation();
      });
    })
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa os eventos dos controles de dados constantes.
   */
  private _initConstantDataControlsEvents() {
    this.formWeightBalanceFilled.controls.components_constant_data.controls.forEach((xComponent: AbstractControl, xIndex: number) => {
      xComponent.get('weight')?.valueChanges.subscribe((xValue: any) => {
        const tempWeight = xValue;
        const tempLongitudinalArm = this.formWeightBalanceFilled.controls.components_constant_data.controls[xIndex].controls.longitudinal_arm.value;
        const tempLateralArm = this.formWeightBalanceFilled.controls.components_constant_data.controls[xIndex].controls.lateral_arm.value;

        this.formWeightBalanceFilled.controls.components_constant_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
        this.formWeightBalanceFilled.controls.components_constant_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });

        this._processCgCalculation();
      });
    })
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa os eventos dos controles de dados de combustível.
   */
  private _initFuelDataControlsEvents() {
    this.formWeightBalanceFilled.controls.components_fuel_data.controls.forEach((xComponent: AbstractControl, xIndex: number) => {
      xComponent.get('weight')?.valueChanges.subscribe((xValue: any) => {
        const tempWeight = xValue;
        const tempLongitudinalArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.longitudinal_arm.value;
        const tempLateralArm = this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.lateral_arm.value;

        this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
        this.formWeightBalanceFilled.controls.components_fuel_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });

        this._processCgCalculation();
      });
    })
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   *  @description Inicializa os eventos dos controles de dados externos.
   */
  private _initExternalDataControlsEvents() {
    this.formWeightBalanceFilled.controls.components_external_data.controls.forEach((xComponent: AbstractControl, xIndex: number) => {
      xComponent.get('weight')?.valueChanges.subscribe((xValue: any) => {
        const tempWeight = xValue;
        const tempLongitudinalArm = this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.longitudinal_arm.value;
        const tempLateralArm = this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.lateral_arm.value;

        this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
        this.formWeightBalanceFilled.controls.components_external_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });

        this._processCgCalculation();
      });
    })
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Peso e Balanceamento.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;
    this.cgComponentType = CG_COMPONENT_TYPE;

    this.optionsFuelType = staticOptionsFuelType;
    this.optionsFuelConsumption = staticOptionsFuelRateUnits;

    this.setupShowWaitingDialog = {
      show_dialog: true,
      type_waiting: DIALOG_WAITING_TYPE.FLIGHT_WEIGHT_BALANCE_LIST
    };

    this.setupHiddenWaitingDialog = {
      show_dialog: false,
      type_waiting: DIALOG_WAITING_TYPE.FLIGHT_WEIGHT_BALANCE_LIST
    };

    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
  }

  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Carrega o formulário de Peso e Balanceamento que será preenchido.
   */
  private _initWeightBalanceFellOutForm(): void {
    this.formWeightBalanceFilled = initWeightBalanceFilledForm();
  }

  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Recupera a Folha de Peso e Balanceamento pelo ID.
   * 
   * @param xIdRegister 
   */
  private async _getWeightBalanceSheetById(xIdRegister: any): Promise<void> {
    this.actionShowWaitingDialog.emit(this.setupShowWaitingDialog);

    await this._moduleWeightBalanceService.getWeightBalanceSheetById(xIdRegister).subscribe({
      next: (xResponse) => {
        if (xResponse.status_code == HTTP_STATUS.OK) {
          const objFormBuilder: FormBuilder = new FormBuilder();

          // ----- >>> PREENCHER OS DADOS AVULSOS <<< ----- //
          this.formWeightBalanceFilled.controls.fk_weight_balance_data_id.setValue(xResponse.data[0].data[0].fk_weight_balance_data_id, { emitEvent: false });
          this.formWeightBalanceFilled.controls.path_perfil_image.setValue(xResponse.data[0].data[0].path_perfil_image, { emitEvent: false });
          this.formWeightBalanceFilled.controls.filled_to.controls.fk_aircraft_data_id.setValue(this.formComponent.controls.flight_request.controls.aircraft_data.controls.fk_aircraft_data_id.value, { emitEvent: false });
          this.formWeightBalanceFilled.controls.filled_to.controls.aircraft_mark.setValue(this.formComponent.controls.flight_request.controls.aircraft_data.controls.aircraft_mark.value, { emitEvent: false });

          // ----- >>> PREENCHER OS DADOS DO CABEÇALHO <<< ----- //
          this.formWeightBalanceFilled.controls.header_data.patchValue(xResponse.data[0].data[0].header_data, { emitEvent: false });

          // ----- >>> PREENCHER OS DAS UNIDADES <<< ----- //
          this.formWeightBalanceFilled.controls.unit_data.patchValue(xResponse.data[0].data[0].unit_data, { emitEvent: false });

          // ----- >>> RECUPERA OS DADOS INPUT <<< ----- //
          if (xResponse.data[0].data[0].components_input_data && xResponse.data[0].data[0].components_input_data.length > 0) {
            xResponse.data[0].data[0].components_input_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_input_data.controls.push(objFormBuilder.group(xData));
            });

            this._initInputDataControlsEvents();
          }

          // ----- >>> RECUPERA OS DADOS CONSTANT <<< ----- //
          if (xResponse.data[0].data[0].components_constant_data && xResponse.data[0].data[0].components_constant_data.length > 0) {
            xResponse.data[0].data[0].components_constant_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_constant_data.controls.push(objFormBuilder.group(xData));
            });

            this._initConstantDataControlsEvents();
          }

          // ----- >>> RECUPERA OS DADOS FUEL <<< ----- //
          if (xResponse.data[0].data[0].components_fuel_data && xResponse.data[0].data[0].components_fuel_data.length > 0) {
            xResponse.data[0].data[0].components_fuel_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_fuel_data.controls.push(objFormBuilder.group(xData));
            });

            this._initFuelDataControlsEvents();
          }

          // ----- >>> RECUPERA OS DADOS EXTERNAL <<< ----- //
          if (xResponse.data[0].data[0].components_external_data && xResponse.data[0].data[0].components_external_data.length > 0) {
            xResponse.data[0].data[0].components_external_data.forEach((xData: any) => {
              this.formWeightBalanceFilled.controls.components_external_data.controls.push(objFormBuilder.group(xData));
            });

            this._initExternalDataControlsEvents();
          }

          // ----- >>> PREENCHER A TABELA MATEMÁTICA DE CALCULO DO CG <<< ----- //
          this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value.push(
            {
              calculate_id: null,
              order_show: 1,
              label: "Basic Empty Mass (BEM)",
              weight: xResponse.data[0].data[0].weight_data.bem.weight,
              longitudinal_arm: xResponse.data[0].data[0].weight_data.bem.longitudinal_arm,
              longitudinal_moment: (xResponse.data[0].data[0].weight_data.bem.weight * xResponse.data[0].data[0].weight_data.bem.longitudinal_arm),
              lateral_arm: xResponse.data[0].data[0].weight_data.bem.lateral_arm,
              lateral_moment: (xResponse.data[0].data[0].weight_data.bem.weight * xResponse.data[0].data[0].weight_data.bem.lateral_arm),
              weight_unit: xResponse.data[0].data[0].unit_data.weight_unit,
              arm_unit: xResponse.data[0].data[0].unit_data.arm_unit
            },
            {
              calculate_id: null,
              order_show: 2,
              label: "Zero Few Weight (ZFW)",
              weight: xResponse.data[0].data[0].weight_data.bem.weight,
              longitudinal_arm: xResponse.data[0].data[0].weight_data.bem.longitudinal_arm,
              longitudinal_moment: (xResponse.data[0].data[0].weight_data.bem.weight * xResponse.data[0].data[0].weight_data.bem.longitudinal_arm),
              lateral_arm: xResponse.data[0].data[0].weight_data.bem.lateral_arm,
              lateral_moment: (xResponse.data[0].data[0].weight_data.bem.weight * xResponse.data[0].data[0].weight_data.bem.lateral_arm),
              weight_unit: xResponse.data[0].data[0].unit_data.weight_unit,
              arm_unit: xResponse.data[0].data[0].unit_data.arm_unit
            },
            {
              calculate_id: null,
              order_show: 3,
              label: "Total Fuel",
              weight: 0,
              longitudinal_arm: 0,
              longitudinal_moment: 0,
              lateral_arm: 0,
              lateral_moment: 0,
              weight_unit: 0,
              arm_unit: 0
            },
            {
              calculate_id: null,
              order_show: 4,
              label: "Ramp Weight (RW)",
              weight: 0,
              longitudinal_arm: 0,
              longitudinal_moment: 0,
              lateral_arm: 0,
              lateral_moment: 0,
              weight_unit: 0,
              arm_unit: 0
            },
            {
              calculate_id: null,
              order_show: 5,
              label: "Taxi Fuel",
              weight: 0,
              longitudinal_arm: 0,
              longitudinal_moment: 0,
              lateral_arm: 0,
              lateral_moment: 0,
              weight_unit: 0,
              arm_unit: 0
            },
            {
              calculate_id: null,
              order_show: 6,
              label: "Take Off Mass (TOM)",
              weight: 0,
              longitudinal_arm: 0,
              longitudinal_moment: 0,
              lateral_arm: 0,
              lateral_moment: 0,
              weight_unit: 0,
              arm_unit: 0
            },
            {
              calculate_id: null,
              order_show: 7,
              label: "Flight Fuel",
              weight: 0,
              longitudinal_arm: 0,
              longitudinal_moment: 0,
              lateral_arm: 0,
              lateral_moment: 0,
              weight_unit: 0,
              arm_unit: 0
            },
            {
              calculate_id: null,
              order_show: 8,
              label: "Landing Weight (LDW)",
              weight: 0,
              longitudinal_arm: 0,
              longitudinal_moment: 0,
              lateral_arm: 0,
              lateral_moment: 0,
              weight_unit: 0,
              arm_unit: 0
            }
          )

          // ----- >>> ATRIBUI OS VALORES QUE FORMAM O GRÁFICO DO C.G. LONGITUDINAL << ----- //
          if (xResponse.data[0].data[0].chart_longitudinal_data && xResponse.data[0].data[0].chart_longitudinal_data.length > 0) {
            xResponse.data[0].data[0].chart_longitudinal_data.forEach((xData: any, xIndex: number) => {
              this.formWeightBalanceFilled.controls.chart_longitudinal_data.push(objFormBuilder.group(xData));

              // Tem um problema o comando acima, ele transforma o array limits_data em um objeto, então tem que forçar o patchValue e sobrepor o objeto pelo array.            
              this.formWeightBalanceFilled.controls.chart_longitudinal_data.controls[xIndex].controls.limits_data.patchValue(xData.limits_data);
            });
          }

          // ----- >>> ATRIBUI OS VALORES QUE FORMAM O GRÁFICO DO C.G. LATERAL << ----- //
          if (xResponse.data[0].data[0].chart_lateral_data && xResponse.data[0].data[0].chart_lateral_data.length > 0) {
            xResponse.data[0].data[0].chart_lateral_data.forEach((xData: any, xIndex: number) => {
              this.formWeightBalanceFilled.controls.chart_lateral_data.push(objFormBuilder.group(xData));

              // Tem um problema o comando acima, ele transforma o array limits_data em um objeto, então tem que forçar o patchValue e sobrepor o objeto pelo array.            
              this.formWeightBalanceFilled.controls.chart_lateral_data.controls[xIndex].controls.limits_data.patchValue(xData.limits_data);
            });
          }

          // ***** CRIAR A MATRIX DE ELEMENTOS ***** //
          this.formWeightBalanceFilled.controls.matrixOfElements.value = [];

          // Criar uma matriz com todos os elementos false, mas que tenha a mesma quantidade de elementos do formulário.          
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(xResponse.data[0].data[0].components_input_data.map(() => false));
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(xResponse.data[0].data[0].components_external_data.map(() => false));
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(xResponse.data[0].data[0].components_constant_data.map(() => false));
          this.formWeightBalanceFilled.controls.matrixOfElements.value.push(xResponse.data[0].data[0].components_fuel_data.map(() => false));
        }

        this.actionShowWaitingDialog.emit(this.setupHiddenWaitingDialog);
      },
      error: (xError) => {
        this.actionShowWaitingDialog.emit(this.setupHiddenWaitingDialog);

        this._messageService.add({
          severity: 'error',
          summary: 'Recuperar a Folha de Peso e Balanceamento',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  private _processCgCalculation(): void {
    // PARA INICIAR O CALCULO TEM QUE REINICIAR OS VALORES DA TABELA:
    // ZERO FEW WEIGHT (ZFW) => IGUALAR AO BASIC EMPTY MASS (BEM)
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].weight;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_arm = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_arm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_moment;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_arm = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_arm;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_moment;

    // ZERO TOTAL FUEL 
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight = 0;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_arm = 0;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_moment = 0;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_arm = 0;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_moment = 0;

    // Linha 0 da matrixOfElements =>  components_input_data;
    // Linha 1 da matrixOfElements =>  components_external_data;
    // Linha 2 da matrixOfElements =>  components_constant_data;
    // Linha 3 da matrixOfElements =>  components_fuel_data;    
    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_input_data
    for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[0].length; i++) {
      if (this.formWeightBalanceFilled.controls.matrixOfElements.value[0][i]) {
        // CALCULA O ZERO FUEL WEIGHT (ZFW)
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight += this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.weight.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment += this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.longitudinal_moment.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment += this.formWeightBalanceFilled.controls.components_input_data.controls[i].controls.lateral_moment.value;
      }
    }

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_external_data
    for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[1].length; i++) {
      if (this.formWeightBalanceFilled.controls.matrixOfElements.value[1][i]) {

        // CALCULA O ZERO FUEL WEIGHT (ZFW)
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight += this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.weight.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment += this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.longitudinal_moment.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment += this.formWeightBalanceFilled.controls.components_external_data.controls[i].controls.lateral_moment.value;
      }
    }

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_fuel_data
    for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[3].length; i++) {
      if (this.formWeightBalanceFilled.controls.matrixOfElements.value[3][i]) {

        // PRECISA CONVERTER A UNIDADE DE MEDIDA DO COMBUSTÍVEL PARA O DE PESO.        
        const tempWeightOfficialUnit = this.converToOthersFuelUnit(this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value)

        // CALCULA O TOTAL FUEL WEIGHT  
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight += tempWeightOfficialUnit;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_moment += this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.longitudinal_moment.value;
        this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_moment += this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.lateral_moment.value;
      }
    }

    // CALCULAR RAMP WEIGHT (RW) - PESO E MOMENTO LONGITUDINAL E LATERAL
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].weight = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight +
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].longitudinal_moment = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment +
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_moment;
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[3].lateral_moment = this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment +
      this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_moment;


    // CALCULAR O BRAÇO LONGITUDINAL DO ZERO FUEL WEIGHT (ZFW)
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_arm = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].longitudinal_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight).toFixed(2));

    // CALCULAR O BRAÇO LATERAL DO ZERO FUEL WEIGHT (ZFW)
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_arm = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].lateral_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[1].weight).toFixed(2));

    // CALCULAR O BRAÇO LONGITUDINAL DO TOTAL FUEL
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_arm = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].longitudinal_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight).toFixed(2));

    // CALCULAR O BRAÇO LATERAL DO TOTAL FUEL
    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_arm = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].lateral_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[2].weight).toFixed(2));

    // DADOS PARA O CALCULO DE COMBUSTÍVEL COMUM
    const tempCurrentWeightUnit = this.formWeightBalanceFilled.controls.unit_data.controls.weight.value;
    const tempFuelType = this.formWeightBalanceFilled.controls.flight_data.controls.fuel_type.value;

    // CALCULO DO CONSUMO DE COMBUSTÍVEL EM TEMPO DE TAXI
    const tempTaxiFuelRate = this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate.value;
    const tempTaxiFuelRateUnit = this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate_unit.value.replaceAll("_", "/");    
    const timeTaxi = this.classLocalMethods.convertTimeToMinutes(this.formWeightBalanceFilled.controls.flight_data.controls.taxi_eet.value);
    const tempTaxiFuelConsumed = this.classLocalMethods.calculateFuelConsumption(timeTaxi, tempTaxiFuelRate, tempTaxiFuelRateUnit, tempCurrentWeightUnit, tempFuelType);

    console.log("AAAAAAAAAAAAA", tempTaxiFuelConsumed);
    
    // CALCULO DO CONSUMO DE COMBUSTÍVEL EM TEMPO DE VOO
    const tempFlightFuelRate = this.formWeightBalanceFilled.controls.flight_data.controls.flight_fuel_rate.value;
    const tempFlightFuelRateUnit = this.formWeightBalanceFilled.controls.flight_data.controls.flight_fuel_rate_unit.value.replaceAll("_", "/");    
    const timeFlight = this.classLocalMethods.convertTimeToMinutes(this.formWeightBalanceFilled.controls.flight_data.controls.flight_eet.value);
    const tempFlightFuelConsumed = this.classLocalMethods.calculateFuelConsumption(timeFlight, tempFlightFuelRate, tempFlightFuelRateUnit, tempCurrentWeightUnit, tempFuelType);

    console.log("BBBBBBBBBBBB", tempFlightFuelConsumed)
    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_constant_data
    //for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[2].length; i++) {
    //  if (this.formWeightBalanceFilled.controls.matrixOfElements.value[2][i]) {
    //    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_moment += this.formWeightBalanceFilled.controls.components_constant_data.controls[i].controls.longitudinal_moment.value;
    //    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_moment += this.formWeightBalanceFilled.controls.components_constant_data.controls[i].controls.lateral_moment.value;
    //  }
    //}

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_fuel_data
    //for (let i = 0; i < this.formWeightBalanceFilled.controls.matrixOfElements.value[3].length; i++) {
    //  if (this.formWeightBalanceFilled.controls.matrixOfElements.value[3][i]) {
    //    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_moment += this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.longitudinal_moment.value;
    //    this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_moment += this.formWeightBalanceFilled.controls.components_fuel_data.controls[i].controls.lateral_moment.value;
    //  }
    //}

    // CALCULAR O PESO TOTAL
    //this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].weight = this.formWeightBalanceFilled.controls.components_input_data.controls.reduce((total: number, xComponent: any) => total + xComponent.controls.weight.value, 0) +
    //  this.formWeightBalanceFilled.controls.components_external_data.controls.reduce((total: number, xComponent: any) => total + xComponent.controls.weight.value, 0) +
    //  this.formWeightBalanceFilled.controls.components_constant_data.controls.reduce((total: number, xComponent: any) => total + xComponent.controls.weight.value, 0) +
    //  this.formWeightBalanceFilled.controls.components_fuel_data.controls.reduce((total: number, xComponent: any) => total + xComponent.controls.weight.value, 0);

    // CALCULAR O MOMENTO TOTAL
    //this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_moment = parseFloat(this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_moment.toFixed(2));
    //this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_moment = parseFloat(this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_moment.toFixed(2));

    // CALCULAR O C.G. LONGITUDINAL
    //this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_cg = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].weight).toFixed(2));

    // CALCULAR O C.G. LATERAL
    //this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_cg = parseFloat((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_moment / this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].weight).toFixed(2));

    // CALCULAR O C.G. LONGITUDINAL E LATERAL
    //this.formWeightBalanceFilled.controls.calculation_cg.controls.result = parseFloat(((this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].longitudinal_cg + this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value[0].lateral_cg) / 2).toFixed(2);

    // CALCULAR O REMARK
    //this.formWeightBalanceFilled.controls.calculation_cg.controls.remark = this._getRemark(this.formWeightBalanceFilled.controls.calculation_cg.controls.result);

  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Método que manipula a ação do botão.
   * 
   * @param xButtonAction 
   */
  callbackClickButton(xButtonAction: string): void {
    this.actionTabWeightBalancePax.emit(xButtonAction);
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que manipular mudanças de estado do componente Switch.
   * 
   * @param xEvent 
   */
  callbackOnChangeSwitch(xEvent: any): void {
    this._processCgCalculation();
  }

  /**
   * @author GASPAR
   * @date 2024-10-11
   * @version 1.0.0
   * 
   * @description Método que retorna o valor a unidade oposta do Peso.
   * 
   * @param xWeightUnit 
   * @returns 
   */
  getOppositeWeightUnit(xCurrentWeightUnit: string): string {
    return xCurrentWeightUnit === 'kg' ? 'lb' : 'kg';
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que retorna o valor a unidade oposta do Braço.
   * 
   * @param xValue 
   * @param xWeightUnit 
   * @returns 
   */
  convertToOppositeWeightUnit(xValue: number, xWeightUnit: string): number {
    const tempConversion = this.classLocalMethods.convertWeight(xValue, xWeightUnit, this.getOppositeWeightUnit(xWeightUnit));

    return parseFloat((tempConversion * 1).toFixed(2));
  }

  /**
   * @author GASPAR
   * @date 2024-10-11
   * @version 1.0.0
   * 
   * @description Método que retorna o valor a unidade oposta do Braço.
   * 
   * @param xCurrentFuelUnit 
   * @param xIndexPosition 
   * @returns 
   */
  getOthersFuelUnit(xCurrentFuelUnit: string, xIndexPosition: number): string {
    const arrayFuelUnits = ['l', 'gal', 'kg', 'lb'];
    const arrayFuelOptions = arrayFuelUnits.filter((xItem: string) => xItem !== xCurrentFuelUnit);

    return arrayFuelOptions[xIndexPosition];
  }

  converToOthersFuelUnit(xValue: number, xCurrentUnit: string, xDesiredUnit: string) {
    return this.classLocalMethods.convertFuelGeneric(xValue, xCurrentUnit, xDesiredUnit)
  }

  generateAttribute(xIndex: any): string {
    return "#pnl" + xIndex;
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que define o valores padrões MÁXIMO do Peso.
   * 
   * @param xComponent 
   * @param xMainUnit 
   * @returns 
   */
  defineMaxWeight(xComponent: any, xMainUnit: string): number {
    let tempMaxValue = 0;

    if (xComponent.controls.max_weight && xComponent.controls.max_weight.value != null && xComponent.controls.max_weight.value > 0) {
      tempMaxValue = xComponent.controls.max_weight.value;
    } else {
      if (xMainUnit == "kg") {
        tempMaxValue = 150
      } else if (xMainUnit == "lb") {
        tempMaxValue = 330
      }
    }

    return tempMaxValue;//xComponent.max_weight ? xComponent.max_weight : this.convertToOppositeWeightUnit(xComponent.max_weight, xMainUnit);
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que define o valores padrões MÁXIMO do Combustível.
   * 
   * @param xComponent 
   * @param xMainUnit 
   * @returns 
   */
  defineMaxFuel(xComponent: any, xMainUnit: string): number {
    let tempMaxValue = 0;

    if (xComponent.controls.max_weight && xComponent.controls.max_weight.value != null && xComponent.controls.max_weight.value > 0) {
      tempMaxValue = xComponent.controls.max_weight.value;
    } else {
      if (xMainUnit == "kg") {
        tempMaxValue = 5000
      } else if (xMainUnit == "lb") {
        tempMaxValue = 11023
      } else if (xMainUnit == "l") {
        tempMaxValue = 5000
      } else if (xMainUnit == "gal") {
        tempMaxValue = 11023
      }
    }

    return tempMaxValue;//xComponent.max_weight ? xComponent.max_weight : this.convertToOppositeWeightUnit(xComponent.max_weight, xMainUnit);
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que define o valores padrões do Label do Peso.
   * 
   * @param xComponent 
   * @param xPercent 
   * @param xMaxValue 
   * @param xCurrentUnit 
   */
  setDefaultWeightLabel(xPercent: string, xMaxValue: number, xCurrentUnit: string): string {
    let tempValue;
    let tempOppositeValue;
    let tempMaxValue: number;
    let returnLabel = "";

    if (xMaxValue && xMaxValue != null && xMaxValue > 0) {
      tempMaxValue = xMaxValue;
    } else {
      if (xCurrentUnit == "kg") {
        tempMaxValue = 150;
      } else {
        tempMaxValue = 330;
      }
    }

    if (xPercent == "0") {
      returnLabel = "00Kg/ 000,00lb";
    } else if (xPercent == "33") {
      tempValue = parseFloat((tempMaxValue * 0.33).toFixed(2));
    } else if (xPercent == "66") {
      tempValue = parseFloat((tempMaxValue * 0.66).toFixed(2));
    } else if (xPercent == "100") {
      tempValue = parseFloat((tempMaxValue * 1).toFixed(2));
    }

    if (tempValue !== undefined) {
      if (xCurrentUnit == "kg") {
        tempOppositeValue = this.convertToOppositeWeightUnit(tempValue, "lb");

        returnLabel = `${tempValue}kg/ ${tempOppositeValue}lb`;
      } else {
        tempOppositeValue = this.convertToOppositeWeightUnit(tempValue, "kg");

        returnLabel = `${tempOppositeValue}lb/ ${tempValue}kg`;
      }
    }

    return returnLabel
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que define o valores padrões do PAX.
   * 
   * @param xComponent 
   * @param xValue 
   * @param xCurrentUnit 
   */
  setDefaultPaxValue(xComponent: any, xValue: string, xCurrentUnit: string): void {
    if (xValue == "heaviest") {
      if (xCurrentUnit == "kg") {
        xComponent.controls.weight.setValue(85);
      } else {
        xComponent.controls.weight.setValue(187.34);
      }
    } else if (xValue == "average") {
      if (xCurrentUnit == "kg") {
        xComponent.controls.weight.setValue(80);
      } else {
        xComponent.controls.weight.setValue(176.37);
      }
    } else if (xValue == "lightest") {
      if (xCurrentUnit == "kg") {
        xComponent.controls.weight.setValue(50);
      } else {
        xComponent.controls.weight.setValue(110.23);
      }
    } else if (xValue == "zero") {
      xComponent.controls.weight.setValue(0);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que define o valores padrões.
   * 
   * @param xComponent 
   * @param xValue 
   * @param xCurrentUnit 
   */
  setDefaultValue(xComponent: any, xValue: string, xCurrentUnit: string): void {
    if (xValue == "heaviest") {
      if (xCurrentUnit == "kg") {
        xComponent.controls.weight.setValue(85);
      } else {
        xComponent.controls.weight.setValue(187.34);
      }
    } else if (xValue == "average") {
      if (xCurrentUnit == "kg") {
        xComponent.controls.weight.setValue(80);
      } else {
        xComponent.controls.weight.setValue(176.37);
      }
    } else if (xValue == "lightest") {
      if (xCurrentUnit == "kg") {
        xComponent.controls.weight.setValue(50);
      } else {
        xComponent.controls.weight.setValue(110.23);
      }
    } else if (xValue == "zero") {
      xComponent.controls.weight.setValue(0);
    }
  }

  /**
  * @author GASPAR
  * @date 2024-10-13
  * @version 1.0.0
  * 
  * @description Método que define o valores padrões do Peso.
  * 
  * @param xComponent 
  * @param xPercent 
  * @param xMaxValue 
  * @param xCurrentUnit 
  */
  setDefaultWeightValue(xComponent: any, xPercent: string, xMaxValue: number, xCurrentUnit: string): void {
    let tempMaxValue: number;

    if (xMaxValue && xMaxValue != null && xMaxValue > 0) {
      tempMaxValue = xMaxValue;
    } else {
      if (xCurrentUnit == "kg") {
        tempMaxValue = 150;
      } else {
        tempMaxValue = 330;
      }
    }

    if (xPercent == "0") {
      xComponent.controls.weight.setValue(0);
    } else if (xPercent == "33") {
      xComponent.controls.weight.setValue(parseFloat((tempMaxValue * 0.33).toFixed(2)));
    } else if (xPercent == "66") {
      xComponent.controls.weight.setValue(parseFloat((tempMaxValue * 0.66).toFixed(2)));
    } else if (xPercent == "100") {
      xComponent.controls.weight.setValue(tempMaxValue);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @definition Método que define o valores padrões do Combustível.
   * 
   * @param xComponent 
   * @param xPercent 
   * @param xMaxValue 
   * @param xCurrentUnit 
   */
  setDefaultFuelValue(xComponent: any, xPercent: string, xMaxValue: number, xCurrentUnit: string): void {
    let tempMaxValue = 0;

    if (xMaxValue && xMaxValue != null && xMaxValue > 0) {
      tempMaxValue = xMaxValue;
    } else {
      if (xCurrentUnit == "kg") {
        tempMaxValue = 5000
      } else if (xCurrentUnit == "lb") {
        tempMaxValue = 11023
      } else if (xCurrentUnit == "l") {
        tempMaxValue = 5000
      } else if (xCurrentUnit == "gal") {
        tempMaxValue = 11023
      }
    }

    if (xPercent == "25") {
      xComponent.controls.weight.setValue(parseFloat((tempMaxValue * 0.25).toFixed(2)));
    } else if (xPercent == "50") {
      xComponent.controls.weight.setValue(parseFloat((tempMaxValue * 0.50).toFixed(2)));
    } else if (xPercent == "75") {
      xComponent.controls.weight.setValue(parseFloat((tempMaxValue * 0.75).toFixed(2)));
    } else if (xPercent == "100") {
      xComponent.controls.weight.setValue(tempMaxValue);
    }
  }
  //#endregion
}
