//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';
//#endregion

export function _handleError(xError: HttpErrorResponse, originalForm: any = null) {
    const tempErrorReturn = {
      http_error: 0,
      detail: "(D) Houve uma instabilidade no sistema. Por favor, tente novamente mais tarde e se o problema persistir, entre em contato com o suporte.",
    }

    if (xError.error instanceof ErrorEvent) {  // Erro no lado do cliente
      if (settingConfig.SHOW_LOG_EXCEPTION) {
        console.error('Houve um Erro do lado do Cliente:', xError);
      }

      tempErrorReturn.detail = "(C) Houve uma instabilidade no sistema. Por favor, tente novamente mais tarde e se o problema persistir, entre em contato com o suporte.";
    } else { // Erro no lado do servidor
      if (settingConfig.SHOW_LOG_EXCEPTION) {
        console.error(`Houve um erro do lado do Servidor HTTP: ${xError.status}, ` + `corpo: `, xError);
      }

      // Resposta Default do servidor para o usuário.
      tempErrorReturn.detail = "(S) Houve uma instabilidade no sistema. Por favor, tente novamente mais tarde e se o problema persistir, entre em contato com o suporte.";

      if (xError.status == HTTP_STATUS.BAD_REQUEST) {
        tempErrorReturn.http_error = HTTP_STATUS.BAD_REQUEST;
        
        if (xError.error != undefined && xError.error.status_code == HTTP_STATUS.BAD_REQUEST) { // NESTE CASO É RETORNO DE ERRO DA API.
          if (xError.error.data != undefined && xError.error.data.length > 0) {
            tempErrorReturn.detail = xError.error.data[0].message;

            if (originalForm != null) {
              for (let i = 0; i < xError.error.data[0].data.length; i++) {
                tempErrorReturn.detail += `\n - ${xError.error.data[0].data[i].message}`;
              }             

              // Faz a sinalização para que o campo que esteja errado, seja marcado.

              for (let j = 0; j < xError.error.data[0].data.length; j++) {
                const arrayControls = xError.error.data[0].data[j].parameter.split(".");
                const errorType: any = {};

                let tempControl = originalForm;

                errorType[`${xError.error.data[0].data[j].type}`] = true;

                arrayControls.forEach((xItemControl: any) => {
                  if (isNaN(xItemControl) == true) { // Verifica se o conteúdo é NÃO NUMÉRICO.
                    try {
                      tempControl = tempControl.controls[`${xItemControl}`];
                    } catch (error) {
                      // Se não encontrar o campo, não faz nada.
                    }                    
                  }
                });              

                setTimeout(() => {
                  tempControl.setErrors(errorType);
                  tempControl.markAsTouched();
                  tempControl.markAsDirty();
                }, 1000)
              }
            }
          } else {
            tempErrorReturn.detail = xError.error.message;
          }
        } else { // NESTE CASO É RETORNO DE ERRO DO SERVIDOR, OU SEJA, O ERRO NÃO FOI TRATADO NA API. 
          tempErrorReturn.detail = xError.message;
        }
      } else if (xError.status == HTTP_STATUS.NOT_FOUND) {
        tempErrorReturn.http_error = HTTP_STATUS.NOT_FOUND;

        if (xError.error != undefined && xError.error.status_code == HTTP_STATUS.NOT_FOUND) { // NESTE CASO É RETORNO DE ERRO DA API.
          if (xError.error.data != undefined && xError.error.data.length > 0) {
            tempErrorReturn.detail = xError.error.data[0].message;
          } else {
            tempErrorReturn.detail = xError.error.message;
          }

        } else { // NESTE CASO É RETORNO DE ERRO DO SERVIDOR, OU SEJA, O ERRO NÃO FOI TRATADO NA API. 
          tempErrorReturn.detail = xError.message;
        }
      } else if (xError.status == HTTP_STATUS.UNAUTHORIZED || xError.status == HTTP_STATUS.FORBIDDEN) {
        tempErrorReturn.http_error = xError.status;
        tempErrorReturn.detail = "Você não tem permissão para acessar este recurso.";
      } else if (xError.status != 0) {
        tempErrorReturn.detail = xError.error.message;
      }
    }

    return throwError(() => (tempErrorReturn));
  }