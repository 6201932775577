//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initFormFkCrewData(xFlightRole: string): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        fk_crew_data_id: [null],
        crew_role: [null],
        flt_role: [xFlightRole],
        prof_doc_type: [null],
        prof_doc_in: [null],
        full_name: [null]     
    });
}