/// <reference types="@types/google.maps" />

// filepath: /c:/CANTILEVER.COM.BR/02-PROJETOS/101-SUNRISE/frontend/sunrise_ftd_web-portal-angular/src/app/app-platform/_components/layout/google-maps/google-maps.component.ts
//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-google-maps-preview',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './google-maps-preview.component.html',
})
export class GoogleMapsPreviewComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;

  //#region "|--- INPUTS ---|"
  @Input() markersToPlot!: any
  @Input() drawsToPlot!: any
  //#endregion  

  //#region "|--- OUTPUTS ---|"

  //#endregion

  //#region "|--- PROPERTIES---|"
  private _map!: google.maps.Map | undefined;
  private _markers: google.maps.Marker[] = [];
  private _lines: google.maps.Polyline[] = [];
  private _labels: google.maps.OverlayView[] = [];
  //#endregion

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this._loadMap();

    if (this.markersToPlot) {
      this._plotMarkers();
    }
  }

  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['markersToPlot'] && xChanges['markersToPlot'].currentValue) {
      if (this.markersToPlot) {
        this._plotMarkers();
      }
    }

    if (xChanges['drawsToPlot'] && xChanges['drawsToPlot'].currentValue) {
      if (this.drawsToPlot) {

      }
    }
  }

  //#region "|--- PRIVATE METHODS ---|"  
  private _loadMap(): void {
    const mapProperties = {
      center: new google.maps.LatLng(-15.7801, -47.9292), // Coordenadas para centralizar na América do Sul
      zoom: 4, // Ajuste o zoom conforme necessário
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this._map = new google.maps.Map(this.mapContainer.nativeElement, mapProperties);
    this._addListeners()
  }

  private _addListeners(): void {
    if (!this._map) return;

    // Adiciona um listener para ajustar o tamanho dos ícones conforme o zoom do mapa
    this._map.addListener('zoom_changed', () => {
      this._adjustMarkerIcons();
      this._adjustLabelSize();
    });
  }

  private _plotMarkers(): void {
    if (!this._map) return;

    // Limpa os marcadores anteriores antes de plotar os novos marcadores no mapa.
    this._markers.forEach(marker => marker.setMap(null));
    this._markers = [];
    this._lines.forEach(line => line.setMap(null)); // $$$$$$$$$$$$$$$$$ Limpa as linhas anteriores
    this._lines = [];
    this._labels.forEach(label => label.setMap(null)); // $$$$$$$$$$$$$$$$$ Limpa os rótulos anteriores
    this._labels = [];

    const bounds = new google.maps.LatLngBounds();

    // Adiciona marcadores ao mapa
    this.markersToPlot.forEach((point: { lat: number, lng: number, icon: string }) => {
      const position = new google.maps.LatLng(point.lat, point.lng);
      const marker = new google.maps.Marker({
        position,
        map: this._map,
        icon: {
          url: `app_platform/icons/${point.icon}`,
          scaledSize: this._getScaledSize()
        } as google.maps.Icon
      });

      this._markers.push(marker);
      bounds.extend(position);
    });

    // Centraliza e ajusta o zoom do mapa para mostrar todos os marcadores
    // Centraliza e ajusta o zoom do mapa para mostrar todos os marcadores
    if (this.markersToPlot.length === 1) {
      this._map.setCenter(bounds.getCenter());
      this._map.setZoom(10); // Define um nível de zoom padrão quando há apenas um ponto
    } else {
      this._map.fitBounds(bounds);
    }

    // Interliga os pontos com uma linha reta
    this._drawLines();
  }

  private _adjustMarkerIcons(): void {
    const scaledSize = this._getScaledSize();

    this._markers.forEach(marker => {
      const tempIcon = marker.getIcon() as google.maps.Icon;

      marker.setIcon({
        url: tempIcon.url,
        scaledSize: scaledSize
      });
    });
  }

  private _getScaledSize(): google.maps.Size {
    const zoom = this._map ? this._map.getZoom() : 10;
    const size = Math.max(40, (zoom ?? 10) * 5); // Ajuste o fator de escala conforme necessário

    return new google.maps.Size(size, size);
  }

  private async _drawLines(): Promise<void> {
    for (let i = 0; i < this.markersToPlot.length - 1; i++) {
      const point1 = this.markersToPlot[i];
      const point2 = this.markersToPlot[i + 1];

      const line = new google.maps.Polyline({
        path: [
          new google.maps.LatLng(point1.lat, point1.lng),
          new google.maps.LatLng(point2.lat, point2.lng)
        ],
        geodesic: true,
        strokeColor: '#FF00FF', // Cor magenta
        strokeOpacity: 1.0,
        strokeWeight: 3,
        map: this._map
      });

      this._lines.push(line); // $$$$$$$$$$$$$$$$$ Adiciona a linha à lista de linhas

      // Calcular a distância e a direção
      const distance = this._calculateDistance(point1, point2);
      const direction = this._calculateDirection(point1, point2);
      const midPoint = this._calculateMidPoint(point1, point2);


      //const magVar = await this._getMagneticVariation(point1.lat, point1.lng); // <<<<<<<<<<<<<<<<
      //const magDirection = this._applyMagneticVariation(direction, magVar);

      // Adicionar o rótulo personalizado
      this._addCustomLabel(point1, point2, direction, distance);
    }
  }

  private _addCustomLabel(point1: { lat: number, lng: number }, point2: { lat: number, lng: number }, direction: number, distance: number): void {
    if (!this._map) return;

    const labelDiv = document.createElement('div'); // <<<<<<<<<<<<<<<<
    labelDiv.className = 'custom-label'; // <<<<<<<<<<<<<<<<
    labelDiv.style.position = 'absolute';
    labelDiv.style.backgroundColor = '#FFF';
    labelDiv.style.padding = '2px 5px';
    labelDiv.style.border = '2px solid magenta';
    labelDiv.style.borderRadius = '5px';
    labelDiv.style.transform = 'translate(-50%, -50%)';
    labelDiv.style.whiteSpace = 'nowrap';
    labelDiv.style.fontSize = '10px';
    //labelDiv.style.fontWeight = 'bold';
    labelDiv.style.fontFamily = 'Roboto, sans-serif';
    
    //labelDiv.innerHTML = `${direction.toFixed(2)}° - ${distance.toFixed(2)} nm`;
    labelDiv.innerHTML = `${distance.toFixed(2)} nm`;

    const overlay = new google.maps.OverlayView(); // <<<<<<<<<<<<<<<<

    overlay.onAdd = () => {
      const panes = overlay.getPanes();
      if (panes) {
        panes.overlayMouseTarget.appendChild(labelDiv);
      }
    };

    overlay.draw = () => {
      const projection = overlay.getProjection();
      if (projection) {
        const position1 = new google.maps.LatLng(point1.lat, point1.lng);
        const position2 = new google.maps.LatLng(point2.lat, point2.lng);
        const pixel1 = projection.fromLatLngToDivPixel(position1);
        const pixel2 = projection.fromLatLngToDivPixel(position2);

        if (pixel1 && pixel2) {
          const midX = (pixel1.x + pixel2.x) / 2;
          const midY = (pixel1.y + pixel2.y) / 2;

          labelDiv.style.left = midX + 'px';
          labelDiv.style.top = midY + 'px'; // Centraliza o rótulo com a linha

          const angle = Math.atan2(pixel2.y - pixel1.y, pixel2.x - pixel1.x) * (180 / Math.PI);
          const adjustedAngle = angle < -90 || angle > 90 ? angle + 180 : angle; // >>>>>>>>>> Correção para evitar que o rótulo fique de ponta cabeça

          labelDiv.style.transform = `translate(-50%, -50%) rotate(${adjustedAngle}deg)`; // >>>>>>>>>> Correção para evitar que o rótulo fique de ponta cabeça
        }
      }
    };

    overlay.onRemove = () => {
      if (labelDiv.parentNode) {
        labelDiv.parentNode.removeChild(labelDiv);
      }
    };

    overlay.setMap(this._map);
    this._labels.push(overlay); // $$$$$$$$$$$$$$$$$ Adiciona o rótulo à lista de rótulos
  }

  private _adjustLabelSize(): void {
    if (!this._map) return;

    const zoom = this._map.getZoom();

    if (zoom) {
      const labelDivs = document.querySelectorAll('.custom-label');

      labelDivs.forEach(labelDiv => {
        if (zoom < 6) {
          (labelDiv as HTMLElement).style.display = 'none';
        } else {
          (labelDiv as HTMLElement).style.display = 'block';
          const fontSize = Math.min(15, zoom * 1.5) + 'px';
          (labelDiv as HTMLElement).style.fontSize = fontSize;
        }
      });
    }
  }

  private _calculateDistance(point1: { lat: number, lng: number }, point2: { lat: number, lng: number }): number {
    const R = 3440.065; // Raio da Terra em milhas náuticas
    const lat1 = this._toRadians(point1.lat);
    const lng1 = this._toRadians(point1.lng);
    const lat2 = this._toRadians(point2.lat);
    const lng2 = this._toRadians(point2.lng);

    const dLat = lat2 - lat1;
    const dLng = lng2 - lng1;

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  private _calculateDirection(point1: { lat: number, lng: number }, point2: { lat: number, lng: number }): number {
    const lat1 = this._toRadians(point1.lat);
    const lng1 = this._toRadians(point1.lng);
    const lat2 = this._toRadians(point2.lat);
    const lng2 = this._toRadians(point2.lng);

    const dLng = lng2 - lng1;

    const y = Math.sin(dLng) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLng);

    const brng = Math.atan2(y, x);
    const brngDegrees = (this._toDegrees(brng) + 360) % 360; // Converter para graus e ajustar para 0-360

    return brngDegrees;
  }

  private _toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  private _toDegrees(radians: number): number {
    return radians * (180 / Math.PI);
  }

  private _calculateMidPoint(point1: { lat: number, lng: number }, point2: { lat: number, lng: number }): { lat: number, lng: number } {
    const lat1 = this._toRadians(point1.lat);
    const lng1 = this._toRadians(point1.lng);
    const lat2 = this._toRadians(point2.lat);
    const lng2 = this._toRadians(point2.lng);

    const dLng = lng2 - lng1;

    const Bx = Math.cos(lat2) * Math.cos(dLng);
    const By = Math.cos(lat2) * Math.sin(dLng);

    const lat3 = Math.atan2(
      Math.sin(lat1) + Math.sin(lat2),
      Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By)
    );
    const lng3 = lng1 + Math.atan2(By, Math.cos(lat1) + Bx);

    return { lat: this._toDegrees(lat3), lng: this._toDegrees(lng3) };
  }

  private _applyMagneticVariation(direction: number, magVar: number): number {
    return (direction + magVar + 360) % 360;
  }

  private async _getMagneticVariation(lat: number, lng: number): Promise<number> {
    const response = await fetch(`https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination?lat1=${lat}&lon1=${lng}&key=YOUR_API_KEY&resultFormat=json`);
    const data = await response.json();
    return data.result[0].declination; // <<<<<<<<<<<<<<<<




    /*const date = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD
    console.log("XXXXXXXXXXXX", `https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination?lat1=${lat}&lon1=${lng}&model=WMM&startYear=${date}&resultFormat=json`)
  
    const response = await fetch(`https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination?lat1=${lat}&lon1=${lng}&model=WMM&startYear=${date}&resultFormat=json`);
    const data = await response.json();
   
    return data.result[0].declination; // <<<<<<<<<<<<<<<<*/
  }
  //#endregion
}