//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções  
 */
export const staticOptionsCgComponentsFuel: IDropDownOptionsBasic[] = [    
    { label: 'COMBUSTÍVEL', value: 'fuel' },
    { label: 'OUTROS', value: 'others' }
]