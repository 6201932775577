//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** COMPONENT ***** //
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleWeightBalanceService } from '../../../../../../../_services/module-weight-balance/module-weight-balance.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-weight-balance-sheet-list',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TitleFormTopicComponent
  ],
  templateUrl: './tab-weight-balance-sheet-list.component.html'
})
export class TabWeightBalanceSheetListComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabWeightBalanceSheetList = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  buttonActions!: any;

  waitingDialogType!: any;

  bolShowWaitingDialog!: boolean;
  bolShowCloneDialog!: boolean;

  strTypeWaitingDialog!: string;

  // ***** LIST ***** //
  arrayWeighBalanceSheetListByAircraft: any[] = [];
  arrayWeighBalanceSheetListAll: any[] = [];
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._loadWeightBalanceSheetListByAircraft();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;

    this.bolShowWaitingDialog = false;
    this.bolShowCloneDialog = false;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.DEFAULT;
    this.waitingDialogType = DIALOG_WAITING_TYPE;
  }

  /**
   * @author GASPAR
   * @date 2024-09-29
   * @version 1.0.0
   * 
   * @description Carrega a lista de fichas de peso e balanceamento.
   */
  private async _loadWeightBalanceSheetListByAircraft(): Promise<void> {
    this.bolShowWaitingDialog = true;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.AIRCRAFT_DATA_LIST;

    await this._moduleWeightBalanceService.getWeightBalanceSheetListByAircraft(this.formComponent.controls.aircraft_data_id.value).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.arrayWeighBalanceSheetListByAircraft = xApiResponse.data[0].data;

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          setTimeout(() => {
            this.bolShowWaitingDialog = false;

            this._messageService.add({
              severity: 'success',
              summary: 'Listar Folhas de Peso e Balanceamento',
              detail: 'Lista de Folha de Peso e Balanceamento carregada com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }, settingConfig.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowWaitingDialog = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Listar Folhas de Peso e Balanceamento',
            detail: 'Não foi possível carregar a Folha de Peso e Balanceamento.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowWaitingDialog = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Listar Folhas de Peso e Balanceamento',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  private async _loadWeightBalanceSheetListAll(): Promise<void> {
    this.bolShowWaitingDialog = true;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.AIRCRAFT_DATA_LIST;

    await this._moduleWeightBalanceService.getAllWeightBalanceSheet().subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.arrayWeighBalanceSheetListAll = xApiResponse.data[0].data;

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          setTimeout(() => {
            this.bolShowWaitingDialog = false;

            /*this._messageService.add({
              severity: 'success',
              summary: 'Listar Folhas de Peso e Balanceamento',
              detail: 'Lista de Folha de Peso e Balanceamento carregada com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });*/
          }, settingConfig.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowWaitingDialog = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Listar Folhas de Peso e Balanceamento para Clonagem',
            detail: 'Não foi possível carregar a Folha de Peso e Balanceamento.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowWaitingDialog = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Listar Folhas de Peso e Balanceamento para Clonagem',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @param xIdRegister 
   */
  private async _deleteWeightBalanceSheet(xIdRegister: string): Promise<void> {
    await this._moduleWeightBalanceService.deleteWeightBalanceSheet(xIdRegister).subscribe({
      next: (xResponse) => {
        if (xResponse.status_code == HTTP_STATUS.OK) {
          // Adiciona o ID do Weight Balance Sheet ao formulário AIRCRAFT DATA.
          if (!this.formComponent.controls.fk_weight_balance_sheet_id.value.includes(xIdRegister)) {
            // Remover o elemento do array
            this.formComponent.controls.fk_weight_balance_sheet_id.value = this.formComponent.controls.fk_weight_balance_sheet_id.value.filter((xId: string) => xId != xIdRegister);
          }

          if (this.arrayWeighBalanceSheetListByAircraft.includes(xResponse.data[0].data[0])) {
            // Remover o elemento do array
            this.arrayWeighBalanceSheetListByAircraft = this.arrayWeighBalanceSheetListByAircraft.filter((xItem: any) => xItem.id != xIdRegister);
          }

          // Emite a ação para atualizar os dados do AIRCRAFT DATA
          this.actionTabWeightBalanceSheetList.emit({ "action": this.buttonActions.DELETE, "id": `${xIdRegister}` });

          this._messageService.add({
            severity: 'success',
            summary: 'Apagar Folha de Peso e Balanceamento',
            detail: `${xResponse.data[0].message}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Apagar Folha de Peso e Balanceamento',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-24
  * @version 1.0.0
  * 
  * @description Emite a ação do botão clicado para o item da tabela.
  */
  callbackItemClickButton(xButtonAction: string, xIdRegister: string): void {
    if (xButtonAction == BUTTON_ACTION.EDIT) {
      this.actionTabWeightBalanceSheetList.emit({ action: xButtonAction, id_register: xIdRegister });
    } else if (xButtonAction == BUTTON_ACTION.CLONE) {
      this.actionTabWeightBalanceSheetList.emit({ action: xButtonAction, id_register: xIdRegister });
    } else if (xButtonAction == BUTTON_ACTION.DELETE) {
      this._deleteWeightBalanceSheet(xIdRegister)
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Emite a ação do botão clicado para o componente pai.
   * 
   * @param xButtonAction 
   */
  callbackTableWeightBalanceClickButton(xButtonAction: string) {
    if (xButtonAction == BUTTON_ACTION.NEW) {
      this.actionTabWeightBalanceSheetList.emit({ action: xButtonAction });
    } else if (xButtonAction == BUTTON_ACTION.OPEN_DIALOG) {
      this._loadWeightBalanceSheetListAll();

      this.bolShowCloneDialog = true;
    }
  }

  getAllAircraftMarks(xArrayBelongTo: any[]): string {
    let strMarks = '';

    xArrayBelongTo.forEach((xItem: any) => {
      if(strMarks == ''){
        strMarks += `${xItem.mark}`;
      } else {
        strMarks += ` - ${xItem.mark}`;
      }      
    });

    return strMarks;
  }
  //#endregion
}
