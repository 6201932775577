<section data-page="app-platform-page">
    <p-confirmDialog class="cls-component-confirm-dialog-default" />
    <sunrise-app-platform--waiting-dialog 
        [showWaitingDialog]="this.bolShowWaitingDialog" 
        [typeWaitingDialog]="this.strTypeWaitingDialog">
    </sunrise-app-platform--waiting-dialog>
    <sunrise-app-platform--toast-message></sunrise-app-platform--toast-message>
    <sunrise-app-platform--title-page [titlePage]="this.objPageTitle"></sunrise-app-platform--title-page>
    <div class="cls-page-list">
        <div class="cls-page-list-grid-1-columns">
            <div class="cls-page-list-content cls-util-no-padding">
                <p-table [value]="this.arrayFlightDataList" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true"
                    [rows]="5" [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Página {first} de {last}." [breakpoint]="'768px'"
                    responsiveLayout="stack" class="cls-component-table-page-list"
                    styleClass="p-datatable-gridlines p-datatable-striped">
                    <ng-template pTemplate="caption">
                        <div class="cls-component-table-page-list-caption">
                            <div class="cls-component-table-page-list-caption-left">
                                Total de Aeronaves: {{this.arrayFlightDataList ? this.arrayFlightDataList.length : 0 }}
                            </div>
                            <div class="cls-component-table-page-list-caption-right">
                                <div>
                                    <p-button styleClass="cls-component-button" label="NOVO VOO"
                                        icon="fa-solid fa-plus" severity="secondary"
                                        (click)="callbackTableClickButton(this.buttonActions.NEW)" />
                                </div>
                                <div>
                                    <p-button styleClass="cls-component-button" label="ATUALIZAR TABELA"
                                        icon="fa-solid fa-refresh" severity="secondary"
                                        (click)="callbackTableClickButton(this.buttonActions.RELOAD)" />
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:6%; text-align: center;">IMAGEM</th>
                            <th style="width:20%;">MATRÍCULA</th>
                            <th style="width:20%;">TIPO</th>
                            <th style="width:20%;">MODELO</th>
                            <th style="width:20%;">CÓDIGO ICAO</th>
                            <th style="width:14%;"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-itemArray>
                        <tr>
                            <td class="cls-component-table-page-list-td-image" style="text-align: center;">
                                <p-image src="https://primefaces.org/cdn/primeng/images/galleria/galleria11.jpg"
                                    previewImageSrc="https://primefaces.org/cdn/primeng/images/galleria/galleria11.jpg"
                                    alt="Image" [preview]="true" width="45rem" height="45rem"
                                    [imageStyle]="{'border-radius': '50%'}" />
                            </td>
                            <td>
                                <div class="flex align-items-center gap-2">
                                    <sunrise-country-flag countryCode="{{itemArray.registration_data.country}}">
                                    </sunrise-country-flag>
                                    <div>
                                        {{ this.classModuleMethods.setAircraftMark(itemArray.registration_data.mark) }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div
                                    [innerHTML]="this.classModuleMethods.getAircraftTypeLabel(itemArray.registration_data.aircraft_type)">
                                </div>
                            </td>
                            <td>{{ itemArray.registration_data.model }}</td>
                            <td>{{ itemArray.registration_data.icao_code }}</td>
                            <td>
                                <div class="cls-component-table-page-list-td-button">
                                    <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true" severity="info"
                                        (onClick)="callbackItemClickButton(this.buttonActions.EDIT, itemArray.aircraft_data_id)" />
                                    <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true"
                                        [outlined]="true"
                                        (onClick)="callbackItemClickButton(this.buttonActions.DELETE, itemArray.aircraft_data_id)" />
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</section>