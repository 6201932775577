//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleAccountService {
  //#region "|--- PROPERTIES---|" 
  urlAccountService: string;
  //#endregion

  constructor(private _http: HttpClient) {
    if (settingConfig.API_ACCOUNT_ADM_PORT == "443") {
      this.urlAccountService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlAccountService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_ACCOUNT_ADM_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- ACCOUNT DATA ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por SALVAR os dados relacionados ao módulo: ACCOUNT DATA.
   * 
   * @param xAccountDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele
   * @returns 
   */
  public saveFormAccountData(xAccountDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>(`${this.urlAccountService}/${settingConfig.API_ACCOUNT_ADM_URL_SEGMENT}/account/data`, xAccountDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xAccountDataForm))
      )
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR TODOS os dados relacionados ao módulo: ACCOUNT DATA.
   * 
   * @returns 
   */
  public getAllAccountDataList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlAccountService}/${settingConfig.API_ACCOUNT_ADM_URL_SEGMENT}/account/data/list`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR BY ID os dados relacionados ao módulo: AIRCRAFT DATA.
   * 
   * @param xAccountDataId
   * @returns 
   */
  public getAccountDataById(xAccountDataId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlAccountService}/${settingConfig.API_ACCOUNT_ADM_URL_SEGMENT}/account/data/${xAccountDataId}`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-01
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por ATUALIZAR os dados relacionados ao módulo: ACCOUNT DATA.
   * 
   * @param accountDataForm 
   * @param xAccountDataId
   * @returns
   */
  public updateFormAircraftData(xAccountDataId: string, xAccountDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.put<any>(`${this.urlAccountService}/${settingConfig.API_ACCOUNT_ADM_URL_SEGMENT}/account/data/${xAccountDataId}`, { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xAccountDataForm))
      )
  }
  //#endregion


















  /**
   * @status? OK
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description 
   *   - Verifica se um determinado valor sá existe na Base de Dados.
   * 
   * @param xFieldName 
   * @param xFieldValue 
   * @returns 
   */
  public checkIfHasFieldValue(xFieldName: string, xFieldValue: string): Observable<any> {
    return this._http.get<any>(`${this.urlAccountService}/${settingConfig.API_ACCOUNT_ADM_URL_SEGMENT}/account/data/check/${xFieldName}/${xFieldValue}`)
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }
}
