/**
 * @author GASPAR
 * @date 2024-09-23
 * @version 1.0.0
 * 
 * @description Configuração dos Aerodromos.
 */
export const configAerodromes: any[] = [
    { label: 'PARTIDA', value: 'departure', icon: 'airport-location-blue.png', color: '#214fc6' },
    { label: 'DESTINO', value: 'destination', icon: 'airport-location-red.png', color: '#d40000' },
    { label: 'ALTERNATIVO 1', value: 'alt_1', icon: 'airport-location-roxo.png', color: '#9f00c5' },
    { label: 'ALTERNATIVO 2', value: 'alt_2', icon: 'airport-location-cyan.png', color: '#00cccc' },
    { label: 'ALTERNATIVO 3', value: 'alt_3', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 4', value: 'alt_4', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 5', value: 'alt_5', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 6', value: 'alt_6', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 7', value: 'alt_7', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 8', value: 'alt_8', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 9', value: 'alt_9', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 10', value: 'alt_10', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 11', value: 'alt_11', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 12', value: 'alt_12', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 13', value: 'alt_13', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 14', value: 'alt_14', icon: 'airport-location-maroon.png', color: '#800000' },
    { label: 'ALTERNATIVO 14', value: 'alt_15', icon: 'airport-location-maroon.png', color: '#800000' }
]

/***************|--- COLORS AND LINKS TO ICONS ---|***************
     * SOBRE CORES: https://html-color.codes/black
     * ICONES CUSTOMIZÁVEIS AERODROMOS: https://www.iconpacks.net/free-icon/airport-location-4358.html
     * - airport-location-blue.png: BODY:#214fc6 - BACK:#ffffff - BORDER:#214fc6
     * - airport-location-red.png: BODY:#d40000 - BACK:#ffffff - BORDER:#d40000
     * - airport-location-roxo.png: BODY:#9f00c5 - BACK:#ffffff - BORDER:#9f00c5
     * - airport-location-orange.png: BODY:#ff4f00 - BACK:#ffffff - BORDER:#ff4f00
     * - airport-location-black.png: BODY:#000000 - BACK:#ffffff - BORDER:#ff4f00
     * - airport-location-green.png: BODY:#006b3c - BACK:#ffffff - BORDER:#006b3c
     * - airport-location-cyan.png: BODY:#00cccc - BACK:#ffffff - BORDER:#00cccc
     * - airport-location-pink.png: BODY:#fe4eda - BACK:#ffffff - BORDER:#fe4eda
     * - airport-location-yellow.png: BODY:#ffd800 - BACK:#ffffff - BORDER:#ffd800
     * - airport-location-gray.png: BODY:#808080  - BACK:#ffffff - BORDER:#808080
     * - airport-location-maroon.png: BODY:#800000  - BACK:#ffffff - BORDER:#8b0000
     * ICONES CUSTOMIZÁVEIS CIRCLE: https://www.iconpacks.net/free-icon/japan-flag-circular-17764.html
     * - circle-red.png: INSIDE:#d40000 - OUTSIDE:#d40000
     * - circle-green.png: INSIDE:#006b3c - OUTSIDE:#006b3c
     * - circle-yellow.png: INSIDE:#ffd800 - OUTSIDE:#ffd800
     * - circle-gray.png: INSIDE:#808080  - OUTSIDE:#808080
     * - circle-green-white.png: INSIDE:ffffff - OUTSIDE:#006b3c
     ****************************************************************/