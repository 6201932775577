<article class="cls-frame-vtl01">
    <div class="cls-frame-vtl01-grid">
        <header class="cls-frame-vtl01-header">
            &lt;header&gt;
        </header>
        <nav class="cls-frame-vtl01-nav">&lt;nav&gt;</nav>
        <section class="cls-frame-vtl01-top-widget">
            &lt;top-widget&gt;
        </section>
        <main class="cls-frame-vtl01-main">
            &lt;main&gt;
            <router-outlet></router-outlet>
        </main>
        <section class="cls-frame-vtl01-bottom-widget">
            &lt;bottom-widget&gt;
        </section>
        <footer class="cls-frame-vtl01-footer">&lt;footer&gt;</footer>
    </div>
</article>