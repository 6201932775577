/**
 * @author GASPAR
 * @date 2024-09-24
 * @version 1.0.0
 * 
 * @description Configuração das Aeronaves.
 */
export const configAircraft: any[] = [
    { label: 'avião', value: 'airplane', icon: 'fa-solid fa-plane' },
    { label: 'helicóptero', value: 'rotorcraft', icon: 'fa-solid fa-helicopter' }
]
