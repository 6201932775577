//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IFkFlightVsCrew } from '../../../../_library/interfaces/IFkFlightVsCrew';
import { IFkAerodromeBasicDataList } from '../../../../_library/interfaces/IFkAerodromeBasicDataList';
import { IFlightDataHistoricalList } from '../../../../_library/interfaces/IFlightDataHistoricalList';
//#endregion

//#region "|--- ENUM ---|"
import { REGEX_EXPRESSIONS } from '../../../../_library/definitions/RegexExpressions';
//#endregion

/** 
 * @status:
 * @author GASPAR
 * @date 2025-01-06
 * @version 1.0.0
 * 
 * @description 
 *   - Interface do FLIGHT DATA.
 */
export function initFormFlightData(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        flt_data_id: [null],
        fk_flt_plan_id: [null],
        ext_url_data: objFormBuilder.group({
            code: [null],
            url: [null],
            end_date_time_utc: [null],
            max_attempt: [null],
            pwd_hash: [null]
        }),
        flt_code_data: objFormBuilder.group({
            text_norm: [null],
            eobt_date_utc: [null],
            seq: [null]
        }),
        logbook_data: objFormBuilder.group({
            fk_flt_log_data_id: [null],
            in: [null],
            page: [null],
            stage: [null]
        }),
        flt_req_data: objFormBuilder.group({
            flt_clear: objFormBuilder.group({
                clear_in: [null],
                content: [null],
                date_time_utc: [null]
            }),
            acft_data: objFormBuilder.group({
                fk_acft_data_id: [
                    null,
                    [
                        Validators.required
                    ]
                ],
                mark: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(3),
                        Validators.maxLength(7),
                        Validators.pattern(REGEX_EXPRESSIONS.AIRCRAFT_MARK)
                    ]
                ],
                acft_type: [null],
                fk_owr_opr_data_id: [
                    null,
                    [
                        Validators.required
                    ]
                ],
                opr_full_name: [null]
            }),
            fk_crew_lst: objFormBuilder.array([] as IFkFlightVsCrew[]),
            eobt_date_time: objFormBuilder.group({
                date_utc: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(10),
                        Validators.pattern(REGEX_EXPRESSIONS.DATE_DD_MM_YYYY)
                    ]
                ],
                time_utc: [
                    null,
                    [
                        Validators.required,
                        Validators.pattern(REGEX_EXPRESSIONS.TIME_HH_MM)
                    ]
                ],
                date_time_utc: [null],
                date_time_norm: [null],
            }),
            eet_total: [
                null,
                [
                    Validators.required,
                    Validators.pattern(REGEX_EXPRESSIONS.TIME_HH_MM)
                ]
            ],
            lnd_date_time: objFormBuilder.group({
                date_utc: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(10),
                        Validators.pattern(REGEX_EXPRESSIONS.DATE_DD_MM_YYYY)
                    ]
                ],
                time_utc: [
                    null,
                    [
                        Validators.required,
                        Validators.pattern(REGEX_EXPRESSIONS.TIME_HH_MM)
                    ]
                ],
                date_time_utc: [null],
                date_time_norm: [null],
            }),
            flt_rule: [
                null,
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern(REGEX_EXPRESSIONS.FLIGHT_RULE)
                ]
            ],
            flt_level: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(4),
                    Validators.pattern(REGEX_EXPRESSIONS.FLIGHT_LEVEL)
                ]
            ],
            cruise_speed: [
                null,
                [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(5),
                    Validators.pattern(REGEX_EXPRESSIONS.CRUISE_SPEED)
                ]
            ],
            endurance: [
                null,
                [
                    Validators.required,
                    Validators.pattern(REGEX_EXPRESSIONS.TIME_HH_MM)
                ]
            ],
            flt_type: [
                null,
                [
                    Validators.required
                ]
            ],
            fk_ad_bsc_lst: objFormBuilder.array([] as IFkAerodromeBasicDataList[]),
        }),
        wght_bal_data: objFormBuilder.group({
            fk_wght_bal_data_filled_id: [null],
            fk_wght_bal_data_sheet_id: [null]
        }),
        map_data: objFormBuilder.group({
            marker_data: [null],
            draw_data: [null],
        }),
        hist_data_list: objFormBuilder.array([] as IFlightDataHistoricalList[]),
        curr_sts: objFormBuilder.group({
            flt_request: [null],
            flt_plan: [null],
            flt: [null],
            flt_clear: [null]
        }),
        doc_db_control: objFormBuilder.group({
            crted_at_utc: [null],
            upted_at_utc: [null],
            cancd_at_utc: [null],
            fnsed_at_utc: [null]
        }),
        tags: [[]]
    });
}