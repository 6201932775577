//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initOwnerOperatorForm } from '../../../../../../owner_operator/form_init/owner-operator-form';

// ***** CLASS ***** //
import { AviationToolsHandlerClass } from '../../../../../../../_classes/AviationToolsHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';

// ***** COMPONENT ***** //
import { DialogFormOwnerOperatorComponent } from '../dialog-form-owner-operator/dialog-form-owner-operator.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../../../../_services/module-aircraft/module-aircraft.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAircraftCountries } from '../../../../../../../../_library/static_options/staticOptionsAircraftCountries';
import { staticOptionsAircraftTypes } from '../../../../../../../../_library/static_options/staticOptionsAircraftTypes';
import { staticOptionsAircraftFlightRules } from '../../../../../../../../_library/static_options/staticOptionsAircraftFlightRules';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-register-data',
  standalone: true,
  imports: [
    CommonModule,
    CanttechComponentsModule,
    DialogFormOwnerOperatorComponent,
    PrimengComponentsModule
  ],
  templateUrl: './tab-register-data.component.html'
})
export class TabRegisterDataComponent implements OnInit, AfterViewInit {
  @ViewChild('ownerTableContent') ownerTableContent!: ElementRef;
  @ViewChild('operatorTableContent') operatorTableContent!: ElementRef;

  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;  // Dados da Aeronave.
  @Input() allOwnersOperators!: any[]; // Todos os Proprietários e Operadores cadastrados no Sistema.

  @Input() set retrievedDataOwnerOperator(xData: any) {
    if (xData && xData != null) {
      if (xData.owner_operator_type == 'owner') {
        this.typeDialogOwnerOperator = 'owner';
      } else if (xData.owner_operator_type == 'operator') {
        this.typeDialogOwnerOperator = 'operator';
      }

      this.formOwnerOperator = initOwnerOperatorForm();
      this.formOwnerOperator.patchValue(xData);

      this.taskDialogOwnerOperator = "edit";
      this.showDialogOwnerOperator = true;
    }
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionToolbarTabRegisterData = new EventEmitter<string>(); // NEW - UPDATE - SAVE - EXIT
  @Output() actionOwnerOperator = new EventEmitter<{ action: string, data: any }>();
  @Output() actionRegisterData = new EventEmitter<any>();
  @Output() actionTabItensOwnerOperator = new EventEmitter<any>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public textOwnerOperatorLabelType!: string
  public typeDialogOwnerOperator!: any; // Não inicializar no ngOnInit, pois ele é atribuído no @Input.
  public taskDialogOwnerOperator!: any; // Não inicializar no ngOnInit, pois ele é atribuído no @Input.  

  // ***** CLASSES ***** //
  public classAviationTools!: any;
  public classLocalMethods!: any;
  public classInternationalization!: any;
  public classModuleMethods!: any;
  
  // ***** SHOW DIALOG ***** //  
  public showDialogOwnerOperator!: any;
  public showDialogRecoverOwnerOperator!: any;

  // ***** OWNER & OPERATOR ***** //
  public formOwnerOperator!: any;

  // ***** OPTIONS ***** //
  public optionsAircraftCountries!: IDropDownOptionsCountry[];
  public optionsAircraftFlightRules!: IDropDownOptionsIcon[];
  public optionsAircraftTypes!: IDropDownOptionsIcon[];
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,    
    private _messageService: MessageService,
    private _moduleAircraftService: ModuleAircraftService,
  ) { }

 // @status:
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @status:
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this._setTablesHeight();
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-27
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.textOwnerOperatorLabelType = "";

    this.classAviationTools = AviationToolsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    
    this.showDialogOwnerOperator = false;
    this.showDialogRecoverOwnerOperator = false;

    this.formOwnerOperator = null;

    this.optionsAircraftCountries = staticOptionsAircraftCountries;
    this.optionsAircraftFlightRules = staticOptionsAircraftFlightRules;
    this.optionsAircraftTypes = staticOptionsAircraftTypes;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
  * @status:
  * @author GASPAR
  * @date 2024-11-22
  * @version 1.0.0
  * 
  * @description 
  *   - Callback das Ações dos Botões da Toolbar.
  * 
  * @param xButtonAction
  */
  public onClickToolbarTabRegisterData(xButtonAction: string): void {
    this.actionToolbarTabRegisterData.emit(xButtonAction);
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-11-01
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será associado ao onBlur do controle para verificar se o valor da Matrícula da Aeronave já está cadastrado.
  * 
  * @returns 
  */
  public onBlurCheckFieldValue(): any {
    return async (xEvent: any, xComponentContext: any) => {
      // Recuperar o elemento que disparou o evento.
      const inputElement = xEvent.target as HTMLInputElement;
      const inputValue = inputElement.value;
      const inputName = inputElement.name.replaceAll("-", "_");

      if (inputValue && inputValue !== null && inputValue.length > 3) {
        xComponentContext.showIconWaiting = true;

        // VERIFICA SE O VALOR JÁ EXISTE NA BASE DE DADOS: ACCOUNT        
        await this._moduleAircraftService.checkIfHasFieldValue(inputName, inputValue).subscribe({
          next: (xApiResponse: any) => {
            if (xApiResponse) {
              if (xApiResponse.data[0] && xApiResponse.data[0].qtd > 0) { // Só importa se houver resultado positivo. Erros e outros não deve ser sinalizado
                if (this.formComponent.get(inputName).errors === null) { // Se já estiver inválido, não precisa marcar novamente. 
                  this.formComponent.get(inputName).markAsDirty();
                  this.formComponent.get(inputName).markAsTouched();
                  this.formComponent.get(inputName).setErrors({ unique: true });

                  this._messageService.add({
                    severity: 'error',
                    summary: this.classInternationalization.getTranslation('ttl_AircraftDataSave'),
                    detail: this.classInternationalization.getTranslation('msg_MarkExisted').replaceAll("##0##", inputValue),
                    key: settingConfig.TOAST_KEY,
                    life: settingConfig.TOAST_LIFE
                  });
                }
              }
            }

            if (this.formComponent.controls.register_data.controls.aircraft_mark && this.formComponent.controls.register_data.controls.aircraft_mark.value.length > 3) {
              // Caso o campo esteja preenchido e não tenha erro, pode-se fazer a consulta ANAC.
              if (this.formComponent.get(inputName).errors === null) {
                if (this.formComponent.controls.register_data.controls.country.value == null || this.formComponent.controls.register_data.controls.country.value == "BR") {
                  // Consultar o RAB da ANAC.
                  this.actionRegisterData.emit({ action: ACTION_TYPE.CHECK_ANAC_RAB });
                }
              }
            }
          },
          error: (xError: any) => { // Não importa tratar o erro, pois na hora de inserir vai ser feita outra verificação mais detalhada.
            // Pode ser um erro que não tenha relação com a o valor já estar ou não cadastrado.
            if (this.formComponent.controls.register_data.controls.aircraft_mark && this.formComponent.controls.register_data.controls.aircraft_mark.value.length > 3) {
              // Caso o campo esteja preenchido e não tenha erro, então pode-se fazer a consulta ANAC.
              if (this.formComponent.get(inputName).errors === null) {
                if (this.formComponent.controls.register_data.controls.country.value == null || this.formComponent.controls.register_data.controls.country.value == "BR") {
                  this.actionRegisterData.emit({ action: ACTION_TYPE.CHECK_ANAC_RAB });
                }
              }
            }
          }
        });

        setTimeout(() => {
          xComponentContext.showIconWaiting = false;
        }, 1500);
      }
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  //#endregion

  //#region "|--- UPLOAD IMAGE METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-20
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para fazer o upload da imagem.
   * 
   * @returns 
   */
  public getUrlToUploadAvatarImage(): string {
    let urlUploadImage: string;

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlUploadImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlUploadImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_WEIGHT_BALANCE_PORT}/${this.currentSettings.API_PATH}`;
    }

    return `${urlUploadImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/aircraft/image`.toLowerCase();
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-20
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o Objeto com os dados que devem ser passados no corpo da requisição para fazer o upload da imagem.
   */
  public getParametersToUploadAvatarImage(): any {
    let tempId = "000";
    let tempMark = "000";

    if (this.formComponent.controls.aircraft_data_id.value != null) {
      tempId = this.formComponent.controls.aircraft_data_id.value;
    }

    if (this.formComponent.controls.register_data.controls.aircraft_mark.value != null) {
      tempMark = this.formComponent.controls.register_data.controls.aircraft_mark.value;
    }

    return {
      id: tempId,
      aircraft_mark: tempMark
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-28
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para mostrar a imagem.
   *
   * @returns
   */
  public getUrlToShowAvatarImage(): string {
    let urlShowImage: string;
    let tempId = "000";
    let tempMark = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_AIRCRAFT_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.formComponent.controls.aircraft_data_id.value != null) {
      tempId = this.formComponent.controls.aircraft_data_id.value;
    }

    if (this.formComponent.controls.register_data.controls.aircraft_mark.value != null) {
      tempMark = this.formComponent.controls.register_data.controls.aircraft_mark.value;
    }

    return `${urlShowImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/aircraft/image/${tempId}/${tempMark}`.toLowerCase();
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description 
   *   - Responsável por tratar as ações do Upload de imagens.  
   * 
   * @param xAction
   */
  public handlerActionAvatarImageUpload(xAction: any) {
    if (xAction.action === "error") {
      this._messageService.add({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_UploadImage'),
        detail: xAction.message,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else if (xAction.action === "success" || xAction.action === "set_default") {
      const tempAvatarImage = this.formComponent.controls.avatar_image.value;

      // !!!! REVISARRRRRRRRRRRRRRRRRRR EU NÃO SEI SE ESTÁ CERTO !!!!!
      //if (tempAvatarImage.indexOf("temp--") >= 0) {
      //  this.formComponent.controls.avatar_image.setValue(tempAvatarImage.replace("temp--", ""));
      //}
      
      this.actionToolbarTabRegisterData.emit(ACTION_TYPE.UPDATE);

      this._messageService.add({
        severity: 'success',
        summary: this.classInternationalization.getTranslation('ttl_UploadImage'),
        detail: xAction.message,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    }
  }
  //#endregion















  //!!!--------------------------------------------------------------------------------------------
  ngAfterViewInit(): void {
    setTimeout(() => {
      this._setTablesHeight();
    }, 1500);
  }



  //#region "|--- PRIVATE METHODS ---|" 
  

  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário de Proprietários e Operadores.
   */
  private _initOwnerOperatorForm(): void {
    this.formOwnerOperator = initOwnerOperatorForm();

    this.formOwnerOperator.controls.owner_operator_data_id.setValue(this.classLocalMethods.generateUuid());
  }


  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description 
   *  - Ajusta a altura das tabelas de Proprietários e Operadores.
   */
  private _setTablesHeight(): void {
    const heightOwnerTable = this.ownerTableContent.nativeElement.offsetHeight;
    const heightOperaTable = this.operatorTableContent.nativeElement.offsetHeight;

    if (heightOwnerTable > heightOperaTable) {
      this.ownerTableContent.nativeElement.style.height = `${heightOwnerTable}px`;
      this.operatorTableContent.nativeElement.style.height = `${heightOwnerTable}px`;
    } else if (heightOwnerTable < heightOperaTable) {
      this.ownerTableContent.nativeElement.style.height = `${heightOperaTable}px`;
      this.operatorTableContent.nativeElement.style.height = `${heightOperaTable}px`;
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"


  /**
   * @author GASPAR
   * @date 2024-11-27
   * @version 1.0.0
   * 
   * @description 
   *   - Callback das Ações dos Botões da Tabela Proprietário. 
   */
  public onClickTableOwner(xButtonAction: string): void {
    if (xButtonAction == ACTION_TYPE.ADD_ITEM) {
      this.typeDialogOwnerOperator = 'owner';
      this.taskDialogOwnerOperator = "new";

      this._initOwnerOperatorForm();

      this.showDialogOwnerOperator = true;
    } else if (xButtonAction == ACTION_TYPE.SEARCH) { // Recuperar todos os Proprietários e Operadores cadastrados no Sistema.      
      this.typeDialogOwnerOperator = 'owner';
      this.textOwnerOperatorLabelType = "Proprietário";

      // Antes de Qualquer coisa, recuperar/atualizar a lista de Proprietários e Operadores.
      this.actionOwnerOperator.emit({ action: ACTION_TYPE.GET_ALL_OWNER_OPERATOR, data: null });

      this.showDialogRecoverOwnerOperator = true;
    }
  }


  /**
   * @author GASPAR
   * @date 2024-11-27
   * @version 1.0.0
   * 
   * @description 
   *   - Callback das Ações dos Botões da Tabela Operador. 
   */
  public onClickTableOperator(xButtonAction: string): void {
    if (xButtonAction == ACTION_TYPE.ADD_ITEM) {
      this.typeDialogOwnerOperator = 'operator';
      this.taskDialogOwnerOperator = "new";

      this._initOwnerOperatorForm();

      this.showDialogOwnerOperator = true;
    } else if (xButtonAction == ACTION_TYPE.SEARCH) { // Recuperar todos os Proprietários e Operadores cadastrados no Sistema.
      this.typeDialogOwnerOperator = 'operator';
      this.textOwnerOperatorLabelType = 'Operator';

      // Antes de Qualquer coisa, recuperar/atualizar a lista de Proprietários e Operadores.
      this.actionOwnerOperator.emit({ action: ACTION_TYPE.GET_ALL_OWNER_OPERATOR, data: null });

      this.showDialogRecoverOwnerOperator = true;
    }
  }


  //#endregion

  //#region "|--- HANDLER METHODS ---|"

  /**
   * @author GASPAR
   * @date 2024-11-27
   * @version 1.0.0
   * 
   * @description 
   *   - Callback da ações dos "Salvar Novo", "Salvar Alterações", "Sair" dados do Proprietário ou Operador.
   * 
   * @abstract 
   *   - Os botões estão no DIALOGO do formulário de Proprietário ou Operador.
   * 
   * @param xEvent 
   */
  public async handlerActionDialogFormOwnerOperator(xEvent: string): Promise<void> {
    if (xEvent == ACTION_TYPE.SAVE) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION) {
        console.log("SALVAR PROPRIETÁRIO E OPERADOR - DIALOG", this.formOwnerOperator.getRawValue());
      }

      this.actionOwnerOperator.emit({ action: ACTION_TYPE.SAVE, data: this.formOwnerOperator.getRawValue() });
    } else if (xEvent == ACTION_TYPE.UPDATE) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION) {
        console.log("ATUALIZAR PROPRIETÁRIO E OPERADOR - DIALOG", this.formOwnerOperator.getRawValue())
      }

      this.actionOwnerOperator.emit({ action: ACTION_TYPE.UPDATE, data: this.formOwnerOperator.getRawValue() });
    } else if (xEvent == ACTION_TYPE.EXIT) {
      if (this.currentSettings.SHOW_LOG_EXCEPTION) {
        console.log("SAIR DIALOG PROPRIETÁRIO E OPERADOR - DIALOG");
      }

      this.formOwnerOperator = null; // Limpar o formulário nos valores e nos eventos.
    }

    this.showDialogOwnerOperator = false; // Em todos os casos fecha-se o Dialog.
  }
  //#endregion





















  //#region "|--- PRIVATE METHODS ---|"  


  private async _saveOwnerOperatorData() {
    if (this.formOwnerOperator.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formOwnerOperator, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Proprietário - Operador',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {

    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"










  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Facha o Dialog.
   */
  public closeDialogRecoverOwnerOperator(): void {
    this.showDialogRecoverOwnerOperator = false;
  }




  /**
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   * @description 
   *   - Callback da ações dos ITENS da tABELA de Proprietários.
   * 
   * @param xAction
   * @param xRowIndex
   * @param xType 
   */
  public onClickItemTableOwner(xAction: string, xRowIndex: string, xType = ""): void {
    if (xAction === ACTION_TYPE.BOTH) {
      this.setOwnerOperatorToBothType(xRowIndex);
    } else if (xAction === ACTION_TYPE.EDIT) {
      this.setOwnerOperatorRetrieveItem(xRowIndex, xType);
    } else if (xAction === ACTION_TYPE.DELETE) {
      this.setOwnerOperatorDelete(xRowIndex, xType);
    }
  }


  /**
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   * @description 
   *   - Callback da ações dos ITENS da tABELA de Proprietários.
   * 
   * @param xAction
   * @param xRowIndex
   * @param xType 
   */
  public onClickItemTableOperator(xAction: string, xRowIndex: string, xType = ""): void {
    if (xAction === ACTION_TYPE.BOTH) {
      this.setOwnerOperatorToBothType(xRowIndex);
    } else if (xAction === ACTION_TYPE.EDIT) {
      this.setOwnerOperatorRetrieveItem(xRowIndex, xType);
    } else if (xAction === ACTION_TYPE.DELETE) {
      this.setOwnerOperatorDelete(xRowIndex, xType);
    }
  }


  /**
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atribui o tipo de Proprietário E Operador.
   * 
   * @param xRegisterIndex
   */
  setOwnerOperatorToBothType(xRegisterIndex: string) {
    this.formComponent.controls.owner_operator.value[xRegisterIndex].owner_operator_type = "both";

    const dataToBoth = {
      owner_operator_table_index: xRegisterIndex,
      owner_operator_data_id: this.formComponent.controls.owner_operator ? this.formComponent.controls.owner_operator.value[xRegisterIndex].owner_operator_data_id : null,
      owner_operator_type: "both",
      full_name: this.formComponent.controls.owner_operator ? this.formComponent.controls.owner_operator.value[xRegisterIndex].full_name : null
    }

    this.actionOwnerOperator.emit({ action: ACTION_TYPE.BOTH, data: dataToBoth });
  }


  /**
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   *  @description 
   *    - Método que recupera dados do Proprietários e Operadores para serem editados.
   * 
   * @param xRegisterIndex 
   * @param xTypeOwnerOperator 
   */
  setOwnerOperatorAddItem(xRegisterIndex: string) {
    const dataToAdd = {
      owner_operator_table_index: xRegisterIndex,
      owner_operator_data_id: this.allOwnersOperators[Number(xRegisterIndex)].owner_operator_data_id,
      owner_operator_type: this.typeDialogOwnerOperator,
      full_name: this.allOwnersOperators[Number(xRegisterIndex)].full_name
    }

    this.actionOwnerOperator.emit({ action: ACTION_TYPE.ADD_ITEM, data: dataToAdd });
  }


  /**
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   *  @description 
   *    - Método que recupera dados do Proprietários e Operadores para serem editados.
   * 
   * @param xRegisterIndex 
   * @param xTypeOwnerOperator 
   */
  setOwnerOperatorRetrieveItem(xRegisterIndex: string, xTypeOwnerOperator: string) {
    const dataToRetrieve = {
      owner_operator_table_index: xRegisterIndex,
      owner_operator_data_id: this.formComponent.controls.owner_operator ? this.formComponent.controls.owner_operator.value[xRegisterIndex].owner_operator_data_id : null,
      owner_operator_type: xTypeOwnerOperator,
      full_name: this.formComponent.controls.owner_operator ? this.formComponent.controls.owner_operator.value[xRegisterIndex].full_name : null
    }

    this.actionOwnerOperator.emit({ action: ACTION_TYPE.RETRIEVE_ITEM_TABLE, data: dataToRetrieve });
  }


  /**
   * @author GASPAR
   * @date 2024-11-26
   * @version 1.0.0
   * 
   * @description 
   *   - Método que deleta um Proprietário ou Operador.
   * 
   * @param xRegisterIndex
   * @param xTypeOwnerOperator
   */
  setOwnerOperatorDelete(xRegisterIndex: string, xTypeOwnerOperator: string) {
    const dataToDelete = {
      owner_operator_table_index: xRegisterIndex,
      owner_operator_data_id: this.formComponent.controls.owner_operator ? this.formComponent.controls.owner_operator.value[xRegisterIndex].owner_operator_data_id : null,
      owner_operator_type: xTypeOwnerOperator,
      full_name: this.formComponent.controls.owner_operator ? this.formComponent.controls.owner_operator.value[xRegisterIndex].full_name : null
    }

    this.actionOwnerOperator.emit({ action: ACTION_TYPE.DELETE_ITEM_TABLE, data: dataToDelete });
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna a quantidade de Proprietários ou AMBOS.
   * 
   * @param xArray 
   * @returns 
   */
  public getLengthOwnerOrBoth(xArray: any): number {
    return xArray.filter((xElement: any) => xElement.controls.owner_operator_type.value == 'owner' || xElement.controls.owner_operator_type.value == 'both').length;
  }

  /**
   * 
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Retorna a quantidade de Operadores ou AMBOS.
   * 
   * @param xArray 
   * @returns 
   */
  public getLengthOperatorOrBoth(xArray: any): number {
    return xArray.filter((xElement: any) => xElement.controls.owner_operator_type.value == 'operator' || xElement.controls.owner_operator_type.value == 'both').length;
  }

  /**
   * @author GASPAR
   * @date 2024-11-01
   * @version 1.0.0
   * 
   * @description Método qeu retorna o label do tipo de documento.
   * 
   * @param xType
   */
  public getDocumentTypeLabel(xType: string): string {
    if (xType && xType != null) {
      return `${xType.toUpperCase()}: `;
    } else {
      return "";
    }
  }
  //#endregion
}
// 603