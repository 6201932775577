//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormArray, FormGroup, ValidationErrors, FormBuilder } from '@angular/forms'

// ***** NPM ***** //
import { v4 as uuidv4 } from 'uuid';
import { NIL as NIL_UUID } from 'uuid';
//#endregion

export class LocalMethodsHandlerClass {
    /**
     * @status:
     * @author GASPAR
     * @date 2025-01-06
     * @version 1.0.0
     * 
     * @description 
     *   - Retorna a data atual no UTC.
     * 
     * @param xContent 
     * @returns 
     */
    static getCurrentDateUTC(): Date {
        const now = new Date();
        return new Date(Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds(),
            now.getUTCMilliseconds()
        ));
    }

    /**
     * @status:
     * @author GASPAR
     * @date 2025-01-06
     * @version 1.0.0
     * 
     * @description 
     *   - Retorna a data atual no UTC formato dd/mm/yyyy hh:mm.
     * 
     * @param xContent 
     * @returns 
     */
    static getCurrentDateUTC_DDMMYYYY_HHMMSS(): string {
        const now = new Date();
        
        const day = String(now.getUTCDate()).padStart(2, '0');
        const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = now.getUTCFullYear();
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    /**
     * @status:
     * @author GASPAR
     * @date 2024-12-03
     * @version 1.0.0
     * 
     * @description
     *   - Converter um número para FORMATO do locale desejado.
     * 
     * @param xNumber 
     * @param xLocale 
     * @returns 
     */
    static convertNumberToLocale(xNumber: number, xLocale: string, xDecimal = null): string | null {
        if (xDecimal !== null) {
            xNumber = parseFloat(xNumber.toFixed(xDecimal));
        }

        if (isNaN(xNumber) || xNumber === null) {
            return null;
        } else {
            const tempLocale = `${xLocale.split('-')[0]}-${xLocale.split('-')[1].toUpperCase()}`;
            const formattedNumber = new Intl.NumberFormat(tempLocale).format(xNumber);

            return formattedNumber;
        }
    }

    /**
     * @status:
     * @author GASPAR
     * @date 2025-01-06
     * @version 1.0.0
     * 
     * @description
     *    - Transforma uma data UTC no formato ISO 8601 para o formato dd/mm/yyyy hh:mm:ss.
     * 
     * @param xContent 
     * @returns 
     */
    static transformISO8601DateToDDMMYYYY_HHMMSS(xDateString: string): string | null {
        if (!xDateString) {
            return null;
        }

        const date = new Date(xDateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    /**
     * @author GASPAR
     * @date 2024-12-03
     * @version 1.0.0
     * 
     * @description
     *     - Remove qualquer mascara, retornando somente letras e números.
     * 
     * @param xContent 
     * @returns 
     */
    static removeAnyMask(xContent: string): string {
        return xContent.replace(/[^a-zA-Z0-9]/g, "");
    }

    /**
     * @author GASPAR
     * @date 2024-12-03
     * @version 1.0.0
     * 
     * @description
     *     - Remove os elementos duplicados de um array de objetos, REMOVENDO o primeiro elemento de cada valor também.
     * 
     * @param array 
     * @returns 
     */
    static removeDuplicatesAndOriginal(array: any[]): any[] {
        const countMap: { [key: string]: number } = {};

        // Contar a ocorrência de cada valor
        array.forEach(item => {
            countMap[item.value] = (countMap[item.value] || 0) + 1;
        });

        // Filtrar o array para remover todos os elementos que aparecem mais de uma vez
        return array.filter(item => countMap[item.value] === 1);
    }

    /**
     * @author GASPAR
     * @date 2024-12-03
     * @version 1.0.0
     * 
     * @description função que converta uma data em string, qualquer formato para dd/MM/yyyy.
     * 
     * @param xDateString 
     * @returns 
     */
    static convertAnyDateToDDMMYYYY_UTC(xDateString: string): string | null {
        if (xDateString === null || xDateString === undefined || xDateString === '') {
            return null;
        } else {
            // Tenta converter usando o formato dd/MM/yyyy
            if (/\d{2}\/\d{2}\/\d{4}/.test(xDateString)) {
                const [day, month, year] = xDateString.split('/');

                return `${year}-${month}-${day}`;
            }

            // Tenta converter usando o construtor Date
            const date = new Date(xDateString);

            if (!isNaN(date.getTime())) {
                const year = date.getUTCFullYear();
                const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
                const day = date.getUTCDate().toString().padStart(2, '0');

                return `${day}/${month}/${year}`;
            }

            // Formato inválido
            return xDateString;
        }
    }

    static convertAnyDateToDDMMYYYY_UTCHHMMSS(xDateString: string): string | null {
        if (xDateString === null || xDateString === undefined || xDateString === '') {
            return null;
        } else {
            // Tenta converter usando o formato dd/MM/yyyy
            if (/\d{2}\/\d{2}\/\d{4}/.test(xDateString)) {
                const [day, month, year] = xDateString.split('/');

                return `${year}-${month}-${day}`;
            }

            // Tenta converter usando o construtor Date
            const date = new Date(xDateString);
            if (!isNaN(date.getTime())) {
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');

                return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
            }

            // Formato inválido
            return xDateString;
        }
    }

    /**
     * @author GASPAR
     * @date 2024-11-10
     * @version 1.0.0
     * 
     * @description  Formata o tamanho do arquivo para um modo amigável.
     * 
     * @param bytes 
     * @returns 
     */
    static formatFileSize(bytes: number): string {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
    }

    /**
     * @author GASPAR
     * @date 2024-11-10
     * @version 1.0.0
     * 
     * @description Método que coloca a mascara em um conteúdo.
     * 
     * @param xContent 
     * @param xMask 
     * @returns 
     */
    static insertMaskContent(xContent: string, xMask: string): string {
        if (xContent && xContent !== null) {
            // Retira os caracteres especiais da máscara, mantendo letras e números.
            let tmpContent = xContent.replace(/[^a-zA-Z0-9]/g, "");

            if (xMask === "cpf" && tmpContent.length === 11) {
                // Coloca a máscara de CPF no conteúdo.
                tmpContent = tmpContent.replace(/(\w{3})(\w{3})(\w{3})(\w{2})/, "$1.$2.$3-$4");
            } else if (xMask === "cnpj" && tmpContent.length === 14) {
                // Coloca a máscara de CNPJ no conteúdo.
                tmpContent = tmpContent.replace(/(\w{2})(\w{3})(\w{3})(\w{4})(\w{2})/, "$1.$2.$3/$4-$5");
            }

            return tmpContent;
        } else {
            return '';
        }
    }

    //#region "|--- PUBLIC METHODS ---|"    
    /**
     * @author GASPAR
     * @date 2024-10-07
     * @version 1.0.0 
     * 
     * @description Método que converte uma cor hexadecimal para RGBA.
     * 
     * @param hex 
     * @param alpha 
     * @returns 
     */
    static hexToRgba(hex: string, alpha: number): string {
        // Remove o caractere '#' se estiver presente
        hex = hex.replace(/^#/, '');

        // Converte a string hexadecimal para valores RGB
        const rContent = parseInt(hex.substring(0, 2), 16);
        const gContent = parseInt(hex.substring(2, 4), 16);
        const bContent = parseInt(hex.substring(4, 6), 16);

        // Retorna a cor no formato RGBA
        return `rgba(${rContent}, ${gContent}, ${bContent}, ${alpha})`;
    }

    /**
     * @author GASPAR
     * @date 2024-09-24
     * @version 1.0.0 
     * 
     * @description Método que gera um UUID (Universally Unique Identifier) v4.
     * 
     * @returns 
     */
    static generateUuid(): string {
        // Retorna uma String com 36 posições = '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'.
        return uuidv4();
    }

    /**
     * @author GASPAR
     * @date 2024-09-24
     * @version 1.0.0 
     * 
     * @description Método que gera um UUID (Universally Unique Identifier) v4, com uma sequencia de zeros.
     * 
     * @returns 
     */
    static generateUuidNil(): string {
        // Retorna uma String com 36 posições = '00000000-0000-0000-0000-000000000000'.
        return NIL_UUID;
    }

    /**
     * @author GASPAR
     * @date 2024-10-064
     * @version 1.0.0 
     * 
     * @description Método que gera uma string aleatória.
     * 
     * @param length 
     * @param type 
     * @returns 
     */
    static generateRandomString(length: number): string {
        let result = '';
        let characters = '';

        characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    /**
     * @author GASPAR
     * @date 2024-10-064
     * @version 1.0.0 
     * 
     * @description Método que gera uma string aleatória.
     * 
     * @param length 
     * @param type 
     * @returns 
     */
    static generateRandomNumber(length: number): string {
        let result = '';
        let characters = '';

        characters = '0123456789';

        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    /**
     * @author GASPAR
     * @date 2024-09-17
     * @version 1.0.0 
     * 
     * @description Método que retorna os erros com o nome do campo e o Tipo de Erro.
     * 
     * @param xForm 
     * @param xFunctionGetFieldName 
     * @returns 
     */
    static handlerFormFieldsErrors(xForm: FormGroup | FormArray, xFunctionGetFieldName: any): any {
        const errosFormRequest = this._getFormFieldsErrors(xForm);
        const fieldsErros: any = [];

        let tmpData = '- campos não identificados.';

        errosFormRequest.forEach((xItem: any) => {
            fieldsErros.push(xFunctionGetFieldName(xItem.controlName));
        });

        // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
        if (Array.isArray(fieldsErros)) {
            tmpData = fieldsErros.join('\n - ');
        }

        return tmpData;
    }

    /**
     * @author GASPAR
     * @date 2024-09-12
     * @version 1.0.0 
     * 
     * @description Método que gera uma cor hexadecimal aleatória.
     * 
     * @returns {string} Retorna uma cor hexadecimal aleatória.
     */
    static generateHexColor(): string {
        const tmpLetters = "0123456789ABCDEF";
        let tmpColor = '#';

        for (let i = 0; i < 6; i++) {
            tmpColor += tmpLetters[(Math.floor(Math.random() * 16))];
        }

        return tmpColor;
    }

    /**
     * 
     * @author GASPAR
     * @date 2024-09-22
     * @version 1.0.0
     * 
     * @description 
     *   - Método que recebe uma Matrícula de Aeronave e retorna o código do país com 2 letras.
     * 
     * @param register 
     * @returns 
     */
    static getCountryCodeFromRegister(register: string): string {
        // Tenta encontrar o prefixo mais longo possível
        for (let i = 4; i > 0; i--) {
            const prefix = register.substring(0, i).toUpperCase();

            if (this.icaoCountryCodes[prefix]) {
                return this.icaoCountryCodes[prefix];
            }
        }
        // Retorna uma string vazia se não encontrado
        return '';
    }



    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description Método que converte o combustível de uma unidade para outra.
     * 
     * @param fuel 
     * @param currentUnit 
     * @param desiredUnit 
     * @param fuelType 
     * @returns 
     */
    static convertFuelSpecific(xFuel: number, xCurrentUnit: string, xDesiredUnit: string, xFuelType: 'JetA' | 'Avgas'): number {
        let fuelInL: number;

        // Densidades em kg/L
        const fuelDensities = {
            JetA: 0.8,
            Avgas: 0.72
        };

        // Verificar se o tipo de combustível é suportado
        if (!fuelDensities[xFuelType]) {
            throw new Error('Tipo de combustível não suportado');
        }

        // Convert current fuel to liters
        switch (xCurrentUnit) {
            case 'l':
                fuelInL = xFuel;
                break;
            case 'gal':
                fuelInL = xFuel * 3.78541;
                break;
            case 'kg':
                fuelInL = xFuel / fuelDensities[xFuelType];
                break;
            case 'lb':
                fuelInL = (xFuel / 2.20462) / fuelDensities[xFuelType];
                break;
            default:
                throw new Error('Unidade de combustível atual não suportada');
        }

        let convertedFuel: number;

        // Convert liters to desired unit
        switch (xDesiredUnit) {
            case 'l':
                convertedFuel = fuelInL;
                break;
            case 'gal':
                convertedFuel = fuelInL / 3.78541;
                break;
            case 'kg':
                convertedFuel = fuelInL * fuelDensities[xFuelType];
                break;
            case 'lb':
                convertedFuel = fuelInL * fuelDensities[xFuelType] * 2.20462;
                break;
            default:
                throw new Error('Unidade de combustível desejada não suportada');
        }

        return parseFloat(convertedFuel.toFixed(2));
    }

    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description Método que converte um número no formato americano (1,000.00) para o formato brasileiro (1.000,00).
     * 
     * @param usNumber 
     * @returns 
     */
    static convertNumberToPtBr(usNumber: string | number): string {
        // Convert number to string if necessary
        const usNumberStr = typeof usNumber === 'number' ? usNumber.toString() : usNumber;

        // Remove commas (thousand separators)
        const numberWithoutCommas = usNumberStr.replace(/,/g, '');

        // Replace dot (decimal separator) with comma
        const numberWithComma = numberWithoutCommas.replace(/\./g, ',');

        // Add thousand separators (dots) in pt-BR format
        const parts = numberWithComma.split(',');
        const integerPart = parts[0];
        const decimalPart = parts.length > 1 ? ',' + parts[1] : '';

        let ptBrNumber = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Append decimal part if it exists
        if (decimalPart) {
            ptBrNumber += decimalPart;
        }

        return ptBrNumber;
    }

    /**
     * @status:
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description 
     *   - Método que converte um horário hh:mm em minutos.
     * 
     * @param xTime 
     * @returns 
     */
    static convertTimeToMinutes(xTime: string): number {
        const [hours, minutes] = xTime.split(':').map(Number);

        return (hours * 60) + minutes;
    }
    //#endregion

    //#region "|--- PRIVATE METHODS ---|"
    /**
     * 
     * @author GASPAR
     * @date 2024-09-17
     * @version 1.0.0
     * 
     * @description 
     *   - Dicionário de códigos de países ICAO.
     */
    private static icaoCountryCodes: { [key: string]: string } = {
        // ISO 3166-1 alpha2
        // https://www.dadosmundiais.com/codigos-de-pais.php#google_vignette
        // América do Norte
        'N': 'US', // Estados Unidos
        'C': 'CA', // Canadá
        'XA': 'MX', // México
        'XB': 'MX', // México
        'XC': 'MX', // México

        // América Central
        'HP': 'PA', // Panamá
        'TI': 'CR', // Costa Rica
        'YS': 'SV', // El Salvador
        'HR': 'HN', // Honduras
        'TG': 'GT', // Guatemala
        'YN': 'NI', // Nicarágua
        'V3': 'BZ', // Belize

        // Caribe
        '8P': 'BB', // Barbados
        'VP-B': 'BM', // Bermudas
        'J8': 'VC', // São Vicente e Granadinas
        'VP-V': 'VC', // São Vicente e Granadinas
        'V2': 'AG', // Antígua e Barbuda
        'PJ': 'AN', // Antilhas Neerlandesas
        'HZ': 'AN', // Antilhas Neerlandesas
        'HI': 'DO', // República Dominicana
        '9Y': 'TT', // Trinidad e Tobago

        // América do Sul
        'LV': 'AR', // Argentina
        'CP': 'BO', // Bolívia
        'PP': 'BR', // Brasil
        'PR': 'BR', // Brasil
        'PT': 'BR', // Brasil
        'PU': 'BR', // Brasil
        'PS': 'BR', // Brasil
        'CC': 'CL', // Chile
        'HK': 'CO', // Colômbia
        'HC': 'EC', // Equador
        'OB': 'PE', // Peru
        'YV': 'VE', // Venezuela
        'CX': 'UY', // Uruguai
        'ZP': 'PY', // Paraguai

        // Oceania
        'VH': 'AU', // Austrália
        'ZK': 'NZ', // Nova Zelândia
    };

    /**
     * @author GASPAR
     * @date 2024-09-17
     * @version 1.0.0
     * 
     * @description Método que retorna os erros com o nome do campo e o Tipo de Erro.
     * 
     * @param xForm 
     * @param xParentField 
     * @returns 
     */
    private static _getFormFieldsErrors(xForm: FormGroup | FormArray, xParentField = ""): any[] {
        let errorsForm: any[] = [];
        let tempFieldName = "";

        Object.keys(xForm.controls).forEach(field => {
            const control = xForm.get(field);

            tempFieldName = `${xParentField}${field}`

            if (control instanceof FormGroup || control instanceof FormArray) {
                errorsForm = errorsForm.concat(this._getFormFieldsErrors(control, `${tempFieldName}.`)); //FAZ RECURSIVIDADES, CASO SEJA UM ARRAY DE CONTROLES.

                return;
            } else {
                // FORCAR OS CAMPOS A MOSTRAREM OS ERROS: TOUCHED & DIRTY:
                if (control) {
                    control.markAsTouched();
                    control.markAsDirty();
                }
            }

            const controlErrors = control ? control.errors as ValidationErrors | null : null;

            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errorsForm.push({
                        controlName: tempFieldName,
                        errorName: keyError,
                        errorValue: controlErrors[keyError]
                    });
                });
            }
        });

        // Remove os Itens Duplicados.
        errorsForm = errorsForm.filter((xValue: any, xIndex: any, xSelf: any) => xSelf.findIndex((t: any) => {
            return t.controlName === xValue.controlName;
        }) === xIndex);

        return errorsForm;

    }
    //#endregion
}




