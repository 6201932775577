<div class="cls-ui-primeng-field cls-ui-primeng-field-dropdown">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.label}}
        </label>
        <p-dropdown 
            [formControl]="this.control"
            [ngClass]="{ 'is-danger': this.showErrors() }"
            [options]="this.arrayOptions" 
            [optionLabel]="getPropertyLabel()"
            [optionValue]="getPropertyValue()"
            [autoDisplayFirst]="true" 
            [showClear]="true"   
            [appendTo]="getAppendTo()" 
            id="{{ this.idField }}"
            name="{{ this.getFieldName() }}" 
            placeholder="{{ this.placeHolderContent }}">
        </p-dropdown>
    </div>    
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="dropdown"></lib-ui-primeng-handler-error>
</div>

