//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, UntypedFormControl } from '@angular/forms';

// ***** PRIMENG ***** //
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsBasic } from '../../../../_library/interfaces/IDropDownOptionsBasic';
//#endregion

//#region "|--- IMPORT DOMAINS ---|"
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAddressType } from '../../../../_library/static_options/staticOptionsAddressType';
import { staticOptionsBrStates } from '../../../../_library/static_options/staticOptionsBrStates';
import { staticOptionsAllCountries } from '../../../../_library/static_options/staticOptionsAllCountries';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../_library/definitions/ButtonAction';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-addresses-component',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    PrimengComponentsModule,
  ],
  templateUrl: './addresses-component.component.html'
})
export class AddressesComponentComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  //#endregion

  //region "|--- PROPERTIES ---|"
  public currentFormAddress!: any;
  public buttonActions!: any;

  public optionsNationalityCountries!: IDropDownOptionsCountry[];
  public optionsAddressType!: IDropDownOptionsBasic[];
  public optionsBrStates!: IDropDownOptionsBasic[];

  public bolShowDialog!: any;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;
    this.bolShowDialog = false;

    this.currentFormAddress = null;

    this.optionsAddressType = staticOptionsAddressType;
    this.optionsBrStates = staticOptionsBrStates;
    this.optionsNationalityCountries = staticOptionsAllCountries;
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de endereço.
   */
  private _initFormAddress() {
    return new FormGroup({
      address_id: new UntypedFormControl(null),
      address_type: new UntypedFormControl(null),
      address: new UntypedFormControl(null),
      number: new UntypedFormControl(null),
      complement: new UntypedFormControl(null),
      district: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      state: new UntypedFormControl(null),
      country: new FormControl<string>('BR'),
      zip_code: new UntypedFormControl(null),
      address_remarks: new UntypedFormControl(null)
    });
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões da Tabela.
   * 
   * @param action 
   */
  callbackTableAddressClickButton(xAction: string): void {
    if (xAction == BUTTON_ACTION.NEW) {
      console.log("NOVO ENDEREÇO");
      this.currentFormAddress = this._initFormAddress();
      this.bolShowDialog = true;
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões da Tabela.
   * 
   * @param xAction 
   * @param xItem
   */
  callbackItemClickButton(xAction: string, xItem: string): void {
    if (xAction == BUTTON_ACTION.EDIT) {
      console.log("EDITAR ENDEREÇO");

      this.currentFormAddress = this.formComponent.controls.find((x: any) => x.controls.address_id.value == xItem);
      
      this.bolShowDialog = true;
    } else if (xAction == BUTTON_ACTION.DELETE) {
      console.log("DELETAR ENDEREÇO");

      const xIndex = this.formComponent.controls.findIndex((x: any) => x.controls.address_id.value == xItem);

      this.formComponent.controls.splice(xIndex, 1);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Handler as ações dos Botões do Painel.
   * 
   * @param xAction 
   */
  callbackDialogClickButton(xAction: string): void {
    if (xAction == BUTTON_ACTION.SAVE) {
      console.log("SALVAR NOVO ENDEREÇO NA TABELA")
      this.currentFormAddress.controls.address_id.setValue(LocalMethodsHandlerClass.generateRandomString(6));

      this.formComponent.controls.push(this.currentFormAddress);

      this.currentFormAddress = null;
      this.bolShowDialog = false;
    } else if (xAction == BUTTON_ACTION.UPDATE) {
      console.log("ATUALIZAR ENDEREÇO NA TABELA")
      // Substituir o endereço que esta dentro do array cujo o id é igual ao id do endereço atual
      const xIndex = this.formComponent.controls.findIndex((x: any) => x.controls.address_id.value == this.currentFormAddress.controls.address_id.value);
     
      this.formComponent.controls[xIndex] = this.currentFormAddress;

      this.currentFormAddress = null;
      this.bolShowDialog = false;
    } else if (xAction == BUTTON_ACTION.EXIT) {
      console.log("SAIR DO DIALOG")
      this.bolShowDialog = false;
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do País.
   */
  getCountryName(xCountryCode: string): string {
    return this.optionsNationalityCountries.find((x) => x.value == xCountryCode)?.label || '';
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do Estado.
   * 
   * @param xStateCode 
   * @returns 
   */
  getStateName(xStateCode: string): string {
    return this.optionsBrStates.find((x) => x.value == xStateCode)?.label || xStateCode;
  }

  /**
   * @author GASPAR
   * @date 2024-10-12
   * @version 1.0.0
   * 
   * @description Retorna o nome do Tipo de Endereço.
   * 
   * @param xAddressType 
   * @returns 
   */
  getAddressTypeName(xAddressType: string): string {
    return this.optionsAddressType.find((x) => x.value == xAddressType)?.label || xAddressType;
  }
  //#endregion
}
