<div class="cls-ui-primeng-field cls-ui-primeng-field-only-label">
    <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold }" >
        {{this.label}}
        <ng-container *ngIf="this.showToolTipIcon">
            <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="500" tooltipPosition="bottom"
                tooltipStyleClass="cls-ui-primeng-field-tooltip-basic" class="fa-solid fa-circle-info ml-1"></i>
        </ng-container>
    </label>
    <ng-template #tooltipContent>
        <i class="fa-solid fa-lightbulb"></i>
        <span [innerHTML]="getTooTipContent()"></span>        
    </ng-template>
</div>

