<section data-page="app-platform-page">
    <sunrise-app-platform--toast-message></sunrise-app-platform--toast-message>
    <sunrise-app-platform--title-page [titlePage]="this.objPageTitle"></sunrise-app-platform--title-page>
    <div class="cls-page-form">
        <div class="grid">
            <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
               
            </div>
            <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
               
            </div>
        </div>
    </div>
</section>
