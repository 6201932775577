//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../_services/_app-locale/app-locale.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModulePaxService {
  //#region "|--- PROPERTIES---|" 
  urlPaxModuleService: string;
  //#endregion

  constructor(
    private _appLocaleService: AppLocaleService,
    private _http: HttpClient
  ) {
    if (settingConfig.API_PAX_PORT == "443") {
      this.urlPaxModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlPaxModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_PAX_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- PAX DATA ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por SALVAR os dados relacionados ao módulo: PAX DATA.
   * 
   * @param xPaxDataForm
   * @returns 
   */
  public saveFormPaxData(xPaxDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.post<any>(`${this.urlPaxModuleService}/${settingConfig.API_PAX_URL_SEGMENT}/${tempLocale}/pax/data`, xPaxDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xPaxDataForm))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR TODOS os dados relacionados ao módulo: PAX DATA.
   * 
   * @returns 
   */
  public getAllPaxDataList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlPaxModuleService}/${settingConfig.API_PAX_URL_SEGMENT}/${tempLocale}/pax/data/list`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por RECUPERAR BY ID os dados relacionados ao módulo: PAX DATA.
   * 
   * @param xPaxDataId
   * @returns 
   */
  public getPaxDataById(xPaxDataId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlPaxModuleService}/${settingConfig.API_PAX_URL_SEGMENT}/${tempLocale}/pax/data/${xPaxDataId}`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por ATUALIZAR os dados relacionados ao módulo: PAX DATA.
   * 
   * @param xPaxDataForm
   * @param xPaxDataId
   * @returns
   */
  public updateFormPaxData(xPaxDataId: string, xPaxDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.put<any>(`${this.urlPaxModuleService}/${settingConfig.API_PAX_URL_SEGMENT}/${tempLocale}/pax/data/${xPaxDataId}`, xPaxDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xPaxDataForm))
      )
  }

  /**
   * @status:
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por DELETAR os dados relacionados ao módulo: PAX DATA.
   * 
   * @param xPaxDataId
   * @returns 
   */
  public deletePaxDataById(xPaxDataId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.delete<any>(`${this.urlPaxModuleService}/${settingConfig.API_PAX_URL_SEGMENT}/${tempLocale}/pax/data/${xPaxDataId}`, { headers })
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Método responsável por Verificar se existe o Documento do Tipo e Numero.
   * 
   * @param xCrewDataId
   * @returns 
   */
  public checkIfHasDocumentValue(xDocumentType: string, xFieldValue: string): Observable<any> {
    const tempLocale = this._appLocaleService.getLocale();

    return this._http.get<any>(`${this.urlPaxModuleService}/${settingConfig.API_PAX_URL_SEGMENT}/${tempLocale}/pax/data/document/${xDocumentType}/${xFieldValue}`)
      .pipe(
        catchError((xError) => _handleError(xError, null))
      )
  }
  //#endregion
}