//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, FormArray, Validators } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig'

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass'
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IFkUserList } from '../../../../_library/interfaces/IFkUserList';
//#endregion

/**
 * @status:
 * @author GASPAR
 * @date 2024-12-14
 * @version 1.0.0
 * 
 * @description:
 *     - Esta função Inicializa o Formulário AccountDataForm, retorna um objeto FormGroup com os campos do formulário.
 * 
 * @returns 
 */
export function initAccountDataForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        account_data_id: [null],
        account_name: [
            "helisul",
            [
                Validators.required, 
                //Validators.minLength(3), 
                //Validators.maxLength(30),
                //Validators.pattern(/^[a-zA-Z][a-zA-Z0-9#@._-]*$/)
            ]
        ],
        account_email: ["helisul@helisul.com.br"],
        account_phone: ["+5541991866452"],
        user_owner_data: objFormBuilder.group({
            password_text: ["123"],
            confirm_password: ["123"],
            must_change_days: [null],
        }),
        phone_data: objFormBuilder.group({
            type: ["mobile"],
            ddi: ["+55"],
            ddd: ["41"],
            number: ["99186452"],
            normalized: ["+554199186452"],
            masked: ["+55 (41) 99186-452"],
            is_whatsapp: [true],
            is_telegram: [true]
        }),
        status: ["waiting_validation"],
        created_date_utc: [null],
        start_date_utc: [null],
        end_date_utc: ["31/12/2024"],
        avatar_image: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_logo_large: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_logo_medium: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_logo_small: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_color: [LocalMethodsHandlerClass.generateHexColor()],
        account_remark: ["TESTANDO CONTA"],
        profile_data: objFormBuilder.group({
            profile_type: ["company"],
            country: ["BR"],
            first_name: ["Osvaldo"],
            last_name: ["Gaspar Jr."],
            full_name: ["Helisul Helicopteros"],
            birth_date_utc: ["01/01/1990"],
            gender: ["not_informed"],
            document_type: ["cpf"],
            document_in: ["12345678901"],
            addresses: objFormBuilder.array([]),
            contacts: objFormBuilder.array([])
        }),
        agent_data: objFormBuilder.group({
            first_name: ["Osvaldo"],
            last_name: ["Gaspar Jr."],
            full_name: ["João Silva"],
            email: ["joao@pop.com"],
            phone_data: objFormBuilder.group({
                type: ["mobile"],
                ddi: ["+55"],
                ddd: ["41"],
                number: ["99186452"],
                normalized: [null],
                masked: [null],
                is_whatsapp: [true],
                is_telegram: [true]
            })
        }),
        fk_users_list: objFormBuilder.array([] as IFkUserList[]),
        fk_users_owner_list: objFormBuilder.array([] as IFkUserList[]),
        fk_users_proxy_list: objFormBuilder.array([] as IFkUserList[]),
        tags: [[]]
    });
}