//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ***** RXJS ***** //
import { Observable } from 'rxjs';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //#region "|--- PROPERTIES---|" 
  urlAuthenticationService: string;
  //#endregion

  constructor(private _http: HttpClient) {
    if (settingConfig.API_CREW_PORT == "443") {
      this.urlAuthenticationService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlAuthenticationService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AUTHENTICATION_PORT}/${settingConfig.API_PATH}`;
    }
  }

  login(credentials: { username?: string; email?: string; password: string }): Observable<any> {
    return this._http.post(`${this.urlAuthenticationService}/login`, credentials);
  }

  saveToken(token: string): void {
    localStorage.setItem('authToken', token);
  }

  getToken(): string | null {
    return localStorage.getItem('authToken');
  }

  logout(): void {
    localStorage.removeItem('authToken');
  }  
}
