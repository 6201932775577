//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description Opções estáticas para unidades de combustível.
 */
export const staticOptionsCgFuelUnits: IDropDownOptionsBasic[] = [
    { label: 'l   - LITROS', value: 'l' },
    { label: 'gal - GALÕES', value: 'gal' },
    { label: 'kg  - QUILOGRAMAS', value: 'kg' },
    { label: 'lb  - LIBRAS', value: 'lb' }
]