<section class="cls-component-tab-data">
     <sunrise-dialog-form-owner-operator [showDialog]="this.showDialogOwnerOperator"
          [formComponent]="this.formOwnerOperator"
          (actionDialogOwnerOperatorForm)="callbackActionDialogOwnerOperatorForm($event)" typeDialog="this.typeDialog">
     </sunrise-dialog-form-owner-operator>
     <p-toolbar class="cls-component-toolbar">
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-center"></div>
          <div class="p-toolbar-group-end">
               <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value !== null">
                    <p-button label="Novo" styleClass="cls-component-button" [rounded]="true" icon="fa-solid fa-plus"
                         severity="secondary" class="mr-2" (click)="callbackClickButton(this.buttonActions.NEW)" />
                    <p-button label="Salvar Modificações" styleClass="cls-component-button" [rounded]="true"
                         icon="fa-solid fa-save" severity="secondary" class="mr-2"
                         (click)="callbackClickButton(this.buttonActions.UPDATE)" />
               </ng-container>
               <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value === null">
                    <p-button label="Salvar Novo" styleClass="cls-component-button" [rounded]="true"
                         icon="fa-solid fa-save" severity="secondary" class="mr-2"
                         (click)="callbackClickButton(this.buttonActions.SAVE)" />
               </ng-container>
               <p-button label="Sair" styleClass="cls-component-button" [rounded]="true"
                    icon="fa-solid fa-right-from-bracket" severity="danger"
                    (click)="callbackClickButton(this.buttonActions.EXIT)" />
          </div>
     </p-toolbar>
     <div class="grid">
          <div class="col-12 md:col-12 lg:col-12 xl:col-12">
               <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span>Registro Aeronáutico</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div class="cls-component-form cls-component-form-frame">
                              <div class="formgrid">
                                   <div class="grid">
                                        <ng-container
                                             *ngIf="this.formComponent.controls.aircraft_data_id.value === null">
                                             <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                  <lib-ui-primeng-input-text label="* Matricula #BOLD#"
                                                       [control]="this.formComponent.controls.registration_data.controls.mark"
                                                       [formComponent]="this.formComponent">
                                                  </lib-ui-primeng-input-text>
                                             </div>
                                        </ng-container>
                                        <ng-container
                                             *ngIf="this.formComponent.controls.aircraft_data_id.value !== null">
                                             <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                  <lib-ui-primeng-show-input-data label="Matricula #BOLD#"
                                                       [dataInput]="this.classModuleMethods.setAircraftMark(this.formComponent.controls.registration_data.controls.mark.value)">
                                                  </lib-ui-primeng-show-input-data>
                                             </div>
                                        </ng-container>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-drop-down-flag label="País de Registro #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.country"
                                                  [arrayOptions]="this.optionsAircraftCountries">
                                             </lib-ui-primeng-drop-down-flag>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-drop-down-icon label="Tipo de Aeronave #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.aircraft_type"
                                                  [arrayOptions]="this.optionsAircraftTypes">
                                             </lib-ui-primeng-drop-down-icon>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-drop-down-icon label="Regras de Voo #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.flight_rule"
                                                  [arrayOptions]="this.optionsAircraftRules">
                                             </lib-ui-primeng-drop-down-icon>
                                        </div>
                                   </div>
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="Fabricante #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.manufacturer">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="Modelo #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.model">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="Código ICAO #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.icao_code">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="Número Serial #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.serial_number">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                   </div>
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="Ano Fabricação #BOLD#"
                                                  [control]="this.formComponent.controls.registration_data.controls.year_manufactured">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="FIN"
                                                  [control]="this.formComponent.controls.registration_data.controls.fin_number">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="Nome Aeronave"
                                                  [control]="this.formComponent.controls.registration_data.controls.aircraft_name">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                             <lib-ui-primeng-input-text label="Observação"
                                                  [control]="this.formComponent.controls.registration_data.controls.registration_data_remarks">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
               </p-panel>
          </div>
     </div>
     <div class="grid">
          <div class="field col-12 md:col-12 lg:col-12 xl:col-6">
               <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span> Proprietário da Aeronave</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <p-table [value]="this.formComponent.controls.owner_operator_data.value"
                              [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5"
                              [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
                              currentPageReportTemplate="Página {first} de {last}." [breakpoint]="'768px'"
                              responsiveLayout="stack" class="cls-component-table-panel-list">
                              <ng-template pTemplate="caption">
                                   <div class="cls-component-table-panel-list-caption">
                                        <div class="cls-component-table-panel-list-caption-left">
                                             Total:
                                             {{this.formComponent.controls.owner_operator_data.value ? this.getLengthOwnerOrBoth(this.formComponent.controls.owner_operator_data.value) : 0 }}
                                        </div>
                                        <div class="cls-component-table-panel-list-caption-right">
                                             <div>
                                                  <p-button *ngIf="false" styleClass="cls-component-button" label="NOVO"
                                                       icon="fa-solid fa-plus" severity="secondary"
                                                       (click)="callbackTableOwnerClickButton(this.buttonActions.NEW)" />
                                             </div>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="header">
                                   <tr>
                                        <th style="width:24%; text-align: center">CPF/CNPJ</th>
                                        <th style="width:58%">NOME</th>
                                        <th style="width:18%;"></th>
                                   </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                   <ng-container
                                        *ngIf="itemArray.type_owner_operator === 'owner' || itemArray.type_owner_operator === 'both'">
                                        <tr>
                                             <td style="text-align: center">{{ itemArray.ni }}</td>
                                             <td>{{ itemArray.name }}</td>
                                             <td>

                                             </td>
                                        </tr>
                                   </ng-container>
                              </ng-template>
                         </p-table>
                    </ng-template>
               </p-panel>
          </div>
          <div class="field col-12 md:col-12 lg:col-12 xl:col-6">
               <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span> Operador da Aeronave</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <p-table [value]="this.formComponent.controls.owner_operator_data.value"
                              [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5"
                              [rowsPerPageOptions]="[5, 10, 20]" [showCurrentPageReport]="true"
                              currentPageReportTemplate="Página {first} de {last}." [breakpoint]="'768px'"
                              responsiveLayout="stack" class="cls-component-table-panel-list">
                              <ng-template pTemplate="caption">
                                   <div class="cls-component-table-panel-list-caption">
                                        <div class="cls-component-table-panel-list-caption-left">
                                             Total:
                                             {{this.formComponent.controls.owner_operator_data.value ? this.getLengthOperatorOrBoth(this.formComponent.controls.owner_operator_data.value) : 0 }}
                                        </div>
                                        <div class="cls-component-table-panel-list-caption-right">
                                             <div>
                                                  <p-button *ngIf="false" styleClass="cls-component-button" label="NOVO"
                                                       icon="fa-solid fa-plus" severity="secondary"
                                                       (click)="callbackTableOwnerClickButton(this.buttonActions.NEW)" />
                                             </div>
                                        </div>
                                   </div>
                              </ng-template>
                              <ng-template pTemplate="header">
                                   <tr>
                                        <th style="width:24%; text-align: center">CPF/CNPJ</th>
                                        <th style="width:58%">NOME</th>
                                        <th style="width:18%;"></th>
                                   </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                   <ng-container
                                        *ngIf="itemArray.type_owner_operator === 'operator' || itemArray.type_owner_operator === 'both'">
                                        <tr>
                                             <td style="text-align: center">{{ itemArray.ni }}</td>
                                             <td>{{ itemArray.name }}</td>
                                             <td>

                                             </td>
                                        </tr>
                                   </ng-container>
                              </ng-template>
                         </p-table>
                    </ng-template>
               </p-panel>
          </div>
     </div>
</section>