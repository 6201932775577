//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initWeightBalanceDataForm } from '../../form-init/weight-balance-data-form';

// ***** COMPONENT ***** //
import { AircraftViewPerspectiveComponent } from '../../../../_components/svg/aircraft-view-perspective/aircraft-view-perspective.component';
import { ChartCGLimitsComponent } from '../../../../_components/chart/chart-cg-limits/chart-cg-limits.component';
import { InputColorComponent } from '../../../../../_library/layouts/components/primeng/form/input-color/input-color.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT INTERFACES---|"
import { IChartCGLimitations } from '../../../../../_library/interfaces/IChartCGLimitations';
import { ICgComponents } from '../../../../../_library/interfaces/ICgComponents';
import { IDropDownOptionsBasic } from '../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IPlotagemChart } from '../../../../../_library/interfaces/IPlotagemChart';
//#endregion

//#region "|--- IMPORT OPTIONS ---|"
import { staticOptionsCgFuelUnits } from '../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsCgArmUnits } from '../../../../../_library/static_options/staticOptionsCgArmUnits';
import { staticOptionsCgWeightUnits } from '../../../../../_library/static_options/staticOptionsCgWeightUnits';
import { staticOptionsAircraftTypes } from '../../../../../_library/static_options/staticOptionsAircraftTypes';
import { staticOptionsChartScales } from '../../../../../_library/static_options/staticOptionsChartScales';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleWeightBalanceService } from '../../../../_services/module-weight-balance/module-weight-balance.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../_library/definitions/ButtonAction';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-weight-balance-form',
  standalone: true,
  imports: [
    AircraftViewPerspectiveComponent,
    ChartCGLimitsComponent,
    CommonModule,
    FormsModule,
    InputColorComponent,
    PrimengComponentsModule,
    ReactiveFormsModule,
    TitlePageComponent,
    ToastMessageComponent
  ],
  templateUrl: './weight-balance-form.component.html'
})
export class WeightBalanceFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  buttonActions!: any;

  objPageTitle!: IPageTitle;

  // ***** ARRAY ***** //
  plotChartData!: IPlotagemChart[];
  aircraftCgComponents!: ICgComponents[];
  fixCgComponents!: ICgComponents[];
  variableCgComponents!: ICgComponents[];
  externCgComponents!: ICgComponents[];

  // ***** CHART ***** //
  objChartCGLongitudinal!: IChartCGLimitations;
  objChartCGLateral!: IChartCGLimitations;

  // ***** DROP-DOWN OPTIONS ***** //
  optionsCgFuelUnits!: IDropDownOptionsBasic[];
  optionsCgArmUnits!: IDropDownOptionsBasic[];
  optionsCgWeightUnits!: IDropDownOptionsBasic[];
  optionsAircraftTypes!: IDropDownOptionsIcon[];
  optionsChartScales!: IDropDownOptionsBasic[];

  // ***** FORMS ***** //
  formWeightBalance: any

  jsonObject: any = {};
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleWeightBalanceApisService: ModuleWeightBalanceService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initWeightBalanceTemplateForm();
    //this._initFormWeightBalanceEvents();
    this._initObjChartCG(); // Tem que deixar por ultimo
  }

  //#region "|--- EVENT HANDLERS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-19
   * @version 1.0.0
   * 
   * @description Inicializa os eventos de mudança de valores do formulário.
   */
  private _initFormWeightBalanceEvents(): void {
    this.formWeightBalance.valueChanges.subscribe((values: any) => {
      this.jsonObject = { ...values };

      this.objChartCGLongitudinal = {
          chart_data_id: LocalMethodsHandlerClass.generateUuid(),
          chart_type: 'longitudinal',
          background_image: 'assets/images/aircraft/longitudinal_cg_limits.png',
          show_grid: true,
          x_min: this.jsonObject.chart_longitudinal_data.x_min,
          x_max: this.jsonObject.chart_longitudinal_data.x_max,
          x_step_axis: this.jsonObject.chart_longitudinal_data.x_step_axis,
          x_step_grid: this.jsonObject.chart_longitudinal_data.x_step_grid,
          x_scale: this.jsonObject.chart_longitudinal_data.x_scale,
          x_unit: this.jsonObject.chart_longitudinal_data.x_unit,
          x_show_opposite_unit: this.jsonObject.chart_longitudinal_data.x_show_opposite_unit,
          y_min: this.jsonObject.chart_longitudinal_data.y_min,
          y_max: this.jsonObject.chart_longitudinal_data.y_max,
          y_step_axis: this.jsonObject.chart_longitudinal_data.y_step_axis,
          y_step_grid: this.jsonObject.chart_longitudinal_data.y_step_grid,
          y_scale: this.jsonObject.chart_longitudinal_data.y_scale,
          y_unit: this.jsonObject.chart_longitudinal_data.y_unit,
          y_show_opposite_unit: this.jsonObject.chart_longitudinal_data.y_show_opposite_unit,
          limits_data: []
      }

      this.objChartCGLateral = {
          chart_data_id: LocalMethodsHandlerClass.generateUuid(),
          chart_type: 'lateral',
          background_image: 'assets/images/aircraft/longitudinal_cg_limits.png',
          show_grid: true,
          x_min: this.jsonObject.chart_lateral_data.x_min,
          x_max: this.jsonObject.chart_lateral_data.x_max,
          x_step_axis: this.jsonObject.chart_lateral_data.x_step_axis,
          x_step_grid: this.jsonObject.chart_lateral_data.x_step_grid,
          x_scale: this.jsonObject.chart_lateral_data.x_scale,
          x_unit: this.jsonObject.chart_lateral_data.x_unit,
          x_show_opposite_unit: this.jsonObject.chart_lateral_data.x_show_opposite_unit,
          y_min: this.jsonObject.chart_lateral_data.y_min,
          y_max: this.jsonObject.chart_lateral_data.y_max,
          y_step_axis: this.jsonObject.chart_lateral_data.y_step_axis,
          y_step_grid: this.jsonObject.chart_lateral_data.y_step_grid,
          y_scale: this.jsonObject.chart_lateral_data.y_scale,
          y_unit: this.jsonObject.chart_lateral_data.y_unit,
          y_show_opposite_unit: this.jsonObject.chart_lateral_data.y_show_opposite_unit,
          limits_data: []        
      }

      console.log(this.jsonObject.chart_longitudinal_data);
    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Peso e Balanceamento.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;

    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;
    this.optionsCgArmUnits = staticOptionsCgArmUnits;
    this.optionsCgWeightUnits = staticOptionsCgWeightUnits;
    this.optionsAircraftTypes = staticOptionsAircraftTypes;
    this.optionsChartScales = staticOptionsChartScales;

    this.objPageTitle = {
      title: 'Peso & Balanceamento',
      subtitle: 'Definindo Fichas de Peso e Balanceamento da Aeronave',
      icon: 'fa-solid fa-balance-scale'
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-16
   * @version 1.0.0
   * 
   * @description Inicializa os objetos de gráficos de Limitações de CG.
   */
  private _initObjChartCG() {
    this.objChartCGLongitudinal = {
      chart_data_id: LocalMethodsHandlerClass.generateUuid(),
      chart_type: 'longitudinal',
      background_image: 'assets/images/aircraft/lateral_cg_limits.png',
      show_grid: true,
      x_min: this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min.value,
      x_max: this.formWeightBalance.controls.chart_longitudinal_data.controls.x_max.value,
      x_step_axis: this.formWeightBalance.controls.chart_longitudinal_data.controls.x_step_axis.value,
      x_step_grid: this.formWeightBalance.controls.chart_longitudinal_data.controls.x_step_grid.value,
      x_scale: this.formWeightBalance.controls.chart_longitudinal_data.controls.x_scale.value,
      x_unit: this.formWeightBalance.controls.unit_data.controls.arm.value,
      x_show_opposite_unit: this.formWeightBalance.controls.chart_longitudinal_data.controls.x_show_opposite_unit.value,
      y_min: this.formWeightBalance.controls.chart_longitudinal_data.controls.y_min.value,
      y_max: this.formWeightBalance.controls.chart_longitudinal_data.controls.y_max.value,
      y_step_axis: this.formWeightBalance.controls.chart_longitudinal_data.controls.y_step_axis.value,
      y_step_grid: this.formWeightBalance.controls.chart_longitudinal_data.controls.y_step_grid.value,
      y_scale: this.formWeightBalance.controls.chart_longitudinal_data.controls.y_scale.value,
      y_unit: this.formWeightBalance.controls.unit_data.controls.weight.value,
      y_show_opposite_unit: this.formWeightBalance.controls.chart_longitudinal_data.controls.y_show_opposite_unit.value,
      limits_data: []
    }

    this.objChartCGLateral = {
      chart_data_id: LocalMethodsHandlerClass.generateUuid(),
      chart_type: 'lateral',
      background_image: 'assets/images/aircraft/lateral_cg_limits.png',
      show_grid: true,
      x_min: this.formWeightBalance.controls.chart_lateral_data.controls.x_min.value,
      x_max: this.formWeightBalance.controls.chart_lateral_data.controls.x_max.value,
      x_step_axis: this.formWeightBalance.controls.chart_lateral_data.controls.x_step_axis.value,
      x_step_grid: this.formWeightBalance.controls.chart_lateral_data.controls.x_step_grid.value,
      x_scale: this.formWeightBalance.controls.chart_lateral_data.controls.x_scale.value,
      x_unit: this.formWeightBalance.controls.unit_data.controls.arm.value,
      x_show_opposite_unit: this.formWeightBalance.controls.chart_lateral_data.controls.x_show_opposite_unit.value,
      y_min: this.formWeightBalance.controls.chart_lateral_data.controls.y_min.value,
      y_max: this.formWeightBalance.controls.chart_lateral_data.controls.y_max.value,
      y_step_axis: this.formWeightBalance.controls.chart_lateral_data.controls.y_step_axis.value,
      y_step_grid: this.formWeightBalance.controls.chart_lateral_data.controls.y_step_grid.value,
      y_scale: this.formWeightBalance.controls.chart_lateral_data.controls.y_scale.value,
      y_unit: this.formWeightBalance.controls.unit_data.controls.weight.value,
      y_show_opposite_unit: this.formWeightBalance.controls.chart_lateral_data.controls.y_show_opposite_unit.value,
      limits_data: []
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Peso e Balanceamento.
   */
  private _initWeightBalanceTemplateForm(): void {
    this.formWeightBalance = initWeightBalanceDataForm("template");
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método que salva os dados de registro da aeronave.
   */
  private async _saveWeightBalance() {
    if (this.formWeightBalance.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formWeightBalance, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Peso & Balanceamento',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: 'toast-basic',
        life: 5000
      });
    } else {
      this._moduleWeightBalanceApisService.saveWeightBalanceSheet(this.formWeightBalance).subscribe({
        next: (xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.CREATED) {
            this.formWeightBalance.controls.weight_balance__id.setValue(xResponse.data[0].data[0].id);

            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Peso & Balanceamento',
              detail: `${xResponse.data[0].message}`,
              key: 'toast-basic',
              life: 5000
            });
          }
        },
        error: (xError: any) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Peso & Balanceamento',
            detail: `${xError.detail}`,
            key: 'toast-basic',
            life: 5000
          });
        }
      });
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  callbackClickButton(xButtonAction: string): void {
    if (xButtonAction === BUTTON_ACTION.NEW) {
      console.log("NOVO");
    } else if (xButtonAction === BUTTON_ACTION.SAVE) {
      console.log("SALVAR PESO E BALANCEAMENTO");
      this._saveWeightBalance();
    } else if (xButtonAction === BUTTON_ACTION.UPDATE) {
      console.log("UPDATE");
    } else if (xButtonAction === BUTTON_ACTION.EXIT) {
      console.log("SAIR");
    }
  }
  //#endregion
}
