//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';

// ***** PRIMENG ***** //
import { MenuItem } from 'primeng/api';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../../_services/_app-locale/app-locale.service';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-frame-login-cover',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    RouterModule
  ],
  templateUrl: './frame-login-cover.component.html'
})
export class FrameLoginCoverComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** PRIVATE ***** //
  private _platform!: string;
  private _frame!: string;

  // ***** PUBLIC ***** //
  public localeExtension!: string;

  // ***** MENU ***** //
  public arrInterItems: MenuItem[] | undefined;
  //#endregion

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DOCUMENT) private _document: Document,
    private _appLocaleService: AppLocaleService,
    private _activeRoute: ActivatedRoute,
  ) { }

  // @status: OK
  ngOnInit(): void {
    const localeUrl = this._activeRoute.snapshot.paramMap.get('locale'); // Recupera o valor do "locale" que foi passado na rota.
    const localeDefault = this._appLocaleService.getLocale() || this._locale; // Recupera o valor do "locale" que foi definido no serviço.

    if (localeUrl && localeUrl) {
      if (localeUrl !== localeDefault) { // Verifica se o "locale" que foi passado na rota é diferente do "locale" que foi definido no serviço.            
        this._changeLocale(localeUrl); // Altera o Locale Extension na URL.
      }

      this._locale = localeUrl; // Set um novo locale, o mesmo que foi salvo e o que está na URL.
    }

    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   * 
   */
  private _initVariables() {
    // Recupera o valor do "platform" que foi passado na rota.
    this._platform = this._activeRoute.snapshot.data['platform'];
    this._frame = this._activeRoute.snapshot.data['frame'];

    this.localeExtension = this._locale; // Recupera o valor do "locale" que foi definido no serviço.

    // Atribui os valores da "platform" e do "frame" que foi passado na rota para o atributo "data-frame" do body.
    document.body.setAttribute('data-platform', `${this._platform}-platform`);
    document.body.setAttribute('data-frame', `${this._platform}-platform-${this._frame}`);

    this.arrInterItems = [
      { label: 'Brasil', icon: 'br-BR' },
      { label: 'USA', icon: 'en-US' },
      { label: 'CHILE', icon: 'es-CL' },
      { label: 'ECUADOR', icon: 'es-EC' },
      { label: 'PERÚ', icon: 'es-PE' },
    ]
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Método que altera o Locale Extension na URL.
   * 
   * @param LocaleExtension: 'pt-BR', 'en-US', 'es-CL', 'es-PE', 'es-EC'
   */
  private _changeLocale(xLocaleExtension: string) {
    this._locale = xLocaleExtension; // Set um novo locale.
    this._appLocaleService.setLocale(xLocaleExtension.toLowerCase()); // Altera o Locale no serviço.

    this._document.location.href = `app/${xLocaleExtension}/login`.toLowerCase();
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @status: OK
  * @author GASPAR
  * @date 2024-12-16
  * @version 1.0.0
  * 
  * @description:
  *   - Método que recupera o idioma do usuário, quando é passado o padrão {language_id}-{locale_extension}.
  * 
  * @param xLocaleExtension: 'pt-BR', 'en-US', 'es-CL', 'es-PE', 'es-EC'
  */
  public getFlagLocale(xLocaleExtension: string) {
    if (xLocaleExtension && xLocaleExtension.length > 2) {
      return xLocaleExtension.split('-')[1].toLowerCase();
    } else {
      return "";
    }
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Método que é chamado quando o usuário clica em uma das bandeiras.
   *   - Define qual será {language_id}-{locale_extension} que será utilizado no programa.
   * 
   * @param xFlag 
   */
  public onClickInter(xLocaleExtension: string) {
    //https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    this.localeExtension = xLocaleExtension;
    let tempLanguageLocale: string;

    switch (xLocaleExtension) {
      case 'pt-BR': // pt-BR (Português - Brasil)
        tempLanguageLocale = 'pt-BR';
        break;
      case 'en-US': // en-US (Inglês - Estados Unidos)
        tempLanguageLocale = 'en-US';
        break;
      case 'es-CL': // es-CL (Espanhol - Chile)
        tempLanguageLocale = 'es-CL';
        break;
      case 'es-PE': // es-PE (Espanhol - Peru)
        tempLanguageLocale = 'es-PE';
        break;
      case 'es-EC': // es-EC (Espanhol - Equador)
        tempLanguageLocale = 'es-EC';
        break;
      default: // pt-BR (Português - Brasil)
        tempLanguageLocale = 'pt-BR';
        break;
    }

    this._changeLocale(tempLanguageLocale);
  }
  //#endregion
}