//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
// ***** COMPONENTS ***** //
import { HandlerErrorComponent } from '../_handle_error/_handler_error.component';

// ***** HANDLER ***** //
import { hasControlErrors } from '../_methods/_controls-methods';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import {IDropDownOptionsCountry } from '../../../../../interfaces/IDropDownOptionsCountry';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FieldsetModule } from 'primeng/fieldset';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-input-ddi-ddd-phone',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FieldsetModule,
    InputTextModule,
    HandlerErrorComponent,
    ReactiveFormsModule
  ],
  templateUrl: './input-ddi-ddd-phone.component.html'
})
export class InputDdiDddPhoneComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() controlDdi!: FormControl;
  @Input() controlDdd!: FormControl;
  @Input() controlNumber!: FormControl;
  @Input() controlNormalized!: FormControl;
  @Input() arrayOptions!: IDropDownOptionsCountry[];
  @Input() idField!: string;
  @Input() propertyLabel!: string;
  @Input() propertyValue!: string;
  @Input() label!: string;
  @Input() toolTip?: string;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: number;

  showLabel = true;
  showLabelBold = false;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;

  }

  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Verifica se o controle possui erros de validação.
   * 
   */
  showErrors(): void {
    return hasControlErrors(this.controlDdd, "input");
    return hasControlErrors(this.controlDdi, "input");
    return hasControlErrors(this.controlNumber, "input");
  }

  /**
  * @author GASPAR
  * @date 2024-09-23
  * @version 1.0.0
  * 
  * @description Retorna o nome do campo, com base no idField.
  * 
  * @returns 
  */
  getFieldName(): string {
    if (this.idField !== undefined) {
      return this.idField.replace("fid-", "");
    } else {
      return "";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Retorna as classes CSS do Label que mostra ele em Negrito.
   * 
   * @returns 
   */
  getLabelBoldClasses(): string {
    return "cls-util-font-bold cls-util-font-family-bold";
  }

  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Retorna Qual propriedade do Objeto Json será usada como LABEL do DropDown.
   * 
   * @returns 
   */
  getPropertyLabel(): string {
    if (this.propertyLabel != undefined && this.propertyLabel != "") {
      return this.propertyLabel;
    } else {
      return "label";
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Retorna Qual propriedade do Objeto Json será usada como VALUE do DropDown.
   * 
   * @returns 
   */
  getPropertyValue(): string {
    if (this.propertyValue != undefined && this.propertyValue != "") {
      return this.propertyValue;
    } else {
      return "ddi";
    }
  }
  //#endregion
}

