
//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { NgModule } from '@angular/core';
//#endregion

//#region "|--- IMPORT PRIMENG ---|"
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropDownComponent } from './form/drop-down/drop-down.component';
import { DropDownFlagComponent } from './form/drop-down-flag/drop-down-flag.component';
import { DropDownIconComponent } from './form/drop-down-icon/drop-down-icon.component';
import { FieldsetModule } from 'primeng/fieldset';
import { ImageModule } from 'primeng/image';
import { InputCalendarComponent } from './form/input-calendar/input-calendar.component';
import { InputDateComponent } from './form/input-date/input-date.component';
import { InputDropdownTextComponent } from './form/input-dropdown-text/input-dropdown-text.component';
import { InputNumberButtonComponent } from './form/input-number-button/input-number-button.component';
import { InputNumberFloatComponent } from './form/input-number-float/input-number-float.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextAreaComponent } from './form/input-text-area/input-text-area.component';
import { InputTextComponent } from './form/input-text/input-text.component';
import { InputTimeComponent } from './form/input-time/input-time.component';
import { LabelComponent } from './form/label/label.component';
import { KnobModule } from 'primeng/knob';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MegaMenuModule } from 'primeng/megamenu';
import { PanelModule } from 'primeng/panel';
import { ShowInputDataComponent } from './form/show-input-data/show-input-data.component';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
//#endregion

const UX_MODULE = [
    BlockUIModule,
    ButtonModule,  
    CardModule,
    ConfirmDialogModule, 
    DataViewModule,
    DialogModule,
    DividerModule,
    DropDownComponent,
    DropDownIconComponent,
    DropDownFlagComponent,
    FieldsetModule,    
    ImageModule,    
    InputCalendarComponent,
    InputDateComponent,
    InputDropdownTextComponent,
    InputNumberButtonComponent,
    InputNumberFloatComponent,
    InputSwitchModule,
    InputTextAreaComponent,
    InputTextComponent,
    InputTimeComponent,
    LabelComponent,
    KnobModule,
    MenubarModule,
    MenuModule,    
    MegaMenuModule,
    PanelModule,
    ShowInputDataComponent,
    SkeletonModule,
    SliderModule,
    SpeedDialModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    ToastModule,
    ToolbarModule,
    TooltipModule
];

@NgModule({
    declarations: [

    ],
    imports: [
        ...UX_MODULE
    ],
    exports: [
        ...UX_MODULE
    ]
})
export class PrimengComponentsModule { }
