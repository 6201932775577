//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** CLASS ***** //
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModulePaxService } from '../../../../../../../_services/module-pax/module-pax.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAllCountries } from '../../../../../../../../_library/static_options/staticOptionsAllCountries';
import { staticOptionsContactRelation } from '../../../../../../../../_library/static_options/staticOptionsContactRelation';
import { staticOptionsDocumentsPersonBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsPersonBr';
import { staticOptionsPersonGender } from '../../../../../../../../_library/static_options/staticOptionsPersonGender';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-pax-register',
  standalone: true,
  imports: [
    CommonModule,
    CanttechComponentsModule,
    PrimengComponentsModule
  ],
  templateUrl: './tab-pax-register.component.html'
})
export class TabPaxRegisterComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any; // Dados do Pax.
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionToolbarTabPaxData = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;

  // ***** CLASSES ***** //
  public classLocalMethods!: any;
  public classInternationalization!: any;
  public classModuleMethods!: any;

  // ***** OPTIONS ***** //
  public optionsDocumentsType!: IDropDownOptionsBasic[];
  public optionsContactRelation!: IDropDownOptionsBasic[];
  public optionsGender!: IDropDownOptionsBasic[];
  public optionsNationalityCountries!: IDropDownOptionsCountry[];
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _messageService: MessageService,
    private _modulePaxService: ModulePaxService,
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;

    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.optionsDocumentsType = staticOptionsDocumentsPersonBr;
    this.optionsContactRelation = staticOptionsContactRelation;
    this.optionsGender = staticOptionsPersonGender;
    this.optionsNationalityCountries = staticOptionsAllCountries;        
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-12
   * @version 1.0.0
   *
   * @description 
   *   - Callback das ação do botões do Toolbar.
   *
   * @param xButtonAction
   */
  public onClickToolbarTabPaxData(xButtonAction: string): void {
    this.actionToolbarTabPaxData.emit(xButtonAction);
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-11-01
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será associado ao onBlur do controle para verificar se o valor do Documento Pessoal já existe na Base de Dados.
  * 
  * @returns 
  */
  public onBlurCheckDocumentInValue(): any {
    return async (xEvent: any, xComponentContext: any) => {
      const inputElement = xEvent.target as HTMLInputElement;
      const inputValue = inputElement.value;
      const documentType = this.formComponent.controls.documents_personal.controls[0].controls.document_type.value;

      //Pegar o INDEX do elemento par amostra o resultado.
      const idElement = inputElement.getAttribute("id");
      const idElementArray = idElement !== null ? idElement.split(".") : [];
      const indexElement = idElementArray[1] !== undefined ? parseInt(idElementArray[1]) : 0;      

      xComponentContext.showIconWaiting = true;

      if (inputValue && inputValue != null && inputValue !== "") {
        // VERIFICA SE O VALOR JÁ EXISTE NA BASE DE DADOS: DOCUMENTO DE DETERMINADO TIPO
        await this._modulePaxService.checkIfHasDocumentValue(documentType, inputValue).subscribe({
          next: (xApiResponse: any) => {
            if (xApiResponse) { // Só importa se houver resultado positivo. Erros e outros não deve ser sinalizado             
              if (xApiResponse.data[0] !== undefined && xApiResponse.data[0].qtd > 0) {                
                if (this.formComponent.controls.documents_personal.controls[indexElement].controls.document_in.errors === null) { // Se já estiver inválido, não precisa marcar novamente. 
                  this.formComponent.controls.documents_personal.controls[indexElement].controls.document_in.markAsDirty();
                  this.formComponent.controls.documents_personal.controls[indexElement].controls.document_in.markAsTouched();
                  this.formComponent.controls.documents_personal.controls[indexElement].controls.document_in.setErrors({ unique: true });
                }
              }
            }
          }
        });

        setTimeout(() => {
          xComponentContext.showIconWaiting = false;
        }, 1500);
      }
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  //#endregion
}
