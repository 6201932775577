//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANLDER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleWeightBalanceService {
  //#region "|--- PROPERTIES---|" 
  urlWeightBalanceModuleService: string;
  //#endregion

  constructor(private _http: HttpClient) {
    if (settingConfig.API_AIRCRAFT_PORT == "443") {
      this.urlWeightBalanceModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlWeightBalanceModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_WEIGHT_BALANCE_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|---  MÉTODOS Do TEMPLATE PESO E BALANCEAMENTO ---|"
  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método responsável por salvar os dados a Folha de Peso e Balanceamento.
   * 
   * @param weightBalanceForm 
   * 
   * @returns 
   */
  saveWeightBalanceSheet(xWeightBalanceForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/weight/balance/sheet`, xWeightBalanceForm.getRawValue(), { headers })
      .pipe(        
        catchError((error) => _handleError(error, xWeightBalanceForm))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-10-10
   * @version 1.0.0
   * 
   * @description Método responsável por buscar todos os dados a Folha de Peso e Balanceamento.
   * 
   * @returns 
   */
  getAllWeightBalanceSheet(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/weight/balance/sheet`, { headers })
      .pipe(
        catchError((error) => _handleError(error, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método responsável por atualizar os dados a Folha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceForm 
   * @param xWeightBalanceId 
   * @returns 
   */
  updateWeightBalanceSheet(xWeightBalanceId: string, xWeightBalanceForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.put<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/weight/balance/sheet/${xWeightBalanceId}`, xWeightBalanceForm.getRawValue(), { headers })
      .pipe(
        catchError((error) => _handleError(error, xWeightBalanceForm))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description Método responsável por deletar a Folha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceId 
   * @returns 
   */
  deleteWeightBalanceSheet(xWeightBalanceId: string): Observable<any> {
    return this._http.delete<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/weight/balance/sheet/${xWeightBalanceId}`)
      .pipe(
        catchError((error) => _handleError(error))
      )
  }

  /**
   * author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Método responsável por buscar a Folha de Peso e Balanceamento pelo ID da Aeronave.
   * 
   * @param idRegister 
   * @returns 
   */
  getWeightBalanceSheetById(idRegister: string): Observable<any> {
    return this._http.get<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/weight/balance/sheet/${idRegister}`)
      .pipe(
        catchError((error) => _handleError(error, null))
      )
  }

  /**
   * author GASPAR
   * @date 2024-09-29
   * @version 1.0.0
   * 
   * @description Método responsável por buscar a Folha de Peso e Balanceamento pelo ID da Aeronave.
   * 
   * @param xAircraftId 
   * @returns 
   */
  getWeightBalanceSheetListByAircraft(xAircraftId: string): Observable<any> {
    return this._http.get<any>(`${this.urlWeightBalanceModuleService}/${settingConfig.API_WEIGHT_BALANCE_URL_SEGMENT}/weight/balance/sheet/aircraft/${xAircraftId}`)
      .pipe(
        catchError((error) => _handleError(error, null))
      )
  }
  //#endregion
}
