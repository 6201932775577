/**
 * @author GASPAR
 * @date 2024-09-12
 * @version 1.0.0
 * 
 * @description Enumerador de ações de botões.
 */
export const BUTTON_ACTION = {
    NEW: "new",    
    SAVE: "save",
    UPDATE: "update",
    DELETE: "delete",
    EXIT: "exit",
    RELOAD: "reload",
    EDIT: "edit",
    LIST: "list",
    CLONE: "clone",
    OPEN_DIALOG: "open_dialog"
}
