<div class="cls-ui-primeng-field cls-ui-primeng-field-input-calendar">
    <div [ngClass]="{'cls-ui-primeng-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-wrong': this.showErrors() }" >
            {{this.label}}
        </label>   
        <p-calendar 
            #inputCalendar
            [formControl]="this.control"
            [dateFormat]="this.getFormatDate()"
            [iconDisplay]="'input'"
            [showIcon]="true" 
            [appendTo]="this.getAppendTo()"
            [showClear]="true"
            [placeholder]="this.getFormatDate()"
            readonlyInput="true"
            showButtonBar="true"
            variant="filled"
            styleClass="w-full"
            inputId="">
            <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                <i class="fa-regular fa-calendar" (click)="clickCallBack($event)"></i>
            </ng-template>
        </p-calendar>      
    </div>
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="input"></lib-ui-primeng-handler-error>
</div>
