<div class="cls-app-component">
    <p-toolbar class="cls-cmp-ptv-toolbar-page">
        <div class="p-toolbar-group-start">
            <!-- BOTÃO: GERAR PDF -->
            <p-button label="{{this.classInternationalization.getTranslation('btn_PDF')}}"
                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" icon="fa-solid fa-file-pdf"
                severity="secondary" (click)="this.onClickToolbarPreview(this.actionType.GENERATE_PDF)" />
            <!-- BOTÃO: GERAR IMAGE -->
            <p-button label="{{this.classInternationalization.getTranslation('btn_Image')}}"
                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" icon="fa-solid fa-image" severity="secondary"
                (click)="this.onClickToolbarPreview(this.actionType.GENERATE_IMAGE)" />
        </div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <!-- BOTÃO: SAIR -->
            <p-button label="SAIR" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                icon="fa-solid fa-right-from-bracket" severity="danger"
                (click)="this.onClickToolbarPreview(this.actionType.EXIT)" />
        </div>
    </p-toolbar>
    <div class="cls-cmp-esp-cg-preview">
        <div id="contentToPdfOrImage" class="cls-cmp-esp-cg-preview-pdf">           
            <div class="cls-cmp-esp-cg-preview-pdf-margin-top-first"></div> 
            <div class="cls-cmp-esp-cg-preview-pdf-page-first">
                <!-- TOP DA PÁGINA: LOGO E TITULO -->
                <div class="cls-cmp-esp-cg-preview-top">
                    <div class="cls-cmp-esp-cg-preview-top-image">
                        <img src="/app_platform/imgs/logo-helisul.svg" alt="">
                    </div>
                    <div class="cls-cmp-esp-cg-preview-top-title">
                        MANIFESTO PESO E BALANCEAMENTO
                    </div>
                </div>
                <!-- HEADER DA PÁGINA: CABEÇALHO DA FICHA -->
                <div class="cls-cmp-esp-cg-preview-header">
                    <div class="cls-cmp-esp-cg-preview-header-image">
                        <img [src]="this.getAircraftImage()" alt="">
                    </div>
                    <div class="cls-cmp-esp-cg-preview-header-content">
                        <div class="cls-cmp-esp-cg-preview-header-content-row">
                            <div class="cls-cmp-esp-cg-preview-header-content-row-mark">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Matrícula:</span>
                                <span
                                    [innerHTML]="this.classAviationTools.setAircraftMarkWithIconType(this.dataAircraft.register_data.aircraft_mark, this.dataAircraft.register_data.aircraft_type)">
                                </span>
                            </div>
                            <div class="cls-cmp-esp-cg-preview-header-content-row-icao-code">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">ICAO Code:</span> 
                                {{this.dataAircraft.register_data.icao_code}}
                            </div>
                            <div class="cls-cmp-esp-cg-preview-header-content-row-serial-number">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Serial</span>: 
                                {{this.dataAircraft.register_data.serial_number}}
                            </div>
                            <div class="cls-cmp-esp-cg-preview-header-content-row-year-manufactured">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Ano:</span>  
                                {{this.dataAircraft.register_data.year_manufactured}}
                            </div>
                        </div>
                        <div class="cls-cmp-esp-cg-preview-header-content-row">
                            <div class="cls-cmp-esp-cg-preview-header-content-row-sheet-name">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Configuração:</span> 
                                {{this.dataSheet.weight_balance_data.header_data.name}}
                            </div>
                            
                            <div class="cls-cmp-esp-cg-preview-header-content-row-created-at">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Gerado:</span> 
                                {{this.classLocalMethods.getCurrentDateUTC_DDMMYYYY_HHMMSS()}} UTC
                            </div>
                        </div>
                    </div>
                </div>
                <!-- BODY DA PÁGINA: CONTEÚDO -->
                <div class="cls-cmp-esp-cg-preview-body">
                    <div class="cls-cmp-esp-cg-preview-body-space"></div>
                    <!-- DADOS VOO -->
                    <div class="grid">
                        <div class="col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: DADOS VOO -->
                            <div class="cls-cmp-esp-cg-preview-body-topic-title">
                                <i class="fa-solid fa-plane"></i> DADOS DO VOO
                            </div>
                            <!-- VOO: CABEÇALHO -->
                            <div class="cls-cmp-esp-cg-preview-body-flight cls-cmp-esp-cg-preview-body-topic-header">
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    ORIGEM
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    DESTINO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-eobt">
                                    EOBT (UTC)
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-stage">
                                   ETAPA
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-in">
                                    N°. DIÁRIO
                                </div>
                            </div>
                            <!-- COMBUSTÍVEL: DADOS -->
                            <div class="cls-cmp-esp-cg-preview-body-flight">
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    &nbsp; {{this.dataSheet.flight_data.aerodromes_data.departure.text_norm}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    &nbsp; {{this.dataSheet.flight_data.aerodromes_data.destination.text_norm}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-eobt">
                                    &nbsp; {{this.dataSheet.flight_data.eobt_date_time.date}}
                                    {{this.dataSheet.flight_data.eobt_date_time.time}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-stage">
                                    &nbsp; {{this.dataSheet.flight_data.logbook_data.stage}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-in">
                                    &nbsp; {{this.dataSheet.flight_data.logbook_data.in}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cls-cmp-esp-cg-preview-body-space"></div>
                    <!-- DADOS COMBUSTÍVEL -->
                    <div class="grid">
                        <div class="col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: DADOS COMBUSTÍVEL -->
                            <div class="cls-cmp-esp-cg-preview-body-topic-title">
                                <i class="fa-solid fa-gas-pump"></i> DADOS DO COMBUSTÍVEL
                            </div>
                            <!-- COMBUSTÍVEL: CABEÇALHO -->
                            <div class="cls-cmp-esp-cg-preview-body-fuel cls-cmp-esp-cg-preview-body-topic-header">
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    TOTAL RAMPA
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-time">
                                    TAXI
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    CONSUMO TAXI
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-time">
                                    VOO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    CONSUMO VOO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    TOTAL POUSO
                                </div>
                            </div>
                            <!-- COMBUSTÍVEL: DADOS -->
                            <div class="cls-cmp-esp-cg-preview-body-fuel">
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.fuel_consumption_data.total_fuel_supplied, this._locale)}}
                                    {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_fuel_supplied_unit)}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-time">
                                    {{this.dataSheet.fuel_consumption_data.taxi_time}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.fuel_consumption_data.total_taxi_fuel, this._locale)}}
                                    {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_taxi_fuel_unit)}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-time">
                                    {{this.dataSheet.fuel_consumption_data.flight_time}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.fuel_consumption_data.total_flight_fuel, this._locale)}}
                                    {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_flight_fuel_unit)}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-fuel-consumption">
                                    {{this.classLocalMethods.convertNumberToLocale((this.dataSheet.fuel_consumption_data.total_fuel_supplied - this.dataSheet.fuel_consumption_data.total_taxi_fuel - this.dataSheet.fuel_consumption_data.total_flight_fuel), this._locale)}}
                                    {{this.getFuelUnitLabel(this.dataSheet.fuel_consumption_data.total_flight_fuel_unit)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cls-cmp-esp-cg-preview-body-space"></div>           
                    <!-- DADOS: ITENS CG -->                                
                    <div class="grid">                    
                        <div class="col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: ITENS CG -->  
                            <div class="cls-cmp-esp-cg-preview-body-topic-title">
                                <i class="fa-solid fa-list"></i> COMPONENTES CG
                            </div>         
                            <!-- ITENS CG: CABEÇALHO/DADOS -->                 
                            <div class="grid">
                                <div class="col-6 cls-util-no-padding-bottom">
                                    <!-- ITENS CG: CABEÇALHO -->
                                    <div class="cls-cmp-esp-cg-preview-body-components cls-cmp-esp-cg-preview-body-topic-header">
                                        <div class="cls-cmp-esp-cg-preview-body-components-label">
                                            DESCRIÇÃO
                                        </div>
                                        <div class="cls-cmp-esp-cg-preview-body-components-weight">
                                            PESO
                                        </div>
                                    </div>
                                    <!-- ITENS CG: DADOS BEM / PESO AERONAVE -->
                                    <div class="cls-cmp-esp-cg-preview-body-components">
                                        <div class="cls-cmp-esp-cg-preview-body-components-label">
                                            <span class="cls-cmp-esp-cg-preview-body-switch-on">
                                                <i class="fa-solid fa-toggle-on"></i>
                                            </span>
                                            BEM / PESO AERONAVE
                                        </div>
                                        <div class="cls-cmp-esp-cg-preview-body-components-weight">
                                            {{this.classLocalMethods.convertNumberToLocale(this.dataSheet.calculation_cg[0].weight, this._locale, 2)}}
                                            {{this.dataSheet.calculation_cg[0].weight_unit}}
                                        </div>
                                    </div>
                                    <!-- ITENS CG: COMPONENTES INTERNOS-->
                                    <ng-container
                                        *ngFor="let objFieldInternals of this.dataSheet.weight_balance_data.components_internals_data; let k = index">
                                        <div class="cls-cmp-esp-cg-preview-body-components"
                                            [ngClass]="{'cls-cmp-esp-cg-preview-body-row-marked': k%2 === 0}">
                                            <div class="cls-cmp-esp-cg-preview-body-components-label">
                                                <ng-container *ngIf="this.dataSheet.matrix_elements[0][k] === true">
                                                    <span class="cls-cmp-esp-cg-preview-body-switch-on">
                                                        <i class="fa-solid fa-toggle-on"></i>
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="this.dataSheet.matrix_elements[0][k] === false">
                                                    <span class="cls-cmp-esp-cg-preview-body-switch-off">
                                                        <i class="fa-solid fa-toggle-off"></i>
                                                    </span>
                                                </ng-container>
                                                <span [ngClass]="{'cls-cmp-esp-cg-preview-body-opacity': this.dataSheet.matrix_elements[0][k] === false}">
                                                    {{objFieldInternals.label}}
                                                </span>                                            
                                            </div>
                                            <div class="cls-cmp-esp-cg-preview-body-components-weight">
                                                <span [ngClass]="{'cls-cmp-esp-cg-preview-body-opacity': this.dataSheet.matrix_elements[0][k] === false}">
                                                    {{this.classLocalMethods.convertNumberToLocale(objFieldInternals.weight, this._locale)}}
                                                    {{objFieldInternals.weight_unit}}
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ITENS CG: COMPONENTES EXTERNOS-->
                                    <ng-container
                                        *ngFor="let objFieldExternals of this.dataSheet.weight_balance_data.components_externals_data; let z = index">
                                        <div class="cls-cmp-esp-cg-preview-body-components"
                                            [ngClass]="{'cls-cmp-esp-cg-preview-body-row-marked': (z + this.getLengthComponentExternal())%2 === 0}">
                                            <div class="cls-cmp-esp-cg-preview-body-components-label">
                                                <ng-container *ngIf="this.dataSheet.matrix_elements[1][z] === true">
                                                    <span class="cls-cmp-esp-cg-preview-body-switch-on">
                                                        <i class="fa-solid fa-toggle-on"></i>
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="this.dataSheet.matrix_elements[1][z] === false">
                                                    <span class="cls-cmp-esp-cg-preview-body-switch-off">
                                                        <i class="fa-solid fa-toggle-off"></i>
                                                    </span>
                                                </ng-container>
                                                    <span [ngClass]="{'cls-cmp-esp-cg-preview-body-opacity': this.dataSheet.matrix_elements[1][z] === false}">
                                                        {{objFieldExternals.label}}
                                                    </span>                                            
                                            </div>
                                            <div class="cls-cmp-esp-cg-preview-body-components-weight">
                                                    <span [ngClass]="{'cls-cmp-esp-cg-preview-body-opacity': this.dataSheet.matrix_elements[1][z] === false}">
                                                        {{this.classLocalMethods.convertNumberToLocale(objFieldExternals.weight, this._locale)}}
                                                        {{objFieldExternals.weight_unit}}
                                                    </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-6 cls-util-no-padding-bottom cls-cmp-esp-cg-preview-body-floor-plan-col-6-width">
                                    <div class="cls-cmp-esp-cg-preview-body-floor-plan cls-cmp-esp-cg-preview-body-topic-header">
                                        <!-- PLANTA BAIXA: CABEÇALHO -->
                                        <div class="cls-cmp-esp-cg-preview-body-floor-plan-header">
                                            PLANTA BAIXA
                                        </div>                                                                            
                                    </div>  

                                    <div class="cls-cmp-esp-cg-preview-body-floor-plan cls-cmp-esp-cg-preview-body-floor-plan-frame">
                                        <div #imageFrame>
                                            <img [src]="this.getUrlImageToShowWeightBalanceDataSheetImage()" alt="Floor Plan"/>
                                        </div>
                                    </div>                                                                 
                                </div>
                            </div>                        
                        </div>                    
                    </div>  
                    <div class="cls-cmp-esp-cg-preview-body-space"></div> 
                    <!-- DADOS: CALCULO CG -->     
                    <div class="grid"> 
                        <div class="col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: CALCULO CG -->  
                            <div class="cls-cmp-esp-cg-preview-body-topic-title">
                                <i class="fa-solid fa-square-root-variable"></i> CALCULO CG
                            </div> 
                            <!-- CALCULO CG: CABEÇALHO -->
                            <div class="cls-cmp-esp-cg-preview-body-calculus cls-cmp-esp-cg-preview-body-topic-header">
                                <div class="cls-cmp-esp-cg-preview-body-calculus-description">
                                    DESCRIÇÃO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-calculus-weight">
                                    PESO ({{this.dataSheet.calculation_cg[0].weight_unit}})
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-calculus-arm">
                                    B. LONG ({{this.dataSheet.calculation_cg[0].arm_unit}})
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-calculus-moment">
                                    M. LONG ({{this.dataSheet.calculation_cg[0].weight_unit}} x {{this.dataSheet.calculation_cg[0].arm_unit}})
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-calculus-arm">
                                    B. LAT ({{this.dataSheet.calculation_cg[1].arm_unit}})
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-calculus-moment cls-cmp-esp-cg-preview-body-calculus-moment-last">
                                    M. LAT. ({{this.dataSheet.calculation_cg[0].weight_unit}} x {{this.dataSheet.calculation_cg[1].arm_unit}})
                                </div>
                            </div>
                            <!-- CALCULO CG: DADOS -->
                            <ng-container
                                *ngFor="let objCalculation of this.dataSheet.calculation_cg; let rowIndex = index">
                                <div class="cls-cmp-esp-cg-preview-body-calculus" [ngClass]="{'cls-cmp-esp-cg-preview-body-row-marked': rowIndex%2 === 1}">
                                    <div class="cls-cmp-esp-cg-preview-body-calculus-description cls-cmp-esp-cg-preview-body-calculus-description-header"
                                        [ngClass]="{'cls-util-font-normal': rowIndex%2 === 0}">
                                        <ng-container *ngIf="rowIndex%2 === 1">
                                            <ng-container *ngIf="rowIndex === 1">
                                                <i class="fa-solid fa-scale-balanced"></i>
                                            </ng-container>
                                            <ng-container *ngIf="rowIndex === 3">
                                                <i class="fa-solid fa-plane"></i>
                                            </ng-container>
                                            <ng-container *ngIf="rowIndex === 5">
                                                <i class="fa-solid fa-plane-departure"></i>
                                            </ng-container>
                                            <ng-container *ngIf="rowIndex === 7">
                                                <i class="fa-solid fa-plane-arrival"></i>
                                            </ng-container>
                                        </ng-container>
                                        {{objCalculation.label}}
                                    </div>
                                    <div [ngClass]="{'cls-util-font-normal': rowIndex%2 === 0}" class="cls-cmp-esp-cg-preview-body-calculus-weight">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.weight, this._locale, 2)}}
                                    </div>
                                    <div [ngClass]="{'cls-util-font-normal': rowIndex%2 === 0}" class="cls-cmp-esp-cg-preview-body-calculus-arm">
                                        <div [ngClass]="this.getClassCgInChart(rowIndex, 'long')">
                                            {{this.classLocalMethods.convertNumberToLocale(objCalculation.longitudinal_arm, this._locale, 2)}}
                                        </div>
                                    </div>
                                    <div [ngClass]="{'cls-util-font-normal': rowIndex%2 === 0}" class="cls-cmp-esp-cg-preview-body-calculus-moment">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.longitudinal_moment, this._locale, 2)}}
                                    </div>
                                    <div [ngClass]="{'cls-util-font-normal': rowIndex%2 === 0}" class="cls-cmp-esp-cg-preview-body-calculus-arm">
                                        <div [ngClass]="this.getClassCgInChart(rowIndex, 'lat')">
                                            {{this.classLocalMethods.convertNumberToLocale(objCalculation.lateral_arm, this._locale, 2)}}
                                        </div>
                                    </div>
                                    <div [ngClass]="{'cls-util-font-normal': rowIndex%2 === 0}" class="cls-cmp-esp-cg-preview-body-calculus-moment cls-cmp-esp-cg-preview-body-calculus-moment-last">
                                        {{this.classLocalMethods.convertNumberToLocale(objCalculation.lateral_moment, this._locale, 2)}}
                                    </div>
                                </div>                            
                            </ng-container>
                            <div class="cls-cmp-esp-cg-preview-body-calculus-legend">
                                <div class="cls-cmp-esp-cg-preview-body-calculus-legend-item">
                                    <div class="cls-cmp-esp-cg-preview-body-calculus-legend-item-ball-green"></div> <span>-CG
                                        dentro do envelope</span>
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-calculus-legend-item">
                                    <div class="cls-cmp-esp-cg-preview-body-calculus-legend-item-ball-yellow"></div> <span>-CG
                                        perto do limite do envelope</span>
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-calculus-legend-item">
                                    <div class="cls-cmp-esp-cg-preview-body-calculus-legend-item-ball-red"></div> <span>-CG
                                        fora do envelope</span>
                                </div>
                            </div>
                            <!-- CALCULO CG: DADOS -->
                        </div>
                    </div>
                    <div class="cls-cmp-esp-cg-preview-body-space"></div> 
                    <!-- DADOS: GRÁFICOS CG -->   
                    <div class="grid"> 
                        <div class="col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: CHART -->
                            <div class="cls-cmp-esp-cg-preview-body-topic-title">
                                <i class="fa-solid fa-chart-line"></i> GRÁFICO CG
                            </div>
                            <!-- CHART: CABEÇALHO/DADOS -->                 
                            <div class="grid">
                                <div class="col-6 cls-util-no-padding-bottom">
                                    <div class="cls-cmp-esp-cg-preview-body-chart cls-cmp-esp-cg-preview-body-topic-header">
                                        <!-- PLANTA BAIXA: CABEÇALHO -->
                                        <div class="cls-cmp-esp-cg-preview-body-chart-longitudinal">
                                            LONGITUDINAL
                                        </div>
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-body-chart-frame">
                                        <sunrise-app-platform--chart-cg-limits
                                            [objChartCGLimits]="this.dataSheet.weight_balance_data.chart_longitudinal_data[0]"
                                            [arrPointsToDraw]="this.listPointToDraw">
                                        </sunrise-app-platform--chart-cg-limits> 
                                    </div>            
                                    <div class="cls-cmp-esp-cg-preview-body-chart-legend">
                                        <div class="cls-cmp-esp-cg-preview-body-chart-legend-item">
                                            <div class="cls-cmp-esp-cg-preview-body-chart-legend-item-ball-zfw"></div>
                                            <span>-ZFW</span>
                                        </div>
                                        <div class="cls-cmp-esp-cg-preview-body-chart-legend-item">
                                            <div class="cls-cmp-esp-cg-preview-body-chart-legend-item-ball-rw"></div> <span>-RW</span>
                                        </div>
                                        <div class="cls-cmp-esp-cg-preview-body-chart-legend-item">
                                            <div class="cls-cmp-esp-cg-preview-body-chart-legend-item-ball-tow"></div>
                                            <span>-TOW</span>
                                        </div>
                                        <div class="cls-cmp-esp-cg-preview-body-chart-legend-item">
                                            <div class="cls-cmp-esp-cg-preview-body-chart-legend-item-ball-ldw"></div>
                                            <span>-LDW</span>
                                        </div>
                                    </div>                    
                                </div>
                                <div class="col-6 cls-util-no-padding-bottom cls-cmp-esp-cg-preview-body-chart-col-6-width">
                                    <div class="cls-cmp-esp-cg-preview-body-chart cls-cmp-esp-cg-preview-body-topic-header">
                                        <!-- PLANTA BAIXA: CABEÇALHO -->
                                        <div class="cls-cmp-esp-cg-preview-body-chart-lateral">
                                            LATERAL
                                        </div>
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-body-chart-frame">
                                        
                                    </div>
                                </div>
                            </div>                            
                        </div>                        
                    </div>                    
                    <div class="cls-cmp-esp-cg-preview-calculus-separator"></div>                                        
                </div>
                <div class="cls-cmp-esp-cg-preview-pdf-page-first-bottom"> 
                    <div class="cls-cmp-esp-cg-preview-body-commander">
                        <div class="cls-cmp-esp-cg-preview-body-commander-responsible">
                            <div class="cls-cmp-esp-cg-preview-body-commander-responsible-label">
                                <i class="fa-solid fa-screwdriver-wrench"></i> ELABORA POR:
                            </div>
                            <div class="cls-cmp-esp-cg-preview-body-commander-responsible-content">
                                {{this.dataSheet.flight_data.commander_data.document_type.toUpperCase()}}: 
                                {{this.dataSheet.flight_data.commander_data.document_in}} -
                                {{this.dataSheet.flight_data.commander_data.full_name}}
                            </div>                                                        
                        </div>
                        <div class="cls-cmp-esp-cg-preview-body-commander-approved">
                            <div class="cls-cmp-esp-cg-preview-body-commander-approved-label">
                                <i class="fa-solid fa-user-tie"></i> APROVADO POR:
                            </div>
                            <div class="cls-cmp-esp-cg-preview-body-commander-approved-content">
                                {{this.dataSheet.flight_data.commander_data.document_type.toUpperCase()}}: 
                                {{this.dataSheet.flight_data.commander_data.document_in}} -
                                {{this.dataSheet.flight_data.commander_data.full_name}}
                            </div>                                                         
                        </div>
                        <div class="cls-cmp-esp-cg-preview-body-commander-signature">                            
                            <div class="cls-cmp-esp-cg-preview-body-commander-signature-label">
                                <i class="fa-solid fa-pen-fancy"></i> ASSINATURA:
                            </div>
                            <div>
                                &nbsp;                             
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="cls-cmp-esp-cg-preview-pdf-margin-top-others"></div>
            <div class="cls-cmp-esp-cg-preview-pdf-page-others">
                <!-- TOP DA PÁGINA: LOGO E TITULO -->
                <div class="cls-cmp-esp-cg-preview-top">
                    <div class="cls-cmp-esp-cg-preview-top-image">
                        <img src="/app_platform/imgs/logo-helisul.svg" alt="">
                    </div>
                    <div class="cls-cmp-esp-cg-preview-top-title">
                        FICHA DE EMBARQUE
                    </div>
                </div>
                <!-- HEADER DA PÁGINA: CABEÇALHO DA FICHA -->
                <div class="cls-cmp-esp-cg-preview-header">
                    <div class="cls-cmp-esp-cg-preview-header-image">
                        <img [src]="this.getAircraftImage()" alt="">
                    </div>
                    <div class="cls-cmp-esp-cg-preview-header-content">
                        <div class="cls-cmp-esp-cg-preview-header-content-row">
                            <div class="cls-cmp-esp-cg-preview-header-content-row-mark">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Matrícula:</span>
                                <span
                                    [innerHTML]="this.classAviationTools.setAircraftMarkWithIconType(this.dataAircraft.register_data.aircraft_mark, this.dataAircraft.register_data.aircraft_type)">
                                </span>
                            </div>
                            <div class="cls-cmp-esp-cg-preview-header-content-row-icao-code">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">ICAO Code:</span> 
                                {{this.dataAircraft.register_data.icao_code}}
                            </div>
                            <div class="cls-cmp-esp-cg-preview-header-content-row-serial-number">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Serial</span>: 
                                {{this.dataAircraft.register_data.serial_number}}
                            </div>
                            <div class="cls-cmp-esp-cg-preview-header-content-row-year-manufactured">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Ano:</span>  
                                {{this.dataAircraft.register_data.year_manufactured}}
                            </div>
                        </div>
                        <div class="cls-cmp-esp-cg-preview-header-content-row">
                            <div class="cls-cmp-esp-cg-preview-header-content-row-sheet-name">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Configuração:</span> 
                                {{this.dataSheet.weight_balance_data.header_data.name}}
                            </div>
                            
                            <div class="cls-cmp-esp-cg-preview-header-content-row-created-at">
                                <span class="cls-cmp-esp-cg-preview-header-content-row-label">Gerado:</span> 
                                {{this.classLocalMethods.getCurrentDateUTC_DDMMYYYY_HHMMSS()}} UTC
                            </div>
                        </div>
                    </div>
                </div>
                <!-- BODY DA PÁGINA: CONTEÚDO -->
                <div class="cls-cmp-esp-cg-preview-body">
                    <div class="cls-cmp-esp-cg-preview-body-space"></div>
                    <!-- DADOS VOO -->
                    <div class="grid">
                        <div class="col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: DADOS VOO -->
                            <div class="cls-cmp-esp-cg-preview-body-topic-title">
                                <i class="fa-solid fa-plane"></i> DADOS DO VOO
                            </div>
                            <!-- VOO: CABEÇALHO -->
                            <div class="cls-cmp-esp-cg-preview-body-flight cls-cmp-esp-cg-preview-body-topic-header">
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    ORIGEM
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    DESTINO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-eobt">
                                    EOBT (UTC)
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-stage">
                                    ETAPA
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-in">
                                    N°. DIÁRIO
                                </div>

                            </div>
                            <!-- FLIGHT: DADOS -->
                            <div class="cls-cmp-esp-cg-preview-body-flight">
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    &nbsp; {{this.dataSheet.flight_data.aerodromes_data.departure.text_norm}} 
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-data">
                                    &nbsp; {{this.dataSheet.flight_data.aerodromes_data.destination.text_norm}} 
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-eobt">
                                    &nbsp; {{this.dataSheet.flight_data.eobt_date_time.date}} 
                                    {{this.dataSheet.flight_data.eobt_date_time.time}}
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-stage">
                                    &nbsp; {{this.dataSheet.flight_data.logbook_data.stage}} 
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-flight-logbook-in">
                                    &nbsp; {{this.dataSheet.flight_data.logbook_data.in}} 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cls-cmp-esp-cg-preview-body-space"></div> 
                    <!-- FICHA DE PASSAGEIROS -->
                    <div class="grid">
                        <div class="col-12 cls-util-no-padding-bottom">
                            <!-- TOPIC: FICHA DE PASSAGEIROS -->
                            <div class="cls-cmp-esp-cg-preview-body-topic-title">
                                <i class="fa-solid fa-users"></i> INFORMAÇÕES DOS PASSAGEIROS
                            </div>                            
                            <!-- FICHA DE PASSAGEIROS: CABEÇALHO -->
                            <div class="cls-cmp-esp-cg-preview-body-passenger cls-cmp-esp-cg-preview-body-topic-header">
                                <div class="cls-cmp-esp-cg-preview-body-passenger-sequency">
                                    #
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-passenger-document">
                                    DOCUMENTO
                                </div>
                                <div class="cls-cmp-esp-cg-preview-body-passenger-name">
                                    NOME
                                </div>                                
                                <div class="cls-cmp-esp-cg-preview-body-passenger-phone-emergency">
                                    CONTATO EMERGÊNCIA
                                </div>
                            </div>
                            <!-- FICHA DE PASSAGEIROS: DADOS -->                            
                            <ng-container
                                *ngFor="let objPassenger of this.dataSheet.fk_pax_data_list; let i = index">
                                <div class="cls-cmp-esp-cg-preview-body-passenger">
                                    <div class="cls-cmp-esp-cg-preview-body-passenger-sequency">
                                        {{ (i + 1).toString().padStart(2, '0') }}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-body-passenger-document">
                                        {{this.getDocumentTypeName(objPassenger.document_data.document_type)}}: {{objPassenger.document_data.document_in}}
                                    </div>
                                    <div class="cls-cmp-esp-cg-preview-body-passenger-name">
                                        {{objPassenger.name_data.full_name}} &nbsp;
                                    </div>                                
                                    <div class="cls-cmp-esp-cg-preview-body-passenger-phone-emergency">
                                        {{objPassenger.complementary_data.emergency_data.masked}} &nbsp;
                                    </div>
                                </div>
                            </ng-container>                                                                                       
                        </div>
                    </div>
                </div>
            </div>                              
        </div>
    </div>
</div>

