//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initWeightBalanceFilledForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        weight_balance_filled_id: objFormBuilder.control(null),    
        fk_weight_balance_data_id: [null],            
        filled_to: objFormBuilder.group({
            fk_aircraft_data_id: [null],
            aircraft_mark: [null]
        }),
        flight_data: objFormBuilder.group({
            flight_eet: ["01:20"],
            taxi_eet: ["00:10"],
            flight_fuel_rate: [250],
            flight_fuel_rate_unit: ["lb_h"],
            taxi_fuel_rate: [150],
            taxi_fuel_rate_unit: ["lb_h"],
            fuel_type:["jeta"]
        }),
        path_perfil_image: [[]],
        header_data: objFormBuilder.group({
            name: [null],
            description_config: [null],
            aircraft_type: [null],
            aircraft_model: [null],
            aircraft_icao_code: [null],
            aircraft_manual_id: [null],
            aircraft_manual_date: [null],
            aircraft_manual_version: [null],
            calibration_date_utc: [null],
            calibration_next_date_utc: [null],
            calibration_observation: [null]
        }),
        unit_data: objFormBuilder.group({
            weight: ["lb"],
            arm: ["in"],
            fuel: ["kg"],
            fuel_rate: ["lb_h"],
            chart_x_unit: [null],
            chart_y_unit: [null]
        }),
        cg_data: [[]],
        weight_data: objFormBuilder.group({
            bem: objFormBuilder.group({
                weight: [null],
                longitudinal_arm: [null],
                lateral_arm: [null]
            }),            
            max_takeoff_weight: [null],
            max_takeoff_weight_unit: [null],
            max_landing_weight: [null],
            max_landing_weight_unit: [null],
            max_ramp_weight: [null],
            max_ramp_weight_unit: [null],
            max_zero_fuel_weight: [null],
            max_zero_fuel_weight_unit: [null],
        }),
        components_input_data: objFormBuilder.array([]),
        components_constant_data: objFormBuilder.array([]),
        components_fuel_data: objFormBuilder.array([]),
        components_external_data: objFormBuilder.array([]),        
        chart_longitudinal_data: objFormBuilder.array([]),
        chart_lateral_data: objFormBuilder.array([]),
        matrixOfElements: [[]],
        calculation_cg: objFormBuilder.group({
            table: [[]],
            result: [null],
            remark: [null]
        }),      
    });
}

