//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

// ***** RXJS *****
import { Subscription } from 'rxjs';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { FrameHorizontal01Component } from '../../_library/layouts/frames/dashboard/frame-horizontal-01/frame-horizontal-01.component';
import { FrameVertical01Component } from '../../_library/layouts/frames/dashboard/frame-vertical-01/frame-vertical-01.component';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { UserConfigService } from '../../_services/app-config/user-config/user-config.service';
import { SystemConfigService } from '../../_services/app-config/system-config/system-config.service';
import { ThemeChangeService } from '../../_services/theme-change/theme-change.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IUserConfigsSinglePageApplication } from '../../_library/interfaces/IUserConfigs';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-dashboard-spa',
  standalone: true,
  imports: [
    CommonModule,
    FrameHorizontal01Component,
    FrameVertical01Component
  ],
  templateUrl: './dashboard-spa.component.html',
  styleUrl: './dashboard-spa.component.css',
})
export class DashboardSpaComponent implements OnInit, OnDestroy {
  //#region "|--- PROPERTIES---|"
  // ***** PRIVATE ***** //
  private _subscriptionThemeService!: Subscription;
  private _system!: string;

  mainMenu!: any;

  // ***** DASHBOARD-FRAME ***** //
  layoutFrame!: string | null;

  // ***** HEADER ***** //
  topHeader!: string | null;
  topHorizontalMenu!: string | null;

  // ***** COLOR & THEMES ***** //
  colorSchema!: string | null;
  theme!: string | null;
  //#endregion

  constructor(
    private _userConfigService: UserConfigService,
    private _themeService: ThemeChangeService,
    private _activeRoute: ActivatedRoute,
    private _systemService: SystemConfigService
  ) { }

  ngOnInit(): void {
    this._initVariables();




    // Neste ponto você está se inscrevendo para receber notificações quando o Thema mudar, pois há um observer gerenciando isto.
    // Neste caso, se você tiver vários componentes que precisam ser notificados, header, body e footer, basta você colocar esta atribuição no Init de cada um.
    //this._subscriptionThemeService = this._themeService.theme$.subscribe(xTheme => {
    //  this.theme = xTheme;

    //  document.body.setAttribute('data-theme', this.theme);
    //})

    //this._settingUserConfig();
  }

  ngOnDestroy(): void {
    //this._subscriptionThemeService.unsubscribe();
  }

  //#region "|--- PRIVATE METHODS ---|"
  private _initVariables() {
    // Recupera o valor do "frame" que foi passado na rota.
    this.layoutFrame = this._activeRoute.snapshot.data['frame'];

    // Recupera o valor do "system" que foi passado na rota.
    this._system = this._activeRoute.snapshot.data['system'];
    
    // Atribui o valor do "frame" que foi passado na rota para o atributo "data-frame" do body.
    document.body.setAttribute('data-system', this._system);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  isFrameHorizontal01() {
    return this.layoutFrame === 'horizontal_01';
  }

  isFrameVertical01() {
    return this.layoutFrame === 'vertical_01';
  }
  //#endregion








  /*private _initDefaultValues() {
    this.layoutFrame = 'vertical';
    this.topHeader = 'true';
    this.topHorizontalMenu = 'true';
    this.colorSchema = 'light';
    this.theme = 'light';
  }

  private _settingUserConfig() {

    // Atribui o valor do "frame" que foi passado na rota para o atributo "data-frame" do body. 
    document.body.setAttribute('data-frame', this.dashboard_frame);

    this._userConfigService.getUserConfigSinglePageApplication().subscribe((xUserConfig: IUserConfigsSinglePageApplication) => {

      this.topHeader = xUserConfig.top_header ? xUserConfig.top_header : "";
      this.topHorizontalMenu = xUserConfig.top_horizontal_menu ? xUserConfig.top_horizontal_menu : "";
      this.layoutFrame = xUserConfig.layout_frame ? xUserConfig.layout_frame : "";
      this.colorSchema = xUserConfig.color_schema ? xUserConfig.color_schema : "";
      this.theme = xUserConfig.theme ? xUserConfig.theme : "";

      document.body.setAttribute('data-top_header', this.topHeader);
      document.body.setAttribute('data-top_horizontal_menu', this.topHorizontalMenu);
      document.body.setAttribute('data-layout_frame', this.layoutFrame);
      document.body.setAttribute('data-color_schema', this.colorSchema);
      document.body.setAttribute('data-theme', this.theme);
    });
  }
  

  
  toggleTheme() {
    // Alterna o tema entre 'light' e 'dark'
    const newTheme = this.theme === 'light' ? 'dark' : 'light';

    // Vai disparar um "trigger", que informa a mudança de tema para todos os componentes que estão cadastrados.
    this._themeService.setTheme(newTheme);
  }
  //#endregion*/
}
