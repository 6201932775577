//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';

//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-app-platform--aircraft-data-summary',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './aircraft-data-summary.component.html'
})
export class AircraftDataSummaryComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  constructor() { }

  ngOnInit(): void { }
}
