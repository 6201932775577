//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initFlightCrewForm(xFlightRole: string): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        crew_data_id: [null],
        profissional_ni: [null],
        full_name: [null],
        flight_role: [xFlightRole]
    });
}