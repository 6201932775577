//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initChartDataForm(xChartDataId: string, xChartType: string): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        chart_data_id: [xChartDataId],
        chart_type: [xChartType],
        label: [null],
        background_image: [null],
        show_grid: [true],
        x_min: [0],
        x_max: [100],
        x_unit: ["in"],
        x_step_axis: [10],
        x_step_grid: [5],
        x_scale: ["linear"],
        x_show_opposite_unit: ["mm"],
        y_min: [0],
        y_max: [1000],
        y_unit: ["lb"],
        y_step_axis: [100],
        y_step_grid: [50],
        y_scale: ["linear"],
        y_show_opposite_unit: ["kg"],
        limits_data: [[]]
    });
}