//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //

// ***** MOCK ***** //

// ***** CLASS ***** //
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';

// ***** COMPONENT ***** //
import { AddressesComponentComponent } from '../../../../../../../_components/layout/addresses-component/addresses-component.component';
import { ContactsComponentComponent } from '../../../../../../../_components/layout/contacts-component/contacts-component.component';
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAllCountries } from '../../../../../../../../_library/static_options/staticOptionsAllCountries';
import { staticOptionsGender } from '../../../../../../../../_library/static_options/staticOptionsGender';
import { staticOptionsDocumentsType } from '../../../../../../../../_library/static_options/staticOptionsDocumentsType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT DOMAINS ---|"
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-crew-register',
  standalone: true,
  imports: [
    AddressesComponentComponent,
    ContactsComponentComponent,
    CommonModule,
    TitleFormTopicComponent,
    PrimengComponentsModule
  ],
  templateUrl: './tab-crew-register.component.html'
})
export class TabCrewRegisterComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabCrewData = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public buttonActions!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** FORM ***** //

  // ***** DIALOG ***** //
  public setupShowWaitingDialog!: IWaitingDialog;
  public setupHiddenWaitingDialog!: IWaitingDialog;

  // ***** DROP-DOWN OPTIONS ***** //
  public optionsNationalityCountries!: IDropDownOptionsCountry[];
  public optionsGender!: IDropDownOptionsBasic[];
  public optionsDocumentsType!: IDropDownOptionsBasic[];

  // ***** OTHERS ***** //

  //#endregion

  constructor(
    private _messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- HANDLE FORM EVENTS ---|"  
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;
    this.optionsNationalityCountries = staticOptionsAllCountries;
    this.optionsGender = staticOptionsGender;
    this.optionsDocumentsType = staticOptionsDocumentsType;

    this.setupShowWaitingDialog = {
      show_dialog: true,
      type_waiting: DIALOG_WAITING_TYPE.ANAC_RAB
    };
    
    this.setupHiddenWaitingDialog = {
      show_dialog: false,
      type_waiting: DIALOG_WAITING_TYPE.DEFAULT
    };

    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
    /**
     * @author GASPAR
     * @date xxxxx
     * @version 1.0.0
     *
     * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
     *
     * @abstract Os botões estão no Toolbar do formulário do REGISTRO AERONÁUTICO.
     */
    callbackClickButton(xButtonAction: string): void {
      this.actionTabCrewData.emit(xButtonAction);
  }
  //#endregion
}
