//#region "|--- IMPORT MODULES/PACKAGES ---|"

//#endregion

export class AviationToolsHandlerClass {
    private _fuelDensities: { [key: string]: { [key: string]: number } } = {
        'jeta': {
            'kg/l': 0.804, // Densidade em kg/l
            'lb/gal': 6.7  // Densidade em lb/gal
        },
        'avgas': {
            'kg/l': 0.72,  // Densidade em kg/l
            'lb/gal': 6.0  // Densidade em lb/gal
        },
        // Adicione outros tipos de combustível conforme necessário
    };

    /**
     * @author COPILOT
     * @date 2024-10-16
     * @version 1.0.0
     * 
     * Calcula o consumo de combustível de uma aeronave.
     * 
     * @param xFuelType Tipo de combustível (ex: "Jet A", "Avgas")
     * @param xFuelRate Taxa de consumo de combustível
     * @param xFuelRateUnit Unidade da taxa de consumo (litros/hora, libras/hora, quilogramas/hora, galões/hora)
     * @param time Tempo desejado no formato hh:mm
     * @param desiredUnit Unidade desejada para o retorno (litros, quilogramas, galões, libras)
     * @returns Consumo de combustível na unidade desejada
     */
    calculateFuelConsumption(
        xFuelType: string,
        xFuelRate: number,
        xFuelRateUnit: string,
        xTime: string,
        xDesiredUnit: string
    ): number {
        // Converter o tempo para horas
        const [hours, minutes] = xTime.split(':').map(Number);
        const totalTimeInHours = hours + minutes / 60;
        let tempFuelRateUnit = xFuelRateUnit;

        if (tempFuelRateUnit && tempFuelRateUnit != null) {
            tempFuelRateUnit = tempFuelRateUnit.replaceAll("_", "/");
        }

        // Converter a taxa de consumo para a unidade desejada
        const fuelRateInDesiredUnit = this._convertFuelRate(xFuelType, xFuelRate, tempFuelRateUnit, xDesiredUnit);

        // Calcular o consumo total de combustível
        const totalFuelConsumption = fuelRateInDesiredUnit * totalTimeInHours;

        return totalFuelConsumption;
    }

    // Calculadora Online para ter uma base se tudo esta ok...
    // https://aeroinfo.com.br/combustivel
    /**
     * @author COPILOT
     * @date 2024-10-16
     * @version 1.0.0
     * 
     * @description
     * 
     * @param xFuelType 
     * @param xFuelAmount 
     * @param xCurrentUnit 
     * @param xDesiredUnit 
     * @returns 
     */
    convertFuelUnit(
        xFuelType: string,
        xFuelAmount: number,
        xCurrentUnit: string,
        xDesiredUnit: string
    ): number {
        const conversionRates: { [key: string]: number } = {
            'l': 1,
            'gal': 3.78541,
            'lb': 0.45359237,
            'kg': 1
        };

        if (!this._fuelDensities[xFuelType]) {
            throw new Error(`Tipo de combustível desconhecido: ${xFuelType}`);
        }

        // Converter a quantidade de combustível para a unidade base (litros)
        let fuelAmountInBaseUnit: number;
        switch (xCurrentUnit) {
            case 'l':
                fuelAmountInBaseUnit = xFuelAmount;
                break;
            case 'gal':
                fuelAmountInBaseUnit = xFuelAmount * conversionRates['gal'];
                break;
            case 'lb':
                fuelAmountInBaseUnit = xFuelAmount * conversionRates['lb'] / this._fuelDensities[xFuelType]['kg/l'];
                break;
            case 'kg':
                fuelAmountInBaseUnit = xFuelAmount / this._fuelDensities[xFuelType]['kg/l'];
                break;
            default:
                throw new Error(`Unidade de combustível desconhecida: ${xCurrentUnit}`);
        }

        // Converter da unidade base para a unidade desejada
        let fuelAmountInDesiredUnit: number;
        switch (xDesiredUnit) {
            case 'l':
                fuelAmountInDesiredUnit = fuelAmountInBaseUnit;
                break;
            case 'gal':
                fuelAmountInDesiredUnit = fuelAmountInBaseUnit / conversionRates['gal'];
                break;
            case 'lb':
                fuelAmountInDesiredUnit = fuelAmountInBaseUnit * this._fuelDensities[xFuelType]['kg/l'] / conversionRates['lb'];
                break;
            case 'kg':
                fuelAmountInDesiredUnit = fuelAmountInBaseUnit * this._fuelDensities[xFuelType]['kg/l'];
                break;
            default:
                throw new Error(`Unidade desejada desconhecida: ${xDesiredUnit}`);
        }

        return parseFloat(fuelAmountInDesiredUnit.toFixed(2));
    }

    convertWeightUnit(xWeightAmount: number, xCurrentUnit: string, xDesiredUnit: string): number {
        const conversionRates: { [key: string]: number } = {
            'kg': 1,
            'lb': 0.45359237
        };

        // Converter a quantidade de peso para a unidade base (quilogramas)
        let weightAmountInBaseUnit: number;
        switch (xCurrentUnit) {
            case 'kg':
                weightAmountInBaseUnit = xWeightAmount;
                break;
            case 'lb':
                weightAmountInBaseUnit = xWeightAmount * conversionRates['lb'];
                break;
            default:
                throw new Error(`Unidade de peso desconhecida: ${xCurrentUnit}`);
        }

        // Converter da unidade base para a unidade desejada
        let weightAmountInDesiredUnit: number;
        switch (xDesiredUnit) {
            case 'kg':
                weightAmountInDesiredUnit = weightAmountInBaseUnit;
                break;
            case 'lb':
                weightAmountInDesiredUnit = weightAmountInBaseUnit / conversionRates['lb'];
                break;
            default:
                throw new Error(`Unidade desejada desconhecida: ${xDesiredUnit}`);
        }

        return parseFloat(weightAmountInDesiredUnit.toFixed(2));
    }

    /**
     * Converte a taxa de consumo de combustível para a unidade desejada.
     * @param xFuelType Tipo de combustível
     * @param xFuelRate Taxa de consumo de combustível
     * @param xFromUnit Unidade da taxa de consumo
     * @param toUnit Unidade desejada
     * @returns Taxa de consumo de combustível na unidade desejada
     */
    private _convertFuelRate(xFuelType: string, xFuelRate: number, xFromUnit: string, xToUnit: string): number {
        const conversionRates: { [key: string]: number } = {
            'l/h': 1,
            'lb/h': 0.45359237,
            'kg/h': 1,
            'gal/h': 3.78541
        };

        if (!this._fuelDensities[xFuelType]) {
            throw new Error(`Tipo de combustível desconhecido: ${xFuelType}`);
        }

        // Converter a taxa de consumo para litros/hora ou quilogramas/hora
        let fuelRateInBaseUnit: number;
        switch (xFromUnit) {
            case 'l/h':
                fuelRateInBaseUnit = xFuelRate;
                break;
            case 'lb/h':
                fuelRateInBaseUnit = xFuelRate * conversionRates['lb/h'];
                break;
            case 'kg/h':
                fuelRateInBaseUnit = xFuelRate;
                break;
            case 'gal/h':
                fuelRateInBaseUnit = xFuelRate * conversionRates['gal/h'];
                break;
            default:
                throw new Error(`Unidade de taxa de consumo desconhecida: ${xFromUnit}`);
        }

        // Converter de base unit para a unidade desejada
        let fuelRateInDesiredUnit: number;
        switch (xToUnit) {
            case 'l':
                fuelRateInDesiredUnit = fuelRateInBaseUnit;
                break;
            case 'lb':
                fuelRateInDesiredUnit = fuelRateInBaseUnit / conversionRates['lbs/h'];
                break;
            case 'kg':
                fuelRateInDesiredUnit = fuelRateInBaseUnit;
                break;
            case 'gal':
                fuelRateInDesiredUnit = fuelRateInBaseUnit / conversionRates['gal/h'];
                break;
            default:
                throw new Error(`Unidade desejada desconhecida: ${xToUnit}`);
        }

        // Ajustar para a densidade do combustível se necessário
        if (xToUnit === 'lb' || xToUnit === 'kg') {
            const density = this._fuelDensities[xFuelType]['kg/l'];
            fuelRateInDesiredUnit *= density;
        } else if (xToUnit === 'gal') {
            const density = this._fuelDensities[xFuelType]['lb/gal'];
            fuelRateInDesiredUnit /= density;
        }

        return fuelRateInDesiredUnit;
    }
}