//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleIamAdmService {
  //#region "|--- PROPERTIES---|" 
  public urlIamService: string;
  //#endregion

  constructor(private _http: HttpClient) {
    if (settingConfig.API_IAM_ADM_PORT == "443") {
      this.urlIamService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlIamService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_IAM_ADM_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- LOGIN SERVICES---|" 
  loginAdm(credentials: { id?: string; password: string }): Observable<any> {
    return this._http.post(`${this.urlIamService}/adm/login`, credentials);
  }
  //#endregion


  saveAdmToken(token: string): void {
    localStorage.setItem('authAdmToken', token);
  }

  getAdmToken(): string | null {
    return localStorage.getItem('authAdmToken');
  }

  logoutAdm(): void {
    this._clearUserCredentials();
  }


  //#region "|--- LOGIN SERVICES---|" 
  getAdmMainMenu(): Observable<any> {
    if (localStorage.getItem('userAdmMainMenu')) {
      return new Observable(observer => {
        observer.next(JSON.parse(localStorage.getItem('userAdmMainMenu') || ''));
        observer.complete();
      });
    } else {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

      return this._http.get<any>(`${this.urlIamService}/${settingConfig.API_IAM_ADM_URL_SEGMENT}/user/main/menu`, { headers })
        .pipe(
          map((xResponse: any) => {
            if (xResponse.status_code == HTTP_STATUS.OK) {
              localStorage.setItem('userAdmMainMenu', JSON.stringify(xResponse.data));

              return xResponse.data[0].data;
            }
          }),
          catchError((xError) => _handleError(xError, null))
        )
    }
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|" 
  private _clearUserCredentials(): void {
    localStorage.removeItem('authAdmToken');
    localStorage.removeItem('userAdmMainMenu');
  }
  //#endregion
}
