//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** RXJS ***** //
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../config/settingConfig';

// ***** HANDLER ERROR ***** //
import { _handleError } from '../_common/handlerError';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ModuleAircraftService {
  //#region "|--- PROPERTIES---|" 
  urlAircraftModuleService: string;
  //#endregion

  constructor(private _http: HttpClient) {
    if (settingConfig.API_AIRCRAFT_PORT == "443") {
      this.urlAircraftModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlAircraftModuleService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIRCRAFT_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- MÉTODOS DO FORMULÁRIO DE DADOS DE REGISTRO DA AERONAVE ---|"
  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método responsável por SALVAR os dados relacionados ao módulo: AIRCRAFT DATA.
   * 
   * @param xAircraftDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele
   * @returns 
   */
  saveAircraftData(xAircraftDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>(`${this.urlAircraftModuleService}/${settingConfig.API_AIRCRAFT_URL_SEGMENT}/aircraft/data`, xAircraftDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xAircraftDataForm))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método responsável por ATUALIZAR os dados relacionados ao módulo: AIRCRAFT DATA.
   * 
   * @param aircraftDataForm - tem que passar o form, pois o tratamento do erro só é possível com ele
   * @param xAircraftDataId
   * @returns
   */
  updateAircraftData(xAircraftDataId: string, xAircraftDataForm: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.put<any>(`${this.urlAircraftModuleService}/${settingConfig.API_AIRCRAFT_URL_SEGMENT}/aircraft/data/${xAircraftDataId}`, xAircraftDataForm.getRawValue(), { headers })
      .pipe(
        catchError((xError) => _handleError(xError, xAircraftDataForm))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Método responsável por RECUPERAR TODOS os dados relacionados ao módulo: AIRCRAFT DATA.
   * 
   * @returns 
   */
  getAllAircraftDataList(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlAircraftModuleService}/${settingConfig.API_AIRCRAFT_URL_SEGMENT}/aircraft/data/list`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Método responsável por RECUPERAR BY ID os dados relacionados ao módulo: AIRCRAFT DATA.
   * 
   * @param xAircraftDataId
   * @returns 
   */
  getAircraftDataById(xAircraftDataId: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlAircraftModuleService}/${settingConfig.API_AIRCRAFT_URL_SEGMENT}/aircraft/data/${xAircraftDataId}`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-22
   * @version 1.0.0
   * 
   * @description Método que recupera os dados de Aeronaves cadastradas no Brasil, junto ao RAB da ANAC.
   * 
   * @param xAircraftMark 
   * @returns 
   */
  getAnacRabData(xAircraftMark: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.get<any>(`${this.urlAircraftModuleService}/${settingConfig.API_AIRCRAFT_URL_SEGMENT}/aircraft/br/rab/${xAircraftMark}`, { headers })
      .pipe(
        map((xResponse: any) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            return xResponse.data[0].data;
          }
        }),
        catchError((xError) => _handleError(xError, null))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   *  @description Método responsável por ATUALIZAR a Folha de Peso e Balanceamento da Aeronave.
   * 
   * @param xAircraftDataId 
   * @param objWeightBalanceSheet 
   * @returns 
   */
  updateAircraftWeightBalanceSheet(xAircraftDataId: string, objWeightBalanceSheet: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>(`${this.urlAircraftModuleService}/${settingConfig.API_AIRCRAFT_URL_SEGMENT}/aircraft/weight/balance/sheet/${xAircraftDataId}`, objWeightBalanceSheet, { headers })
      .pipe(
        catchError((xError) => _handleError(xError))
      )
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description Método responsável por APAGAR a Folha de Peso e Balanceamento da Aeronave.
   * 
   * @param xAircraftId 
   * @param objWeightBalanceSheet 
   * @returns 
   */
  deleteAircraftWeightBalanceSheet(xIdAircraftData: string, xIdWeightBalanceSheet: any): Observable<any> {    
    return this._http.delete<any>(`${this.urlAircraftModuleService}/${settingConfig.API_AIRCRAFT_URL_SEGMENT}/aircraft/weight/balance/sheet/${xIdAircraftData}/${xIdWeightBalanceSheet}`)
      .pipe(
        catchError((xError) => _handleError(xError))
      )
  }
  //#endregion
}
