import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'sunrise-ftd-web-portal-angular-aircraft-dashboard-general',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aircraft-dashboard-general.component.html',
  styleUrl: './aircraft-dashboard-general.component.css',
})
export class AircraftDashboardGeneralComponent {}
