//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-app-platform--title-form-topic',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './title-form-topic.component.html'
})
export class TitleFormTopicComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() titleFormTopic!: string;
  //#endregion

  constructor() { }

  ngOnInit(): void { }
}
