<section data-page="cls-pge-app-list">
    <sunrise-toast-message></sunrise-toast-message>
    <!-- <p-confirmDialog class="cls-component-confirm-dialog-default" /> -->
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
    <div class="cls-frame-dashboard-horizontal-main-content">
        <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="false">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-solid fa-list"></i>
                            <span>{{this.classInternationalization.getTranslation("ttl_CrewRegisteredSystem")}}</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-table 
                        [value]="this.listCrewData" 
                        [tableStyle]="{ 'min-width': '50rem' }"
                        [paginator]="true"
                        [rows]="10"
                        [rowsPerPageOptions]="[10, 15, 20]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="{{this.classInternationalization.getTranslation('lbl_PageFromTo')}}"
                        styleClass="p-datatable-gridlines p-datatable-striped"
                        class="cls-cmp-ptv-table-page-list">
                    <ng-template pTemplate="caption">
                        <div class="cls-cmp-ptv-table-page-list-caption">
                            <div class="cls-cmp-ptv-table-page-list-caption-left">
                                {{this.classInternationalization.getTranslation("lbl_Total")}}: {{this.listCrewData ? this.listCrewData.length : 0 }}
                            </div>
                            <div class="cls-cmp-ptv-table-page-list-caption-right">                                
                                <div>
                                    <p-button 
                                        label="{{this.classInternationalization.getTranslation('btn_ReloadTable')}}"
                                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                                         
                                        icon="fa-solid fa-refresh" 
                                        severity="secondary"
                                        (click)="this.onClickTableList(this.actionType.RELOAD)" />
                                </div>
                                <div>
                                    <p-button 
                                        label="{{this.classInternationalization.getTranslation('btn_NewCrewData')}}"
                                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                                         
                                        icon="fa-solid fa-plus"
                                        severity="success"
                                        (click)="this.onClickTableList(this.actionType.NEW)" />
                                </div>
                            </div>
                        </div>                            
                    </ng-template>
                    <ng-template pTemplate="header"> 
                        <tr>
                            <th style="width:6%; text-align: center;">{{this.classInternationalization.getTranslation('lbl_Image').toUpperCase()}}</th>
                            <th style="width:10%;">{{this.classInternationalization.getTranslation('lbl_DocumentProfessional').toUpperCase()}}</th>
                            <th style="width:20%;">{{this.classInternationalization.getTranslation('lbl_FullName').toUpperCase()}}</th>
                            <th style="width:10%;">{{this.classInternationalization.getTranslation('lbl_Aircraft').toUpperCase()}}</th>
                            <th style="width:14%;"></th>
                        </tr>                           
                    </ng-template>
                    <ng-template pTemplate="body" let-itemArray>         
                        <tr>
                            <td class="cls-cmp-ptv-table-page-list-td-image" style="text-align: center;">
                                <p-image 
                                    [src]="this.getUrlToShowImage(itemArray.crew_data_id, itemArray.avatar_image)"
                                    [previewImageSrc]="this.getUrlToShowImage(itemArray.crew_data_id, itemArray.avatar_image)"
                                    alt="Image" 
                                    [preview]="true" 
                                    width="45rem" 
                                    height="45rem"
                                    [imageStyle]="{'border-radius': '50%'}" />
                            </td>
                            <td>
                                {{ itemArray.documents_profissional[0].document_type }}: {{ itemArray.documents_profissional[0].document_in }}
                            </td>
                            <td>
                                {{ itemArray.name_data.full_name }}
                            </td>
                            <td>
                                <div *ngFor="let itemAircraft of itemArray.fk_aircraft_list">                                   
                                    <div>
                                        <i class="fa-solid fa-check"></i> {{ this.classAviationTools.setAircraftMark(itemAircraft.fk_aircraft_data.aircraft_mark) }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="cls-cmp-ptv-table-page-list-td-button">
                                    <p-button 
                                        icon="fa-solid fa-edit" 
                                        [rounded]="true" 
                                        [outlined]="true" 
                                        severity="info"
                                        (onClick)="this.onClickItemTableList(this.actionType.EDIT, itemArray.crew_data_id)" />
                                   <!-- <p-button icon="fa-solid fa-trash-alt" severity="danger" [rounded]="true"
                                        [outlined]="true"
                                        (onClick)="callbackItemClickButton(this.actionType.DELETE, itemArray.aircraft_data_id)" />
                                --></div>
                            </td>
                        </tr>         
                    </ng-template>
                </p-table>
            </ng-template>
        </p-panel>                
    </div>
</section>