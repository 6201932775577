//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-ui-primeng-label',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './label.component.html'
})
export class LabelComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() idField!: string;

  @Input() label!: string;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: number;

  showLabel = true;
  showLabelBold = false;
  //#endregion

  constructor() { }

  ngOnInit(): void {
    this._initVariables();
    this._hasToShowLabelBold();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Verifica se o Label deve ser exibido em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }
  //#endregion
}
