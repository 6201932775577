//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

// ***** CLASS ***** //
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';

// ***** COMPONENT ***** //
import { AircraftInfoCardComponent } from '../../../../../../../_components/layout/aircraft-info-card/aircraft-info-card.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleWeightBalanceService } from '../../../../../../../_services/module-weight-balance/module-weight-balance.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-weight-balance-sheet-list',
  standalone: true,
  imports: [
    AircraftInfoCardComponent,
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './tab-weight-balance-sheet-list.component.html'
})
export class TabWeightBalanceSheetListComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionWeightBalanceDataSheetList = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any
  public typeDialogWaiting!: any;

  // ***** CLASS ***** //
  public classLocalMethods!: any;
  public classModuleMethods!: any;
  public classInternationalization!: any;

  // ***** SHOW DIALOG ***** // 
  public bolShowDialogWaiting!: boolean;
  public bolShowCloneDialog!: boolean;

  // ***** LIST ***** //
  public listWeighBalanceSheetListByAircraft: any[] = []; // Por exceção, vai ser inicializado já na declaração.
  public listAllWeighBalanceSheet: any[] = []; // Por exceção, vai ser inicializado já na declaração.
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();

    this._loadWeightBalanceSheetListByAircraft();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;

    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;

    this.bolShowDialogWaiting = false;
    this.bolShowCloneDialog = false;
  }
  //#endregion 

  //#region "|--- PUBLIC METHODS ---|"  
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
  * @status:
  * @author GASPAR
  * @date 2024-09-24
  * @version 1.0.0
  * 
  * @description 
  *   - Emite a ação do botão clicado para o item da tabela.
  */
  public onClickItemTableListSheet(xButtonAction: string, xIdRegister: string): void {
    if (xButtonAction == ACTION_TYPE.EDIT) {
      this.actionWeightBalanceDataSheetList.emit({ action: xButtonAction, id_register: xIdRegister });
    } else if (xButtonAction == ACTION_TYPE.CLONE) {
      this.actionWeightBalanceDataSheetList.emit({ action: xButtonAction, id_register: xIdRegister });
    } else if (xButtonAction == ACTION_TYPE.DELETE) {
      this._confirmationService.confirm({
        message: `Você deseja APAGAR está Ficha de Peso e Balanceamento?`,
        header: 'Confirmação Apagar a Ficha de Peso e Balanceamento.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        acceptLabel: "CONFIRMAR",
        rejectLabel: "CANCELAR",
        acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
        rejectButtonStyleClass: "cls-component-button-pattern-default",
        key:"weight_balance_sheet_del",
        accept: async () => {
          await this._deleteWeightBalanceSheet(xIdRegister)
        },
        reject: () => {
          this._messageService.add({
            severity: 'warn',
            summary: 'Apagar a Ficha de Peso e Balanceamento.',
            detail: 'O Processo de Apagar a Ficha de Peso e Balanceamento foi Cancelado pelo Usuário.',
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description 
   *   - Emite a ação do botão clicado para o componente pai.
   * 
   * @param xButtonAction 
   */
  public async onClickTableList(xButtonAction: string) {
    if (xButtonAction == ACTION_TYPE.NEW) {
      this.actionWeightBalanceDataSheetList.emit({ action: xButtonAction });
    } else if (xButtonAction == ACTION_TYPE.OPEN_DIALOG) {
      await this._loadWeightBalanceSheetListAll();

      this.bolShowCloneDialog = true;
    } else if (xButtonAction == ACTION_TYPE.RELOAD) {
      await this._loadWeightBalanceSheetListByAircraft();
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-29
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega a lista de fichas de peso e balanceamento para uma determinada aeronave.
   */
  private async _loadWeightBalanceSheetListByAircraft(): Promise<void> {
    await this._moduleWeightBalanceService.getWeightBalanceDataSheetByAircraft(this.formComponent.controls.aircraft_data_id.value).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.listWeighBalanceSheetListByAircraft = [];
          this.listWeighBalanceSheetListByAircraft = xApiResponse.data[0].data;

          

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          setTimeout(() => {
            this._messageService.add({
              severity: 'success',
              summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
              detail: this.classInternationalization.getTranslation('msg_WeightBalanceDataSheetListSuccess'),
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });
          }, this.currentSettings.WAITING_DIALOG_LIFE);
        } else {
          this.listWeighBalanceSheetListByAircraft = [];

          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
            detail: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetListError'),
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        this.listWeighBalanceSheetListByAircraft = [];

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetList'),
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-29
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega TODAS as fichas de peso e balanceamento.
   */
  private async _loadWeightBalanceSheetListAll(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleWeightBalanceService.getAllWeightBalanceDataSheetList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listAllWeighBalanceSheet = [];
            this.listAllWeighBalanceSheet = xApiResponse;
          } else {
            this._messageService.add({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetListToClone'),
              detail: this.classInternationalization.getTranslation('msg_WeightBalanceDataSheetListToCloneError'),
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }

          resolve();
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          this.listAllWeighBalanceSheet = [];

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._messageService.add({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetListToClone'),
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });

          resolve();
        }
      });
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-01
   * @version 1.0.0
   * 
   * @description
   *   - Deleta uma ficha de peso e balanceamento.
   * 
   * @param xIdRegister 
   */
  private async _deleteWeightBalanceSheet(xIdRegister: string): Promise<void> {    
    await this._moduleWeightBalanceService.deleteWeightBalanceSheet(xIdRegister).subscribe({
      next: async (xResponse) => {
        if (xResponse.status_code == HTTP_STATUS.OK) {
          // Verifica se o ID do Weight Balance Sheet está no fk_weight_balance_sheet_list do AIRCRAFT DATA.
          for (let i = 0; i < this.formComponent.controls.fk_weight_balance_sheet_list.controls.length; i++) {
            if (this.formComponent.controls.fk_weight_balance_sheet_list.controls[i].controls.fk_weight_balance_data_sheet_id.value === xIdRegister) {
              this.formComponent.controls.fk_weight_balance_sheet_list.controls.splice(i, 1);
              break;
            }
          }

          // Emite a ação para atualizar os dados do AIRCRAFT DATA
          this.actionWeightBalanceDataSheetList.emit({ "action": this.actionType.DELETE, "id": `` });

          await this._loadWeightBalanceSheetListByAircraft();

          this._messageService.add({
            severity: 'success',
            summary: 'Apagar Folha de Peso e Balanceamento',
            detail: `${xResponse.data[0].message}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Apagar Folha de Peso e Balanceamento',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }
  //#endregion
}