<section class="cls-component-tab-data">
    <p-toolbar class="cls-component-toolbar">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end"></div>
    </p-toolbar>
    <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
        [expandIcon]="'fa-solid fa-chevron-up'">
        <ng-template pTemplate="header">
            <div class="cls-component-panel-default-header">
                <div class="cls-component-panel-default-header-title">
                    <h2>
                        <i class="fa-solid fa-bolt"></i>
                        <span> Dados da Ficha de Peso e Balanceamento</span>
                    </h2>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-form cls-component-form-frame">
                <div class="formgrid">
                    <div class="grid">
                        <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-drop-down label="Configuração #BOLD#"
                                [control]="this.formWeightBalanceFilled.controls.fk_weight_balance_data_id"
                                [arrayOptions]="this.optionsWeightBalanceSheet">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-show-input-data label="Tipo de Aeronave #BOLD#"
                                [dataInput]="this.classModuleMethods.getAircraftTypeLabel(this.formWeightBalanceFilled.controls.header_data.controls.aircraft_type.value)">
                            </lib-ui-primeng-show-input-data>
                        </div>
                        <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-show-input-data label="Modelo Aeronave #BOLD#"
                                [dataInput]="this.formWeightBalanceFilled.controls.header_data.controls.aircraft_model.value">
                            </lib-ui-primeng-show-input-data>
                        </div>
                        <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-show-input-data label="Código ICAO #BOLD#"
                                [dataInput]="this.formWeightBalanceFilled.controls.header_data.controls.aircraft_icao_code.value">
                            </lib-ui-primeng-show-input-data>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-panel>
    <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
        [collapsed]="true" [expandIcon]="'fa-solid fa-chevron-up'">
        <ng-template pTemplate="header">
            <div class="cls-component-panel-default-header">
                <div class="cls-component-panel-default-header-title">
                    <h2>
                        <i class="fa-solid fa-bolt"></i>
                        <span> Unidades de Medidas para Preenchimento</span>
                    </h2>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-form cls-component-form-frame">
                <div class="formgrid">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down label="Peso #BOLD#"
                                [control]="this.formWeightBalanceFilled.controls.unit_data.controls.weight"
                                [arrayOptions]="this.optionsCgWeightUnits">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down label="Braço #BOLD#"
                                [control]="this.formWeightBalanceFilled.controls.unit_data.controls.arm"
                                [arrayOptions]="this.optionsCgArmUnits">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down label="Combustível #BOLD#"
                                [control]="this.formWeightBalanceFilled.controls.unit_data.controls.fuel"
                                [arrayOptions]="this.optionsCgFuelUnits">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">
                            <lib-ui-primeng-drop-down label="Combustível Consumo #BOLD#"
                                [control]="this.formWeightBalanceFilled.controls.unit_data.controls.fuel_rate"
                                [arrayOptions]="this.optionsFuelRateUnits">
                            </lib-ui-primeng-drop-down>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                            <div class="cls-app-platform--component-field-form-advise">
                                <i class="fa-solid fa-info-circle"></i>
                                <span>As unidades de medida dos Gráficos são fixos:
                                    {{this.formWeightBalanceFilled.controls.unit_data.controls.chart_x_unit.value}} x
                                    {{this.formWeightBalanceFilled.controls.unit_data.controls.chart_y_unit.value}}.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-panel>
    <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
        [collapsed]="true" [expandIcon]="'fa-solid fa-chevron-up'">
        <ng-template pTemplate="header">
            <div class="cls-component-panel-default-header">
                <div class="cls-component-panel-default-header-title">
                    <h2>
                        <i class="fa-solid fa-bolt"></i>
                        <span> Dados Combustível</span>
                    </h2>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-form cls-component-form-frame">
                <div class="formgrid">
                    <div class="grid">
                        <div class="col-12 md:col-12 lg:col-12 xl:col-2">
                            <lib-ui-primeng-drop-down label="Tipo Combustível #BOLD#"
                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.fuel_type"
                                [arrayOptions]="this.optionsFuelType">
                            </lib-ui-primeng-drop-down>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-2">
                            <lib-ui-primeng-input-time label="Tempo de Voo #BOLD#"
                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.flight_eet">
                            </lib-ui-primeng-input-time>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="Consumo Taxi"
                                [groupRightText]="normalizingFuelRateUnit(this.formWeightBalanceFilled.controls.flight_data.controls.flight_fuel_rate_unit.value)"
                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.flight_fuel_rate">
                            </lib-ui-primeng-input-number-float>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-2">
                            <lib-ui-primeng-input-time label="Tempo de Taxi"
                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.taxi_eet">
                            </lib-ui-primeng-input-time>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                            <lib-ui-primeng-input-number-float label="Consumo Taxi"
                                [groupRightText]="normalizingFuelRateUnit(this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate_unit.value)"
                                [control]="this.formWeightBalanceFilled.controls.flight_data.controls.taxi_fuel_rate">
                            </lib-ui-primeng-input-number-float>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-panel>
    <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
        [expandIcon]="'fa-solid fa-chevron-up'">
        <ng-template pTemplate="header">
            <div class="cls-component-panel-default-header">
                <div class="cls-component-panel-default-header-title">
                    <h2>
                        <i class="fa-solid fa-bolt"></i>
                        <span> Calculo do Peso e Balanceamento da Aeronave</span>
                    </h2>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-form cls-component-form-frame">
                <div class="formgrid">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-7">
                            <div class="cls-app-platform--component-calc-cg">
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-3">
                                        <div class="cls-app-platform--component-calc-cg-all-units">
                                            <p-inputSwitch [(ngModel)]="this.showAllUnits" />
                                            <div class="cls-app-platform--component-calc-cg-all-units-label">Mostrar
                                                Todas Unidades</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                                        <p-panel class="cls-component-panel-form-fieldset">
                                            <ng-template pTemplate="header">
                                                <div class="cls-component-panel-form-fieldset-header">
                                                    <div class="cls-component-panel-form-fieldset-header-title">
                                                        <h3>
                                                            <i class="fa-solid fa-user-tie"></i>
                                                            <span> Tripulação</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p-table
                                                    [value]="this.formWeightBalanceFilled.controls.components_input_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"
                                                    class="cls-app-platform--component-calc-cg-table"
                                                    styleClass="p-datatable-striped p-datatable-gridlines">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th style="width:5%; text-align: center"></th>
                                                            <th style="width:15%; text-align: left">Descrição</th>
                                                            <th style="width:14%; text-align: center">Peso</th>
                                                            <ng-container *ngIf="showAllUnits === true">
                                                                <th style="width:12%; text-align: center">Peso</th>
                                                            </ng-container>
                                                            <th style="width:14%; text-align: center">B. Long.</th>
                                                            <th style="width:16%; text-align: center">M. Long.</th>
                                                            <th style="width:14%; text-align: center">B. Lat.</th>
                                                            <th style="width:16%; text-align: center">M. Lat.</th>
                                                            <th style="width:10%; text-align: center">Ações</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container
                                                            *ngIf="this.isPilotSeat(ItemArray.controls.type_component.value)">
                                                            <tr>
                                                                <td style="width:5%; text-align: center">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex]"
                                                                        (onChange)="callbackOnChangeSwitch($event)" />
                                                                </td>
                                                                <td style="width:15%; text-align: left">
                                                                    {{ ItemArray.controls.description.value }}</td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        <lib-ui-primeng-input-number-float
                                                                            [groupRightText]="this.formWeightBalanceFilled.controls.unit_data.controls.weight.value"
                                                                            [control]="ItemArray.controls.weight">
                                                                        </lib-ui-primeng-input-number-float>
                                                                    </ng-container>
                                                                </td>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                </ng-container>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:10%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        <div
                                                                            class="cls-app-platform--component-calc-cg-table-item-button">
                                                                            <p-menu #menu
                                                                                [model]="createMenuItemCrewPaxData(ItemArray, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)"
                                                                                [style]="{'width': 'auto'}"
                                                                                [popup]="true" appendTo="body" />
                                                                            <p-button (onClick)="menu.toggle($event)"
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info" />
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-panel>
                                        <p-panel class="cls-component-panel-form-fieldset">
                                            <ng-template pTemplate="header">
                                                <div class="cls-component-panel-form-fieldset-header">
                                                    <div class="cls-component-panel-form-fieldset-header-title">
                                                        <h3>
                                                            <i class="fa-solid fa-users"></i>
                                                            <span> Passageiros</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p-table
                                                    [value]="this.formWeightBalanceFilled.controls.components_input_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"
                                                    class="cls-app-platform--component-calc-cg-table"
                                                    styleClass="p-datatable-striped p-datatable-gridlines">
                                                    <ng-template pTemplate="header">
                                                        <tr *ngIf="true">
                                                            <th
                                                                style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <ng-container *ngIf="showAllUnits === true">
                                                                <th
                                                                    style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </ng-container>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container
                                                            *ngIf="this.isPaxSeat(ItemArray.controls.type_component.value)">
                                                            <tr>
                                                                <td style="width:5%; text-align: center">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex]"
                                                                        (onChange)="callbackOnChangeSwitch($event)" />
                                                                </td>
                                                                <td style="width:15%; text-align: left">
                                                                    {{ ItemArray.controls.description.value }}</td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        <lib-ui-primeng-input-number-float
                                                                            [groupRightText]="this.formWeightBalanceFilled.controls.unit_data.controls.weight.value"
                                                                            [control]="ItemArray.controls.weight">
                                                                        </lib-ui-primeng-input-number-float>
                                                                    </ng-container>
                                                                </td>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            </span>

                                                                        </ng-container>
                                                                    </td>
                                                                </ng-container>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}

                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}

                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}

                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}

                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:10%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        <div
                                                                            class="cls-app-platform--component-calc-cg-table-item-button">
                                                                            <p-menu #menu
                                                                                [model]="createMenuItemCrewPaxData(ItemArray, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)"
                                                                                appendTo="body"
                                                                                [style]="{'width': 'auto'}"
                                                                                [popup]="true" />
                                                                            <p-button (onClick)="menu.toggle($event)"
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info" />
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-panel>
                                        <p-panel class="cls-component-panel-form-fieldset">
                                            <ng-template pTemplate="header">
                                                <div class="cls-component-panel-form-fieldset-header">
                                                    <div class="cls-component-panel-form-fieldset-header-title">
                                                        <h3>
                                                            <i class="fa-solid fa-suitcase-rolling"></i>
                                                            <span> Carga Interna</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p-table
                                                    [value]="this.formWeightBalanceFilled.controls.components_input_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"
                                                    class="cls-app-platform--component-calc-cg-table"
                                                    styleClass="p-datatable-striped p-datatable-gridlines">
                                                    <ng-template pTemplate="header">
                                                        <tr *ngIf="true">
                                                            <th
                                                                style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <ng-container *ngIf="showAllUnits === true">
                                                                <th
                                                                    style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </ng-container>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container
                                                            *ngIf="this.isInternalLoad(ItemArray.controls.type_component.value)">
                                                            <tr>
                                                                <td style="width:5%; text-align: center">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex]"
                                                                        (onChange)="callbackOnChangeSwitch($event)" />
                                                                </td>
                                                                <td style="width:15%; text-align: left">
                                                                    {{ ItemArray.controls.description.value }}</td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        <lib-ui-primeng-input-number-float
                                                                            [groupRightText]="this.formWeightBalanceFilled.controls.unit_data.controls.weight.value"
                                                                            [control]="ItemArray.controls.weight">
                                                                        </lib-ui-primeng-input-number-float>
                                                                    </ng-container>
                                                                </td>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                </ng-container>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                        <span                                                                            
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">                                                                            
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:10%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[0][rowIndex] === true">
                                                                        <div
                                                                            class="cls-app-platform--component-calc-cg-table-item-button">
                                                                            <p-menu #menu
                                                                                [model]="this.createMenuItemLoad(ItemArray, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, ItemArray.controls.max_weight.value)"
                                                                                appendTo="body"
                                                                                [style]="{'width': 'auto'}"
                                                                                [popup]="true" />
                                                                            <p-button (onClick)="menu.toggle($event)"
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info" />
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-panel>
                                        <p-panel class="cls-component-panel-form-fieldset">
                                            <ng-template pTemplate="header">
                                                <div class="cls-component-panel-form-fieldset-header">
                                                    <div class="cls-component-panel-form-fieldset-header-title">
                                                        <h3>
                                                            <i class="fa-solid fa-basket-shopping"></i>
                                                            <span> Carga Externa</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p-table
                                                    [value]="this.formWeightBalanceFilled.controls.components_external_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"
                                                    class="cls-app-platform--component-calc-cg-table"
                                                    styleClass="p-datatable-striped p-datatable-gridlines">
                                                    <ng-template pTemplate="header">
                                                        <tr *ngIf="true">
                                                            <th
                                                                style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <ng-container *ngIf="showAllUnits === true">
                                                                <th
                                                                    style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </ng-container>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container
                                                            *ngIf="this.isExternalLoad(ItemArray.controls.type_component.value)">
                                                            <tr>
                                                                <td style="width:5%; text-align: center">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex]"
                                                                        (onChange)="callbackOnChangeSwitch($event)" />
                                                                </td>
                                                                <td style="width:15%; text-align: left">
                                                                    {{ ItemArray.controls.description.value }}</td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                        <lib-ui-primeng-input-number-float
                                                                            [groupRightText]="this.formWeightBalanceFilled.controls.unit_data.controls.weight.value"
                                                                            [control]="ItemArray.controls.weight">
                                                                        </lib-ui-primeng-input-number-float>
                                                                    </ng-container>
                                                                </td>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            </span>
                                                                        </ng-container>
                                                                    </td>
                                                                </ng-container>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:10%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[1][rowIndex] === true">
                                                                        <div
                                                                            class="cls-app-platform--component-calc-cg-table-item-button">
                                                                            <p-menu #menu
                                                                                [model]="this.createMenuItemLoad(ItemArray, this.formWeightBalanceFilled.controls.unit_data.controls.weight.value, ItemArray.controls.max_weight.value)"
                                                                                appendTo="body"
                                                                                [style]="{'width': 'auto'}"
                                                                                [popup]="true" />
                                                                            <p-button (onClick)="menu.toggle($event)"
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info" />
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-panel>
                                        <p-panel class="cls-component-panel-form-fieldset">
                                            <ng-template pTemplate="header">
                                                <div class="cls-component-panel-form-fieldset-header">
                                                    <div class="cls-component-panel-form-fieldset-header-title">
                                                        <h3>
                                                            <i class="fa-solid fa-thumbtack-slash"></i>
                                                            <span> Itens Removíveis</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p-table
                                                    [value]="this.formWeightBalanceFilled.controls.components_constant_data.controls"
                                                    [tableStyle]="{ 'min-width': '50rem' }"
                                                    class="cls-app-platform--component-calc-cg-table"
                                                    styleClass="p-datatable-striped p-datatable-gridlines">
                                                    <ng-template pTemplate="header">
                                                        <tr *ngIf="true">
                                                            <th
                                                                style="width:5%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:15%; text-align: left; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <ng-container *ngIf="showAllUnits === true">
                                                                <th
                                                                    style="width:12%; text-align: center; background-color:transparent; padding:0">
                                                                </th>
                                                            </ng-container>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:14%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:16%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                            <th
                                                                style="width:10%; text-align: center; background-color:transparent; padding:0">
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <ng-container
                                                            *ngIf="this.isRemovable(ItemArray.controls.type_component.value)">
                                                            <tr>
                                                                <td style="width:5%; text-align: center">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex]"
                                                                        (onChange)="callbackOnChangeSwitch($event)" />
                                                                </td>
                                                                <td style="width:15%; text-align: left">
                                                                    {{ ItemArray.controls.description.value }}</td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                        <lib-ui-primeng-input-number-float
                                                                            [groupRightText]="this.formWeightBalanceFilled.controls.unit_data.controls.weight.value"
                                                                            [control]="ItemArray.controls.weight">
                                                                        </lib-ui-primeng-input-number-float>
                                                                    </ng-container>
                                                                </td>
                                                                <ng-container *ngIf="showAllUnits === true">
                                                                    <td style="width:12%; text-align: center">
                                                                        <ng-container
                                                                            *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value) }}
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                        </ng-container>
                                                                    </td>
                                                                </ng-container>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                        {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                        {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                        x
                                                                        {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                        {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:16%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                        {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                        x
                                                                        {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:10%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[2][rowIndex] === true">
                                                                        <div
                                                                            class="cls-app-platform--component-calc-cg-table-item-button">
                                                                            <p-menu #menu
                                                                                [model]="this.itemsMenuWeightPattern"
                                                                                appendTo="body"
                                                                                [style]="{'width': 'auto'}"
                                                                                [popup]="true" />
                                                                            <p-button (onClick)="menu.toggle($event)"
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="secondary" />
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-panel>
                                        <p-panel class="cls-component-panel-form-fieldset">
                                            <ng-template pTemplate="header">
                                                <div class="cls-component-panel-form-fieldset-header">
                                                    <div class="cls-component-panel-form-fieldset-header-title">
                                                        <h3>
                                                            <i class="fa-solid fa-gas-pump"></i>
                                                            <span> Combustível</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p-table
                                                    [value]="this.formWeightBalanceFilled.controls.components_fuel_data.controls"
                                                    [tableStyle]="{ 'min-width': '60rem' }"                                                                         
                                                    class="cls-app-platform--component-calc-cg-table"
                                                    styleClass="p-datatable-striped p-datatable-gridlines">
                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr *ngIf="true">
                                                            <th style="width:5%; text-align: center;">

                                                            </th>
                                                            <th style="width:10%; text-align: left;">
                                                                Descrição
                                                            </th>
                                                            <th style="width:14%; text-align: center;">
                                                                <div
                                                                    class="cls-app-platform--component-calc-cg-table-item-switch-fuel-input">
                                                                    <div>
                                                                        Peso/Vol.
                                                                    </div>
                                                                    <p-button icon="fa-solid fa-right-left"
                                                                        [rounded]="true"
                                                                        (click)="this.changeInputFuelInput()" />
                                                                </div>
                                                            </th>
                                                            <th style="width:12%; text-align: center;">
                                                                Peso/Vol.
                                                            </th>
                                                            <th style="width:12%; text-align: center;">
                                                                B. Long
                                                            </th>
                                                            <th style="width:14%; text-align: center;">
                                                                M. Long.
                                                            </th>
                                                            <th style="width:12%; text-align: center;">
                                                                B. Lat.
                                                            </th>
                                                            <th style="width:14%; text-align: center;">
                                                                M. Lat.
                                                            </th>
                                                            <th style="width:10%; text-align: center;">
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex" let-columns="columns">
                                                        <ng-container
                                                            *ngIf="this.isFuel(ItemArray.controls.type_component.value)">
                                                            <tr>
                                                                <td style="width:5%; text-align: center">
                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex]"
                                                                        (onChange)="callbackOnChangeSwitch($event)" />
                                                                </td>
                                                                <td style="width:10%; text-align: left">
                                                                    {{ ItemArray.controls.description.value }}</td>
                                                                <td style="width:12%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                        <ng-container *ngIf="typeInputFuel === 'knob'">
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-item-knob">
                                                                                <p-knob
                                                                                    [(ngModel)]="ItemArray.controls.weight.value"
                                                                                    [strokeWidth]="10" [size]="75"
                                                                                    [min]="0"
                                                                                    [max]="this.defineMaxFuel(this.formWeightBalanceFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)"
                                                                                    (onChange)="this.handleKnobOnChange($event, ItemArray.controls.weight)"
                                                                                    valueTemplate="{{ this.fuelPercent(ItemArray.controls.weight.value, this.defineMaxFuel(this.formWeightBalanceFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)) }}%" />
                                                                            </div>
                                                                            <div
                                                                                class="cls-app-platform--component-calc-cg-table-item-slider">
                                                                                <p-slider
                                                                                    [formControl]="ItemArray.controls.weight"
                                                                                    [min]="0"
                                                                                    [max]="this.defineMaxFuel(this.formWeightBalanceFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value)"
                                                                                    styleClass="100%" />
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="typeInputFuel === 'input'">
                                                                            <lib-ui-primeng-input-number-float
                                                                                [groupRightText]="this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value"
                                                                                [control]="ItemArray.controls.weight">
                                                                            </lib-ui-primeng-input-number-float>
                                                                        </ng-container>




                                                                        <!--
                                                                        <div class="cls-app-platform--component-calc-cg-table-item-knob">
                                                                            <p-knob
                                                                                [formControl]="ItemArray.controls.weight"
                                                                                [strokeWidth]="10" 
                                                                                [min]="0"
                                                                                valueTemplate="{{this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.weight.value)}} {{this.formWeightBalanceFilled.controls.unit_data.controls.fuel.value}}" />
                                                                        </div>
                                                                        <div class="cls-app-platform--component-calc-cg-table-item-slider">
                                                                            <p-slider
                                                                                [formControl]="ItemArray.controls.weight"
                                                                                styleClass="100%"/>
                                                                        </div>
                                                                        -->
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:12%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                        <div>
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "kg")) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                kg
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "lb")) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                lb
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "gal")) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                gal
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            {{ this.classLocalMethods.convertNumberToPtBr(this.convertItemTableFuelUnit(ItemArray.controls.weight.value, "l")) }}
                                                                            <span
                                                                                class="cls-app-platform--component-calc-cg-table-item-units">
                                                                                l
                                                                            </span>
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:12%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.longitudinal_moment.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:12%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_arm.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:14%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                        {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.controls.lateral_moment.value) }}
                                                                        <span
                                                                            class="cls-app-platform--component-calc-cg-table-item-units">
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.weight.value }}
                                                                            x
                                                                            {{ this.formWeightBalanceFilled.controls.unit_data.controls.arm.value }}
                                                                        </span>
                                                                    </ng-container>
                                                                </td>
                                                                <td style="width:10%; text-align: center">
                                                                    <ng-container
                                                                        *ngIf="this.formWeightBalanceFilled.controls.matrixOfElements.value[3][rowIndex] === true">
                                                                        <div
                                                                            class="cls-app-platform--component-calc-cg-table-itembutton-">
                                                                            <p-menu #menu
                                                                                [model]="createMenuItemFuel(ItemArray, this.defineMaxFuel(this.formWeightBalanceFilled.controls.components_external_data.controls[rowIndex], this.formWeightBalanceFilled.controls.unit_data.controls.weight.value))"
                                                                                appendTo="body"
                                                                                [style]="{'width': 'auto'}"
                                                                                [popup]="true" />
                                                                            <p-button (onClick)="menu.toggle($event)"
                                                                                icon="fa-solid fa-ellipsis-vertical"
                                                                                severity="info" />
                                                                        </div>
                                                                    </ng-container>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-panel>
                                        <p-divider class="cls-component-divider-panel" />
                                        <p-panel class="cls-component-panel-form-fieldset">
                                            <ng-template pTemplate="header">
                                                <div class="cls-component-panel-form-fieldset-header">
                                                    <div class="cls-component-panel-form-fieldset-header-title">
                                                        <h3>
                                                            <i class="fa-solid fa-location-crosshairs"></i>
                                                            <span> CÁLCULO DO CG</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <p-table
                                                    [value]="this.formWeightBalanceFilled.controls.calculation_cg.controls.table.value"
                                                    [tableStyle]="{ 'min-width': '50rem' }"
                                                    class="cls-app-platform--component-calc-cg-table"
                                                    styleClass="p-datatable-striped p-datatable-gridlines">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th style="width:22%; text-align: left">Descrição</th>
                                                            <th style="width:12%; text-align: center">Peso
                                                                ({{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}})
                                                            </th>
                                                            <th style="width:13%; text-align: center">B. Long.
                                                                ({{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                            </th>
                                                            <th style="width:20%; text-align: center">M. Long.
                                                                ({{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                                x
                                                                {{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                            </th>
                                                            <th style="width:13%; text-align: center">B. Lat.
                                                                ({{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                            </th>
                                                            <th style="width:20%; text-align: center">M. Lat.
                                                                ({{this.formWeightBalanceFilled.controls.unit_data.controls.weight.value}}
                                                                x
                                                                {{this.formWeightBalanceFilled.controls.unit_data.controls.arm.value}})
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-ItemArray let-rowIndex="rowIndex">
                                                        <tr>
                                                            <td style="width:22%; text-align: left">
                                                                {{ ItemArray.label }}
                                                            </td>
                                                            <td style="width:12%; text-align: center">
                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.weight) }}
                                                            </td>
                                                            <td style="width:15%; text-align: center">
                                                                <div
                                                                    [ngClass]="this.getClassCgInChart(rowIndex, 'long')">
                                                                    {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.longitudinal_arm) }}
                                                                </div>
                                                            </td>
                                                            <td style="width:20%; text-align: center">
                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.longitudinal_moment) }}
                                                            </td>
                                                            <td style="width:15%; text-align: center">
                                                                <div
                                                                    [ngClass]="this.getClassCgInChart(rowIndex, 'lat')">
                                                                    {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.lateral_arm) }}
                                                                </div>
                                                            </td>
                                                            <td style="width:20%; text-align: center">
                                                                {{ this.classLocalMethods.convertNumberToPtBr(ItemArray.lateral_moment) }}
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-panel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-5">
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> CG Envelope LONGITUDINAL</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div
                                        *ngIf="this.formWeightBalanceFilled.controls.chart_longitudinal_data.controls[0]">
                                        <sunrise-app-platform--chart-cg-limits
                                            [objChartCGLimits]="this.formWeightBalanceFilled.controls.chart_longitudinal_data.controls[0].getRawValue()"
                                            [arrPointsToDraw]="this.arrLongPointsToDraw">
                                        </sunrise-app-platform--chart-cg-limits>
                                    </div>
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> CG Envelope LATERAL</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div *ngIf="this.formWeightBalanceFilled.controls.chart_lateral_data.controls[0]">
                                        <sunrise-app-platform--chart-cg-limits
                                            [objChartCGLimits]="this.formWeightBalanceFilled.controls.chart_lateral_data.controls[0].getRawValue()"
                                            [arrPointsToDraw]="this.arrLatPointsToDraw">
                                        </sunrise-app-platform--chart-cg-limits>
                                    </div>
                                </ng-template>
                            </p-panel>
                            <p-panel class="cls-component-panel-form-fieldset">
                                <ng-template pTemplate="header">
                                    <div class="cls-component-panel-form-fieldset-header">
                                        <div class="cls-component-panel-form-fieldset-header-title">
                                            <h3>
                                                <i class="fa-solid fa-check"></i>
                                                <span> Lista de Passageiros</span>
                                            </h3>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">

                                </ng-template>
                            </p-panel>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-panel>
</section>