//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { Inject, LOCALE_ID } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//#endregion

//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { ModuleIamAppService } from '../../_services/module-iam-app/module.iam.app.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AuthenticationAppGuard implements CanActivate {
  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _moduleIamAppService: ModuleIamAppService,
    private _router: Router
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const tempCurrentToken = this._moduleIamAppService.getAppToken();

      if (tempCurrentToken) {
        // O Token jã vai incluso no cabeçalho da requisição. Incluído via observer...
        this._moduleIamAppService.verifyToken().subscribe({
          next: (xApiResponse) => {
            if (xApiResponse === true) {
              resolve(true);
            } else {
              this._router.navigate([`app/${this._locale}/login`], { queryParams: { returnUrl: state.url } });
              resolve(false);
            }
          },
          error: (xError) => {
            this._router.navigate([`app/${this._locale}/login`], { queryParams: { returnUrl: state.url } });

            resolve(false);
          }
        });
      } else {
        this._router.navigate([`app/${this._locale}/login`], { queryParams: { returnUrl: state.url } });

        resolve(false);
      }
    });
  }
}