/**
 * @author GASPAR
 * @date 2024-10-04
 * @version 1.0.0
 * 
 */
export const WEIGHT_BALANCE_FORMAT = {
    TEMPLATE: "template",    
    SHEET: "sheet",
    FILLED: "filled"
}