//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { CommonModule } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

// ***** HANDLER ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion

export function initFormFkAerodromeBasicDataForm(xAerodromeGoal: string) {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        fk_ad_bsc_data_id: LocalMethodsHandlerClass.generateUuid(),
        fk_ad_cmp_data_id: [null],
        ad_goal: [xAerodromeGoal],
        icao: [null],
        iata: [null],
        ad_type: [null],
        name: [null],
        coord_dc: [],
        alt_ft: [null],
        timezone: [null],
        coord_geo: objFormBuilder.group({
            location: objFormBuilder.group({
                type: "Point",
                coordinates: [null]
            })
        }),
        locus: objFormBuilder.group({
            country: [null],
            state: [null],
            city: [null]
        }),
        doc_db_control: objFormBuilder.group({
            crted_at_utc: [null],
            upted_at_utc: [null],
            dlted_at_utc: [null]
        }),
        font: [null]
    });
}

