/**
 * @author GASPAR
 * @date 2024-10-16
 * @version 1.0.0
 * 
 * @description Enumerador da localização do CG dentro do envelope da Aeronave.
 */
export const POSITION_CG_IN_ENVELOP = {
    OUTSIDE: "outside",
    INSIDE: "inside",
    INSIDE_NEAR_BOARD: "inside_near_board"
}