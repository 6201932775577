//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { HomePage01Component } from 'src/layouts/library/frames/web-sites/home-page-01/home-page-01.component';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { UserConfigService } from 'src/app/_services/app-config/user-config/user-config.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IUserConfigsHomePage } from 'src/app/_library/interfaces/IUserConfigs';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-web-page-spa',
  standalone: true,
  imports: [
    CommonModule,
    HomePage01Component
  ],
  templateUrl: './web-page-spa.component.html',
  styleUrl: './web-page-spa.component.css',
})
export class WebPageSpaComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** SPA-FRAME *****
  layoutFrame!: string | null;

  // ***** HEADER *****
  topHeader!: string | null;
  topHorizontalMenu!: string | null;

  // ***** COLOR & THEMES *****
  colorSchema!: string | null;
  theme!: string | null;
  //#endregion

  constructor(
    private _userConfigService: UserConfigService
  ) { }

  ngOnInit(): void {
    this._settingUserConfig();
  }

  //#region "|--- PRIVATE METHODS ---|"
  private _settingUserConfig() {
    this._userConfigService.getUserConfigHomepage().subscribe((xUserConfig: IUserConfigsHomePage) => {
      this.topHeader = xUserConfig.top_header ? xUserConfig.top_header : "";
      this.topHorizontalMenu = xUserConfig.top_horizontal_menu ? xUserConfig.top_horizontal_menu : "";
      this.layoutFrame = xUserConfig.layout_frame ? xUserConfig.layout_frame : "";
      this.colorSchema = xUserConfig.color_schema ? xUserConfig.color_schema : "";
      this.theme = xUserConfig.theme ? xUserConfig.theme : "";

      document.body.setAttribute('data-top_header', this.topHeader);
      document.body.setAttribute('data-top_horizontal_menu', this.topHorizontalMenu);
      document.body.setAttribute('data-layout_frame', this.layoutFrame);
      document.body.setAttribute('data-color_schema', this.colorSchema);
      document.body.setAttribute('data-theme', this.theme);
    });
  }
  //#endregion
}
