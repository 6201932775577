//#region "|--- INTERFACE ---|"
import { IDropDownOptionsIcon } from '../interfaces/IDropDownOptionsIcon';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description Opções estáticas para unidades de combustível.
 */
export const staticOptionsAircraftTypes: IDropDownOptionsIcon[] = [
    { label: 'AVIÃO', value: 'airplane', icon: 'fa-solid fa-plane' },
    { label: 'HELICÓPTERO', value: 'rotorcraft', icon: 'fa-solid fa-helicopter' },
]