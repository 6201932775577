<section data-page="app-platform-page">
    <sunrise-app-platform--toast-message></sunrise-app-platform--toast-message>
    <sunrise-app-platform--title-page [titlePage]="this.objPageTitle"></sunrise-app-platform--title-page>
    <sunrise-app-platform--waiting-dialog 
        [showWaitingDialog]="this.bolShowWaitingDialog" 
        [typeWaitingDialog]="this.strTypeWaitingDialog">
    </sunrise-app-platform--waiting-dialog>
    <div class="cls-page-form">
        <div class="cls-page-form-grid-3-columns">
            <aside class="cls-page-form-sidebar-left"></aside>
            <div class="cls-page-form-content cls-util-no-padding">
                <p-tabView [scrollable]="true" [activeIndex]="0" class="cls-component-tabview-page"
                    (activeIndexChange)="onTabChange($event)">
                     <!-- REGISTER DATA -->
                     <p-tabPanel class="cls-component-tabview-page-tabpanel">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-clipboard-list"></i>
                                <div>Cadastro</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-tabview-page-tabpanel-content">
                                <sunrise-tab-crew-register [formComponent]="this.formCrewData "
                                    (actionTabCrewData)="this.handlerActionTabCrewData($event)"
                                    (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)">
                                </sunrise-tab-crew-register>
                            </div>
                        </ng-template>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <aside class="cls-page-form-sidebar-right"></aside>
        </div>
    </div>
</section>