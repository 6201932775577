import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeChangeService {  
  // BehaviorSubject é um tipo de Subject que requer um valor inicial e emite o valor atual para novos inscritos.
  private themeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('light'); // Tema padrão é 'light'.

  constructor() {

   }

  // Método para alterar o tema da aplicação.
  setTheme(theme: string): void {
    // ESTA DANDO ERRO DE ESTOURAR O MAXIMO DE REPETIÇÕES
    //this.themeSubject.next(theme); // Atualiza o valor do BehaviorSubject com o novo tema e notifica os inscritos para que possam receber a atualização.            
    this.applyTheme(theme);
  }

  // Método para obter o tema atual da aplicação.
  getTheme(): Observable<string> {
    return this.themeSubject.asObservable(); // Retorna o Observable do BehaviorSubject para que os componentes possam se inscrever e receber atualizações.
  }

  // Método para aplicar o tema da aplicação.
  private applyTheme(theme: string): void {
    document.body.classList.toggle('light-mode', theme === 'light');
    document.body.classList.toggle('dark-mode', theme === 'dark');
  }
}
