//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../_library/layouts/components/canttech/canttech-components.module';
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initCrewDataForm } from '../../form-init/crew-data-form';
import { initDocumentPersonalForm } from '../../form-init/document-personal-form';
import { initDocumentProfissionalForm } from '../../form-init/document-profissional-form';

// ***** COMPONENT ***** //
import { TabAircraftDataComponent } from './_components/aircraft/tab-aircraft-data/tab-aircraft-data.component';
import { TabCrewRegisterComponent } from './_components/crew-data/tab-crew-register/tab-crew-register.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';

// ***** CLASS ***** //
import { InternationalizationHandler } from '../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
import { ModuleCrewService } from '../../../../_services/module-crew/module-crew.service';
import { ModuleOwnerOperatorService } from '../../../../_services/module-owner-operator/module-owner-operator.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
import { FORM_DATA_STATUS } from '../../../../../_library/definitions/FormDataFont';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from 'src/app/_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
import { IOutputAction } from '../../../../../_library/interfaces/IOutputAction';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-crew-data-form',
  standalone: true,
  imports: [
    CanttechComponentsModule,
    CommonModule,
    PrimengComponentsModule,
    TabAircraftDataComponent,
    TabCrewRegisterComponent,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './crew-data-form.component.html'
})
export class CrewDataFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public activeTabIndex!: number;
  public typeDialogWaiting!: string;
  public extraDataDialogWaiting!: any[];

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classLocalMethods!: any;
  public classModuleMethods!: any;

  // ***** SHOW DIALOG ***** //  
  public bolShowDialogWaiting!: boolean;

  // ***** FORMS ***** //
  public formCrewData: any

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** ARRAY ***** // 
  public listAircraftData!: any[];
  public listOwnerOperatorData!: any[];

  // ***** OPTIONS ***** //
  public optionsAircraftData!: IDropDownOptionsIcon[];
  public optionsOperatorsData!: IDropDownOptionsBasic[];
  //#endregion  

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _messageService: MessageService,
    private _location: Location,
    private _moduleAircraftService: ModuleAircraftService,
    private _moduleOwnerOperatorService: ModuleOwnerOperatorService,
    private _moduleCrewService: ModuleCrewService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();
    this._initCrewDataForm();
    this._checkEditMode();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.activeTabIndex = 0;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;
    this.extraDataDialogWaiting = [];

    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;

    this.listAircraftData = [];
    this.listOwnerOperatorData = [];

    this.optionsAircraftData = [];
    this.optionsOperatorsData = [];

    this.bolShowDialogWaiting = false;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * 
   * @description 
   *   - Método que verifica se é uma EDIÇÃO de dados de um registro ou um NOVO registro.
   *   - Define os Título da página.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      let isEdit = false;

      if (xParams["id"]) {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_CrewDataAlter'),
          subtitle: this.classInternationalization.getTranslation('txt_CrewDataAlter:'),
          icon: 'fa-solid fa-edit'
        }

        isEdit = true;
      } else {
        this.objPageTitle = {
          title: this.classInternationalization.getTranslation('ttl_CrewDataNew'),
          subtitle: this.classInternationalization.getTranslation('txt_CrewDataNew:'),
          icon: 'fa-solid fa-plus'
        }
      }

      if (isEdit) {
        Promise.all([
          await this._loadExtraDataDb(false),
          await this._getCrewDataById(xParams["id"])
        ]);
      } else {
        await this._loadExtraDataDb(true);
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega o dados necessários para preenchimento do cadastro.
   * 
   * @param xBolCloseWaitingDialog
   */
  private async _loadExtraDataDb(xBolCloseWaitingDialog: boolean): Promise<void> {
    this.bolShowDialogWaiting = true;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.LOADING_DATA;

    Promise.resolve([
      await this._getAllAircraftDataDb(),
      await this._getAllOwnerOperatorDataDb()
    ]);

    setTimeout(() => {
      if (xBolCloseWaitingDialog === true) {
        this.bolShowDialogWaiting = false;
      }
    }, this.currentSettings.WAITING_DIALOG_LIFE);
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description 
   *   - Gera as opções de todas as aeronaves para por DROPDOWN.
   */
  private _generateOptionsAllAircraft(): void {
    this.optionsAircraftData = [];

    this.optionsAircraftData = this.listAircraftData ? this.listAircraftData.map((xObjAircraft: any) => {
      return {
        label: xObjAircraft.register_data.aircraft_mark,
        value: xObjAircraft.aircraft_data_id,
        icon: `${this.classModuleMethods.getAircraftTypeLabel(xObjAircraft.register_data.aircraft_type, true)}`
      }
    }) : [];
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário.
   */
  private _initCrewDataForm(): void {
    this.formCrewData = initCrewDataForm(FORM_DATA_STATUS.COMPLETE);

    // É preciso criar pelo menos 1 instância do documento para que o formulário funcione corretamente.
    this.formCrewData.controls.documents_profissional.controls.push(initDocumentProfissionalForm());

    // É preciso criar pelo menos 2 instâncias do documento para que o formulário funcione corretamente.
    this.formCrewData.controls.documents_personal.controls.push(initDocumentPersonalForm());
    this.formCrewData.controls.documents_personal.controls.push(initDocumentPersonalForm());
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-22-27
   * @version 1.0.0
   * 
   * @description 
   *   - Método que limpa o formulário de dados da aeronave.
   */
  private _toastMessage(xMessage: any): void {
    this._messageService.add({
      severity: xMessage.severity,
      summary: xMessage.summary,
      detail: xMessage.detail,
      key: this.currentSettings.TOAST_KEY,
      life: this.currentSettings.TOAST_LIFE
    });
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *     - Método que trata a ação de fechar o dialog de espera.
   * 
   * @param xBolCloseDialog
   */
  public handlerActionCloseWaitingDialog(xBolCloseDialog: boolean): void {
    this.bolShowDialogWaiting = xBolCloseDialog;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-05
   * @version 1.0.0
   * 
   * @description
   *   - Método que trata as ações do componente de dados do tripulante.
   * 
   * @param xAction
   */
  public async handlerActionCrewData(xAction: string): Promise<void> {
    if (xAction === ACTION_TYPE.UPDATE) {
      await this._updateCrewData();
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-30
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações da aba de dados de registro da aeronave.
   * 
   * @param xAction 
   */
  public async handlerActionToolbarTabCrewData(xAction: string): Promise<void> {
    if (xAction === ACTION_TYPE.NEW) {
      console.log('NOVO REGISTRO DE TRIPULANTE');
    } else if (xAction === ACTION_TYPE.SAVE) {
      await this._saveRegisterData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      await this._updateCrewData();
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log('SAIR DA TRIPULANTE');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-30
   * @version 1.0.0
   * 
   * @description
   *   - Método que trata as ações da aba de dados da aeronave.
   * 
   * @param xAction 
   */
  public async handlerActionCrewAircraft(xAction: IOutputAction): Promise<void> {
    let bolIsToUpdateAircraftData = false;
    let currentAircraftId: any;
    let currentAircraft: any;

    if (xAction.action === ACTION_TYPE.SAVE) {
      await this._updateCrewData(); // Deve Atualizar os dados do Tripulante, pois a referencia a aeronave jã foi posta no formulário.

      // Encontrar a aeronave que está sendo referenciada. Ela vai ser sempre o ultimo elemento - inserção via push() - this.formCrewData.controls.aircraft
      const indexLastElement = this.formCrewData.controls.fk_aircraft_list.length - 1;

      currentAircraftId = this.formCrewData.controls.fk_aircraft_list.controls[indexLastElement].controls.fk_aircraft_data.controls.fk_aircraft_data_id.value;
      currentAircraft = this.listAircraftData.find(xItem => xItem.aircraft_data_id == currentAircraftId);

      // Acrescentar o Tripulante a aeronave.
      if (currentAircraft && currentAircraft !== null) {
        currentAircraft.fk_crew_list.push({
          crew_aircraft_id: this.formCrewData.controls.fk_aircraft_list.controls[indexLastElement].controls.crew_aircraft_id.value,
          fk_crew_data_id: this.formCrewData.controls.crew_data_id.value,
          crew_role: this.formCrewData.controls.fk_aircraft_list.controls[indexLastElement].controls.crew_role.value
        });
      }

      bolIsToUpdateAircraftData = true;
    } else if (xAction.action === ACTION_TYPE.UPDATE) {
      await this._updateCrewData(); // Deve Atualizar os dados do Tripulante, pois a referencia a aeronave jã foi posta no formulário.

      // Encontrar a aeronave que está sendo referenciada. Usando ID passado na chamada.  
      for (let k = 0; k < this.formCrewData.controls.fk_aircraft_list.length; k++) {
        if (this.formCrewData.controls.fk_aircraft_list.controls[k].controls.crew_aircraft_id.value == xAction.id) {
          currentAircraftId = this.formCrewData.controls.fk_aircraft_list.controls[k].controls.fk_aircraft_data.controls.fk_aircraft_data_id.value;
          currentAircraft = this.listAircraftData.find(xItem => xItem.aircraft_data_id == currentAircraftId);

          // Atualizar os dados do crew_list da aeronave
          for (let j = 0; j < currentAircraft.fk_crew_list.length; j++) {
            if (currentAircraft.fk_crew_list[j].crew_aircraft_id == xAction.id) {
              currentAircraft.fk_crew_list[j].crew_role = this.formCrewData.controls.fk_aircraft_list.controls[k].controls.crew_role.value;

              break;
            }
          }
        }
      }

      bolIsToUpdateAircraftData = true;
    } else if (xAction.action === ACTION_TYPE.DELETE) {
      await this._updateCrewData(); // Deve Atualizar os dados do Tripulante, pois a referencia a aeronave jã foi posta no formulário.
      
      currentAircraft = this.listAircraftData.find(xItem => xItem.aircraft_data_id == xAction.id[1]);
      currentAircraftId = xAction.id[1];
      
      // Atualizar os dados do crew_list da aeronave
      for (let j = 0; j < currentAircraft.fk_crew_list.length; j++) {
        if (currentAircraft.fk_crew_list[j].crew_aircraft_id == xAction.id[0]) {
          currentAircraft.fk_crew_list.splice(j, 1);

          break;
        }
      }

      bolIsToUpdateAircraftData = true;
    }

    if (bolIsToUpdateAircraftData == true) {
      // Atualiza a aeronave com o novo tripulante.
      this._moduleAircraftService.updateObjectAircraftData(currentAircraftId, currentAircraft).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.OK) {
            this._toastMessage({
              severity: 'success',
              summary: 'Atualizar dados Tripulante na Aeronave',
              detail: `${xApiResponse.data[0].message}`
            });
          }
        },
        error: (xError: any) => {
          this._toastMessage({
            severity: 'error',
            summary: 'Salvar Tripulante na Aeronave',
            detail: `${xError.detail}`
          });
        }
      });
    }
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  //#endregion

  //#region "|--- SERVICES METHODS ---|"  
  /**
   * @status OK
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados do "REGISTRO DO TRIPULANTE".
   * 
   * @abstract 
   *   - Importante ressaltar que quando você está salvando um novo "REGISTRO DDO TRIPULANTE", você também está criando a estrutura de Dados do Tripulante.
   *   - "REGISTRO DO TRIPULANTE" é um subconjunto dos Dados do Tripulante.
   */
  private async _saveRegisterData(): Promise<void> {
    if (this.formCrewData.invalid) { // Verifica se o formulário está válido.
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formCrewData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._toastMessage({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_CrewDataSave'),
        detail: `${this.classInternationalization.getTranslation('msg_FormFieldsNotValid')} \n- ${messageErrosFormRequest}`
      });
    } else {
      this.bolShowDialogWaiting = true;
      this.typeDialogWaiting = DIALOG_WAITING_TYPE.CREW_DATA_SAVE;

      await this._moduleCrewService.saveCrewData(this.formCrewData).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
            this.formCrewData.controls.crew_data_id.setValue(xApiResponse.data[0].data[0].id);

            // É preciso ajustar a imagem, caso ela seja temporária, pois na hora de salvar vai ser retirado o prefixo
            // Para evitar de ter que receber o retorno é mais prático tratar aqui, pois tenho a certeza que isto foi feito no back.
            if (this.formCrewData.controls.avatar_image.value.indexOf("temp--") >= 0) {
              this.formCrewData.controls.avatar_image.setValue(this.formCrewData.controls.avatar_image.value.replace("temp--", ""));
            }

            // Atualizar a URL do Browser sem fazer o redirect. Isto é feito para refletir o Estado atual, uqe vem a ser o de editar os dados da aeronave.
            this._location.replaceState(`/app/${this._locale}/crew/data/form/edit/${xApiResponse.data[0].data[0].id}`);

            setTimeout(() => {
              this.bolShowDialogWaiting = false;
              this._toastMessage({
                severity: 'success',
                summary: this.classInternationalization.getTranslation('ttl_CrewDataSave'),
                detail: `${xApiResponse.data[0].message}`
              });
            }, this.currentSettings.WAITING_DIALOG_LIFE);
          }
        },
        error: (xError: any) => {
          this.bolShowDialogWaiting = false;

          this._toastMessage({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_CrewDataSave'),
            detail: `${xError.detail}`
          });
        }
      });
    }
  }

  /**
   * @status: 
   * @author GASPAR
   * @date 2025-01-04
   * @version 1.0.0
   * 
   * @description 
   *  - Método que recupera os dados do Crew Data pelo ID.
   * 
   * @param xCrewDataId 
   */
  private async _getCrewDataById(xCrewDataId: string): Promise<void> {
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.CREW_DATA;
    this.bolShowDialogWaiting = true;

    await this._moduleCrewService.getCrewDataById(xCrewDataId).subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          const tempData = xApiResponse[0];

          // Atualiza o formulário com os dados do tripulante.
          this.formCrewData.patchValue(tempData, { emitEvent: false });

          for (let i = 0; i < tempData.fk_aircraft_list.length; i++) {
            const objFormBuilder: FormBuilder = new FormBuilder();

            // DETALHE: Acrescenta os valores que não são salvos no DB.
            this.formCrewData.controls.fk_aircraft_list.push(objFormBuilder.group({
              crew_aircraft_id: tempData.fk_aircraft_list[i].crew_aircraft_id,
              fk_aircraft_data: objFormBuilder.group({
                fk_aircraft_data_id: tempData.fk_aircraft_list[i].fk_aircraft_data.fk_aircraft_data_id,
                aircraft_mark: tempData.fk_aircraft_list[i].fk_aircraft_data.aircraft_mark,
                ntbs_aircraft_type: this.listAircraftData.find(xItem => xItem.aircraft_data_id == tempData.fk_aircraft_list[i].fk_aircraft_data.fk_aircraft_data_id).register_data.aircraft_type
              }),
              fk_owner_operator_data: objFormBuilder.group({
                fk_owner_operator_data_id: tempData.fk_aircraft_list[i].fk_owner_operator_data.fk_owner_operator_data_id,
                ntbs_operator_full_name: this.listOwnerOperatorData.find(xItem => xItem.owner_operator_data_id == tempData.fk_aircraft_list[i].fk_owner_operator_data.fk_owner_operator_data_id).full_name
              }),
              crew_role: tempData.fk_aircraft_list[i].crew_role,
              crew_aircraft_remark: tempData.fk_aircraft_list[i].crew_aircraft_remark
            }));
          }

          // CONFIGURANDO DATA.
          this.formCrewData.controls.birthday_date_utc.setValue(this.classLocalMethods.convertAnyDateToDDMMYYYY_UTC(tempData.birthday_date_utc));

          // Simula um delay para fechar o diálogo de espera.
          setTimeout(() => {
            this.bolShowDialogWaiting = false;
          }, this.currentSettings.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowDialogWaiting = false;

          this._toastMessage({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_CrewDataRecover'),
            detail: this.classInternationalization.getTranslation('msg_CrewDataRecoverError')
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        this.bolShowDialogWaiting = false;

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this._toastMessage({
          severity: `${tempSeverity}`,
          summary: this.classInternationalization.getTranslation('ttl_CrewDataRecover'),
          detail: `${xError.detail}`
        });
      }
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega a lista de Aeronaves cadastradas.
   */
  private async _getAllAircraftDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleAircraftService.getAllAircraftDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listAircraftData = []; // Para forçar a atualização da lista.
            this.listAircraftData = xApiResponse;

            this._generateOptionsAllAircraft(); // Gera as opções de todas as aeronaves para por DROPDOWN.
          } else {
            this.listAircraftData = [];
            this.optionsAircraftData = [];

            this._toastMessage({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
              detail: this.classInternationalization.getTranslation('msg_AircraftDataListError')
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempSeverity = 'error';

          if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
            tempSeverity = 'info';
          }

          this._toastMessage({
            severity: `${tempSeverity}`,
            summary: this.classInternationalization.getTranslation('ttl_AircraftDataList'),
            detail: `${xError.detail}`
          });

          this.listAircraftData = [];
          this.optionsAircraftData = [];

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Recupera todos os Owners/Operators do Sistema.
   */
  private async _getAllOwnerOperatorDataDb(): Promise<void> {
    return new Promise((resolve) => {
      this._moduleOwnerOperatorService.getAllOwnerOperatorDataList().subscribe({
        next: (xApiResponse: any) => {
          if (xApiResponse) {
            this.listOwnerOperatorData = []; // Para forçar a atualização da lista.
            this.listOwnerOperatorData = xApiResponse;
          } else {
            this.listOwnerOperatorData = [];

            this._toastMessage({
              severity: 'error',
              summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
              detail: this.classInternationalization.getTranslation('msg_OwnerOperatorDataListError')
            });
          }

          resolve(); // Garante que o retorno vai ser aguardado...
        },
        error: (xError: any) => {
          let tempToastType = "error"

          if (xError.http_error == 404) {
            tempToastType = "info";
          }

          this._toastMessage({
            severity: tempToastType,
            summary: this.classInternationalization.getTranslation('ttl_OwnerOperatorDataList'),
            detail: `${xError.detail}`
          });

          this.listOwnerOperatorData = [];

          resolve(); // Garante que o retorno vai ser aguardado...
        }
      });
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description 
   *   - Método que atualiza os dados do tripulante.
   * 
   * @abstract
   *   - Importante ressaltar que quando você está atualizando os dados do tripulante, você também está atualizando o "REGISTRO DO TRIPULANTE" e os demais Conjunto de Dados.
   */
  private async _updateCrewData(): Promise<void> {
    if (this.formCrewData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formCrewData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Atualizar Registro do Tripulante',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    } else {
      await this._moduleCrewService.updateCrewData(this.formCrewData.controls.crew_data_id.value, this.formCrewData).subscribe({
        next: (xResponse) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            this._messageService.add({
              severity: 'success',
              summary: 'Atualizar Registro do Tripulante',
              detail: `${xResponse.data[0].message}`,
              key: this.currentSettings.TOAST_KEY,
              life: this.currentSettings.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          let tempSeverity = 'error';

          if (xError.http_error == 404) {
            tempSeverity = 'info'
          }

          this._messageService.add({
            severity: tempSeverity,
            summary: 'Atualizar Registro do Tripulante',
            detail: `${xError.detail}`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      });
    }
  }
  //#endregion































  //!!!--------------------------------------------------------------------------------------------
  //#region "|--- PRIVATE METHODS ---|"








  //#region "|--- PRIVATE METHODS ---|"
  private async _newCrewData() {
    //this.private_clearForm();
    this._router.navigate(['/app/crew/form']);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Método que trata a mudança de abas.
   */
  onTabChange(xTabNumber: number): void {
    this.activeTabIndex = xTabNumber;
  }


  /**
   * @author GASPAR
   * @date 2024-10-09
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowDialogWaiting = xShowDialog.show;
    this.typeDialogWaiting = xShowDialog.type;
  }
  //#endregion
}