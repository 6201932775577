//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter, Renderer2, SimpleChanges, OnChanges, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from "../../../../../../../../_library/layouts/components/primeng/primeng-components.module";

// ***** PRIMENG ***** //
import { ButtonModule } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initWeightBalanceDataFilledForm } from '../../../../../../weight-balance/form-init/weight-balance-data-filled-form';
import { initPaxForm } from '../../../../../../weight-balance/form-init/fk-pax-form';

// ***** COMPONENT ***** //
import { ChartCGLimitsComponent } from '../../../../../../../_components/chart/chart-cg-limits/chart-cg-limits.component';
import { PreviewDataSheetFilledComponent } from './_components/preview-data-sheet-filled/preview-data-sheet-filled.component';

// ***** CLASS ***** //
import { AviationToolsHandlerClass } from '../../../../../../../_classes/AviationToolsHandlerClass';
import { CheckCgEnvelopeHandlerClass } from '../../../../../../../_classes/CheckCgEnvelopeHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleWeightBalanceService } from '../../../../../../../_services/module-weight-balance/module-weight-balance.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
import { CG_COMPONENT_TYPE } from '../../../../../../../../_library/definitions/CgComponentType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
import { POSITION_CG_IN_ENVELOP } from '../../../../../../../../_library/definitions/PositionCgInEnvelop';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { ICgComponents } from '../../../../../../../../_library/interfaces/ICgComponents';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCgArmUnits } from '../../../../../../../../_library/static_options/staticOptionsCgArmUnits';
import { staticOptionsCgFuelUnits } from '../../../../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsCgWeightUnits } from '../../../../../../../../_library/static_options/staticOptionsCgWeightUnits';
import { staticOptionsFuelRateUnits } from '../../../../../../../../_library/static_options/staticOptionsFuelRateUnits';
import { staticOptionsFuelType } from '../../../../../../../../_library/static_options/staticOptionsFuelType';
import { staticOptionsDocumentsPersonAll } from '../../../../../../../../_library/static_options/staticOptionsDocumentsPersonAll';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-weight-balance-filled',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    ChartCGLimitsComponent,
    FormsModule,
    MenuModule,
    PrimengComponentsModule,
    ReactiveFormsModule,
    PreviewDataSheetFilledComponent
  ],
  templateUrl: './tab-weight-balance-filled.component.html'
})
export class TabWeightBalanceFilledComponent implements OnInit, OnChanges {
  @ViewChild('imageFrame') imageFrame!: ElementRef;

  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any
  @Input() arrWeightBalanceDataSheet!: any
  @Input() objAircraftData!: any
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public showAllUnits!: boolean;
  public typeInputFuel!: any;
  public cgComponentType!: any;
  public positionCgInEnvelop!: any;
  public showWeightBalanceDataFilledPreview!: boolean;
  public showPaxChoose!: boolean;

  // ***** DATA SHEET ***** //
  public currentWeightBalanceDataSheet!: any; // Não inicializar, pois o valor vai ser atribuído no onChange.

  // ***** CLASSES ***** //
  public classAviationTools!: any;
  public classInternationalization!: any;
  public classLocalMethods!: any;
  public classModuleMethods!: any;
  public classCheckCgEnvelope!: any;

  // ***** FORMS ***** // 
  public formWeightBalanceDataFilled: any

  // ***** CHART ***** // 
  public objPositionChart!: {
    zfwPositionChartLong: any,
    zfwPositionChartLat: any,
    rwPositionChartLong: any,
    rwPositionChartLat: any,
    ldwPositionChartLong: any,
    ldwPositionChartLat: any,
    towPositionChartLong: any,
    towPositionChartLat: any,
  }

  // ***** ARRAY ***** //
  public listLongPointsToDraw!: any[];
  public listLatPointsToDraw!: any[];

  // ***** OPTIONS ***** //
  public optionsCgArmUnits!: any;
  public optionsCgFuelUnits!: any;
  public optionsCgWeightUnits!: any;
  public optionsFuelRateUnits!: any;
  public optionsFuelType!: any;
  public optionsWeightBalanceDataSheet: any[] = []; // Inicializar aqui, pois seu preenchimento vai ser feito no onChange.
  public optionsDocumentsType!: IDropDownOptionsBasic[];

  // ***** MENU ***** //
  public itemsMenuWeightPatternCrewPaxData: [MenuItem[]] | undefined = undefined;
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _renderer: Renderer2,
    private _messageService: MessageService,
    private _moduleWeightBalanceService: ModuleWeightBalanceService
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();
  }

  // @status:
  ngOnChanges(xChanges: SimpleChanges): void {
    if (xChanges['arrWeightBalanceDataSheet']) {
      this.formWeightBalanceDataFilled = null;
      this.optionsWeightBalanceDataSheet = [];
      this.formComponent.get('wght_bal_data.fk_wght_bal_data_sheet_id').setValue(null, { emitEvent: false });

      if (this.arrWeightBalanceDataSheet && this.arrWeightBalanceDataSheet !== null && this.arrWeightBalanceDataSheet.length > 0) {
        if (this.arrWeightBalanceDataSheet.length === 1) {
          // Atribuir o ID da Ficha de Peso e Balanceamento.
          this.formComponent.get('wght_bal_data.fk_wght_bal_data_sheet_id').setValue(this.arrWeightBalanceDataSheet[0].weight_balance_data_sheet_id, { emitEvent: false });

          this._loadDataSheetToFill(); // Quando tiver somente uma Ficha, carrega ela diretor.
        } else if (this.arrWeightBalanceDataSheet.length > 1) {
          this.optionsWeightBalanceDataSheet = this.arrWeightBalanceDataSheet.map((xItem: any) => { return { label: xItem.weight_balance_data.header_data.name, value: xItem.weight_balance_data_sheet_id } });

          // Ordenar por label em ordem alfabética.
          this.optionsWeightBalanceDataSheet.sort((a: any, b: any) => a.label.localeCompare(b.label));
        }
      }
    }
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // !!! ATENÇÃO: Preciso forçar a renderização do gráfico CG
    this._processCgCalculation();

  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status OK
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.showAllUnits = false;
    this.typeInputFuel = "knob";
    this.cgComponentType = CG_COMPONENT_TYPE;
    this.positionCgInEnvelop = POSITION_CG_IN_ENVELOP;
    this.showWeightBalanceDataFilledPreview = false;
    this.showPaxChoose = false;

    this.classAviationTools = AviationToolsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classCheckCgEnvelope = CheckCgEnvelopeHandlerClass;

    this.formWeightBalanceDataFilled = null;

    this.listLongPointsToDraw = [];
    this.listLatPointsToDraw = [];

    this.optionsCgArmUnits = staticOptionsCgArmUnits;
    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;
    this.optionsCgWeightUnits = staticOptionsCgWeightUnits;
    this.optionsFuelType = staticOptionsFuelType;
    this.optionsFuelRateUnits = staticOptionsFuelRateUnits;
    this.optionsDocumentsType = staticOptionsDocumentsPersonAll;

    this.objPositionChart = {
      zfwPositionChartLong: "none",
      zfwPositionChartLat: "none",
      rwPositionChartLong: "none",
      rwPositionChartLat: "none",
      ldwPositionChartLong: "none",
      ldwPositionChartLat: "none",
      towPositionChartLong: "none",
      towPositionChartLat: "none"
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário de Peso e Balanceamento para ser Preenchido.
   */
  private _initWeightBalanceFilledForm(): void {
    this.formWeightBalanceDataFilled = initWeightBalanceDataFilledForm();

    this._initFormWeightBalanceSheetEvents();
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega os dados do Weight And Balance Data Sheet que serve como referência o preenchimento do formulário de peso e Balanceamento.
   */
  private async _loadDataSheetToFill(): Promise<void> {
    const idWeightBalanceDataSheet = this.formComponent.get('wght_bal_data.fk_wght_bal_data_sheet_id').value;

    if (idWeightBalanceDataSheet !== null && idWeightBalanceDataSheet !== undefined && idWeightBalanceDataSheet !== "") {
      this.currentWeightBalanceDataSheet = this.arrWeightBalanceDataSheet.find((xItem: any) => xItem.weight_balance_data_sheet_id === idWeightBalanceDataSheet);

      // Caso a Ficha de Peso e Balanceamento seja encontrada, carrega os dados direto do Banco de Dados.
      if (this.currentWeightBalanceDataSheet !== null && this.currentWeightBalanceDataSheet !== undefined) {
        await this._getWeightBalanceSheetById();
      }

      if (this.currentWeightBalanceDataSheet !== null && this.currentWeightBalanceDataSheet !== undefined) {
        this._transferDataSheetToForm();
      }
    } else {
      this._messageService.add({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetLoad'),
        detail: this.classInternationalization.getTranslation('msg_WeightBalanceDataSheetLoadError'),
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-08
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Peso e Balanceamento para ser Preenchido.
   */
  private _transferDataSheetToForm(): void {
    const objFormBuilder: FormBuilder = new FormBuilder();
    const weightBalanceDataFilledId = this.classLocalMethods.generateUuid();
    const listElementsCalculation = ["Total Fuel", "Ramp Mass (RW)", "Fuel TakeOff", "Take Off Weight (TOW)", "Fuel Landing", "Landing Weight (LDW)"]

    // ----- >>>  Inicializa o Formulário de Peso e Balanceamento para ser Preenchido. <<< ----- //
    this.formWeightBalanceDataFilled = null;
    this._initWeightBalanceFilledForm();

    // ----- >>>  CRIAR A LISTA INICIAL DA FICHA DE PASSAGEIROS. <<< ----- //
    for (let k = 0; k < this.currentWeightBalanceDataSheet.weight_balance_data.header_data.seats_pax; k++) {
      this.formWeightBalanceDataFilled.controls.fk_pax_data_list.controls.push(initPaxForm());
    }

    // ----- >>> PREENCHER OS DADOS DE VOO <<< ----- //
    const tempEobtData = this.formComponent.get('flt_req_data.eobt_date_time.date_utc').value;
    const tempEobtTime = this.formComponent.get('flt_req_data.eobt_date_time.time_utc').value;
    const tempEetTotal = this.formComponent.get('flt_req_data.eet_total').value;
    const tempLogBookPage = this.formComponent.get('logbook_data.page').value;
    const tempLogBookIn = this.formComponent.get('logbook_data.in').value;
    const tempLogBookStage = this.formComponent.get('logbook_data.stage').value;
    const tempCommanderId = this.formComponent.get('flt_req_data.fk_crew_lst.0.fk_crew_data_id').value;
    const tempCommanderName = this.formComponent.get('flt_req_data.fk_crew_lst.0.full_name').value;
    const tempCommanderDocumentType = this.formComponent.get('flt_req_data.fk_crew_lst.0.prof_doc_type').value;
    const tempCommanderDocumentIn = this.formComponent.get('flt_req_data.fk_crew_lst.0.prof_doc_in').value;
    const tempAerodromeDepartureName = this.formComponent.get('flt_req_data.fk_ad_bsc_lst.0.name').value;
    const tempAerodromeDepartureIcao = this.formComponent.get('flt_req_data.fk_ad_bsc_lst.0.icao').value;
    const tempAerodromeDepartureCoord = this.formComponent.get('flt_req_data.fk_ad_bsc_lst.0.coord_dc').value;
    const tempAerodromeDestinationName = this.formComponent.get('flt_req_data.fk_ad_bsc_lst.1.name').value;
    const tempAerodromeDestinationIcao = this.formComponent.get('flt_req_data.fk_ad_bsc_lst.1.icao').value;
    const tempAerodromeDestinationCoord = this.formComponent.get('flt_req_data.fk_ad_bsc_lst.1.coord_dc').value;

    // 1) AERODROMES
    let tempAerodromeDepartureTextNorm = tempAerodromeDepartureIcao;
    let tempAerodromeDestinationTextNorm = tempAerodromeDepartureIcao;

    if (tempAerodromeDepartureName !== undefined && tempAerodromeDepartureName !== null && tempAerodromeDepartureName !== "") {
      tempAerodromeDepartureTextNorm = `${tempAerodromeDepartureIcao} (${tempAerodromeDepartureName})`;
    } else if (tempAerodromeDepartureCoord !== null && tempAerodromeDepartureCoord !== undefined) {
      tempAerodromeDepartureTextNorm = `${tempAerodromeDepartureIcao} (${tempAerodromeDepartureCoord[0]}, ${tempAerodromeDepartureCoord[1]})`;
    }

    if (tempAerodromeDestinationName !== undefined && tempAerodromeDestinationName !== null && tempAerodromeDestinationName !== "") {
      tempAerodromeDestinationTextNorm = `${tempAerodromeDepartureIcao} (${tempAerodromeDestinationName})`;
    } else if (tempAerodromeDestinationCoord !== null && tempAerodromeDestinationCoord !== undefined) {
      tempAerodromeDestinationTextNorm = `${tempAerodromeDepartureIcao} (${tempAerodromeDestinationCoord[0]}, ${tempAerodromeDestinationCoord[1]})`;
    }

    this.formWeightBalanceDataFilled.get('flight_data.fk_flight_data_id').patchValue(this.formComponent.get('flt_data_id').value, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.departure.name').patchValue(tempAerodromeDepartureName, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.departure.icao').patchValue(tempAerodromeDepartureIcao, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.departure.coord_dc').patchValue(tempAerodromeDepartureCoord, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.departure.text_norm').patchValue(tempAerodromeDepartureTextNorm, { emitEvent: false });

    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.destination.name').patchValue(tempAerodromeDestinationName, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.destination.icao').patchValue(tempAerodromeDestinationIcao, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.destination.coord_dc').patchValue(tempAerodromeDestinationCoord, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.aerodromes_data.destination.text_norm').patchValue(tempAerodromeDestinationTextNorm, { emitEvent: false });

    // 2) EOBT
    this.formWeightBalanceDataFilled.get('flight_data.eobt_date_time.date').patchValue(tempEobtData, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.eobt_date_time.time').patchValue(tempEobtTime, { emitEvent: false });

    // 3) EET
    this.formWeightBalanceDataFilled.get('flight_data.eet_total').patchValue(tempEetTotal, { emitEvent: false });

    // 4) LOGBOOK
    this.formWeightBalanceDataFilled.get('flight_data.logbook_data.page').patchValue(tempLogBookPage, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.logbook_data.in').patchValue(tempLogBookIn, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.logbook_data.stage').patchValue(tempLogBookStage, { emitEvent: false });

    // 5) COMMANDER
    this.formWeightBalanceDataFilled.get('flight_data.commander_data.fk_crew_data_id').patchValue(tempCommanderId, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.commander_data.full_name').patchValue(tempCommanderName, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.commander_data.document_type').patchValue(tempCommanderDocumentType, { emitEvent: false });
    this.formWeightBalanceDataFilled.get('flight_data.commander_data.document_in').patchValue(tempCommanderDocumentIn, { emitEvent: false });


    // ----- >>> ATRIBUIR O ID AO FORMULÁRIO DE PREENCHIMENTO DE PESO E BALANCEAMENTO E DADOS DE VOO <<< ----- //
    // Atribuir ao formulário do Voo, qual é o Id da Ficha de peso e Balanceamento.
    this.formComponent.get('wght_bal_data.fk_wght_bal_data_filled_id').setValue(weightBalanceDataFilledId, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.weight_balance_data_filled_id.setValue(weightBalanceDataFilledId, { emitEvent: false });

    // ----- >>> PREENCHER OS DADOS DO CABEÇALHO <<< ----- //
    this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.header_data.patchValue(this.currentWeightBalanceDataSheet.weight_balance_data.header_data, { emitEvent: false });

    // ----- >>> PREENCHER OS DADOS AVULSOS: fk_aircraft_data e weight_balance_data.floor_plan  <<< ----- //          
    this.formWeightBalanceDataFilled.controls.fk_aircraft_data.patchValue(this.currentWeightBalanceDataSheet.fk_aircraft_data, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.floor_plan.patchValue(this.currentWeightBalanceDataSheet.weight_balance_data.floor_plan, { emitEvent: false });

    // TRATAMENTO DA IMAGEM QUE SERÁ MOSTRADA DA PLANTA BAIXA
    setTimeout(() => {
      //Pegar o elemento img de this.imageFrame.nativeElement 
      const container = this.imageFrame.nativeElement;
      const tempImg = this.imageFrame.nativeElement.querySelector('img');

      if (this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.floor_plan.controls.orientation.value === "vertical") {
        this._renderer.setStyle(tempImg, 'rotate', `-90deg`);
        this._renderer.setStyle(tempImg, 'height', this.currentSettings.FLOOR_PLANT_IMAGE_WIDTH);
        this._renderer.setStyle(tempImg, 'width', this.currentSettings.FLOOR_PLANT_IMAGE_HEIGHT);

        this._renderer.setStyle(container, 'display', 'flex');
        this._renderer.setStyle(container, 'justify-content', 'center');
        this._renderer.setStyle(container, 'align-items', 'center');
        this._renderer.setStyle(container, 'width', this.currentSettings.FLOOR_PLANT_IMAGE_WIDTH);
        this._renderer.setStyle(container, 'height', this.currentSettings.FLOOR_PLANT_IMAGE_HEIGHT);
      } else {
        this._renderer.setStyle(tempImg, 'rotate', `0deg`);
        this._renderer.setStyle(tempImg, 'max-height', this.currentSettings.FLOOR_PLANT_IMAGE_HEIGHT);
        this._renderer.setStyle(tempImg, 'max-width', this.currentSettings.FLOOR_PLANT_IMAGE_WIDTH);
      }

      // Ajusta o contêiner da imagem para acomodar a rotação

    }, 1000); // Tem que esperar um pouco para que o componente renderize na tela.

    // ----- >>> PREENCHER AS UNIDADES <<< ----- //
    // A unidade de consumo de combustível dever ser definida neste momento, pois ela não existe no Data sheet.
    const tempFuelUnit = this.currentWeightBalanceDataSheet.weight_balance_data.unit_data.fuel;
    const tempFuelRateUnit = `${tempFuelUnit}/h`; // !! AQUI EU POSSO DEFINIR A UNIDADE DE CONSUMO DE COMBUSTÍVEL, POIS EU FAÇO A DEVIDA CONVERSÃO LOGO ABAIXO.

    this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.patchValue(this.currentWeightBalanceDataSheet.weight_balance_data.unit_data, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.fuel_rate.patchValue(tempFuelRateUnit, { emitEvent: false });

    // ----- >>> PREENCHENDO OS DADOS DO WEIGHT BALANCE DATA SHEET <<< ----- //
    this.formWeightBalanceDataFilled.controls.sheet_data.controls.fk_weight_balance_data_sheet_id.patchValue(this.currentWeightBalanceDataSheet.weight_balance_data_sheet_id, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.sheet_data.controls.sheet_version.patchValue(this.currentWeightBalanceDataSheet.sheet_data.sheet_version, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.sheet_data.controls.sheet_version_date_time_utc.patchValue(this.currentWeightBalanceDataSheet.sheet_data.sheet_version_date_time_utc, { emitEvent: false });

    // ----- >>> PREENCHENDO DOS PESO BEM E PESOS ESPECIFICADOS <<< ----- //
    this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.patchValue(this.currentWeightBalanceDataSheet.weight_balance_data.weight_data, { emitEvent: false });

    // ----- >>> PREENCHENDO DADOS DO CONSUMO DE COMBUSTÍVEL <<< ----- //
    // Antes de atribuir os valores definidos para a aeronave, é preciso verificar se está na mesma unidade da Folha de peso e Balanceamento.    
    const tempFuelType = this.objAircraftData.general_data.fuel_data.fuel_type;
    const tempFuelDensityDefault = this.classAviationTools.getFuelDensityDefault(tempFuelType, tempFuelUnit);

    let tempFlightFuelRateAverage = this.objAircraftData.general_data.fuel_data.flight_fuel_rate_average;
    let tempFlightFuelRateAverageUnit = this.objAircraftData.general_data.fuel_data.flight_fuel_rate_average_unit;
    let tempTaxiFuelRateAverage = this.objAircraftData.general_data.fuel_data.taxi_fuel_rate_average;
    let tempTaxiFuelRateAverageUnit = this.objAircraftData.general_data.fuel_data.taxi_fuel_rate_average_unit;

    // ATRIBUIR A DENSIDADE E UNIDADE DO COMBUSTÍVEL
    let tempDensityUnit = "kg/l";

    if (tempFuelUnit === "lb") {
      tempDensityUnit = "lb/gal";
    }
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density_unit.setValue(tempDensityUnit, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density.setValue(tempFuelDensityDefault, { emitEvent: false });

    // ATRIBUIR CAPACIDADE MÁXIMA DE COMBUSTÍVEL
    const tempAircraftTotalCapacity = this.objAircraftData.general_data.fuel_data.total_capacity;
    const tempAircraftTotalCapacityUnit = this.objAircraftData.general_data.fuel_data.total_capacity_unit;

    const tempTotalCapacity = this.classAviationTools.convertFuelUnit(tempAircraftTotalCapacity, tempAircraftTotalCapacityUnit, tempFuelUnit);

    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_capacity.setValue(parseInt(tempTotalCapacity), { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_capacity_unit.setValue(tempFuelUnit, { emitEvent: false });

    // CASO NECESSÁRIO, FAZ CONVERSÃO DA TAXA DE CONSUMO PARA A UNIDADE DA FOLHA DE PESO E BALANCEAMENTO.
    if (tempFlightFuelRateAverageUnit !== tempFuelRateUnit) {
      tempFlightFuelRateAverage = this.classAviationTools.convertFuelRate(tempFlightFuelRateAverage, tempFlightFuelRateAverageUnit, tempFuelRateUnit);
      tempFlightFuelRateAverage = isNaN(tempFlightFuelRateAverage) ? 0 : tempFlightFuelRateAverage;
      tempFlightFuelRateAverageUnit = tempFuelRateUnit;
    }

    // CASO NECESSÁRIO, FAZ CONVERSÃO DA TAXA DE CONSUMO PARA A UNIDADE DA FOLHA DE PESO E BALANCEAMENTO.
    if (tempTaxiFuelRateAverageUnit !== tempFuelRateUnit) {
      tempTaxiFuelRateAverage = this.classAviationTools.convertFuelRate(tempTaxiFuelRateAverage, tempTaxiFuelRateAverageUnit, tempFuelRateUnit);
      tempTaxiFuelRateAverage = isNaN(tempTaxiFuelRateAverage) ? 0 : tempTaxiFuelRateAverage;
      tempTaxiFuelRateAverageUnit = tempFuelRateUnit;
    }

    // Consumo em Voo. Deixar por ultimo o flight_time, pois somente ele vai disparar o evento
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_type.patchValue(this.objAircraftData.general_data.fuel_data.fuel_type, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate.patchValue(tempFlightFuelRateAverage, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate_unit.patchValue(tempFlightFuelRateAverageUnit, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_flight_fuel_unit.patchValue(tempFuelUnit, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_time.patchValue(this.formComponent.get('flt_req_data.eet_total').value ? this.formComponent.get('flt_req_data.eet_total').value : "00:00");

    // Consumo Taxi. Deixar por ultimo o taxi_time, pois somente ele vai disparar o evento
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate.patchValue(tempTaxiFuelRateAverage, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate_unit.patchValue(tempTaxiFuelRateAverageUnit, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_taxi_fuel_unit.patchValue(tempFuelUnit, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_time.patchValue("00:00");

    // CONFIGURAR A UNIDADE DO COMBUSTÍVEL TOTAL DISPONÍVEL PAR AO VOO, "COMBUSTÍVEL RAMP"
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.patchValue(tempFuelUnit, { emitEvent: false });

    //#########################################################################################################//
    // RECUPERAR OS DADOS DE CALCULO DO DATA SHEET PARA O FORMULÁRIO DE PREENCHIMENTO DE PESO E BALANCEAMENTO #//
    //#########################################################################################################//
    // ----- >>> RECUPERA OS DADOS INPUT: INTERNALS <<< ----- //
    const tempComponentsInternalsData = this.currentWeightBalanceDataSheet.weight_balance_data.components_internals_data;

    if (tempComponentsInternalsData && tempComponentsInternalsData.length > 0) {
      tempComponentsInternalsData.forEach((xData: any) => {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls.push(objFormBuilder.group(xData));
      });

      // Acrescentar o Evento que dispara toda vez que se altera o valor do weight: calcula o calor do momento longitudinal e lateral e acrescenta ao componente.
      this._initArrayControlsEvents("components_internals_data");
    }

    // ----- >>> RECUPERA OS DADOS INPUT: EXTERNALS <<< ----- //
    const tempComponentsExternalsData = this.currentWeightBalanceDataSheet.weight_balance_data.components_externals_data;

    if (tempComponentsExternalsData && tempComponentsExternalsData.length > 0) {
      tempComponentsExternalsData.forEach((xData: any) => {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls.push(objFormBuilder.group(xData));
      });

      this._initArrayControlsEvents("components_externals_data");
    }

    // ----- >>> RECUPERA OS DADOS INPUT: CONSTANTS <<< ----- //
    const tempComponentsConstantsData = this.currentWeightBalanceDataSheet.weight_balance_data.components_constants_data;

    if (tempComponentsConstantsData && tempComponentsConstantsData.length > 0) {
      tempComponentsConstantsData.forEach((xData: any) => {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_constants_data.controls.push(objFormBuilder.group(xData));
      });
    }

    // ----- >>> RECUPERA OS DADOS INPUT: FUEL (INPUT TYPE) <<< ----- //
    this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.patchValue(this.currentWeightBalanceDataSheet.weight_balance_data.components_fuel_data.input_type, { emitEvent: false });

    // ----- >>> RECUPERA OS DADOS INPUT: FUEL (TYPE ITEM) <<< ----- //
    const tempComponentsFuelData = this.currentWeightBalanceDataSheet.weight_balance_data.components_fuel_data.fuel_item;

    if (tempComponentsFuelData && tempComponentsFuelData.length > 0) {
      tempComponentsFuelData.forEach((xData: any) => {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_item.controls.push(objFormBuilder.group(xData));
      });

      this._initArrayControlsEvents("components_fuel_data");
    }

    // ----- >>> RECUPERA OS DADOS INPUT: FUEL (TYPE TABLE) <<< ----- //
    const tempComponentsFuelDataTable = this.currentWeightBalanceDataSheet.weight_balance_data.components_fuel_data.fuel_table;

    if (tempComponentsFuelDataTable && tempComponentsFuelDataTable.length > 0) {
      tempComponentsFuelDataTable.forEach((xData: any, xIndex: number) => {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_table.controls.push(objFormBuilder.group(xData));

        // Por algum motivo, na inserção acima ele insere somente o primeiro elemento do table_fuel_cg (deixa de ser um array e passa a ser um elemento simples), então tem que fazer manualmente.
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_table.controls[xIndex].controls.table_fuel_cg.value = xData.table_fuel_cg;
      });
    }

    // ----- >>> CRIAR A MATRIX DE ELEMENTOS <<< ----- //
    this.formWeightBalanceDataFilled.controls.matrix_elements.value = [];

    // ----- >>> PREENCHER A TABELA MATEMÁTICA PARA CALCULAR DO CG <<< ----- //
    // Basic Empty Mass (BEM)
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls.push(objFormBuilder.group({
      calculate_id: null,
      order_show: 1,
      label: "BEM / Aircraft Weight",
      weight: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight,
      longitudinal_arm: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.longitudinal_arm,
      longitudinal_moment: (this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight * this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.longitudinal_arm),
      lateral_arm: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.lateral_arm,
      lateral_moment: (this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight * this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.lateral_arm),
      weight_unit: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight_unit,
      arm_unit: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.longitudinal_arm_unit

    })
    );

    // Zero Few Weight (ZFW) -- Preencher com o valor do BEM, pois o formulário está em branco, no inicio do processo.
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls.push(objFormBuilder.group({
      calculate_id: null,
      order_show: 2,
      label: "Zero Few Weight (ZFW)",
      weight: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight,
      longitudinal_arm: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.longitudinal_arm,
      longitudinal_moment: (this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight * this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.longitudinal_arm),
      lateral_arm: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.lateral_arm,
      lateral_moment: (this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight * this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.lateral_arm),
      weight_unit: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight_unit,
      arm_unit: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.longitudinal_arm_unit
    })
    );

    listElementsCalculation.forEach((xElement: string, xIndex: number) => {
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls.push(objFormBuilder.group({
        calculate_id: null,
        order_show: 3 + xIndex, // 3 é o valor inicial, pois já temos 2 elementos na tabela e o primeiro valor de Index é 0.
        label: xElement,
        weight: 0,
        longitudinal_arm: 0,
        longitudinal_moment: 0,
        lateral_arm: 0,
        lateral_moment: 0,
        weight_unit: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.weight_unit,
        arm_unit: this.currentWeightBalanceDataSheet.weight_balance_data.weight_data.bem.longitudinal_arm_unit
      })
      );
    });

    // ----- >>> ATRIBUI OS VALORES QUE FORMAM O GRÁFICO DO C.G. LONGITUDINAL << ----- //
    if (this.currentWeightBalanceDataSheet.weight_balance_data.chart_longitudinal_data && this.currentWeightBalanceDataSheet.weight_balance_data.chart_longitudinal_data.length > 0) {
      this.currentWeightBalanceDataSheet.weight_balance_data.chart_longitudinal_data.forEach((xData: any, xIndex: number) => {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_longitudinal_data.push(objFormBuilder.group(xData));

        // Tem um problema o comando acima, ele transforma o array limits_data em um objeto, então tem que forçar o patchValue e sobrepor o objeto pelo array.            
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_longitudinal_data.controls[xIndex].controls.limits_data.patchValue(xData.limits_data);
      });
    }

    // ----- >>> ATRIBUI OS VALORES QUE FORMAM O GRÁFICO DO C.G. LATERAL << ----- //
    if (this.currentWeightBalanceDataSheet.weight_balance_data.chart_lateral_data && this.currentWeightBalanceDataSheet.weight_balance_data.chart_lateral_data.length > 0) {
      this.currentWeightBalanceDataSheet.weight_balance_data.chart_lateral_data.forEach((xData: any, xIndex: number) => {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_lateral_data.push(objFormBuilder.group(xData));

        // Tem um problema o comando acima, ele transforma o array limits_data em um objeto, então tem que forçar o patchValue e sobrepor o objeto pelo array.            
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_lateral_data.controls[xIndex].controls.limits_data.patchValue(xData.limits_data);
      });
    }

    // ----- >>> CRIAR A MATRIX DE ELEMENTOS <<< ----- //
    this.formWeightBalanceDataFilled.controls.matrix_elements.value = [];

    // Criar uma matriz com todos os elementos false, mas que tenha a mesma quantidade de elementos do formulário.     
    // 0 - Input Data
    // 1 - External Data
    // 2 - Constant Data
    // 3 - Fuel Data     
    this.formWeightBalanceDataFilled.controls.matrix_elements.value.push(this.currentWeightBalanceDataSheet.weight_balance_data.components_internals_data.map(() => false));
    this.formWeightBalanceDataFilled.controls.matrix_elements.value.push(this.currentWeightBalanceDataSheet.weight_balance_data.components_externals_data.map(() => false));
    this.formWeightBalanceDataFilled.controls.matrix_elements.value.push(this.currentWeightBalanceDataSheet.weight_balance_data.components_constants_data.map(() => true));
    this.formWeightBalanceDataFilled.controls.matrix_elements.value.push(this.currentWeightBalanceDataSheet.weight_balance_data.components_fuel_data.fuel_item.map(() => false))

    // ----- >>> REALIZA O PROCESSAMENTO DO CG <<< ----- //
    this._processCgCalculation();
  }

  /**
   * @status:
   * @date 2025-01-20
   * @version 1.0.0
   * 
   * @description
   *   - Faz o Cálculo de quão perto um ponto está da borda interna do gráfico
   * 
   * @param xChartType Tipo do Gráfico (Longitudinal ou Lateral)
   * 
   */
  private _defineNearInsideChart(xChartType: string): number {
    let returnThreshold = 1;

    // Quem vai definir o espaço considerado o quão perto está o ponto da borda interna é o eixo X
    if (xChartType == "long") {
      const tempLogDrawCoordinates = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data;

      let tempLongitudinalPoints = [];

      if (tempLogDrawCoordinates && tempLogDrawCoordinates.value.length > 0) {
        tempLongitudinalPoints = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.value[0].draw_coordinates;

        // Remove todas as Propriedades diferentes de coordenadas, pois só preciso das coordenadas X e Y
        tempLongitudinalPoints = tempLongitudinalPoints.map((xItem: any) => { return { x: xItem.x, y: xItem.y } });

        // Recupera a menor coordenada x e a maior coordenada x
        const tempMinX = tempLongitudinalPoints.reduce((xPrev: any, xCurrent: any) => xPrev.x < xCurrent.x ? xPrev : xCurrent).x;
        const tempMaxX = tempLongitudinalPoints.reduce((xPrev: any, xCurrent: any) => xPrev.x > xCurrent.x ? xPrev : xCurrent).x;

        // Calcula a distância entre os pontos
        returnThreshold = (tempMaxX - tempMinX) * 0.05; // 5% da distância entre
      }
    } else if (xChartType === "lat") {
      const tempLatDrawCoordinates = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data;

      let tempLateralPoints = [];

      if (tempLatDrawCoordinates && tempLatDrawCoordinates.value.length > 0) {
        tempLateralPoints = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.value[0].draw_coordinates;
      }

      tempLateralPoints = tempLateralPoints.map((xItem: any) => { return { x: xItem.x, y: xItem.y } });

      // Recupera a menor coordenada x e a maior coordenada x
      const tempMinX = tempLateralPoints.reduce((xPrev: any, xCurrent: any) => xPrev.x < xCurrent.x ? xPrev : xCurrent).x;
      const tempMaxX = tempLateralPoints.reduce((xPrev: any, xCurrent: any) => xPrev.x > xCurrent.x ? xPrev : xCurrent).x;

      // Calcula a distância entre os pontos
      returnThreshold = (tempMaxX - tempMinX) * 0.05; // 5% da distância entre
    }

    return returnThreshold;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   *
   * @description 
   *   - Realiza o Cálculo do C.G. 
   */
  private _processCgCalculation(): void {
    // 0) TODAS AS UNIDADES QUE SERÃO UTILIZADAS NO CÁLCULO DO C.G. DEVE SER CONVERTIDAS PARA ESTAS UNIDADES ANTES DE FAZER QUALQUER CALCULO.
    const weightFinalUnit = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.weight.value;
    const armFinalUnit = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.arm.value;
    const fuelFinalUnit = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.fuel.value;

    // 0) OUTRAS CONSTANTES E VARIÁVEIS
    const tempFuelType = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_type.value;

    let tempFuelTaxiWeight = 0;
    let tempFuelFlightWeight = 0;
    let tempFuelSuppliedTotalWeight = 0;

    // 1) ----->>> ZERA AS LINHAS DA TABELA PARA REFAZER TODA A OPERAÇÃO <<<----- //
    for (let i = 1; i < 8; i++) {
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[i].controls.weight.setValue(0, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[i].controls.longitudinal_arm.setValue(0, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[i].controls.longitudinal_moment.setValue(0, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[i].controls.lateral_arm.setValue(0, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[i].controls.lateral_moment.setValue(0, { emitEvent: false });
    }

    // X) VERIFICA SE OS DADOS DO BEM ESTÃO NAS UNIDADES DESEJADAS
    // X.1) Verificar se o Peso está na mesa unidade que o desejado
    const tempBemWeightUnit = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.weight_unit.value;

    let tempBemWeight = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.weight.value;

    if (tempBemWeightUnit !== weightFinalUnit) {
      tempBemWeight = this.classAviationTools.convertWeight(tempBemWeight, tempBemWeightUnit, weightFinalUnit);
      tempBemWeight = isNaN(tempBemWeight) ? 0 : tempBemWeight;

      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.weight.setValue(tempBemWeight, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.weight_unit.setValue(weightFinalUnit, { emitEvent: false });
    }

    // X.2) Verificar se o BRAÇO está na mesa unidade que o desejado
    // TODO: FAZER COM CUIDADO
    const tempBemArmUnit = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.arm_unit.value;

    let tempBemLongitudinalArm = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_arm.value;
    let tempBemLateArm = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_arm.value;

    if (tempBemArmUnit !== armFinalUnit) {
      // LONGITUDINAL
      tempBemLongitudinalArm = this.classAviationTools.convertArm(tempBemLongitudinalArm, tempBemArmUnit, armFinalUnit);
      tempBemLongitudinalArm = isNaN(tempBemLongitudinalArm) ? 0 : tempBemLongitudinalArm;
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_arm.setValue(tempBemLongitudinalArm, { emitEvent: false });

      // LATERAL
      tempBemLateArm = this.classAviationTools.convertArm(tempBemLateArm, tempBemArmUnit, armFinalUnit);
      tempBemLateArm = isNaN(tempBemLateArm) ? 0 : tempBemLateArm;
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_arm.setValue(tempBemLateArm, { emitEvent: false });

      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.arm_unit.setValue(armFinalUnit, { emitEvent: false });
    }

    // X.3) Verificar se o MOMENTO está na mesa unidade que o desejado
    // TODO: FAZER COM CUIDADO
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_moment.setValue(tempBemWeight * tempBemLongitudinalArm, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_moment.setValue(tempBemWeight * tempBemLateArm, { emitEvent: false });

    // 2) ----->>> INICIALIZAR OS CAMPOS IMPAR COM O PESO BÁSICO <<<----- //
    for (let j = 1; j < 8; j = j + 2) {
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[j].controls.weight.setValue(this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.weight.value, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[j].controls.longitudinal_arm.setValue(this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_arm.value, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[j].controls.longitudinal_moment.setValue(this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_moment.value, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[j].controls.lateral_arm.setValue(this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_arm.value, { emitEvent: false });
      this.formWeightBalanceDataFilled.controls.calculation_cg.controls[j].controls.lateral_moment.setValue(this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_moment.value, { emitEvent: false });
    }

    // 3) ----->>> CALCULA O CONSUMO DO TEMPO DE TAXI <<<----- //
    //#region CONSUMO EM MASSA: TAXI             
    const tempTaxiFuelRate = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate.value;
    const tempTaxiTime = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_time.value;
    const fuelTaxiConsumption = parseFloat(this.classAviationTools.calculateSimpleFuelConsumption(tempTaxiTime, tempTaxiFuelRate));

    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_taxi_fuel.patchValue(fuelTaxiConsumption, { emitEvent: false }); // Atualizo no formulário para exibir o valor.

    // ---------------------------------------------------------------- //
    // CONVERTER O VOLUME DE COMBUSTÍVEL EM MASSA E ATRIBUIR AO CALCULO
    // ---------------------------------------------------------------- //
    tempFuelTaxiWeight = this.classAviationTools.convertFuelVolumeToMass(tempFuelType, fuelTaxiConsumption, fuelFinalUnit, weightFinalUnit);
    tempFuelTaxiWeight = isNaN(tempFuelTaxiWeight) ? 0 : tempFuelTaxiWeight;
    //#endregion

    // 4) ----->>> CALCULA O CONSUMO DO TEMPO DE FLIGHT <<<----- // 
    //#region CONSUMO EM MASSA: FLIGHT    
    const tempFlightFuelRate = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate.value;
    const tempFlightTime = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_time.value
    const fuelFlightConsumption = parseFloat(this.classAviationTools.calculateSimpleFuelConsumption(tempFlightTime, tempFlightFuelRate));

    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_flight_fuel.patchValue(fuelFlightConsumption, { emitEvent: false }); // Atualizo no formulário para exibir o valor.

    // ---------------------------------------------------------------- //
    // CONVERTER O VOLUME DE COMBUSTÍVEL EM MASSA E ATRIBUIR AO CALCULO
    // ---------------------------------------------------------------- //
    tempFuelFlightWeight = this.classAviationTools.convertFuelVolumeToMass(tempFuelType, fuelFlightConsumption, fuelFinalUnit, weightFinalUnit);
    tempFuelFlightWeight = isNaN(tempFuelFlightWeight) ? 0 : tempFuelFlightWeight;
    //#endregion

    // 5) ----->>> CALCULA O PESO DO COMBUSTÍVEL ABASTECIDO <<<----- //
    const tempFuelSuppliedTotal = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.value;

    tempFuelSuppliedTotalWeight = this.classAviationTools.convertFuelVolumeToMass(tempFuelType, tempFuelSuppliedTotal, fuelFinalUnit, weightFinalUnit);

    // 6) ----->>> CALCULA O MOMENTO DO COMBUSTÍVEL: TOTAL FUEL, TAKE OFF, LANDING FUEL <<<----- //    
    if (this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value === "item") {
      // Recuperar o momento do tank:
      const tempFuelItemData = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_fuel_data.controls.fuel_item.controls;


      if (tempFuelItemData.length === 1) { // FAZER CONSIDERANDO UM ÚNICO TANQUE, POIS OS BRAÇOS LONG E LATERAL JÁ ESTÃO DEFINIDOS.      
        const tempFuelLongitudinalArmUnit = tempFuelItemData[0].controls.longitudinal_arm_unit.value;
        const tempFuelLateralArmUnit = tempFuelItemData[0].controls.lateral_arm_unit.value;

        let tempArmLong = tempFuelItemData[0].controls.longitudinal_arm.value;
        let tempArmLat = tempFuelItemData[0].controls.lateral_arm.value;

        // 1) Verificar as Unidades dos Braços estão conforme selecionado
        if (tempFuelLongitudinalArmUnit !== armFinalUnit) {
          tempArmLong = this.classAviationTools.convertArm(tempArmLong, tempFuelLongitudinalArmUnit, armFinalUnit)
          tempFuelItemData[0].controls.longitudinal_arm.setValue(tempArmLong, { emitEvent: false });
          tempFuelItemData[0].controls.longitudinal_arm_unit.setValue(armFinalUnit, { emitEvent: false });
        }

        if (tempFuelLateralArmUnit !== armFinalUnit) {
          tempArmLat = this.classAviationTools.convertArm(tempArmLat, tempFuelLateralArmUnit, armFinalUnit)
          tempFuelItemData[0].controls.lateral_arm.setValue(tempArmLat, { emitEvent: false });
          tempFuelItemData[0].controls.lateral_arm_unit.setValue(armFinalUnit, { emitEvent: false });
        }

        // 2) Calcular os Momentos
        // Não preciso me preocupar com o peso, pois este já vem calculado conforme a unidade presente.
        const tempFuelSuppliedTotalLongMoment = tempFuelSuppliedTotalWeight * tempArmLong;
        const tempFuelSuppliedTotalLatMoment = tempFuelSuppliedTotalWeight * tempArmLat;
        const fuelTakeOffWeight = tempFuelSuppliedTotalWeight - tempFuelTaxiWeight;
        const fuelTakeOffLongMoment = fuelTakeOffWeight * tempArmLong;
        const fuelTakeOffLatMoment = fuelTakeOffWeight * tempArmLat;
        const fuelLandingWeight = tempFuelSuppliedTotalWeight - tempFuelTaxiWeight - tempFuelFlightWeight;
        const fuelLandingLongMoment = fuelLandingWeight * tempArmLong;
        const fuelLandingLatMoment = fuelLandingWeight * tempArmLat;

        // 3) Preencher o Formulário com BRAÇOS.
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.longitudinal_arm.setValue(tempArmLong, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.lateral_arm.setValue(tempArmLat, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.longitudinal_arm.setValue(tempArmLong, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.lateral_arm.setValue(tempArmLat, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.longitudinal_arm.setValue(tempArmLong, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.lateral_arm.setValue(tempArmLat, { emitEvent: false });

        // 4) PREENCHER COM OS PESOS E MOMENTOS
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.weight.setValue(tempFuelSuppliedTotalWeight, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.longitudinal_moment.setValue(tempFuelSuppliedTotalLongMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.lateral_moment.setValue(tempFuelSuppliedTotalLatMoment, { emitEvent: false });

        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.weight.setValue(fuelTakeOffWeight, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.longitudinal_moment.setValue(fuelTakeOffLongMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.lateral_moment.setValue(fuelTakeOffLatMoment, { emitEvent: false });

        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.weight.setValue(fuelLandingWeight, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.longitudinal_moment.setValue(fuelLandingLongMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.lateral_moment.setValue(fuelLandingLatMoment, { emitEvent: false });
      }
    }

    // ----->>> CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE <<<----- //
    //###########################################################################################//
    // Linha 0 da matrix_elements =>  components_input_data;
    // Linha 1 da matrix_elements =>  components_external_data;
    // Linha 2 da matrix_elements =>  components_constant_data;
    // Linha 3 da matrix_elements =>  components_fuel_data;    
    //##########################################################################################

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_input_data
    for (let i = 0; i < this.formWeightBalanceDataFilled.controls.matrix_elements.value[0].length; i++) {
      if (this.formWeightBalanceDataFilled.controls.matrix_elements.value[0][i]) {
        // ----->>> CALCULAR ZFW <<<----- //
        const tempWeightZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value;
        const tempLongMomentZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.value;
        const tempLatMomentZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.value;

        const tempWeightComponent = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls[i].controls.weight.value;
        const tempLongMomentComponent = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls[i].controls.longitudinal_moment.value;
        const tempLatMomentComponent = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls[i].controls.lateral_moment.value;

        const totalWeight = tempWeightZfw + tempWeightComponent;
        const totalLongMoment = tempLongMomentZfw + tempLongMomentComponent;
        const totalLatMoment = tempLatMomentZfw + tempLatMomentComponent;

        // CALCULO DOS BRAÇOS
        const totalLongArm = (totalLongMoment / totalWeight);
        const totalLatArm = (totalLatMoment / totalWeight);

        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.setValue(totalWeight, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_arm.setValue(totalLongArm, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.setValue(totalLongMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_arm.setValue(totalLatArm, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.setValue(totalLatMoment, { emitEvent: false });
      }
    }

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_externals_data
    for (let i = 0; i < this.formWeightBalanceDataFilled.controls.matrix_elements.value[1].length; i++) {
      if (this.formWeightBalanceDataFilled.controls.matrix_elements.value[1][i]) {
        // ----->>> CALCULAR ZFW <<<----- //
        const tempWeightZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value;
        const tempLongMomentZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.value;
        const tempLatMomentZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.value;

        const tempWeightComponent = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls[i].controls.weight.value;
        const tempLongMomentComponent = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls[i].controls.longitudinal_moment.value;
        const tempLatMomentComponent = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls[i].controls.lateral_moment.value;

        const totalWeight = tempWeightZfw + tempWeightComponent;
        const totalLongMoment = tempLongMomentZfw + tempLongMomentComponent;
        const totalLatMoment = tempLatMomentZfw + tempLatMomentComponent;

        // CALCULO DOS BRAÇOS
        const totalLongArm = (totalLongMoment / totalWeight);
        const totalLatArm = (totalLatMoment / totalWeight);

        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.setValue(totalWeight);
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_arm.setValue(totalLongArm);
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.setValue(totalLongMoment);
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_arm.setValue(totalLatArm);
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.setValue(totalLatMoment);
      }
    }

    // CALCULAR O MOMENTO LONGITUDINAL E LATERAL DE CADA COMPONENTE: components_constants_data
    for (let i = 0; i < this.formWeightBalanceDataFilled.controls.matrix_elements.value[2].length; i++) {
      // 1) PRIMEIRA COISA VERIFICAR SE O COMPONENTE FAZ PARTE DO BEM, POIS VAI DEFINIR A LOGICA...      
      if (this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_constants_data.controls[i].controls.is_belongs_bem.value === false) {
        // 1.1) NESTE CASO SEGUE O FLUXO NORMAL, OU SEJA, O PESO DO COMPONENTE SOMA-SE AO PESO DO ZFW E CALCULA O MOMENTO LONGITUDINAL E LATERAL, QUANDO ATIVADO. 
        if (this.formWeightBalanceDataFilled.controls.matrix_elements.value[2][i]) {
          // ----->>> CALCULAR ZFW <<<----- //
          const tempWeightZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value;
          const tempLongMomentZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.value;
          const tempLatMomentZfw = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.value;

          const tempWeightComponent = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_constants_data.controls[i].controls.weight.value;
          const tempLongArm = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_constants_data.controls[i].controls.longitudinal_arm.value;
          const tempLatArm = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_constants_data.controls[i].controls.lateral_arm.value;
          const tempLongMomentComponent = tempLongArm * tempWeightComponent;
          const tempLatMomentComponent = tempLatArm * tempWeightComponent;

          const totalWeight = tempWeightZfw + tempWeightComponent;
          const totalLongMoment = tempLongMomentZfw + tempLongMomentComponent;
          const totalLatMoment = tempLatMomentZfw + tempLatMomentComponent;

          // CALCULO DOS BRAÇOS
          const totalLongArm = (totalLongMoment / totalWeight);
          const totalLatArm = (totalLatMoment / totalWeight);

          this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.setValue(totalWeight);
          this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_arm.setValue(totalLongArm);
          this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.setValue(totalLongMoment);
          this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_arm.setValue(totalLatArm);
          this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.setValue(totalLatMoment);

          // INSERIR OS MOMENTOS PARA SALVAR
          this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_constants_data.controls[i].controls.longitudinal_moment.setValue(tempLongMomentComponent);
          this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_constants_data.controls[i].controls.lateral_moment.setValue(tempLatMomentComponent);
        }
      } else {
        // !! TEM QUE IMPLEMENTAR AINDAAAAAAAAAAAAAAAAAAA
      }
    }

    // !!! *** ATENÇÃO COMBUSTÍVEL **
    // !!! NESTA FASE INICIAL EU VOU CONTAR QUE A AERONAVE TEM SOMENTE 1 TANQUE, QUANDO FOR COMPONENTE: components_fuel_data 
    // !!! VOU TER QUE FAZER UM ESTUDO QUANDO FOR MAIS DE 1 TANQUE DE COMBUSTÍVEL. QUANTOS POR CENTO DE CADA TANQUE É CONSUMIDO NO TAXI E NO VOO.
    // !!! O CALCULO CONSIDERANDO 1 TANQUE QUANDO FOR  input_type "item" E "table" É IMPLEMENTADO NO ITEM: 6) ----->>> CALCULA O MOMENTO DO COMBUSTÍVEL: TOTAL FUEL, TAXI FUEL, FLIGHT FUEL <<<-----

    // REFORÇAR (PARA TIRAR QUALQUER DUVIDA): CALCULAR O BRAÇO LONGITUDINAL E LATERAL DO ZERO FUEL WEIGHT (ZFW)
    const tempZfwWeight = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value;
    const tempZfwLongMoment = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.value;
    const tempZfwLatMoment = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.value;

    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_arm.setValue((tempZfwLongMoment / tempZfwWeight), { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_arm.setValue((tempZfwLatMoment / tempZfwWeight), { emitEvent: false });

    // ----->>> CALCULAR RAMP WEIGHT (RW) <<<----- //    
    const tempRwWeight = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.weight.value;
    const tempRwLongMom = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.longitudinal_moment.value;
    const tempRwLatMom = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[2].controls.lateral_moment.value;
    const tempRwLongArm = tempRwLongMom / tempRwWeight;
    const tempRwLatArm = tempRwLatMom / tempRwWeight;

    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.weight.setValue(tempRwWeight, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.longitudinal_moment.setValue(tempRwLongMom, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.lateral_moment.setValue(tempRwLatMom, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.longitudinal_arm.setValue(tempRwLongArm, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.lateral_arm.setValue(tempRwLatArm, { emitEvent: false });

    // ----->>> CALCULAR TAKE OFF WEIGHT (TOW) <<<----- //
    const tempTowWeight = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.weight.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value;
    const tempTowLongMom = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.longitudinal_moment.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.value;
    const tempTowLatMom = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[4].controls.lateral_moment.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.value;
    const tempTowLongArm = tempTowLongMom / tempTowWeight;
    const tempTowLatArm = tempTowLatMom / tempTowWeight;

    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.weight.setValue(tempTowWeight, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.longitudinal_moment.setValue(tempTowLongMom, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.lateral_moment.setValue(tempTowLatMom, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.longitudinal_arm.setValue(tempTowLongArm, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.lateral_arm.setValue(tempTowLatArm, { emitEvent: false });

    // ----->>> CALCULAR LANDING OFF WEIGHT (LDW) <<<----- //
    const tempLdwWeight = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.weight.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value;
    const tempLdwLongMom = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.longitudinal_moment.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_moment.value;
    const tempLdwLatMom = this.formWeightBalanceDataFilled.controls.calculation_cg.controls[6].controls.lateral_moment.value + this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_moment.value;
    const tempLdwLongArm = tempLdwLongMom / tempLdwWeight;
    const tempLdwLatArm = tempLdwLatMom / tempLdwWeight;

    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.weight.setValue(tempLdwWeight, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.longitudinal_moment.setValue(tempLdwLongMom, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.lateral_moment.setValue(tempLdwLatMom, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.longitudinal_arm.setValue(tempLdwLongArm, { emitEvent: false });
    this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.lateral_arm.setValue(tempLdwLatArm, { emitEvent: false });

    // ----->>> INSERIR O PONTO ZFW <<<----- //   
    const tempLongPointsToDraw: any[] = [];
    const tempLatPointsToDraw: any[] = [];

    tempLongPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value,
        color: "#00b7eb",
        label: "ZFW"
      }
    )

    tempLatPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value,
        color: "#00b7eb",
        label: "ZFW"
      }
    )

    // ----->>> INSERIR O PONTO RW <<<----- //  
    tempLongPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.weight.value,
        color: "#000",
        label: "RW"
      }
    )

    tempLatPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.weight.value,
        color: "#000",
        label: "RW"
      }
    )

    // ----->>> INSERIR O PONTO TOW <<<----- // 
    tempLongPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.weight.value,
        color: "#3cb371",
        label: "TOW"
      }
    )

    tempLatPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.weight.value,
        color: "#3cb371",
        label: "TOW"
      }
    )

    // ----->>> INSERIR O PONTO LDW <<<----- // 
    tempLongPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.weight.value,
        color: "#dc143c",
        label: "LDW"
      }
    )

    tempLatPointsToDraw.push(
      {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.weight.value,
        color: "#dc143c",
        label: "LDW"
      }
    )

    // Tem que fazer isto, para forcar a renderização.
    this.listLongPointsToDraw = [];
    this.listLongPointsToDraw = tempLongPointsToDraw;

    this.listLatPointsToDraw = [];
    this.listLatPointsToDraw = tempLatPointsToDraw;

    // ----- >>> CALCULAR SE OS PONTOS ESTÃO DENTRO DO ENVELOPE LONGITUDINAL E LATERAL <<< ----- //         
    // !! ATENÇÃO: NESTE PONTO QUE SERÁ FEITA ANALISE PARA VER QUAL DOS GRÁFICOS DE LIMITE SERÁ UTILIZADO: OPERAÇÃO NORMAL OU COM CARGA EXTERNA.   
    const tempLogDrawCoordinates = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data;
    const tempLatDrawCoordinates = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data;

    let tempLongitudinalPoints = [];
    let tempLateralPoints = [];

    if (tempLogDrawCoordinates && tempLogDrawCoordinates.value.length > 0) {
      tempLongitudinalPoints = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_longitudinal_data.controls[0].controls.limits_data.value[0].draw_coordinates;
    }

    if (tempLatDrawCoordinates && tempLatDrawCoordinates.value.length > 0) {
      tempLateralPoints = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.chart_lateral_data.controls[0].controls.limits_data.value[0].draw_coordinates;
    }

    // Remove todas as Propriedades diferentes de coordenadas, pois só preciso das coordenadas X e Y
    tempLongitudinalPoints = tempLongitudinalPoints.map((xItem: any) => { return { x: xItem.x, y: xItem.y } });
    tempLateralPoints = tempLateralPoints.map((xItem: any) => { return { x: xItem.x, y: xItem.y } });

    const objCheckCgLongitudinal = new this.classCheckCgEnvelope(tempLongitudinalPoints);
    const objCheckCgLateral = new this.classCheckCgEnvelope(tempLateralPoints);

    // ***** CALCULAR O PONTO ZFW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    this.objPositionChart.zfwPositionChartLong = "none";
    this.objPositionChart.zfwPositionChartLat = "none";

    if (tempLongitudinalPoints.length > 0) {
      const tempZfwLongitudinal = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value
      }

      this.objPositionChart.zfwPositionChartLong = objCheckCgLongitudinal.checkPoint(tempZfwLongitudinal, this._defineNearInsideChart("long"));
    }

    if (tempLateralPoints.length > 0) {
      const tempZfwLateral = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[1].controls.weight.value
      }

      this.objPositionChart.zfwPositionChartLat = objCheckCgLateral.checkPoint(tempZfwLateral, this._defineNearInsideChart("lat"));
    }

    // ***** CALCULAR O PONTO RW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    this.objPositionChart.rwPositionChartLong = "none";
    this.objPositionChart.rwPositionChartLat = "none";

    if (tempLongitudinalPoints.length > 0) {
      const tempRwLongitudinal = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.weight.value
      }

      this.objPositionChart.rwPositionChartLong = objCheckCgLongitudinal.checkPoint(tempRwLongitudinal, this._defineNearInsideChart("long"));
    }

    if (tempLateralPoints.length > 0) {
      const tempRwLateral = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[3].controls.weight.value
      }

      this.objPositionChart.rwPositionChartLat = objCheckCgLateral.checkPoint(tempRwLateral, this._defineNearInsideChart("lat"));
    }

    // ***** CALCULAR O PONTO TOW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    this.objPositionChart.towPositionChartLong = "none";
    this.objPositionChart.towPositionChartLat = "none";

    if (tempLongitudinalPoints.length > 0) {
      const tempTowLongitudinal = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.weight.value
      }

      this.objPositionChart.towPositionChartLong = objCheckCgLongitudinal.checkPoint(tempTowLongitudinal, this._defineNearInsideChart("long"));
    }

    if (tempLateralPoints.length > 0) {
      const tempTowLateral = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[5].controls.weight.value
      }

      this.objPositionChart.towPositionChartLat = objCheckCgLateral.checkPoint(tempTowLateral, this._defineNearInsideChart("lat"));
    }

    // ***** CALCULAR O PONTO LDW NO ENVELOPE LONGITUDINAL E LATERAL ***** //
    this.objPositionChart.ldwPositionChartLong = "none";
    this.objPositionChart.ldwPositionChartLat = "none";

    if (tempLongitudinalPoints.length > 0) {
      const tempLdwLongitudinal = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.longitudinal_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.weight.value
      }

      this.objPositionChart.ldwPositionChartLong = objCheckCgLongitudinal.checkPoint(tempLdwLongitudinal, this._defineNearInsideChart("long"));
    }

    if (tempLateralPoints.length > 0) {
      const tempLdwLateral = {
        x: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.lateral_arm.value,
        y: this.formWeightBalanceDataFilled.controls.calculation_cg.controls[7].controls.weight.value
      }

      this.objPositionChart.ldwPositionChartLat = objCheckCgLateral.checkPoint(tempLdwLateral, this._defineNearInsideChart("lat"));
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"  
  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o Nome do Weight Balance Data Sheet.
   * @param xSheetId 
   * @returns 
   */
  public getSheetName(xSheetId: string): string {
    if (this.arrWeightBalanceDataSheet.length > 0 && xSheetId !== undefined && xSheetId !== null && xSheetId !== "") {
      const tempSheet = this.arrWeightBalanceDataSheet.find((xItem: any) => xItem.weight_balance_data_sheet_id === xSheetId);

      return tempSheet.weight_balance_data.header_data.name;
    } else {
      return "";
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna a Descrição do Weight Balance Data Sheet.
   * 
   * @param xSheetId
   * @returns 
   */
  public getSheetDescription(xSheetId: string): string {
    if (this.arrWeightBalanceDataSheet.length > 0 && xSheetId !== undefined && xSheetId !== null && xSheetId !== "") {
      const tempSheet = this.arrWeightBalanceDataSheet.find((xItem: any) => xItem.weight_balance_data_sheet_id === xSheetId);

      return tempSheet.weight_balance_data.header_data.description;
    } else {
      return "";
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna a Icao Code da Aeronave ligada ao Weight Balance Data Sheet.
   * 
   * @param xSheetId
   * @returns 
   */
  public getSheetAircraftIcaoCode(xSheetId: string): string {
    if (this.arrWeightBalanceDataSheet.length > 0 && xSheetId !== undefined && xSheetId !== null && xSheetId !== "") {
      const tempSheet = this.arrWeightBalanceDataSheet.find((xItem: any) => xItem.weight_balance_data_sheet_id === xSheetId);

      return tempSheet.weight_balance_data.header_data.aircraft_icao_code;
    } else {
      return "";
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o Modelo da Aeronave ligada ao Weight Balance Data Sheet.
   * 
   * @param xSheetId
   * @returns 
   */
  public getSheetAircraftModel(xSheetId: string): string {
    if (this.arrWeightBalanceDataSheet.length > 0 && xSheetId !== undefined && xSheetId !== null && xSheetId !== "") {
      const tempSheet = this.arrWeightBalanceDataSheet.find((xItem: any) => xItem.weight_balance_data_sheet_id === xSheetId);

      return tempSheet.weight_balance_data.header_data.aircraft_model;
    } else {
      return "";
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o Tipo da Aeronave ligada ao Weight Balance Data Sheet.
   * 
   * @param xSheetId
   * @returns 
   */
  public getSheetAircraftType(xSheetId: string): string {
    if (this.arrWeightBalanceDataSheet.length > 0 && xSheetId !== undefined && xSheetId !== null && xSheetId !== "") {
      const tempSheet = this.arrWeightBalanceDataSheet.find((xItem: any) => xItem.weight_balance_data_sheet_id === xSheetId);

      return this.classModuleMethods.getAircraftTypeLabel(tempSheet.weight_balance_data.header_data.aircraft_type);
    } else {
      return "";
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna a versão da Weight Balance Data Sheet.
   * 
   * @param xSheetId
   * @returns 
   */
  public getSheetVersion(xSheetId: string): string {
    if (this.arrWeightBalanceDataSheet.length > 0 && xSheetId !== undefined && xSheetId !== null && xSheetId !== "") {
      const tempSheet = this.arrWeightBalanceDataSheet.find((xItem: any) => xItem.weight_balance_data_sheet_id === xSheetId);

      return tempSheet.sheet_version;
    } else {
      return "";
    }
  }

  /**
   * @status:
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna se o componente do CG é um Assento de Piloto.
   * 
   * @param xSheetId
   * @returns 
   */
  public isPilotSeat(xValue: string): boolean {
    return xValue === this.cgComponentType.PILOT_SEAT;
  }

  /**
   * @status:
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna se o componente do CG é um Assento PAX.
   * 
   * @param xSheetId
   * @returns 
   */
  public isPaxSeat(xValue: string): boolean {
    return xValue === this.cgComponentType.PAX_SEAT;
  }

  /**
   * @status:
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna se o componente do CG é um Componente Interno.
   * 
   * @param xSheetId
   * @returns 
   */
  public isInternalsLoad(xValue: string): boolean {
    return xValue === this.cgComponentType.LUGGAGE_RACK
      || xValue === this.cgComponentType.INTERNAL_LOAD
      || xValue === this.cgComponentType.BALLAST
      || xValue === this.cgComponentType.STRETCHER
      || xValue === this.cgComponentType.OTHERS;
  }

  /**
   * @status:
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna se o componente do CG é um Componente Externo.
   * 
   * @param xSheetId
   * @returns 
   */
  public isExternalsLoad(xValue: string): boolean {
    return xValue === this.cgComponentType.LUGGAGE_RACK
      || xValue === this.cgComponentType.BASKET_LOAD
      || xValue === this.cgComponentType.EXTERNAL_LOAD
      || xValue === this.cgComponentType.HOIST_LOAD
      || xValue === this.cgComponentType.BALLAST
      || xValue === this.cgComponentType.OTHERS;
  }

  /**
   * @status:
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna se o componente do CG é um Componente Constantes (Removíveis).
   * 
   * @param xSheetId
   * @returns 
   */
  public isConstants(xValue: string): boolean {
    return xValue === this.cgComponentType.CYCLIC
      || xValue === this.cgComponentType.COLLECTIVE
      || xValue === this.cgComponentType.PEDAL
      || xValue === this.cgComponentType.DOOR
      || xValue === this.cgComponentType.BALLAST
      || xValue === this.cgComponentType.OTHERS;
  }

  /**
   * @status:
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *  - Retorna a Label da Unidade de Peso do Combustível. 
   *
   * @returns 
   */
  public getFuelUnitLabel(xValue = ""): string {
    if (xValue === "") {
      xValue = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.fuel.value;
    }

    let tempFuelUnitLabel = this.optionsCgFuelUnits.find((xItem: any) => xItem.value === xValue).label;

    tempFuelUnitLabel = tempFuelUnitLabel.replace(`${xValue} - `, "")

    return tempFuelUnitLabel;
  }

  /**
   * @status:
   * @date 2025-01-07
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o nome do tipo de Combustível.
   * 
   * @param xFuelType
   * @returns
   */
  public getFuelTypeLabel(xFuelType: string): string {
    if (xFuelType !== null && xFuelType !== undefined && xFuelType !== "") {
      const temp = this.optionsFuelType.filter((xItem: any) => xItem.value === xFuelType);
      
      return temp[0].label;
    } else {
      return "";
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-08
   * @version 1.0.0
   * 
   * @description 
   *   - Analisa se o Ponto está dentro ou fora do gráfico e retorna cores indicando a situação.
   * 
   *  @xIndex
   *  @xChart
   */
  public getClassCgInChart(xIndex: number, xChart: string): string {
    let tempPosition = "";

    if (xIndex === 1) { // ZFW
      if (xChart === "long") {
        tempPosition = this.objPositionChart.zfwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.objPositionChart.zfwPositionChartLat;
      }
    } else if (xIndex === 3) { // RW
      if (xChart === "long") {
        tempPosition = this.objPositionChart.rwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.objPositionChart.rwPositionChartLat;
      }
    } else if (xIndex === 5) { // TOW
      if (xChart === "long") {
        tempPosition = this.objPositionChart.towPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.objPositionChart.towPositionChartLat;
      }
    } else if (xIndex === 7) { // LDW
      if (xChart === "long") {
        tempPosition = this.objPositionChart.ldwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.objPositionChart.ldwPositionChartLat;
      }
    }

    if (tempPosition === this.positionCgInEnvelop.INSIDE) {
      return "cls-cmp-esp-cg-alert-ok";
    } else if (tempPosition === this.positionCgInEnvelop.INSIDE_NEAR_BOARD) {
      return "cls-cmp-esp-cg-alert-warning";
    } else if (tempPosition === this.positionCgInEnvelop.OUTSIDE) {
      return "cls-cmp-esp-cg-alert-danger";
    } else {
      return "";
    }
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2025-01-12
  * @version 1.0.0
  * 
  * @description 
  *   - Retorno da Url para mostrar a imagem.
  *
  * @returns
  */
  public getUrlImageToShowWeightBalanceDataSheetImage(): string {
    const imageName = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.floor_plan.controls.image.value;

    let urlShowImage: string;
    let tempId = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_WEIGHT_BALANCE_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.formWeightBalanceDataFilled.controls.sheet_data.controls.fk_weight_balance_data_sheet_id.value != null) {
      tempId = this.formWeightBalanceDataFilled.controls.sheet_data.controls.fk_weight_balance_data_sheet_id.value;
    }

    return `${urlShowImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/weight/balance/data/sheet/floor/plan/image/${tempId}/${imageName}`.toLowerCase();
  }

  /**
   * @status:
   * @date 2025-01-12
   * @version 1.0.0
   * 
   * @description 
   *   - Retorna o valor do peso na unidade oposta.
   * 
   * @returns 
   */
  public getOppositeUnitWeight(xWeight: string, xCurrentUnit: string): string {
    if (xCurrentUnit === "kg") {
      const tempValueLib = this.classAviationTools.convertWeight(xWeight, "kg", "lb").toFixed(2);

      return `(Em Libras ${tempValueLib} lb)`;
    } else if (xCurrentUnit === "lb") {
      const tempValueKg = this.classAviationTools.convertWeight(xWeight, "lb", "kg").toFixed(2);

      return `(Em Quilogramas: ${tempValueKg} kg)`;
    } else {
      return "";
    }
  }

  /**
   * @status: 
   * @date 2025-01-12
   * @version 1.0.0
   * 
   * @description
   *   - Cria o menu para inserir os valores de peso padrão para CREW/PAX
   * 
   * @param xItem
   * @param xCurrentUnit
   */
  public createMenuItemCrewPaxData(xItem: any, xIndex: number, xCurrentUnit: string,): MenuItem[] {
    // Linha 0 da matrixOfElements =>  components_input_data;
    // Linha 1 da matrixOfElements =>  components_external_data;
    // Linha 2 da matrixOfElements =>  components_constant_data;
    // Linha 3 da matrixOfElements =>  components_fuel_data;  

    // !!! ATENÇÃO: Optei por usar um vetor de arrays ao invés de retornar simplesmente o MenuItem[] formado dentro do método;
    // !!! O motivo foi que retornando direto ele é renderizado direto, e isto criava um problema: o usuário tinha que clicar 2 vezes seguida para executar o item de menu.
    // !!! Para evitar o duplo clique, usa o vetor de ....

    let returnMenuItem: MenuItem[] = [];

    // Preencher o Array de controles para todos os componentes: components_input_data. Vai ser executada uma única vez.
    if (this.itemsMenuWeightPatternCrewPaxData === undefined) {
      this.itemsMenuWeightPatternCrewPaxData = [[]];

      for (let i = 0; i < this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls.length; i++) {
        this.itemsMenuWeightPatternCrewPaxData[0].push([]);
      }
    }

    if (this.itemsMenuWeightPatternCrewPaxData[0][xIndex]['length'] === 0) {
      returnMenuItem = [
        {
          label: '- Pessoa',
          items: [
            {
              label: `82 kg /  ${this.classLocalMethods.convertNumberToLocale(180.78, this._locale)} lb`,
              icon: 'fa-solid fa-person',
              command: () => {
                if (xCurrentUnit == "kg") {
                  xItem.controls.weight.setValue(82);
                } else {
                  xItem.controls.weight.setValue(180.78);
                }
              }
            },
            {
              label: `70 kg /  ${this.classLocalMethods.convertNumberToLocale(154.32, this._locale)} lb`,
              icon: 'fa-solid fa-person',
              command: () => {
                if (xCurrentUnit == "kg") {
                  xItem.controls.weight.setValue(70);
                } else {
                  xItem.controls.weight.setValue(154.32);
                }
              }
            },
            {
              label: `35 kg /  ${this.classLocalMethods.convertNumberToLocale(77.16, this._locale)} lb`,
              icon: 'fa-solid fa-child',
              command: () => {
                if (xCurrentUnit == "kg") {
                  xItem.controls.weight.setValue(35);
                } else {
                  xItem.controls.weight.setValue(77.16);
                }
              }
            },
            {
              label: `0 kg /  0 lb`,
              icon: 'fa-solid fa-person',
              command: () => {
                xItem.controls.weight.setValue(0);
              }
            }
          ]
        },
        {
          label: '- Com Bag. de Mão',
          items: [
            {
              label: `90 kg /  ${this.classLocalMethods.convertNumberToLocale(198.42, this._locale)} lb`,
              icon: 'fa-solid fa-person-walking-luggage',
              command: () => {
                if (xCurrentUnit == "kg") {
                  xItem.controls.weight.setValue(90);
                } else {
                  xItem.controls.weight.setValue(198.42);
                }
              }
            },
            {
              label: `78 Kg / ${this.classLocalMethods.convertNumberToLocale(171.96, this._locale)} lb`,
              icon: 'fa-solid fa-person-walking-luggage',
              command: () => {
                if (xCurrentUnit == "kg") {
                  xItem.controls.weight.setValue(78);
                } else {
                  xItem.controls.weight.setValue(171.96);
                }
              }
            },
            {
              label: `43 Kg / ${this.classLocalMethods.convertNumberToLocale(97.8, this._locale)} lb`,
              icon: 'fa-solid fa-person-walking-luggage',
              command: () => {
                if (xCurrentUnit == "kg") {
                  xItem.controls.weight.setValue(43);
                } else {
                  xItem.controls.weight.setValue(97.8);
                }
              }
            },
          ]
        }
      ];

      this.itemsMenuWeightPatternCrewPaxData[0][xIndex] = returnMenuItem;
    } else {
      for (let j = 0; j < this.itemsMenuWeightPatternCrewPaxData[0][xIndex]["length"]; j++) {
        returnMenuItem.push(this.itemsMenuWeightPatternCrewPaxData[0][xIndex][j]);
      }
    }

    return returnMenuItem;
  }
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status: 
   * @author GASPAR
   * @date 2024-10-15
   * @version 1.0.0
   * 
   * @description 
   *   - Evento acionado quando o usuário seleciona uma Folha de Peso e Balanceamento.
   */
  private _initFormWeightBalanceSheetEvents(): void {
    const fuelConsumptionData = this.formWeightBalanceDataFilled.get('fuel_consumption_data') as FormGroup;

    Object.keys(fuelConsumptionData.controls).forEach(controlName => {
      fuelConsumptionData.get(controlName)?.valueChanges.subscribe(xValue => {
        const flightTime = fuelConsumptionData.get('flight_time')?.value;
        const flightFuelRate = fuelConsumptionData.get('flight_fuel_rate')?.value;
        const taxiTime = fuelConsumptionData.get('taxi_time')?.value;
        const taxiFuelRate = fuelConsumptionData.get('taxi_fuel_rate')?.value;

        let fuelFlightConsumption = 0;
        let fuelTaxiConsumption = 0

        // Não tirar, pois vai servir para futuras implementações
        //console.log(`Field ${controlName} changed to:`, xValue);

        if (flightTime && flightFuelRate) { // Se tiver tempo de voo e taxa de combustível de voo, calcula o consumo de combustível de voo.
          fuelFlightConsumption = parseFloat(this.classAviationTools.calculateSimpleFuelConsumption(flightTime, flightFuelRate));
        } else {
          fuelFlightConsumption = 0;
        }

        if (taxiTime && taxiFuelRate) { // Se tiver tempo de taxi e taxa de combustível de taxi, calcula o consumo de combustível de taxi.
          fuelTaxiConsumption = parseFloat(this.classAviationTools.calculateSimpleFuelConsumption(taxiTime, taxiFuelRate));
        } else {
          fuelTaxiConsumption = 0;
        }

        // Inserir os valores do consumo de combustível no Formulário de preenchimento.
        if (this.formWeightBalanceDataFilled !== null) {
          this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_flight_fuel.patchValue(fuelFlightConsumption, { emitEvent: false });
          this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_taxi_fuel.patchValue(fuelTaxiConsumption, { emitEvent: false });
        }

        if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
          this._processCgCalculation();
        }
      });
    });

    this.formWeightBalanceDataFilled.get('fuel_consumption_data.total_fuel_supplied').valueChanges.subscribe((xValue: any) => {
      if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
        this._processCgCalculation();
      }
    });

    this.formWeightBalanceDataFilled.get('weight_balance_data.unit_data.weight').valueChanges.subscribe((xValue: any) => {
      if (xValue !== undefined && xValue !== null && xValue !== "") {
        if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
          this._processCgCalculation();
        }
      }
    });

    this.formWeightBalanceDataFilled.get('weight_balance_data.unit_data.arm').valueChanges.subscribe((xValue: any) => {
      if (xValue !== undefined && xValue !== null && xValue !== "") {
        if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
          this._processCgCalculation();
        }
      }
    });

    this.formWeightBalanceDataFilled.get('weight_balance_data.unit_data.fuel').valueChanges.subscribe((xValue: any) => {
      if (xValue !== undefined && xValue !== null && xValue !== "") {
        // Como estas unidades não tem direto no form, vou pegar o valor do campo da taxa de consumo e adaptar.
        const tempFuelUnitBeforeChange = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate_unit.value.replaceAll("/h", "")

        // 1) Alterar a unidade padrão de consumo:
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.unit_data.controls.fuel_rate.setValue(`${xValue}/h`, { emitEvent: false });

        // 2) Converter Valor da Taxa de Consumo Flight e Taxi para a nova Unidade
        const tempFlightFuelRate = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate.value;
        const tempTaxiFuelRate = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate.value;

        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate.setValue(this.classAviationTools.convertFuelRate(tempFlightFuelRate, `${tempFuelUnitBeforeChange}/h`, `${xValue}/h`), { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate.setValue(this.classAviationTools.convertFuelRate(tempTaxiFuelRate, `${tempFuelUnitBeforeChange}/h`, `${xValue}/h`), { emitEvent: false });

        // 3) Alterar a UNIDADE e VALOR da Densidade
        const tempFuelDensityUnitBeforeChange = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density_unit.value;
        const tempFuelDensityValue = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density.value;

        let tempFuelDensityUnitNew = "";

        if (xValue === "gal") {
          tempFuelDensityUnitNew = "lb/gal";
        } else {
          tempFuelDensityUnitNew = "kg/l";
        }

        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density.setValue(this.classAviationTools.convertFuelDensity(tempFuelDensityValue, tempFuelDensityUnitBeforeChange, tempFuelDensityUnitNew), { emitEvent: false });

        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.fuel_density_unit.setValue(tempFuelDensityUnitNew, { emitEvent: false })

        // 4) Converter o Valor do Combustível de RAMP, a que o usuário entra
        const tempTotalFuelSupplied = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.value;
        const tempTotalFuelSuppliedUnit = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.value;

        if (!isNaN(tempTotalFuelSupplied)) {
          this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.setValue(this.classAviationTools.convertFuelUnit(tempTotalFuelSupplied, tempTotalFuelSuppliedUnit, xValue), { emitEvent: false });
        }

        // 5) Alterar a unidade de consumo de combustível do Flight e Taxi.
        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.flight_fuel_rate_unit.setValue(`${xValue}/h`, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.taxi_fuel_rate_unit.setValue(`${xValue}/h`, { emitEvent: false });

        // 6) Alterar a unidade de Volume de Combustível: taxi, flight, supplied
        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_flight_fuel_unit.setValue(xValue, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_taxi_fuel_unit.setValue(xValue, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied_unit.setValue(xValue, { emitEvent: false });
      }

      if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
        this._processCgCalculation();
      }
    });


    this.formWeightBalanceDataFilled.get('weight_balance_data.weight_data.bem.longitudinal_moment').valueChanges.subscribe((xValue: any) => {
      let tempLongitudinalArm = 0;
      let tempLongitudinalMoment = 0;

      if (xValue !== undefined && xValue !== null && xValue !== "") {
        const tempBemWeight = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight.value;

        tempLongitudinalArm = xValue / tempBemWeight;
        tempLongitudinalMoment = xValue;

        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_arm.setValue(tempLongitudinalArm, { emitEvent: false });
      } else {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_arm.setValue(tempLongitudinalArm, { emitEvent: false });
      }

      //É preciso
      if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_moment.setValue(tempLongitudinalMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_arm.setValue(tempLongitudinalArm, { emitEvent: false });

        this._processCgCalculation();
      }
    });

    this.formWeightBalanceDataFilled.get('weight_balance_data.weight_data.bem.lateral_moment').valueChanges.subscribe((xValue: any) => {
      let tempLateralArm = 0;
      let tempLateralMoment = 0;

      if (xValue !== undefined && xValue !== null && xValue !== "") {
        const tempBemWeight = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.weight.value;

        tempLateralArm = xValue / tempBemWeight;
        tempLateralMoment = xValue;

        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm.setValue(tempLateralArm, { emitEvent: false });
      } else {
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm.setValue(tempLateralArm, { emitEvent: false });
      }

      if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_moment.setValue(tempLateralMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_arm.setValue(tempLateralArm, { emitEvent: false });

        this._processCgCalculation();
      }
    });

    this.formWeightBalanceDataFilled.get('weight_balance_data.weight_data.bem.weight').valueChanges.subscribe((xValue: any) => {
      let tempWeight = 0;
      let tempLongitudinalArm = 0;
      let tempLongitudinalMoment = 0;
      let tempLateralArm = 0;
      let tempLateralMoment = 0;

      if (xValue !== undefined && xValue !== null && xValue !== "") {
        tempLongitudinalMoment = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_moment.value;
        tempLateralMoment = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_moment.value;
        tempWeight = xValue;

        tempLongitudinalArm = tempLongitudinalMoment / xValue;
        tempLateralArm = tempLateralMoment / xValue;


        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_arm.setValue(tempLongitudinalArm, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm.setValue(tempLateralArm, { emitEvent: false });
      } else {

        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.longitudinal_arm.setValue(tempLongitudinalArm, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.weight_data.controls.bem.controls.lateral_arm.setValue(tempLateralArm, { emitEvent: false });
      }

      if (this.formWeightBalanceDataFilled.controls.calculation_cg.controls.length > 0) {
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.weight.setValue(tempWeight, { emitEvent: false });

        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_moment.setValue(tempLongitudinalMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.longitudinal_arm.setValue(tempLongitudinalArm, { emitEvent: false });

        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_moment.setValue(tempLateralMoment, { emitEvent: false });
        this.formWeightBalanceDataFilled.controls.calculation_cg.controls[0].controls.lateral_arm.setValue(tempLateralArm, { emitEvent: false });

        this._processCgCalculation();
      }
    });


    /*this.formWeightBalanceDataFilled.get('unit_data').get('fuel_rate').valueChanges.subscribe((xValue: string) => {
      this.formWeightBalanceDataFilled.get('flight_data').get('flight_fuel_rate_unit').setValue(xValue, { emitEvent: false });
      this.formWeightBalanceDataFilled.get('flight_data').get('taxi_fuel_rate_unit').setValue(xValue, { emitEvent: false });
    });*/
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-15
   * @version 1.0.0
   * 
   * @description Inicializa os eventos dos controles do Array.
   * 
   * @param xControlName 
   */
  private _initArrayControlsEvents(xControlName: string) {
    if (xControlName !== "components_fuel_data") {
      this.formWeightBalanceDataFilled.get(`weight_balance_data.${xControlName}`).controls.forEach((xComponent: AbstractControl, xIndex: number) => {
        xComponent.get('weight')?.valueChanges.subscribe((xValue: any) => {
          const tempWeight = xValue;

          if (xControlName == "components_internals_data") {
            const tempLongitudinalArm = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls[xIndex].controls.longitudinal_arm.value;
            const tempLateralArm = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls[xIndex].controls.lateral_arm.value;

            this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
            this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_internals_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });
          } else if (xControlName == "components_externals_data") {
            const tempLongitudinalArm = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls[xIndex].controls.longitudinal_arm.value;
            const tempLateralArm = this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls[xIndex].controls.lateral_arm.value;

            this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
            this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_externals_data.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });
          }

          // TODO: REVER ESTE TRECHO DE CÓDIGO
          this._processCgCalculation();
        });
      })
    } else {
      this.formWeightBalanceDataFilled.get(`weight_balance_data.${xControlName}.fuel_item`).controls.forEach((xComponent: AbstractControl, xIndex: number) => {
        xComponent.get('weight')?.valueChanges.subscribe((xValue: any) => {
          const tempWeight = xValue;

          if (this.formWeightBalanceDataFilled.controls.weight_balance_data.controls.components_fuel_data.controls.input_type.value == "item") {
            const tempLongitudinalArm = this.formWeightBalanceDataFilled.controls.components_fuel_data.controls.fuel_item.controls[xIndex].controls.longitudinal_arm.value;
            const tempLateralArm = this.formWeightBalanceDataFilled.controls.components_fuel_data.controls.fuel_item.controls[xIndex].controls.lateral_arm.value;

            this.formWeightBalanceDataFilled.controls.components_fuel_data.controls.fuel_item.controls[xIndex].controls.longitudinal_moment.setValue(tempWeight * tempLongitudinalArm, { emitEvent: false });
            this.formWeightBalanceDataFilled.controls.components_fuel_data.controls.fuel_item.controls[xIndex].controls.lateral_moment.setValue(tempWeight * tempLateralArm, { emitEvent: false });
          }

          // TODO: REVER ESTE TRECHO DE CÓDIGO
          this._processCgCalculation();
        });
      })
    }
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
  * @status:
  * @date 2025-01-20
  * @version 1.0.0
  * 
  * @description 
  *   - Manipula a ação do Formulário de do Preview da Folha de Peso e Balanceamento Preenchida.
  * 
  * @param xAction 
  */
  public handlerToolbarPreview(xAction: any): void {
    if (xAction === ACTION_TYPE.EXIT) {
      this.showWeightBalanceDataFilledPreview = false;
    }
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status: 
   * @author GASPAR
   * @date 2024-10-15
   * @version 1.0.0
   * 
   * @description 
   * 
   * @param xEvent 
   */
  public onClickToolbarTabWeightBalanceFilled(xEvent: any): void {
    if (xEvent === this.actionType.LOAD) {
      console.log("LOAD-----");
      this._loadDataSheetToFill();
    } else if (xEvent === this.actionType.SAVE) {
      console.log("SAVE-----");
    } else if (xEvent === this.actionType.PREVIEW) {
      this.showWeightBalanceDataFilledPreview = true;
    }
  }

  /**
   * @status: 
   * @author GASPAR
   * @date 2024-10-15
   * @version 1.0.0
   * 
   * @description 
   * 
   * @param xAction 
   */
  public onClickToolbarTabPaxList(xAction: string): void {
    if (xAction === this.actionType.ADD_ITEM) {
      this.formWeightBalanceDataFilled.controls.fk_pax_data_list.controls.push(initPaxForm());
    }
  }

  /**
   * @status: 
   * @author GASPAR
   * @date 2024-10-15
   * @version 1.0.0
   * 
   * @description 
   * 
   * @param xAction 
   */
  public onClickItemTabPaxList(xAction: string, xIndex: string): void {
    if (xAction === this.actionType.DELETE) {
      this.formWeightBalanceDataFilled.controls.fk_pax_data_list.controls.splice(parseInt(xIndex), 1);
    } else if (xAction === this.actionType.CLEAR) {
      this.formWeightBalanceDataFilled.controls.fk_pax_data_list.controls[parseInt(xIndex)] = initPaxForm();
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description 
   *   - Método que manipular mudanças de estado do componente Switch.
   * 
   * @param xEvent 
   */
  public onChangeSwitch(xEvent: any): void {
    this._processCgCalculation();
  }

  /**
   * @author GASPAR
   * @date 2025-01-23
   * @version 1.0.0
   * 
   * @description 
   *   - Abre a Opção de escolha do CREW/PAX
   * 
   * @param xEvent 
   */
  public onClickChoosePax() {
    this.showPaxChoose = true;
  }

  /**
   * @author GASPAR
   * @date 2025-01-23
   * @version 1.0.0
   * 
   * @description
   *   - Seleciona a porcentagem do total da capacidade total.
   * @param xPercent 
   */
  public onClickSetFuelPercent(xPercent: string) {
    const tempTotalCapacity = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_capacity.value;
    const tempPercentTotalCapacity = tempTotalCapacity * (parseFloat(xPercent) / 100);

    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.setValue(tempPercentTotalCapacity);
  }

  /**
   * @author GASPAR
   * @date 2025-01-23
   * @version 1.0.0
   * 
   * @description
   *   - Adiciona a porcentagem do total da capacidade total.
   * 
   * @param xPercent
   */
  public onClickAddFuelPercent(xPercent: string) {
    const tempTotalCapacity = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_capacity.value;
    const tempPercentTo = tempTotalCapacity * (parseFloat(xPercent) / 100);
    const tempCurrentTotalFuelSupplied = this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.value;
    let valueToSet = tempCurrentTotalFuelSupplied + tempPercentTo;

    if (valueToSet > tempTotalCapacity) {
      valueToSet = parseFloat(tempTotalCapacity.toFixed(0));
    } else if (valueToSet < 0) {
      valueToSet = 0;
    }

    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.setValue(valueToSet);
  }

  public onChangeHandleKnob(xEvent: any) {
    this.formWeightBalanceDataFilled.controls.fuel_consumption_data.controls.total_fuel_supplied.setValue(xEvent);
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
  * @status:
  * @author GASPAR
  * @date 2024-09-30
  * @version 1.0.0
  * 
  * @description 
  *   - Recupera a Folha de Peso e Balanceamento pelo ID.
  * 
  * @param xIdRegister 
  */
  private async _getWeightBalanceSheetById(): Promise<void> {
    const idWeightBalanceDataSheet = this.formComponent.get('wght_bal_data.fk_wght_bal_data_sheet_id').value

    await this._moduleWeightBalanceService.getWeightBalanceSheetById(idWeightBalanceDataSheet).subscribe({
      next: (xApiResponse) => {
        if (xApiResponse.status_code == HTTP_STATUS.OK) {
          this.currentWeightBalanceDataSheet = xApiResponse.data[0];
        } else {
          this._messageService.add({
            severity: 'error',
            summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetLoad'),
            detail: this.classInternationalization.getTranslation('msg_WeightBalanceDataSheetLoadError'),
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError) => {
        this._messageService.add({
          severity: 'error',
          summary: this.classInternationalization.getTranslation('ttl_WeightBalanceDataSheetLoad'),
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }
  //#endregion











































  //region "|--- EVENT HANDLERS ---|"



  //endregion













  public isFuel(xValue: string): boolean {
    return xValue === this.cgComponentType.FUEL
      || xValue === this.cgComponentType.OTHERS;
  }

  public convertItemTableFuelUnit(xFuelWeight: number, xWeightFinalUnit: string): number {
    const tempFuelType = this.formWeightBalanceDataFilled.controls.flight_data.controls.fuel_type.value;
    const tempCurrentFuelUnit = this.formWeightBalanceDataFilled.controls.unit_data.controls.fuel.value;

    return this.classAviationTools.convertFuelUnit(tempFuelType, xFuelWeight, tempCurrentFuelUnit, xWeightFinalUnit)
  }

  public changeInputFuelInput() {
    return this.typeInputFuel === "input" ? this.typeInputFuel = "knob" : this.typeInputFuel = "input";
  }





  defineMaxWeight(xComponent: any, xMainUnit: string): number {
    let tempMaxValue = 0;

    if (xComponent.controls.max_weight && xComponent.controls.max_weight.value != null && xComponent.controls.max_weight.value > 0) {
      tempMaxValue = xComponent.controls.max_weight.value;
    } else {
      if (xMainUnit == "kg") {
        tempMaxValue = 150
      } else if (xMainUnit == "lb") {
        tempMaxValue = 330
      }
    }

    return tempMaxValue;//xComponent.max_weight ? xComponent.max_weight : this.convertToOppositeWeightUnit(xComponent.max_weight, xMainUnit);
  }

  /**
   * @author GASPAR
   * @date 2024-10-13
   * @version 1.0.0
   * 
   * @description Método que define o valores padrões MÁXIMO do Combustível.
   * 
   * @param xComponent 
   * @param xMainUnit 
   * @returns 
   */
  defineMaxFuel(xComponent: any, xMainUnit: string): number {
    let tempMaxValue = 0;

    if (xComponent.controls.max_weight && xComponent.controls.max_weight.value != null && xComponent.controls.max_weight.value > 0) {
      tempMaxValue = xComponent.controls.max_weight.value;
    } else {
      if (xMainUnit == "kg") {
        tempMaxValue = 5000
      } else if (xMainUnit == "lb") {
        tempMaxValue = 11023
      } else if (xMainUnit == "l") {
        tempMaxValue = 5000
      } else if (xMainUnit == "gal") {
        tempMaxValue = 11023
      }
    }

    return tempMaxValue;//xComponent.max_weight ? xComponent.max_weight : this.convertToOppositeWeightUnit(xComponent.max_weight, xMainUnit);
  }



  public createMenuItemLoad(xItem: any, xCurrentUnit: string, xMaxWeight: any): MenuItem[] {
    const tempMenuItems: MenuItem[] = [];
    const tempLabel00 = "000,00 kg/000,00 Lb";

    let tempLabel25 = "";
    let tempLabel50 = "";
    let tempLabel75 = "";
    let tempLabel100 = "";
    let tempWeight25 = 0;
    let tempWeight50 = 0;
    let tempWeight75 = 0;
    let tempWeight100 = 0;

    if (xMaxWeight && xMaxWeight != null && xMaxWeight > 0) {
      tempWeight25 = xMaxWeight * 0.25;
      tempWeight50 = xMaxWeight * 0.50;
      tempWeight75 = xMaxWeight * 0.75;
      tempWeight100 = xMaxWeight;

      tempLabel25 = `${this.classAviationTools.convertWeightUnit(tempWeight25, xCurrentUnit, "kg")} kg/${this.classAviationTools.convertWeightUnit(tempWeight25, xCurrentUnit, "lb")} lb`;
      tempLabel50 = `${this.classAviationTools.convertWeightUnit(tempWeight50, xCurrentUnit, "kg")} kg/${this.classAviationTools.convertWeightUnit(tempWeight50, xCurrentUnit, "lb")} lb`;
      tempLabel75 = `${this.classAviationTools.convertWeightUnit(tempWeight75, xCurrentUnit, "kg")} kg/${this.classAviationTools.convertWeightUnit(tempWeight75, xCurrentUnit, "lb")} lb`;
      tempLabel100 = `${this.classAviationTools.convertWeightUnit(tempWeight100, xCurrentUnit, "kg")} kg/${this.classAviationTools.convertWeightUnit(tempWeight100, xCurrentUnit, "lb")} lb`;
    } else {

      tempWeight25 = xCurrentUnit == "kg" ? 25 : 55.11;
      tempWeight50 = xCurrentUnit == "kg" ? 50 : 110.23;
      tempWeight75 = xCurrentUnit == "kg" ? 75 : 165.34;
      tempWeight100 = xCurrentUnit == "kg" ? 100 : 220.46;

      tempLabel25 = "25,00 kg/55,11 lb";
      tempLabel50 = "50,00 kg/110,23 lb";
      tempLabel75 = "75,00 kg/165,34 lb";
      tempLabel100 = "100,00 kg/220,46 lb";
    }

    tempMenuItems.push({
      label: '000,00 kg/000,00 lb',
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(0);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel25,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight25);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel50,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight50);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel75,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight75);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel100,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight100);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    return tempMenuItems;
  }

  public createMenuItemFuel(xItem: any, xMaxWeight: any): MenuItem[] {
    const tempMenuItems: MenuItem[] = [];

    const tempLabel00 = "00% Tank";
    const tempLabel25 = "25% Tank";
    const tempLabel50 = "50% Tank";
    const tempLabel75 = "75% Tank";
    const tempLabel100 = "Full Tank";
    const tempWeight25 = xMaxWeight * 0.25;
    const tempWeight50 = xMaxWeight * 0.50;
    const tempWeight75 = xMaxWeight * 0.75;
    const tempWeight100 = xMaxWeight;

    tempMenuItems.push({
      label: tempLabel00,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(0);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel25,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight25);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel50,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight50);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel75,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight75);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    tempMenuItems.push({
      label: tempLabel100,
      icon: 'fa-solid fa-weight',
      command: () => {
        xItem.controls.weight.setValue(tempWeight100);
        // TODO: REVER ESTE TRECHO DE CÓDIGO
        /////////this._processCgCalculation();
      }
    });

    return tempMenuItems;
  }

  public fuelPercent(xAmount: number, xTotal: number) {
    return parseFloat(((xAmount / xTotal) * 100).toFixed(2));
  }
  //endregion
}
