//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description Opções estáticas para unidades de comprimento.
 */
export const staticOptionsCgArmUnits: IDropDownOptionsBasic[] = [
    { label: 'mm - MILÍMETROS', value: 'mm' },
    { label: 'cm - CENTÍMETROS', value: 'cm' },
    { label: 'm  - METROS', value: 'm' },        
    { label: 'in - POLEGADAS', value: 'in' }
]