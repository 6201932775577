//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IAddress } from '../../../../_library/interfaces/IAddress';
import { IContact } from 'src/app/_library/interfaces/IContact';
import { IFkAircraftList } from '../../../../_library/interfaces/IFkAircraftList';
import { IFkCivList } from '../../../../_library/interfaces/IFkCivList';
import { IDocumentPersonal } from '../../../../_library/interfaces/IDocumentPersonal';
import { IDocumentProfissional } from '../../../../_library/interfaces/IDocumentProfissional';
//#endregion

/**
 * @status:
 * @author GASPAR
 * @date 2025-01-05
 * @version 1.0.0
 * 
 * @description:
 *   - Inicializa o Formulário CrewDataForm, retorna um objeto FormGroup com os campos do formulário.
 * 
 * @returns 
 */
export function initCrewDataForm(xFormDataStatus: string): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        crew_data_id: [null],        
        avatar_image: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_color: [LocalMethodsHandlerClass.generateHexColor()],
        crew_role: [null],
        documents_profissional: objFormBuilder.array([] as IDocumentProfissional[]),     
        name_data: objFormBuilder.group({
            first_name: [null],          
            last_name: [null],
            full_name: [null]
        }),
        nick_name: [null],
        birthday_date_utc: [null],     
        gender: ['not_informed'],   
        physical_data: objFormBuilder.group({
            height: [null],
            height_unit: ["m"],
            weight: [null],
            weight_unit: ["kg"],
            blood_type: [null]           
        }),
        nationality: [null],
        documents_personal: objFormBuilder.array([] as IDocumentPersonal[]),
        licenses: objFormBuilder.array([]),        
        ratings: objFormBuilder.array([]),
        medical_certificates: objFormBuilder.array([]),
        addresses: objFormBuilder.array([] as IAddress[]),
        contacts: objFormBuilder.array([] as IContact[]),        
        fk_aircraft_list: objFormBuilder.array([] as IFkAircraftList[]),
        fk_civ_list: objFormBuilder.array([] as IFkCivList[]),
        form_data_status: [xFormDataStatus],
        crew_remark: [null],
        tags: [[]]
    });
}