//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// ***** NPM ***** //

// ***** CONFIG ***** //

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //

// ***** MOCK ***** //

// ***** DOMÍNIOS ***** //

// ***** COMPONENT ***** //
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { DIALOG_WAITING_TYPE } from 'src/app/_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-home-page',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent
  ],
  templateUrl: './home-page.component.html'
})
export class HomePageComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** OBJECTS ***** //
  objPageTitle!: IPageTitle;

  // ***** OTHERS ***** //
  waitingDialogType!: any;
  bolShowWaitingDialog!: boolean;
  strTypeWaitingDialog!: string;
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables() {
    this.waitingDialogType = DIALOG_WAITING_TYPE;
    this.bolShowWaitingDialog = false;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.DEFAULT;

    this.objPageTitle = {
      title: 'Página Inicial',
      subtitle: 'Bem vindo ao Sunrise!',
      icon: 'fa-solid fa-home'
    }
  }
}
