<section data-page="cls-pge-app-list">
    <sunrise-toast-message></sunrise-toast-message>
    <!-- <p-confirmDialog class="cls-component-confirm-dialog-default" /> -->
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
    <div class="cls-frame-dashboard-horizontal-main-content">
        <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
        <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="false">
            <ng-template pTemplate="header">
                <div class="cls-cmp-ptv-panel-default-header">
                    <div class="cls-cmp-ptv-panel-default-header-title">
                        <h2>
                            <i class="fa-solid fa-list"></i>
                            <span>Voos Cadastradas no Sistema</span>
                        </h2>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-table 
                        [value]="this.listFlightData" 
                        [tableStyle]="{ 'min-width': '50rem' }"
                        [paginator]="true"
                        [rows]="10"
                        [rowsPerPageOptions]="[10, 15, 20]"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Página {first} de {last}."
                        styleClass="p-datatable-gridlines p-datatable-striped"
                        class="cls-cmp-ptv-table-page-list">
                    <ng-template pTemplate="caption">
                        <div class="cls-cmp-ptv-table-page-list-caption">
                            <div class="cls-cmp-ptv-table-page-list-caption-left">
                                Total: {{this.listFlightData ? this.listFlightData.length : 0 }}
                            </div>
                            <div class="cls-cmp-ptv-table-page-list-caption-right">
                                <div>
                                    <p-button styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" label="NOVO VOO"
                                        icon="fa-solid fa-plus" severity="secondary"
                                        (click)="this.onClickTableList(this.actionType.NEW)" />
                                </div>
                                <div>
                                    <p-button styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar" label="ATUALIZAR TABELA"
                                        icon="fa-solid fa-refresh" severity="secondary"
                                        (click)="this.onClickTableList(this.actionType.RELOAD)" />
                                </div>
                            </div>
                        </div>                            
                    </ng-template>
                    <ng-template pTemplate="header"> 
                            <tr>
                                <th style="width:5%; text-align: center;">ORIGEM</th>
                                <th style="width:5%; text-align: center;">DESTINO</th>
                                <th style="width:5%; text-align: center;">MATRÍCULA</th>
                                <th style="width:5%; text-align: center;">EOBT DATA (UTC)</th>
                                <th style="width:5%; text-align: center;">EOBT HORA (UTC)</th>
                                <!--<th style="width:10%;">CÓDIGO ICAO</th>
                                <th style="width:10%;">OPERADORES</th>
                                <th style="width:10%;">PESO & BALAN.</th>-->
                                <th style="width:14%;"></th>
                            </tr>                           
                    </ng-template>
                    <ng-template pTemplate="body" let-itemArray>         
                        <tr>       
                            <td style="text-align: center;">
                                <div class="cls-cmp-ptv-table-page-list-td-aerodrome">                                    
                                    <div class="cls-cmp-ptv-table-page-list-td-aerodrome-icao">
                                        <sunrise-country-flag 
                                            countryCode="{{itemArray.flt_req_data.fk_ad_bsc_lst[0].locus.country}}">
                                        </sunrise-country-flag>
                                        <div class="cls-cmp-ptv-table-page-list-td-aerodrome-icao-label">
                                            {{ itemArray.flt_req_data.fk_ad_bsc_lst[0].icao }}
                                        </div>                                        
                                    </div>
                                    <div class="cls-cmp-ptv-table-page-list-td-aerodrome-name">
                                        <ng-container *ngIf="itemArray.flt_req_data.fk_ad_bsc_lst[0].name !== null">
                                            {{ itemArray.flt_req_data.fk_ad_bsc_lst[0].name }}
                                        </ng-container>
                                        <ng-container *ngIf="itemArray.flt_req_data.fk_ad_bsc_lst[0].name === null || itemArray.flt_req_data.fk_ad_bsc_lst[0].name === ''">
                                            ({{ itemArray.flt_req_data.fk_ad_bsc_lst[0].coord_dc[0] }}, {{ itemArray.flt_req_data.fk_ad_bsc_lst[0].coord_dc[1] }})
                                        </ng-container>
                                    </div>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="cls-cmp-ptv-table-page-list-td-aerodrome">                                    
                                    <div class="cls-cmp-ptv-table-page-list-td-aerodrome-icao">
                                        <sunrise-country-flag 
                                            countryCode="{{itemArray.flt_req_data.fk_ad_bsc_lst[1].locus.country}}">
                                        </sunrise-country-flag>
                                        <div class="cls-cmp-ptv-table-page-list-td-aerodrome-icao-label">
                                            {{ itemArray.flt_req_data.fk_ad_bsc_lst[1].icao }}
                                        </div>                                        
                                    </div>
                                    <div class="cls-cmp-ptv-table-page-list-td-aerodrome-name">                                        
                                        <ng-container *ngIf="itemArray.flt_req_data.fk_ad_bsc_lst[1].name !== null">
                                            {{ itemArray.flt_req_data.fk_ad_bsc_lst[1].name }}
                                        </ng-container>
                                        <ng-container *ngIf="itemArray.flt_req_data.fk_ad_bsc_lst[1].name === null || itemArray.flt_req_data.fk_ad_bsc_lst[1].name === ''">
                                            ({{ itemArray.flt_req_data.fk_ad_bsc_lst[1].coord_dc[1] }}, {{ itemArray.flt_req_data.fk_ad_bsc_lst[1].coord_dc[1] }})
                                        </ng-container>
                                    </div>
                                </div>
                            </td>                         
                            <td style="text-align: center;">
                                <i class="{{this.classModuleMethods.getAircraftTypeLabel(itemArray.flt_req_data.acft_data.acft_type, true)}}"></i>
                                {{ this.classAviationTools.setAircraftMark(itemArray.flt_req_data.acft_data.mark) }}
                            </td>
                            <td style="text-align: center;">
                                {{ itemArray.flt_req_data.eobt_date_time.date_utc }}
                            </td>
                            <td style="text-align: center;">
                                {{ itemArray.flt_req_data.eobt_date_time.time_utc }}
                            </td>
                            <!--<td>{{ itemArray.register_data.icao_code }}</td>
                            <td>{{ itemArray.register_data.icao_code }}</td>-->
                            <td>
                                <div class="cls-cmp-ptv-table-page-list-td-button">
                                    <p-button icon="fa-solid fa-edit" [rounded]="true" [outlined]="true" severity="info"
                                        (onClick)="this.onClickItemTableList(this.actionType.EDIT, itemArray.flt_data_id)" />                                    
                                </div>
                            </td>
                        </tr>         
                    </ng-template>
                </p-table>
                <div class="cls-cmp-ptv-panel-bottom"></div>  
            </ng-template>
        </p-panel>               
    </div>
</section>