//#region "|--- IMPORT MODULES/PACKAGES ---|"

//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAircraftTypes } from '../../_library/static_options/staticOptionsAircraftTypes';
//#endregion


export class AviationToolsHandlerClass {
    /**
     * @status:
     * @author GASPAR
     * @date 2024-09-20
     * @version 1.0.0
     * 
     * @description Retorna a matricula da Aeronave formatada conforme o país de registro
     * 
     * @param xMark 
     * @returns 
     */
    static setAircraftMark(xMark: string): string {
        if (xMark !== undefined && xMark !== null) {
            xMark = xMark.replace(/[^a-zA-Z0-9]/g, "");
        }

        // peque as 2 primeiras letras de xMark e veja se ele é igual a PR ou PP)
        if (xMark && (xMark.substring(0, 2) === 'PR' || xMark.substring(0, 2) === 'PP' || xMark.substring(0, 2) === 'PT' || xMark.substring(0, 2) === 'PS')) {
            return `${xMark.substring(0, 2)}-${xMark.substring(2, 5)}`; // BRASIL
        } else {
            return xMark;
        }
    }

    /**
     * @author GASPAR
     * @date 2024-12-01
     * @version 1.0.0
     * 
     * @description Retorna a matricula da Aeronave formatada conforme o país de registro
     * 
     * @param xMark 
     * @returns 
     */
    static setAircraftMarkWithIconType(xMark: string, xAircraftType: string): string {
        let tempMark = xMark;

        // peque as 2 primeiras letras de xMark e veja se ele é igual a PR ou PP)
        if (tempMark && (xMark.substring(0, 2) === 'PR' || xMark.substring(0, 2) === 'PP' || xMark.substring(0, 2) === 'PT' || xMark.substring(0, 2) === 'P')) {
            tempMark = `${xMark.substring(0, 2)}-${xMark.substring(2, 5)}`; // BRASIL
        } else {
            tempMark = xMark;
        }

        if (xAircraftType === null || xAircraftType === undefined) {
            return tempMark;
        } else {
            const aircraftType = staticOptionsAircraftTypes.find(x => x.value === xAircraftType.toLowerCase());

            return aircraftType ? `<i class="${aircraftType.icon}"></i> ${tempMark}` : tempMark;
        }
    }

    // https://aeroinfo.com.br/combustivel
    // !!! SENDO USADA
    /**
     * @status:
     * @author GASPAR
     * @date 2024-10-16
     * @version 1.0.0
     * 
     * @description
     *   - Calcula o consumo de combustível de uma aeronave.
     * 
     * @param xTime 
     *   - Tempo desejado no formato hh:mm   
     * @param xFuelRate   
     * @returns Consumo de combustível na unidade desejada
     */
    static calculateSimpleFuelConsumption(xTime: string, xFuelRate: number): string {
        if (xTime === null || `${xTime}`.indexOf(":") < 0 || xFuelRate === 0) {
            return '0,00';
        } else {
            // Converter o tempo para horas
            const [hours, minutes] = xTime.split(':').map(Number);
            const totalTimeInHours = hours + minutes / 60;

            // Calcular o consumo total de combustível
            const totalFuelConsumption = xFuelRate * totalTimeInHours;

            // Se não for um úmero retorna 0
            if (isNaN(totalFuelConsumption)) {
                return '0';
            } else {
                return totalFuelConsumption.toFixed(2);
            }
        }
    }

    // !!! SENDO USADA
    /**
     * @status:
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description
     *   - Converte o peso de uma unidade para outra.
     *   - Kg, lb, g
     * 
     * @param weight 
     * @param currentUnit 
     * @param desiredUnit 
     * @returns 
     */
    static convertWeight(xWeight: number, xCurrentUnit: string, xDesiredUnit: string): number {
        let weightInKg: number;

        // Convert current weight to kilograms
        switch (xCurrentUnit) {
            case 'kg':
                weightInKg = xWeight;
                break;
            case 'lb':
                weightInKg = xWeight * 0.453592;
                break;
            case 'g':
                weightInKg = xWeight / 1000;
                break;
            default:
                throw new Error('Unidade de peso atual não suportada');
        }

        // Convert weight from kilograms to desired unit
        let convertedWeight: number;
        switch (xDesiredUnit) {
            case 'kg':
                convertedWeight = weightInKg;
                break;
            case 'lb':
                convertedWeight = weightInKg / 0.453592;
                break;
            case 'g':
                convertedWeight = weightInKg * 1000;
                break;
            default:
                throw new Error('Unidade de peso desejada não suportada');
        }

        return convertedWeight;
    }

    // !!! SENDO USADA
    /**
     * @status:
     * @date 2025-01-19
     * @version 1.0.0
     * 
     * @description
     *   - Converte o braço de uma unidade para outra.
     * 
     * @param weight 
     * @param currentUnit 
     * @param desiredUnit 
     * @returns 
     */
    static convertArm(xArm: number, xCurrentUnit: 'm' | 'cm' | 'mm' | 'in', xDesiredUnit: 'm' | 'cm' | 'mm' | 'in'): number {
        let armInInches: number;

        // Convert current arm to inches
        switch (xCurrentUnit) {
            case 'in':
                armInInches = xArm;
                break;
            case 'cm':
                armInInches = xArm / 2.54;
                break;
            case 'mm':
                armInInches = xArm / 25.4;
                break;
            case 'm':
                armInInches = xArm * 39.3701;
                break;
            default:
                throw new Error('Unidade de braço atual não suportada');
        }

        // Convert arm from inches to desired unit
        let convertedArm: number;
        switch (xDesiredUnit) {
            case 'in':
                convertedArm = armInInches;
                break;
            case 'cm':
                convertedArm = armInInches * 2.54;
                break;
            case 'mm':
                convertedArm = armInInches * 25.4;
                break;
            case 'm':
                convertedArm = armInInches / 39.3701;
                break;
            default:
                throw new Error('Unidade de braço desejada não suportada');
        }

        return convertedArm;
    }

    // !!! SENDO USADA
    /**
     * @status:
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description
     *   - Retorna a Densidade Padrão para ser usada, inicialmente.
     *   - SEMPRE VAI RETORNAR A DENSIDADE A 15°C;
     *   - SEMPRE VAI RETORNAR KG/L;
     * 
     * @param fuelDensity 
     * @param currentUnit 
     * @param desiredUnit 
     * @returns 
     */
    static getFuelDensityDefault(
        xFuelType: 'jeta' | 'jeta1' | 'avgas100' | 'avgas100LL' | 'avgas80',
        xUnit?: 'kg' | 'lb'
    ): number {
        let densityAt15C: number;

        // Use 'kg/L' as default unit if unit is undefined or null
        if (!xUnit) {
            xUnit = 'kg';
        }

        switch (xFuelType) {
            case 'jeta':
                // !! TIVE QUE ALTERAR: de 0.804 para 0.790, conforme o manual da aeronave AS350B2, página 348.
                densityAt15C = 0.790; // kg/L
                break;
            case 'jeta1':
                // !! TIVE QUE ALTERAR: de 0.804 para 0.790, conforme o manual da aeronave AS350B2, página 348.
                densityAt15C = 0.790; // kg/L
                break;
            case 'avgas100':
                densityAt15C = 0.720; // kg/L
                break;
            case 'avgas100LL':
                densityAt15C = 0.725; // kg/L
                break;
            case 'avgas80':
                densityAt15C = 0.710; // kg/L
                break;
            default:
                throw new Error('Tipo de combustível desconhecido');
        }

        if (xUnit === 'lb') {
            densityAt15C = densityAt15C * 8.345404; // Convert kg/L to lb/gal
        }

        return parseFloat(densityAt15C.toFixed(4));
    }

    // !!! SENDO USADA
    /**
     * @status:
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description
     *   - Retorna a Densidade convertida.
     * 
     * @param fuelDensity 
     * @param currentUnit 
     * @param desiredUnit 
     * @returns 
     */
    static convertFuelDensity(
        xFuelDensity: number,
        xCurrentUnit: 'kg/l' | 'lb/gal',
        xDesiredUnit: 'kg/l' | 'lb/gal'
    ): number {
        if (xCurrentUnit === xDesiredUnit) {
            return parseFloat(xFuelDensity.toFixed(4));
        }

        if (xCurrentUnit === 'kg/l' && xDesiredUnit === 'lb/gal') {
            return parseFloat((xFuelDensity * 8.345404).toFixed(4));
        }

        if (xCurrentUnit === 'lb/gal' && xDesiredUnit === 'kg/l') {
            return parseFloat((xFuelDensity / 8.345404).toFixed(4));
        }

        throw new Error('Unidades de densidade de combustível não suportadas');
    }

    // !!! SENDO USADA
    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description 
     *   - Método que converte o combustível de uma unidade para outra.
     * 
     * @param xFuel 
     * @param xCurrentUnit 
     * @param xDesiredUnit 
     * @returns 
     */
    static convertFuelUnit(xFuel: number, xCurrentUnit: 'l' | 'gal' | 'kg' | 'lb', xDesiredUnit: 'l' | 'gal' | 'kg' | 'lb'): number {
        type FuelUnit = 'l' | 'gal' | 'kg' | 'lb';

        const conversionRates: { [key in FuelUnit]: { [key in FuelUnit]: number } } = {
            l: {
                l: 1,
                gal: 0.264172,
                kg: 0.8, // assuming density of aviation fuel is 0.8 kg/L
                lb: 1.7637 // 1 liter of aviation fuel is approximately 1.7637 pounds
            },
            gal: {
                l: 3.78541,
                gal: 1,
                kg: 3.2, // 1 gallon of aviation fuel is approximately 3.2 kg
                lb: 7.05 // 1 gallon of aviation fuel is approximately 7.05 pounds
            },
            kg: {
                l: 1.25, // 1 kg of aviation fuel is approximately 1.25 liters
                gal: 0.3125, // 1 kg of aviation fuel is approximately 0.3125 gallons
                kg: 1,
                lb: 2.20462
            },
            lb: {
                l: 0.56699, // 1 pound of aviation fuel is approximately 0.56699 liters
                gal: 0.141255, // 1 pound of aviation fuel is approximately 0.141255 gallons
                kg: 0.453592,
                lb: 1
            }
        };

        if (xCurrentUnit === xDesiredUnit) {
            return parseFloat(xFuel.toFixed(5));
        }

        const conversionRate = conversionRates[xCurrentUnit][xDesiredUnit];
        const convertedFuel = xFuel * conversionRate;

        return parseFloat(convertedFuel.toFixed(5));
    }

    // !!! SENDO USADA
    /**
     * @status:
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description
     *   - Método que converte o volume de combustível para massa.
     * 
     * @param fuelType 
     * @param quantity 
     * @param currentUnit 
     * @param desiredUnit 
     * @returns 
     */
    static convertFuelVolumeToMass(
        fuelType: 'jeta' | 'jeta1' | 'avgas100' | 'avgas100LL' | 'avgas80',
        quantity: number,
        currentUnit: 'l' | 'gal',
        desiredUnit: 'kg' | 'lb'
    ): number {
        // https://www.aeroinfo.com.br/combustivel
        let densityAt15C: number;

        switch (fuelType) {
            case 'jeta':
            case 'jeta1':
                // !! TIVE QUE ALTERAR: de 0.804 para 0.790, conforme o manual da aeronave AS350B2, página 348.
                densityAt15C = 0.790; // kg/L
                break;
            case 'avgas100':
                densityAt15C = 0.720; // kg/L
                break;
            case 'avgas100LL':
                densityAt15C = 0.725; // kg/L
                break;
            case 'avgas80':
                densityAt15C = 0.710; // kg/L
                break;
            default:
                throw new Error('Tipo de combustível desconhecido');
        }

        const conversionFactors: { [key: string]: number } = {
            'l_to_gal': 0.264172,
            'gal_to_l': 3.78541,
            'kg_to_lb': 2.20462,
            'lb_to_kg': 0.453592
        };

        let quantityInLiters: number = quantity;

        // Convert volume to liters if necessary
        if (currentUnit === 'gal') {
            quantityInLiters = quantity * conversionFactors['gal_to_l'];
        }

        // Calculate mass in kg
        let massInKg = quantityInLiters * densityAt15C;

        // Convert mass to desired unit if necessary
        if (desiredUnit === 'lb') {
            massInKg = massInKg * conversionFactors['kg_to_lb'];
        }

        return parseFloat(massInKg.toFixed(5));
    }









    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description 
     *   - Método que converte a taxa de consumo de combustível.
     * 
     * @param xFuel 
     * @param xCurrentUnit 
     * @param xDesiredUnit 
     * @returns 
     */
    static convertFuelRate(
        rate: number,
        currentUnit: 'l/h' | 'gal/h' | 'kg/h' | 'lb/h',
        desiredUnit: 'l/h' | 'gal/h' | 'kg/h' | 'lb/h'
    ): number {
        type FuelRateUnit = 'l/h' | 'gal/h' | 'kg/h' | 'lb/h';

        const conversionRates: { [key in FuelRateUnit]: { [key in FuelRateUnit]: number } } = {
            'l/h': {
                'l/h': 1,
                'gal/h': 0.264172,
                'kg/h': 0.8, // assuming density of aviation fuel is 0.8 kg/L
                'lb/h': 1.7637 // 1 liter of aviation fuel is approximately 1.7637 pounds
            },
            'gal/h': {
                'l/h': 3.78541,
                'gal/h': 1,
                'kg/h': 3.2, // 1 gallon of aviation fuel is approximately 3.2 kg
                'lb/h': 7.05 // 1 gallon of aviation fuel is approximately 7.05 pounds
            },
            'kg/h': {
                'l/h': 1.25, // 1 kg of aviation fuel is approximately 1.25 liters
                'gal/h': 0.3125, // 1 kg of aviation fuel is approximately 0.3125 gallons
                'kg/h': 1,
                'lb/h': 2.20462
            },
            'lb/h': {
                'l/h': 0.56699, // 1 pound of aviation fuel is approximately 0.56699 liters
                'gal/h': 0.141255, // 1 pound of aviation fuel is approximately 0.141255 gallons
                'kg/h': 0.453592,
                'lb/h': 1
            }
        };

        if (currentUnit === desiredUnit) {
            return parseFloat(rate.toFixed(2));
        }

        const conversionRate = conversionRates[currentUnit][desiredUnit];
        const convertedRate = rate * conversionRate;

        return parseFloat(convertedRate.toFixed(2));
    }





    // TODO: TESTAR
    static calculateFuelDensity(fuelType: 'jeta' | 'jeta1' | 'avgas100' | 'avgas100LL' | 'avgas80', temperature: number): number {
        let densityAt15C: number;
        let thermalExpansionCoefficient: number;

        switch (fuelType) {
            case 'jeta':
                densityAt15C = 0.804; // kg/L
                thermalExpansionCoefficient = 0.0008; // kg/L/°C
                break;
            case 'jeta1':
                densityAt15C = 0.804; // kg/L
                thermalExpansionCoefficient = 0.0008; // kg/L/°C
                break;
            case 'avgas100':
                densityAt15C = 0.720; // kg/L
                thermalExpansionCoefficient = 0.0010; // kg/L/°C
                break;
            case 'avgas100LL':
                densityAt15C = 0.725; // kg/L
                thermalExpansionCoefficient = 0.0010; // kg/L/°C
                break;
            case 'avgas80':
                densityAt15C = 0.710; // kg/L
                thermalExpansionCoefficient = 0.0010; // kg/L/°C
                break;
            default:
                throw new Error('Tipo de combustível desconhecido');
        }

        const density = densityAt15C - thermalExpansionCoefficient * (temperature - 15);
        return parseFloat(density.toFixed(4));
    }


    //---------------
    static calculateFuelConsumption(
        flightTime: number,
        consumptionRate: number,
        consumptionUnit: string,
        desiredUnit: string,
        fuelType: string
    ): number {
        // Converter flightTime de minutos para horas
        const flightTimeInHours = flightTime / 60;

        const conversionFactors: { [key: string]: number } = {
            'lb_to_kg': 0.453592,
            'kg_to_lb': 2.20462,
            'l_to_gal': 0.264172,
            'gal_to_l': 3.78541
        };

        const fuelDensity: { [key: string]: number } = {
            'JetA_lb_per_gal': 6.7,
            'Avgas_lb_per_gal': 6.0,
            'JetA_kg_per_l': 0.8,
            'Avgas_kg_per_l': 0.72
        };

        let rateInDesiredUnit: number = consumptionRate;

        // Convert consumption rate to desired unit
        if (consumptionUnit !== desiredUnit) {
            if (consumptionUnit === 'lb/h' && desiredUnit === 'kg/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['lb_to_kg'];
            } else if (consumptionUnit === 'kg/h' && desiredUnit === 'lb/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['kg_to_lb'];
            } else if (consumptionUnit === 'l/h' && desiredUnit === 'gal/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['l_to_gal'];
            } else if (consumptionUnit === 'gal/h' && desiredUnit === 'l/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['gal_to_l'];
            }
        }

        // Adjust for fuel type if necessary
        if (fuelType === 'jeta' && desiredUnit === 'lb/h') {
            rateInDesiredUnit *= fuelDensity['JetA_lb_per_gal'];
        } else if (fuelType === 'avgas' && desiredUnit === 'lb/h') {
            rateInDesiredUnit *= fuelDensity['Avgas_lb_per_gal'];
        } else if (fuelType === 'jeta' && desiredUnit === 'kg/h') {
            rateInDesiredUnit *= fuelDensity['JetA_kg_per_l'];
        } else if (fuelType === 'avgas' && desiredUnit === 'kg/h') {
            rateInDesiredUnit *= fuelDensity['Avgas_kg_per_l'];
        }

        // Calcular o consumo total de combustível
        const totalFuelConsumption = rateInDesiredUnit * flightTimeInHours;

        return totalFuelConsumption;
    }
}