//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initCrewDataForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        crew_data_id: [null],
        avatar_image: [null],
        avatar_color: [null], 
        folder_path: [null],
        profissional_ni: [null],       
        name: objFormBuilder.group({
            first_name: [''],
            last_name: [''],
            full_name: ['']
        }),
        birthday: [''],     
        gender: [''],   
        physical: objFormBuilder.group({
            height: [null],
            height_unit: ["kg"],
            weight: [null],
            weight_unit: ["m"],
            blood_type: [null]           
        }),
        nationality: [''],
        documents: objFormBuilder.array([]),
        licenses: objFormBuilder.array([]),        
        ratings: objFormBuilder.array([]),
        medical_certificates: objFormBuilder.array([]),
        addresses: objFormBuilder.array([]),
        contacts: objFormBuilder.array([]),
        aircraft: objFormBuilder.array([]),
        data_from: [null],
        crew_remarks: [null]
    });
}

