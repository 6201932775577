<div class="cls-ui-primeng-field cls-ui-primeng-field-input-time">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.label}}
        </label> 
        <p-inputNumber 
            [formControl]="this.control"
            [ngClass]="{ 'is-danger': this.showErrors() }"
            [mode]=this.getNumberType() 
            showButtons="true" 
            buttonLayout="horizontal"
            inputId="minmax-buttons" 
            [min]=this.getMinNumber() 
            [max]=this.getMaxNumber() />
    </div>
</div>