<section class="cls-component-tab-data">
     <p-toolbar class="cls-component-toolbar">
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-center"></div>
          <div class="p-toolbar-group-end">
               <ng-container *ngIf="formWeightBalanceSheet.controls.weight_balance_data_id.value !== null">
                    <p-button label="Novo" styleClass="cls-component-button" [rounded]="true" icon="fa-solid fa-plus"
                         severity="secondary" class="mr-2" (click)="callbackClickButton(this.buttonActions.NEW)" />
                    <p-button label="Salvar Modificações" styleClass="cls-component-button" [rounded]="true"
                         icon="fa-solid fa-save" severity="secondary" class="mr-2"
                         (click)="callbackClickButton(this.buttonActions.UPDATE)" />
               </ng-container>
               <ng-container *ngIf="formWeightBalanceSheet.controls.weight_balance_data_id.value === null">
                    <p-button label="Salvar Novo" styleClass="cls-component-button" [rounded]="true"
                         icon="fa-solid fa-save" severity="secondary" class="mr-2"
                         (click)="callbackClickButton(this.buttonActions.SAVE)" />
               </ng-container>
               <p-button label="Lista" styleClass="cls-component-button" [rounded]="true"
                    icon="fa-solid fa-clipboard-list" severity="danger"
                    (click)="callbackClickButton(this.buttonActions.LIST)" />
          </div>
     </p-toolbar>
     <div class="grid">
          <div class="col-12 md:col-12 lg:col-12 xl:col-12">
               <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span> Cabeçalho da Ficha de Peso & Balanceamento</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div class="cls-component-form cls-component-form-frame">
                              <div class="formgrid">
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-input-text label="Nome da Configuração #BOLD#"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.name"
                                                  groupLeftIcon="fa-solid fa-asterisk">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-8">
                                             <lib-ui-primeng-input-text label="Descrição da Configuração #BOLD#"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.description_config"
                                                  groupLeftIcon="fa-solid fa-asterisk">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                   </div>
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-show-input-data label="Tipo de Aeronave #BOLD#"
                                                  [dataInput]="this.classModuleMethods.getAircraftTypeLabel(this.formComponent.controls.registration_data.controls.aircraft_type.value)">
                                             </lib-ui-primeng-show-input-data>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-show-input-data label="Código ICAO #BOLD#"
                                                  [dataInput]="this.formComponent.controls.registration_data.controls.icao_code.value">
                                             </lib-ui-primeng-show-input-data>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-show-input-data label="Modelo Aeronave #BOLD#"
                                                  [dataInput]="this.formComponent.controls.registration_data.controls.model.value">
                                             </lib-ui-primeng-show-input-data>
                                        </div>
                                   </div>
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-input-date label="Data de Aferição #BOLD#"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.calibration_date_utc">
                                             </lib-ui-primeng-input-date>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-input-date label="Próxima Aferição #BOLD#"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.calibration_next_date_utc">
                                             </lib-ui-primeng-input-date>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-input-text label="Observação Aferição"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.calibration_observation">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                   </div>
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-input-text label="ID Manual da Aeronave"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.aircraft_manual_id">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-input-text label="Data Manual"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.aircraft_manual_date">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-input-text label="Versão Manual"
                                                  [control]="this.formWeightBalanceSheet.controls.header_data.controls.aircraft_manual_version">
                                             </lib-ui-primeng-input-text>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
               </p-panel>
               <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span> Unidades de Medidas Padrões</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div class="cls-component-form cls-component-form-frame">
                              <div class="formgrid">
                                   <div class="grid">
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-drop-down label="Peso #BOLD#"
                                                  [control]="this.formWeightBalanceSheet.controls.unit_data.controls.weight"
                                                  [arrayOptions]="this.optionsCgWeightUnits">
                                             </lib-ui-primeng-drop-down>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-drop-down label="Braço #BOLD#"
                                                  [control]="this.formWeightBalanceSheet.controls.unit_data.controls.arm"
                                                  [arrayOptions]="this.optionsCgArmUnits">
                                             </lib-ui-primeng-drop-down>
                                        </div>
                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                             <lib-ui-primeng-drop-down label="Combustível #BOLD#"
                                                  [control]="this.formWeightBalanceSheet.controls.unit_data.controls.fuel"
                                                  [arrayOptions]="this.optionsCgFuelUnits">
                                             </lib-ui-primeng-drop-down>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </ng-template>
               </p-panel>
               <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span>Componentes para Cálculo do Centro de Gravidade</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <div class="grid">
                              <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                   <p-panel class="cls-component-panel-form-fieldset">
                                        <ng-template pTemplate="header">
                                             <div class="cls-component-panel-form-fieldset-header">
                                                  <div class="cls-component-panel-form-fieldset-header-title">
                                                       <h3>
                                                            <i class="fa-solid fa-check"></i>
                                                            <span> Componentes da Aeronave</span>
                                                       </h3>
                                                  </div>
                                             </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                             <p-tabView [scrollable]="true" class="cls-component-tabview-page"
                                                  [activeIndex]="0">
                                                  <!--<p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-bullseye"></i>
                                                                 <div>CG</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                       </ng-template>
                                                  </p-tabPanel>-->
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-weight-hanging"></i>
                                                                 <div>Pesos</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <p-fieldset legend="BEM - Peso Básico Vazio"
                                                                 styleClass="cls-app-platform--component-chart-fieldset-config">
                                                                 <div
                                                                      class="cls-component-form cls-component-form-frame">
                                                                      <div class="formgrid">
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Peso #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.weight_data.controls.bem.controls.weight">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Braço Longitudinal #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.weight_data.controls.bem.controls.longitudinal_arm">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Braço Lateral #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.weight_data.controls.bem.controls.lateral_arm">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </p-fieldset>
                                                            <p-fieldset legend="Pesos Limites"
                                                                 styleClass="cls-app-platform--component-chart-fieldset-config">
                                                                 <div
                                                                      class="cls-component-form cls-component-form-frame">
                                                                      <div class="formgrid">
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                                     <lib-ui-primeng-show-input-data
                                                                                          label="MTOW #BOLD#"
                                                                                          [dataInput]="getCurrentWeightUnit('MTOW')">
                                                                                     </lib-ui-primeng-show-input-data>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                                     <lib-ui-primeng-show-input-data
                                                                                          label="MLW #BOLD#"
                                                                                          [dataInput]="getCurrentWeightUnit('MLW')">
                                                                                     </lib-ui-primeng-show-input-data>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                                     <lib-ui-primeng-show-input-data
                                                                                          label="MRW #BOLD#"
                                                                                          [dataInput]="getCurrentWeightUnit('MRW')">
                                                                                     </lib-ui-primeng-show-input-data>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-3">
                                                                                     <lib-ui-primeng-show-input-data
                                                                                          label="MZFW #BOLD#"
                                                                                          [dataInput]="getCurrentWeightUnit('MZFW')">
                                                                                     </lib-ui-primeng-show-input-data>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </p-fieldset>
                                                       </ng-template>
                                                  </p-tabPanel>
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-gas-pump"></i>
                                                                 <div>Combustível</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <p-table [value]="this.cgComponentsFuel"
                                                                 dataKey="component_id" editMode="row"
                                                                 [tableStyle]="{'min-width': '65rem'}"
                                                                 [reorderableColumns]="true"
                                                                 class="cls-component-table-panel-list"
                                                                 styleClass="p-datatable-gridlines p-datatable-striped">
                                                                 <ng-template pTemplate="caption">
                                                                      <div
                                                                           class="cls-component-table-page-list-caption">
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-left">
                                                                                Total:
                                                                                {{this.cgComponentsFuel ? this.cgComponentsFuel.length : 0 }}
                                                                           </div>
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-right">
                                                                                <div>
                                                                                     <p-button
                                                                                          styleClass="cls-component-button"
                                                                                          label="NOVA LINHA"
                                                                                          icon="fa-solid fa-plus"
                                                                                          severity="secondary"
                                                                                          (click)="onRowNew('cgComponentsFuel')" />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="header">
                                                                      <tr>
                                                                           <th style="width:1%"></th>
                                                                           <th style="width:15%">Descrição</th>
                                                                           <th style="width:10%">Label</th>
                                                                           <th style="width:10%">Capacidade Máxima</th>
                                                                           <th style="width:15%">B. Long</th>
                                                                           <th style="width:15%">B. Lateral</th>
                                                                           <th style="width:15%">Tipo</th>
                                                                           <th style="width:10%">Confirmar</th>
                                                                           <th style="width:10%"></th>
                                                                      </tr>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="body" let-itemArray
                                                                      let-editing="editing" let-Index="rowIndex">
                                                                      <tr [pEditableRow]="itemArray"
                                                                           [pReorderableRow]="Index">
                                                                           <td>
                                                                                <span class="fa-solid fa-arrows-up-down"
                                                                                     pReorderableRowHandle></span>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.description"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.description}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.label"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.label}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>                                                                           
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.max_weight"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.max_weight ? itemArray.max_weight : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.fuel.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.longitudinal_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.longitudinal_arm ? itemArray.longitudinal_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.lateral_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.lateral_arm ? itemArray.lateral_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">                                                                                          
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.type_component"
                                                                                               [options]="optionsCgComponentsFuel"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelCgComponents("fuel", itemArray.type_component)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">                                                                                        
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.confirm"
                                                                                               [options]="optionsYesNo"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelYesNo(itemArray.confirm)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <div
                                                                                     class="flex align-items-center justify-content-center gap-2">
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"                                                                                 
                                                                                          icon="fa-solid fa-trash"
                                                                                          (click)="this.onRowDelete(itemArray, 'cgComponentsFuel')"
                                                                                          class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          pInitEditableRow
                                                                                          icon="fa-solid fa-pencil"
                                                                                          (click)="this.onRowEditInit(itemArray)"
                                                                                          class="p-button-rounded p-button-text">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pSaveEditableRow
                                                                                          icon="fa-solid fa-check"
                                                                                          (click)="onRowEditSave(itemArray)"
                                                                                          class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pCancelEditableRow
                                                                                          icon="fa-solid fa-arrow-right-from-bracket"
                                                                                          (click)="onRowEditCancel(itemArray, Index, 'cgComponentsFuel')"
                                                                                          class="p-button-rounded p-button-text p-button-danger">
                                                                                     </button>
                                                                                </div>
                                                                           </td>
                                                                      </tr>
                                                                 </ng-template>
                                                            </p-table>
                                                       </ng-template>
                                                  </p-tabPanel>
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-square-check"></i>
                                                                 <div>Constantes</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <p-table [value]="this.cgComponentsConstants"
                                                                 dataKey="component_id" editMode="row"
                                                                 [tableStyle]="{'min-width': '65rem'}"
                                                                 [reorderableColumns]="true"
                                                                 class="cls-component-table-panel-list"
                                                                 styleClass="p-datatable-gridlines p-datatable-striped">
                                                                 <ng-template pTemplate="caption">
                                                                      <div
                                                                           class="cls-component-table-page-list-caption">
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-left">
                                                                                Total:
                                                                                {{this.cgComponentsConstants ? this.cgComponentsConstants.length : 0 }}
                                                                           </div>
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-right">
                                                                                <div>
                                                                                     <p-button
                                                                                          styleClass="cls-component-button"
                                                                                          label="NOVA LINHA"
                                                                                          icon="fa-solid fa-plus"
                                                                                          severity="secondary"
                                                                                          (click)="onRowNew('cgComponentsConstants')" />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="header">
                                                                      <tr>
                                                                           <th style="width:1%"></th>
                                                                           <th style="width:20%">Descrição</th>
                                                                           <th style="width:10%">Label</th>
                                                                           <th style="width:10%">Peso</th>
                                                                           <th style="width:10%">Peso Máximo</th>
                                                                           <th style="width:15%">B. Long</th>
                                                                           <th style="width:15%">B. Lateral</th>
                                                                           <th style="width:15%">Tipo</th>
                                                                           <th style="width:10%">Confirmar</th>
                                                                           <th style="width:10%"></th>
                                                                      </tr>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="body" let-itemArray
                                                                      let-editing="editing" let-Index="rowIndex">
                                                                      <tr [pEditableRow]="itemArray"
                                                                           [pReorderableRow]="Index">
                                                                           <td>
                                                                                <span class="fa-solid fa-arrows-up-down"
                                                                                     pReorderableRowHandle></span>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">                                                                            
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.description"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.description}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.label"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.label}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.weight"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.weight ? itemArray.weight : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.weight.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.max_weight"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.weight ? itemArray.max_weight : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.weight.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.longitudinal_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.longitudinal_arm ? itemArray.longitudinal_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.lateral_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.lateral_arm ? itemArray.lateral_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.type_component"
                                                                                               [options]="optionsCgComponentsConstant"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>                                                                                         
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelCgComponents("constant", itemArray.type_component)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.confirm"
                                                                                               [options]="optionsYesNo"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelYesNo(itemArray.confirm)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <div
                                                                                     class="flex align-items-center justify-content-center gap-2">
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          icon="fa-solid fa-trash"
                                                                                          (click)="this.onRowDelete(itemArray, 'cgComponentsConstants')"
                                                                                          class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          pInitEditableRow
                                                                                          icon="fa-solid fa-pencil"
                                                                                          (click)="this.onRowEditInit(itemArray)"
                                                                                          class="p-button-rounded p-button-text">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pSaveEditableRow
                                                                                          icon="fa-solid fa-check"
                                                                                          (click)="onRowEditSave(itemArray)"
                                                                                          class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pCancelEditableRow
                                                                                          icon="fa-solid fa-arrow-right-from-bracket"
                                                                                          (click)="onRowEditCancel(itemArray, Index, 'cgComponentsConstants')"
                                                                                          class="p-button-rounded p-button-text p-button-danger">
                                                                                     </button>
                                                                                </div>
                                                                           </td>
                                                                      </tr>
                                                                 </ng-template>
                                                            </p-table>
                                                       </ng-template>
                                                  </p-tabPanel>
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-weight-scale"></i>
                                                                 <div>Entradas</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <p-table [value]="this.cgComponentsInputs"
                                                                 dataKey="component_id" editMode="row"
                                                                 [tableStyle]="{'min-width': '65rem'}"
                                                                 [reorderableColumns]="true"
                                                                 class="cls-component-table-panel-list"
                                                                 styleClass="p-datatable-gridlines p-datatable-striped">
                                                                 <ng-template pTemplate="caption">
                                                                      <div
                                                                           class="cls-component-table-page-list-caption">
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-left">
                                                                                Total:
                                                                                {{this.cgComponentsInputs ? this.cgComponentsInputs.length : 0 }}
                                                                           </div>
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-right">
                                                                                <div>
                                                                                     <p-button
                                                                                          styleClass="cls-component-button"
                                                                                          label="NOVA LINHA"
                                                                                          icon="fa-solid fa-plus"
                                                                                          severity="secondary"
                                                                                          (click)="onRowNew('cgComponentsInput')" />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="header">
                                                                      <tr>
                                                                           <th style="width:1%"></th>
                                                                           <th style="width:20%">Descrição</th>
                                                                           <th style="width:10%">Label</th>
                                                                           <th style="width:10%">Peso Máximo</th>
                                                                           <th style="width:15%">B. Long</th>
                                                                           <th style="width:15%">B. Lateral</th>
                                                                           <th style="width:15%">Tipo</th>
                                                                           <th style="width:10%">Confirmar</th>
                                                                           <th style="width:10%"></th>
                                                                      </tr>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="body" let-itemArray
                                                                      let-editing="editing" let-Index="rowIndex">
                                                                      <tr [pEditableRow]="itemArray"
                                                                           [pReorderableRow]="Index">
                                                                           <td>
                                                                                <span class="fa-solid fa-arrows-up-down"
                                                                                     pReorderableRowHandle></span>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.description"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.description}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.label"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.label}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.max_weight"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.max_weight ? itemArray.max_weight : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.weight.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.longitudinal_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.longitudinal_arm ? itemArray.longitudinal_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.lateral_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.lateral_arm ? itemArray.lateral_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.type_component"
                                                                                               [options]="optionsCgComponentsInput"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelCgComponents("input", itemArray.type_component)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.confirm"
                                                                                               [options]="optionsYesNo"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelYesNo(itemArray.confirm)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <div
                                                                                     class="flex align-items-center justify-content-center gap-2">
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          icon="fa-solid fa-trash"
                                                                                          (click)="this.onRowDelete(itemArray, 'cgComponentsInput')"
                                                                                          class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          pInitEditableRow
                                                                                          icon="fa-solid fa-pencil"
                                                                                          (click)="this.onRowEditInit(itemArray)"
                                                                                          class="p-button-rounded p-button-text">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pSaveEditableRow
                                                                                          icon="fa-solid fa-check"
                                                                                          (click)="onRowEditSave(itemArray)"
                                                                                          class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pCancelEditableRow
                                                                                          icon="fa-solid fa-arrow-right-from-bracket"
                                                                                          (click)="onRowEditCancel(itemArray, Index, 'cgComponentsInput')"
                                                                                          class="p-button-rounded p-button-text p-button-danger">
                                                                                     </button>
                                                                                </div>
                                                                           </td>
                                                                      </tr>
                                                                 </ng-template>
                                                            </p-table>
                                                       </ng-template>
                                                  </p-tabPanel>
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-basket-shopping"></i>
                                                                 <div>Externo</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <p-table [value]="this.cgComponentsExternal"
                                                                 dataKey="component_id" editMode="row"
                                                                 [tableStyle]="{'min-width': '65rem'}"
                                                                 [reorderableColumns]="true"
                                                                 class="cls-component-table-panel-list"
                                                                 styleClass="p-datatable-gridlines p-datatable-striped">
                                                                 <ng-template pTemplate="caption">
                                                                      <div
                                                                           class="cls-component-table-page-list-caption">
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-left">
                                                                                Total:
                                                                                {{this.cgComponentsExternal ? this.cgComponentsExternal.length : 0 }}
                                                                           </div>
                                                                           <div
                                                                                class="cls-component-table-page-list-caption-right">
                                                                                <div>
                                                                                     <p-button
                                                                                          styleClass="cls-component-button"
                                                                                          label="NOVA LINHA"
                                                                                          icon="fa-solid fa-plus"
                                                                                          severity="secondary"
                                                                                          (click)="onRowNew('cgComponentsExternal')" />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="header">
                                                                      <tr>
                                                                           <th style="width:1%"></th>
                                                                           <th style="width:20%">Descrição</th>
                                                                           <th style="width:10%">Label</th>
                                                                           <th style="width:10%">Peso</th>
                                                                           <th style="width:10%">Peso Máximo</th>
                                                                           <th style="width:15%">B. Long</th>
                                                                           <th style="width:15%">B. Lateral</th>
                                                                           <th style="width:15%">Tipo</th>
                                                                           <th style="width:10%">Confirmar</th>
                                                                           <th style="width:10%"></th>
                                                                      </tr>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="body" let-itemArray
                                                                      let-editing="editing" let-Index="rowIndex">
                                                                      <tr [pEditableRow]="itemArray"
                                                                           [pReorderableRow]="Index">
                                                                           <td>
                                                                                <span class="fa-solid fa-arrows-up-down"
                                                                                     pReorderableRowHandle></span>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.description"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.description}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                          [(ngModel)]="itemArray.label"
                                                                                          class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.label}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.weight"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.weight ? itemArray.weight : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.weight.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.max_weight"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.max_weight ? itemArray.max_weight : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.weight.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.longitudinal_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.longitudinal_arm ? itemArray.longitudinal_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-inputNumber
                                                                                               [(ngModel)]="itemArray.lateral_arm"
                                                                                               mode="decimal"
                                                                                               locale="pt-BR"
                                                                                               mode="decimal" 
                                                                                               [minFractionDigits]="0"
                                                                                               [maxFractionDigits]="2"
                                                                                               class="cls-component-table-page-list-input-number" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{itemArray.lateral_arm ? itemArray.lateral_arm : 0}}
                                                                                          {{this.formWeightBalanceSheet.controls.unit_data.controls.arm.value}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.type_component"
                                                                                               [options]="optionsCgComponentsExternal"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelCgComponents("external", itemArray.type_component)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.confirm"
                                                                                               [options]="optionsYesNo"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          {{this.showLabelYesNo(itemArray.confirm)}}
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <div
                                                                                     class="flex align-items-center justify-content-center gap-2">
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          icon="fa-solid fa-trash"
                                                                                          (click)="this.onRowDelete(itemArray, 'cgComponentsExternal')"
                                                                                          class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          pInitEditableRow
                                                                                          icon="fa-solid fa-pencil"
                                                                                          (click)="this.onRowEditInit(itemArray)"
                                                                                          class="p-button-rounded p-button-text">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pSaveEditableRow
                                                                                          icon="fa-solid fa-check"
                                                                                          (click)="onRowEditSave(itemArray)"
                                                                                          class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pCancelEditableRow
                                                                                          icon="fa-solid fa-arrow-right-from-bracket"
                                                                                          (click)="onRowEditCancel(itemArray, Index, 'cgComponentsExternal')"
                                                                                          class="p-button-rounded p-button-text p-button-danger">
                                                                                     </button>
                                                                                </div>
                                                                           </td>
                                                                      </tr>
                                                                 </ng-template>
                                                            </p-table>
                                                       </ng-template>
                                                  </p-tabPanel>
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-regular fa-images"></i>
                                                                 <div>Perspectiva</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">

                                                       </ng-template>
                                                  </p-tabPanel>
                                             </p-tabView>
                                        </ng-template>
                                   </p-panel>
                              </div>
                         </div>
                    </ng-template>
               </p-panel>
               <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                         <div class="cls-component-panel-default-header">
                              <div class="cls-component-panel-default-header-title">
                                   <h2>
                                        <i class="fa-solid fa-bolt"></i>
                                        <span>Gráficos de Limites</span>
                                   </h2>
                              </div>
                         </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                         <!-- GRÁFICO LONGITUDINAL -->
                         <div class="grid">
                              <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                   <p-panel class="cls-component-panel-form-fieldset">
                                        <ng-template pTemplate="header">
                                             <div class="cls-component-panel-form-fieldset-header">
                                                  <div class="cls-component-panel-form-fieldset-header-title">
                                                       <h3>
                                                            <i class="fa-solid fa-check"></i>
                                                            <span> GRÁFICO LIMITE C.G.LONGITUDINAL</span>
                                                       </h3>
                                                  </div>
                                             </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                             <sunrise-app-platform--chart-cg-limits
                                                  [objChartCGLimits]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].getRawValue()">
                                             </sunrise-app-platform--chart-cg-limits>
                                        </ng-template>
                                   </p-panel>
                              </div>
                              <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                   <p-panel class="cls-component-panel-form-fieldset">
                                        <ng-template pTemplate="header">
                                             <div class="cls-component-panel-form-fieldset-header">
                                                  <div class="cls-component-panel-form-fieldset-header-title">
                                                       <h3>
                                                            <i class="fa-solid fa-check"></i>
                                                            <span> CONFIGURAÇÃO GRÁFICO LIMITE C.G. LONGITUDINAL</span>
                                                       </h3>
                                                  </div>
                                             </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                             <p-tabView [scrollable]="true" class="cls-component-tabview-page"
                                                  [activeIndex]="0">
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-chart-bar"></i>
                                                                 <div>Eixos</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <p-fieldset legend="Eixo Y - Peso Bruto"
                                                                 styleClass="cls-app-platform--component-chart-fieldset-config">
                                                                 <div
                                                                      class="cls-component-form cls-component-form-frame">
                                                                      <div class="formgrid">
                                                                           <div class="grid">  
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Mínimo Y #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.y_min">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Máximo Y #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.y_max">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>                                                                              
                                                                                                                                                                
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down
                                                                                          label="Escala Eixo Y #BOLD#"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.y_scale"
                                                                                          [arrayOptions]="this.optionsChartScales">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Eixo #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.y_step_axis">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Grid #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.y_step_grid">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down
                                                                                          label="Eixo Oposto"
                                                                                          appendDropdownTo="body"                                                                                          
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.y_show_opposite_unit"
                                                                                          [arrayOptions]="this.optionsCgWeightUnits">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </p-fieldset>
                                                            <p-fieldset legend="Eixo X - Estação Fuselagem"
                                                                 styleClass="cls-app-platform--component-chart-fieldset-config">
                                                                 <div
                                                                      class="cls-component-form cls-component-form-frame">
                                                                      <div class="formgrid">
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Mínimo X #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.x_min">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Máximo X #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.x_max">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                                                                                                
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down
                                                                                          label="Escala eixo X #BOLD#"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.x_scale"
                                                                                          [arrayOptions]="this.optionsChartScales">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Eixo #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.x_step_axis">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Grid #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.x_step_grid">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down                                                                                          
                                                                                          label="Eixo Oposto"
                                                                                          appendDropdownTo="body"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_longitudinal_data.controls[0].controls.x_show_opposite_unit"
                                                                                          [arrayOptions]="this.optionsCgArmUnits">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </p-fieldset>
                                                       </ng-template>
                                                  </p-tabPanel>
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-chart-line"></i>
                                                                 <div>Plotagem</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <!-- TABELA DA PLOTAGEM -->
                                                            <p-table 
                                                                 [value]="this.chartDrawLimitsCGLongitudinal" 
                                                                 dataKey="draw_limit_data_id"
                                                                 editMode="row" 
                                                                 [tableStyle]="{'min-width': '50rem'}"
                                                                 [reorderableColumns]="true"
                                                                 class="cls-component-table-panel-list"
                                                                 styleClass="p-datatable-gridlines p-datatable-striped">
                                                                 <ng-template pTemplate="caption">
                                                                      <div class="cls-component-table-page-list-caption">
                                                                           <div class="cls-component-table-page-list-caption-left">
                                                                                Total: {{this.chartDrawLimitsCGLongitudinal ? this.chartDrawLimitsCGLongitudinal.length : 0 }}
                                                                           </div>
                                                                           <div class="cls-component-table-page-list-caption-right">
                                                                                <div>
                                                                                     <p-button
                                                                                          styleClass="cls-component-button"
                                                                                          label="NOVA LINHA"
                                                                                          icon="fa-solid fa-plus"
                                                                                          severity="secondary"
                                                                                          (click)="onRowNewLimits('cgDataDrawLimitsCGLongitudinal')" />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="header">
                                                                      <tr>
                                                                           <th style="width:60%">Coordenadasss</th>                                                                           
                                                                           <th style="width:15%">Cor</th>
                                                                           <th style="width:15%">Legenda</th>                                                                         
                                                                           <th style="width:10%"></th>
                                                                      </tr>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="body" let-itemArray
                                                                      let-editing="editing" let-Index="rowIndex">
                                                                      <tr [pEditableRow]="itemArray">
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <!-- TABELA DAS COORDENADAS LONGITUDINAL -->
                                                                                          <p-table 
                                                                                               [value]="itemArray.coordinates" 
                                                                                               editMode="row"
                                                                                               dataKey="component_id"                                                                                               
                                                                                               [reorderableColumns]="true"
                                                                                               styleClass="p-datatable-gridlines p-datatable-striped">
                                                                                               <ng-template pTemplate="caption">
                                                                                                    <div
                                                                                                         class="cls-component-table-page-list-caption">
                                                                                                         <div
                                                                                                              class="cls-component-table-page-list-caption-left">
                                                                                                              Total:
                                                                                                              {{itemArray.coordinates ? itemArray.coordinates.length : 0 }}
                                                                                                         </div>
                                                                                                         <div
                                                                                                              class="cls-component-table-page-list-caption-right">
                                                                                                              <div>
                                                                                                                   <p-button
                                                                                                                        styleClass="cls-component-button"                                                                                                                       
                                                                                                                        icon="fa-solid fa-plus"
                                                                                                                        severity="secondary"
                                                                                                                        (click)="onRowNewCoordinates('longitudinal', itemArray.draw_limit_data_id)" />
                                                                                                              </div>
                                                                                                         </div>
                                                                                                    </div>
                                                                                               </ng-template>
                                                                                               <ng-template pTemplate="header">
                                                                                                    <tr>                                                                                                         
                                                                                                        <th style="width:2%"></th>
                                                                                                        <th style="width:44%; text-align: center">X</th>
                                                                                                        <th style="width:44%; text-align: center">Y</th> 
                                                                                                        <th style="width:10%"></th>                                                                          
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-itemCoordinate let-editing="editing" let-IndexCoordinates="rowIndex">
                                                                                                    <tr [pEditableRow]="itemCoordinate" [pReorderableRow]="IndexCoordinates">
                                                                                                         <td>
                                                                                                              <span class="fa-solid fa-arrows-up-down"
                                                                                                                   pReorderableRowHandle></span>
                                                                                                         </td>
                                                                                                         <td>
                                                                                                              <p-cellEditor>
                                                                                                                   <ng-template pTemplate="input">
                                                                                                                        <p-inputNumber
                                                                                                                             [(ngModel)]="itemCoordinate.x"
                                                                                                                             mode="decimal"
                                                                                                                             locale="pt-BR"
                                                                                                                             class="cls-component-table-page-list-input-number" 
                                                                                                                             [maxFractionDigits]="2"/>
                                                                                                                   </ng-template>
                                                                                                                   <ng-template pTemplate="output">
                                                                                                                        {{itemCoordinate.x}}
                                                                                                                   </ng-template>
                                                                                                              </p-cellEditor>                                                                                                            
                                                                                                         </td>
                                                                                                         <td>
                                                                                                              <p-cellEditor>
                                                                                                                   <ng-template pTemplate="input">
                                                                                                                        <p-inputNumber
                                                                                                                             [(ngModel)]="itemCoordinate.y"
                                                                                                                             mode="decimal"
                                                                                                                             locale="pt-BR"
                                                                                                                             class="cls-component-table-page-list-input-number" 
                                                                                                                             [maxFractionDigits]="2"/>
                                                                                                                   </ng-template>
                                                                                                                   <ng-template pTemplate="output">
                                                                                                                        {{itemCoordinate.y}}
                                                                                                                   </ng-template>
                                                                                                              </p-cellEditor>
                                                                                                         </td>
                                                                                                         <td>
                                                                                                              <div class="flex align-items-center justify-content-center gap-2">
                                                                                                                   <button *ngIf="!editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        icon="fa-solid fa-trash"
                                                                                                                        (click)="this.onRowDeleteCoordinates('longitudinal', IndexCoordinates, 'cgDataDrawCoordinates', itemArray.draw_limit_data_id)"
                                                                                                                        class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                                                   </button>
                                                                                                                   <button *ngIf="!editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        pInitEditableRow
                                                                                                                        icon="fa-solid fa-pencil"
                                                                                                                        (click)="this.onRowEditInitCoordinates(itemCoordinate)"
                                                                                                                        class="p-button-rounded p-button-text">
                                                                                                                   </button>
                                                                                                                   <button *ngIf="editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        pSaveEditableRow
                                                                                                                        icon="fa-solid fa-check"
                                                                                                                        (click)="onRowEditSaveCoordinates(itemCoordinate)"
                                                                                                                        class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                                                   </button>
                                                                                                                   <button *ngIf="editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        pCancelEditableRow
                                                                                                                        icon="fa-solid fa-arrow-right-from-bracket"
                                                                                                                        (click)="onRowEditCancelCoordinates('longitudinal', itemCoordinate, IndexCoordinates, itemArray.draw_limit_data_id)"
                                                                                                                        class="p-button-rounded p-button-text p-button-danger">
                                                                                                                   </button>
                                                                                                              </div>
                                                                                                         </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                          </p-table>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          <ng-container *ngFor="let item of itemArray.coordinates">
                                                                                               ({{item.x}}, {{item.y}}) <br>
                                                                                          </ng-container>                                                                                          
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">                                                                                          
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.fill_color"
                                                                                               [options]="optionsBasicColors"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          
                                                                                               <div [style.background-color]="itemArray.fill_color" style="width: 2rem; height:2rem; border-radius: 25px; margin: 0 auto;">
                                                                                               </div>                                                                                          
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.label"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                               {{itemArray.label}}                                        
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <div class="flex align-items-center justify-content-center gap-2">
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"                                                                                          
                                                                                          icon="fa-solid fa-trash"
                                                                                          (click)="this.onRowDelete(itemArray, 'chartDrawLimitsCGLongitudinal')"
                                                                                          class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          pInitEditableRow
                                                                                          icon="fa-solid fa-pencil"
                                                                                          (click)="this.onRowEditInit(itemArray)"
                                                                                          class="p-button-rounded p-button-text">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pSaveEditableRow
                                                                                          icon="fa-solid fa-check"
                                                                                          (click)="onRowEditSave(itemArray)"
                                                                                          class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pCancelEditableRow
                                                                                          icon="fa-solid fa-arrow-right-from-bracket"
                                                                                          (click)="onRowEditCancel(itemArray, Index, 'chartDrawLimitsCG')"
                                                                                          class="p-button-rounded p-button-text p-button-danger">
                                                                                     </button>
                                                                                </div>                                                                                
                                                                           </td>
                                                                      </tr>
                                                                 </ng-template>
                                                            </p-table>
                                                       </ng-template>
                                                  </p-tabPanel>
                                             </p-tabView>
                                        </ng-template>
                                   </p-panel>
                              </div>
                         </div>
                         <!-- GRÁFICO LATERAL -->
                         <div class="grid">
                              <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                   <p-panel class="cls-component-panel-form-fieldset">
                                        <ng-template pTemplate="header">
                                             <div class="cls-component-panel-form-fieldset-header">
                                                  <div class="cls-component-panel-form-fieldset-header-title">
                                                       <h3>
                                                            <i class="fa-solid fa-check"></i>
                                                            <span> GRÁFICO LIMITE C.G.LATERAL</span>
                                                       </h3>
                                                  </div>
                                             </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                             <sunrise-app-platform--chart-cg-limits
                                                  [objChartCGLimits]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].getRawValue()">
                                             </sunrise-app-platform--chart-cg-limits>
                                        </ng-template>
                                   </p-panel>
                              </div>
                              <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                   <p-panel class="cls-component-panel-form-fieldset">
                                        <ng-template pTemplate="header">
                                             <div class="cls-component-panel-form-fieldset-header">
                                                  <div class="cls-component-panel-form-fieldset-header-title">
                                                       <h3>
                                                            <i class="fa-solid fa-check"></i>
                                                            <span> CONFIGURAÇÃO GRÁFICO LIMITE C.G. LATERAL</span>
                                                       </h3>
                                                  </div>
                                             </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                             <p-tabView [scrollable]="true" class="cls-component-tabview-page"
                                                  [activeIndex]="0">
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-chart-bar"></i>
                                                                 <div>Eixos</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <p-fieldset legend="Eixo Y - Peso Bruto"
                                                                 styleClass="cls-app-platform--component-chart-fieldset-config">
                                                                 <div
                                                                      class="cls-component-form cls-component-form-frame">
                                                                      <div class="formgrid">
                                                                           <div class="grid"> 
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Mínimo Y #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.y_min">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>                                                                                
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Máximo Y #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.y_max">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                                                                                               
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down
                                                                                          label="Escala Eixo Y #BOLD#"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.y_scale"
                                                                                          [arrayOptions]="this.optionsChartScales">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Eixo #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.y_step_axis">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Grid #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.weight.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.y_step_grid">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down
                                                                                          appendTo="body"
                                                                                          label="Eixo Oposto"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.y_show_opposite_unit"
                                                                                          [arrayOptions]="this.optionsCgWeightUnits">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </p-fieldset>
                                                            <p-fieldset legend="Eixo X - Estação Fuselagem"
                                                                 styleClass="cls-app-platform--component-chart-fieldset-config">
                                                                 <div
                                                                      class="cls-component-form cls-component-form-frame">
                                                                      <div class="formgrid">
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Mínimo X #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.x_min">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div> 
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Valor Máximo X #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.x_max">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                                                                                               
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down
                                                                                          label="Escala eixo X #BOLD#"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.x_scale"
                                                                                          [arrayOptions]="this.optionsChartScales">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                           <div class="grid">
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Eixo #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.x_step_axis">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-input-number-float
                                                                                          label="Step Grid #BOLD#"
                                                                                          [groupRightText]="this.formWeightBalanceSheet.controls.unit_data.controls.arm.value"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.x_step_grid">
                                                                                     </lib-ui-primeng-input-number-float>
                                                                                </div>
                                                                                <div
                                                                                     class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                                     <lib-ui-primeng-drop-down
                                                                                          appendTo="body"
                                                                                          label="Eixo Oposto"
                                                                                          [control]="this.formWeightBalanceSheet.controls.chart_lateral_data.controls[0].controls.x_show_opposite_unit"
                                                                                          [arrayOptions]="this.optionsCgArmUnits">
                                                                                     </lib-ui-primeng-drop-down>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </p-fieldset>
                                                       </ng-template>
                                                  </p-tabPanel>
                                                  <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                       <ng-template pTemplate="header">
                                                            <div class="cls-component-tabview-page-tabpanel-header">
                                                                 <i class="fa-solid fa-chart-line"></i>
                                                                 <div>Plotagem</div>
                                                            </div>
                                                       </ng-template>
                                                       <ng-template pTemplate="content">
                                                            <!-- TABELA DA PLOTAGEM LATERAL -->
                                                            <p-table 
                                                                 [value]="this.chartDrawLimitsCGLateral" 
                                                                 dataKey="draw_limit_data_id"
                                                                 editMode="row" 
                                                                 [tableStyle]="{'min-width': '50rem'}"
                                                                 [reorderableColumns]="true"
                                                                 class="cls-component-table-panel-list"
                                                                 styleClass="p-datatable-gridlines p-datatable-striped">
                                                                 <ng-template pTemplate="caption">
                                                                      <div class="cls-component-table-page-list-caption">
                                                                           <div class="cls-component-table-page-list-caption-left">
                                                                                Total: {{this.chartDrawLimitsCGLateral ? this.chartDrawLimitsCGLateral.length : 0 }}
                                                                           </div>
                                                                           <div class="cls-component-table-page-list-caption-right">
                                                                                <div>
                                                                                     <p-button
                                                                                          styleClass="cls-component-button"
                                                                                          label="NOVA LINHA"
                                                                                          icon="fa-solid fa-plus"
                                                                                          severity="secondary"
                                                                                          (click)="onRowNewLimits('cgDataDrawLimitsCGLateral')" />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="header">
                                                                      <tr>
                                                                           <th style="width:60%">Coordenadas</th>                                                                           
                                                                           <th style="width:15%">Cor</th>
                                                                           <th style="width:15%">Legenda</th>                                                                         
                                                                           <th style="width:10%"></th>
                                                                      </tr>
                                                                 </ng-template>
                                                                 <ng-template pTemplate="body" let-itemArray
                                                                      let-editing="editing" let-Index="rowIndex">
                                                                      <tr [pEditableRow]="itemArray">
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <!-- TABELA DAS COORDENADAS -->
                                                                                          <p-table 
                                                                                               [value]="itemArray.coordinates"                                                                                                
                                                                                               [reorderableColumns]="true"
                                                                                               styleClass="p-datatable-gridlines p-datatable-striped"
                                                                                               editMode="row"
                                                                                               dataKey="component_id">
                                                                                               <ng-template pTemplate="caption">
                                                                                                    <div
                                                                                                         class="cls-component-table-page-list-caption">
                                                                                                         <div
                                                                                                              class="cls-component-table-page-list-caption-left">
                                                                                                              Total:
                                                                                                              {{itemArray.coordinates ? itemArray.coordinates.length : 0 }}
                                                                                                         </div>
                                                                                                         <div
                                                                                                              class="cls-component-table-page-list-caption-right">
                                                                                                              <div>
                                                                                                                   <p-button
                                                                                                                        styleClass="cls-component-button"                                                                                                                       
                                                                                                                        icon="fa-solid fa-plus"
                                                                                                                        severity="secondary"
                                                                                                                        (click)="onRowNewCoordinates('lateral', itemArray.draw_limit_data_id)" />
                                                                                                              </div>
                                                                                                         </div>
                                                                                                    </div>
                                                                                               </ng-template>
                                                                                               <ng-template pTemplate="header">
                                                                                                    <tr>                                                                                                         
                                                                                                        <th style="width:2%"></th>
                                                                                                        <th style="width:44%; text-align: center">X</th>
                                                                                                        <th style="width:44%; text-align: center">Y</th> 
                                                                                                        <th style="width:10%"></th>                                                                          
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-itemCoordinate let-editing="editing" let-IndexCoordinates="rowIndex">
                                                                                                    <tr [pEditableRow]="itemCoordinate" [pReorderableRow]="IndexCoordinates">
                                                                                                         <td>
                                                                                                              <span class="fa-solid fa-arrows-up-down"
                                                                                                                   pReorderableRowHandle></span>
                                                                                                         </td>
                                                                                                         <td>
                                                                                                              <p-cellEditor>
                                                                                                                   <ng-template pTemplate="input">
                                                                                                                        <p-inputNumber
                                                                                                                             [(ngModel)]="itemCoordinate.x"
                                                                                                                             mode="decimal"
                                                                                                                             locale="pt-BR"
                                                                                                                             class="cls-component-table-page-list-input-number" 
                                                                                                                             [maxFractionDigits]="2"/>
                                                                                                                   </ng-template>
                                                                                                                   <ng-template pTemplate="output">
                                                                                                                        {{itemCoordinate.x}}
                                                                                                                   </ng-template>
                                                                                                              </p-cellEditor>                                                                                                            
                                                                                                         </td>
                                                                                                         <td>
                                                                                                              <p-cellEditor>
                                                                                                                   <ng-template pTemplate="input">
                                                                                                                        <p-inputNumber
                                                                                                                             [(ngModel)]="itemCoordinate.y"
                                                                                                                             mode="decimal"
                                                                                                                             locale="pt-BR"
                                                                                                                             class="cls-component-table-page-list-input-number" 
                                                                                                                             [maxFractionDigits]="2"/>
                                                                                                                   </ng-template>
                                                                                                                   <ng-template pTemplate="output">
                                                                                                                        {{itemCoordinate.y}}
                                                                                                                   </ng-template>
                                                                                                              </p-cellEditor>
                                                                                                         </td>
                                                                                                         <td>
                                                                                                              <div class="flex align-items-center justify-content-center gap-2">
                                                                                                                   <button *ngIf="!editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        icon="fa-solid fa-trash"
                                                                                                                        (click)="this.onRowDeleteCoordinates('lateral', IndexCoordinates, 'cgDataDrawCoordinates', itemArray.draw_limit_data_id)"
                                                                                                                        class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                                                   </button>
                                                                                                                   <button *ngIf="!editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        pInitEditableRow
                                                                                                                        icon="fa-solid fa-pencil"
                                                                                                                        (click)="this.onRowEditInitCoordinates(itemCoordinate)"
                                                                                                                        class="p-button-rounded p-button-text">
                                                                                                                   </button>
                                                                                                                   <button *ngIf="editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        pSaveEditableRow
                                                                                                                        icon="fa-solid fa-check"
                                                                                                                        (click)="onRowEditSaveCoordinates(itemCoordinate)"
                                                                                                                        class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                                                   </button>
                                                                                                                   <button *ngIf="editing" pButton
                                                                                                                        pRipple type="button"
                                                                                                                        pCancelEditableRow
                                                                                                                        icon="fa-solid fa-arrow-right-from-bracket"
                                                                                                                        (click)="onRowEditCancelCoordinates('lateral', itemCoordinate, IndexCoordinates, itemArray.draw_limit_data_id)"
                                                                                                                        class="p-button-rounded p-button-text p-button-danger">
                                                                                                                   </button>
                                                                                                              </div>
                                                                                                         </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                          </p-table>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          <ng-container *ngFor="let item of itemArray.coordinates">
                                                                                               ({{item.x}}, {{item.y}}) <br>
                                                                                          </ng-container>                                                                                          
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">                                                                                          
                                                                                          <p-dropdown 
                                                                                               [(ngModel)]="itemArray.fill_color"
                                                                                               [options]="optionsBasicColors"                                                                                               
                                                                                               optionLabel="label"
                                                                                               placeholder="Selecione"   
                                                                                               appendTo="body"  
                                                                                               optionValue="value"
                                                                                               class="cls-component-table-page-list-input-dropdown"/>
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                          
                                                                                               <div [style.background-color]="itemArray.fill_color" style="width: 2rem; height:2rem; border-radius: 25px; margin: 0 auto;">
                                                                                               </div>
                                                                                          
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <p-cellEditor>
                                                                                     <ng-template pTemplate="input">
                                                                                          <input pInputText type="text"
                                                                                               [(ngModel)]="itemArray.label"
                                                                                               class="cls-component-table-page-list-input-text" />
                                                                                     </ng-template>
                                                                                     <ng-template pTemplate="output">
                                                                                               {{itemArray.label}}                                        
                                                                                     </ng-template>
                                                                                </p-cellEditor>
                                                                           </td>
                                                                           <td>
                                                                                <div class="flex align-items-center justify-content-center gap-2">
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"                                                                                          
                                                                                          icon="fa-solid fa-trash"
                                                                                          (click)="this.onRowDelete(itemArray, 'chartDrawLimitsCGLateral')"
                                                                                          class="p-button-rounded p-button-text p-button-danger mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="!editing" pButton
                                                                                          pRipple type="button"
                                                                                          pInitEditableRow
                                                                                          icon="fa-solid fa-pencil"
                                                                                          (click)="this.onRowEditInit(itemArray)"
                                                                                          class="p-button-rounded p-button-text">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pSaveEditableRow
                                                                                          icon="fa-solid fa-check"
                                                                                          (click)="onRowEditSave(itemArray)"
                                                                                          class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                     </button>
                                                                                     <button *ngIf="editing" pButton
                                                                                          pRipple type="button"
                                                                                          pCancelEditableRow
                                                                                          icon="fa-solid fa-arrow-right-from-bracket"
                                                                                          (click)="onRowEditCancel(itemArray, Index, 'chartDrawLimitsCG')"
                                                                                          class="p-button-rounded p-button-text p-button-danger">
                                                                                     </button>
                                                                                </div>                                                                                
                                                                           </td>
                                                                      </tr>
                                                                 </ng-template>
                                                            </p-table>
                                                       </ng-template>
                                                  </p-tabPanel>
                                             </p-tabView>
                                        </ng-template>
                                   </p-panel>
                              </div>
                         </div>
                    </ng-template>
               </p-panel>
          </div>
     </div>

</section>