<div class="cls-app-component">
    <sunrise-confirm-delete-dialog dialogKey="cg_component_conf_del"></sunrise-confirm-delete-dialog>
    <sunrise-unit-conversion-dialog
          [showDialog]="this.bolShowDialogUnitConversion"
          [model]="this.unitConversionModel"                          
          [unitType]="this.unitConversionType"
          [originalValue]="this.unitConversionOriginalValue"
          [unitToConvert]="this.unitConversionUnitToConvert"
          [classInternationalization]="this.classInternationalization"
          (actionUnitConversionDialog)="this.handlerActionUnitConversionDialog($event)"
          (actionReturnModeValueSave)="this.handlerActionReturnModeValueSave($event)">
    </sunrise-unit-conversion-dialog>
    <p-table
        [value]="this.inputComponent"
        [reorderableColumns]="true"
        dataKey="draw_limit_data_id"
        editMode="row"
        styleClass="p-datatable-gridlines p-datatable-striped"
        class="cls-cmp-ptv-table-form-list">
        <ng-template pTemplate="caption">
            <div class="cls-cmp-ptv-table-page-list-caption">
                <div class="cls-cmp-ptv-table-form-list-caption-left">
                    {{this.labelComponent}}
                    {{this.classInternationalization.getTranslation('lbl_Total')}}:
                    {{this.inputComponent ? this.inputComponent.length : 0 }}
                </div>
                <div class="cls-cmp-ptv-table-form-list-caption-right">                    
                    <!-- NOVA LINHA -->                                                 
                    <p-button
                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                        label="{{this.classInternationalization.getTranslation('btn_NewLine')}}"
                        icon="fa-solid fa-plus"
                        severity="secondary"
                        (click)="this.onClickTabInputRowNewTableMain(this.inputComponent ? this.inputComponent.length : 0)" />
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:1%"></th>
                <th style="width:49%">Coordenadas</th>
                <th style="width:15%">Cor</th>
                <th style="width:10%">Condição</th>
                <th style="width:20%">Legenda</th>                               
                <th style="width:5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-itemArrayTableMain let-editingTableMain="editing" let-IndexTableMain="rowIndex">
            <tr [pEditableRow]="itemArrayTableMain" [pReorderableRow]="IndexTableMain" 
                [ngClass]="{'cls-cmp-ptv-table-page-list-row-new': this.creatingRowIndexTableMain.includes(IndexTableMain), 'cls-cmp-ptv-table-page-list-row-edit': editingRowIndexTableMain.includes(IndexTableMain)}">
                <td>
                    <span class="fa-solid fa-arrows-up-down" pReorderableRowHandle></span>
                </td>
                <td>
                    <!-- TABELA DAS COORDENADAS -->
                    <p-table
                        [value]="this.inputComponent[IndexTableMain].draw_coordinates"
                        [tableStyle]="{'min-width': '30rem'}"
                        [reorderableColumns]="true"
                        (onRowReorder)="this.onRowReorderTableCoordinates($event)"
                        dataKey="coordinate_id"
                        editMode="row"
                        styleClass="p-datatable-gridlines p-datatable-striped"
                        class="cls-cmp-ptv-table-form-list">
                        <ng-template pTemplate="caption">
                            <div class="cls-cmp-ptv-table-page-list-caption">
                                <div class="cls-cmp-ptv-table-form-list-caption-left">
                                    Coordenadas 
                                    {{this.classInternationalization.getTranslation('lbl_Total')}}:
                                    {{this.inputComponent[IndexTableMain].table_fuel_cg ? this.inputComponent[IndexTableMain].table_fuel_cg.length : 0 }}
                                </div>
                                <div class="cls-cmp-ptv-table-form-list-caption-right">   
                                    <!-- EDITAR TABELA -->          
                                    <p-button 
                                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                        label="{{this.classInternationalization.getTranslation('btn_EditTable')}}"
                                        icon="fa-solid fa-edit"
                                        severity="secondary"
                                        (click)="this.onClickTabEditTableCoordinates(IndexTableMain)" />  
                                    <!-- NOVA LINHA -->      
                                    <ng-container *ngIf="!editingTableMain">
                                        <p-button
                                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                            label="{{this.classInternationalization.getTranslation('btn_NewLine')}}"
                                            icon="fa-solid fa-plus"
                                            severity="secondary"
                                            (click)="this.onClickTabInputRowNewTableCoordinates(this.inputComponent[IndexTableMain].table_fuel_cg ? this.inputComponent[IndexTableMain].table_fuel_cg.length : 0, IndexTableMain)" />
                                    </ng-container>                      
                                </div>
                            </div>                            
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:1%"></th>
                                <th style="width:45%; text-align: center">X</th>
                                <th style="width:45%; text-align: center">Y</th>                               
                                <th style="width:8%"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-itemArrayTableCoordinates let-editingTableCoordinates="editing" let-IndexTableCoordinates="rowIndex">
                            <tr [pEditableRow]="itemArrayTableCoordinates" [pReorderableRow]="IndexTableCoordinates" 
                                [ngClass]="{'cls-cmp-ptv-table-page-list-row-new': this.creatingRowIndexTableCoordinates.includes(IndexTableMain + '-' + IndexTableCoordinates), 'cls-cmp-ptv-table-page-list-row-edit': editingRowIndexTableCoordinates.includes(IndexTableMain + '-' + IndexTableCoordinates)}">
                                <td>
                                    <span class="fa-solid fa-arrows-up-down" pReorderableRowHandle></span>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="cls-cmp-ptv-table-page-list-input-number">
                                                <p-inputGroup>
                                                    <p-inputNumber
                                                        [(ngModel)]="itemArrayTableCoordinates.x"
                                                        mode="decimal"                                        
                                                        mode="decimal" 
                                                        [locale]="this._locale"
                                                        [minFractionDigits]="0"
                                                        [maxFractionDigits]="2"/>
                                                </p-inputGroup>                                
                                            </div>                            
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                                {{
                                                    this.classLocalMethods.convertNumberToLocale(itemArrayTableCoordinates.x, this._locale) 
                                                }} 
                                            </span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="cls-cmp-ptv-table-page-list-input-number">
                                                <p-inputGroup>
                                                    <p-inputNumber
                                                        [(ngModel)]="itemArrayTableCoordinates.y"
                                                        mode="decimal"                                        
                                                        mode="decimal" 
                                                        [locale]="this._locale"
                                                        [minFractionDigits]="0"
                                                        [maxFractionDigits]="2"/>
                                                </p-inputGroup>                                
                                            </div>                            
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                                {{
                                                    this.classLocalMethods.convertNumberToLocale(itemArrayTableCoordinates.y, this._locale) 
                                                }} 
                                            </span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>                                                                                                                                                                 
                                <td>
                                    <div class="cls-cmp-ptv-table-form-list-td-button">
                                        <button *ngIf="!editingTableCoordinates && !editingTableMain" 
                                            pButton
                                            icon="fa-solid fa-trash"
                                            (click)="this.onClickRowDeleteTableCoordinates(itemArrayTableCoordinates, IndexTableCoordinates, IndexTableMain)"
                                            [rounded]="true"
                                            class="mr-1"
                                            severity="danger">
                                        </button>
                                        <button *ngIf="!editingTableCoordinates && !editingTableMain" 
                                            pButton
                                            pInitEditableRow
                                            icon="fa-solid fa-pencil"
                                            (click)="this.onClickRowEditInitTableCoordinates(itemArrayTableCoordinates, IndexTableCoordinates, IndexTableMain)"
                                            [rounded]="true"
                                            severity="secondary">
                                        </button>
                                        <button *ngIf="editingTableCoordinates && !editingTableMain" 
                                            pButton
                                            pSaveEditableRow
                                            icon="fa-solid fa-check"
                                            (click)="this.onClickRowEditSaveTableCoordinates(itemArrayTableCoordinates, IndexTableCoordinates, IndexTableMain)"
                                            [rounded]="true"
                                            severity="success">
                                        </button>
                                        <button *ngIf="editingTableCoordinates && !editingTableMain" 
                                            pButton
                                            pCancelEditableRow
                                            icon="fa-solid fa-arrow-right-from-bracket"
                                            (click)="this.onClickRowEditCancelTCoordinates(itemArrayTableCoordinates, IndexTableCoordinates, IndexTableMain)"
                                            [rounded]="true"
                                            severity="danger">
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-dropdown">
                                <p-dropdown 
                                    [(ngModel)]="this.inputComponent[IndexTableMain].fill_color"
                                    [options]="this.optionsColors"    
                                    showClear="true"                                                                                           
                                    optionLabel="label"
                                    placeholder="Selecione"   
                                    appendTo="body"  
                                    optionValue="value"/>
                            </div>                             
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                <div [style.background-color]="this.showColorValue(this.inputComponent[IndexTableMain].fill_color)" style="width: 2rem; height:2rem; border-radius: 25px; margin: 0 auto;">
                                </div>
                            </span>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-dropdown">
                                <p-dropdown 
                                    [(ngModel)]="this.inputComponent[IndexTableMain].conditional"
                                    [options]="this.optionsConditions"    
                                    showClear="true"                                                                                           
                                    optionLabel="label"
                                    placeholder="Selecione"   
                                    appendTo="body"  
                                    optionValue="value"/>
                            </div>                             
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                                {{this.showConditionLabel(this.inputComponent[IndexTableMain].conditional)}}
                            </span>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="cls-cmp-ptv-table-page-list-input-text">
                                <input 
                                    pInputText 
                                    type="text"
                                        placeholder="Legenda"
                                    [(ngModel)]="this.inputComponent[IndexTableMain].label"/>                                                                        
                            </div> 
                           <div class="cls-cmp-ptv-table-page-list-input-text">
                                <input 
                                    pInputText 
                                    type="text"
                                    placeholder="Cor RGB"
                                    [(ngModel)]="this.inputComponent[IndexTableMain].label_color"/>                                                                        
                            </div>
                            <div class="cls-cmp-ptv-table-page-list-input-text">
                                <input 
                                pInputText 
                                type="text"
                                placeholder="Posição (x;y)"
                                [(ngModel)]="this.inputComponent[IndexTableMain].label_position"/>                                                                        
                            </div> 
                            <div class="cls-cmp-ptv-table-page-list-input-text">
                                <input 
                                pInputText 
                                type="text"
                                placeholder="Tamanho Fonte"
                                [(ngModel)]="this.inputComponent[IndexTableMain].label_font_size"/>                                                                        
                            </div>                                                           
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="cls-cmp-ptv-table-page-list-input-show">
                               {{this.inputComponent[IndexTableMain].label}}
                            </span>               
                            <br/>
                            <ng-container *ngIf="this.inputComponent[IndexTableMain].label !== ''">
                                <span class="cls-cmp-ptv-table-page-list-input-show">
                                    ({{this.inputComponent[IndexTableMain].label_color}}; {{this.inputComponent[IndexTableMain].label_font_size}}; [{{this.inputComponent[IndexTableMain].label_position}}])
                                 </span>
                            </ng-container>                                                               
                        </ng-template>
                   </p-cellEditor>                                        
                </td>                                                          
                <td>
                    <div class="cls-cmp-ptv-table-form-list-td-button">
                        <button *ngIf="!editingTableMain" 
                            pButton
                            icon="fa-solid fa-trash"
                            (click)="this.onClickRowDeleteTableMain(itemArrayTableMain, IndexTableMain)"
                            [rounded]="true"
                            class="mr-1"
                            severity="danger">
                        </button>
                        <button *ngIf="!editingTableMain" 
                            pButton
                            pInitEditableRow
                            icon="fa-solid fa-pencil"
                            (click)="this.onClickRowEditInitTableMain(itemArrayTableMain, IndexTableMain)"
                            [rounded]="true"
                            severity="secondary">
                        </button>
                        <button *ngIf="editingTableMain" 
                            pButton
                            pSaveEditableRow
                            icon="fa-solid fa-check"
                            (click)="onClickRowEditSaveTableMain(itemArrayTableMain, IndexTableMain)"
                            [rounded]="true"
                            severity="success">
                        </button>
                        <button *ngIf="editingTableMain" 
                             pButton
                             pCancelEditableRow
                             icon="fa-solid fa-arrow-right-from-bracket"
                             (click)="onClickRowEditCancelTableMain(itemArrayTableMain, IndexTableMain)"
                             [rounded]="true"
                             severity="danger">
                        </button>
                    </div>
                </td>
            </tr>            
        </ng-template>
    </p-table>
    <div class="cls-cmp-ptv-panel-bottom"></div> 
    <p-dialog [modal]="true" [(visible)]="this.bolShowDialogEditTable" [style]="{width: '40vw'}">
        <ng-template pTemplate="headless">
            <div class="cls-cmp-ptv-dialog-form-box">
                <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-panel-default-header">
                            <div class="cls-cmp-ptv-panel-default-header-title">
                                <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>{{this.classInternationalization.getTranslation('ttl_CgComponentsEditTable')}}</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="grid">
                            <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">  
                                <p-panel class="cls-cmp-ptv-panel-topic">
                                    <ng-template pTemplate="header">
                                        <div class="cls-cmp-ptv-panel-topic-header">
                                            <div class="cls-cmp-ptv-panel-topic-header-title">
                                              <!-- DADOS DA TABELA FORMATO CSV -->
                                                <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>{{this.classInternationalization.getTranslation('ttl_CgComponentsTableDataCsv')}}</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div class="cls-cmp-ptv-form-textarea-csv">
                                            <textarea 
                                                [(ngModel)]="this.dataTableToEdit"
                                                rows="25"
                                                cols="30" 
                                                pInputTextarea>
                                            </textarea>
                                        </div>                                       
                                        <div class="cls-cmp-ptv-panel-bottom"></div> 
                                    </ng-template>
                               </p-panel>
                            </div>
                        </div>
                        <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-center"></div>
                            <div class="p-toolbar-group-end">
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_Save')}}" 
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                    icon="fa-solid fa-right-from-bracket" 
                                    severity="success"
                                    (click)="this.onClickSaveEditTableCoordinates()"/>  
                                <p-button 
                                    label="{{this.classInternationalization.getTranslation('btn_GoOut')}}" 
                                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                    icon="fa-solid fa-right-from-bracket" 
                                    severity="danger"
                                    (click)="this.onClickCloseDialogEditTableCoordinates()"/>                                                          
                            </div>
                       </p-toolbar>
                       <div class="cls-cmp-ptv-panel-bottom"></div>
                    </ng-template>
                </p-panel>
            </div>               
        </ng-template>
    </p-dialog>
</div>