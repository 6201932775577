<div class="cls-app-platform--component">
    <div class="cls-app-platform--component-waiting-dialog">
        <p-dialog header="Header" 
            [modal]="true" 
            [(visible)]="this.isToShowDialog" 
            [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
            <ng-template pTemplate="content"> 
                <div class="cls-app-platform--component-waiting-dialog-header">
                    <div><i class="fa-solid fa-hourglass"></i></div>
                    <div> AGUARDE, O PROCESSO ESTA SENDO EXECUTADO...</div>
                </div>
                          
                <ng-container *ngIf="this.typeLayout === this.dialogWaitingType.DEFAULT">
                    
                </ng-container>
                <ng-container *ngIf="this.typeLayout === 'this.dialogWaitingType.'">
                    "CARREGANDO A LISTA DE AERONAVES. AGUARDE..."
                </ng-container>
                <ng-container *ngIf="this.typeLayout === 'this.dialogWaitingType.'">
                    "CARREGANDO DADOS DA AERONAVE. AGUARDE..."
                </ng-container>
            </ng-template>     
        </p-dialog>        
    </div>
</div>