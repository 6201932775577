//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASSES ***** //
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

// ***** COMPONENT ***** //
import { CountryFlagComponent } from '../../../../_components/layout/country-flag/country-flag.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleFlightService } from '../../../../_services/module-flight/module-flight.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-flight-data-list',
  standalone: true,
  imports: [
    CommonModule,
    CountryFlagComponent,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './flight-data-list.component.html'
})
export class FlightDataListComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  buttonActions!: any;

  waitingDialogType!: any;

  bolShowWaitingDialog!: boolean;
  
  strTypeWaitingDialog!: string;

  classModuleMethods!: any;

  // ***** LIST ***** //
  arrayFlightDataList: any[] = [];

  // ***** OBJECTS ***** //
  objPageTitle!: IPageTitle;
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleFlightApisService: ModuleFlightService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._loadFlightDataList();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.buttonActions = BUTTON_ACTION;
    
    this.bolShowWaitingDialog = false;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.DEFAULT;
    this.waitingDialogType = DIALOG_WAITING_TYPE;

    this.objPageTitle = {
      title: 'Listar Voos',
      subtitle: 'Voos Cadastrados no Sistema.',
      icon: 'fa-solid fa-plane'
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Redireciona para a página de Fomulário: de Flight Data - NOVO.
   * 
   */
  private async _newFlightData(): Promise<void> {
    this._router.navigateByUrl(`/app/flight/data/form`);
  }

  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Redireciona para a página de Fomulário: Flight Data - EDITAR.
   * 
   */
  private async _editFlightData(xIdFlightData: string): Promise<void> {
    this._router.navigateByUrl(`/app/flight/data/form/edit/${xIdFlightData}`);
  }

  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Apaga um Documento de Flight Data.
   * 
   * @param xId 
   */
  private async _deleteFlightData(xIdFlightData: string): Promise<void> {
    const tempFlightData = this.arrayFlightDataList.find(x => x.flight_data_id == xIdFlightData);

    this._confirmationService.confirm({
      message: `Você deseja APAGAR o Registro do Dado do Voo?`,
      header: 'Confirmação Apagar o Registro do Dado do Voo.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: () => {

      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: 'Apagar Registro do Plano de Voo',
          detail: 'O Processo de Apagar o Registro do Dado do Voo foi Cancelado pelo Usuário.',
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Carrega a lista de Dados dos Voos.
   */
  private async _loadFlightDataList() {
    this.bolShowWaitingDialog = true;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.FLIGHT_DATA_LIST;

    await this._moduleFlightApisService.getAllFlightDataList().subscribe({
      next: (xApiResponse: any) => {
        if (xApiResponse) {
          this.arrayFlightDataList = xApiResponse;

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          setTimeout(() => {
            this.bolShowWaitingDialog = false;

            this._messageService.add({
              severity: 'success',
              summary: 'Listar Dados de Voos',
              detail: 'Lista de Dados de Voos carregada com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }, settingConfig.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowWaitingDialog = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Listar Dados de Voos',
            detail: 'Não foi possível carregar a lista de Dados de Voos.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowWaitingDialog = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Listar Dados de Voos',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-28
  * @version 1.0.0
  * 
  * @description mite as ações dos botões da TABELA.
  */
  callbackTableClickButton(xButtonAction: string): void {
    if (xButtonAction == BUTTON_ACTION.NEW) {
      this._newFlightData()
    } else if (xButtonAction == BUTTON_ACTION.RELOAD) {
      this._loadFlightDataList();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Emite as ações dos botões de um REGISTRO listado na tabela.
   * 
   * @param xButtonAction 
   * @param xIdRegister 
   */
  callbackItemClickButton(xButtonAction: string, xIdRegister: string): void {
    if (xButtonAction == BUTTON_ACTION.EDIT) {
      this._editFlightData(xIdRegister);
    } else if (xButtonAction == BUTTON_ACTION.DELETE) {
      this._deleteFlightData(xIdRegister);
    }
  }
  //#endregion
}
