<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" 
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">
                            <h2>
                                <i class="fa-regular fa-rectangle-list"></i>
                                <span>Registro da Conta</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">                    
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-10 cls-util-no-padding-margin-bottom">
                            <p-panel class="cls-cmp-ptv-panel-topic">
                                <ng-template pTemplate="header">
                                     <div class="cls-cmp-ptv-panel-topic-header">
                                          <div class="cls-cmp-ptv-panel-topic-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>Dados da Conta</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                        <div class="formgrid grid">
                                            <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <lib-ui-primeng-input-text-button 
                                                    label="Id da Conta #BOLD#"
                                                    idField="fid-account-data-id"
                                                    buttonRightIcon="fa-solid fa-folder-tree"
                                                    [control]="this.formComponent.controls.account_data_id"
                                                    [maxLength]="36"
                                                    [onBlurMethod]="this.onBlurCheckFieldValue()"
                                                    [onClickMethod]="this.onClickCreatePathTree()"
                                                    toolTipButton="Executar novamente a criação das pastas.">
                                                </lib-ui-primeng-input-text-button>
                                           </div>
                                           <div class="field col-12 md:col-12 lg:col-6 xl:col-6">
                                                <lib-ui-primeng-drop-down-color 
                                                    label="Status #BOLD#"
                                                    idField="fid-status"
                                                    appendDropdownTo="body"
                                                    [control]="this.formComponent.controls.status"
                                                    [arrayOptions]="this.optionsAccountStatus">
                                                </lib-ui-primeng-drop-down-color>
                                            </div>                                                                                     
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid"> 
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">                                
                                                <lib-ui-primeng-input-text 
                                                    label="Nome da Conta #BOLD#"
                                                    idField="fid-account-name"
                                                    [control]="this.formComponent.controls.account_name"
                                                    [maxLength]="20" 
                                                    [onBlurMethod]="onBlurCheckFieldValue()"
                                                    toolTip="Este valor deve ser único. Será feita uma validação">
                                                </lib-ui-primeng-input-text>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">                                
                                                <lib-ui-primeng-input-text-button 
                                                    label="Email da Conta #BOLD#"
                                                    idField="fid-account-email"
                                                    buttonRightIcon="fa-solid fa-envelope"
                                                    [control]="this.formComponent.controls.account_email"
                                                    [maxLength]="150" 
                                                    [isUpperCase]="false"
                                                    [onBlurMethod]="onBlurCheckFieldValue()"
                                                    toolTip="Este valor deve ser único. Será feita uma validação"
                                                    toolTipButton="Enviar novo pedido de confirmação de E-mail.">
                                                </lib-ui-primeng-input-text-button>
                                            </div>  
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">                                
                                                <lib-ui-primeng-input-ddi-ddd-phone
                                                    label="Telefone da Conta #BOLD#"
                                                    [controlPhoneData]="this.formComponent.controls.phone_data">                                                                
                                                </lib-ui-primeng-input-ddi-ddd-phone>
                                            </div>            
                                        </div>  
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid"> 
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                <lib-ui-primeng-input-text-button 
                                                    label="Password Conta Proprietário #BOLD#"
                                                    idField="fid-account.user-owner-data.password-text"
                                                    buttonRightIcon="fa-solid fa-key"
                                                    [control]="this.formComponent.controls.user_owner_data.controls.password_text"
                                                    [onClickMethod]="this.onClickGenerateDefaultPassword()">
                                                </lib-ui-primeng-input-text-button>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                <lib-ui-primeng-input-text-button 
                                                    label="Confirma Password #BOLD#"
                                                    idField="fid-account.user-owner-data.confirm-password"
                                                    [control]="this.formComponent.controls.user_owner_data.controls.confirm_password">
                                                </lib-ui-primeng-input-text-button>
                                            </div>
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                <lib-ui-primeng-input-number-float 
                                                    label="Trocar Senha a cada (dias):"
                                                    idField="fid-account.user-owner-data.must-change-days"
                                                    [control]="this.formComponent.controls.user_owner_data.controls.must_change_days">
                                                </lib-ui-primeng-input-number-float>
                                            </div>
                                        </div>
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid"> 
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                <lib-ui-primeng-input-date
                                                    label="Data Criação"
                                                    idField="fid-date-limit"                                                    
                                                    [control]="this.formComponent.controls.created_date_utc">                                                    
                                                </lib-ui-primeng-input-date>
                                            </div> 
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                <lib-ui-primeng-input-date
                                                    label="Data Inicial"
                                                    idField="fid-date-limit"                                                    
                                                    [control]="this.formComponent.controls.start_date_utc">                                                    
                                                </lib-ui-primeng-input-date>
                                            </div> 
                                            <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
                                                <lib-ui-primeng-input-date
                                                    label="Data Final"
                                                    idField="fid-date-limit"                                                    
                                                    [control]="this.formComponent.controls.end_date_utc">                                                    
                                                </lib-ui-primeng-input-date>
                                            </div>   
                                        </div>  
                                        <div class="cls-cmp-ptv-form-gap"></div>
                                        <div class="formgrid grid"> 
                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-12">                                
                                                <lib-ui-primeng-input-text 
                                                    label="Observation"
                                                    idField="fid-account-remark"
                                                    [control]="this.formComponent.controls.account_remark"
                                                    [maxLength]="255" 
                                                    [isUpperCase]="true">
                                                </lib-ui-primeng-input-text>
                                            </div>
                                        </div>                                      
                                    </div>
                                    <div class="cls-cmp-ptv-form-advice">
                                        <i class="fa-solid fa-circle-info"></i><span>Toda Nova Conta será criada com o status: "WAITING VALIDATION".</span>
                                    </div>                                     
                                </ng-template>
                            </p-panel>                                                         
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-2 cls-util-no-padding-margin-bottom">                            
                            <p-panel class="cls-cmp-ptv-panel-topic">
                                <ng-template pTemplate="header">
                                     <div class="cls-cmp-ptv-panel-topic-header">
                                          <div class="cls-cmp-ptv-panel-topic-header-title">
                                               <h3>
                                                    <i class="fa-solid fa-window-maximize"></i>
                                                    <span>Preview Árvore de diretórios</span>
                                               </h3>
                                          </div>
                                     </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="cls-cmp-esp-preview-path">
                                        <p-tree [value]="this.pathTree"/>
                                    </div>                                        
                                </ng-template>
                            </p-panel>                                                        
                        </div>
                    </div>                                      
                </ng-template>
            </p-panel>
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" 
                [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">
                            <h2>
                                <i class="fa-regular fa-rectangle-list"></i>
                                <span>Perfil da Conta</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="grid">
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-10 cls-util-no-padding-margin-bottom">                                                                             
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-margin-bottom">
                                    <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                 <div class="cls-cmp-ptv-panel-topic-header-title">
                                                      <h3>
                                                           <i class="fa-solid fa-window-maximize"></i>
                                                           <span>Detalhe da Conta</span>
                                                      </h3>
                                                 </div>
                                            </div>
                                       </ng-template>
                                       <ng-template pTemplate="content">
                                            <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                                                <div class="formgrid grid">
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                        <lib-ui-primeng-drop-down-flag 
                                                            label="País #BOLD#"
                                                            idField="fid-profile-data.country"
                                                            appendDropdownTo="body"
                                                            [control]="this.formComponent.controls.profile_data.controls.country"
                                                            [arrayOptions]="this.optionsAccountCountries">
                                                        </lib-ui-primeng-drop-down-flag>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                        <lib-ui-primeng-drop-down-icon 
                                                            label="Tipo de Perfil #BOLD#"
                                                            idField="fid-profile-data.profile-type"
                                                            appendDropdownTo="body"
                                                            [control]="this.formComponent.controls.profile_data.controls.profile_type"
                                                            [arrayOptions]="this.optionsProfileType">
                                                        </lib-ui-primeng-drop-down-icon>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">
                                                        <lib-ui-primeng-drop-down 
                                                            label="Document Type #BOLD#"
                                                            idField="fid-profile-data.document-type"
                                                            appendDropdownTo="body"
                                                            [control]="this.formComponent.controls.profile_data.controls.document_type"
                                                            [arrayOptions]="this.optionsDocumentType">
                                                        </lib-ui-primeng-drop-down>
                                                    </div>
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-3">                                
                                                        <lib-ui-primeng-input-text 
                                                            label="Document IN #BOLD#"
                                                            idField="fid-profile-data.document-in"
                                                            [control]="this.formComponent.controls.profile_data.controls.document_in"                                                    [maxLength]="150" 
                                                            [isUpperCase]="true">
                                                        </lib-ui-primeng-input-text>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="this.formComponent.controls.profile_data.controls.profile_type.value === 'person'">
                                                    <div class="cls-cmp-ptv-form-gap"></div>
                                                    <div class="formgrid grid">                                           
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-6">                                
                                                            <lib-ui-primeng-input-text 
                                                                label="Full Name #BOLD#"
                                                                idField="fid-profile-data.full-name"
                                                                [control]="this.formComponent.controls.profile_data.controls.full_name"
                                                                [maxLength]="150" 
                                                                [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                        </div> 
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">    
                                                            <lib-ui-primeng-input-date
                                                                label="Data de Nascimento"
                                                                idField="fid-profile-data.birth-date-utc"                                                                
                                                                [control]="this.formComponent.controls.profile_data.controls.birth_date_utc">                                                    
                                                            </lib-ui-primeng-input-date>                                                           
                                                        </div> 
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-3">  
                                                            <lib-ui-primeng-drop-down 
                                                                label="Gênero"
                                                                idField="fid-profile-data.gender"
                                                                appendDropdownTo="body"
                                                                [control]="this.formComponent.controls.profile_data.controls.gender"
                                                                [arrayOptions]="this.optionsPersonGender">
                                                            </lib-ui-primeng-drop-down>                                                        
                                                        </div>                                                                                                                                            
                                                    </div>  
                                                </ng-container>
                                                <ng-container *ngIf="this.formComponent.controls.profile_data.controls.profile_type.value === 'company'">
                                                    <div class="cls-cmp-ptv-form-gap"></div>
                                                    <div class="formgrid grid">                                           
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-12">                                
                                                            <lib-ui-primeng-input-text 
                                                                label="Nome completo #BOLD#"
                                                                idField="fid-profile-data.full-name"
                                                                [control]="this.formComponent.controls.profile_data.controls.full_name"
                                                                [maxLength]="150" 
                                                                [isUpperCase]="true">
                                                            </lib-ui-primeng-input-text>
                                                        </div>                                                                                                                                            
                                                    </div>  
                                                </ng-container>                                                                                                                                                  
                                                <div class="cls-cmp-ptv-form-gap"></div>
                                                <div class="formgrid grid">                                           
                                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">                                
                                                        <lib-ui-primeng-input-text 
                                                            label="Nome Completo Representante #BOLD#"
                                                            idField="fid-agent-data.full-name"
                                                            [control]="this.formComponent.controls.agent_data.controls.full_name"
                                                            [maxLength]="150" 
                                                            [isUpperCase]="true">
                                                        </lib-ui-primeng-input-text>
                                                    </div>   
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">                                
                                                        <lib-ui-primeng-input-text 
                                                            label="Email do Representante #BOLD#"
                                                            idField="fid-profile-data.full-name"
                                                            [control]="this.formComponent.controls.agent_data.controls.email"
                                                            [maxLength]="150">
                                                        </lib-ui-primeng-input-text>
                                                    </div>    
                                                    <div class="field col-12 md:col-12 lg:col-6 xl:col-4">                                
                                                        <lib-ui-primeng-input-ddi-ddd-phone
                                                            label="Telefone do Representante #BOLD#"
                                                            [controlPhoneData]="this.formComponent.controls.agent_data.controls.phone_data">                                                                
                                                        </lib-ui-primeng-input-ddi-ddd-phone>
                                                    </div>                                                                                                                                             
                                                </div>                                                                                                
                                            </div>
                                            <div class="cls-cmp-ptv-panel-bottom"></div> 
                                       </ng-template>
                                    </p-panel> 
                                </div>
                            </div>
                            <div class="grid">
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-margin-bottom">
                                    <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                 <div class="cls-cmp-ptv-panel-topic-header-title">
                                                      <h3>
                                                           <i class="fa-solid fa-window-maximize"></i>
                                                           <span>Contatos</span>
                                                      </h3>
                                                 </div>
                                            </div>
                                       </ng-template>
                                       <ng-template pTemplate="content">
                                        <sunrise-contacts-handler
                                            [formComponent]="this.formComponent.controls.profile_data.controls.contacts">
                                        </sunrise-contacts-handler>
                                        <div class="cls-cmp-ptv-panel-bottom"></div> 
                                       </ng-template>
                                    </p-panel>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-6 cls-util-no-padding-margin-bottom">
                                    <p-panel class="cls-cmp-ptv-panel-topic">
                                        <ng-template pTemplate="header">
                                            <div class="cls-cmp-ptv-panel-topic-header">
                                                 <div class="cls-cmp-ptv-panel-topic-header-title">
                                                      <h3>
                                                           <i class="fa-solid fa-window-maximize"></i>
                                                           <span>Endereços</span>
                                                      </h3>
                                                 </div>
                                            </div>
                                       </ng-template>
                                       <ng-template pTemplate="content">
                                        <sunrise-addresses-handler
                                            [formComponent]="this.formComponent.controls.profile_data.controls.addresses">
                                        </sunrise-addresses-handler>
                                        <div class="cls-cmp-ptv-panel-bottom"></div> 
                                       </ng-template>
                                    </p-panel>
                                </div>                                
                            </div>                             
                        </div>
                        <div class="field col-12 md:col-12 lg:col-12 xl:col-2 cls-util-no-padding-margin-bottom">
                            <ng-container *ngIf="this.formComponent.controls.profile_data.controls.profile_type.value === 'company'">
                                <p-panel class="cls-cmp-ptv-panel-topic">
                                    <ng-template pTemplate="header">
                                         <div class="cls-cmp-ptv-panel-topic-header">
                                              <div class="cls-cmp-ptv-panel-topic-header-title">
                                                   <h3>
                                                        <i class="fa-solid fa-window-maximize"></i>
                                                        <span>Logo</span>
                                                   </h3>
                                              </div>
                                         </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        
                                    </ng-template>
                                </p-panel>                                
                            </ng-container>                                                        
                        </div>
                    </div>  
                </ng-template>
            </p-panel>
        </div>
    </div>    
    <p-toolbar class="cls-cmp-ptv-toolbar-page">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container *ngIf="this.currentStatus === 'edit'">
                <p-button 
                    label="Novo" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-plus" 
                    severity="secondary"
                    (click)="this.onClickToolbarTabRegisterData(this.actionType.NEW)" />
                <p-button 
                    label="Salvar Modificações" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-save" 
                    severity="secondary"
                    (click)="this.onClickToolbarTabRegisterData(this.actionType.UPDATE)" />
            </ng-container>
            <ng-container *ngIf="this.currentStatus === 'new'">
                <p-button 
                    label="Salvar Novo" 
                    styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                    icon="fa-solid fa-save" 
                    severity="success"
                    (click)="this.onClickToolbarTabRegisterData(this.actionType.SAVE)" />
            </ng-container>
            <p-button 
                label="Sair" styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                icon="fa-solid fa-right-from-bracket" 
                severity="danger"
                (click)="this.onClickToolbarTabRegisterData(this.actionType.EXIT)" />
        </div>
    </p-toolbar>
</section>