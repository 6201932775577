//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //

// ***** MOCK ***** //

// ***** DOMÍNIOS ***** //

// ***** COMPONENT ***** //
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleCanttechService } from '../../../../_services/module-canttech/module-canttech.service';
import { ModuleAircraftService } from 'src/app/app-platform/_services/module-aircraft/module-aircraft.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from 'src/app/_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IDropDownOptionsBasic } from '../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsIcon } from '../../../../../_library/interfaces/IDropDownOptionsIcon';
import { FormBuilder, FormControl } from '@angular/forms';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-account-data-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TitlePageComponent,
    ToastMessageComponent
  ],
  templateUrl: './account-data-form.component.html'
})
export class AccountDataFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** OBJECTS ***** //
  objPageTitle!: IPageTitle;

  // ***** FORMS ***** //
  formAccountData: any

  // ***** ARRAYS ***** //

  // ***** OPTIONS***** //
  optionsAllAircraft!: IDropDownOptionsIcon[];
  optionsAllPilots!: IDropDownOptionsIcon[];
  optionsOperatorsByAircraft!: IDropDownOptionsBasic[];

  // ***** OTHERS ***** //
  waitingDialogType!: any;
  bolShowWaitingDialog!: boolean;
  strTypeWaitingDialog!: string;
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleAircraftApisService: ModuleAircraftService,
    private _moduleCanttechApisService: ModuleCanttechService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initAccountDataForm();

    //#region "|--- MOCKS ---|"
    //#endregion

    //#region "|--- BANCO DE DADOS ---|"
    //#endregion

    this._initFormFlightEvents();
    this._checkEditMode();
  }

  //region "|--- EVENT HANDLERS ---|"
  private _initFormFlightEvents(): void {
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-28
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables() {
    this.waitingDialogType = DIALOG_WAITING_TYPE;
    this.bolShowWaitingDialog = false;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.DEFAULT;
  }

  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de voo.
   */
  private _initAccountDataForm() {
    //this.formAccountwData = initFlightForm();
  }

  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      if (xParams["id"]) {
        this.objPageTitle = {
          title: 'Editar Conta',
          subtitle: 'Alterar os Dados de uma Conta do Sistema',
          icon: 'fa-solid fa-edit'
        }

        //this._getFlightDataById(xParams["id"]);
      } else {
        this.objPageTitle = {
          title: 'Nova Conta',
          subtitle: 'Inclua os Dados de um Nova Conta no Sistema.',
          icon: 'fa-solid fa-plus'
        }
      }
    })
  }

  private async _newAccountData() {
    //this.private_clearForm();
    this._router.navigate(['/app/canttech/account/form']);
  }
}

