<div class="cls-frame-login-cover">
    <div class="cls-frame-login-cover-container">
        <div class="cls-frame-login-cover-image">
            <div class="cls-frame-login-cover-image-logo">
                LOGO
            </div>
            <div class="cls-frame-login-cover-image-advise">
                ipsun lorem dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </div>
        </div>
        <div class="cls-frame-login-cover-content">             
            <h2>Seja Bem Vindo!</h2>         
            <p>Sign in to continue to your account.</p>
            <router-outlet></router-outlet>     
            <div class="cls-frame-login-cover-content-language">
                <div>
                    Escolha o idioma: 
                </div>
                <p-button 
                    styleClass="cls-cmp-esp-top-header-button cls-cmp-esp-top-header-flag"
                    [rounded]="true" 
                    [text]="true" 
                    (onClick)="menuInter.toggle($event)">
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                    [class]="'flag flag-' + this.getFlagLocale(this.localeExtension)"/>
                </p-button>                               
            </div>  
            <div class="cls-frame-login-cover-content-system">
                <div class="cls-frame-login-cover-content-system-content">
                    <div>
                        &#169; 2025 Cantilever Tecnologia
                    </div>
                    <div>
                        versão: 1.0.0
                    </div>
                </div>                
            </div>       
        </div>                
    </div>
    <p-menu #menuInter [model]="this.arrInterItems" [popup]="true">
        <ng-template pTemplate="item" let-item>
            <a 
                pRipple class="flex align-items-center p-menuitem-link"
                (click)="this.onClickInter(item.icon)">
                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                    [class]="'flag flag-' + this.getFlagLocale(item.icon)"
                    style="width: 18px" />
                <span class="ml-2">{{ item.label }}</span>
            </a>
        </ng-template>
    </p-menu>
</div>
