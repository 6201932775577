//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, EventEmitter, Output, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormControl, FormsModule } from '@angular/forms';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../components/primeng/primeng-components.module';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../definitions/ActionType';
//#endregion

//#region "|--- INTERFACE ---|"
interface IDropDownOptions {
  label: string,
  value: string | boolean | null
}
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-unit-conversion-dialog',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './unit_conversion_dialog.component.html'
})
export class UnitConversionDialogComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() classInternationalization!: any;

  @Input() set unitToConvert(xValue: string) {
    if (this.formUnitConversion === null) {
      this._initUnitConversionForm();
      this._initUnitConversionFormEvents();
    }

    // Passo direto o valor, pois o evento de valueChanges faz o tratamento de UNDEFINED e NULL.
    this.formUnitConversion.get('value_converted_unit').setValue(xValue);   
  }

  @Input() set originalValue(xValue: string) {
    if (this.formUnitConversion === null) {
      this._initUnitConversionForm();
      this._initUnitConversionFormEvents();
    }

    if (xValue && xValue !== null) {
      this.formUnitConversion.get('value_original').setValue(xValue);
    } else {
      this.formUnitConversion.get('value_original').setValue(null);
    }
  }

  @Input() set unitType(xValue: string) {
    if (this.formUnitConversion === null) {
      this._initUnitConversionForm();
      this._initUnitConversionFormEvents();
    }

    if (xValue && xValue !== null) {
      this.formUnitConversion.get('unit_type').setValue(xValue);
    } else {
      this.formUnitConversion.get('unit_type').setValue(null);
    }
  }

  @Input() set control(xValue: FormControl | null) {
    if (xValue && xValue !== null) {
      this.controlContent = xValue;
      this.bolHasControl = true;
    } else {
      this.controlContent = null;
      this.bolHasControl = false;
    }
  }

  @Input() set model(xValue: FormsModule | null) {
    if (xValue && xValue !== null) {
      this.modelContent = xValue;
      this.bolHasModel = true;
    } else {
      this.modelContent = null;
      this.bolHasModel = false;
    }
  }

  @Input() set showDialog(xValue: any) {
    if (xValue && xValue !== null) {
      this.currentShowDialog = xValue;
    } else {
      this.currentShowDialog = false;
    }
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionUnitConversionDialog = new EventEmitter<string>();
  @Output() actionReturnModeValueSave = new EventEmitter<any>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;

  // ***** CONTROL ***** //
  public controlContent!: FormControl | null;
  public modelContent!: FormsModule | null;
  public bolHasControl = false; // Tem que inicializar na declaração, pois vai ser tratado no setter do control. 
  public bolHasModel = false; // Tem que inicializar na declaração, pois vai ser tratado no setter do model.

  // ***** FORMS ***** //
  public formUnitConversion: any = null;

  // ***** OPTIONS ***** //
  public optionsUnitType!: IDropDownOptions[];
  public optionsAcceleration!: IDropDownOptions[];
  public optionsAircraftFuel!: IDropDownOptions[];
  public optionsWeight!: IDropDownOptions[];
  public optionsLength!: IDropDownOptions[];
  public optionsVolume!: IDropDownOptions[];
  public optionsTemperature!: IDropDownOptions[];
  public optionsPressure!: IDropDownOptions[];
  public optionsTime!: IDropDownOptions[];
  public optionsSpeed!: IDropDownOptions[];


  // ***** OPTIONS ***** //
  public optionsValueOriginalUnit!: IDropDownOptions[];
  public optionsValueConvertedUnit!: IDropDownOptions[];

  public force!: {};
  public torque!: {};
  public power!: {};
  public energy!: {};
  public angle!: {};
  public frequency!: {};
  public area!: {};
  public volumeFlowRate!: {};
  public massFlowRate!: {};
  public density!: {};
  public viscosity!: {};
  public kinematicViscosity!: {};
  public specificVolume!: {};
  public specificWeight!: {};

  // ***** SHOW DIALOG ***** //  
  public currentShowDialog!: boolean;
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string
  ) { }

 // @status:
  ngOnInit(): void {
    this._initVariables();

    this._loadOptionsUnits();

    if (this.formUnitConversion === null) {
      this._initUnitConversionForm();
      this._initUnitConversionFormEvents();
    }
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-02
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;

    this.optionsValueOriginalUnit = [];
    this.optionsValueConvertedUnit = [];
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário de conversão de unidades.
   */
  private _initUnitConversionForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    this.formUnitConversion = objFormBuilder.group({
      unit_type: [null],
      value_original: [null],
      value_original_unit: [null],
      value_converted: [null],
      value_converted_unit: [null]
    });
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Carrega as opções de unidades de medida.
   */
  private _loadOptionsUnits(): void {
    this.optionsUnitType = [
      { label: 'Aceleração', value: 'acceleration' },
      { label: 'Comprimento', value: 'length' },
      { label: 'Combustível de Avião', value: 'aircraft_fuel' },
      { label: 'Peso', value: 'weight' },
      { label: 'Pressão', value: 'pressure' },
      { label: 'Temperature', value: 'temperature' },
      { label: 'Velocidade', value: 'speed' },
      { label: 'Volume', value: 'volume' },
      { label: 'Tempo', value: 'time' }
    ]

    this.optionsAcceleration = [
      { label: '(m/s²) Meter per Second Squared', value: 'm/s²' },
      { label: '(ft/s²) Foot per Second Squared', value: 'ft/s²' },
      { label: '(g) Standard Gravity', value: 'g' }
    ]

    this.optionsAircraftFuel = [
      { label: '(l) Liter', value: 'l' },
      { label: '(gal)Gallon', value: 'gal' },
      { label: '(lb) Pound', value: 'lb' },
      { label: '(kg) Kilogram', value: 'kg' },
      { label: '(oz) Ounce', value: 'oz' },
      { label: '(t) Ton', value: 't' }
    ]

    this.optionsWeight = [
      { label: '(kg) Kilogram', value: 'kg' },
      { label: '(lb) Pound', value: 'lb' },
      { label: '(oz) Ounce', value: 'oz' },
      { label: '(g) Gram', value: 'g' },
      { label: '(mg) Milligram', value: 'mg' },
      { label: '(t) on', value: 't' }
    ]

    this.optionsLength = [
      { label: '(m) Meter', value: 'm' },
      { label: '(cm) Centimeter', value: 'cm' },
      { label: '(mm) Millimeter', value: 'mm' },
      { label: '(in) Inch', value: 'in' },
      { label: '(ft) Foot', value: 'ft' },
      { label: '(ft in) Foot Inch', value: 'ft_in' },
      { label: '(yd) Yard', value: 'yd' },
      { label: '(mi) Mile', value: 'mi' },
      { label: '(nmi) Nautical Mile', value: 'nmi' },
      { label: '(km) Kilometer', value: 'km' }
    ]

    this.optionsVolume = [
      { label: '(l) Liter', value: 'l' },
      { label: '(ml) Milliliter', value: 'ml' },
      { label: '(m³) Cubic Meter', value: 'm³' },
      { label: '(cm³) Cubic Centimeter', value: 'cm³' },
      { label: '(mm³) Cubic Millimeter', value: 'mm³' },
      { label: '(gal) Gallon', value: 'gal' },
      { label: '(qt) Quart', value: 'qt' }
    ]

    this.optionsTemperature = [
      { label: '(°C) Celsius', value: '°C' },
      { label: '(°F) Fahrenheit', value: '°F' },
      { label: '(K) Kelvin', value: 'K' }
    ]

    this.optionsPressure = [
      { label: '(Pa) Pascal', value: 'Pa' },
      { label: '(kPa) Kilopascal', value: 'kPa' },
      { label: '(bar) Bar', value: 'bar' },
      { label: '(atm) Atmosphere', value: 'atm' },
      { label: '(psi) Pound per Square Inch', value: 'psi' }
    ]

    this.optionsSpeed = [
      { label: '(m/s) Meter per Second', value: 'm/s' },
      { label: '(km/h) Kilometer per Hour', value: 'km/h' },
      { label: '(kn) Knot', value: 'kn' },
      { label: '(mph) Mile per Hour', value: 'mph' }
    ]

    this.optionsTime = [
      { label: '(s) Second', value: 's' },
      { label: '(min) Minute', value: 'min' },
      { label: '(h) Hour', value: 'h' },
      { label: '(d) Day', value: 'd' },
      { label: '(wk) Week', value: 'wk' },
      { label: '(mo) Month', value: 'mo' },
      { label: '(yr) Year', value: 'yr' }
    ]
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-25
   * @version 1.0.0
   * 
   * @description 
   *   - Copia o conteúdo para o clipboard.
   */
  private _copyToClipboard(xContent: any): void {
    navigator.clipboard.writeText(xContent).then(() => {
      console.log('Conteúdo copiado para o clipboard');
    }).catch(err => {
      console.error('Erro ao copiar para o clipboard: ', err);
    });
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-03
   * @version 1.0.0
   * 
   * @description
   *   - Converter um numero para o locale desejado
   * 
   * @param xNumber 
   * @param xLocale 
   * @returns 
   */
  public convertNumberToLocale(xNumber: number): string | null {
    const tempLocale = `${this._locale.split('-')[0]}-${this._locale.split('-')[1].toUpperCase()}`;

    if (!isNaN(xNumber) && xNumber !== null) {
      return xNumber.toLocaleString(tempLocale);
    } else if (xNumber !== null) {
      return xNumber.toString();
    } else {
      return null;
    }
  }
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status:  
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa os eventos do Formulário de Conversão de Unidades.
   */
  private _initUnitConversionFormEvents(): void {
    this.formUnitConversion.get('unit_type').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        if (xValue === 'acceleration') {
          this.optionsValueOriginalUnit = this.optionsAcceleration;
          this.optionsValueConvertedUnit = this.optionsAcceleration;
        } else if (xValue === 'length') {
          this.optionsValueOriginalUnit = this.optionsLength;
          this.optionsValueConvertedUnit = this.optionsLength;
        } else if (xValue === 'aircraft_fuel') {
          this.optionsValueOriginalUnit = this.optionsAircraftFuel;
          this.optionsValueConvertedUnit = this.optionsAircraftFuel;
        } else if (xValue === 'weight') {
          this.optionsValueOriginalUnit = this.optionsWeight;
          this.optionsValueConvertedUnit = this.optionsWeight;
        } else if (xValue === 'pressure') {
          this.optionsValueOriginalUnit = this.optionsPressure;
          this.optionsValueConvertedUnit = this.optionsPressure;
        } else if (xValue === 'temperature') {
          this.optionsValueOriginalUnit = this.optionsTemperature;
          this.optionsValueConvertedUnit = this.optionsTemperature;
        } else if (xValue === 'speed') {
          this.optionsValueOriginalUnit = this.optionsSpeed;
          this.optionsValueConvertedUnit = this.optionsSpeed;
        } else if (xValue === 'volume') {
          this.optionsValueOriginalUnit = this.optionsVolume;
          this.optionsValueConvertedUnit = this.optionsVolume;
        } else if (xValue === 'time') {
          this.optionsValueOriginalUnit = this.optionsTime;
          this.optionsValueConvertedUnit = this.optionsTime;
        }

        // A Titulo de estética, opta-se por selecionar o primeiro item da lista de unidades de medida.
        this.formUnitConversion.get('value_original_unit').setValue(this.optionsValueOriginalUnit[0].value, { emitEvent: false }); // Seleciona a primeira unidade de medida.
        this.formUnitConversion.get('value_converted_unit').setValue(null, { emitEvent: false }); // Limpa o campo de unidade de medida convertida.
        this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false }); // Limpa o campo de valor convertido.
      } else {
        this.optionsValueOriginalUnit = [];
        this.optionsValueConvertedUnit = [];
        this.formUnitConversion.get('value_original_unit').setValue(null, { emitEvent: false }); // Limpa o campo de unidade de medida original.
        this.formUnitConversion.get('value_converted_unit').setValue(null, { emitEvent: false }); // Limpa o campo de unidade de medida convertida.
        this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false }); // Limpa o campo de valor convertido.
      }
    });

    this.formUnitConversion.get('value_original').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        const valueOriginalUnit = this.formUnitConversion.get('value_original_unit').value;
        const valueConvertedUnit = this.formUnitConversion.get('value_converted_unit').value;
        const tempUnitType = this.formUnitConversion.get('unit_type').value;

        if ((tempUnitType && tempUnitType !== null) && (valueOriginalUnit && valueOriginalUnit !== null) && (valueConvertedUnit && valueConvertedUnit !== null)) {
          const convertedValue = this._getConvertedValue(tempUnitType, parseFloat(xValue), valueOriginalUnit, valueConvertedUnit);

          this.formUnitConversion.get('value_converted').setValue(this.convertNumberToLocale(convertedValue), { emitEvent: false });
        } else {
          this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false });
        }
      } else {
        this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false });
      }
    });

    this.formUnitConversion.get('value_original_unit').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        const value_original = this.formUnitConversion.get('value_original').value;
        const valueConvertedUnit = this.formUnitConversion.get('value_converted_unit').value;
        const tempUnitType = this.formUnitConversion.get('unit_type').value;

        if ((tempUnitType && tempUnitType !== null) && (value_original && value_original !== null) && (valueConvertedUnit && valueConvertedUnit !== null)) {
          const convertedValue = this._getConvertedValue(tempUnitType, parseFloat(value_original), xValue, valueConvertedUnit);

          this.formUnitConversion.get('value_converted').setValue(convertedValue, { emitEvent: false });
        } else {
          this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false });
        }
      } else {
        this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false });
      }
    });

    this.formUnitConversion.get('value_converted_unit').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        const value_original = this.formUnitConversion.get('value_original').value;
        const valueOriginalUnit = this.formUnitConversion.get('value_original_unit').value;
        const tempUnitType = this.formUnitConversion.get('unit_type').value;

        if ((tempUnitType && tempUnitType !== null) && (value_original && value_original !== null) && (valueOriginalUnit && valueOriginalUnit !== null)) {
          const convertedValue = this._getConvertedValue(tempUnitType, parseFloat(value_original), valueOriginalUnit, xValue);

          this.formUnitConversion.get('value_converted').setValue(parseFloat(convertedValue), { emitEvent: false });
        } else {
          this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false });
        }
      } else {
        this.formUnitConversion.get('value_converted').setValue(null, { emitEvent: false });
      }
    });
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion














 // !! REVISAR O CODIGO ABAIXO-------------------------------

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * 
   * @author GASPAR
   * @date 2024-12-24
   * @version 1.0.0
   * 
   * @description 
   *   - Obtém o valor convertido de acordo com o tipo de unidade.
   */
  private _getConvertedValue(xUnitType: string, xValue: number, xValueOriginalUnit: string, xValueConvertedUnit: string): any {
    // https://www.convertworld.com/pt/

    let convertedValue = null;

    if (xUnitType === 'acceleration') {
      convertedValue = this.convertAcceleration(xValue, xValueOriginalUnit, xValueConvertedUnit);
    } else if (xUnitType === 'length') {
      convertedValue = this.convertLength(xValue, xValueOriginalUnit, xValueConvertedUnit);
    } else if (xUnitType === 'aircraft_fuel') {
      convertedValue = this.convertAircraftFuel(xValue, xValueOriginalUnit, xValueConvertedUnit, 'JetA');
    } else if (xUnitType === 'weight') {
      convertedValue = this.convertWeight(xValue, xValueOriginalUnit, xValueConvertedUnit);
    } else if (xUnitType === 'pressure') {
      convertedValue = this.convertPressure(xValue, xValueOriginalUnit, xValueConvertedUnit);
    } else if (xUnitType === 'temperature') {
      convertedValue = this.convertTemperature(xValue, xValueOriginalUnit, xValueConvertedUnit);
    } else if (xUnitType === 'speed') {
      convertedValue = this.convertSpeed(xValue, xValueOriginalUnit, xValueConvertedUnit);
    } else if (xUnitType === 'volume') {
      convertedValue = this.convertVolume(xValue, xValueOriginalUnit, xValueConvertedUnit);
    } else if (xUnitType === 'time') {
      convertedValue = this.convertTime(xValue, xValueOriginalUnit, xValueConvertedUnit);
    }

    // Máximo 3 casas decimais para exibição do valor convertido (truncado)
    if (convertedValue && convertedValue !== null) {
      if (convertedValue.toString().includes('.')) {
        const decimalPlaces = convertedValue.toString().split('.')[1].length;

        if (decimalPlaces > 3) {
          convertedValue = parseFloat(convertedValue.toFixed(3));
        }
      }
    }

    return convertedValue;
  }


  
  //#endregion

  //#region "|--- UNIT MAPS ---|"
  private unitToMeter: { [key: string]: number } = {
    'm': 1,
    'cm': 0.01,
    'mm': 0.001,
    'in': 0.0254,
    'ft': 0.3048,
    'yd': 0.9144,
    'mi': 1609.34,
    'nmi': 1852,
    'km': 1000
  };

  private unitToKilogram: { [key: string]: number } = {
    'kg': 1,
    'lb': 0.453592,
    'oz': 0.0283495,
    'g': 0.001,
    'mg': 0.000001,
    't': 1000
  };

  // Mapeamento de unidades de temperatura para Celsius
  private unitToCelsius: { [key: string]: (value: number) => number } = {
    '°C': value => value,
    '°F': value => (value - 32) * 5 / 9,
    'K': value => value - 273.15
  };

  // Mapeamento de Celsius para outras unidades de temperatura
  private celsiusToUnit: { [key: string]: (value: number) => number } = {
    '°C': value => value,
    '°F': value => (value * 9 / 5) + 32,
    'K': value => value + 273.15
  };

  // Mapeamento de unidades de pressão para Pascals
  private unitToPascal: { [key: string]: number } = {
    'Pa': 1,
    'kPa': 1000,
    'bar': 100000,
    'atm': 101325,
    'psi': 6894.76
  };

  // Mapeamento de unidades de velocidade para metros por segundo
  private unitToMeterPerSecond: { [key: string]: number } = {
    'm/s': 1,
    'km/h': 0.277778,
    'kn': 0.514444,
    'mph': 0.44704
  };

  // Mapeamento de unidades de tempo para segundos
  private unitToSecond: { [key: string]: number } = {
    's': 1,
    'min': 60,
    'h': 3600,
    'd': 86400,
    'wk': 604800,
    'mo': 2628000, // Aproximadamente 30.44 dias
    'yr': 31536000
  };

  // Mapeamento de unidades de volume para litros
  private unitToLiter: { [key: string]: number } = {
    'l': 1,
    'ml': 0.001,
    'm³': 1000,
    'cm³': 0.001,
    'mm³': 0.000001,
    'gal': 3.78541,
    'qt': 0.946353,
    'lb': 0.453592, // Considerando densidade padrão de água para simplificação]
    'kg': 1, // Considerando densidade padrão de água para simplificação
    'oz': 0.0283495, // Considerando densidade padrão de água para simplificação
    't': 1000 // Considerando densidade padrão de água para simplificação
  };

  // Mapeamento de densidade de combustíveis de aviação (kg/l)
  private fuelDensity: { [key: string]: number } = {
    'AVGAS': 0.721,
    'JetA': 0.804,
    'JetA-1': 0.804,
    'JetB': 0.765
  };
  //#endregion

  //#region "|--- CONVERSION METHODS ---|"
  // Método para converter entre unidades de medida de aceleração
  public convertAcceleration(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToMeterPerSecond[fromUnit] || !this.unitToMeterPerSecond[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para metros por segundo ao quadrado
    const valueInMetersPerSecondSquared = value * this.unitToMeterPerSecond[fromUnit];

    // Converter de metros por segundo ao quadrado para a unidade de destino
    const convertedValue = valueInMetersPerSecondSquared / this.unitToMeterPerSecond[toUnit];

    return convertedValue;
  }

  // Método para converter entre unidades de medida de comprimento
  public convertLength(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToMeter[fromUnit] || !this.unitToMeter[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para metros
    const valueInMeters = value * this.unitToMeter[fromUnit];

    // Converter de metros para a unidade de destino
    const convertedValue = valueInMeters / this.unitToMeter[toUnit];

    return convertedValue;
  }

  // Método para converter entre unidades de medida de peso
  public convertWeight(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToKilogram[fromUnit] || !this.unitToKilogram[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para quilogramas
    const valueInKilograms = value * this.unitToKilogram[fromUnit];

    // Converter de quilogramas para a unidade de destino
    const convertedValue = valueInKilograms / this.unitToKilogram[toUnit];

    return convertedValue;
  }

  // Método para converter entre unidades de medida de temperatura
  convertTemperature(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToCelsius[fromUnit] || !this.celsiusToUnit[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para Celsius
    const valueInCelsius = this.unitToCelsius[fromUnit](value);

    // Converter de Celsius para a unidade de destino
    const convertedValue = this.celsiusToUnit[toUnit](valueInCelsius);

    return convertedValue;
  }

  // Método para converter entre unidades de medida de pressão
  convertPressure(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToPascal[fromUnit] || !this.unitToPascal[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para Pascals
    const valueInPascals = value * this.unitToPascal[fromUnit];

    // Converter de Pascals para a unidade de destino
    const convertedValue = valueInPascals / this.unitToPascal[toUnit];

    return convertedValue;
  }

  // Método para converter entre unidades de medida de velocidade
  convertSpeed(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToMeterPerSecond[fromUnit] || !this.unitToMeterPerSecond[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para metros por segundo
    const valueInMetersPerSecond = value * this.unitToMeterPerSecond[fromUnit];

    // Converter de metros por segundo para a unidade de destino
    const convertedValue = valueInMetersPerSecond / this.unitToMeterPerSecond[toUnit];

    return convertedValue;
  }

  // Método para converter entre unidades de medida de tempo
  convertTime(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToSecond[fromUnit] || !this.unitToSecond[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para segundos
    const valueInSeconds = value * this.unitToSecond[fromUnit];

    // Converter de segundos para a unidade de destino
    const convertedValue = valueInSeconds / this.unitToSecond[toUnit];

    return convertedValue;
  }

  // Método para converter entre unidades de medida de volume
  convertVolume(value: number, fromUnit: string, toUnit: string): number {
    if (!this.unitToLiter[fromUnit] || !this.unitToLiter[toUnit]) {
      throw new Error('Unidade de medida inválida');
    }

    // Converter o valor para litros
    const valueInLiters = value * this.unitToLiter[fromUnit];

    // Converter de litros para a unidade de destino
    const convertedValue = valueInLiters / this.unitToLiter[toUnit];

    return convertedValue;
  }

  // Método para converter entre unidades de medida de combustível de aviação
  convertAircraftFuel(value: number, fromUnit: string, toUnit: string, fuelType: string): number {
    if (!this.unitToLiter[fromUnit] || !this.unitToLiter[toUnit] || !this.fuelDensity[fuelType]) {
      throw new Error('Unidade de medida ou tipo de combustível inválido');
    }

    // Converter o valor para litros
    let valueInLiters: number;

    if (fromUnit === 'lb' || fromUnit === 'kg' || fromUnit === 'oz' || fromUnit === 't') {
      // Converter de massa para volume usando a densidade do combustível
      const valueInKg = value * this.unitToLiter[fromUnit];
      valueInLiters = valueInKg / this.fuelDensity[fuelType];
    } else {
      valueInLiters = value * this.unitToLiter[fromUnit];
    }

    // Converter de litros para a unidade de destino
    let convertedValue: number;
    if (toUnit === 'lb' || toUnit === 'kg' || toUnit === 'oz' || toUnit === 't') {
      // Converter de volume para massa usando a densidade do combustível
      const valueInKg = valueInLiters * this.fuelDensity[fuelType];
      convertedValue = valueInKg / this.unitToLiter[toUnit];
    } else {
      convertedValue = valueInLiters / this.unitToLiter[toUnit];
    }

    return convertedValue;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-12-25
   * @version 1.0.0
   * 
   * @description 
   *   - Evento de clique nos botões da barra de ferramentas do diálogo de conversão de unidades.
   */
  public onClickToolbarDialogUnitConversion(xAction: any): void {
    if (xAction === ACTION_TYPE.EXIT) {
      this.actionUnitConversionDialog.emit(ACTION_TYPE.EXIT);
      this.formUnitConversion.reset();
    } else if (xAction === ACTION_TYPE.COPY) {
      this._copyToClipboard(this.formUnitConversion.controls.value_converted.value);
      this.actionUnitConversionDialog.emit(ACTION_TYPE.COPIED_TO_CLIPBOARD);
    } else if (xAction === ACTION_TYPE.SAVE) {
      if (this.bolHasControl) {
        this.controlContent?.setValue(this.formUnitConversion.controls.value_converted.value);
        this.actionUnitConversionDialog.emit(ACTION_TYPE.SAVE);
      }

      if (this.bolHasModel) {
        if (this.modelContent) {
          this.actionReturnModeValueSave.emit({
            action: ACTION_TYPE.SAVE,
            value: this.formUnitConversion.controls.value_converted.value
          });

          this.actionUnitConversionDialog.emit(ACTION_TYPE.SAVE);
        }
      }
    }
  }
  //#endregion
}
