//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder } from '@angular/forms';
//#endregion

export function initPaxForm(): any {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        fk_pax_data_id: [null],
        name_data: objFormBuilder.group({
            first_name: [null],
            last_name: [null],
            full_name: [null],
        }),
        birthday_date_utc: [null],
        gender: [null],
        nationality: [null],
        document_data: objFormBuilder.group({
            document_type: [null],
            document_in: [null]
        }),
        complementary_data: objFormBuilder.group({
            weight: [null],
            weight_unit: ["kg"],
            emergency_data: objFormBuilder.group({
                ddi: ["+55"],
                ddd: [null],
                number: [null],
                normalized: [null],
                masked: [null]
            }),
            contact_full_name:[null]
        })
    });
}