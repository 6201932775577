//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** FORM ***** //
import { initCrewAircraftForm } from '../../../../../form-init/crew-aircraft-form';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

// ***** CLASSES ***** //
import { AviationToolsHandlerClass } from '../../../../../../../_classes/AviationToolsHandlerClass';
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
import { IOutputAction } from '../../../../../../../../_library/interfaces/IOutputAction';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCrewRole } from '../../../../../../../../_library/static_options/staticOptionsCrewRole';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-aircraft-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './tab-aircraft-data.component.html'
})
export class TabAircraftDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;

  @Input() set allAircraftData(xValue: any) {
    if (xValue && xValue !== null) {
      this.listAircraftData = xValue;
    }
  }

  @Input() set allOwnersOperatorsData(xValue: any) {
    if (xValue && xValue !== null) {
      this.listOwnersOperatorsData = xValue;
    }
  }

  @Input() set allOptionsAircraftData(xValue: any) {
    if (xValue && xValue !== null) {
      this.optionsAircraftData = xValue;
    }
  }
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionCrewAircraft = new EventEmitter<IOutputAction>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;

  // ***** CLASSES ***** //
  public classAviationTools!: any;
  public classInternationalization!: any;
  public classLocalMethods!: any;
  public classModuleMethods!: any;

  // ***** SHOW DIALOG ***** //  
  public showDialogCrewAircraft!: any;

  // ***** FORM ***** //  
  public formCrewAircraft!: any;

  // ***** LIST ***** //
  public listOwnersOperatorsData: any[] = [];
  public listAircraftData: any[] = [];

  // ***** OPTIONS ***** //
  public optionsAircraftData: IDropDownOptionsIcon[] = [];
  public optionsCrewRole!: IDropDownOptionsBasic[];
  public optionsOperatorData: IDropDownOptionsBasic[] = [];
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa o formulário de Peso e Balanceamento.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;

    this.showDialogCrewAircraft = false;

    this.classAviationTools = AviationToolsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.optionsCrewRole = staticOptionsCrewRole;
  }

  private _generateOperatorsOptions(xAircraftId: string): void {
    const currentAircraftData = this.listAircraftData.find((xItem: any) => xItem.aircraft_data_id === xAircraftId);

    // Limpa os dados que serão preenchidos...
    this.optionsOperatorData = []; // Sempre Limpar a Lista de Proprietários e Operadores
    this.formCrewAircraft.controls.fk_aircraft_data.controls.aircraft_mark.setValue(null);
    this.formCrewAircraft.controls.fk_aircraft_data.controls.ntbs_aircraft_type.setValue(null);
    this.formCrewAircraft.controls.fk_owner_operator_data.controls.fk_owner_operator_data_id.setValue(null);
    this.formCrewAircraft.controls.fk_owner_operator_data.controls.ntbs_operator_full_name.setValue(null);

    // Preenche os dados do formulário...
    this.formCrewAircraft.controls.fk_aircraft_data.controls.aircraft_mark.setValue(currentAircraftData.register_data.aircraft_mark);
    this.formCrewAircraft.controls.fk_aircraft_data.controls.ntbs_aircraft_type.setValue(currentAircraftData.register_data.aircraft_type);

    for (let i = 0; i < currentAircraftData.fk_owner_operator_list.length; i++) {
      if (currentAircraftData.fk_owner_operator_list[i].owner_operator_type === "both" || currentAircraftData.fk_owner_operator_list[i].owner_operator_type === "operator") {
        const currentOwnerOperatorData = this.listOwnersOperatorsData.find((xItem: any) => xItem.owner_operator_data_id === currentAircraftData.fk_owner_operator_list[i].fk_owner_operator_data_id);

        this.optionsOperatorData.push({
          label: currentOwnerOperatorData.full_name,
          value: currentOwnerOperatorData.owner_operator_data_id
        });
      }
    }

    if (this.optionsOperatorData.length === 1) {
      this.formCrewAircraft.controls.fk_owner_operator_data.controls.fk_owner_operator_data_id.setValue(this.optionsOperatorData[0].value);
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-22
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa os eventos do Formulário de Dados da Aeronave (formAircraftData).
   */
  private _initCrewAircraftFormEvents(): void {
    this.formCrewAircraft.get('fk_aircraft_data.fk_aircraft_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        this._generateOperatorsOptions(xValue);
      } else {
        this.optionsOperatorData = [];
      }
    });

    this.formCrewAircraft.get('fk_owner_operator_data.fk_owner_operator_data_id').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue !== null) {
        const currentOwnerOperatorData = this.listOwnersOperatorsData.find((xItem: any) => xItem.owner_operator_data_id === xValue);

        this.formCrewAircraft.controls.fk_owner_operator_data.controls.ntbs_operator_full_name.setValue(currentOwnerOperatorData.full_name);
      }
    });
  }
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de Cadastro de Tripulante para Aeronave.
   * 
   * @param xAction: string
   */
  public onClickTableCrewAircraftData(xAction: string): void {
    if (xAction === ACTION_TYPE.ADD_ITEM) {
      this.showDialogCrewAircraft = true;

      this.formCrewAircraft = null;
      this.formCrewAircraft = initCrewAircraftForm();

      this._initCrewAircraftFormEvents();
    }
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-12-01
  * @version 1.0.0
  * 
  * @description  Fecha o Dialog de Cadastro de Aeronave.
  * 
  * @param xAction: string
  */
  public onClickToolbarDialogCrewAircraft(xAction: string): void {
    if (xAction === ACTION_TYPE.SAVE) {
      const tempCrewAircraftId = this.classLocalMethods.generateUuid();
     
      this.formCrewAircraft.controls.crew_aircraft_id.setValue(tempCrewAircraftId);

      if (this.formCrewAircraft.invalid) { // Verifica se o formulário está válido...
        const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formCrewAircraft, ModuleMethodsHandlerClass.getFunctionFieldName());

        this._messageService.add({
          severity: 'error',
          summary: 'Salvar Registro Tripulante para Aeronave',
          detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      } else {
        this.showDialogCrewAircraft = false;

        // Verificar se o Tripulante já está cadastrado na Aeronave com o mesmo papel...
        const tempCrewRole = this.formCrewAircraft.controls.crew_role.value;
        const tempAircraftDataId = this.formCrewAircraft.controls.fk_aircraft_data.controls.fk_aircraft_data_id.value;

        if (this.formComponent.controls.fk_aircraft_list.controls.some((xItem: any) => xItem.controls.crew_role.value === tempCrewRole && xItem.controls.fk_aircraft_data.controls.fk_aircraft_data_id.value === tempAircraftDataId)) {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Registro Tripulante para Aeronave',
            detail: `O Tripulante já está cadastrado na Aeronave com o mesma função.`,
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        } else {
          this.formComponent.controls.fk_aircraft_list.controls.push(this.formCrewAircraft); // É um array de objetos (formCrewAircraft) que é adicionado ao array de objetos (aircraft) do formulário principal.  
          this.formCrewAircraft = null;

          this.actionCrewAircraft.emit({ action: ACTION_TYPE.SAVE, id: tempCrewAircraftId });
        }
      }
    } else if (xAction === ACTION_TYPE.UPDATE) {
      this.showDialogCrewAircraft = false;

      // RECUPERA O ÍNDICE DO ARRAY DE AERONAVES
      const tempIndexCrewAircraft = this.formComponent.controls.fk_aircraft_list.controls.findIndex((xItem: any) => xItem.controls.crew_aircraft_id.value === this.formCrewAircraft.controls.crew_aircraft_id.value);

      this.formComponent.controls.fk_aircraft_list.controls[tempIndexCrewAircraft] = (this.formCrewAircraft);
      
      this.actionCrewAircraft.emit({ action: ACTION_TYPE.UPDATE, id: this.formCrewAircraft.controls.crew_aircraft_id.value });
      
      this.formCrewAircraft = null;
    } else if (xAction === ACTION_TYPE.EXIT) {
      this.showDialogCrewAircraft = false;
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-01
   * @version 1.0.0
   * 
   * @description 
   *   - Abre o Dialog de Cadastro de Tripulante para Aeronave.
   * 
   * @param xAction: string
   * @param xIndexArray: number
   */
  public onClickItemTableAircraft(xAction: string, xIndexArray: number): void {
    if (xAction === ACTION_TYPE.EDIT) {
      const tempAircraftId = this.formComponent.controls.fk_aircraft_list.controls[xIndexArray].controls.fk_aircraft_data.controls.fk_aircraft_data_id.value;
      
      this.showDialogCrewAircraft = true;
      this.formCrewAircraft = this.formComponent.controls.fk_aircraft_list.controls[xIndexArray];

      // !! TEM QUE RECUPERAR O ID DA AERONAVE COM BASE NA MATRICULA DA AERONAVE, PARA GERAR A OPÇÃO DE OPERADORES....
      this._generateOperatorsOptions(tempAircraftId);
    } else if (xAction === ACTION_TYPE.DELETE) {
      this._confirmationService.confirm({
        message: "Deseja realmente excluir o registro?",
        header: "Confirmação de Exclusão",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        acceptLabel: this.classInternationalization.getTranslation('btn_Delete').toUpperCase(),
        rejectLabel: this.classInternationalization.getTranslation('btn_Cancel').toUpperCase(),
        acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
        rejectButtonStyleClass: "cls-component-button-pattern-default",
        key: "crew_aircraft_conf_del",
        accept: () => {
          const tempAircraftId = this.formComponent.controls.fk_aircraft_list.controls[xIndexArray].controls.fk_aircraft_data.controls.fk_aircraft_data_id.value;
          const tempCrewAircraftId = this.formComponent.controls.fk_aircraft_list.controls[xIndexArray].controls.crew_aircraft_id.value;

          // Estou excluindo o item do array de objetos (aircraft) do formulário principal: CREW.
          this.formComponent.controls.fk_aircraft_list.controls.splice(xIndexArray, 1);
         
          this.actionCrewAircraft.emit({ action: ACTION_TYPE.DELETE, id: [tempCrewAircraftId, tempAircraftId] });

          this._messageService.add({
            severity: 'success',
            summary: "Exclusão de Registro",
            detail: "O registro foi excluído com sucesso.",
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        },
        reject: () => {
          this._messageService.add({
            severity: 'warn',
            summary: "Exclusão de Registro",
            detail: "A exclusão do registro foi cancelada.",
            key: this.currentSettings.TOAST_KEY,
            life: this.currentSettings.TOAST_LIFE
          });
        }
      });
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"

  //#endregion
}
