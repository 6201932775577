//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormArray, FormGroup, ValidationErrors, FormBuilder } from '@angular/forms'

// ***** NPM ***** //
import { v4 as uuidv4 } from 'uuid';
import { NIL as NIL_UUID } from 'uuid';
//#endregion

export class LocalMethodsHandlerClass {
    //#region "|--- PUBLIC METHODS ---|"
    /**
     * @author GASPAR
     * @date 2024-10-07
     * @version 1.0.0 
     * 
     * @description Método que converte uma cor hexadecimal para RGBA.
     * 
     * @param hex 
     * @param alpha 
     * @returns 
     */
    static hexToRgba(hex: string, alpha: number): string {
        // Remove o caractere '#' se estiver presente
        hex = hex.replace(/^#/, '');

        // Converte a string hexadecimal para valores RGB
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        // Retorna a cor no formato RGBA
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }


    /**
     * @author GASPAR
     * @date 2024-09-24
     * @version 1.0.0 
     * 
     * @description Método que gera um UUID (Universally Unique Identifier) v4.
     * 
     * @returns 
     */
    static generateUuid(): string {
        // Retorna uma String com 36 posições = '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'.
        return uuidv4();
    }

    /**
     * @author GASPAR
     * @date 2024-09-24
     * @version 1.0.0 
     * 
     * @description Método que gera um UUID (Universally Unique Identifier) v4, com uma sequencia de zeros.
     * 
     * @returns 
     */
    static generateUuidNil(): string {
        // Retorna uma String com 36 posições = '00000000-0000-0000-0000-000000000000'.
        return NIL_UUID;
    }

    /**
     * @author GASPAR
     * @date 2024-10-064
     * @version 1.0.0 
     * 
     * @description Método que gera uma string aleatória.
     * 
     * @param length 
     * @param type 
     * @returns 
     */
    static generateRandomString(length: number): string {
        let result = '';
        let characters = '';

        characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    /**
     * @author GASPAR
     * @date 2024-09-17
     * @version 1.0.0 
     * 
     * @description Método que retorna os erros com o nome do campo e o Tipo de Erro.
     * 
     * @param xForm 
     * @param xFunctionGetFieldName 
     * @returns 
     */
    static handlerFormFieldsErrors(xForm: FormGroup | FormArray, xFunctionGetFieldName: any): any {
        const errosFormRequest = this._getFormFieldsErrors(xForm);
        const fieldsErros: any = [];

        let tmpData = '- campos não identificados.';

        errosFormRequest.forEach((xItem: any) => {
            fieldsErros.push(xFunctionGetFieldName(xItem.controlName));
        });

        // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
        if (Array.isArray(fieldsErros)) {
            tmpData = fieldsErros.join('\n - ');
        }

        return tmpData;
    }

    /**
     * @author GASPAR
     * @date 2024-09-12
     * @version 1.0.0 
     * 
     * @description Método que gera uma cor hexadecimal aleatória.
     * 
     * @returns {string} Retorna uma cor hexadecimal aleatória.
     */
    static generateHexColor(): string {
        const tmpLetters = "0123456789ABCDEF";
        let tmpColor = '#';

        for (let i = 0; i < 6; i++) {
            tmpColor += tmpLetters[(Math.floor(Math.random() * 16))];
        }

        return tmpColor;
    }

    /**
     * @author GASPAR
     * @date 2024-09-22
     * @version 1.0.0
     * 
     * @description Método que recebe uma matrícula de aeronave e retorna o código do país com 2 letras.
     * 
     * @param {string} registration - Matrícula da aeronave.
     * @returns {string} Código do país com 2 letras.
     */
    static getCountryCodeFromRegistration(registration: string): string {
        // Tenta encontrar o prefixo mais longo possível
        for (let i = 4; i > 0; i--) {
            const prefix = registration.substring(0, i).toUpperCase();
            if (this.icaoCountryCodes[prefix]) {
                return this.icaoCountryCodes[prefix];
            }
        }
        // Retorna uma string vazia se não encontrado
        return '';
    }

    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @param weight 
     * @param currentUnit 
     * @param desiredUnit 
     * @returns 
     */
    static convertWeight(xWeight: number, xCurrentUnit: string, xDesiredUnit: string): number {
        let weightInKg: number;

        // Convert current weight to kilograms
        switch (xCurrentUnit) {
            case 'kg':
                weightInKg = xWeight;
                break;
            case 'lb':
                weightInKg = xWeight * 0.453592;
                break;
            case 'g':
                weightInKg = xWeight / 1000;
                break;
            default:
                throw new Error('Unidade de peso atual não suportada');
        }

        // Convert weight from kilograms to desired unit
        let convertedWeight: number;
        switch (xDesiredUnit) {
            case 'kg':
                convertedWeight = weightInKg;
                break;
            case 'lb':
                convertedWeight = weightInKg / 0.453592;
                break;
            case 'g':
                convertedWeight = weightInKg * 1000;
                break;
            default:
                throw new Error('Unidade de peso desejada não suportada');
        }

        return convertedWeight;
    }

    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description Método que converte o combustível de uma unidade para outra.
     * 
     * @param xFuel 
     * @param xCurrentUnit 
     * @param xDesiredUnit 
     * @returns 
     */
    static convertFuelGeneric(xFuel: number, xCurrentUnit: 'l' | 'gal' | 'kg' | 'lb', xDesiredUnit: 'l' | 'gal' | 'kg' | 'lb'): number {
        type FuelUnit = 'l' | 'gal' | 'kg' | 'lb';

        const conversionRates: { [key in FuelUnit]: { [key in FuelUnit]: number } } = {
            l: {
                l: 1,
                gal: 0.264172,
                kg: 0.8, // assuming density of aviation fuel is 0.8 kg/L
                lb: 1.7637 // 1 liter of aviation fuel is approximately 1.7637 pounds
            },
            gal: {
                l: 3.78541,
                gal: 1,
                kg: 3.2, // 1 gallon of aviation fuel is approximately 3.2 kg
                lb: 7.05 // 1 gallon of aviation fuel is approximately 7.05 pounds
            },
            kg: {
                l: 1.25, // 1 kg of aviation fuel is approximately 1.25 liters
                gal: 0.3125, // 1 kg of aviation fuel is approximately 0.3125 gallons
                kg: 1,
                lb: 2.20462
            },
            lb: {
                l: 0.56699, // 1 pound of aviation fuel is approximately 0.56699 liters
                gal: 0.141255, // 1 pound of aviation fuel is approximately 0.141255 gallons
                kg: 0.453592,
                lb: 1
            }
        };

        if (xCurrentUnit === xDesiredUnit) {
            return parseFloat(xFuel.toFixed(2));
        }

        const conversionRate = conversionRates[xCurrentUnit][xDesiredUnit];
        const convertedFuel = xFuel * conversionRate;

        return parseFloat(convertedFuel.toFixed(2));
    }

    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description Método que converte o combustível de uma unidade para outra.
     * 
     * @param fuel 
     * @param currentUnit 
     * @param desiredUnit 
     * @param fuelType 
     * @returns 
     */
    static convertFuelSpecific(xFuel: number, xCurrentUnit: string, xDesiredUnit: string, xFuelType: 'JetA' | 'Avgas'): number {
        let fuelInL: number;

        // Densidades em kg/L
        const fuelDensities = {
            JetA: 0.8,
            Avgas: 0.72
        };

        // Verificar se o tipo de combustível é suportado
        if (!fuelDensities[xFuelType]) {
            throw new Error('Tipo de combustível não suportado');
        }

        // Convert current fuel to liters
        switch (xCurrentUnit) {
            case 'l':
                fuelInL = xFuel;
                break;
            case 'gal':
                fuelInL = xFuel * 3.78541;
                break;
            case 'kg':
                fuelInL = xFuel / fuelDensities[xFuelType];
                break;
            case 'lb':
                fuelInL = (xFuel / 2.20462) / fuelDensities[xFuelType];
                break;
            default:
                throw new Error('Unidade de combustível atual não suportada');
        }

        let convertedFuel: number;

        // Convert liters to desired unit
        switch (xDesiredUnit) {
            case 'l':
                convertedFuel = fuelInL;
                break;
            case 'gal':
                convertedFuel = fuelInL / 3.78541;
                break;
            case 'kg':
                convertedFuel = fuelInL * fuelDensities[xFuelType];
                break;
            case 'lb':
                convertedFuel = fuelInL * fuelDensities[xFuelType] * 2.20462;
                break;
            default:
                throw new Error('Unidade de combustível desejada não suportada');
        }

        return parseFloat(convertedFuel.toFixed(2));
    }

    /**
     * @author GASPAR
     * @date 2024-10-05
     * @version 1.0.0
     * 
     * @description Método que converte um número no formato americano (1,000.00) para o formato brasileiro (1.000,00).
     * 
     * @param usNumber 
     * @returns 
     */
    static convertNumberToPtBr(usNumber: string | number): string {
        // Convert number to string if necessary
        const usNumberStr = typeof usNumber === 'number' ? usNumber.toString() : usNumber;

        // Remove commas (thousand separators)
        const numberWithoutCommas = usNumberStr.replace(/,/g, '');

        // Replace dot (decimal separator) with comma
        const numberWithComma = numberWithoutCommas.replace(/\./g, ',');

        // Add thousand separators (dots) in pt-BR format
        const parts = numberWithComma.split(',');
        const integerPart = parts[0];
        const decimalPart = parts.length > 1 ? ',' + parts[1] : '';

        let ptBrNumber = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Append decimal part if it exists
        if (decimalPart) {
            ptBrNumber += decimalPart;
        }

        return ptBrNumber;
    }

    static calculateFuelConsumption(
        flightTime: number, // Em minutos
        consumptionRate: number, 
        consumptionUnit: string, 
        desiredUnit: string, 
        fuelType: string
    ): number {
        // Converter flightTime de minutos para horas
        const flightTimeInHours = flightTime / 60;

        const conversionFactors: { [key: string]: number } = {
            'lb_to_kg': 0.453592,
            'kg_to_lb': 2.20462,
            'l_to_gal': 0.264172,
            'gal_to_l': 3.78541
        };

        const fuelDensity: { [key: string]: number } = {
            'JetA_lb_per_gal': 6.7,
            'Avgas_lb_per_gal': 6.0,
            'JetA_kg_per_l': 0.8,
            'Avgas_kg_per_l': 0.72
        };

        let rateInDesiredUnit: number = consumptionRate;

        // Convert consumption rate to desired unit
        if (consumptionUnit !== desiredUnit) {
            if (consumptionUnit === 'lb/h' && desiredUnit === 'kg/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['lb_to_kg'];
            } else if (consumptionUnit === 'kg/h' && desiredUnit === 'lb/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['kg_to_lb'];
            } else if (consumptionUnit === 'l/h' && desiredUnit === 'gal/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['l_to_gal'];
            } else if (consumptionUnit === 'gal/h' && desiredUnit === 'l/h') {
                rateInDesiredUnit = consumptionRate * conversionFactors['gal_to_l'];
            }
        }

        // Adjust for fuel type if necessary
        if (fuelType === 'jeta' && desiredUnit === 'lb/h') {
            rateInDesiredUnit *= fuelDensity['JetA_lb_per_gal'];
        } else if (fuelType === 'avgas' && desiredUnit === 'lb/h') {
            rateInDesiredUnit *= fuelDensity['Avgas_lb_per_gal'];
        } else if (fuelType === 'jeta' && desiredUnit === 'kg/h') {
            rateInDesiredUnit *= fuelDensity['JetA_kg_per_l'];
        } else if (fuelType === 'avgas' && desiredUnit === 'kg/h') {
            rateInDesiredUnit *= fuelDensity['Avgas_kg_per_l'];
        }

        // Calcular o consumo total de combustível
        const totalFuelConsumption = rateInDesiredUnit * flightTimeInHours;

        return totalFuelConsumption;
    }

    static convertTimeToMinutes(time: string): number {
        const [hours, minutes] = time.split(':').map(Number);
        return (hours * 60) + minutes;
    }
    //#endregion

    //#region "|--- PRIVATE METHODS ---|"
    /**
     * @author GASPAR
     * @date 2024-09-17
     * @version 1.0.0
     * 
     * @description Dicionário de códigos de países ICAO.
     */
    private static icaoCountryCodes: { [key: string]: string } = {
        // ISO 3166-1 alpha2
        // https://www.dadosmundiais.com/codigos-de-pais.php#google_vignette
        // América do Norte
        'N': 'US', // Estados Unidos
        'C': 'CA', // Canadá
        'XA': 'MX', // México
        'XB': 'MX', // México
        'XC': 'MX', // México

        // América Central
        'HP': 'PA', // Panamá
        'TI': 'CR', // Costa Rica
        'YS': 'SV', // El Salvador
        'HR': 'HN', // Honduras
        'TG': 'GT', // Guatemala
        'YN': 'NI', // Nicarágua
        'V3': 'BZ', // Belize

        // Caribe
        '8P': 'BB', // Barbados
        'VP-B': 'BM', // Bermudas
        'J8': 'VC', // São Vicente e Granadinas
        'VP-V': 'VC', // São Vicente e Granadinas
        'V2': 'AG', // Antígua e Barbuda
        'PJ': 'AN', // Antilhas Neerlandesas
        'HZ': 'AN', // Antilhas Neerlandesas
        'HI': 'DO', // República Dominicana
        '9Y': 'TT', // Trinidad e Tobago

        // América do Sul
        'LV': 'AR', // Argentina
        'CP': 'BO', // Bolívia
        'PP': 'BR', // Brasil
        'PR': 'BR', // Brasil
        'PT': 'BR', // Brasil
        'PU': 'BR', // Brasil
        'CC': 'CL', // Chile
        'HK': 'CO', // Colômbia
        'HC': 'EC', // Equador
        'OB': 'PE', // Peru
        'YV': 'VE', // Venezuela
        'CX': 'UY', // Uruguai
        'ZP': 'PY', // Paraguai

        // Oceania
        'VH': 'AU', // Austrália
        'ZK': 'NZ', // Nova Zelândia
    };

    /**
     * @author GASPAR
     * @date 2024-09-17
     * @version 1.0.0
     * 
     * @description Método que retorna os erros com o nome do campo e o Tipo de Erro.
     * 
     * @param xForm 
     * @param xParentField 
     * @returns 
     */
    private static _getFormFieldsErrors(xForm: FormGroup | FormArray, xParentField = ""): any[] {
        let errorsForm: any[] = [];
        let tempFieldName = "";

        Object.keys(xForm.controls).forEach(field => {
            const control = xForm.get(field);

            tempFieldName = `${xParentField}${field}`

            if (control instanceof FormGroup || control instanceof FormArray) {
                errorsForm = errorsForm.concat(this._getFormFieldsErrors(control, `${tempFieldName}.`)); //FAZ RECURSIVIDADES, CASO SEJA UM ARRAY DE CONTROLES.

                return;
            } else {
                // FORCAR OS CAMPOS A MOSTRAREM OS ERROS: TOUCHED & DIRTY:
                if (control) {
                    control.markAsTouched();
                    control.markAsDirty();
                }
            }

            const controlErrors = control ? control.errors as ValidationErrors | null : null;

            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errorsForm.push({
                        controlName: tempFieldName,
                        errorName: keyError,
                        errorValue: controlErrors[keyError]
                    });
                });
            }
        });

        // Remove os Itens Duplicados.
        errorsForm = errorsForm.filter((xValue: any, xIndex: any, xSelf: any) => xSelf.findIndex((t: any) => {
            return t.controlName === xValue.controlName;
        }) === xIndex);

        return errorsForm;

    }
    //#endregion
}




