//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-10-12
 * @version 1.0.0
 * 
 * @description Opções estáticas de tipos de Documentos.
 */
export const staticOptionsDocumentsType: IDropDownOptionsBasic[] = [   
    { label: 'IDENTIDADE (RG)', value: 'identity' },
    { label: 'CPF', value: 'cpf' },
    { label: 'PASSAPORTE', value: 'passport' },
    { label: 'CARTEIRA MOTORISTA', value: 'cnh' },
    { label: 'NÃO INFORMADO', value: 'not_informed' }
]