//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../_classes/InternationalizationHandlerClass';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { DIALOG_WAITING_TYPE } from '../../../../_library/definitions/DialogWaitingType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-app-platform-waiting-dialog',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './waiting-dialog.component.html'
})
export class WaitingDialogComponent implements OnInit, OnDestroy {
  //#region "|--- INPUTS ---|"    
  @Input() extraData!: any[];

  @Input() set typeWaitingDialog(xValue: string) {
    if (xValue != undefined && xValue != null && xValue != '') {
      this.typeLayout = xValue;
    }

    this.typeLayout = xValue ?? DIALOG_WAITING_TYPE.DEFAULT;
    this.dialogWaitingType = DIALOG_WAITING_TYPE;

    this._setMessageToShow();
  }

  @Input() set showWaitingDialog(xValue: boolean) {
    if (xValue != undefined) {
      this.isToShowDialog = xValue ?? false;

      if (this.isToShowDialog) {
        this.bolShowButtonClose = false;

        this._startCounter();
      }
    }
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionCloseWaitingDialog = new EventEmitter<boolean>();
  //#endregion


  //region "|--- PROPERTIES ---|"
  public isToShowDialog!: boolean;
  public currentSetting!: any;
  public typeLayout!: string
  public dialogWaitingType!: any;
  public bolShowButtonClose!: boolean;
  public txtMessage!: string;
  public classAnimationLoading!: string;

  // ***** CLASSES ***** //
  public classInternationalization!: any;

  // ***** TIMER ***** //
  public counter!: number;
  public formattedTime!: string;
  private intervalId!: any; // Variable to store the interval id when the timer is running.
  //#endregion

  constructor() { }

  // @status:
  ngOnInit(): void {
    this._initVariables();
  }

  // @status:
  ngOnDestroy(): void {
    this.bolShowButtonClose = false;

    this._stopCounter(); // Stop the timer when the component is destroyed.
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.typeLayout = this.typeWaitingDialog ?? 'default';
    this.currentSetting = settingConfig;

    this.classInternationalization = InternationalizationHandler;

    // ***** TIMER ***** //
    this.counter = 0;
    this.formattedTime = '00:00:00';
    this.intervalId;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description 
   *   - Inicia o contador do tempo.
   */
  private _startCounter(): void {
    this.counter = 0;

    this._updateFormattedTime();

    this.intervalId = setInterval(() => {
      this.counter++;
      this._updateFormattedTime();

      if (this.counter >= this.currentSetting.WAITING_DIALOG_CLOSE_BUTTON) {
        this.bolShowButtonClose = true;
      }
    }, 1000);
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description 
   *   - Atualiza o tempo formatado.
   */
  private _updateFormattedTime(): void {
    const hours = `${Math.floor(this.counter / 3600)}`.padStart(2, '0');
    const minutes = `${Math.floor((this.counter % 3600) / 60)}`.padStart(2, '0');
    const seconds = `${this.counter % 60}`.padStart(2, '0');

    this.formattedTime = `${hours}:${minutes}:${seconds}`;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description 
   *   - Interrompe o contador do tempo.
   */
  private _stopCounter(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description 
   *   - Define a mensagem a ser exibida.
   */
  private _setMessageToShow(): void {
    if (this.classInternationalization === undefined) {
      this.classInternationalization = InternationalizationHandler;
    }

    if (this.typeLayout) {
      if (this.typeLayout === this.dialogWaitingType.DEFAULT) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_ProcessingIsRunning');
        this.classAnimationLoading = "cls-animation-loading-spinner-01";
      } else if (this.typeLayout === this.dialogWaitingType.LOADING_DATA) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_LoadingData');
        this.classAnimationLoading = "cls-animation-loading-shapes-01";
      } else if (this.typeLayout === this.dialogWaitingType.ANAC_RAB) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_LoadingAnacBr');
        this.classAnimationLoading = "cls-animation-loading-spinner-01";
      } else if (this.typeLayout === this.dialogWaitingType.AIRCRAFT_DATA_LIST) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_LoadingAircraftData');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      } else if (this.typeLayout === this.dialogWaitingType.AIRCRAFT_DATA_LOAD) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_LoadingAircraftList');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      } else if (this.typeLayout === this.dialogWaitingType.AIRCRAFT_DATA_SAVE) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_AircraftDataSaving');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      } else if (this.typeLayout === this.dialogWaitingType.AIRCRAFT_DATA_UPDATE) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_AircraftDataUpdating');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      } else if (this.typeLayout === this.dialogWaitingType.WEIGHT_BALANCE_DATA_SHEET_SAVE) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_WeighBalanceDataSheetSaving');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      } else if (this.typeLayout === this.dialogWaitingType.CREW_DATA_SAVE) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_CrewDataSaving');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      } else if (this.typeLayout === this.dialogWaitingType.PAX_DATA_LIST) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_LoadingPaxData');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      } else if (this.typeLayout === this.dialogWaitingType.PAX_DATA_LOAD) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_LoadingPaxList');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      }else if (this.typeLayout === this.dialogWaitingType.FLIGHT_DATA_LIST) {
        this.txtMessage = this.classInternationalization.getTranslation('msg_LoadingFlightRequestData');
        this.classAnimationLoading = "cls-animation-loading-line-01";
      }
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-17
   * @version 1.0.0
   * 
   * @description
   *   - Fecha a janela de espera.
   */
  public onClickCloseWaitingDialog(): void {
    this.bolShowButtonClose = true;

    this.actionCloseWaitingDialog.emit(false);
  }
  //#endregion
}