//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { FormBuilder, Validators } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass';

// ***** DATA GROUP ***** //
import { _pbnDataGroup } from './_pbn-data-group';
import { _radioNavigationDataGroup } from './_radio-navigation-data-group';
import { _ssrDataGroup } from './_ssr-data-group';
import { _supplementaryDataGroup } from './_supplementary-data-group';
//#endregion

//#region "|--- IMPORT INTERFACE ---|"
import { IFkAircraftClientList } from '../../../../_library/interfaces/IFkAircraftClientList';
import { IFkAircraftFboList } from '../../../../_library/interfaces/IFkAircraftFboList';
import { IFkAircraftOwnerOperatorList } from '../../../../_library/interfaces/IFkAircraftOwnerOperatorList';
import { IFkAircraftWeightBalanceSheetList } from '../../../../_library/interfaces/IFkAircraftWeightBalanceSheetList';
import { IFkAircraftCrewList } from '../../../../_library/interfaces/IFkAircraftCrewList';
import { IFkAircraftPaxList } from '../../../../_library/interfaces/IFkAircraftPaxList';
import { IFkAircraftNotebookList } from '../../../../_library/interfaces/IFkAircraftNotebookList';
import { IFkAircraftSharingList } from '../../../../_library/interfaces/IFkAircraftSharingList';
import { IFkAircraftCoastList } from '../../../../_library/interfaces/IFkAircraftCoastList';
import { IFkAircraftDocumentList } from '../../../../_library/interfaces/IFkAircraftDocumentList';
import { IFkAircraftMaintenanceList } from '../../../../_library/interfaces/IFkAircraftMaintenanceList';
//#endregion

/**
 * @status:
 * @author GASPAR
 * @date 2024-12-14
 * @version 1.0.0
 * 
 * @description:
 *   - Inicializa o Formulário AircraftDataForm, retorna um objeto FormGroup com os campos do formulário.
 * 
 * @returns 
 */
export function initAircraftDataForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        aircraft_data_id: [null],
        avatar_image: [
            settingConfig.NO_IMAGE_AVAILABLE,
            Validators.required
        ],
        avatar_color: [
            LocalMethodsHandlerClass.generateHexColor(),
            Validators.required
        ],
        register_data: objFormBuilder.group({            
            aircraft_mark: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(10),
                    Validators.pattern(/^[A-Z0-9]+$/)
                ]
            ],
            country: [
                null,
                [
                    Validators.required,
                    Validators.minLength(2)
                ]
            ],
            aircraft_type: [
                null,
                [
                    Validators.required,
                ]
            ],
            manufacturer: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(150)
                ]
            ],
            model: [
                null,
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(50)
                ]
            ],
            serial_number: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(50)
                ]
            ],
            icao_code: [
                null,
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(15),
                    Validators.pattern(/^[A-Z0-9]+$/)
                ]
            ],
            year_manufactured: [
                null,
                [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4)
                ]
            ],
            aircraft_flight_rule: [
                null,
                [
                    Validators.required
                ]
            ],
            aircraft_color: [
                null,
                [
                    Validators.required,
                    Validators.maxLength(150)
                ]
            ],
            fin: [
                null,
                [
                    Validators.maxLength(50)
                ]
            ],
            aircraft_name: [
                null,
                [
                    Validators.maxLength(25)
                ]
            ],
            register_data_remark: [
                null,
                [
                    Validators.maxLength(255)
                ]
            ]
        }),
        general_data: objFormBuilder.group({
            certificated_weight_data: objFormBuilder.group({
                max_ramp: [null],
                max_ramp_unit: [
                    "lb",
                    [
                        Validators.required
                    ]
                ],
                max_takeoff: [null],
                max_takeoff_unit: [
                    "lb",
                    [
                        Validators.required
                    ]
                ],
                max_landing: [null],
                max_landing_unit: [
                    "lb",
                    [
                        Validators.required
                    ]
                ],
                max_zero_fuel: [null],
                max_zero_fuel_unit: [
                    "lb",
                    [
                        Validators.required
                    ]
                ]
            }),
            fuel_data: objFormBuilder.group({
                fuel_type: ["jeta1"],
                total_capacity: [null],
                total_capacity_unit: [
                    "gal",
                    [
                        Validators.required
                    ]
                ],
                usable_capacity: [null],
                usable_capacity_unit: [
                    "gal",
                    [
                        Validators.required
                    ]
                ],
                flight_fuel_rate_average: [null],
                flight_fuel_rate_average_unit: [
                    "gal/h",
                    [
                        Validators.required
                    ]
                ],
                taxi_fuel_rate_average: [null],
                taxi_fuel_rate_average_unit: [
                    "gal/h",
                    [
                        Validators.required
                    ]
                ],
                fuel_data_remark: [
                    null,
                    [
                        Validators.maxLength(255)
                    ]
                ]
            }),
            basic_performance_data: objFormBuilder.group({
                turbulence_category: ['l'],
                performance_category: ['h'],
                operational_ceiling: [null],
                operational_ceiling_unit: [
                    "ft",
                    [
                        Validators.required
                    ]
                ],
                cruise_speed: [null],
                cruise_speed_unit: [
                    "kn",
                    [
                        Validators.required
                    ]
                ],
                endurance: ["00:00"],
                range: [null],
                range_unit: [
                    "nm",
                    [
                        Validators.required
                    ]
                ],
                min_takeoff_distance: [null],
                min_takeoff_distance_unit: [
                    "m",
                    [
                        Validators.required
                    ]
                ],
                min_landing_distance: [null],
                min_landing_distance_unit: [
                    "m",
                    [
                        Validators.required
                    ]
                ]
            }),
            equipment_data: objFormBuilder.group({
                radio_navigation_data: _radioNavigationDataGroup(),
                radio_navigation_text: [null],
                ssr_data: _ssrDataGroup(),
                ssr_text: [null],
                pbn_data: _pbnDataGroup(),
                pbn_text: [null],
                supplementary_data: _supplementaryDataGroup()                
            }),
            dimensions_data: objFormBuilder.group({
                rotorcraft: objFormBuilder.group({
                    overall_length: [null],
                    overall_length_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    fuselage_length: [null],
                    fuselage_length_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    width: [null],
                    width_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    overall_height: [null],
                    overall_height_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    main_rotor_diameter: [null],
                    main_rotor_diameter_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    tail_rotor_diameter: [null],
                    tail_rotor_diameter_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    lateral_span: [null],
                    lateral_span_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    main_rotor_ground_clearance: [null],
                    main_rotor_ground_clearance_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ]
                }),
                aircraft: objFormBuilder.group({
                    overall_length: [null],
                    overall_length_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    wingspan: [null],
                    wingspan_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    overall_height: [null],
                    overall_height_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    fuselage_height: [null],
                    fuselage_height_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    fuselage_width: [null],
                    fuselage_width_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    tailplane_span: [null],
                    tailplane_span_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    landing_gear_track: [null],
                    landing_gear_track_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    landing_gear_length: [null],
                    landing_gear_length_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    radius_inside_gear: [null],
                    radius_inside_gear_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    radius_nose_wheel: [null],
                    radius_nose_wheel_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    radius_outside_gear: [null],
                    radius_outside_gear_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ],
                    radius_wing_tip: [null],
                    radius_wing_tip_unit: [
                        "ft",
                        [
                            Validators.required
                        ]
                    ]
                })
            })
        }),
        fk_client_list: objFormBuilder.array([] as IFkAircraftClientList[]),
        fk_fbo_list: objFormBuilder.array([] as IFkAircraftFboList[]),
        fk_owner_operator_list: objFormBuilder.array([] as IFkAircraftOwnerOperatorList[]),
        fk_weight_balance_sheet_list: objFormBuilder.array([] as IFkAircraftWeightBalanceSheetList[]),
        fk_crew_list: objFormBuilder.array([] as IFkAircraftCrewList[]),
        fk_pax_list: objFormBuilder.array([] as IFkAircraftPaxList[]),
        fk_notebook_list: objFormBuilder.array([] as IFkAircraftNotebookList[]),
        fk_sharing_list: objFormBuilder.array([] as IFkAircraftSharingList[]),
        fk_coast_list: objFormBuilder.array([] as IFkAircraftCoastList[]),
        fk_document_list: objFormBuilder.array([] as IFkAircraftDocumentList[]),
        fk_maintenance_list: objFormBuilder.array([] as IFkAircraftMaintenanceList[]),
        tags: [[]]
    });
}