<section data-page="app-platform-page">
    <p-confirmDialog class="cls-component-confirm-dialog-default" />
    <sunrise-app-platform--toast-message></sunrise-app-platform--toast-message>
    <sunrise-app-platform--waiting-dialog [showWaitingDialog]="this.bolShowWaitingDialog"
        [typeWaitingDialog]="this.strTypeWaitingDialog">
    </sunrise-app-platform--waiting-dialog>
    <sunrise-app-platform--title-page [titlePage]="this.objPageTitle"></sunrise-app-platform--title-page>
    <div class="cls-page-form">
        <div class="cls-page-form-grid-3-columns">
            <aside class="cls-page-form-sidebar-left">
                <ng-container *ngIf="this.activeTabIndex === 0">
                    <p-panel *ngIf="false" class="cls-component-panel-aside">
                        <ng-template pTemplate="header">
                            <div class="cls-component-panel-aside-header">
                                <div class="cls-component-panel-aside-header-title">
                                    <h3>
                                        <span><i class="fa-solid fa-bolt"></i> Imagem Aeronave</span>
                                    </h3>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-avatar">
                                <div class="cls-component-avatar-image">
                                    <p-button [rounded]="true"
                                        styleClass="cls-component-button cls-component-avatar-button-delete"
                                        icon="fa-solid fa-xmark" severity="danger" />
                                    <p-image src="https://primefaces.org/cdn/primeng/images/galleria/galleria11.jpg"
                                        previewImageSrc="https://primefaces.org/cdn/primeng/images/galleria/galleria11.jpg"
                                        alt="Image" width="100%" [preview]="true" />
                                </div>
                                <p-button label="Alterar Imagem" [rounded]="true"
                                    styleClass="cls-component-button cls-component-avatar-button"
                                    icon="fa-solid fa-folder-closed" severity="primary" />
                            </div>
                        </ng-template>
                    </p-panel>
                </ng-container>
                <ng-container *ngIf="this.activeTabIndex === 1">

                </ng-container>
                <ng-container *ngIf="this.activeTabIndex === 2"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 3">
                    <sunrise-app-platform--aircraft-data-summary></sunrise-app-platform--aircraft-data-summary>
                </ng-container>
                <ng-container *ngIf="this.activeTabIndex === 4"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 5"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 6"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 7"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 8"></ng-container>
            </aside>
            <div class="cls-page-form-content cls-util-no-padding">
                <p-tabView [scrollable]="true" [activeIndex]="3" class="cls-component-tabview-page"
                    (activeIndexChange)="onTabChange($event)">
                    <!-- REGISTER DATA -->
                    <p-tabPanel class="cls-component-tabview-page-tabpanel">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-clipboard-list"></i>
                                <div>Registro</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-tabview-page-tabpanel-content">
                                <sunrise-tab-registration-data [formComponent]="this.formAircraftData"
                                    (actionTabRegistrationData)="this.handlerActionTabRegistrationData($event)"
                                    (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)">
                                </sunrise-tab-registration-data>
                            </div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel"
                        *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-sliders-h"></i>
                                <div>Especificações</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <sunrise-tab-specification-data [formComponent]="this.formAircraftData"
                                (actionTabSpecificationData)="this.handlerActionTabSpecificationsData($event)">
                            </sunrise-tab-specification-data>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-user-tie"></i>
                                <div>Tripulação</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            INFORMAR OS CONFIGURAÇÕES DA AERONAVE
                        </ng-template>
                    </p-tabPanel>
                    <!-- WEIGHT BALANCE SHEET -->
                    <p-tabPanel class="cls-component-tabview-page-tabpanel"
                        *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null">
                        <ng-template pTemplate="header">
                            <div
                                class="cls-component-tabview-page-tabpanel-header cls-component-tabview-page-tabpanel-header-big">
                                <i class="fa-solid fa-balance-scale"></i>
                                <div>Ficha Peso & Bal.</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <ng-container *ngIf="this.weightBalanceSheetScreen === 'list'">
                                <sunrise-tab-weight-balance-sheet-list [formComponent]="this.formAircraftData"
                                    (actionTabWeightBalanceSheetList)="this.handlerActionTabWeightBalanceSheetList($event)">
                                </sunrise-tab-weight-balance-sheet-list>
                            </ng-container>
                            <ng-container *ngIf="this.weightBalanceSheetScreen === 'form'">
                                <sunrise-tab-weight-balance-sheet-form [formComponent]="this.formAircraftData"
                                    [idWeightBalanceSheet]="this.currentIdWeightBalanceSheet"
                                    (actionTabWeightBalanceSheetForm)="this.handlerActionTabWeightBalanceSheetForm($event)">
                                </sunrise-tab-weight-balance-sheet-form>
                            </ng-container>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-book"></i>
                                <div>Diários</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            DIÁRIOS DE VOO
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-share-alt"></i>
                                <div>Compartilhamento</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            COMPARTILHAMENTO DE AERONAVE
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-dollar-sign"></i>
                                <div>Custos</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            CENTRAL DE CUSTOS
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-file-alt"></i>
                                <div>Documentos</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            DOCUMENTOS DA AERONAVE
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-wrench"></i>
                                <div>Manutenção</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            DADOS DE MANUTENÇÃO
                        </ng-template>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <aside class="cls-page-form-sidebar-right">
                <ng-container *ngIf="this.activeTabIndex === 0"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 1"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 2"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 3">
                    <p-panel class="cls-component-panel-aside">
                        <ng-template pTemplate="header">
                            <div class="cls-component-panel-aside-header">
                                <div class="cls-component-panel-aside-header-title">
                                    <h3>
                                        <span><i class="fa-solid fa-bolt"></i> Templates Iniciais</span>
                                    </h3>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                    </p-panel>
                    <p-panel class="cls-component-panel-aside">
                        <ng-template pTemplate="header">
                            <div class="cls-component-panel-aside-header">
                                <div class="cls-component-panel-aside-header-title">
                                    <h3>
                                        <span><i class="fa-solid fa-bolt"></i> Fichas Aeronaves Frota</span>
                                    </h3>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                    </p-panel>
                </ng-container>
                <ng-container *ngIf="this.activeTabIndex === 4"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 5"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 6"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 7"></ng-container>
                <ng-container *ngIf="this.activeTabIndex === 8"></ng-container>
            </aside>
        </div>
    </div>
</section>