<div class="cls-app-component">
    <sunrise-advice-dialog
        [showDialog]="this.showAdviceDialog"
        [adviceMessage]="this.contentAdviceMessage"
        (actionCloseDialog)="this.onClickCloseDialogAdvice($event)">
    </sunrise-advice-dialog>
    <div class="cls-cmp-esp-upload">
        <ng-container *ngIf="this.control.value !== undefined && this.control.value !== null && this.currentSettings.NO_IMAGE_AVAILABLE.indexOf(this.control.value) < 0">
            <div class="cls-cmp-esp-upload-show-image">
                <div class="cls-cmp-esp-upload-show-image-frame" #imageFrame>
                    <p-image                         
                        src="{{ this.currentUrlImageToShow }} / {{ this.control.value }}" 
                        alt="Image" 
                        [preview]="this.isToShowPreviewImage()"/>
                </div>
                <div class="cls-cmp-esp-upload-show-image-button">
                    <p-button icon="fa-solid fa-x" severity="danger" size="small" (click)="this.onClickSetDefaultImage()" />
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.control.value === undefined || this.control.value === null || this.currentSettings.NO_IMAGE_AVAILABLE.indexOf(this.control.value) >= 0">
            <div #uploadArea
                class="cls-cmp-esp-upload-dropzone" 
                (drop)="onDropHandler($event)" 
                (dragover)="onDragOverHandler($event)" 
                (paste)="onCtrlVPasteHandler($event)">
                <ng-container *ngIf="this.showProgressBar === true">
                    <div class="cls-cmp-esp-upload-dropzone-progress" >
                        <div>{{this.fileName}}</div>
                        <p-progressBar 
                            [value]="this.uploadProgress" 
                            [showValue]="true"/>
                    </div>
                </ng-container>                
                <div class="cls-cmp-esp-upload-dropzone-box-upload"> 
                    <div class="cls-cmp-esp-upload-dropzone-box-upload-watermark">
                        <img src="{{ this.getUpdateImageToShow() }}" alt="Image">
                    </div>
                    <div class="cls-cmp-esp-upload-dropzone-box-upload-info">
                        <div class="cls-cmp-esp-upload-dropzone-box-upload-info-label">
                            Arraste e Solte Aqui 
                        </div>
                        <div class="cls-cmp-esp-upload-dropzone-box-upload-info-label">
                            Cole Aqui (Ctrl + V) ou <p-button icon="fa-solid fa-paste" (click)="this.onClickPasteHandler($event)" severity="secondary" />
                        </div>
                        <div class="cls-cmp-esp-upload-dropzone-box-upload-info-button">
                            <p-button 
                                label="{{this.classInternationalization.getTranslation('ttl_ChooseFile')}}" 
                                [outlined]="true" 
                                severity="secondary" 
                                (click)="this.onClickChooseFileClick()"/>                                                      
                        </div>
                        <div class="cls-cmp-esp-upload-dropzone-box-upload-info-advice">
                            Tamanho Máximo {{this.formatFileSize(this.maxFileSize)}}.
                        </div>
                        <div class="cls-cmp-esp-upload-dropzone-box-upload-info-advice">
                            Extensões  {{this.extensionsFile}}.
                        </div>
                    </div>                    
                </div>
            </div>
            <input 
                #fileInput
                type="file"                  
                (change)="onChangeFileSelectedHandler($event)" 
                multiple 
                style="display: none;" />
        </ng-container>
    </div>
</div>