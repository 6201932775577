//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-15
 * @version 1.0.0
 * 
 * @description Opções estáticas para o campo de seleção de escalas de gráficos.
 */
export const staticOptionsChartScales: IDropDownOptionsBasic[] = [
    { label: 'LINEAR', value: 'linear' }
]