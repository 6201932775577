//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { CanttechComponentsModule } from '../../../../../../../../_library/layouts/components/canttech/canttech-components.module';
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** CLASSES ***** //
import { InternationalizationHandler } from '../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';

// ***** COMPONENT ***** //
import { UnitConversionDialogComponent } from '../../../../../../../../_library/layouts/components/canttech/unit_conversion_dialog/unit_conversion_dialog.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AppLocaleService } from '../../../../../../../_services/_app-locale/app-locale.service';
import { ModuleCrewService } from '../../../../../../../_services/module-crew/module-crew.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsBasic } from '../../../../../../../../_library/interfaces/IDropDownOptionsBasic';
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAirmanCertificatesType } from '../../../../../../../../_library/static_options/staticOptionsAirmanCertificatesType';
import { staticOptionsAllCountries } from '../../../../../../../../_library/static_options/staticOptionsAllCountries';
import { staticOptionsBloodTypes } from '../../../../../../../../_library/static_options/staticOptionsBloodTypes';
import { staticOptionsCrewRole } from '../../../../../../../../_library/static_options/staticOptionsCrewRole';
import { staticOptionsDocumentsPersonBr } from '../../../../../../../../_library/static_options/staticOptionsDocumentsPersonBr';
import { staticOptionsPersonGender } from '../../../../../../../../_library/static_options/staticOptionsPersonGender';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-crew-register',
  standalone: true,
  imports: [
    CanttechComponentsModule,
    CommonModule,
    PrimengComponentsModule,
    UnitConversionDialogComponent 
  ],
  templateUrl: './tab-crew-register.component.html'
})
export class TabCrewRegisterComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabCrewData = new EventEmitter<string>();
  @Output() actionCrewData = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;

  // ***** CLASSES ***** //
  public classInternationalization!: any;
  public classLocalMethods!: any;
  public classModuleMethods!: any;

  //#region "|--- UNIT CONVERSION ---|" 
  public bolShowDialogUnitConversion!: boolean;

  public unitConversionControl!: any;
  public unitConversionType!: any;
  public unitConversionOriginalValue!: any;
  public unitConversionUnitToConvert!: any;
  //#endregion

  // ***** DROP-DOWN OPTIONS ***** //
  public optionsAirmanCertificatesType!: IDropDownOptionsBasic[];
  public optionsBloodTypes!: IDropDownOptionsBasic[];
  public optionsCrewRole!: IDropDownOptionsBasic[];
  public optionsDocumentsType!: IDropDownOptionsBasic[];
  public optionsNationalityCountries!: IDropDownOptionsCountry[];
  public optionsPersonGender!: IDropDownOptionsBasic[];
  //#endregion

  constructor(
    private _appLocaleService: AppLocaleService,
    private _messageService: MessageService,
    private _moduleCrewService: ModuleCrewService,
  ) { }

  // @status:
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
  * @status:
  * @author GASPAR
  * @date 2024-10-09
  * @version 1.0.0
  * 
  * @description 
  *   - Inicializa as variáveis do componente.
  */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;

    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.optionsAirmanCertificatesType = staticOptionsAirmanCertificatesType;
    this.optionsBloodTypes = staticOptionsBloodTypes;
    this.optionsCrewRole = staticOptionsCrewRole;
    this.optionsDocumentsType = staticOptionsDocumentsPersonBr;
    this.optionsNationalityCountries = staticOptionsAllCountries;
    this.optionsPersonGender = staticOptionsPersonGender;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-12-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações do Dialogo de Transformação de Unidades.
   * 
   * @param xAction 
   */
  public async handlerActionUnitConversionDialog(xAction: any): Promise<void> {
    if (xAction === ACTION_TYPE.EXIT) {
      this.unitConversionControl = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionOriginalValue = null;
      this.unitConversionUnitToConvert = null;

      this.bolShowDialogUnitConversion = false;
    } else if (xAction === ACTION_TYPE.COPIED_TO_CLIPBOARD) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataCopiedClipboardSuccess'),
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else if(xAction === ACTION_TYPE.SAVE) {
      this._messageService.add({
        severity: "info",
        summary: this.classInternationalization.getTranslation('lbl_DataCopiedClipboard'),
        detail: this.classInternationalization.getTranslation('msg_DataSaveInField'),
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    }
  }
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-05
   * @version 1.0.0
   *
   * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
   *
   * @abstract Os botões estão no Toolbar do formulário do REGISTRO AERONÁUTICO.
   */
  public onClickToolbarTabCrewRegister(xButtonAction: string): void {
    this.actionTabCrewData.emit(xButtonAction);
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-11-01
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será associado ao onBlur do controle para verificar se o valor do Documento Profissional do Tripulante já está sendo usado.
  * 
  * @returns 
  */
  public onBlurCheckDocumentInValue(): any {
    return async (xEvent: any, xComponentContext: any) => {

      const inputElement = xEvent.target as HTMLInputElement;
      const inputValue = inputElement.value;
      const documentType = this.formComponent.controls.documents_profissional.controls[0].controls.document_type.value;

      xComponentContext.showIconWaiting = true;

      if (inputValue && inputValue != null && inputValue !== "") {
        // VERIFICA SE O VALOR JÁ EXISTE NA BASE DE DADOS: DOCUMENTO DE DETERMINADO TIPO
        await this._moduleCrewService.checkIfHasDocumentValue(documentType, inputValue).subscribe({
          next: (xApiResponse: any) => {
            if (xApiResponse) { // Só importa se houver resultado positivo. Erros e outros não deve ser sinalizado
              if (xApiResponse.data[0] !== undefined && xApiResponse.data[0].qtd > 0) {
                if (this.formComponent.controls.documents_profissional.controls[0].controls.document_in.errors === null) { // Se já estiver inválido, não precisa marcar novamente. 
                  this.formComponent.controls.documents_profissional.controls[0].controls.document_in.markAsDirty();
                  this.formComponent.controls.documents_profissional.controls[0].controls.document_in.markAsTouched();
                  this.formComponent.controls.documents_profissional.controls[0].controls.document_in.setErrors({ unique: true });
                }
              }
            }
          }
        });
      }

      setTimeout(() => {
        xComponentContext.showIconWaiting = false;
      }, 1500);
    }
  }

  /**
  * @status:
  * @author GASPAR
  * @date 2024-12-30
  * @version 1.0.0
  * 
  * @description 
  *   - Método que será associado ao onClick para Abrir a Tela de Conversão de Dados.
  * 
  * @returns 
  */
  public onClickConverterValue(xTypeConvert: string): any {
    return async (xEvent: any, xComponentContext: any) => {
      // Recuperar o elemento que disparou o evento.
      const inputElement = xEvent.target as HTMLInputElement;

      //#region "|--- UNIT CONVERSION ---|" 
      this.unitConversionControl = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionControl = xComponentContext.control;

      this.unitConversionType = null; // Tem que forçar a limpeza do controle para ser capturado pelo setter do componente.
      this.unitConversionType = xTypeConvert;

      this.unitConversionOriginalValue = null;
      this.unitConversionOriginalValue = xComponentContext.control.value;

      this.unitConversionUnitToConvert = null;
      this.unitConversionUnitToConvert = inputElement.innerText || inputElement.textContent;
      //#endregion

      this.bolShowDialogUnitConversion = true;
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  //#endregion

  //#region "|--- UPLOAD IMAGE METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-20
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para fazer o upload da imagem.
   * 
   * @returns 
   */
  public getUrlToUploadAvatarImage(): string {
    const tempLocale = this._appLocaleService.getLocale();

    let urlUploadImage: string;

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlUploadImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlUploadImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_CREW_PORT}/${this.currentSettings.API_PATH}`;
    }

    return `${urlUploadImage}/${this.currentSettings.API_CREW_URL_SEGMENT}/${tempLocale}/crew/image`;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-20
   * @version 1.0.0
   * 
   * @description 
   *  - Retorna o Objeto com os dados que devem ser passados no corpo da requisição para fazer o upload da imagem.
   */
  public getParametersToUploadAvatarImage(): any {
    let tempId = "000";
    let tempProfissionalDocumentIn = "000";

    if (this.formComponent.controls.crew_data_id.value != null) {
      tempId = this.formComponent.controls.crew_data_id.value;
    }

    if (this.formComponent.controls.documents_profissional.controls[0].controls.document_in.value != null) {
      tempProfissionalDocumentIn = this.formComponent.controls.documents_profissional.controls[0].controls.document_in.value;
    }

    return {
      id: tempId,
      doc_in: tempProfissionalDocumentIn
    }
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2024-11-29
   * @version 1.0.0
   * 
   * @description 
   *   - Retorno da Url para mostrar a imagem.
   *
   * @returns
   */
  public getUrlToShowAvatarImage(): string {
    const tempLocale = this._appLocaleService.getLocale();

    let urlShowImage: string;
    let tempId = "000";
    let tempProfissionalDocumentIn = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_CREW_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.formComponent.controls.crew_data_id.value != null) {
      tempId = this.formComponent.controls.crew_data_id.value;
    }

    if (this.formComponent.controls.documents_profissional.controls[0].controls.document_in.value != null) {
      tempProfissionalDocumentIn = this.formComponent.controls.documents_profissional.controls[0].controls.document_in.value;
    }

    return `${urlShowImage}/${this.currentSettings.API_CREW_URL_SEGMENT}/${tempLocale}/crew/image/${tempId}/${tempProfissionalDocumentIn}`.toLocaleLowerCase();
  }
  
  /**
   * @status:
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Responsável por tratar as ações do Upload de imagens.  
   * 
   * @param xAction
   */
  public handlerActionAvatarImageUpload(xAction: any) {
    if (xAction.action === "error") {
      this._messageService.add({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_UploadImage'),
        detail: xAction.message,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else if (xAction.action === "success" || xAction.action === "set_default") {
      this.actionCrewData.emit(ACTION_TYPE.UPDATE);

      this._messageService.add({
        severity: 'success',
        summary: this.classInternationalization.getTranslation('ttl_UploadImage'),
        detail: xAction.message,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    }
  }
  //#endregion
}