<div class="cls-ui-primeng-field cls-ui-primeng-field-input-float">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
            {{this.label}}
            <ng-container *ngIf="this.showToolTipIcon">
                <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300" tooltipPosition="bottom" class="fa-solid fa-circle-info ml-1"></i>
            </ng-container>            
        </label> 
        <ng-template #tooltipContent>
            <div class="flex align-items-center" style="width: 50rem">
                <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                <span> {{ this.getTooTipContent() }} </span>
            </div>
        </ng-template> 
        <p-inputGroup> 
            <ng-container *ngIf="this.hastLeftGroup()"> 
                <p-inputGroupAddon>
                    <span>{{this.groupLeftText}}</span>
                    <i class="{{this.groupLeftIcon}}"></i>
                </p-inputGroupAddon>
            </ng-container>    
            <p-inputNumber 
                [formControl]="this.control"
                [ngClass]="{ 'is-danger': this.showErrors() }"
                [mode]=this.getNumberType() 
                locale="pt-BR"
                [minFractionDigits]="0" 
                [maxFractionDigits]="2" />
            <ng-container *ngIf="this.hastRightGroup()"> 
                <p-inputGroupAddon>
                    <span>{{this.groupRightText}}</span>
                    <i class="{{this.groupRightIcon}}"></i>  
                </p-inputGroupAddon>
            </ng-container>
        </p-inputGroup>
    </div>
</div>