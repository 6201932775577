<div class="cls-ui-primeng-field cls-ui-primeng-field-input-time">
    <div [ngClass]="{'cls-ui-primeng-field-wrong': this.showErrors() }">
        <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-wrong': this.showErrors() }">
            {{this.label}}
        </label>   
        <p-inputMask 
            [formControl]="this.control"
            [ngClass]="{ 'is-danger': this.showErrors() }"
            showClear="false"
            id="{{this.idField}}"
            mask="99:99" 
            placeholder="hh:mm"
            class="w-full"/>  
 
    </div>
    <lib-ui-primeng-handler-error [control]="this.control" typeControl="input"></lib-ui-primeng-handler-error>
</div>
