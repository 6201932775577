//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Route } from '@angular/router';

// ***** SHARED *****
import { WebPageSpaComponent } from './_shared/web-page-spa/web-page-spa.component';
import { DashboardSpaComponent } from './_shared/dashboard-spa/dashboard-spa.component';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
// ***** APP *****
import { AircraftDataFormComponent } from './app-platform/modules/aircraft-data/pages/aircraft-data-form/aircraft-data-form.component';
import { AircraftDataHomeComponent } from './app-platform/modules/aircraft-data/pages/aircraft-data-home/aircraft-data-home.component';
import { AircraftDataListComponent } from './app-platform/modules/aircraft-data/pages/aircraft-data-list/aircraft-data-list.component';
import { AircraftDashboardGeneralComponent } from './app-platform/modules/aircraft-data/dashboards/aircraft-dashboard-general/aircraft-dashboard-general.component';

import { AccountDataFormComponent } from './app-platform/modules/canttech/pages/account-data-form/account-data-form.component';
import { AccountDataListComponent } from './app-platform/modules/canttech/pages/account-data-list/account-data-list.component';

import { CrewDataFormComponent } from './app-platform/modules/crew/pages/crew-data-form/crew-data-form.component';
import { CrewDataListComponent } from './app-platform/modules/crew/pages/crew-data-list/crew-data-list.component';

import { FlightDataFormComponent } from './app-platform/modules/flight/pages/flight-data-form/flight-data-form.component';
import { FlightDataListComponent } from './app-platform/modules/flight/pages/flight-data-list/flight-data-list.component';

import { HomePageComponent } from './app-platform/modules/app-platform-module/pages/home-page/home-page.component';

import { PaxDataFormComponent } from './app-platform/modules/pax/pages/pax-data-form/pax-data-form.component';
import { PaxDataListComponent } from './app-platform/modules/pax/pages/pax-data-list/pax-data-list.component';

import { WeightBalanceFormComponent } from './app-platform/modules/weight-balance/pages/weight-balance-form/weight-balance-form.component';
import { WeightBalanceListComponent } from './app-platform/modules/weight-balance/pages/weight-balance-list/weight-balance-list.component';
//#endregion

//#region "|--- IMPORT GUARDS ---|"
import { AuthenticationGuard } from './app-platform/_guard/module-authentication/authentication.guard';
//#endregion

/**
 * O serviço de autenticação (AuthenticationService) gerencia o login, armazenamento e recuperação do token JWT.
 * O interceptor HTTP (AuthenticationInterceptor) adiciona o token JWT às requisições HTTP.
 * O guard de autenticação (AuthenticationGuard) protege as rotas que requerem autenticação.
 * O componente de login (LoginComponent) permite que o usuário insira suas credenciais e faça login.
 * O componente protegido (ProtectedComponent) é um exemplo de uma rota protegida que só pode ser acessada se o usuário estiver autenticado.
 */

export const appRoutes: Route[] = [    
    {
        path: 'page',
        component: WebPageSpaComponent
    },
    {
        path: 'admin',
        component: DashboardSpaComponent,
        data: {
            system: 'admin-panel',
            frame: 'vertical_01'
        }
    },
    {
        path: 'app/login',
        component: DashboardSpaComponent,
        data: {
            system: 'app',
            frame: 'login'
        }
    },
    {        
        path: 'app',
        component: DashboardSpaComponent,
        data: {
            system: 'app-platform',
            frame: 'horizontal_01'
        },
        children: [
            { path: 'protected', component: AircraftDataHomeComponent, canActivate: [AuthenticationGuard] },
            { path: 'home', component: HomePageComponent },
            { path: 'aircraft/home', component: AircraftDataHomeComponent },
            { path: 'aircraft/home', component: AircraftDataHomeComponent },
            { path: 'aircraft/data/form/edit/:id', component: AircraftDataFormComponent },
            { path: 'aircraft/data/form', component: AircraftDataFormComponent },            
            { path: 'aircraft/data/list', component: AircraftDataListComponent },
            { path: 'aircraft/dashboard/general', component: AircraftDashboardGeneralComponent },  
            { path: 'canttech/account/form', component: AccountDataFormComponent },
            { path: 'canttech/account/:id', component: AccountDataFormComponent },
            { path: 'canttech/account/list', component: AccountDataListComponent }, 
            { path: 'crew/data/form/edit/:id', component: CrewDataFormComponent },
            { path: 'crew/data/form', component: CrewDataFormComponent },
            { path: 'crew/data/list', component: CrewDataListComponent },       
            { path: 'flight/data/form/edit/:id', component: FlightDataFormComponent },
            { path: 'flight/data/form', component: FlightDataFormComponent },
            { path: 'flight/data/list', component: FlightDataListComponent },
            { path: 'pax/data/form/edit/:id', component: PaxDataFormComponent },
            { path: 'pax/data/form', component: PaxDataFormComponent },
            { path: 'pax/data/list', component: PaxDataListComponent },
            { path: 'weight-balance/template/form/id', component: WeightBalanceFormComponent },
            { path: 'weight-balance/template/form', component: WeightBalanceFormComponent },
            { path: 'weight-balance/template', component: WeightBalanceListComponent },
        ]
    },
    {
        path: 'playground',
        component: DashboardSpaComponent,
        data: {
            title: 'Playground_Panel',
            frame: 'vertical_01'
        }
    }
];
