<div class="cls-ui-primeng-field cls-ui-primeng-field-input-ddi-ddd-phone">
    <div [ngClass]="{'cls-ui-primeng-field-field-wrong': this.showErrors() }">

            <label class="cls-ui-primeng-field-label" for="{{this.idField}}" [ngClass]="{'cls-util-font-bold': this.showLabelBold, 'cls-ui-primeng-field-field-wrong': this.showErrors() }" >
                {{this.label}}
            </label>
            <div class="cls-ui-primeng-field-input-ddi-ddd-phone-frame">
                <div class="cls-ui-primeng-field-input-ddi-ddd-phone-ddi">
                    <p-dropdown 
                        [formControl]="this.controlDdi"
                        [ngClass]="{ 'is-danger': this.showErrors() }"
                        [options]="this.arrayOptions" 
                        [optionLabel]="getPropertyLabel()"
                        [optionValue]="getPropertyValue()"
                        [autoDisplayFirst]="true" 
                        [showClear]="false"
                        optionLabel="name"                        
                        appendTo=".cls-ui-primeng-field cls-ui-primeng-field-input-ddi-ddd-phone"
                        id="{{ this.idField }}"
                        name="{{ this.getFieldName() }}">
                        <ng-template let-dropdownSelectedItem pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                    [class]="'flag flag-' + this.dropdownSelectedItem.flag.toLowerCase()"
                                    style="width: 18px" />
                                <div>({{dropdownSelectedItem.ddi}}) &nbsp;&nbsp; {{ dropdownSelectedItem.label }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-itemsArray pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                    [class]="'flag flag-' + this.itemsArray.flag.toLowerCase()"
                                    style="width: 18px" />
                                <div>({{itemsArray.ddi}}) {{ itemsArray.label }}</div> 
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="cls-ui-primeng-field-input-ddi-ddd-phone-ddd">
                    <input
                        [formControl]="this.controlDdd"
                        [ngClass]="{ 'is-danger': this.showErrors() }"
                        type="text"
                        name="{{this.getFieldName()}}" 
                        id="{{this.idField}}" 
                        pInputText />
                </div>
                <div class="cls-ui-primeng-field-input-ddi-ddd-phone-number">
                    <input
                        [formControl]="this.controlNumber"
                        [ngClass]="{ 'is-danger': this.showErrors() }"
                        type="text"
                        name="{{this.getFieldName()}}" 
                        id="{{this.idField}}"
                        pInputText />
                </div>
            </div>                    
    </div>
</div>

