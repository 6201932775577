//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withFetch, withJsonpSupport, withNoXsrfProtection } from "@angular/common/http";
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

// ***** ROUTES ***** //
import { appRoutes } from './app.routes';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AuthenticationService } from './app-platform/_services/module-authentication/authentication.service';
//#endregion

//#region "|--- IMPORT INTERCEPTOR ---|"
import { AuthenticationInterceptor } from './app-platform/_interceptor/module-authentication/authentication.interceptor';
//#endregion

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideHttpClient(withJsonpSupport(), withNoXsrfProtection()),
    provideAnimations(),
    MessageService,
    ConfirmationService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }
  ],
};
