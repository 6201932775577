import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-aircraft-data-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aircraft-data-home.component.html',
  styleUrl: './aircraft-data-home.component.css',
})
export class AircraftDataHomeComponent {}
