//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Inject, LOCALE_ID } from '@angular/core';

// ***** RXJS ***** //
import { Observable } from 'rxjs';

// ***** CONFIG ***** //
import { settingConfig } from '../../../config/settingConfig';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class CanttechService {
  //#region "|--- PROPERTIES---|"   
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _http: HttpClient
  ) { }

  public getAddressByZipCodeBr(xZipCode: string): Observable<any> {
    xZipCode = xZipCode.replace(/\D/g, ''); // Remove all non-numeric characters from the string.

    return this._http.get(`${settingConfig.URL_VIACEP}/${xZipCode}/json`);
  }

  public loadImage(xUrl: string): Observable<any> {
    console.log('****************************', xUrl)
    return this._http.get(xUrl, { responseType: 'blob' });
  }
}
