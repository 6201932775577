//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frame-vertical-01',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './frame-vertical-01.component.html',
  styleUrl: './frame-vertical-01.component.css',
})
export class FrameVertical01Component implements OnInit, AfterViewInit {
  //#region "|--- PROPERTIES---|"
  // ***** CONFIG ***** //
  innerWidth?: any;
  //#endregion

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.innerWidth = window.innerWidth;  
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion
}
