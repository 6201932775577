<div class="cls-app-platform--component">
    <div class="cls-app-platform--component-title-form-topic">
        <div class="cls-app-platform--component-title-form-topic-icon">
            <i class="fa-solid fa-bookmark"></i>
        </div>
        <div class="cls-app-platform--component-title-form-topic-content">
            <h2>{{this.titleFormTopic}}</h2>            
        </div>
    </div>
</div>
