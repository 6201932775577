//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initAccountDataForm } from '../../form-init/account-data-form';

// ***** COMPONENT ***** //
import { TabRegisterDataComponent } from './_components/register-data/tab-register-data/tab-register-data.component';
import { TabUserDataComponent } from './_components/user-data/tab-user-data/tab-user-data.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';

// ***** CLASS ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAccountService } from '../../../../_services/module-account/module.account.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
import { DIALOG_WAITING_TYPE } from '../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-account-data-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    TabRegisterDataComponent,
    TitlePageComponent,
    TabUserDataComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './account-data-form.component.html'
})
export class AccountDataFormComponent implements OnInit {
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public typeDialogWaiting!: string;

  // ***** CURRENT ***** //
  public currentDataStatus!: string; // Eu não posso usar o account_data_id para verificar se é um novo registro ou edição, pois pode ser um registro legado.

  // ***** CLASSES ***** //
  public classLocalMethods!: any;

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** FORMS ***** //  
  public formAccountData!: any;

  // ***** SHOW DIALOG ***** //
  public bolShowDialogWaiting!: boolean;
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleAccountApisService: ModuleAccountService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  // @status: OK
  ngOnInit(): void {
    this._initVariables();

    this._initAccountDataForm();

    this._checkEditMode();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-24
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.typeDialogWaiting = DIALOG_WAITING_TYPE.DEFAULT;

    this.classLocalMethods = LocalMethodsHandlerClass;

    this.bolShowDialogWaiting = false;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-26
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initAccountDataForm(): void {
    this.formAccountData = initAccountDataForm();
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-24
   * @version 1.0.0
   * 
   * @description 
   *   - Método que verifica se é uma edição de dados de uma conta ou um novo registro.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      let isEdit = false;

      if (xParams["id"]) {
        this.objPageTitle = {
          title: 'Editar Conta',
          subtitle: 'Alterar Dados de uma conta no sistema.',
          icon: 'fa-solid fa-edit'
        }

        this.currentDataStatus = "edit";

        isEdit = true;
      } else {
        this.objPageTitle = {
          title: 'Nova Conta',
          subtitle: 'Inserir dados de uma nova conta no sistema.',
          icon: 'fa-solid fa-plus'
        }

        this.currentDataStatus = "new";
      }

      if (isEdit) {
        //Promise.all([
        //  await this._loadExtraDataDb(false),
        //  await this._getAircraftDataById(xParams["id"])
        //]);
      } else {
        //await this._loadExtraDataDb(true);
      }
    })
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que normaliza e mascara o telefone do agente.
   */
  private _settingNormalizedAndMaskedAgentPhone(): void {
    let ddi = this.formAccountData.controls.agent_data.controls.phone_data.get('ddi').value;
    let ddd = this.formAccountData.controls.agent_data.controls.phone_data.get('ddd').value;
    let number = this.formAccountData.controls.agent_data.controls.phone_data.get('number').value;

    if (ddi !== null && ddd !== null && number !== null) {
      ddi = this.classLocalMethods.removeAnyMask(ddi);
      ddd = this.classLocalMethods.removeAnyMask(ddd);
      number = this.classLocalMethods.removeAnyMask(number);

      const normalized = `${ddi}${ddd}${number}`;
      const masked = `${ddi} (${ddd}) ${number}`;

      this.formAccountData.controls.agent_data.controls.phone_data.get('normalized').setValue(normalized);
      this.formAccountData.controls.agent_data.controls.phone_data.get('masked').setValue(masked);
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que normaliza e mascara o telefone da conta.
   */
  private _settingNormalizedAndMaskedAccountPhone(): void {
    let ddi = this.formAccountData.controls.phone_data.get('ddi').value;
    let ddd = this.formAccountData.controls.phone_data.get('ddd').value;
    let number = this.formAccountData.controls.phone_data.get('number').value;

    if (ddi !== null && ddd !== null && number !== null) {
      ddi = this.classLocalMethods.removeAnyMask(ddi);
      ddd = this.classLocalMethods.removeAnyMask(ddd);
      number = this.classLocalMethods.removeAnyMask(number);

      const normalized = `${ddi}${ddd}${number}`;
      const masked = `${ddi} (${ddd}) ${number}`;

      this.formAccountData.controls.phone_data.get('normalized').setValue(normalized);
      this.formAccountData.controls.phone_data.get('masked').setValue(masked);
      this.formAccountData.controls.account_phone.setValue(`+${normalized}`);
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-11-02
   * @version 1.0.0
   * 
   * @description 
   *   - Método que verifica se o agente é uma pessoa física ou jurídica.
   */
  private _checkPersonOrCompany(): void {
    if (this.formAccountData.controls.profile_data.controls.profile_type.value === 'company') {
      this.formAccountData.controls.profile_data.controls.birth_date_utc.setValue(null);
      this.formAccountData.controls.profile_data.controls.gender.setValue('not_applicable');
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion

  //#region "|--- EVENT METHODS ---|"

  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  /**
   * @status:
   * @author GASPAR
   * @date 2024-10-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método que trata as ações da aba de dados de registro da conta.
   * 
   * @param xAction 
   */
  public async handlerActionTabRegisterData(xAction: string): Promise<void> {
    if (xAction === ACTION_TYPE.NEW) {
      console.log('NOVO REGISTRO DE CONTA');
      //this._newRegisterData();
    } else if (xAction === ACTION_TYPE.SAVE) {
      await this._saveRegisterData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      console.log('UPDATE DADOS DA CONTA');
      //this._updateAircraftData();
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log('SAIR DA CONTA');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-28
   * @version 1.0.0
   * 
   * @description 
   *   - Método que salva os dados do "REGISTRO DA CONTA".
   * 
   * @abstract 
   * - Importante ressaltar que quando você está salvando um novo "REGISTRO DA CONTA", você também está criando a estrutura de Dados da Conta.
   * - "REGISTRO DA CONTA" é um subconjunto dos Dados da Aeronave.
   */
  private async _saveRegisterData(): Promise<void> {
    this._settingNormalizedAndMaskedAccountPhone();
    this._settingNormalizedAndMaskedAgentPhone();
    this._checkPersonOrCompany();

    if (this.formAccountData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formAccountData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Registro Conta',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      await this._moduleAccountApisService.saveFormAccountData(this.formAccountData).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
            const objFormBuilder: FormBuilder = new FormBuilder();
            const tempUser = {
              fk_user_data_id: xApiResponse.data[0].data[0].fk_users_data[0].id,
              ntbs_user_name: this.formAccountData.controls.account_name.value,
              ntbs_user_email: this.formAccountData.controls.account_email.value,
              ntbs_user_phone: this.formAccountData.controls.account_phone.value,
              ntbs_full_name: this.formAccountData.controls.profile_data.controls.full_name.value,
            }

            this.formAccountData.controls.fk_users_list.controls.push(objFormBuilder.group(tempUser));
            this.formAccountData.controls.fk_users_owner_list.controls.push(objFormBuilder.group(tempUser));

            this.currentDataStatus = "edit"; // Não pode ser pelo ID, pois vai ser permitido entrar com ID para tratar de Contas legadas.

            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Registro da Conta',
              detail: `${xApiResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Registro da Conta',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }
  //#endregion















  //#region "|--- PRIVATE METHODS ---|"











  //#endregion

  //#region "|--- PUBLIC METHODS ---|"


  /**
   * @author GASPAR
   * @date 2024-11-05
   * @version 1.0.0
   * 
   * @description Método que trata as ações da aba de dados do usuário.
   * 
   * @param xAction 
   */
  handlerActionTabUserData(xAction: string): void {
    if (xAction === ACTION_TYPE.NEW) {
      console.log('NOVO REGISTRO DE USER');
      //this._newRegisterData();
    } else if (xAction === ACTION_TYPE.SAVE) {
      console.log('SALVAR DADOS DA USER');
      //this._saveRegisterData();
    } else if (xAction === ACTION_TYPE.UPDATE) {
      console.log('UPDATE DADOS DA USER');
      //this._updateAircraftData();
    } else if (xAction === ACTION_TYPE.EXIT) {
      console.log('SAIR DA USER');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-28
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowDialogWaiting = xShowDialog.show;
    this.typeDialogWaiting = xShowDialog.type;
  }
  //#endregion

}
