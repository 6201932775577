//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit } from '@angular/core';
import { Inject, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** FORM ***** //
import { initLoginForm } from '../../form-init/login-form';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { ModuleIamAppService } from '../../../../_services/module-iam-app/module.iam.app.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../_library/definitions/ActionType';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-login-app-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './login-app-form.component.html'
})
export class LoginAppFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public actionType!: any;

  // ***** FORM ***** //
  public formLogin!: any;

  // ***** ERROR ***** //
  public loginError!: any;
  public bolShowErrorDialog!: boolean;
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _moduleIamAppService: ModuleIamAppService,
    private _messageService: MessageService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initFormLogin();
    this._checkLoggedUser();
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-19
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.bolShowErrorDialog = false;

    this.loginError = null;
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-19
   * @version 1.0.0
   * 
   * @description
   *   - Verifica se o usuário está logado.
   */
  private _checkLoggedUser(): void {
    const tempToken = this._moduleIamAppService.getAppToken();

    if (tempToken !== undefined && tempToken !== null && tempToken !== '') {
      this._moduleIamAppService.verifyToken().subscribe({
        next: (xResponse: any) => {
          if (xResponse === true) {
            this._router.navigate([`app/${this._locale}/home`]);
          } else {
            // Se retornar falso, limpa o token.
            this._moduleIamAppService.logoutApp();
          }
        },
        error: (xError: any) => {
          // Se retornar erro, limpa o token.
          this._moduleIamAppService.logoutApp();
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2024-10-19
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de login.
   * 
   */
  private _initFormLogin(): void {
    this.formLogin = initLoginForm();
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2024-10-22
   * @version 1.0.0
   * 
   * @description 
   *   - Realiza o login no aplicativo.
   */
  public async _loginApp(): Promise<void> {
    this.loginError = null;

    if (this.formLogin.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formLogin, ModuleMethodsHandlerClass.getFunctionFieldName());

      this.loginError = `Os campos não foram validados: \n- ${messageErrosFormRequest}`;
    } else {
      this._moduleIamAppService.loginFormApp(this.formLogin).subscribe({
        next: xResponse => {
          const returnUrl = this._router.routerState.snapshot.root.queryParams['returnUrl'];

          if (returnUrl !== undefined && returnUrl !== null && returnUrl !== '') {
            this._router.navigate([returnUrl]);
          } else {
            this._router.navigate([`app/${this._locale}/home`]);
          }
        },
        error: xError => {
          if (xError.detail != undefined && xError.detail != null) {
            this.loginError = xError.detail;
            this.bolShowErrorDialog = true;
          }
        }
      });
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"

  //#endregion

  //#region "|--- ACTION METHODS ---|"
  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-17
   * @version 1.0.0
   * 
   * @description 
   *   - Realiza a ação do botão.
   */
  public onClickButton(xButtonAction: string): void {
    if (xButtonAction == ACTION_TYPE.LOGIN) {
      this._loginApp();
    }
  }

  /**
   * @status: OK
   * @author GASPAR
   * @date 2025-02-17
   * @version 1.0.0
   * 
   * @description 
   *   - Fecha o dialog de erro.
   */
  public onClickCloseDialog(): void {
    this.bolShowErrorDialog = false;
  }
  //#endregion

  //#region "|--- SERVICES METHODS ---|"
  //#endregion
}
