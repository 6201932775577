//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-09-25
 * @version 1.0.0
 * 
 * @description Opções de SIM ou Não.   
 */
export const staticOptionsBasicColors: IDropDownOptionsBasic[] = [
    { label: 'AMARELO', value: '#FFFF00' },
    { label: 'AZUL', value: '#0000FF' },
    { label: 'BRANCA', value: '#FFFFFF' },
    { label: 'CIANO', value: '#00FFFF' },
    { label: 'CINZA', value: '##808080' },
    { label: 'LARANJA', value: '#FFA500' },
    { label: 'MARROM', value: '#800000' },
    { label: 'PRETA', value: '#000000' },
    { label: 'ROSA', value: '#FF00FF' },
    { label: 'ROXA', value: '#800080' },
    { label: 'VERDE', value: '#00FF00' },
    { label: 'VERMELHO', value: '#FF0000' }
]