//#region "|--- IMPORT ANGULAR ---|"
// ***** ANGULAR ***** //
import { FormBuilder, FormArray, Validators } from '@angular/forms';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../config/settingConfig'

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../_library/classes/LocalMethodsHandlerClass'
//#endregion

export function initAircraftDataForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        aircraft_data_id: [null],
        avatar_image: [settingConfig.NO_IMAGE_AVAILABLE],
        avatar_color: [LocalMethodsHandlerClass.generateHexColor()],
        registration_data: objFormBuilder.group({
            country: [
                null,
                [
                    Validators.required,
                    Validators.minLength(2)
                ]
            ],
            mark: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(7),
                    Validators.pattern(/^[a-zA-Z0-9]*$/)
                ]
            ],
            aircraft_type: [null],
            manufacturer: [null],
            model: [
                null,
                [
                    Validators.required
                ]
            ],
            serial_number: [null],
            icao_code: [null],
            year_manufactured: [null],
            flight_rule: [null],
            fin_number: [null],
            aircraft_name: [null],
            registration_data_remarks: [null]
        }),
        specification_data: objFormBuilder.group({
            weight_data: objFormBuilder.group({               
                max_takeoff_weight: [null],
                max_takeoff_weight_unit: ["lb"],
                max_landing_weight: [null],  
                max_landing_weight_unit: ["lb"],  
                max_ramp_weight: [null],
                max_ramp_weight_unit: ["lb"],
                max_zero_fuel_weight: [null],
                max_zero_fuel_weight_unit: ["lb"]
            }),
            performance_data: objFormBuilder.group({
                cruise_speed: [null],
                cruise_speed_unit: ["kt"],            
                autonomy: ["00:00"],
                autonomy_unit: ["hh:mm"],
                max_takeoff_distance: [null],
                max_takeoff_distance_unit: ["m"],
                max_landing_distance: [null],
                max_landing_distance_unit: ["m"],
            }),
            fuel_data: objFormBuilder.group({
                fuel_type: [null],
                total_capacity: [null],
                total_capacity_unit: ["kg"],  
                usable_capacity: [null],
                usable_capacity_unit: ["kg"],              
                flight_fuel_rate: [null],
                flight_fuel_rate_unit: ["kg_h"],
                taxi_fuel_rate: [null],
                taxi_fuel_rate_unit: ["kg_h"]
            })
        }),
        owner_operator_data: [[]],
        fk_weight_balance_sheet_id: [[]]
    });
}