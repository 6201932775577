<section class="cls-component-dialog-form">
    <p-dialog [(visible)]="this.showDialog" [modal]="true" [showHeader]="true" [closable]="true"
        [style]="{height:'80vh', width: '75vw'}" class="cls-component-dialog-modal">
        <ng-template pTemplate="header">
            HEDAER
        </ng-template>
        <ng-template pTemplate="content">
            <p-panel class="cls-component-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-component-panel-default-header">
                        <div class="cls-component-panel-default-header-title">
                            <h2>
                                <i class="fa-solid fa-bolt"></i>
                                <span>Dados do Proprietário</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-form cls-component-form-frame">
                        <div class="formgrid">
                            <div class="grid">
                                <div class="col-12 md:col-6 lg:col-3">
                                    <lib-ui-primeng-drop-down-flag label="País #BOLD#"
                                        [control]="this.formComponent.controls.country"
                                        [arrayOptions]="this.optionsAircraftCountries">
                                    </lib-ui-primeng-drop-down-flag>
                                </div>
                                <div class="col-12 md:col-6 lg:col-3">
                                    <lib-ui-primeng-drop-down-icon label="Tipo Entidade #BOLD#"
                                        [control]="this.formComponent.controls.type_entity"
                                        [arrayOptions]="this.optionsEntityType">
                                    </lib-ui-primeng-drop-down-icon>
                                </div>
                                <ng-container *ngIf="this.formComponent.controls.type_entity.value === 'company'">
                                    <div class="col-12 md:col-6 lg:col-3">
                                        <lib-ui-primeng-input-text label="CNPJ #BOLD#"
                                            [control]="this.formComponent.controls.ni">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="col-12 md:col-6 lg:col-3">
                                        <lib-ui-primeng-input-text label="Nome Empresa #BOLD#"
                                            [control]="this.formComponent.controls.name">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="this.formComponent.controls.type_entity.value === 'person'">
                                    <div class="col-12 md:col-6 lg:col-3">
                                        <lib-ui-primeng-input-text label="CPF #BOLD#"
                                            [control]="this.formComponent.controls.ni">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="col-12 md:col-6 lg:col-3">
                                        <lib-ui-primeng-input-text label="Nome #BOLD#"
                                            [control]="this.formComponent.controls.name">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="this.formComponent.controls.type_entity.value === 'company'">
                                <div class="grid">
                                    <div class="col-12 md:col-12 lg:col-12 xl:col-6">
                                        <lib-ui-primeng-input-text label="Nome Responsável"
                                            [control]="this.formComponent.controls.company_responsible.controls.name">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="col-12 md:col-6 lg:col-6 xl:col-3">
                                        <lib-ui-primeng-input-ddi-ddd-phone
                                            label=" Celular Responsável"
                                            [arrayOptions]="this.optionsAircraftCountries"
                                            [controlDdi]="this.formComponent.controls.company_responsible.controls.contacts.controls[0].ddi"                                            
                                            [controlDdd]="this.formComponent.controls.company_responsible.controls.contacts.controls[0].ddd"
                                            [controlNumber]="this.formComponent.controls.company_responsible.controls.contacts.controls[0].number"
                                            [controlNormalized]="this.formComponent.controls.company_responsible.controls.contacts.controls[0].normalized">
                                        </lib-ui-primeng-input-ddi-ddd-phone>                                        
                                    </div>
                                    <div class="col-12 md:col-6 lg:col-6 xl:col-3">                                       
                                        <lib-ui-primeng-input-text label="E-mail Responsável"
                                            [control]="this.formComponent.controls.company_responsible.controls.contacts.controls[1].contact">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </p-panel>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-toolbar class="cls-component-toolbar">
                <div class="p-toolbar-group-start"></div>
                <div class="p-toolbar-group-center"></div>
                <div class="p-toolbar-group-end">
                    <p-button label="Salvar Novo" styleClass="cls-component-button" [rounded]="true"
                        icon="fa-solid fa-save" severity="primary" class="mr-2"
                        (click)="callbackClickButton(this.buttonActions.SAVE)" />
                    <p-button label="Sair" styleClass="cls-component-button" [rounded]="true"
                        icon="fa-solid fa-right-from-bracket" severity="danger"
                        (click)="callbackClickButton(this.buttonActions.EXIT)" />
                </div>
            </p-toolbar>
        </ng-template>
    </p-dialog>
</section>