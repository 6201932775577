//#region "|--- INTERFACE ---|"
import { IDropDownOptionsBasic } from '../interfaces/IDropDownOptionsBasic';
//#endregion

/**
 * @author GASPAR
 * @date 2024-10-01
 * @version 1.0.0
 * 
 * @description Opções estáticas para unidades de combustível.
 */
export const staticOptionsCgSpeedUnits: IDropDownOptionsBasic[] = [
    { label: 'kt   - KNOTS', value: 'kt' },
    { label: 'km/h - QUILÔMETROS POR HORA', value: 'kmh' },    
]