/**
 * @author GASPAR
 * @date 2024-09-27
 * @version 1.0.0
 *
 * @description Domínio de tipos de aeródromos básicos
 *  
 * */
export const domainBasicAerodromeTypes = [
    "departure", 
    "destination",
    "alt_1",
    "alt_2", 
];