//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, ElementRef, Input, AfterViewInit, SimpleChanges, OnChanges, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-app-platform--aircraft-view-perspective',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './aircraft-view-perspective.component.html'
})
export class AircraftViewPerspectiveComponent implements AfterViewInit, OnChanges {
  //#region "|--- INPUTS ---|"

  //#endregion

  //#region "|--- PROPERTIES---|"
  // ***** SCREEN ***** //
  _innerWidth!: number; // Largura da tela do usuário

   // ***** DRAW ***** //
   _hostDrawElement!: any; // Elemento D3 do componente DrawCGLimits.
   _divFrameDraw!: any; // Elemento div do componente DrawCGLimits.
   _axisX!: any;
   _axisY!: any;

   // ***** SVG ***** //
  _svgDraw!: any;  // SVG do componente DrawCGLimits.
  _svgDrawWidth!: number;  // Largura do SVG do componente DrawCGLimits.
  _svgDrawHeight!: number; // Altura do SVG do componente DrawCGLimits.
  _svgDrawMargin!: any;  // Margens do SVG do componente DrawCGLimits.
  //#endregion

  constructor(
    private _element: ElementRef
  ) { }

 
  // Método que é chamado após a renderização do componente (view).
  ngAfterViewInit(): void {
    /**********************************************************/
    // ***** AÇÕES QUE DEVEM SER FEITAS SOMENTE UMA VEZ ***** //
    /**********************************************************/

    // Quando for recuperar elementos da interface, é preciso esperar ele renderizar para poder recurar. Por isto é preciso usar o AfterViewInit, pois ele executa após a renderização.   
    // Se executar estes comando dentro do OnInit, ele não vai encontrar os elementos, pois eles ainda não foram renderizados (criados).    
    // Neste exemplo, bodySelection será uma seleção do elemento svg com o seletor "#id-svg-draw". 
    this._hostDrawElement = this._element.nativeElement.querySelector("#id-svg-draw");

    // Utilizado como referência para pegar as dimensões do SVG do componente ChartCGLimits para manipulação. 
    // Tamanho definido no CSS, ele ajusta conforme o tamanho da tela.
    this._divFrameDraw = this._element.nativeElement.querySelector('.cls-app-platform--component-chart-cg-limits');

    // Pega o tamanho da tela do usuário. 
    this._innerWidth = window.innerWidth;

    // Desenha o gráfico do componente ChartCGLimits.
    //this._drawChart();
  }

  // Método que é chamado sempre que houver alterações nos inputs do componente.
  ngOnChanges(xChanges: SimpleChanges): void {
    /*if (xChanges['objChartCGLimits']) {
      // Handle the changes here
      console.log('objChartCGLimits has changed:', xChanges['objChartCGLimits'].currentValue);
      // You can call your methods to update the chart here

      // ATUALIZAR OS PARÂMETROS DO CHART CONFORME AS MUDANÇAS REALIZADA NO FORMULÁRIO.
      //this._drawChart();
    }*/
  }

  /*private _drawSquare(): void {
    const rectElement = document.createElementNS(this._svgElement.namespaceURI, 'rect');

    rectElement.setAttribute('width', '100');
    rectElement.setAttribute('height', '100');
    rectElement.setAttribute('x', '50');
    rectElement.setAttribute('y', '50');
    rectElement.setAttribute('fill', 'blue');

    this._svgElement.appendChild(rectElement);
  }

  private _drawSemiCircle(): void {
    const pathElement = document.createElementNS(this._svgElement.namespaceURI, 'path');

    const radius = 50;
    const centerX = 100;
    const centerY = 100;
    const startX = centerX - radius;
    const startY = centerY;
    const endX = centerX + radius;
    const endY = centerY;

    const d = `M ${startX},${startY} A ${radius},${radius} 0 0,1 ${endX},${endY} L ${centerX},${centerY} Z`;

    pathElement.setAttribute('d', d);
    pathElement.setAttribute('fill', 'red');

    this._svgElement.appendChild(pathElement);
  }*/
  //#endregion
}
