/**
 * @author GASPAR
 * @date 2024-09-29
 * @version 1.0.0
 * 
 * @description Enum para Ações de CRUD 
 */
export const REGEX_EXPRESSIONS = {
    UUID: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, // 123e4567-e89b-12d3-a456-426614174000
    AERODROME_ICAO_CODE: /^[A-Z0-9]{4}$/, // ABCD
    COORDINATE_DECIMAL: /^-?\d{1,2}\.\d+,-?\d{1,3}\.\d+$/, // 12.1234,123.1234 - Não permite espaço em branco entre ao conteúdo e a virgula.
    COORDINATE_LABEL: /^\d{4}\.\d{2,4}[NS][,\/]\s*\d{5}\.\d{2,4}[EW]$/, // 1234.1234N, 12345.12E
    COORDINATE_GMS: /^\d{2} \d{2} \d{2}[NS],\s*\d{3} \d{2} \d{2}[EW]$/, // 12 34 56N, 123 45 67E
    COORDINATE_PLAN: /^\d{4}[NS]\d{5}[EW]$/, // 1234N56789E
    DATE_DD_MM_YYYY:  /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/, // 01/01/2021
    DATE_DDMMYYYY: /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])\d{4}$/, // 01012021
    DATE_YYYYMMDD: /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/, // 20210101
    DATE_YYYY_MM_DD: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, // 2021-01-01
    DATE_DD_MM_YYYY__HH_MM: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4} ([01]\d|2[0-3]):([0-5]\d)$/, // 01/01/2021 23:59
    DATE_ISO_8601: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/, // 2021-01-01T23:59:59Z
    TIME_HH_MM: /^([01]\d|2[0-3]):([0-5]\d)$/, // 23:59
    LETTERS_NUMBER_ONLY: /^[a-zA-Z0-9-]+$/, // ABC123    
    URL: /^(http|https):\/\/[^ "]+$/, // http://www.google.com
    EMAIL: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, // email@domain,
    AIRCRAFT_MARK: /^[A-Z0-9]+$/, // ABC123 
    FLIGHT_LEVEL: /^[AF]\d{3}$|^VFR$/, // A123, F123, VFR,
    CRUISE_SPEED: /^(?:[KkNn]\d{4}|[Mm]\d{3})$/, // K1234, N1234, M123,
    FLIGHT_RULE: /^[iIvVyYzZ]$/, // I, V, Y, Z,
    NUMERIC_BR: /^\d+(\,\d{1,2})?$/, // 123, 123.45
    ALPHA_NUMERIC_NO_SPACE:  /^[a-zA-Z0-9_-]+$/, // ABC-123_E6
    COLOR_HEXADECIMAL: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, // #FFF, #FFFFFF,
    IMAGE_EXTENSION: /\.(jpe?g|png)$/, // .jpg, .jpeg, .png,
    VERSION: /^\d{1,2}\.\d{1,2}\.\d{1,2}$/, // 1.0.0,
    VERSION_SHORT: /^\d{1,2}\.\d{1,2}$/, // 1.0,
    AIRCRAFT_ICAO_CODE: /^[A-Z0-9]+$/, // C152
}