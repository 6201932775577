export class InternationalizationHandler {
    static getTranslation(xId: string): string {
        return _translation(xId)
    }
}

// Tradução do plano de voo em ingles: https://www.faa.gov/air_traffic/publications/atpubs/fs_html/appendix_a.html OU https://files.eurofpl.eu/originalfpl/pdfs/EuroFPL-ICAO_Flightplan_Form_Basics-latest.pdf
// Tradução do plano de voo em espanhol: 

function _translation(xId: string): string {
    const tempType = xId.split("_")[0];
    const tempContent = `app_${xId.replaceAll("_", "")}`;

    const objTye: { [key: string]: string } = {
        lbl: "label",
        txt: "text",
        btn: "button",
        plc: "placeholder",
        ttl: "title",
        msg: "message",
        ttp: "tooltip",
        adv: "advice",
        wek: "week",
        mth: "month",        
        cnt: "country",
        opt: "option"
    }

    const objTranslateContent: { [key: string]: { [key: string]: string } } = {
        label: {
            app_lblArm: $localize`:@@app_lblArm:Braço`,   
            app_lblArmLongitudinal: $localize`:@@app_lblArmLongitudinal:Braço Longitudinal`,
            app_lblArmLateral: $localize`:@@app_lblArmLateral:Braço Lateral`,         
            app_lblAerodromeAlternative: $localize`:@@app_lblAerodromeAlternative:Aeródromo Altn`,
            app_lblAerodrome2Alternative: $localize`:@@app_lblAerodrome2Alternative:2º Aeródromo Altn`,
            app_lblAerodromeDeparture: $localize`:@@app_lblAerodromeDeparture:Aeródromo de Partida`,
            app_lblAerodromeDestination: $localize`:@@app_lblAerodromeDestination:Aeródromo de Destino`,
            app_lblAircraft: $localize`:@@app_lblAircraft:Aeronaves`, 
            app_lblAircraftFlightRule: $localize`:@@app_lblAircraftFlightRule:Regra de Voo`,
            app_lblAircraftIcaoCode: $localize`:@@app_lblAircraftIcaoCode:ICAO Code`,
            app_lblAircraftMark: $localize`:@@app_lblAircraftMark:Matrícula`,
            app_lblAircraftModel: $localize`:@@app_lblAircraftModel:Modelo`,
            app_lblAircraftOperator: $localize`:@@app_lblAircraftOperator:Operador da Aeronave`,            
            app_lblAircraftType: $localize`:@@app_lblAircraftType:Tipo de Aeronave`,
            app_lblAircraftWeightBalanceSheet: $localize`:@@app_lblAircraftWeightBalanceSheet:Ficha de Peso e Balanceamento`, 
            app_lblBelongsBem: $localize`:@@app_lblBelongsBem:Pertence ao BEM`,
            app_lblBirthday: $localize`:@@app_lblBirthday:Aniversário`,        
            app_lblBloodType: $localize`:@@app_lblBloodType:Tipo Sanguíneo`,   
            app_lblCapacity: $localize`:@@app_lblCapacity:Capacidade`,
            app_lblConfirmation: $localize`:@@app_lblConfirmation:Confirmação`,
            app_lblContactName: $localize`:@@app_lblContactName:Nome do Contato`,
            app_lblContactRelation: $localize`:@@app_lblContactRelation:Parentesco do Contato`,
            app_lblCountryNationality: $localize`:@@app_lblCountryNationality:País de Nacionalidade`,
            app_lblCrewRemark: $localize`:@@app_lblCrewRemark:Observações da Tripulação`,
            app_lblCruiseSpeed: $localize`:@@app_lblCruiseSpeed:Velocidade de Cruzeiro`,
            app_lblDimensionFuselageHeight: $localize`:@@app_lblDimensionFuselageHeight:Altura da Fuselagem`,
            app_lblDimensionFuselageLength: $localize`:@@app_lblDimensionFuselageLength:Comprimento da Fuselagem`,
            app_lblDimensionFuselageWidth: $localize`:@@app_lblDimensionFuselageWidth:Largura da Fuselagem`,
            app_lblDimensionLandingGearLength: $localize`:@@app_lblDimensionLandingGearLength:Comprimento do Trem de Pouso`,
            app_lblDimensionLandingGearTrack: $localize`:@@app_lblDimensionLandingGearTrack:Distância entre os Trens Principal`,
            app_lblDimensionLateralSpan: $localize`:@@app_lblDimensionLateralSpan:Envergadura Lateral`,
            app_lblDimensionOverallHeight: $localize`:@@app_lblDimensionOverallHeight:Altura Total`,
            app_lblDimensionOverallLength: $localize`:@@app_lblDimensionOverallLength:Comprimento Total`,
            app_lblDimensionMainRotorDiameter: $localize`:@@app_lblDimensionMainRotorDiameter:Diâmetro do Rotor Principal`,
            app_lblDimensionMainRotorGroundClearance: $localize`:@@app_lblDimensionMainRotorGroundClearance:Distância Rotor Principal ao Solo`,
            app_lblDimensionTailplaneSpan: $localize`:@@app_lblDimensionTailplaneSpan:Envergadura da Cauda`,
            app_lblDimensionTailRotorDiameter: $localize`:@@app_lblDimensionTailRotorDiameter:Diâmetro do Rotor de Cauda`,
            app_lblDimensionWidth: $localize`:@@app_lblDimensionWidth:Largura`,
            app_lblDimensionWingspan: $localize`:@@app_lblDimensionWingspan:Envergadura`,
            app_lblDocumentPersonal: $localize`:@@app_lblDocumentPersonal:Documento Pessoal`,
            app_lblDocumentPersonalType: $localize`:@@app_lblDocumentPersonalType:Tipo de Doc. Pessoal`,
            app_lblDocumentPersonalExtra: $localize`:@@app_lblDocumentPersonalExtra:Documento Pessoal Extra`,
            app_lblDocumentPersonalExtraType: $localize`:@@app_lblDocumentPersonalExtraType:Tipo de Doc. Pessoal Extra`,
            app_lblDocumentProfessional: $localize`:@@app_lblDocumentProfessional:Documento Profissional`, 
            app_lblDocumentProfessionalType: $localize`:@@app_lblDocumentProfessionalType:Tipo de Doc. Profissional`,
            app_lblEetTime: $localize`:@@app_lblEetTime:EET Total`,                                   
            app_lblEndurance: $localize`:@@app_lblEndurance:Autonomia`,
            app_lblEobtDate: $localize`:@@app_lblEobtDate:Data EOBT`,
            app_lblEobtTime: $localize`:@@app_lblEobtTime:Hora EOBT`,            
            app_lblFirstInCommand: $localize`:@@app_lblFirstInCommand:Primeiro Comandante`,
            app_lblFlightLevel: $localize`:@@app_lblFlightLevel:Nível de Voo`,
            app_lblFlightRule: $localize`:@@app_lblFlightRule:Regra de Voo`,
            app_lblFlightTime: $localize`:@@app_lblFlightTime:Tempo de Voo`,
            app_lblFuel: $localize`:@@app_lblFuel:Combustível`,
            app_lblFuelAverageRateFlight: $localize`:@@app_lblFuelAverageRateFlight:Consumo Médio em Voo`, 
            app_lblFuelAverageRateTaxi: $localize`:@@app_lblFuelAverageRateTaxi:Consumo Médio Taxi`, 
            app_lblFuelCgTable: $localize`:@@app_lblFuelCgTable:Tabela de Combustível vs CG`,                      
            app_lblFuelConsumption: $localize`:@@app_lblFuelConsumption:Consumo Combustível`,
            app_lblFuelConsumptionFlight: $localize`:@@app_lblFuelConsumptionFlight:Consumo Combustível em Voo`,
            app_lblFuelConsumptionTaxi: $localize`:@@app_lblFuelConsumptionTaxi:Consumo Combustível em Taxi`,
            app_lblFuelRateInFlight: $localize`:@@app_lblFuelRateInFlight:Taxa de Consumo em Voo`,
            app_lblFuelRateInTaxi: $localize`:@@app_lblFuelRateInTaxi:Taxa de Consumo em Taxi`,
            app_lblFuelRemark: $localize`:@@app_lblFuelRemark:Observações do Combustível`,
            app_lblFuelType: $localize`:@@app_lblFuelType:Tipo de Combustível`, 
            app_lblFuelUnit: $localize`:@@app_lblFuelUnit:Unidade Combustível`,
            app_lblFullName: $localize`:@@app_lblFullName:Nome Completo`,                        
            app_lblHeight: $localize`:@@app_lblHeight:Altura`,      
            app_lblGender: $localize`:@@app_lblGender:Gênero`,
            app_lblImage: $localize`:@@app_lblImage:Imagem`,
            app_lblImageOrientation: $localize`:@@app_lblImageOrientation:Orientação da Imagem`,
            app_lblInputType: $localize`:@@app_lblInputType:Tipo de Entrada`,
            app_lblLabel: $localize`:@@app_lblLabel:Rótulo`,
            app_lblLandingDataTime: $localize`:@@app_lblLandingDataTime:Data/Hora Pouso`,
            app_lblLengthUnit: $localize`:@@app_lblLengthUnit:Unidade Comprimento`,
            app_lblManufacturer: $localize`:@@app_lblManufacturer:Fabricante`,
            app_lblMinimumLandingDistance: $localize`:@@app_lblMinimumLandingDistance:Distância Mínima de Pouso`,
            app_lblMinimumTakeoffDistance: $localize`:@@app_lblMinimumTakeoffDistance:Distância Mínima de Decolagem`,
            app_lblMlw: $localize`:@@app_lblMlw:MLW`,
            app_lblMrw: $localize`:@@app_lblMrw:MRW`,
            app_lblMomentLateral: $localize`:@@app_lblMomentLateral:Momento Lateral`,
            app_lblMomentLongitudinal: $localize`:@@app_lblMomentLongitudinal:Momento Longitudinal`,
            app_lblMtow: $localize`:@@app_lblMtow:MTOW`,
            app_lblMzfw: $localize`:@@app_lblMzfw:MZFW`,
            app_lblNickName: $localize`:@@app_lblNickName:Apelido`,
            app_lblOperator: $localize`:@@app_lblOperator:Operador`,
            app_lblOperacionalCeiling: $localize`:@@app_lblOperacionalCeiling:Teto Operacional`,
            app_lblPageFromTo: $localize`:@@app_lblPageFromTo:Página {first} de {last}`,
            app_lblPaxRemark: $localize`:@@app_lblPaxRemark:Observações dos Passageiro`,
            app_lblPerformanceCategory: $localize`:@@app_lblPerformanceCategory:Categoria de Performance`,
            app_lblPhoneEmergency: $localize`:@@app_lblPhoneEmergency:Telefone de Emergência`,
            app_lblRadiusForInsideGear: $localize`:@@app_lblRadiusForInsideGear:Raio Giro do Trem Interno`,
            app_lblRadiusForNoseWheel: $localize`:@@app_lblRadiusForNoseWheel:Raio Giro do Trem Nariz`,
            app_lblRadiusForOutsideGear: $localize`:@@app_lblRadiusForOutsideGear:Raio Giro do Trem Externo`,
            app_lblRadiusForWingTip: $localize`:@@app_lblRadiusForWingTip:Raio Giro da Ponta da Asa`,
            app_lblRange: $localize`:@@app_lblRange:Alcance`,
            app_lblRole: $localize`:@@app_lblRole:Função`,
            app_lblSecondInCommand: $localize`:@@app_lblSecondInCommand:Segundo Comandante`,
            app_lblSerial: $localize`:@@app_lblSerial:Serial`,
            app_lblSerialNumber: $localize`:@@app_lblSerialNumber:Número Serial`,
            app_lblSheet: $localize`:@@app_lblSheet:Ficha`,
            app_lblSheetDescription: $localize`:@@app_lblSheetDescription:Descrição da Ficha`,
            app_lblSheetName: $localize`:@@app_lblSheetName:Nome da Ficha`, 
            app_lblSheetVersion: $localize`:@@app_lblSheetVersion:Versão da Ficha`, 
            app_lblTaxiTime: $localize`:@@app_lblTaxiTime:Tempo de Taxi`,        
            app_lblTipTank: $localize`:@@app_lblTipTank:Tip Tank`,  
            app_lblTotal: $localize`:@@app_lblTotal:Total`,
            app_lblTotalCapacity: $localize`:@@app_lblTotalCapacity:Capacidade Total`,
            app_lblTurbulenceCategory: $localize`:@@app_blTurbulenceCategory:Categoria de Turbulência`,                        
            app_lblType: $localize`:@@app_lblType:Tipo`,
            app_lblUsefulCapacity: $localize`:@@app_lblUsefulCapacity:Capacidade Utilizável`,
            app_lblWeight: $localize`:@@app_lblWeight:Peso`,
            app_lblWeightUnit: $localize`:@@app_lblWeightUnit:Unidade Peso`,
            app_lblWeightMax: $localize`:@@app_lblWeightMax:Peso Máximo`,
            app_lblVersion: $localize`:@@app_lblVersion:Versão`,
            

            
            
            



            app_lblMark: $localize`:@@app_lblMark:Matrícula Aeronave`,            
            app_lblCountryRegister: $localize`:@@app_lblCountryRegister:País de Registro`,            
                                                            
            app_lblYearManufactured: $localize`:@@app_lblYearManufactured:Ano Fabricação`,
            app_lblAircraftColors: $localize`:@@app_lblAircraftColor:Cores da Aeronave`,
            app_lblFin: $localize`:@@app_lblFin:Fin`,
            app_lblAircraftName: $localize`:@@app_lblAircraftName:Nome da Aeronave`,
            app_lblRegisterRemark: $localize`:@@app_lblRegisterRemark:Observações do Registro`,            
            app_lblDocuments: $localize`:@@app_lblDocuments:Documentos`,
            app_lblName: $localize`:@@app_lblName:Nome`,                                    
            app_lblAircraftDataLoad: $localize`:@@app_lblAircraftLoad:Carregar Dados da Aeronave`,
            app_lblOwner: $localize`:@@app_lblOwner:Proprietário`,                                                                                                         
            app_lblRadioNavigation: $localize`:@@app_lblRadioNavigation:Rádio e Navegação`,
            app_lblSsr: $localize`:@@app_lblSsr:VigiLância`,
            app_lblPbn: $localize`:@@app_lblPbn:PBN`,                                                                                    
            app_lblIdWeightBalanceSheet: $localize`:@@app_lblIdWeightBalanceSheet:Id. da Ficha de Pesos e Balanceamento`,
            
            app_lblDateTimeVersion: $localize`:@@app_lblDateTimeVersion:Data/Hora (Z) da Versão`,                        
            app_lblWeightBalanceType: $localize`:@@app_lblWeightBalanceType:Tipo de Peso e Balanceamento`,
            app_lblCalibrationDate: $localize`:@@app_lblCalibrationDate:Data de Calibração`,
            app_lblCalibrationDateNext: $localize`:@@app_lblCalibrationDateNext:Próxima Calibração`,
            app_lblCalibrationDateRemark: $localize`:@@app_lblCalibrationDateRemark:Observações da Calibração`,
            app_lblAircraftManualId: $localize`:@@app_lblAircraftManualId:Id. do Manual da Aeronave`,
            app_lblAircraftManualDate: $localize`:@@app_lblAircraftManualDate:Data do Manual da Aeronave`,
            app_lblAircraftManualVersion: $localize`:@@app_lblAircraftManualVersion:Versão do Manual da Aeronave`,
            app_lblWeightBalanceSheetRemark: $localize`:@@app_lblWeightBalanceSheetRemark:Observações da Ficha de Pesos e Balanceamento`,
                                    
            app_lblBem: $localize`:@@app_lblBem:BEM - Peso Vazio Básico`,
            app_lblCertificatedWeights: $localize`:@@app_lblCertificatedWeights:Pesos Certificados`,
            
            app_lblDataCopiedClipboard: $localize`:@@app_lblDataCopiedClipboard:Dados Copiados`,
            app_lblCgLongitudinal: $localize`:@@app_lblCgLongitudinal:CG Longitudinal`,
            app_lblCgLateral: $localize`:@@app_lblCgLateral:CG Lateral`,
            app_lblLongitudinalDatum: $localize`:@@app_lblLongitudinalDatum:Datum Longitudinal`,
            app_lblLateralDatum: $localize`:@@app_lblLateralDatum:Datum Lateral`,
            app_lblLongitudinalForwardLimit: $localize`:@@app_lblLongitudinalForwardLimit:Limite Longitudinal Anterior`,
            app_lblLongitudinalAftLimit: $localize`:@@app_lblLongitudinalAftLimit:Limite Longitudinal Posterior`,
            app_lblLateralLeftLimit: $localize`:@@app_lblLateralLeftLimit:Limite Lateral Esquerdo`,
            app_lblLateralRightLimit: $localize`:@@app_lblLateralRightLimit:Limite Lateral Direito`,
            
        },
        text: {
            app_txtAircraftDataAlter: $localize`:@@app_txtAircraftDataAlter:Alterar os Dados de uma Aeronave no Sistema.`,
            app_txtAircraftDataNew: $localize`:@@app_txtAircraftDataNew:Inserir Dados de uma Nova Aeronave no Sistema.`,
            app_txtAircraftDataList: $localize`:@@app_txtAircraftDataList:Listar Dados de Aeronaves no Sistema.`,
            app_txtCrewDataAlter: $localize`:@@app_txtCrewDataAlter:Alterar os Dados de um Tripulante no Sistema.`,
            app_txtCrewDataNew: $localize`:@@app_txtCrewDataNew:Inserir Dados de um Novo Tripulante no Sistema.`,    
            app_txtCrewDataList: $localize`:@@app_txtCrewDataList:Listar Dados de Tripulantes no Sistema.`, 
            app_txtFlightPlanningAlter: $localize`:@@app_txtFlightPlanningAlter:Alterar os Dados de um Planejamento de Voo no Sistema.`,
            app_txtFlightPlanningNew: $localize`:@@app_txtFlightPlanningNew:Inserir Dados de um Nova Planejamento de Voo no Sistema.`,
            app_txtPaxDataAlter: $localize`:@@app_txtPaxDataAlter:Alterar os Dados de Passageiros no Sistema.`,
            app_txtPaxDataList: $localize`:@@app_txtPaxDataList:Listar Dados de Passageiros no Sistema.`,
            app_txtPaxDataNew: $localize`:@@app_txtPaxDataNew:Inserir Dados de Novos Passageiros no Sistema.`,                                            
        },
        button: {
            app_btnAuxiliary: $localize`:@@app_btnAuxiliary:Auxiliar`,
            app_btnCancelar: $localize`:@@app_btnCancelar:Cancelar`,
            app_btnConverter: $localize`:app_btnConverter:Conversor`,
            app_btnCopy: $localize`:app_btnCopy:Copiar`,
            app_btnClonar: $localize`:app_btnClonar:Clonar`,
            app_btnDelete: $localize`:app_btnDelete:Excluir`,
            app_btnEditTable: $localize`:app_btnEditTable:Editar Tabela`,
            app_btnGoOut: $localize`:app_btnMark:Sair`,
            app_btnImage: $localize`:app_btnImage:Imagem`,
            app_btnList: $localize`:app_btnSearch:Listar`,
            app_btnLoad: $localize`:app_btnLoad:Carregar`,
            app_btnNew: $localize`:app_btnNew:Novo`,
            app_btnNewAircraftData: $localize`:app_btnNewAircraftData:Nova Aeronave`,
            app_btnNewCrewData: $localize`:app_btnNewCrewData:Nova Tripulação`,
            app_btnNewLine: $localize`:app_btnNewLine:Nova Linha`,
            app_btnNewPaxData: $localize`:app_btnNewPaxData:Novo Passageiro`,
            app_btnNewSheet: $localize`:app_btnNewSheet:Nova Ficha`,
            app_btnOK: $localize`:app_btnOK:OK`,
            app_btnPDF: $localize`:app_btnPDF:PDF`,
            app_btnReloadTable: $localize`:app_btnReloadTable:Recarregar Tabela`,
            app_btnPreview: $localize`:app_btnPreview:Prévia`,
            app_btnSaveModifications: $localize`:app_btnSaveModification:Salvar Modificações`,
            app_btnSaveNew: $localize`:app_btnSaveNew:Salvar Novo`,
            app_btnSaveDate: $localize`:app_btnSaveDate:Salvar Dados`,
            app_btnSave: $localize`:app_btnSave:Salvar`,            
            app_btnSearch: $localize`:app_btnSearch:Buscar`                                    
        },
        placeholder: {

        },
        title: {       
            app_ttlAddresses: $localize`:@@app_ttlAddresses:Endereços`,
            app_ttlAerodromes: $localize`:@@app_ttlAerodromes:Aeródromos`,   
            app_ttlAircraft: $localize`:@@app_ttlAircraft:Aeronave`,                    
            app_ttlAircraftAndCrew: $localize`:@@app_ttlAircraftAndCrew:Aeronave e Tripulação`,     
            app_ttlAircraftData: $localize`:@@app_ttlAircraftData:Dados da Aeronave`,
            app_ttlAircraftDataAlter: $localize`:@@app_ttlAircraftDataAlter:Alterar Dados da Aeronave`,
            app_ttlAircraftDataNew: $localize`:@@app_ttlAircraftDataNew:Novo Registro de Aeronave`, 
            app_ttlAircraftDataSave: $localize`:@@app_ttlAircraftDataSave:Salvar Dados da Aeronave`, 
            app_ttlAircraftDataList: $localize`:@@app_ttlAircraftDataList:Listar Aeronaves`,  
            app_ttlAircraftDataListLoad: $localize`:@@app_ttlAircraftDataListLoad:Carregar Lista de Aeronaves`, 
            app_ttlAircraftDataUpdate: $localize`:@@app_ttlAircraftDataUpdate:Atualizar Dados da Aeronave`,
            app_ttlAircraftPicture: $localize`:@@app_ttlAircraftPictures:Foto da Aeronave`,
            app_ttlAircraftRegisteredSystem: $localize`:@@app_ttlAircraftRegisteredSystem:Aeronaves Registradas no Sistema`,
            app_ttlBasicPerformance: $localize`:@@app_ttlBasicPerformance:Performance Básica`,
            app_ttlCertificatedWeights: $localize`:@@app_ttlCertificatedWeights:Pesos Certificados`,
            app_ttlCoasts: $localize`:@@app_ttlCoasts:Custos`,            
            app_ttlContacts: $localize`:@@app_ttlContacts:Contatos`,
            app_ttlCgComponents: $localize`:@@app_ttlCgComponents:Componentes do CG`,
            app_ttlCgComponentsConfirmDelete: $localize`:@@app_ttlCgComponentsConfirmDelete:Exclusão dos Componentes do CG`,
            app_ttlCgComponentsDelete: $localize`:@@app_ttlCgComponentsDelete:Excluir Componentes do CG`, 
            app_ttlCgComponentsDominions: $localize`:@@app_ttlCgComponentsDominions:Domínios dos Componentes do CG`,
            app_ttlCgComponentsEditTable: $localize`:@@app_ttlCgComponentsEditTable:Editar Tabela de Componentes do CG`,
            app_ttlCgComponentsConstants: $localize`:@@app_ttlCgComponentsConstants:Destacáveis`,
            app_ttlCgComponentsExternals: $localize`:@@app_ttlCgComponentsExternals:Externos`,
            app_ttlCgComponentsFuel: $localize`:@@app_ttlCgComponentsFuel:Combustível`,
            app_ttlCgComponentsInternals: $localize`:@@app_ttlCgComponentsInternals:Internos`,
            app_ttlCgComponentsTableDataCsv: $localize`:@@app_ttlCgComponentsTableDataCsv:Dados da Tabela de Componentes do CG no Formato CSV`,
            app_ttlComplementaryData: $localize`:@@app_ttlComplementaryData:Dados Complementares`,
            app_ttlCrewRegisteredSystem: $localize`:@@app_ttlCrewRegisteredSystem:Tripulantes Registrados no Sistema`,
            app_ttlCrewAndPaxShort: $localize`:@@app_ttlCrewAndPaxShort:Tripul. e Passageiros`,
            app_ttlCrewData: $localize`:@@app_ttlCrewData:Dados do Tripulante`,
            app_ttlCrewDataAlter: $localize`:@@app_ttlCrewDataAlter:Alterar Dados do Tripulante`,
            app_ttlCrewDataList: $localize`:@@app_ttlCrewDataList:Listar Tripulantes`,
            app_ttlCrewDataListLoad: $localize`:@@app_ttlCrewDataListLoad:Carregar Lista de Tripulantes`, 
            app_ttlCrewDataRecover: $localize`:@@app_ttlCrewDataRecover:Recuperar Dados do Tripulante`,
            app_ttlCrewDataNew: $localize`:@@app_ttlCrewDataNew:Novo Registro de Tripulante`,
            app_ttlCrewDataSave: $localize`:@@app_ttlCrewDataSave:Salvar Dados do Tripulante`,
            app_ttlCrewPicture: $localize`:@@app_ttlCrewPicture:Foto do Tripulante`,
            app_ttlCrewRegister: $localize`:@@app_ttlCrewRegister:Cadastro do Tripulantes`,              
            app_ttlChooseFile: $localize`:@@app_ttlChooseFiles:Escolher Arquivo`,
            app_ttlDocumentation: $localize`:@@app_ttlDocumentation:Documentação`,
            app_ttlDocuments: $localize`:@@app_ttlDocuments:Documentos`,          
            app_ttlDimensions: $localize`:@@app_ttlDimensions:Dimensões`,
            app_ttlFlight: $localize`:@@app_ttlFlight:Voo`,
            app_ttlFlightData: $localize`:@@app_ttlFlightData:Dados do Voo`,
            app_ttlFlightDataLoad: $localize`:@@app_ttlFlightDataLoad:Carregar Dados do Voo`,
            app_ttlFlightDataRecover: $localize`:@@app_ttlFlightDataRecover:Recuperar Dados do Voo`,
            app_ttlFlightPlanningAlter: $localize`:@@app_ttlFlightPlanningAlter:Alterar Dados do Planejamento de Voo`,
            app_ttlFlightPlanningNew: $localize`:@@app_ttlFlightPlanningNew:Novo Registro de Planejamento de Voo`,
            app_ttlFlightRequest: $localize`:@@app_ttlFlightRequest:Requisição de Voo`,
            app_ttlFlightPlanning: $localize`:@@app_ttlFlightPlanning:Planejamento de Voo`,
            app_ttlFuel: $localize`:@@app_ttlFuel:Combustível`, 
            app_ttlGeneratePreviewPdf: $localize`:@@app_ttlGeneratePreviewPdf:Gerar Prévia em PDF`,
            app_ttlGeneratePreviewImage: $localize`:@@app_ttlGeneratePreviewImage:Gerar Prévia em Imagem`,          
            app_ttlMaintenance: $localize`:@@app_ttlMaintenance:Manutenção`, 
            app_ttlNotebookShort: $localize`:@@app_ttlNotebookShort:Diário`,
            app_ttlOwnerOperatorDataList: $localize`:@@app_ttlOwnerOperatorDataList:Listar Proprietários e Operadores`,
            app_ttlOwnerOperatorLoad: $localize`:@@app_ttlOwnerOperatorLoad:Carregar Proprietários e Operadores`,
            app_ttlOwnerOperatorSave: $localize`:@@app_ttlOwnerOperatorSave:Salvar Proprietário e Operador`,
            app_ttlOwnerOperatorUpdate: $localize`:@@app_ttlOwnerOperatorUpdate:Atualizar Proprietário e Operador`,
            app_ttlPasteContent: $localize`:@@app_ttlPasteContent:Colar Conteúdo`,
            app_ttlPaxRegisteredSystem: $localize`:@@app_ttlPaxRegisteredSystem:Passageiros Registrados no Sistema`,
            app_ttlPaxDataAlter: $localize`:@@app_ttlPaxDataAlter:Alterar Dados de Passageiros`,            
            app_ttlPaxDataConfirmDelete: $localize`:@@app_ttlPaxDataConfirmDelete:Exclusão de Dados de Passageiros`,
            app_ttlPaxDataDelete: $localize`:@@app_ttlPaxDataDelete:Excluir Dados de Passageiros`,
            app_ttlPaxDataList: $localize`:@@app_ttlPaxDataList:Listar Passageiros`,   
            app_ttlPaxDataListLoad: $localize`:@@app_ttlPaxDataListLoad:Carregar Lista de Passageiros`,         
            app_ttlPaxDataNew: $localize`:@@app_ttlPaxDataNew:Novo Registro de Passageiros`,
            app_ttlPaxDataRecover: $localize`:@@app_ttlPaxDataRecover:Recuperar Dados de Passageiro`,
            app_ttlPaxDataRegister: $localize`:@@app_ttlPaxDataRegister:Registro de Passageiros`,
            app_ttlPaxDataSave: $localize`:@@app_ttlPaxDataSave:Salvar Dados de Passageiro`,
            app_ttlPaxDataUpdate: $localize`:@@app_ttlPaxDataUpdate:Atualizar Dados de Passageiro`,
            app_ttlPhysicalAspects: $localize`:@@app_ttlPhysicalAspects:Aspectos Físicos`,    
            app_ttlRadioSsrPbn: $localize`:@@app_ttlRadioSsrPbn:Rádio, SSR e PBN`,
            app_ttlRegister: $localize`:@@app_ttlRegister:Registro`,
            app_ttlRequestAnacBrConfirm: $localize`:@@app_ttlRequestAnacBrConfirm:Deseja recuperar os dados da Matrícula ##0## no RAB/ANAC/BRASIL?`,
            app_ttlRequestAnacBrRequest: $localize`:@@app_ttlRequestAnacBrRequest:Pesquisar RAB/ANAC/BR`, 
            app_ttlSharingShort: $localize`:@@app_ttlSharingShort:Compart.`,
            app_ttlSheetToClone: $localize`:@@app_ttlSheetToClone:Ficha para Clonar`,
            app_ttlSpecifications: $localize`:@@app_ttlSpecifications:Especificações`, 
            app_ttlWaitingPlease: $localize`:@@app_ttlWaitingPlease:Aguarde por favor...`,
            app_ttlWeightBalanceDataSheetList: $localize`:@@app_ttlWeightBalanceDataSheetList:Listar Fichas de Pesos e Balanceamento`,
            app_ttlWeightBalanceDataSheetSave: $localize`:@@app_ttlWeightBalanceDataSheetSave:Salvar Ficha de Pesos e Balanceamento`,
            app_ttlWeightBalanceDataSheetLoad: $localize`:@@app_ttlWeightBalanceDataSheetLoad:Carregar Ficha de Pesos e Balanceamento`,
            app_ttlWeightBalance: $localize`:@@app_ttlWeightBalance:Pesos e Balanceamento`,
            app_ttlWeightBalanceShort: $localize`:@@app_ttlWeightBalanceShort:Pesos e Balanc.`,            
            app_ttlWeightBalanceDataSheet: $localize`:@@app_ttlWeightBalanceDataSheet:Ficha de Peso e Balanceamento`,
            app_ttlWeightBalanceDataSheetListToClone: $localize`:@@app_ttlWeightBalanceDataSheetListToClone:Listar Fichas para Clonar`,
            app_ttlWeightBalanceDataSheetFuelConsumption: $localize`:@@app_ttlWeightBalanceDataSheetFuelConsumption:Consumo de Combustível`,
            app_ttlWeightBalanceDataSheetHeader: $localize`:@@app_ttlWeightBalanceDataSheetHeader:Cabeçalho da Ficha de Pesos e Balanceamento`,            
            app_ttlWeightBalanceDataSheetUnits: $localize`:@@app_ttlWeightBalanceDataSheetUnits:Unidades da Ficha de Pesos e Balanceamento`,
            app_ttlWeightBalanceDataSheetUpdate: $localize`:@@app_ttlWeightBalanceDataSheetUpdate:Atualizar Ficha de Pesos e Balanceamento`,
            app_ttlWeightBalanceSheetConfig: $localize`:@@app_ttlWeightBalanceSheetConfig:Configuração da Ficha de Pesos e Balanceamento`,                        
            app_ttlTimeZones: $localize`:@@app_ttlTimeZones:Fusos Horários`,
            app_ttlUploadImage: $localize`:@@app_ttlUploadImage:Carregar Imagem`,
            
            
            

                    
            app_ttlAeronauticalRegister: $localize`:@@app_ttlAeronauticalRegister:Registro Aeronáutico`,
            app_ttlOwnersList: $localize`:@@app_ttlOwnersList:Lista de Proprietários`,
            app_ttlOperatorsList: $localize`:@@app_ttlOperatorsList:Lista de Operadores`,
            app_ttlLoadOwnerOperatorsData: $localize`:@@app_ttlLoadOwnerOperatorsData:Recuperar Dado ##0##`,                                                
            
            app_ttlFlightPreview: $localize`:@@app_ttlPreview:Prévia do Voo`,
                                                                        
            app_ttlEquipmentRadioNavigation: $localize`:@@app_ttlEquipmentRadioNavigation:Equipamentos de Rádio e Navegação`,
            app_ttlEquipmentSurveillance: $localize`:@@app_ttlEquipmentSurveillance:Equipamentos Vigilância`,
            app_ttlEquipmentSsr: $localize`:@@app_ttlEquipmentSsr:Equipamentos SSR`,
            app_ttlEquipmentAdsB: $localize`:@@app_ttlEquipmentSsr:Equipamentos ADS-B`,
            app_ttlEquipmentAdsC: $localize`:@@app_ttlEquipmentSsr:Equipamentos ADS-C`,
            app_ttlEquipmentPbn: $localize`:@@app_ttlEquipmentPbn:Equipamentos PBN`,
            app_ttlIdentificationVersion: $localize`:@@app_ttlIdentificationVersion:Identificação e Versão`,    
            app_ttlWeightBalanceSheetHeader: $localize`:@@app_ttlWeightBalanceSheetHeader:Cabeçalho da Ficha de Pesos e Balanceamento`, 
            app_ttlDefaultUnits: $localize`:@@app_ttlDefaultUnits:Unidades Padrões`,  
            app_ttlCalculeComponents: $localize`:@@app_ttlCalculeComponents:Componentes para Cálculo do Centro de Gravidade`, 
            app_ttlFuelCgComponentes: $localize`:@@app_ttlFuelCgComponentes:Centro de Gravidade do Combustível`,   
            app_ttlWeights: $localize`:@@app_ttlWeights:Pesos`,
            app_ttlUnitConversion: $localize`:@@app_ttlUnitConversion:Conversão de Unidades`,
            app_ttlUnitConversionType: $localize`:@@app_ttlUnitConversionType:Tipo de Conversão`,
            app_ttlUnitConversionValue: $localize`:@@app_ttlUnitConversionValue:Valor de Conversão`,            
            app_ttlUnitConvertedValue: $localize`:@@app_ttlUnitConvertedValue:Valor Convertido`,
            app_ttlUnitConvertedUnit: $localize`:@@app_ttlUnitConvertedUnit:Unidade de Conversão`,
            app_ttlCg: $localize`:@@app_ttlCgCalculation:CG`,
            app_ttlCgCalculation: $localize`:@@app_ttlCgCalculation:Calculo do Centro de Gravidade`,
            app_ttlFloorPlan: $localize`:@@app_ttlFloorPlan:Planta Baixa`,       
        },
        message: {
            app_msgAircraftDataListError: $localize`:@@app_msgAircraftDataListError:Erro ao carregar a Lista de Aeronaves. Tente novamente.`,            
            app_msgAircraftDataListLoadError: $localize`:@@app_msgAircraftDataListLoadError:Erro ao carregar a Lista de Aeronaves. Tente novamente.`,
            app_msgAircraftDataSaving: $localize`:@@app_msgAircraftDataSaving:Salvando os Dados da Aeronave. Aguarde por favor...`,
            app_msgAircraftDataUpdating: $localize`:@@app_msgAircraftDataUpdating:Atualizando os Dados da Aeronave. Aguarde por favor...`,
            app_msgCrewDataListLoadError: $localize`:@@app_msgCrewDataListLoadError:Erro ao carregar a Lista de Tripulantes. Tente novamente.`,
            app_msgCrewDataRecoverError: $localize`:@@app_msgCrewDataRecoverError:Erro ao recuperar os Dados do Tripulante. Tente novamente.`, 
            app_msgCrewDataSaving: $localize`:@@app_msgCrewDataSaving:Salvando os Dados do Tripulante. Aguarde por favor...`,
            app_msgCgComponentsSaved: $localize`:@@app_msgCgComponentsSaved:Componentes do CG Salvos com Sucesso.`,
            app_msgCgComponentsDeleted: $localize`:@@app_msgCgComponentsDeleted:Componentes do CG Excluídos com Sucesso.`, 
            app_msgCgComponentsAskToConfirmDeleted: $localize`:@@app_msgCgComponentsAskToConfirmDeleted:Confirma a Exclusão dos Componentes do CG?`,         
            app_msgDataCopiedClipboardSuccess: $localize`:@@app_msgDataCopiedClipboardSuccess:Dados Copiados com Sucesso para a Área de Transferência`,
            app_msgDataSaveInField: $localize`:@@app_msgDataSaveInField:O dado foi salvo no campo.`,
            app_msgFileNotSupported: $localize`:@@app_msgFileNotSupported:Arquivo não suportado. Selecione um arquivo válido.`,
            app_msgFlightDataListError: $localize`:@@app_msgFlightDataListError:Erro ao carregar a Lista de Voos. Tente novamente.`,            
            app_msgFlightDataRecoverError: $localize`:@@app_msgFlightDataRecoverError:Erro ao recuperar os Dados do Voo. Tente novamente.`,
            app_msgGeneratePreviewImageClipboardSuccess: $localize`:@@app_msgGeneratePreviewImageClipboardSuccess:Imagem da Prévia gerada com sucesso e copiada para a Área de Transferência.`,
            app_msgGeneratePreviewImageError: $localize`:@@app_msgGeneratePreviewImageError:Erro ao gerar a Prévia da Imagem. Tente novamente.`,
            app_msgGeneratePreviewImageSuccess: $localize`:@@app_msgGeneratePreviewImageSuccess:Imagem da prévia gerada com sucesso.`,
            app_msgGeneratePreviewPdfClipboardSuccess: $localize`:@@app_msgGeneratePreviewPdfClipboardSuccess:PDF da Prévia gerada com sucesso e copiada para a Área de Transferência.`,
            app_msgGeneratePreviewPdfClipboardError: $localize`:@@app_msgGeneratePreviewPdfClipboardError:Erro ao gerar a Prévia em PDF e copiar para a Área de Transferência. Tente novamente.`,
            app_msgGeneratePreviewPdfError: $localize`:@@app_msgGeneratePreviewPdfError:Erro ao gerar a Prévia em PDF. Tente novamente.`,
            app_msgGeneratePreviewPdfSuccess: $localize`:@@app_msgGeneratePreviewPdfSuccess:PDF da prévia gerada com sucesso.`,
            app_msgLoadingAircraftData: $localize`:@@app_msgLoadingAircraftData:Carregando os Dados da Aeronave. Aguarde por favor...`,
            app_msgLoadingFlightRequestData: $localize`:@@app_msgLoadingFlightRequestData:Carregando as Requisições de Plano de Voo. Aguarde por favor...`,            
            app_msgLoadingAircraftList: $localize`:@@app_msgLoadingAircraftList:Carregando a Lista de Aeronaves. Aguarde por favor...`,           
            app_msgLoadingAnacBr: $localize`:@@app_msgLoadingAnacBr:Carregando os dados do RAB/ANAC/BRASIL. Aguarde por favor...`,
            app_msgLoadingData: $localize`:@@app_msgLoadingData:Carregando os Dados. Aguarde por favor...`,
            app_msgLoadingPaxData: $localize`:@@app_msgLoadingPaxData:Carregando os Dados de Passageiros. Aguarde por favor...`,
            app_msgLoadingPaxList: $localize`:@@app_msgLoadingPaxList:Carregando a Lista de Passageiros. Aguarde por favor...`,
            app_msgOwnerOperatorDataListError: $localize`:@@app_msgOwnerOperatorDataListError:Erro ao carregar a Lista de Proprietários e Operadores. Tente novamente.`,
            app_msgOwnerOperatorDataLoadError: $localize`:@@app_msgOwnerOperatorDataLoadError:Erro ao carregar os dados do Proprietário e Operador. Tente novamente.`,
            app_msgPasteClipboardError: $localize`:@@app_msgPasteClipboardError:Erro ao Colar os Dados da Área de Transferência.`,
            app_msgPasteClipboardOnlyImageText: $localize`:@@app_msgPasteClipboardOnlyImageText:Somente Imagens ou Textos podem ser colados. \nPara enviar arquivos Arraste e Solte o arquivo na área de Upload ou clique no botão "ESCOLHER ARQUIVO".`,
            app_msgPaxDataAskToConfirmDeleted: $localize`:@@app_msgPaxDataAskToConfirmDeleted:Confirma a Exclusão dos Dados de Passageiros?`,
            app_msgPaxDataDeleted: $localize`:@@app_msgPaxDataDeleted:Dados de Passageiros Excluídos com Sucesso.`,
            app_msgPaxDataListLoadError: $localize`:@@app_msgPaxDataListLoadError:Erro ao carregar os Dados de Passageiros. Tente novamente.`,
            app_msgPaxDataRecoverError: $localize`:@@app_msgPaxDataRecoverError:Erro ao recuperar os Dados do Passageiro. Tente novamente.`,
            app_msgProcessingIsRunning: $localize`:@@app_msgProcessingIsRunning:O Processo está em andamento. Aguarde por favor...`,
            app_msgUploadSuccess: $localize`:@@app_msgUploadSuccess:Arquivo carregado no Servidor com sucesso.`,
            app_msgWeightBalanceDataSheetListToCloneError: $localize`:@@app_msgWeightBalanceDataSheetListToCloneError:Erro ao listar as Fichas de Pesos e Balanceamento para Clonagem. Tente novamente.`,
            app_msgWeightBalanceDataSheetListSuccess: $localize`:@@app_msgWeightBalanceDataSheetListSuccess:Fichas de Pesos e Balanceamento listadas com sucesso.`,
            app_msgWeightBalanceDataSheetListError: $localize`:@@app_msgWeightBalanceDataSheetListError:Erro ao listar as Fichas de Pesos e Balanceamento. Tente novamente.`,
            app_msgWeighBalanceDataSheetNoLateral: $localize`:@@app_msgWeighBalanceDataSheetNoLateral: O Momento do Braço Lateral foi zerado, pois a plotagem é somente Longitudinal.`,
            app_msgWeighBalanceDataSheetSaving: $localize`:@@app_msgWeighBalanceDataSheetSaving:Salvando a Ficha de Pesos e Balanceamento. Aguarde por favor...`,
            app_msgWeighBalanceDataSheetListSuccess: $localize`:@@app_msgWeighBalanceDataSheetListSuccess:Fichas de Pesos e Balanceamento listadas com sucesso.`,
            app_msgWeighBalanceDataSheetListError: $localize`:@@app_msgWeighBalanceDataSheetListError:Erro ao listar as Fichas de Pesos e Balanceamento. Tente novamente.`,
            app_msgWeightBalanceDataSheetLoadError: $localize`:@@app_msgWeightBalanceDataSheetLoadError:Erro ao carregar a Ficha de Pesos e Balanceamento. Tente novamente.`,
            app_msgUserCancelProcessOfDelete: $localize`:@@app_msgUserCancelProcessOfDelete:O Usuário cancelou o processo de exclusão.`,
            


            app_msgMarkExisted: $localize`:@@app_msgMarkExisted:A Matrícula "##0##" já foi cadastrada no Sistema. Altere a Matrícula ou entre em contato com o Administrador do Sistema.`,
            app_msgRequestAnacBrCanceled: $localize`:@@app_msgRequestAnacBrCanceled:O processo de recuperar os dados da Matrícula ##0## no RAB da ANAC/BR foi cancelado pelo Usuário.`,
            app_msgRequestAnacBrConfirm: $localize`:@@app_msgRequestAnacBrConfirm:Ao recuperar os dados da Matrícula ##0## no RAB/ANAC/BRASIL, os dados atuais serão substituídos.`,
            app_msgRequestAnacBrError: $localize`:@@app_msgRequestAnacBrError:Erro ao recuperar os dados da Matrícula ##0## no RAB/ANAC/BRASIL. Verifique a Matrícula e tente novamente.`,
            app_msgFormFieldsNotValid: $localize`:@@app_msgFormFieldsNotValid:Os campos a seguir não foram preenchidos ou estão incorretos. Verifique-os e tente novamente.`,
            
            app_msgOwnerOperatorMustHave: $localize`:@@app_msgOwnerOperatorMustHave:O Proprietário e o Operador devem ser informados.`,
            app_msgOperatorMustHave: $localize`:@@app_msgOperatorMustHave:O Operador deve ser informado.`,
            app_msgOwnerMustHave: $localize`:@@app_msgOwnerMustHave:O Proprietário deve ser informado.`,                                                                                  
            app_msgRequestAnacBrSuccess: $localize`:@@app_msgRequestAnacBrSuccess:Os dados da Matrícula ##0## foram recuperados com sucesso do RAB/ANAC/BRASIL.`,                                    
            app_msgAircraftDataLoadError: $localize`:@@app_msgAircraftDataLoadError:Não Foi possível carregar os dados da Aeronave. Verifique a Matrícula e tente novamente.`,                                                
        },
        tooltip: {
            app_ttpAircraftFuselageHeight: $localize`:@@app_ttpAircraftFuselageHeight:Altura máxima da fuselagem, geralmente da base ao topo da seção transversal.`,
            app_ttpAircraftFuselageWidth: $localize`:@@app_ttpAircraftFuselageWidth:Largura máxima da fuselagem.`,
            app_ttpAircraftLandingGearLength: $localize`:@@app_ttpAircraftLandingGearLength:Distância entre o trem de pouso principal e o trem de pouso dianteiro (ou traseiro em aviões triciclos).`,
            app_ttpAircraftLandingGearTrack: $localize`:@@app_ttpAircraftLandingGearTrack:Distância lateral entre os trens de pouso principais.`,
            app_ttpAircraftOverallHeight: $localize`:@@app_ttpAircraftOverallHeight:Altura máxima do avião medida do solo até o ponto mais alto, geralmente o topo do estabilizador vertical.`,
            app_ttpAircraftOverallLength: $localize`:@@app_ttpAircraftOverallLength:Distância do ponto mais à frente do avião (geralmente o nariz) até o ponto mais à retaguarda (geralmente o estabilizador vertical ou cone de cauda).`,
            app_ttpAircraftRadiusForInsideGear: $localize`:@@app_ttpAircraftForRadiusInsideGear:Raio de giro mínimo com o trem de pouso interno.`,
            app_ttpAircraftRadiusForNoseWheel: $localize`:@@app_ttpAircraftForRadiusNoseWheel:Raio de giro mínimo com o trem de pouso dianteiro (nariz).`,            
            app_ttpAircraftRadiusForOutsideGear: $localize`:@@app_ttpAircraftForRadiusOutsideGear:Raio de giro mínimo com o trem de pouso externo.`,
            app_ttpAircraftRadiusForWingTip: $localize`:@@app_ttpAircraftForRadiusWingTip:Raio de giro mínimo com a ponta da asa.`,
            app_ttpAircraftTailplaneSpan: $localize`:@@app_ttpAircraftTailplaneSpan:Distância entre as extremidades dos estabilizadores horizontais.`,
            app_ttpAircraftWingspan: $localize`:@@app_ttpAircraftWingspan:Distância entre as pontas das asas, medida horizontalmente.`,
            app_ttpHelicopterMainRotorGroundClearance: $localize`:@@app_ttpHelicopterMainRotorGroundClearance:Distância entre o solo e o ponto mais baixo do rotor principal.`,
            app_ttpHelicopterLateralSpan: $localize`:@@app_ttpHelicopterLateralSpan:Distância entre os pontos mais extremos em uma vista lateral, considerando trem de pouso ou estabilizadores horizontais, se aplicável.`,
            app_ttpHelicopterFuselageLength: $localize`:@@app_ttpHelicopterFuselageLength:Comprimento da fuselagem sem considerar as pás do rotor principal ou de cauda.`,
            app_ttpHelicopterMainRotorDiameter: $localize`:@@app_ttpHelicopterMainRotorDiameter:Diâmetro do círculo descrito pelas pás do rotor principal.`,
            app_ttpHelicopterOverallHeight: $localize`:@@app_ttpHelicopterOverallHeight:Altura máxima medida do solo até o ponto mais alto do helicóptero, geralmente no topo do rotor principal.`,
            app_ttpHelicopterOverallLength: $localize`:@@app_ttpHelicopterOverallLength:Distância do ponto mais à frente do helicóptero até o ponto mais à retaguarda, incluindo as pás do rotor de cauda (se aplicável).`,
            app_ttpHelicopterTailRotorDiameter: $localize`:@@app_ttpHelicopterTailRotorDiameter:Diâmetro do círculo descrito pelas pás do rotor de cauda (se presente).`,
            app_ttpHelicopterWidth: $localize`:@@app_ttpHelicopterWidth:Largura máxima da fuselagem ou do trem de pouso.`,
            app_ttpMark: $localize`:@@app_ttpMark:A validação/pesquisa  do campo Matrícula será feita quando o campo perder o foco.`,
            app_ttpMarkNotEditable: $localize`:@@app_ttpMarkNotEditable:Campo Matrícula não editável.`,
            app_ttpMlw: $localize`:@@app_ttpMlw:Maximum Landing Weight`, 
            app_ttpMrw: $localize`:@@app_ttpMrw:Maximum Ramp Weight`,
            app_ttpMtow: $localize`:@@app_ttpMto:Maximum Takeoff Weight`,                        
            app_ttpMzfw: $localize`:@@app_ttpMzfw:Maximum Zero Fuel Weight`,
        },
        option: {
            app_optPerformanceCatA: $localize`:@@app_optPerformanceCatA:Cat. A`,
            app_optPerformanceCatB: $localize`:@@app_optPerformanceCatB:Cat. B`,
            app_optPerformanceCatC: $localize`:@@app_optPerformanceCatC:Cat. C`,
            app_optPerformanceCatD: $localize`:@@app_optPerformanceCatD:Cat. D`,
            app_optPerformanceCatE: $localize`:@@app_optPerformanceCatE:Cat. E`,
            app_optPerformanceCatH: $localize`:@@app_optPerformanceCatH:Cat. H`,
            app_optWakeTurbulenceJ: $localize`:@@app_optWakeTurbulenceJ:J-Super`,
            app_optWakeTurbulenceH: $localize`:@@app_optWakeTurbulenceH:H-Pesada`,
            app_optWakeTurbulenceM: $localize`:@@app_optWakeTurbulenceM:M-Média`,
            app_optWakeTurbulenceL: $localize`:@@app_optWakeTurbulenceL:L-Leve`,
            app_optLongitudinal: $localize`:@@app_optLongitudinal:Longitudinal`,
            app_optLateral: $localize`:@@app_optLateral:Lateral`,
            app_optLongitudinalLateral: $localize`:@@app_optLongitudinalLateral:Longitudinal e Lateral`,
            app_optLibraPound: $localize`:@@app_optLibraPound:lb - Libra`,
            app_optKilogram: $localize`:@@app_optKilogram:kg - Quilograma`,
            app_optMilimetres: $localize`:@@app_optMilimetres:mm - Milímetro`,
            app_optCentimeters: $localize`:@@app_optCentimeters:cm - Centímetro`,
            app_optMeters: $localize`:@@app_optMeters:m - Metro`,
            app_optInches: $localize`:@@app_optInches:in - Polegada`,
            app_optLiters: $localize`:@@app_optLiters:l -Litros`,
            app_optGallons: $localize`:@@app_optGallons:gal - Galões`,
        },
        advice: {
            app_advAircraftListInitialOrder: $localize`:@@app_advAircraftListInitialOrder:Ordem Inicial da Lista: Matrícula (Crescente).`,
            app_advAircraftDimensions: $localize`:@@app_advAircraftDimensions:As Dimensões são usadas para o Cálculo do Centro de Gravidade, alocação em Hangares, manobras em solo e desempenho e compatibilidade com áreas de operação.`,
            app_advAverageFlightFuelRate: $localize`:@@app_advAverageFlightFuelRate:Consumo Médio de Combustível em Voo leva em consideração todas as etapas (decolagem, cruzeiro e pouso). Para o calculo detalhado, use o manual da aeronave.`,
            app_advBasicPerformance: $localize`:@@app_advBasicPerformance:Para o Calculo de Performance, consulte o Manual da aeronave. Os dados preenchidos são utilizados para gerar avisos e alertas de forma geral.`,            
            app_advCanCloseDialog: $localize`:@@app_advCanCloseDialog:Você pode fechar a janela de espera! O processo continua e não será cancelar.`,            
            app_advOpenUnitConverter: $localize`:@@app_advOpenUnitConverter:Para abrir Conversor de Unidades, clique sobre o simbolo da unidade do campo desejado para converter`,
            app_advPaxPatternBlankWeight: $localize`:@@app_advPaxPatternBlankWeight:Se o Peso for deixado em branco, será preenchido com valores padrões, com base no gênero e na idade.`,
            app_advSpecificationUse: $localize`:@@app_advSpecificationUse:Os dados fornecidos abaixo serão utilizados para criar alertas e avisos durante o planejamento do voo.`,
            app_advTakeCareUnits: $localize`:@@app_advTakeCareUnits:Atenção com as Unidades de Medidas, pois elas são fixas no formulário. \n Use o conversor de unidades: clique sobre a unidade disponível nos campos do formulário.`,
            app_advWeightBalanceDataFilledUnits: $localize`:@@app_advWeightBalanceDataFilledUnits:A unidades selecionadas são usadas para preencher os campos do formulário. A unidades dos gráficos são fixas`,


            app_advAircraftBr: $localize`:@@app_advAircraftBr:Aeronaves Registradas no Brasil são recuperadas automaticamente junto a ANAC.`,
            app_advDialogNotClose: $localize`:@@app_advDialogNotClose:A Janela não fecha automaticamente após a escolha, aperte o botão de SAIR.`,                        
            app_advUnitsReference: $localize`:@@app_advUnitsReference:As Unidades são usadas na criação do Gráfico e como base para o Preenchimento.`,
            app_advNoLateralArm: $localize`:@@app_advNoLateralArm:Quando não houver Peso/Balanceamento Lateral: preencha com 0 (zero).`,
            app_advCertifiedWeightsOrigin: $localize`:@@app_advCertifiedWeightsOrigin:O Pesos Limites são recuperados das Especificações da Aeronave.`,
            app_advCertifiedWeightsUnitsLb: $localize`:@@app_advCertifiedWeightsUnitsLb:ATENÇÃO: Os Pesos Limites estão em Libras (lb).`,
            app_advDatumLongitudinal: $localize`:@@app_advDatumLongitudinal:O Datum Longitudinal é a referência para o Cálculo do CG Longitudinal. Obtido a partir do Manual da Aeronave.`,
            app_advDatumLateral: $localize`:@@app_advDatumLateral:O Datum Lateral é a referência para o Cálculo do CG Lateral. Obtido a partir do Manual da Aeronave.`,
        },
        week: {
            app_wekMonday: $localize`:@@app_wekMonday:Segunda`,
            app_wekTuesday: $localize`:@@app_wekTuesday:Terça`,
            app_wekWednesday: $localize`:@@app_wekWednesday:Quarta`,
            app_wekThursday: $localize`:@@app_wekThursday:Quinta`,
            app_wekFriday: $localize`:@@app_wekFriday:Sexta`,
            app_wekSaturday: $localize`:@@app_wekSaturday:Sábado`,
            app_wekSunday: $localize`:@@app_wekSunday:Domingo`
        },
        month: {
            app_mthJanuary: $localize`:@@app_mthJanuary:Janeiro`,
            app_mthFebruary: $localize`:@@app_mthFebruary:Fevereiro`,
            app_mthMarch: $localize`:@@app_mthMarch:Março`,
            app_mthApril: $localize`:@@app_mthApril:Abril`,
            app_mthMay: $localize`:@@app_mthMay:Maio`,
            app_mthJune: $localize`:@@app_mthJune:Junho`,
            app_mthJuly: $localize`:@@app_mthJuly:Julho`,
            app_mthAugust: $localize`:@@app_mthAugust:Agosto`,
            app_mthSeptember: $localize`:@@app_mthSeptember:Setembro`,
            app_mthOctober: $localize`:@@app_mthOctober:Outubro`,
            app_mthNovember: $localize`:@@app_mthNovember:Novembro`,
            app_mthDecember: $localize`:@@app_mthDecember:Dezembro`
        },        
        country: {
            app_cntAr: $localize`:@@app_cntAr:Argentina`,
            app_cntAu: $localize`:@@app_cntAu:Austrália`,
            app_cntBr: $localize`:@@app_cntBr:Brasil`,   
            app_cntBo: $localize`:@@app_cntBo:Bolívia`,    
            app_cntCa: $localize`:@@app_cntCa:Canadá`,
            app_cntCl: $localize`:@@app_cntCl:Chile`,            
            app_cntCo: $localize`:@@app_cntCo:Colômbia`,
            app_cntEc: $localize`:@@app_cntEc:Equador`,
            app_cntGf: $localize`:@@app_cntGf:Guiana Francesa`,
            app_cntGy: $localize`:@@app_cntGy:Guiana`,
            app_cntMx: $localize`:@@app_cntMx:México`,
            app_cntPy: $localize`:@@app_cntPy:Paraguai`,
            app_cntPe: $localize`:@@app_cntPe:Peru`,
            app_cntGe: $localize`:@@app_cntGe:Georgia do Sul`,
            app_cntSr: $localize`:@@app_cntSr:Suriname`,        
            app_cntUs: $localize`:@@app_cntUs:Estados Unidos`,
            app_cntUy: $localize`:@@app_cntUy:Uruguai`,
            app_cntVe: $localize`:@@app_cntVe:Venezuela`,
            app_cntNz: $localize`:@@app_cntNz:Nova Zelândia`
        }
    }

    return objTranslateContent[objTye[tempType]]?.[tempContent] || '';
}

