<header class="cls-hp1-frame-header">
    <div class="cls-hp1-frame-header-logo-box">
        <img src="natours_project/imgs/logo-white.png" alt="Logo" class="cls-hp1-frame-header-logo-box-image">
    </div>
    <div class="cls-hp1-frame-header-text-box">
        <h1 class="cls-hp1-frame-title-primary">
            <span class="cls-hp1-frame-title-primary-main">Outdoors</span>
            <span class="cls-hp1-frame-title-primary-sub">is where life happens</span>
        </h1>
        <a href="#" class="cls-hp1-btn cls-hp1-btn-white cls-hp1-btn-animated">Discover our tours</a>
    </div>
</header>
<main>
    <section class="cls-hp1-frame-section-about">
        <div class="class-center-text class-margin-bottom-big">
            <h2 class="cls-hp1-frame-title-secondary">
                Exciting tours for adventures people
            </h2>
        </div>
        <div class="row">
            <div class="col-1-of-2">
                <h3 class="cls-hp1-frame-title-tertiary class-margin-bottom-small">
                    Your're going to fall in love with nature
                </h3>
                <p class="cls-hp1-frame-paragraph">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum alias explicabo laborum sequi. Vel
                    sed voluptates modi doloremque possimus enim itaque, minima earum tempore iste harum assumenda quia
                    nostrum voluptate!
                </p>

                <h3 class="cls-hp1-frame-title-tertiary class-margin-bottom-small">
                    Live adventures like you never have before
                </h3>
                <p class="cls-hp1-frame-paragraph">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum alias explicabo laborum sequi. Vel
                    sed voluptates modi doloremque possimus enim itaque, minima earum tempore iste harum assumenda quia
                    nostrum voluptate!
                </p>

                <a href="#" class="cls-hp1-btn-text">Learn more &rarr;</a>
            </div>
            <div class="col-1-of-2">
                <div class="cls-hp1-composition">
                    <img src="natours_project/imgs/nat-1-large.jpg" alt="Photo 1"
                        class="cls-hp1-composition-photo cls-hp1-composition-photo-p1">
                    <img src="natours_project/imgs/nat-2-large.jpg" alt="Photo 2"
                        class="cls-hp1-composition-photo cls-hp1-composition-photo-p2">
                    <img src="natours_project/imgs/nat-3-large.jpg" alt="Photo 3"
                        class="cls-hp1-composition-photo cls-hp1-composition-photo-p3">
                </div>
            </div>
        </div>
    </section>
    <section class="cls-hp1-frame-section-features">
        <div class="row">
            <div class="col-1-of-4">
                <div class="cls-hp1-features-box">
                    <i class="cls-hp1-features-box-icon icon-basic-world"></i>
                    <h3 class="cls-hp1-frame-title-tertiary class-margin-bottom-small">Explore the World</h3>
                    <p class="cls-hp1-features-box-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel illo corporis rem non quis
                        consequuntur labore molestias.
                    </p>
                </div>
            </div>
            <div class="col-1-of-4">
                <div class="cls-hp1-features-box">
                    <i class="cls-hp1-features-box-icon icon-basic-compass"></i>
                    <h3 class="cls-hp1-frame-title-tertiary class-margin-bottom-small">Meet Nature</h3>
                    <p class="cls-hp1-features-box-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel illo corporis rem non quis
                        consequuntur labore molestias.
                    </p>
                </div>
            </div>
            <div class="col-1-of-4">
                <div class="cls-hp1-features-box">
                    <i class="cls-hp1-features-box-icon icon-basic-map"></i>
                    <h3 class="cls-hp1-frame-title-tertiary class-margin-bottom-small">Find your Way</h3>
                    <p class="cls-hp1-features-box-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel illo corporis rem non quis
                        consequuntur labore molestias.
                    </p>
                </div>
            </div>
            <div class="col-1-of-4">
                <div class="cls-hp1-features-box">
                    <i class="cls-hp1-features-box-icon icon-basic-heart"></i>
                    <h3 class="cls-hp1-frame-title-tertiary class-margin-bottom-small">Healthier life</h3>
                    <p class="cls-hp1-features-box-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel illo corporis rem non quis
                        consequuntur labore molestias.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="cls-hp1-frame-section-tours">
        <div class="class-center-text class-margin-bottom-big">
            <h2 class="cls-hp1-frame-title-secondary">
                Most popular tours
            </h2>
        </div>
        <div class="row">
            <div class="col-1-of-3">
                <div class="cls-hp1-card">
                    <div class="cls-hp1-card-side cls-hp1-card-side-front">
                        <div class="cls-hp1-card-picture cls-hp1-card-picture-1">
                            &nbsp;
                        </div>
                        <h4 class="cls-hp1-card-heading">
                            <span class="cls-hp1-card-heading-span cls-hp1-card-heading-span-1">The Sea Explorer</span>                            
                        </h4>
                        <div class="cls-hp1-card-details">
                            <ul>
                                <li>3 day tours</li>
                                <li>Up to 30 people</li>
                                <li>2 tour guides</li>
                                <li>Sleep in cozy hotels</li>
                                <li>Difficulty: easy</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cls-hp1-card-side cls-hp1-card-side-back cls-hp1-card-side-back-1">
                        <div class="cls-hp1-card-cta">
                            <div class="cls-hp1-card-price-box">
                                <p class="cls-hp1-card-price-only">
                                    Only
                                </p>
                                <p class="cls-hp1-card-price-value">
                                    $297
                                </p>
                            </div>
                            <a href="#popup" class="cls-hp1-btn cls-hp1-btn-white">
                                Book now!
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1-of-3">
                <div class="cls-hp1-card">
                    <div class="cls-hp1-card-side cls-hp1-card-side-front">
                        <div class="cls-hp1-card-picture cls-hp1-card-picture-2">
                            &nbsp;
                        </div>
                        <h4 class="cls-hp1-card-heading">
                            <span class="cls-hp1-card-heading-span cls-hp1-card-heading-span-2">The Forest Hiker</span>                            
                        </h4>
                        <div class="cls-hp1-card-details">
                            <ul>
                                <li>7 day tours</li>
                                <li>Up to 40 people</li>
                                <li>6 tour guides</li>
                                <li>Sleep in provided tents</li>
                                <li>Difficulty: medium</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cls-hp1-card-side cls-hp1-card-side-back cls-hp1-card-side-back-2">
                        <div class="cls-hp1-card-cta">
                            <div class="cls-hp1-card-price-box">
                                <p class="cls-hp1-card-price-only">
                                    Only
                                </p>
                                <p class="cls-hp1-card-price-value">
                                    $497
                                </p>
                            </div>
                            <a href="#popup" class="cls-hp1-btn cls-hp1-btn-white">
                                Book now!
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1-of-3">
                <div class="cls-hp1-card">
                    <div class="cls-hp1-card-side cls-hp1-card-side-front">
                        <div class="cls-hp1-card-picture cls-hp1-card-picture-3">
                            &nbsp;
                        </div>
                        <h4 class="cls-hp1-card-heading">
                            <span class="cls-hp1-card-heading-span cls-hp1-card-heading-span-3">The Snow Adventurer</span>                            
                        </h4>
                        <div class="cls-hp1-card-details">
                            <ul>
                                <li>5 day tours</li>
                                <li>Up to 15 people</li>
                                <li>3 tour guides</li>
                                <li>Sleep in provided tents</li>
                                <li>Difficulty: hard</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cls-hp1-card-side cls-hp1-card-side-back cls-hp1-card-side-back-3">
                        <div class="cls-hp1-card-cta">
                            <div class="cls-hp1-card-price-box">
                                <p class="cls-hp1-card-price-only">
                                    Only
                                </p>
                                <p class="cls-hp1-card-price-value">
                                    $897
                                </p>
                            </div>
                            <a href="#popup" class="cls-hp1-btn cls-hp1-btn-white">
                                Book now!
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="class-center-text class-margin-top-huge">
            <a href="#" class="cls-hp1-btn cls-hp1-btn-green">Discover all tours</a>
        </div>
    </section>
</main>
<router-outlet></router-outlet>