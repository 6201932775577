//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initAircraftOwnersOperatorsForm } from '../../../../../form-init/owner-operator-form';

// ***** MOCK ***** //

// ***** CLASS ***** //
import { ModuleMethodsHandlerClass } from '../../../../../../../_classes/ModuleMethodsHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../_library/classes/LocalMethodsHandlerClass';

// ***** COMPONENT ***** //
import { TitleFormTopicComponent } from '../../../../../../../_components/layout/title-form-topic/title-form-topic.component';
import { DialogFormOwnerOperatorComponent } from '../../../../_components/dialog-form-owner-operator/dialog-form-owner-operator.component';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../../../../_services/module-aircraft/module-aircraft.service';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownOptionsCountry } from '../../../../../../../../_library/interfaces/IDropDownOptionsCountry';
import { IDropDownOptionsIcon } from '../../../../../../../../_library/interfaces/IDropDownOptionsIcon';
import { IWaitingDialog } from '../../../../../../../../_library/interfaces/IWaitingDialog';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from '../../../../../../../../_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsAircraftCountries } from '../../../../../../../../_library/static_options/staticOptionsAircraftCountries';
import { staticOptionsAircraftTypes } from '../../../../../../../../_library/static_options/staticOptionsAircraftTypes';
import { staticOptionsAircraftRules } from '../../../../../../../../_library/static_options/staticOptionsAircraftRules';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-tab-registration-data',
  standalone: true,
  imports: [
    CommonModule,
    DialogFormOwnerOperatorComponent,
    TitleFormTopicComponent,
    PrimengComponentsModule
  ],
  templateUrl: './tab-registration-data.component.html'
})
export class TabRegistrationDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() actionTabRegistrationData = new EventEmitter<string>();
  @Output() actionShowWaitingDialog = new EventEmitter<IWaitingDialog>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public buttonActions!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** FORM ***** //
  public formOwnerOperator!: any;

  // ***** DIALOG ***** //
  public setupShowWaitingDialog!: IWaitingDialog;
  public setupHiddenWaitingDialog!: IWaitingDialog;
  public showDialogOwnerOperator!: any;
  public typeDialogOwnerOperator!: any;

  // ***** OPTIONS ***** //
  public optionsAircraftCountries!: IDropDownOptionsCountry[];
  public optionsAircraftTypes!: IDropDownOptionsIcon[];
  public optionsAircraftRules!: IDropDownOptionsIcon[];

  // ***** OTHERS ***** //
  
  //#endregion

  constructor(
    private _messageService: MessageService,
    private _moduleAircraftApisService: ModuleAircraftService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- HANDLE FORM EVENTS ---|"  
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.buttonActions = BUTTON_ACTION;
    
    this.setupShowWaitingDialog = {
      show_dialog: true,
      type_waiting: DIALOG_WAITING_TYPE.ANAC_RAB
    };
    
    this.setupHiddenWaitingDialog = {
      show_dialog: false,
      type_waiting: DIALOG_WAITING_TYPE.ANAC_RAB
    };
      
    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
    
    this.optionsAircraftTypes = staticOptionsAircraftTypes;
    this.optionsAircraftCountries = staticOptionsAircraftCountries;
    this.optionsAircraftRules = staticOptionsAircraftRules;
  }

  /**
   * @author GASPAR
   * @date 2024-09-15
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de Proprietários e Operadores.
   */
  private _initOwnerOperatorForm(): void {
    this.formOwnerOperator = initAircraftOwnersOperatorsForm();
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @author GASPAR
  * @date 2024-09-15
  * @version 1.0.0
  * 
  * @description CALLBACK das ação do botões que "Salva  Novo", "Salvar Modificações", Novo e Sai do REGISTRO AERONÁUTICO
  * 
  * @abstract Os botões estão no Toolbar do formulário do REGISTRO AERONÁUTICO.
  */
  callbackClickButton(xButtonAction: string): void {
    this.actionTabRegistrationData.emit(xButtonAction);
  }

  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Callback da ações dos "Novo Proprietário", "Editar", "Apagar"  dados do Proprietário. 
   * 
   * @abstract Os botões estão na TABELA de Proprietário.
   */
  callbackTableOwnerClickButton(xButtonAction: string): void {
    if (xButtonAction == BUTTON_ACTION.NEW) {
      this.typeDialogOwnerOperator = 'owner';
      this._initOwnerOperatorForm();

      this.showDialogOwnerOperator = true;
    } else if (xButtonAction == BUTTON_ACTION.EDIT) {
      this.typeDialogOwnerOperator = 'owner';
      this._initOwnerOperatorForm();

      this.showDialogOwnerOperator = true;
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-23
   * @version 1.0.0
   * 
   * @description Callback da ações dos "Salvar Novo", "Salvar Alterações", "Sair" dados do Proprietário ou Operador.
   * 
   * @abstract Os botões estão no DIALOGO do formulário de Proprietário ou Operador.
   * 
   * @param xEvent 
   */
  callbackActionDialogOwnerOperatorForm(xEvent: string): void {
    this.showDialogOwnerOperator = false;

    if (xEvent == BUTTON_ACTION.SAVE) {
      this.formComponent.controls.owner_operators.push(this.formOwnerOperator.getRawValue());


    } else if (xEvent == BUTTON_ACTION.EDIT) {
      this.formComponent.controls.owner_operators = this.formComponent.controls.owner_operators.map((owner_operator: any) => {
        if (owner_operator.owner_operator_id == 1 && (owner_operator.type_owner_operator == 'owner' || owner_operator.type_owner_operator == 'both')) {
          return this.formOwnerOperator.getRawValue();
        } else {
          return owner_operator;
        }
      });

    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Retorna a quantidade de Proprietários ou Operadores.
   * 
   * @param xArray 
   * @returns 
   */
  getLengthOwnerOrBoth(xArray: any): number {
    return xArray.filter((xElement: any) => xElement.type_owner_operator == 'owner' || xElement.type_owner_operator == 'both').length;
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Retorna a quantidade de Operadores ou Ambos.
   * 
   * @param xArray 
   * @returns 
   */
  getLengthOperatorOrBoth(xArray: any): number {
    return xArray.filter((xElement: any) => xElement.type_owner_operator == 'operator' || xElement.type_owner_operator == 'both').length;
  }
}