<section class="cls-pge-app-form">
    <sunrise-toast-message></sunrise-toast-message>
    <!-- <p-confirmDialog class="cls-component-confirm-dialog-default" /> -->
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        [extraData]="this.extraDataDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
    <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
    <div class="cls-frame-dashboard-horizontal-main-form-grid-1-col">
        <section class="cls-frame-dashboard-horizontal-main-content">            
            <p-tabView 
                [scrollable]="true" 
                [activeIndex]="0"
                (onChange) = "this.onChangeTabView($event)"
                class="cls-cmp-ptv-tabview-page">      
                <!-- REQUISIÇÃO VOO -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-pen-to-square"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_FlightRequest')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <sunrise-tab-flight-request 
                            [formComponent]="this.formFlightData"
                            [arrayAllAircraft]="this.arrAircraftData"
                            [arrayOptionsAircraft]="this.optAircraftData"
                            [arrayAllOperatorByAircraft]="this.arrOwnerOperatorData"
                            [arrayOptionsOperator]="this.optOperatorsData"
                            [arrayOptionsCrew]="this.optCrewData"
                            [utcDateTime]="this.currentUtcDateTime"
                            (actionTabFlightRequest)="this.handlerActionTabFlightRequest($event)">
                        </sunrise-tab-flight-request>
                    </ng-template>                    
                </p-tabPanel>  
                <!-- ATENDIMENTO -->   
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-route"></i>
                            <span>ATENDIMENTO</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        
                    </ng-template>
                </p-tabPanel>
                <!-- PLANO DE VOO -->   
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-route"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_FlightPlanning')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        
                    </ng-template>
                </p-tabPanel>       
                <!-- PESO E BALANCEAMENTO -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formFlightData.get('flt_data_id').value !== null">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-scale-balanced"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_WeightBalance')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <sunrise-tab-weight-balance-filled 
                            [formComponent]="this.formFlightData"
                            [objAircraftData]="this.objCurrentAircraft"
                            [arrWeightBalanceDataSheet]="this.arrWeightBalanceDataSheet"
                            (actionShowWaitingDialog)="handleShowDialogWaiting($event)">
                        </sunrise-tab-weight-balance-filled>
                    </ng-template>
                </p-tabPanel>    
                <!-- DOCUMENTAÇÃO -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-file-alt"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_Documentation')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        
                    </ng-template>
                </p-tabPanel>                
            </p-tabView>
        </section>
        <aside class="cls-frame-dashboard-horizontal-main-right"></aside>
    </div>













    <!--<sunrise-app-platform--toast-message></sunrise-app-platform--toast-message>
    <sunrise-app-platform-waiting-dialog [showWaitingDialog]="this.bolShowDialogWaiting"
        [typeWaitingDialog]="this.typeDialogWaiting">
    </sunrise-app-platform-waiting-dialog>
    <sunrise-app-platform--title-page [titlePage]="this.objPageTitle"></sunrise-app-platform--title-page>
    <div class="cls-page-form">
        <div class="cls-page-form-grid-3-columns">
            <aside class="cls-page-form-sidebar-left">
                <p-panel class="cls-component-panel-aside cls-component-panel-aside-flight-requisition">
                    <ng-template pTemplate="header">
                        <h3>
                            <span><i class="fa-solid fa-paper-plane"></i> Requisição de Voo </span>
                        </h3>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-component-panel-aside-flight-requisition-code">
                            <div class="cls-component-panel-aside-flight-requisition-code-label">
                                PRKEB-20241002-001
                            </div>
                            <div class="cls-component-panel-aside-flight-requisition-code-buttons">
                                <p-button icon="fa-solid fa-copy" severity="secondary" />
                                <p-button icon="fa-solid fa-link" severity="secondary" />
                            </div>
                        </div>
                        <div>
                            <div>DAAADOOSS AERONAVE</div>
                            <div class="cls-component-from-to-breadcrumb">
                                <ul>
                                    <li class="cls-component-from-to-breadcrumb-item">
                                        <div>
                                            SBMG
                                        </div>
                                    </li>
                                    <li class="cls-component-from-to-breadcrumb-item">
                                        <div>
                                            SBBI
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div>
                                <sunrise-countdown-utc></sunrise-countdown-utc>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>-->

                <!--<p-panel class="cls-component-panel-aside">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-aside-header">
                            <div class="cls-component-panel-aside-header-title">
                                <h3>
                                    <span><i class="fa-solid fa-bolt"></i>Requisição XXXXXXXXX </span>
                                </h3>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>-->
            <!--</aside>
            <div class="cls-page-form-content cls-util-no-padding">
                <p-tabView [scrollable]="true" class="cls-component-tabview-page" [activeIndex]=0>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-file-alt"></i>
                                <div>Requisição</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-tabview-page-tabpanel-content">
                                <sunrise-tab-flight-request [formComponent]="this.formFlightData"
                                    [arrayAllAircraft]="this.optionsAllAircraft"
                                    [arrayAllOperatorByAircraft]="this.optionsOperatorsByAircraft"
                                    [utcDateTime]="this.currentUtcDateTime"
                                    (actionTabFlightRequest)="this.handlerActionTabFlightRequest($event)">
                                </sunrise-tab-flight-request>
                            </div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-compass-drafting"></i>
                                <div>Planejamento</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-cloud-sun-rain"></i>
                                <div>Meteorologia</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-tabview-page-tabpanel-content">

                            </div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel">
                        <ng-template pTemplate="header">
                            <div
                                class="cls-component-tabview-page-tabpanel-header cls-component-tabview-page-tabpanel-header-big">
                                <i class="fa-solid fa-balance-scale"></i>
                                <div>Peso & Bal.</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-tabview-page-tabpanel-content">
                                <sunrise-weight-balance-filled 
                                    [formComponent]="this.formFlightData"
                                    [objAircraft]="this.objCurrentAircraft"
                                    [arrWeightBalanceSheet]="this.allWeightBalanceSheetListDb"
                                    (actionShowWaitingDialog)="handleShowDialogWaiting($event)">
                                </sunrise-weight-balance-filled>
                            </div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-book"></i>
                                <div>Documentação</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-tabview-page-tabpanel-content">
                                
                            </div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel class="cls-component-tabview-page-tabpanel" *ngIf="false">
                        <ng-template pTemplate="header">
                            <div class="cls-component-tabview-page-tabpanel-header">
                                <i class="fa-solid fa-plane-arrival"></i>
                                <div>Fechamento</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <aside class="cls-page-form-sidebar-right">-->
                <!--<p-panel class="cls-component-panel-widget">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-widget-header">
                            <div class="cls-component-panel-widget-header-title">
                                <h3>
                                    <span><i class="fa-solid fa-bolt"></i>Fusos </span>
                                </h3>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>

                <p-panel class="cls-component-panel-widget">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-widget-header">
                            <div class="cls-component-panel-widget-header-title">
                                <h3>
                                    <span><i class="fa-solid fa-bolt"></i>Agenda </span>
                                </h3>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>

                <p-panel class="cls-component-panel-widget">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-widget-header">
                            <div class="cls-component-panel-widget-header-title">
                                <h3>
                                    <span><i class="fa-solid fa-bolt"></i>Meteorologia </span>
                                </h3>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
                <p-panel class="cls-component-panel-widget">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-widget-header">
                            <div class="cls-component-panel-widget-header-title">
                                <h3>
                                    <span><i class="fa-solid fa-bolt"></i>Contatos </span>
                                </h3>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
                <p-panel class="cls-component-panel-widget">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-widget-header">
                            <div class="cls-component-panel-widget-header-title">
                                <h3>
                                    <span><i class="fa-solid fa-bolt"></i>Conversão </span>
                                </h3>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>-->
                <!--<p-panel class="cls-component-panel-widget">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-widget-header">
                            <div class="cls-component-panel-widget-header-title">
                                <h3>
                                    <span><i class="fa-solid fa-bolt"></i>Sites Apoio </span>
                                </h3>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>-->
            <!--</aside>
        </div>
    </div>-->
</section>