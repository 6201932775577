<section class="cls-cmp-ptv-tabview-page-tabpanel-data">
    <div class="grid">
        <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
            <p-panel class="cls-cmp-ptv-panel-default" [toggleable]="true" [collapseIcon]="'fa-solid fa-chevron-down'"
                [expandIcon]="'fa-solid fa-chevron-up'">
                <ng-template pTemplate="header">
                    <div class="cls-cmp-ptv-panel-default-header">
                        <div class="cls-cmp-ptv-panel-default-header-title">
                            <h2>
                                <i class="fa-regular fa-rectangle-list"></i>
                                <span>Tripulante das Aeronaves</span>
                            </h2>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                <p-table 
                                    [value]="this.formComponent.controls.fk_aircraft_list.controls"
                                    [tableStyle]="{ 'min-width': '50rem' }" 
                                    [paginator]="true" 
                                    [rows]="5"
                                    [rowsPerPageOptions]="[5, 10, 20]" 
                                    [showCurrentPageReport]="true"
                                    styleClass="p-datatable-gridlines"
                                    currentPageReportTemplate="Página {first} de {last}."
                                    class="cls-cmp-ptv-table-form-list">
                                    <ng-template pTemplate="caption">
                                        <div class="cls-cmp-ptv-table-form-list-caption">
                                            <div class="cls-cmp-ptv-table-form-list-caption-left">
                                                Total:
                                                {{this.formComponent.controls.fk_aircraft_list.controls ? this.formComponent.controls.fk_aircraft_list.controls.length : 0 }}
                                            </div>
                                            <div class="cls-cmp-ptv-table-form-list-caption-right">
                                                <div>
                                                    <p-button 
                                                        label="NOVO" 
                                                        styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"                                                        
                                                        icon="fa-solid fa-plus" 
                                                        severity="success"
                                                        (click)="this.onClickTableCrewAircraftData(this.actionType.ADD_ITEM)" />
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width:10%; text-align: center">AERONAVE</th>
                                            <th style="width:25%; text-align: left">OPERADOR</th>
                                            <th style="width:10%; text-align: center">FUNÇÃO</th>
                                            <th style="width:55%"></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-itemArray let-rowIndex="rowIndex">
                                        <tr>
                                            <td style="text-align: center">
                                                <div
                                                    [innerHTML]="this.classAviationTools.setAircraftMarkWithIconType(itemArray.controls.fk_aircraft_data.controls.aircraft_mark.value, itemArray.controls.fk_aircraft_data.controls.ntbs_aircraft_type.value)">
                                                </div>
                                            </td>
                                            <td style="text-align: left">
                                                {{ itemArray.controls.fk_owner_operator_data.controls.ntbs_operator_full_name.value }}
                                            </td>
                                            <td style="text-align: center">
                                                {{ this.classModuleMethods.getCrewRoleLabel(itemArray.controls.crew_role.value) }}
                                            </td>
                                            <td class="cls-cmp-ptv-table-form-list-td-button">
                                                <p-button 
                                                    icon="fa-solid fa-pen" 
                                                    [rounded]="true" 
                                                    [outlined]="true"
                                                    severity="info"
                                                    (click)="this.onClickItemTableAircraft(this.actionType.EDIT, rowIndex)">
                                                </p-button>
                                                <p-button 
                                                    icon="fa-solid fa-trash-can" 
                                                    [rounded]="true"
                                                    [outlined]="true" 
                                                    severity="danger"
                                                    (click)="this.onClickItemTableAircraft(this.actionType.DELETE, rowIndex)">
                                                </p-button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>
                    <div class="cls-cmp-ptv-panel-bottom"></div>
                </ng-template>
            </p-panel>
        </div>
    </div>
    <p-dialog [modal]="true" [(visible)]="this.showDialogCrewAircraft" [style]="{width: '40vw'}">
        <ng-template pTemplate="headless">
            <div class="cls-cmp-ptv-dialog-form-box">
                <p-panel class="cls-cmp-ptv-panel-default cls-cmp-ptv-dialog-form-last-panel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-panel-default-header">
                            <div class="cls-cmp-ptv-panel-default-header-title">
                                <h2>
                                    <i class="fa-regular fa-rectangle-list"></i>
                                    <span>Tripulante e Aeronave</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-cmp-ptv-form cls-cmp-ptv-form-frame">
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                    <lib-ui-primeng-drop-down 
                                        label="Função" 
                                        idField="fid-crew-role"
                                        [control]="this.formCrewAircraft.controls.crew_role"
                                        [arrayOptions]="this.optionsCrewRole">
                                    </lib-ui-primeng-drop-down>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                    <lib-ui-primeng-drop-down-icon 
                                        label="Aeronave" 
                                        idField="fid-aircraft-data-id"
                                        [control]="this.formCrewAircraft.controls.fk_aircraft_data.controls.fk_aircraft_data_id"
                                        [arrayOptions]="this.optionsAircraftData">
                                    </lib-ui-primeng-drop-down-icon>
                                </div>
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
                                    <lib-ui-primeng-drop-down 
                                        label="Operador"
                                        idField="fid-operator-full-owner-operator-data-id"
                                        [control]="this.formCrewAircraft.controls.fk_owner_operator_data.controls.fk_owner_operator_data_id"
                                        [arrayOptions]="this.optionsOperatorData">
                                    </lib-ui-primeng-drop-down>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6 xl:col-12">
                                    <ui-primeng-input-text-area 
                                        label="Observação" 
                                        idField="fid-nationality"
                                        [maxLength]=255 
                                        [control]="this.formCrewAircraft.controls.crew_aircraft_remark">
                                    </ui-primeng-input-text-area>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col-12 md:col-12 lg:col-12 xl:col-12 cls-util-no-padding-bottom">
                                <p-toolbar class="cls-cmp-ptv-toolbar-dialog">
                                    <div class="p-toolbar-group-start"></div>
                                    <div class="p-toolbar-group-center"></div>
                                    <div class="p-toolbar-group-end">
                                        <p-button 
                                            label="Sair"
                                            styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                            icon="fa-solid fa-right-from-bracket" 
                                            severity="danger"
                                            (click)="this.onClickToolbarDialogCrewAircraft(this.actionType.EXIT)" />
                                        <ng-container *ngIf="this.formCrewAircraft.controls.crew_aircraft_id.value === null">
                                            <p-button 
                                                label="Salvar"
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" 
                                                severity="success"
                                                (click)="this.onClickToolbarDialogCrewAircraft(this.actionType.SAVE)" />
                                        </ng-container>
                                        <ng-container *ngIf="this.formCrewAircraft.controls.crew_aircraft_id.value !== null">
                                            <p-button 
                                                label="Salvar Modificações"
                                                styleClass="cls-cmp-ptv-button cls-cmp-ptv-button-toolbar"
                                                icon="fa-solid fa-save" 
                                                severity="success"
                                                (click)="this.onClickToolbarDialogCrewAircraft(this.actionType.UPDATE)" />
                                        </ng-container>
                                    </div>
                                </p-toolbar>
                            </div>
                        </div>
                        <div class="cls-cmp-ptv-panel-bottom"></div>
                    </ng-template>
                </p-panel>
            </div>
        </ng-template>
    </p-dialog>
</section>