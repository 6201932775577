//#region "|--- IMPORT ENUM ---|"
import { REGEX_EXPRESSIONS } from '../../_library/definitions/RegexExpressions';
//#endregion

//#region "|--- CONSTANTS ---|"
const enumRegexExpressions = REGEX_EXPRESSIONS;
//#endregion

export class CoordinatesHandlerClass {
    /**
     * @status: OK
     * @author GASPAR
     * @date 2024-09-29
     * @version 1.0.0
     * 
     * @description 
     *   - Verifica se o ID passado é um um TIPO coordenada.
     * 
     * @param xCoordinate 
     * @returns 
     */
    static coordinateType(xCoordinate: string): string | null {
        // 1) VERIFICA SE O ID PASSADO É UMA: COORDENADA DECIMAL.
        // Formato esperado: "-99.999999, -99.999999"
        if (_isCoordinateDecimal(xCoordinate) === "decimal") {
            return "decimal";
        } else if (_isCoordinateLabel(xCoordinate) === "label") { // 2) VERIFICA SE O ID PASSADO É UMA: COORDENADA LABEL.
            return "label";
        } else if (_isCoordinateGMS(xCoordinate) === "gms") { // 3) VERIFICA SE O ID PASSADO É UMA: COORDENADA GMS.
            return "gms";
        } else if (_isCoordinatePlan(xCoordinate) === "plan") { // 4) VERIFICA SE O ID PASSADO É UMA: COORDENADA PLAN.
            return "plan";
        } else {
            return null;
        }
    }

    /**
     * @author GASPAR
     * @date 2024-09-29
     * @version 1.0.0
     * 
     * @description Converte uma coordenada para DECIMAL.
     * 
     * @param xCoordinate 
     * @returns 
     */
    static automaticConvertToDecimal(xCoordinate: string, xDecimalPlaces = 5): any {
        // Remove parênteses, colchetes e espaços em branco antes e depois da vírgula ou barra
        const formattedCoordinate = xCoordinate.replace(/[()\[\]]/g, '').replace(/\s*([,\/])\s*/g, '$1');
                
        if (REGEX_EXPRESSIONS.COORDINATE_DECIMAL.test(formattedCoordinate)) {
            const tempCoordinate = formattedCoordinate.split(/[,\/]/);
            
            return [parseFloat(tempCoordinate[0]), parseFloat(tempCoordinate[1])];
        } else if (REGEX_EXPRESSIONS.COORDINATE_LABEL.test(formattedCoordinate)) {
            const tempCoordinate = formattedCoordinate.split(/[,\/]/);
            
            return this.convertLabelCoordinateToDecimal(tempCoordinate[0], tempCoordinate[1], xDecimalPlaces);
        } else if (REGEX_EXPRESSIONS.COORDINATE_GMS.test(formattedCoordinate)) {
            const tempCoordinate = formattedCoordinate.split(/[,\/]/);
            
            return this.convertGmsCoordinateToDecimal(tempCoordinate[0], tempCoordinate[1], xDecimalPlaces);
        } else if (REGEX_EXPRESSIONS.COORDINATE_PLAN.test(formattedCoordinate)) {
            return this.convertPlanCoordinateToDecimal(formattedCoordinate, xDecimalPlaces);
        }

        return [];
    }

    /**
     * @author GASPAR
     * @date 2024-09-29
     * @version 1.0.0
     * 
     * @description Converte uma coordenada LABEL para DECIMAL.
     * 
     * @param latitude 
     * @param longitude 
     * @returns 
     */
    static convertLabelCoordinateToDecimal(xLatitude: string, xLongitude: string, xDecimalPlaces: number): [number, number] {
        const latDegrees = parseInt(xLatitude.slice(0, 2));
        const latMinutes = parseFloat(xLatitude.slice(2, 4));
        const latSeconds = parseInt(xLatitude.slice(5, 7)); // Pegar somente os 2 primeiros caracteres. Caso contrário ele dá distorção de mais de 1 grau.
        const latDirection = xLatitude.slice(9).trim();

        const lonDegrees = parseInt(xLongitude.slice(0, 3));
        const lonMinutes = parseFloat(xLongitude.slice(3, 5));
        const lonSeconds = parseInt(xLongitude.slice(6, 8))
        const lonDirection = xLongitude.slice(8).trim();

        let latDecimal = latDegrees + (latMinutes / 60) + (latSeconds / 3600);
        let lonDecimal = lonDegrees + (lonMinutes / 60) + (lonSeconds / 3600);

        if (latDirection === 'S') {
            latDecimal = -latDecimal;
        }
        if (lonDirection === 'W') {
            lonDecimal = -lonDecimal;
        }

        latDecimal = parseFloat(latDecimal.toFixed(xDecimalPlaces));
        lonDecimal = parseFloat(lonDecimal.toFixed(xDecimalPlaces));

        return [latDecimal, lonDecimal];
    }

    /**
     * @author GASPAR
     * @date 2024-09-29
     * @version 1.0.0
     * 
     * @description Converte uma coordenada GMS para DECIMAL.
     * 
     * @param xLatitude
     * @param xLongitude
     * @param xDecimalPlaces
     */
    static convertGmsCoordinateToDecimal(xLatitude: string, xLongitude: string, xDecimalPlaces: number): [number, number] {
        const latDegrees = parseInt(xLatitude.slice(0, 2));
        const latMinutes = parseInt(xLatitude.slice(3, 5));
        const latSeconds = parseInt(xLatitude.slice(6, 8));
        const latDirection = xLatitude.slice(8).trim();

        const lonDegrees = parseInt(xLongitude.slice(0, 3));
        const lonMinutes = parseInt(xLongitude.slice(4, 6));
        const lonSeconds = parseInt(xLongitude.slice(7, 9));
        const lonDirection = xLongitude.slice(9).trim();

        let latDecimal = latDegrees + (latMinutes / 60) + (latSeconds / 3600);
        let lonDecimal = lonDegrees + (lonMinutes / 60) + (lonSeconds / 3600);

        if (latDirection === 'S') {
            latDecimal = -latDecimal;
        }
        if (lonDirection === 'W') {
            lonDecimal = -lonDecimal;
        }

        latDecimal = parseFloat(latDecimal.toFixed(xDecimalPlaces));
        lonDecimal = parseFloat(lonDecimal.toFixed(xDecimalPlaces));

        return [latDecimal, lonDecimal];
    }

    /**
     * @author GASPAR
     * @date 2024-09-29
     * @version 1.0.0
     * 
     * @description Converte uma coordenada PLAN para DECIMAL.
     * 
     * @param coord 
     * @param decimalPlaces 
     * @returns 
     */
    static convertPlanCoordinateToDecimal(xCoord: string, xDecimalPlaces: number): [number, number] {
        const latDegrees = parseInt(xCoord.slice(0, 2));
        const latMinutes = parseInt(xCoord.slice(2, 4));
        const latDirection = xCoord.slice(4, 5);

        const lonDegrees = parseInt(xCoord.slice(5, 8));
        const lonMinutes = parseInt(xCoord.slice(8, 10));
        const lonDirection = xCoord.slice(10, 11);

        let latDecimal = latDegrees + (latMinutes / 60);
        let lonDecimal = lonDegrees + (lonMinutes / 60);

        if (latDirection === 'S') {
            latDecimal = -latDecimal;
        }
        if (lonDirection === 'W') {
            lonDecimal = -lonDecimal;
        }

        latDecimal = parseFloat(latDecimal.toFixed(xDecimalPlaces));
        lonDecimal = parseFloat(lonDecimal.toFixed(xDecimalPlaces));

        return [latDecimal, lonDecimal];
    }
}

//#region "|--- PRIVATE METHODS ---|"
/**
 * @status: OK
 * @author GASPAR
 * @date 2024-09-29
 * @version 1.0.0
 * 
 * @description 
 *   - Verifica se é um tipo de coordenada DECIMAL
 *     - DECIMAL (Graus Decimais): 12.1234,123.1234
 * 
 * @param coordinate 
 * @returns 
 */
function _isCoordinateDecimal(coordinate: string): string {
    // Remove parênteses e espaços em branco antes e depois da vírgula
    const formattedCoordinate = coordinate.replace(/[()]/g, '').replace(/\s*,\s*/g, ',');

    if (enumRegexExpressions.COORDINATE_DECIMAL.test(formattedCoordinate)) {
        return "decimal";
    } else {
        return "invalid";
    }
}

/**
 * @status: OK
 * @author GASPAR
 * @date 2024-09-29
 * @version 1.0.0
 * 
 * @description 
 *   - Verifica se é um tipo de coordenada LABEL
 *     - LABEL (Graus e Minutos Decimais): 1234.12N/12345.12E
 * 
 * @param coordinate 
 * @returns 
 */
function _isCoordinateLabel(coordinate: string): string {
    // Substitui vírgula por barra e remove espaços em branco
    const formattedCoordinate = coordinate.replace(/[()]/g, '').replace(/,/g, '/').replace(/\s+/g, '');

    if (enumRegexExpressions.COORDINATE_LABEL.test(formattedCoordinate)) {        
        return "label";
    }
    
    return "invalid";
}

/**
 * @status: OK
 * @author GASPAR
 * @date 2024-09-29
 * @version 1.0.0
 * 
 * @description 
 *   - Verifica se é um tipo de coordenada GMS
 *     - GMS (Graus, Minutos e Segundos): 12 34 56N/123 45 67E
 * 
 * @param coordinate 
 * @returns 
 */
function _isCoordinateGMS(coordinate: string): string {
    // Remove espaços em branco antes e depois da vírgula ou barra
    const formattedCoordinate = coordinate.replace(/[()]/g, '').replace(/\s*([,/])\s*/g, '$1');

    if (enumRegexExpressions.COORDINATE_GMS.test(formattedCoordinate)) {
        return "gms";
    } else {
        return "invalid";
    }
}

/**
 * @status: OK
 * @author GASPAR
 * @date 2024-09-29
 * @version 1.0.0
 * 
 * @description 
 *   - Verifica se é um tipo de coordenada PLAN
 *     - PLAN (Plano): 1234N56789E
 * 
 * @param coordinate 
 * @returns 
 */
function _isCoordinatePlan(coordinate: string): string {
    // Remove parênteses e espaços em branco antes e depois da vírgula
    const formattedCoordinate = coordinate.replace(/[()]/g, '');

    if (enumRegexExpressions.COORDINATE_PLAN.test(formattedCoordinate)) {
        return "plan";
    } else {
        return "invalid";
    }
}
//#endregion