<section data-page="app-platform-page">
    <sunrise-app-platform--toast-message></sunrise-app-platform--toast-message>
    <sunrise-app-platform--title-page [titlePage]="this.objPageTitle"></sunrise-app-platform--title-page>
    <div class="cls-page-form">
        <div class="cls-page-form-grid-3-columns">
            <aside class="cls-page-form-sidebar-left">
                aside left
            </aside>
            <div class="cls-page-form-content">
                <p-toolbar class="cls-component-toolbar">
                    <div class="p-toolbar-group-start"></div>
                    <div class="p-toolbar-group-center"></div>
                    <div class="p-toolbar-group-end">
                        <p-button label="Novo" styleClass="cls-component-button" [rounded]="true"
                            icon="fa-solid fa-plus" severity="primary" class="mr-2"
                            (click)="callbackClickButton(this.buttonActions.NEW)" />
                        <p-button label="Salvar Modificações" styleClass="cls-component-button" [rounded]="true"
                            icon="fa-solid fa-save" severity="primary" class="mr-2"
                            (click)="callbackClickButton(this.buttonActions.UPDATE)" />
                        <p-button label="Salvar Novo" styleClass="cls-component-button" [rounded]="true"
                            icon="fa-solid fa-save" severity="primary" class="mr-2"
                            (click)="callbackClickButton(this.buttonActions.SAVE)" />
                        <p-button label="Sair" styleClass="cls-component-button" [rounded]="true"
                            icon="fa-solid fa-right-from-bracket" severity="danger"
                            (click)="callbackClickButton(this.buttonActions.EXIT)" />
                    </div>
                </p-toolbar>
                <p-panel class="cls-component-panel-default" [toggleable]="true" [collapsed]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-default-header">
                            <div class="cls-component-panel-default-header-title">
                                <h2>
                                    <i class="fa-solid fa-bolt"></i>
                                    <span>Cabeçalho do TEMPLATE de Peso & Balanceamento</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-component-form cls-component-form-frame">
                            <div class="formgrid">
                                <div class="grid">
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="Nome do Template #BOLD#"
                                            [control]="this.formWeightBalance.controls.header_data.controls.name">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-drop-down-icon label="Tipo de Aeronave #BOLD#"
                                            [control]="this.formWeightBalance.controls.header_data.controls.aircraft_type"
                                            [arrayOptions]="this.optionsAircraftTypes">
                                        </lib-ui-primeng-drop-down-icon>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="Modelo Aeronave #BOLD#"
                                            [control]="this.formWeightBalance.controls.header_data.controls.aircraft_model">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="ID Manual da Aeronave"
                                            [control]="this.formWeightBalance.controls.header_data.controls.aircraft_manual_id">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="Data Manual"
                                            [control]="this.formWeightBalance.controls.header_data.controls.aircraft_manual_date">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="Versão Manual"
                                            [control]="this.formWeightBalance.controls.header_data.controls.aircraft_manual_version">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="Data de Aferição"
                                            [control]="this.formWeightBalance.controls.header_data.controls.calibration_date_utc">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="Próxima Aferição"
                                            [control]="this.formWeightBalance.controls.header_data.controls.calibration_next_date_utc">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-input-text label="Observação Aferição"
                                            [control]="this.formWeightBalance.controls.header_data.controls.calibration_observation">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-12 md:col-12 lg:12 xl:12">
                                        <lib-ui-primeng-input-text label="Descrição da Configuração #BOLD#"
                                            [control]="this.formWeightBalance.controls.header_data.controls.description_config">
                                        </lib-ui-primeng-input-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
                <p-panel class="cls-component-panel-default" [toggleable]="true" [collapsed]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [collapsed]="true"
                    [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-default-header">
                            <div class="cls-component-panel-default-header-title">
                                <h2>
                                    <i class="fa-solid fa-bolt"></i>
                                    <span>Unidades de Medidas</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-component-form cls-component-form-frame">
                            <div class="formgrid">
                                <div class="grid">
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-drop-down label="Peso #BOLD#"
                                            [control]="this.formWeightBalance.controls.unit_data.controls.weight"
                                            [arrayOptions]="this.optionsCgWeightUnits">
                                        </lib-ui-primeng-drop-down>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-drop-down label="Distância #BOLD#"
                                            [control]="this.formWeightBalance.controls.unit_data.controls.arm"
                                            [arrayOptions]="this.optionsCgArmUnits">
                                        </lib-ui-primeng-drop-down>
                                    </div>
                                    <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                        <lib-ui-primeng-drop-down label="Combustível #BOLD#"
                                            [control]="this.formWeightBalance.controls.unit_data.controls.fuel"
                                            [arrayOptions]="this.optionsCgFuelUnits">
                                        </lib-ui-primeng-drop-down>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
                <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-default-header">
                            <div class="cls-component-panel-default-header-title">
                                <h2>
                                    <i class="fa-solid fa-bolt"></i>
                                    <span>Componentes para Cálculo do Centro de Gravidade</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="grid">
                            <div class="field col-12 md:col-12 lg:col-5 xl:col-5">
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> Perspectiva</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <sunrise-app-platform--aircraft-view-perspective>

                                        </sunrise-app-platform--aircraft-view-perspective>
                                    </ng-template>
                                </p-panel>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-7 xl:col-7">
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> Componentes da Aeronave</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <p-tabView [scrollable]="true" class="cls-component-tabview-page"
                                            [activeIndex]="2">
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-bullseye"></i>
                                                        <div>Centro Gravidade</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-fieldset legend="Localização DATUM"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-6 xl:col-6">

                                                                    </div>
                                                                </div>
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-6 xl:col-6">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                </ng-template>
                                            </p-tabPanel>
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-plane"></i>
                                                        <div>Aeronave</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-fieldset legend="BEW - Peso Vazio Básico"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Peso #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Braço Longitudinal #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Braço Lateral"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                    <p-fieldset legend="ZFW - Peso Zero Combustível"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Peso #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Braço Longitudinal #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Braço Lateral"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                    <p-fieldset legend="TOW - Peso Máximo de Decolagem"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Peso #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Braço Longitudinal #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Braço Lateral"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                    <p-fieldset legend="LDW - Peso Máximo de Pouso"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Peso #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Braço Longitudinal #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text label="Braço Lateral"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                </ng-template>
                                            </p-tabPanel>
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-thumbtack"></i>
                                                        <div>Fixo</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-fieldset legend="Componentes que fazem parte do BEW"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="grid">
                                                            <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
                                                                <p-table [value]="this.fixCgComponents" dataKey="id"
                                                                    editMode="row"
                                                                    [tableStyle]="{'min-width': '50rem'}">
                                                                    <ng-template pTemplate="header">
                                                                        <tr>                                                                            
                                                                            <th style="width:13%">Descrição</th>
                                                                            <th style="width:10%">Peso ({{this.formWeightBalance.controls.unit_data.controls.weight.value}})</th>
                                                                            <th style="width:15%">B. Long ({{this.formWeightBalance.controls.unit_data.controls.arm.value}})</th>
                                                                            <th style="width:15%">B. Lateral ({{this.formWeightBalance.controls.unit_data.controls.arm.value}})</th>
                                                                            <th style="width:10%">Dimensão</th>
                                                                            <th style="width:10%">Cor</th>
                                                                            <th style="width:10%">Label</th>
                                                                            <th style="width:10%"></th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-fixCgComponents let-editing="editing" let-ri="rowIndex">
                                                                        <tr [pEditableRow]="fixCgComponents">
                                                                            <td>
                                                                                <p-cellEditor>
                                                                                    <ng-template pTemplate="input">
                                                                                        <input 
                                                                                            pInputText type="text" 
                                                                                            [(ngModel)]="fixCgComponents.name" 
                                                                                            required />
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="output">
                                                                                        {{fixCgComponents.description}}
                                                                                    </ng-template>
                                                                                </p-cellEditor>
                                                                            </td>
                                                                            <td>
                                                                                <p-cellEditor>
                                                                                    <ng-template pTemplate="input">
                                                                                        <input 
                                                                                            pInputText type="text" 
                                                                                            [(ngModel)]="fixCgComponents.weight" 
                                                                                            required />
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="output">
                                                                                        {{fixCgComponents.weight}}
                                                                                    </ng-template>
                                                                                </p-cellEditor>
                                                                            </td>   
                                                                            <td>
                                                                                <p-cellEditor>
                                                                                    <ng-template pTemplate="input">
                                                                                        <input 
                                                                                            pInputText type="text" 
                                                                                            [(ngModel)]="fixCgComponents.longitudinal_arm" 
                                                                                            required />
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="output">
                                                                                        {{fixCgComponents.longitudinal_arm}}
                                                                                    </ng-template>
                                                                                </p-cellEditor>
                                                                            </td>  
                                                                            <td>
                                                                                <p-cellEditor>
                                                                                    <ng-template pTemplate="input">
                                                                                        <input 
                                                                                            pInputText type="text" 
                                                                                            [(ngModel)]="fixCgComponents.lateral_arm" 
                                                                                            required />
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="output">
                                                                                        {{fixCgComponents.lateral_arm}}
                                                                                    </ng-template>
                                                                                </p-cellEditor>
                                                                            </td>   
                                                                            <td>
                                                                                <p-cellEditor>
                                                                                    <ng-template pTemplate="input">
                                                                                        <input 
                                                                                            pInputText type="text" 
                                                                                            [(ngModel)]="fixCgComponents.draw_dimension" 
                                                                                            required />
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="output">
                                                                                        {{fixCgComponents.draw_dimension}}
                                                                                    </ng-template>
                                                                                </p-cellEditor>
                                                                            </td>    
                                                                            <td>
                                                                                <p-cellEditor>
                                                                                    <ng-template pTemplate="input">
                                                                                        <input 
                                                                                            pInputText type="text" 
                                                                                            [(ngModel)]="fixCgComponents.draw_color" 
                                                                                            required />
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="output">
                                                                                        {{fixCgComponents.draw_color}}
                                                                                    </ng-template>
                                                                                </p-cellEditor>
                                                                            </td>   
                                                                            <td>
                                                                                <p-cellEditor>
                                                                                    <ng-template pTemplate="input">
                                                                                        <input 
                                                                                            pInputText type="text" 
                                                                                            [(ngModel)]="fixCgComponents.draw_label" 
                                                                                            required />
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="output">
                                                                                        {{fixCgComponents.draw_label}}
                                                                                    </ng-template>
                                                                                </p-cellEditor>
                                                                            </td>    
                                                                            <td>
                                                                                <div class="flex align-items-center justify-content-center gap-2">
                                                                                    <button 
                                                                                        *ngIf="!editing" 
                                                                                        pButton 
                                                                                        pRipple 
                                                                                        type="button" 
                                                                                        pInitEditableRow 
                                                                                        icon="pi pi-pencil"                                                                                         
                                                                                        class="p-button-rounded p-button-text">
                                                                                    </button>
                                                                                    <button 
                                                                                        *ngIf="editing" 
                                                                                        pButton 
                                                                                        pRipple 
                                                                                        type="button" 
                                                                                        pSaveEditableRow 
                                                                                        icon="pi pi-check"                                                                                         
                                                                                        class="p-button-rounded p-button-text p-button-success mr-2">
                                                                                    </button>
                                                                                    <button 
                                                                                        *ngIf="editing" 
                                                                                        pButton pRipple 
                                                                                        type="button" 
                                                                                        pCancelEditableRow 
                                                                                        icon="pi pi-times"                                                                                         
                                                                                        class="p-button-rounded p-button-text p-button-danger">
                                                                                    </button>
                                                                                </div>
                                                                            </td>                                                        
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                </ng-template>
                                            </p-tabPanel>
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-plus-minus"></i>
                                                        <div>Variável</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                </ng-template>
                                            </p-tabPanel>
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-link"></i>
                                                        <div>Externo</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                </ng-template>
                                            </p-tabPanel>
                                        </p-tabView>
                                    </ng-template>
                                </p-panel>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
                <p-panel class="cls-component-panel-default" [toggleable]="true"
                    [collapseIcon]="'fa-solid fa-chevron-down'" [expandIcon]="'fa-solid fa-chevron-up'">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-default-header">
                            <div class="cls-component-panel-default-header-title">
                                <h2>
                                    <i class="fa-solid fa-bolt"></i>
                                    <span>Gráficos de Limites</span>
                                </h2>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="grid">
                            <div class="field col-12 md:col-12 lg:col-5 xl:col-5">
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> GRÁFICO LIMITE C.G.LONGITUDINAL</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <sunrise-app-platform--chart-cg-limits
                                            [objChartCGLimits]="this.objChartCGLongitudinal">
                                        </sunrise-app-platform--chart-cg-limits>
                                    </ng-template>
                                </p-panel>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-7 xl:col-7">
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> CONFIGURAÇÃO GRÁFICO LIMITE C.G. LONGITUDINAL</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <p-tabView [scrollable]="true" class="cls-component-tabview-page"
                                            [activeIndex]="1">
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-chart-bar"></i>
                                                        <div>Eixos</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-fieldset legend="Eixo Y - Peso Bruto"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Valor Mínimo Y #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.y_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Valor Máximo Y #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.y_max">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-drop-down
                                                                            label="Escala Eixo Y #BOLD#"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.y_scale"
                                                                            [arrayOptions]="this.optionsChartScales">
                                                                        </lib-ui-primeng-drop-down>
                                                                    </div>
                                                                </div>
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Step Eixo #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.y_step_axis">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Step Grid #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.y_step_grid">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-drop-down appendTo="body"
                                                                            label="Eixo Oposto"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.y_show_opposite_unit"
                                                                            [arrayOptions]="this.optionsCgWeightUnits">
                                                                        </lib-ui-primeng-drop-down>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                    <p-fieldset legend="Eixo X - Estação Fuselagem"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Valor Mínimo X #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_min">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Valor Máximo X #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_max">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-drop-down
                                                                            label="Escala eixo X #BOLD#"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_scale"
                                                                            [arrayOptions]="this.optionsChartScales">
                                                                        </lib-ui-primeng-drop-down>
                                                                    </div>
                                                                </div>
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Step Eixo #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_step_axis">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Step Grid #BOLD#"
                                                                            [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_step_grid">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                                        <lib-ui-primeng-drop-down appendTo="body"
                                                                            label="EIXO OPOSTO #BOLD#"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.x_show_opposite_unit"
                                                                            [arrayOptions]="this.optionsCgArmUnits">
                                                                        </lib-ui-primeng-drop-down>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                </ng-template>
                                            </p-tabPanel>
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-chart-line"></i>
                                                        <div>Plotagem</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-table [value]="this.plotChartData" dataKey="id" editMode="row"
                                                        [tableStyle]="{'min-width': '50rem'}">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th style="width:50%">Coordenadas</th>
                                                                <th style="width:10%">Tipo</th>
                                                                <th style="width:10%">Linha</th>
                                                                <th style="width:10%">Cor</th>
                                                                <th style="width:10%">Espessura</th>
                                                                <th style="width:10%"></th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-plotChartData
                                                            let-editing="editing" let-ri="rowIndex">
                                                            <tr [pEditableRow]="plotChartData">
                                                                <td>
                                                                    <p-cellEditor>
                                                                        <ng-template pTemplate="input">
                                                                            <input pInputText type="text"
                                                                                [formControl]="plotChartData.array_coordinates" />
                                                                        </ng-template>
                                                                        <ng-template pTemplate="output">
                                                                            {{plotChartData.array_coordinates}}
                                                                        </ng-template>
                                                                    </p-cellEditor>
                                                                </td>
                                                                <td>
                                                                    <p-cellEditor>
                                                                        <ng-template pTemplate="input">
                                                                            <input pInputText type="text"
                                                                                [formControl]="plotChartData.color" />
                                                                        </ng-template>
                                                                        <ng-template pTemplate="output">
                                                                            {{plotChartData.color}}
                                                                        </ng-template>
                                                                    </p-cellEditor>
                                                                </td>
                                                                <td>
                                                                    <p-cellEditor>
                                                                        <ng-template pTemplate="input">
                                                                            <input pInputText type="text"
                                                                                [formControl]="plotChartData.size" />
                                                                        </ng-template>
                                                                        <ng-template pTemplate="output">
                                                                            {{plotChartData.size}}
                                                                        </ng-template>
                                                                    </p-cellEditor>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </ng-template>
                                            </p-tabPanel>
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-image"></i>
                                                        <div>Imagem</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                </ng-template>
                                            </p-tabPanel>
                                            <p-tabPanel class="cls-component-tabview-page-tabpanel">
                                                <ng-template pTemplate="header">
                                                    <div class="cls-component-tabview-page-tabpanel-header">
                                                        <i class="fa-solid fa-closed-captioning"></i>
                                                        <div>Legenda</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="content">
                                                    <p-fieldset legend="Limites"
                                                        styleClass="cls-app-platform--component-chart-fieldset-config">
                                                        <div class="cls-component-form cls-component-form-frame">
                                                            <div class="formgrid">
                                                                <div class="grid">
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-8">
                                                                        <lib-ui-primeng-input-text
                                                                            label="Coordenadas dos Limites #BOLD#"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.limits_data.controls[0].controls.coordinates">
                                                                        </lib-ui-primeng-input-text>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-2">
                                                                        <lib-ui-primeng-input-number-button
                                                                            label="Espessura #BOLD#" [maxNumber]="10"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.limits_data.controls[0].controls.size">
                                                                        </lib-ui-primeng-input-number-button>
                                                                    </div>
                                                                    <div
                                                                        class="field col-12 md:col-12 lg:col-12 xl:col-2">
                                                                        <lib-ui-primeng-input-color label="Cor #BOLD#"
                                                                            [control]="this.formWeightBalance.controls.chart_longitudinal_data.controls.limits_data.controls[0].controls.color">
                                                                        </lib-ui-primeng-input-color>
                                                                    </div>
                                                                </div>
                                                                <div class="grid">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-fieldset>
                                                </ng-template>
                                            </p-tabPanel>
                                        </p-tabView>
                                    </ng-template>
                                </p-panel>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12 md:col-12 lg:col-5 xl:col-5">
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> GRÁFICO LIMITE C.G.LATERAL</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <sunrise-app-platform--chart-cg-limits
                                            [objChartCGLimits]="this.objChartCGLateral">
                                        </sunrise-app-platform--chart-cg-limits>
                                    </ng-template>
                                </p-panel>
                            </div>
                            <div class="field col-12 md:col-12 lg:col-7 xl:col-7">
                                <p-panel class="cls-component-panel-form-fieldset">
                                    <ng-template pTemplate="header">
                                        <div class="cls-component-panel-form-fieldset-header">
                                            <div class="cls-component-panel-form-fieldset-header-title">
                                                <h3>
                                                    <i class="fa-solid fa-check"></i>
                                                    <span> CONFIGURAÇÃO GRÁFICO LIMITE C.G. LATERAL</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <p-fieldset legend="Gráfico"
                                            styleClass="cls-app-platform--component-chart-fieldset-config">
                                            <p class="m-0">
                                                Lorem ipsum dolor sit amet...
                                            </p>
                                        </p-fieldset>
                                        <p-fieldset legend="Eixo Y - Peso Bruto"
                                            styleClass="cls-app-platform--component-chart-fieldset-config">
                                            <div class="cls-component-form cls-component-form-frame">
                                                <div class="formgrid">
                                                    <div class="grid">
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Valor Mínimo Y #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.y_min">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Valor Máximo Y #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.y_max">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-drop-down label="Escala Eixo Y #BOLD#"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.y_scale"
                                                                [arrayOptions]="this.optionsChartScales">
                                                            </lib-ui-primeng-drop-down>
                                                        </div>
                                                    </div>
                                                    <div class="grid">
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Step Eixo #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.y_step_axis">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Step Grid #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.weight.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.y_step_grid">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-drop-down appendTo="body"
                                                                label="Eixo Oposto"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.y_show_opposite_unit"
                                                                [arrayOptions]="this.optionsCgWeightUnits">
                                                            </lib-ui-primeng-drop-down>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-fieldset>
                                        <p-fieldset legend="Eixo X - Estação Fuselagem"
                                            styleClass="cls-app-platform--component-chart-fieldset-config">
                                            <div class="cls-component-form cls-component-form-frame">
                                                <div class="formgrid">
                                                    <div class="grid">
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Valor Mínimo X #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.x_min">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Valor Máximo X #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.x_max">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-drop-down label="Escala eixo X #BOLD#"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.x_scale"
                                                                [arrayOptions]="this.optionsChartScales">
                                                            </lib-ui-primeng-drop-down>
                                                        </div>
                                                    </div>
                                                    <div class="grid">
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Step Eixo #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.x_step_axis">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-input-text label="Step Grid #BOLD#"
                                                                [groupRightText]="this.formWeightBalance.controls.unit_data.controls.arm.value"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.x_step_grid">
                                                            </lib-ui-primeng-input-text>
                                                        </div>
                                                        <div class="field col-12 md:col-12 lg:col-12 xl:col-4">
                                                            <lib-ui-primeng-drop-down appendTo="body"
                                                                label="EIXO OPOSTO #BOLD#"
                                                                [control]="this.formWeightBalance.controls.chart_lateral_data.controls.x_show_opposite_unit"
                                                                [arrayOptions]="this.optionsCgArmUnits">
                                                            </lib-ui-primeng-drop-down>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-fieldset>
                                        <!--<p-fieldset legend="Limites"
                                                styleClass="cls-app-platform--component-chart-fieldset-config">
                                                <p class="m-0">
                                                    Lorem ipsum dolor sit amet...
                                                </p>
                                            </p-fieldset>-->
                                    </ng-template>
                                </p-panel>
                            </div>
                        </div>
                    </ng-template>
                </p-panel>
            </div>
            <aside class="cls-page-form-sidebar-right">
                <p-panel class="cls-component-panel-aside">
                    <ng-template pTemplate="header">
                        <div class="cls-component-panel-aside-header">
                            <div class="cls-component-panel-aside-header-title">
                                <span><i class="fa-solid fa-bolt"></i> Conversor Unidades</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">

                    </ng-template>
                </p-panel>
            </aside>
        </div>
    </div>
</section>