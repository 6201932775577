<section class="cls-pge-app-form">
    <sunrise-toast-message></sunrise-toast-message>
    <!-- <p-confirmDialog class="cls-component-confirm-dialog-default" /> -->
    <sunrise-confirm-delete-dialog dialogKey="crew_aircraft_conf_del"></sunrise-confirm-delete-dialog>
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        [extraData]="this.extraDataDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
    <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
    <div class="cls-frame-dashboard-horizontal-main-form-grid-1-col">
        <section class="cls-frame-dashboard-horizontal-main-content">            
            <p-tabView [scrollable]="true" class="cls-cmp-ptv-tabview-page">
                 <!-- REGISTER DATA -->
                 <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-clipboard-list"></i>
                            <span>{{this.classInternationalization.getTranslation("ttl_Register")}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-component-tabview-page-tabpanel-content">
                            <sunrise-tab-crew-register 
                                [formComponent]="this.formCrewData "                                
                                (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)"
                                (actionCrewData)="this.handlerActionCrewData($event)"
                                (actionTabCrewData)="this.handlerActionToolbarTabCrewData($event)">
                            </sunrise-tab-crew-register>
                        </div>
                    </ng-template>
                </p-tabPanel>
                 <!-- AERONAVE DATA -->
                 <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formCrewData.controls.crew_data_id.value !== null">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-clipboard-list"></i>
                            <span>{{this.classInternationalization.getTranslation("ttl_Aircraft")}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-component-tabview-page-tabpanel-content">
                            <sunrise-tab-aircraft-data 
                                [formComponent]="this.formCrewData"
                                [allOptionsAircraftData] = "this.optionsAircraftData"
                                [allAircraftData] = "this.listAircraftData"
                                [allOwnersOperatorsData]="this.listOwnerOperatorData"
                                (actionCrewAircraft)="this.handlerActionCrewAircraft($event)"
                                (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)">
                            </sunrise-tab-aircraft-data>
                        </div>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </section>             
    </div>
</section>