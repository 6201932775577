//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../_library/layouts/components/primeng/primeng-components.module';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-country-flag',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './country-flag.component.html'
})
export class CountryFlagComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() countryCode!: string;
  //#endregion

  //#region "|--- PROPERTIES---|"
  
  //#endregion

  constructor() {}

  ngOnInit(): void {}
}
