//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
//#endregion

//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { AuthenticationService } from '../../_services/module-authentication/authentication.service';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private _authenticationService: AuthenticationService, 
    private _router: Router
  ) {}

  canActivate(): boolean {
    if (this._authenticationService.getToken()) {
      return true;
    } else {
      this._router.navigate(['/login']);
      return false;
    }
  }
}