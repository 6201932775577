export const environment = {
    production: false,
    angular_env: "development",
    web_service_name: "dev-sunrise-ftd-web-portal-angular",
    project_name: "SUNRISE PROJECT",
    front_end_version_build: "1.0.0",
    backs_end_version_build: "1.0.0",
    api_protocol: "https",
    api_host: "api-web.gerencial.app.br",
   
    api_url_port: "443",
    api_aim_port: "443", 
    api_canttech_port: "443",
    api_authentication_port: "443",
    api_aircraft_port: "443", 
    api_crew_port: "443",   
    api_flight_port: "443",
    api_pax_port: "443",
    api_weight_balance_port: "443",
    
    api_url_segment: "mod_api",
    api_aim_url_segment: "mod_aim",
    api_canttech_url_segment: "mod_ctc", 
    api_authentication_url_segment: "mod_auth",
    api_aircraft_url_segment: "mod_acf",
    api_crew_url_segment: "mod_crew",
    api_flight_url_segment: "mod_flt",
    api_pax_url_segment: "mod_pax",    
    api_weight_balance_url_segment: "mod_wbl",
    
    api_path: "api/v1",
    show_log_exception: true,
    no_image_available: "no_image_available.jpg",
    toast_life: 5000,
    toast_key: "toast-basic",
    waiting_dialog_life: 1000
}