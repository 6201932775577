<div class="cls-app-platform--component">
    <div class="cls-app-platform--component-title-page">
        <div class="cls-app-platform--component-title-page-icon">
            <i class="{{this.titlePage.icon}}"></i>
        </div>
        <div class="cls-app-platform--component-title-page-content">
            <h1>{{this.titlePage.title}}</h1>
            <div class="cls-app-platform--component-title-page-content-subtitle">{{this.titlePage.subtitle}}</div>
        </div>
    </div>
</div>