//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// ***** NPM ***** //
import HTTP_STATUS from 'http-status-codes';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../config/settingConfig';

// ***** LIBRARY ***** //
import { PrimengComponentsModule } from '../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { ConfirmationService, MessageService } from 'primeng/api';

// ***** FORM ***** //
import { initAircraftDataForm } from '../../form-init/aircraft-data-form';

// ***** MOCK ***** //
import { MockAircraftFormDataClass } from './_mock/MockAircraftFormDataClass';

// ***** DOMÍNIOS ***** //

// ***** COMPONENT ***** //
import { AircraftDataSummaryComponent } from '../../../../_components/layout/aircraft-data-summary/aircraft-data-summary.component';
import { TitlePageComponent } from '../../../../_components/layout/title-page/title-page.component';
import { TabRegistrationDataComponent } from './_components/register-data/tab-registration-data/tab-registration-data.component';
import { TabSpecificationDataComponent } from './_components/specification/tab-specification-data/tab-specification-data.component';
import { ToastMessageComponent } from '../../../../_components/layout/toast-message/toast-message.component';
import { TabWeightBalanceSheetFormComponent } from '../aircraft-data-form/_components/weigh-balance-sheet/tab-weight-balance-sheet-form/tab-weight-balance-sheet-form.component';
import { TabWeightBalanceSheetListComponent } from './_components/weigh-balance-sheet/tab-weight-balance-sheet-list/tab-weight-balance-sheet-list.component';
import { WaitingDialogComponent } from '../../../../_components/layout/waiting-dialog/waiting-dialog.component';

// ***** CLASSES ***** //
import { LocalMethodsHandlerClass } from '../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { ModuleAircraftService } from '../../../../_services/module-aircraft/module-aircraft.service';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from '../../../../../_library/definitions/ButtonAction';
import { DIALOG_WAITING_TYPE } from 'src/app/_library/definitions/DialogWaitingType';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPageTitle } from '../../../../../_library/interfaces/IPageTitle';
import { IWaitingDialog } from '../../../../../_library/interfaces/IWaitingDialog';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-aircraft-data-form',
  standalone: true,
  imports: [
    AircraftDataSummaryComponent,
    CommonModule,
    TitlePageComponent,
    PrimengComponentsModule,
    TabRegistrationDataComponent,
    TabSpecificationDataComponent,
    TabWeightBalanceSheetFormComponent,
    TabWeightBalanceSheetListComponent,
    ToastMessageComponent,
    WaitingDialogComponent
  ],
  templateUrl: './aircraft-data-form.component.html'
})
export class AircraftDataFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** GENERAL ***** //
  public buttonActions!: any;
  public classModuleMethods!: any;
  public classLocalMethods!: any;

  // ***** OBJECTS ***** //
  public objPageTitle!: IPageTitle;

  // ***** FORMS ***** //
  public formAircraftData: any

  // ***** DIALOG ***** //
  public bolShowWaitingDialog!: boolean;
  public strTypeWaitingDialog!: string;

  // ***** ARRAYS ***** //

  // ***** OPTIONS***** //

  // ***** OTHERS ***** //
  public activeTabIndex!: number;
  public currentMarkDataFromRabAnac!: string;

  public currentIdWeightBalanceSheet!: any;
  public weightBalanceSheetScreen!: any;
  //#endregion

  constructor(
    private _confirmationService: ConfirmationService,
    private _messageService: MessageService,
    private _moduleAircraftApisService: ModuleAircraftService,
    private _routerActive: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initAircraftDataForm();

    //#region "|--- MOCKS ---|"
    //#endregion

    //#region "|--- BANCO DE DADOS ---|"
    //#endregion

    this._initFormAircraftDataEvents();
    this._checkEditMode();
  }

  //#region "|--- EVENT HANDLERS ---|"
  private _initFormAircraftDataEvents(): void {
    this.formAircraftData.get('registration_data.mark').valueChanges.subscribe((xValue: any) => {
      if (xValue) {
        if (xValue.length > 3) {
          const tempCountryCode = LocalMethodsHandlerClass.getCountryCodeFromRegistration(xValue);

          if (tempCountryCode !== '') {
            this.formAircraftData.controls.registration_data.controls.country.setValue(tempCountryCode, { emitEvent: false });
          }
        }

        if (xValue.length >= 5 && this.formAircraftData.controls.registration_data.controls.country.value == "BR") {
          this._getDataFormRabAnac(xValue);
        }
      }
    });

    this.formAircraftData.get('registration_data.country').valueChanges.subscribe((xValue: any) => {
      if (xValue) {
        if (this.currentMarkDataFromRabAnac != xValue) {
          if (this.formAircraftData.controls.registration_data.controls.mark.value && this.formAircraftData.controls.registration_data.controls.mark.value.length >= 5 && xValue == "BR") {
            this.currentMarkDataFromRabAnac = xValue;

            this._getDataFormRabAnac(this.formAircraftData.controls.registration_data.controls.mark.value);
          }
        }
      }
    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Inicializa as variáveis do componente.
   * 
   */
  private _initVariables(): void {
    this.bolShowWaitingDialog = false;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.AIRCRAFT_DATA;

    this.weightBalanceSheetScreen = "list";
    this.currentMarkDataFromRabAnac = "";

    this.activeTabIndex = 0;

    this.classModuleMethods = ModuleMethodsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
  }

  /**
   * @author GASPAR
   * @date 2024-09-12
   * @version 1.0.0
   * 
   * @description Inicializa o formulário de dados da aeronave.
   */
  private _initAircraftDataForm(): void {
    this.formAircraftData = initAircraftDataForm();
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método que verifica se é uma edição de dados da aeronave ou um novo registro.
   */
  private async _checkEditMode(): Promise<void> {
    await this._routerActive.params.subscribe(async xParams => {
      if (xParams["id"]) {
        this.objPageTitle = {
          title: 'Editar Aeronave',
          subtitle: 'Alterar Dados de uma Aeronave',
          icon: 'fa-solid fa-edit'
        }

        this._getAircraftDataById(xParams["id"]);
      } else {
        this.objPageTitle = {
          title: 'Nova Aeronave',
          subtitle: 'Inserir Dados de Aeronaves',
          icon: 'fa-solid fa-plus'
        }
      }
    })
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description Método que cria um novo registro de dados da aeronave.
   */
  private async _newRegistrationData() {
    this.private_clearForm();
    this._router.navigate(['/app/aircraft/form']);
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método que salva os dados do "REGISTRO DA AERONAVE".
   * 
   * @abstract 
   * - Importante ressaltar que quando você está salvando um novo "REGISTRO DA AERONAVE", você também está criando a estrutura de Dados da Aeronave.
   * - "REGISTRO DA AERONAVE" é um subconjunto dos Dados da Aeronave.
   */
  private async _saveRegistrationData() {
    if (this.formAircraftData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formAircraftData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Salvar Registro Aeronave',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      await this._moduleAircraftApisService.saveAircraftData(this.formAircraftData).subscribe({
        next: (xApiResponse) => {
          if (xApiResponse.status_code == HTTP_STATUS.CREATED) {
            this.formAircraftData.controls.aircraft_data_id.setValue(xApiResponse.data[0].data[0].id);

            this._messageService.add({
              severity: 'success',
              summary: 'Salvar Registro Aeronave',
              detail: `${xApiResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Salvar Registro Aeronave',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método que atualiza os dados da aeronave.
   * 
   * @abstract
   *   - Importante ressaltar que quando você está atualizando os dados da aeronave, você também está atualizando o "REGISTRO DA AERONAVE" e os demais Conjunto de Dados.
   */
  private async _updateAircraftData() {

    if (this.formAircraftData.invalid) {
      const messageErrosFormRequest = LocalMethodsHandlerClass.handlerFormFieldsErrors(this.formAircraftData, ModuleMethodsHandlerClass.getFunctionFieldName());

      this._messageService.add({
        severity: 'error',
        summary: 'Atualizar Registro Aeronave',
        detail: `Os campos não foram validados: \n- ${messageErrosFormRequest}`,
        key: settingConfig.TOAST_KEY,
        life: settingConfig.TOAST_LIFE
      });
    } else {
      await this._moduleAircraftApisService.updateAircraftData(this.formAircraftData.controls.aircraft_data_id.value, this.formAircraftData).subscribe({
        next: (xResponse) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            this._messageService.add({
              severity: 'success',
              summary: 'Atualizar Registro Aeronave',
              detail: `${xResponse.data[0].message}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        },
        error: (xError) => {
          let tempSeverity = 'error';

          if (xError.http_error == 404) {
            tempSeverity = 'info'
          }

          this._messageService.add({
            severity: tempSeverity,
            summary: 'Atualizar Registro Aeronave',
            detail: `${xError.detail}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      });
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Método que Atualiza a Ficha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceSheetId 
   * @param xAircraftId 
   */
  private async _updateAircraftWeightBalanceSheet(xWeightBalanceSheetId: string, xAircraftId: string) {
    await this._moduleAircraftApisService.updateAircraftWeightBalanceSheet(xAircraftId, { id: xWeightBalanceSheetId }).subscribe({
      next: (xResponse) => {
        if (xResponse.status_code == HTTP_STATUS.OK) {
          this._messageService.add({
            severity: 'success',
            summary: 'Atualizado Peso e Balanceamento da Aeronave.',
            detail: `${xResponse.data[0].message}`,
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Atualizado Peso e Balanceamento da Aeronave.',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-27
   * @version 1.0.0
   * 
   * @description Método que deleta a Ficha de Peso e Balanceamento.
   * 
   * @param xWeightBalanceSheetId 
   * @param xAircraftId 
   */
  private async _deleteAircraftWeightBalanceSheet(xIdAircraft: string, xIdWeightBalanceSheet: string) {
    this._confirmationService.confirm({
      message: `Você deseja APAGAR está Ficha de Peso e Balanceamento?`,
      header: 'Confirmação Apagar a Ficha de Peso e Balanceamento.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: async () => {
        await this._moduleAircraftApisService.deleteAircraftWeightBalanceSheet(xIdAircraft, xIdWeightBalanceSheet).subscribe({
          next: (xResponse) => {
            if (xResponse.status_code == HTTP_STATUS.OK) {
              this._messageService.add({
                severity: 'success',
                summary: 'Apagar a Ficha de Peso e Balanceamento.',
                detail: `${xResponse.data[0].message}`,
                key: settingConfig.TOAST_KEY,
                life: settingConfig.TOAST_LIFE
              });
            }
          },
          error: (xError) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Apagar a Ficha de Peso e Balanceamento.',
              detail: `${xError.detail}`,
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }
        })
      },
      reject: () => {
        this._messageService.add({
          severity: 'warn',
          summary: 'Apagar a Ficha de Peso e Balanceamento.',
          detail: 'O Processo de Apagar a Ficha de Peso e Balanceamento foi Cancelado pelo Usuário.',
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description Método que recupera os dados da aeronave pelo ID.
   * 
   * @param xAircraftId 
   */
  private async _getAircraftDataById(xAircraftId: string) {
    this.bolShowWaitingDialog = true;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.AIRCRAFT_DATA;

    await this._moduleAircraftApisService.getAircraftDataById(xAircraftId).subscribe({
      next: (xResponse: any) => {
        if (xResponse) {
          const tempData = xResponse[0];

          // Atualiza o formulário com os dados da aeronave.
          this.formAircraftData.patchValue(tempData, { emitEvent: false });

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          setTimeout(() => {
            this.bolShowWaitingDialog = false;

            /*this._messageService.add({
              severity: 'success',
              summary: 'Recuperar Dados da Aeronave',
              detail: 'Dados da aeronave carregados com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });*/
          }, settingConfig.WAITING_DIALOG_LIFE);/**/
        } else {
          this.bolShowWaitingDialog = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Listar Aeronaves',
            detail: 'Não foi possível carregar a lista de aeronaves.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowWaitingDialog = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Listar Aeronaves',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @description Recupera os dados da aeronave no RAB/ANAC.
   * 
   * @param xMark 
   */
  private async _getDataFormRabAnac(xMark: string): Promise<void> {
    // ANTES DE QUALQUER COISA, LIMPA OS CAMPOS DO FORMULÁRIO DE REGISTRO DE AERONAVE
    this.formAircraftData.controls.registration_data.controls.aircraft_type.setValue(null);
    this.formAircraftData.controls.registration_data.controls.flight_rule.setValue(null);
    this.formAircraftData.controls.registration_data.controls.manufacturer.setValue(null);
    this.formAircraftData.controls.registration_data.controls.model.setValue(null);
    this.formAircraftData.controls.registration_data.controls.icao_code.setValue(null);
    this.formAircraftData.controls.registration_data.controls.serial_number.setValue(null);
    this.formAircraftData.controls.registration_data.controls.year_manufactured.setValue(null);
    this.formAircraftData.controls.registration_data.controls.fin_number.setValue(null);
    this.formAircraftData.controls.registration_data.controls.aircraft_name.setValue(null);
    this.formAircraftData.controls.registration_data.controls.registration_data_remarks.setValue(null);

    this.bolShowWaitingDialog = true;
    this.strTypeWaitingDialog = DIALOG_WAITING_TYPE.ANAC_RAB;

    await this._moduleAircraftApisService.getAnacRabData(xMark).subscribe({
      next: (xResponse: any) => {
        if (xResponse) {
          const tempData = xResponse[0];
          const tempOwnerOperador: any = [];

          const tempOwnerName: any = [];
          const tempOwnerNi: any = [];

          this.formAircraftData.controls.registration_data.controls.model.setValue(tempData.model);
          this.formAircraftData.controls.registration_data.controls.serial_number.setValue(tempData.serial_number);
          this.formAircraftData.controls.registration_data.controls.manufacturer.setValue(tempData.manufacturer);
          this.formAircraftData.controls.registration_data.controls.icao_code.setValue(tempData.icao_code);
          this.formAircraftData.controls.registration_data.controls.year_manufactured.setValue(tempData.year_manufactured);
          this.formAircraftData.controls.registration_data.controls.aircraft_type.setValue(tempData.aircraft_type);
          this.formAircraftData.controls.registration_data.controls.flight_rule.setValue(tempData.flight_rule);

          // OWNER
          tempData.owner.forEach((xElementOwner: any) => {
            tempOwnerName.push(xElementOwner.name.trim());
            tempOwnerNi.push(xElementOwner.ni.trim());

            tempOwnerOperador.push(
              {
                owner_operator_id: LocalMethodsHandlerClass.generateUuid(),
                type_owner_operator: "owner",
                avatar_image: "",
                avatar_color: "",
                type_entity: xElementOwner.type_entity,
                country: xElementOwner.ni ? "BR" : "",
                type_ni: xElementOwner.ni ? (xElementOwner.ni.length == 11 ? "CPF" : "CNPJ") : "",
                ni: xElementOwner.ni,
                name: xElementOwner.name,
                responsible: {
                  name: "",
                  contact: [
                    {
                      title: "Contato Responsável",
                      type: 'mobile',
                      ddi: '+55',
                      ddd: '',
                      number: '',
                      normalized: '',
                      is_whatsapp: true,
                      is_telegram: true,
                      observation: '',
                      receive_news: true
                    }
                  ]
                },
                address: [],
                contacts: [],
                owner_operator_remarks: ""
              }
            )
          });

          // OPERATOR
          tempData.operator.forEach((xElementOperator: any) => {
            if (tempOwnerName.includes(xElementOperator.name.trim()) && tempOwnerNi.includes(xElementOperator.ni.trim())) {
              tempOwnerOperador.forEach((xElementOwnerOperator: any, xIndex: number) => {
                if (xElementOwnerOperator.name == xElementOperator.name) {
                  tempOwnerOperador[xIndex].type_owner_operator = "both";
                }
              });
            } else {
              tempOwnerOperador.push(
                {
                  owner_operator_id: LocalMethodsHandlerClass.generateUuid(),
                  type_owner_operator: "operator",
                  avatar_image: "",
                  avatar_color: "",
                  type_entity: xElementOperator.type_entity,
                  country: xElementOperator.ni ? "BR" : "",
                  type_ni: xElementOperator.ni ? (xElementOperator.ni.length == 11 ? "CPF" : "CNPJ") : "",
                  ni: xElementOperator.ni,
                  name: xElementOperator.name,
                  responsible: {
                    name: "",
                    contact: [{
                      title: "Contato Responsável",
                      type: 'mobile',
                      ddi: '+55',
                      ddd: '',
                      number: '',
                      normalized: '',
                      is_whatsapp: true,
                      is_telegram: true,
                      observation: '',
                      receive_news: true
                    }]
                  },
                  address: [],
                  contacts: [],
                  owner_operator_remarks: ""
                }
              )
            }
          });

          this.formAircraftData.controls.owner_operator_data.value = tempOwnerOperador;

          // Simula um delay de 5 segundos para exibir a mensagem de sucesso.
          setTimeout(() => {
            this.bolShowWaitingDialog = false;

            this._messageService.add({
              severity: 'success',
              summary: 'Recuperar Dados RAB/ANAC',
              detail: 'Os dados da aeronave foram recuperados com sucesso.',
              key: settingConfig.TOAST_KEY,
              life: settingConfig.TOAST_LIFE
            });
          }, settingConfig.WAITING_DIALOG_LIFE);
        } else {
          this.bolShowWaitingDialog = false;

          this._messageService.add({
            severity: 'error',
            summary: 'Recuperar Dados RAB/ANAC',
            detail: 'Não foi possível recuperar os dados da aeronave.',
            key: settingConfig.TOAST_KEY,
            life: settingConfig.TOAST_LIFE
          });
        }
      },
      error: (xError: any) => {
        let tempSeverity = 'error';

        if (xError.http_error == HTTP_STATUS.NOT_FOUND) {
          tempSeverity = 'info';
        }

        this.bolShowWaitingDialog = false;

        this._messageService.add({
          severity: `${tempSeverity}`,
          summary: 'Recuperar Dados RAB/ANAC',
          detail: `${xError.detail}`,
          key: settingConfig.TOAST_KEY,
          life: settingConfig.TOAST_LIFE
        });
      }
    });
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método que limpa o formulário de dados da aeronave.
   */
  private_clearForm(): void {
    // tem que garantir que os eventos que são adicionado em outros formulários sejam reexecutados no OnChange, para que não haja duplicidade de eventos.
    this.formAircraftData = null;
    this._initAircraftDataForm();
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * @author GASPAR
   * @date 2024-09-20
   * @version 1.0.0
   * 
   * @description Método que trata a mudança de abas.
   */
  onTabChange(xTabNumber: number): void {
    this.activeTabIndex = xTabNumber;
  }

  /**
   * @author GASPAR
   * @date 2024-09-17
   * @version 1.0.0
   * 
   * @description Método que trata as ações da aba de dados de registro da aeronave.
   * 
   * @param xAction 
   */
  handlerActionTabRegistrationData(xAction: string): void {
    if (xAction === BUTTON_ACTION.NEW) {
      console.log('NOVO REGISTRO DE AERONAVE');
      this._newRegistrationData();
    } else if (xAction === BUTTON_ACTION.SAVE) {
      console.log('SALVAR DADOS DA AERONAVE');
      this._saveRegistrationData();
    } else if (xAction === BUTTON_ACTION.UPDATE) {
      console.log('UPDATE DADOS DA AERONAVE');
      this._updateAircraftData();
    } else if (xAction === BUTTON_ACTION.EXIT) {
      console.log('SAIR DA AERONAVE');
    } else {
      console.log('NENHUMA AÇÃO DEFINIDA');
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * 
   * @Método que trata as ações da aba de dados de registro da aeronave.
   * 
   * @abstract Para salvar os dados da Especificação, basta atualizar os dados da aronave.
   * 
   * @param xAction 
   */
  handlerActionTabSpecificationsData(xAction: string): void {
    if (xAction === BUTTON_ACTION.SAVE) { //
      console.log('SALVAR DADOS ESPECIFICAÇÃO DA AERONAVE');
      this._updateAircraftData();
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-26
   * @version 1.0.0
   * 
   * @description Método que trata as ações da aba de ficha de peso e balanceamento.
   * 
   * @param xAction 
   */
  handlerActionTabWeightBalanceSheetForm(xAction: any): void {
    console.log("BBBBBBBBBBBBBBBBB", xAction)
    if (xAction === BUTTON_ACTION.LIST) {
      console.log('LISTAR FICHA DE PESO E BALANCEAMENTO');
      this.weightBalanceSheetScreen = "list";
      this.currentIdWeightBalanceSheet = null;
    } else if (xAction.action === BUTTON_ACTION.SAVE) {
      console.log('ATUALIZAR WEIGHT BALANCE SHEET', xAction);
      this._updateAircraftWeightBalanceSheet(xAction.id, this.formAircraftData.controls.aircraft_data_id.value);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description Método que trata as ações da aba de lista de peso e balanceamento.
   * 
   * @param xAction 
   */
  handlerActionTabWeightBalanceSheetList(xAction: any): void {
    if (xAction.action === BUTTON_ACTION.NEW) {
      console.log('NOVA FICHA DE PESO E BALANCEAMENTO');
      this.weightBalanceSheetScreen = "form";
      this.currentIdWeightBalanceSheet = null;
    } else if (xAction.action === BUTTON_ACTION.EDIT) {
      console.log('EDIT FICHA DE PESO E BALANCEAMENTO');
      this.weightBalanceSheetScreen = "form";
      this.currentIdWeightBalanceSheet = xAction.id_register;
    } else if (xAction.action === BUTTON_ACTION.CLONE) {
      console.log('CLONE FICHA DE PESO E BALANCEAMENTO');
      this.weightBalanceSheetScreen = "form";
      this.currentIdWeightBalanceSheet = `##TOCLONE##${xAction.id_register}`;
    } else if (xAction.action === BUTTON_ACTION.DELETE) {
      console.log('APAGAR FICHA DE PESO E BALANCEAMENTO');
      this._deleteAircraftWeightBalanceSheet(this.formAircraftData.controls.aircraft_data_id.value, xAction.id);
    }
  }

  /**
   * @author GASPAR
   * @date 2024-09-24
   * @version 1.0.0
   * @param xShowDialog  
   * 
   * @description Método que trata a exibição do dialog de espera.
   * 
   * @param xShowDialog 
   */
  handleShowDialogWaiting(xShowDialog: IWaitingDialog): void {
    this.bolShowWaitingDialog = xShowDialog.show_dialog;
    this.strTypeWaitingDialog = xShowDialog.type_waiting;
  }
  //#endregion
}
