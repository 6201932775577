<section class="cls-pge-app-form">
    <sunrise-toast-message></sunrise-toast-message>
    <!-- <p-confirmDialog class="cls-component-confirm-dialog-default" /> -->
    <sunrise-confirm-delete-dialog dialogKey="owner_operator_conf_del"></sunrise-confirm-delete-dialog>
    <sunrise-confirm-delete-dialog dialogKey="weight_balance_sheet_del"></sunrise-confirm-delete-dialog>
    <sunrise-app-platform-waiting-dialog 
        [showWaitingDialog]="this.bolShowDialogWaiting" 
        [typeWaitingDialog]="this.typeDialogWaiting"
        [extraData]="this.extraDataDialogWaiting"
        (actionCloseWaitingDialog)="this.handlerActionCloseWaitingDialog($event)">
    </sunrise-app-platform-waiting-dialog>
    <sunrise-title-page [titlePage]="this.objPageTitle"></sunrise-title-page>
    <div class="cls-frame-dashboard-horizontal-main-form-grid-1-col">
        <section class="cls-frame-dashboard-horizontal-main-content">            
            <p-tabView 
                [scrollable]="true" 
                [activeIndex]="0"
                (onChange) = "this.onChangeTabView($event)"
                class="cls-cmp-ptv-tabview-page">
                <!-- REGISTER DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-clipboard-list"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_Register')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <sunrise-tab-register-data 
                            [formComponent]="this.formAircraftData"
                            [retrievedDataOwnerOperator]="this.dataRetrievedOwnerOperatorToEdit"
                            [allOwnersOperators]="this.listOwnerOperatorData"      
                            (actionToolbarTabRegisterData)="this.handlerActionToolbarTabRegisterData($event)"                       
                            (actionOwnerOperator)="this.handlerActionOwnerOperator($event)"
                            (actionRegisterData)="this.handlerActionRegisterData($event)"
                            (actionTabItensOwnerOperator)="this.handlerActionTabItensOwnerOperator($event)"                                                       
                            (actionShowWaitingDialog)="this.handleShowDialogWaiting($event)">
                        </sunrise-tab-register-data>
                    </ng-template>
                </p-tabPanel>
                <!-- SPECIFICATIONS DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-sliders-h"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_Specifications')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <sunrise-tab-specification-data
                            [formComponent]="this.formAircraftData"
                            (actionTabSpecificationData)="this.handlerActionTabSpecificationsData($event)"
                            (actionSpecificationData)="this.handlerActionSpecificationData($event)">
                        </sunrise-tab-specification-data>
                    </ng-template>
                </p-tabPanel>
                <!-- CREW AND PAX DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null && false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-users"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_CrewAndPaxShort')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content"></ng-template>
                </p-tabPanel>
                <!-- WEIGHT AND BALANCE SHEET -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-balance-scale"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_WeightBalanceShort')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <ng-container *ngIf="this.typeWeightBalanceSheetScreen === 'list'">
                            <sunrise-tab-weight-balance-sheet-list
                                [formComponent]="this.formAircraftData"
                                (actionWeightBalanceDataSheetList)="this.handlerActionWeightBalanceSheetList($event)">
                            </sunrise-tab-weight-balance-sheet-list>
                        </ng-container>
                        <ng-container *ngIf="this.typeWeightBalanceSheetScreen === 'form'">
                            <sunrise-tab-weight-balance-sheet-form 
                                [formComponent]="this.formAircraftData"
                                [idWeightBalanceSheet]="this.currentWeightBalanceSheetId"
                                (actionWaitingDialog)="this.handlerActionWaitingDialog($event)"
                                (actionTabWeightBalanceDataSheetForm)="this.handlerActionTabWeightBalanceSheetForm($event)"
                                (actionWeightBalanceDataSheetForm)="this.handlerActionWeightBalanceSheetForm($event)">
                            </sunrise-tab-weight-balance-sheet-form>
                        </ng-container>  
                    </ng-template>
                </p-tabPanel>
                <!-- NOTEBOOK DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null && false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-book"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_NotebookShort')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content"></ng-template>
                </p-tabPanel>
                <!-- SHARING DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null && false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-share-alt"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_SharingShort')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content"></ng-template>
                </p-tabPanel>
                <!-- COAST DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null && false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-dollar-sign"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_Coasts')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content"></ng-template>
                </p-tabPanel>
                <!-- DOCUMENTS DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null && false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-file-alt"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_Documents')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content"></ng-template>
                </p-tabPanel>
                <!-- MAINTENANCE DATA -->
                <p-tabPanel class="cls-cmp-ptv-tabview-page-tabpanel" *ngIf="this.formAircraftData.controls.aircraft_data_id.value !== null && false">
                    <ng-template pTemplate="header">
                        <div class="cls-cmp-ptv-tabview-page-tabpanel-header">
                            <i class="fa-solid fa-wrench"></i>
                            <span>{{this.classInternationalization.getTranslation('ttl_Maintenance')}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content"></ng-template>
                </p-tabPanel>
            </p-tabView>
        </section>
    </div>
</section>