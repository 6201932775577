import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class languageAppGuard implements CanActivate {
  constructor(
    private router: Router
  ) {}

  /**
   * @author GASPAR
   * @date 2024-12-16
   * @version 1.0.0
   * 
   * @description:
   *   - Método que é chamado para verificar se o idioma do usuário foi salvo no localStorage.
   *   - caso o usuário entre com o endereço: http://localhost:4200/ o sistema irá redirecionar para a rota correta com base no idioma.
   * 
   * @returns 
   */
  canActivate(): boolean {
    // Recupera o idioma do usuário do navegador ou define o idioma padrão como 'pt-br' caso não seja encontrado nenhum idioma.
    const userLang = navigator.language || (navigator.languages && navigator.languages[0]) || 'pt-br';
    // Recupera o idioma do usuário do localStorage ou define o idioma padrão como 'pt-br' caso não seja encontrado nenhum idioma.
    const savedLang = localStorage.getItem('userLang') || userLang;

    // É preciso salvar o idioma do usuário no localStorage
    localStorage.setItem('userLang', savedLang.toLowerCase());

     // Redirecione para a rota correta com base no idioma
     this.router.navigate([`/app/${savedLang}/login`.toLowerCase()]);

     return false;
  }  
};
