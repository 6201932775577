import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-weight-balance-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './weight-balance-list.component.html'
})
export class WeightBalanceListComponent {}
