//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR ***** //
import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';

// ***** NPM ***** //
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

// ***** CONFIG ***** //
import { settingConfig } from '../../../../../../../../../../../config/settingConfig';

// ***** MODULE ***** //
import { PrimengComponentsModule } from '../../../../../../../../../../_library/layouts/components/primeng/primeng-components.module';

// ***** PRIMENG ***** //
import { MessageService } from 'primeng/api';

// ***** COMPONENT ***** //
import { ChartCGLimitsComponent } from '../../../../../../../../../_components/chart/chart-cg-limits/chart-cg-limits.component';

// ***** CLASS ***** //
import { AviationToolsHandlerClass } from '../../../../../../../../../_classes/AviationToolsHandlerClass';
import { InternationalizationHandler } from '../../../../../../../../../_classes/InternationalizationHandlerClass';
import { LocalMethodsHandlerClass } from '../../../../../../../../../../_library/classes/LocalMethodsHandlerClass';
import { ModuleMethodsHandlerClass } from '../../../../../../../../../_classes/ModuleMethodsHandlerClass';
//#endregion

//#region "|--- IMPORT ENUM ---|"
import { ACTION_TYPE } from '../../../../../../../../../../_library/definitions/ActionType';
import { POSITION_CG_IN_ENVELOP } from '../../../../../../../../../../_library/definitions/PositionCgInEnvelop';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { staticOptionsCgFuelUnits } from '../../../../../../../../../../_library/static_options/staticOptionsCgFuelUnits';
import { staticOptionsDocumentsPersonAll } from '../../../../../../../../../../_library/static_options/staticOptionsDocumentsPersonAll';
//#endregion

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sunrise-preview-data-sheet-filled',
  standalone: true,
  imports: [
    ChartCGLimitsComponent,
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './preview-data-sheet-filled.component.html',
})
export class PreviewDataSheetFilledComponent implements OnInit, AfterViewInit {
  @ViewChild('imageFrame') imageFrame!: ElementRef;

  //#region "|--- INPUTS ---|"
  @Input() dataSheet: any;
  @Input() dataAircraft: any;
  @Input() dataFlight: any;
  @Input() listPointToDraw: any;
  @Input() positionChart: any;
  //#endregion

  //#region "|--- INPUTS ---|"
  @Output() actionToolbarPreview: EventEmitter<string> = new EventEmitter();
  //#endregion

  //region "|--- PROPERTIES ---|"
  // ***** GENERAL ***** //
  public actionType!: any;
  public currentSettings!: any;
  public indexTableMarker!: number;
  public positionCgInEnvelop!: any;

  // ***** CLASSES ***** //
  public classAviationTools!: any;
  public classLocalMethods!: any;
  public classInternationalization!: any;
  public classModuleMethods!: any;

  // ***** OPTIONS ***** //
  public optionsCgFuelUnits!: any
  //#endregion

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private _messageService: MessageService,
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._setStyleForImageFrame();
    }, 100);
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * @status OK
   * @author GASPAR
   * @date 2025-01-20
   * @version 1.0.0
   * 
   * @description 
   *   - Inicializa as variáveis do componente.
   */
  private _initVariables(): void {
    this.actionType = ACTION_TYPE;
    this.currentSettings = settingConfig;
    this.indexTableMarker = 0;
    this.positionCgInEnvelop = POSITION_CG_IN_ENVELOP;

    this.classAviationTools = AviationToolsHandlerClass;
    this.classLocalMethods = LocalMethodsHandlerClass;
    this.classInternationalization = InternationalizationHandler;
    this.classModuleMethods = ModuleMethodsHandlerClass;

    this.optionsCgFuelUnits = staticOptionsCgFuelUnits;

    console.log("00000000000000", this.dataSheet);
    console.log("11111111111111", this.dataAircraft);
    console.log("22222222222222", this.dataFlight);
    console.log("33333333333333", this.listPointToDraw);
  }

  /**
   * @author GASPAR
   * @date 2024-11-28
   * @version 1.0.0
   * 
   * @description Método responsável por ajustar o tamanho do frame da imagem.
   */
  private _setStyleForImageFrame(): void {
    if (this.imageFrame !== undefined && this.imageFrame !== null) {
      const tempImg = this.imageFrame.nativeElement.querySelector('img');

      this._renderer.setStyle(this.imageFrame.nativeElement, 'width', "10rem");
      this._renderer.setStyle(this.imageFrame.nativeElement, 'height', "25rem");

      this._renderer.setStyle(tempImg, 'width', "10rem");
      this._renderer.setStyle(tempImg, 'height', "24rem");
    }
  }

  /**
   * @status OK
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description
   *   - Gera um arquivo PDF a partir do conteúdo da página.
   * 
   * !! ATENÇÃO
   * !! A captura de imagens de links externos pode falhar devido a restrições de CORS (Cross-Origin Resource Sharing). 
   * !! Para contornar isso, você pode configurar html2canvas para usar uma proxy que permite carregar imagens de diferentes origens.
   */
  private _generatePDF(): void {
    const element = document.getElementById('contentToPdfOrImage');

    if (element) {
      const scale = 2; // Aumente a escala para melhorar a qualidade da imagem
      element.style.width = `817px`;

      html2canvas(element, {
        scale: scale,
        useCORS: true // Adicione esta linha se você estiver carregando imagens de diferentes origens
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.75); // Use JPEG e ajuste a qualidade para 75%
        const doc = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = doc.internal.pageSize.getHeight();
        const imgProps = doc.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let heightLeft = imgHeight;
        let position = 0;

        // Adiciona a primeira página
        doc.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;

        // Adiciona páginas adicionais conforme necessário
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }

        const pdfBlob = doc.output('blob');

        // Abre uma nova aba do navegador para exibir o PDF
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');

        // Libera o objeto canvas
        canvas.width = 0;
        canvas.height = 0;

        // Cria um link de download para o PDF
        //const downloadLink = document.createElement('a');
        //downloadLink.href = pdfUrl;
        //downloadLink.download = 'weight-balance-data.pdf';
        //downloadLink.click();

        this._messageService.add({
          severity: 'success',
          summary: this.classInternationalization.getTranslation('ttl_GeneratePreviewPdf'),
          detail: this.classInternationalization.getTranslation('msg_GeneratePreviewPdfSuccess'),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      }).catch((error) => {
        console.error('Erro ao gerar o PDF:', error);
        this._messageService.add({
          severity: 'error',
          summary: this.classInternationalization.getTranslation('ttl_GeneratePreviewPdf'),
          detail: this.classInternationalization.getTranslation('msg_GeneratePreviewPdfError'),
          key: this.currentSettings.TOAST_KEY,
          life: this.currentSettings.TOAST_LIFE
        });
      });
    } else {
      console.error('Elemento contentToPdfOrImage não encontrado.');
      this._messageService.add({
        severity: 'error',
        summary: this.classInternationalization.getTranslation('ttl_GeneratePreviewPdf'),
        detail: this.classInternationalization.getTranslation('msg_GeneratePreviewPdfError'),
        key: this.currentSettings.TOAST_KEY,
        life: this.currentSettings.TOAST_LIFE
      });
    }
  }

  /**
   * @status OK
   * @author GASPAR
   * @date 2024-09-30
   * @version 1.0.0
   * 
   * @description
   *   - Gera uma imagem a partir do conteúdo da página.
   * 
   * !! ATENÇÃO
   *  !! A captura de imagens de links externos pode falhar devido a restrições de CORS (Cross-Origin Resource Sharing). 
   *  !! Para contornar isso, você pode configurar html2canvas para usar uma proxy que permite carregar imagens de diferentes origens.
   */
  private _generateImage(): void {
    const element = document.getElementById('contentToPdfOrImage');

    if (element) {
      const scale = 4; // Aumente a escala para melhorar a qualidade da imagem

      html2canvas(element, {
        scale: scale,
        useCORS: true // Adicione esta linha se você estiver carregando imagens de diferentes origens
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // Copia a imagem para a área de transferência
        canvas.toBlob((blob) => {
          if (blob) {
            const item = new ClipboardItem({ 'image/png': blob });
            navigator.clipboard.write([item]).then(() => {

              this._messageService.add({
                severity: 'success',
                summary: this.classInternationalization.getTranslation('ttl_GeneratePreviewImage'),
                detail: this.classInternationalization.getTranslation('msg_GeneratePreviewImageClipboardSuccess'),
                key: this.currentSettings.TOAST_KEY,
                life: this.currentSettings.TOAST_LIFE
              });

              // Abre uma nova aba do navegador para exibir a imagem
              const imageWindow = window.open('', '_blank');
              if (imageWindow) {
                imageWindow.document.write('<img src="' + imgData + '" />');
              }
            }).catch((xError) => {
              this._messageService.add({
                severity: 'error',
                summary: this.classInternationalization.getTranslation('ttl_GeneratePreviewImage'),
                detail: this.classInternationalization.getTranslation('msg_GeneratePreviewImageError'),
                key: this.currentSettings.TOAST_KEY,
                life: this.currentSettings.TOAST_LIFE
              });
            });
          }
        });
      });
    }

    // SE FOSSE USAR PROXY
    /*if (element) {
      html2canvas(element, {
        useCORS: true,
        proxy: 'https://your-proxy-server.com/proxy' // Substitua pelo URL do seu servidor proxy
      }).then((canvas) => {
        // ADAPTAR O CODIGO ACIMA.....
      }).catch((error) => {
        console.error('Erro ao gerar a imagem:', error);
      });
    } else {
      console.error('Elemento contentToPdfOrImage não encontrado.');
    }*/
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  /**
  * @status:
  * @author GASPAR
  * @date 2025-01-12
  * @version 1.0.0
  * 
  * @description 
  *   - Retorno da Url para mostrar a imagem.
  *
  * @returns
  */
  public getUrlImageToShowWeightBalanceDataSheetImage(): string {
    const imageName = this.dataSheet.weight_balance_data.floor_plan.image;

    let urlShowImage: string;
    let tempId = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_WEIGHT_BALANCE_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.dataSheet.sheet_data.fk_weight_balance_data_sheet_id != null) {
      tempId = this.dataSheet.sheet_data.fk_weight_balance_data_sheet_id;
    }

    return `${urlShowImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/weight/balance/data/sheet/floor/plan/image/${tempId}/${imageName}`.toLowerCase();
  }

  public getDocumentTypeName(xDocumentType: string) {
    const tempDocumentType = staticOptionsDocumentsPersonAll.find((xItem: any) => xItem.value === xDocumentType);

    if (tempDocumentType) {
      let returnLabel = "";

      // Verifica se tem algum valor entre (), se tiver retira ele e retorna ele.
      if (tempDocumentType.label.includes("(")) {
        returnLabel = tempDocumentType.label.split("(")[1].replaceAll(")", "").trim();
      } else {
        returnLabel = tempDocumentType.label;
      }

      return returnLabel;
    } else {
      return "";
    }
  }

  public getAircraftImage(): string {
    let urlShowImage: string;
    let tempId = "000";
    let tempMark = "000";
    let temImage = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_AIRCRAFT_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.dataAircraft.aircraft_data_id != null) {
      tempId = this.dataAircraft.aircraft_data_id;
    }

    if (this.dataAircraft.register_data.aircraft_mark != null) {
      tempMark = this.dataAircraft.register_data.aircraft_mark;
    }

    if (this.dataAircraft.avatar_image != null) {
      temImage = this.dataAircraft.avatar_image;
    }

    return `${urlShowImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/aircraft/image/${tempId}/${tempMark}/${temImage}`.toLowerCase();
  }

  public getFloorPlantImage(): string {
    let urlShowImage: string;
    let tempId = "000";
    let tempMark = "000";
    let temImage = "000";

    if (this.currentSettings.API_FOR_ALL_PORT == "443") {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}/${this.currentSettings.API_PATH}`;
    } else {
      urlShowImage = `${this.currentSettings.API_PROTOCOL}://${this.currentSettings.API_HOST}:${this.currentSettings.API_AIRCRAFT_PORT}/${this.currentSettings.API_PATH}`;
    }

    if (this.dataAircraft.aircraft_data_id != null) {
      tempId = this.dataAircraft.aircraft_data_id;
    }

    if (this.dataAircraft.register_data.aircraft_mark != null) {
      tempMark = this.dataAircraft.register_data.aircraft_mark;
    }

    if (this.dataAircraft.avatar_image != null) {
      temImage = this.dataAircraft.avatar_image;
    }

    return `${urlShowImage}/${this.currentSettings.API_AIRCRAFT_URL_SEGMENT}/${this._locale}/aircraft/image/${tempId}/${tempMark}/${temImage}`.toLowerCase();
  }

  public getLengthComponentExternal(): number {
    return this.dataSheet.weight_balance_data.components_externals_data.length;
  }

  /**
     * @status:
     * @date 2025-01-07
     * @version 1.0.0
     * 
     * @description 
     *  - Retorna a Label da Unidade de Peso do Combustível. 
     *
     * @returns 
     */
  public getFuelUnitLabel(xUnit: string): string {
    let tempFuelUnitLabel = this.optionsCgFuelUnits.find((xItem: any) => xItem.value === xUnit).label;

    tempFuelUnitLabel = tempFuelUnitLabel.replace(`${xUnit} - `, "")

    return tempFuelUnitLabel;
  }

  /**
   * @status:
   * @author GASPAR
   * @date 2025-01-08
   * @version 1.0.0
   * 
   * @description 
   *   - Analisa se o Ponto está dentro ou fora do gráfico e retorna cores indicando a situação.
   * 
   *  @xIndex
   *  @xChart
   */
  public getClassCgInChart(xIndex: number, xChart: string): string {
    let tempPosition = "";

    if (xIndex === 1) { // ZFW
      if (xChart === "long") {
        tempPosition = this.positionChart.zfwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.positionChart.zfwPositionChartLat;
      }
    } else if (xIndex === 3) { // RW
      if (xChart === "long") {
        tempPosition = this.positionChart.rwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.positionChart.rwPositionChartLat;
      }
    } else if (xIndex === 5) { // TOW
      if (xChart === "long") {
        tempPosition = this.positionChart.towPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.positionChart.towPositionChartLat;
      }
    } else if (xIndex === 7) { // LDW
      if (xChart === "long") {
        tempPosition = this.positionChart.ldwPositionChartLong;
      } else if (xChart === "lat") {
        tempPosition = this.positionChart.ldwPositionChartLat;
      }
    }

    if (tempPosition === this.positionCgInEnvelop.INSIDE) {
      return "cls-cmp-esp-cg-alert-ok cls-cmp-esp-cg-alert-preview";
    } else if (tempPosition === this.positionCgInEnvelop.INSIDE_NEAR_BOARD) {
      return "cls-cmp-esp-cg-alert-warning cls-cmp-esp-cg-alert-preview";
    } else if (tempPosition === this.positionCgInEnvelop.OUTSIDE) {
      return "cls-cmp-esp-cg-alert-danger cls-cmp-esp-cg-alert-preview";
    } else {
      return "";
    }
  }
  //#endregion

  //#region "|--- EVENT METHODS ---|"
  //#endregion

  //#region "|--- HANDLER METHODS ---|"
  //#endregion

  //#region "|--- ACTION METHODS ---|"
  public onClickToolbarPreview(xAction: string): void {
    if (xAction === this.actionType.EXIT) {
      this.actionToolbarPreview.emit(this.actionType.EXIT);
    } else if (xAction === this.actionType.GENERATE_PDF) {
      this._generatePDF();
    } else if (xAction === this.actionType.GENERATE_IMAGE) {
      this._generateImage();
    }
  }
  //#endregion
}
